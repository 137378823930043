
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import RegReportTypesTable from './partials/RegReportTypesTable.vue'
  import MedicationStockTypesTable from '@/views/modules/mars/partials/MedicationStockTypesTable.vue'
  import MedicationStockCategoriesTable from '@/views/modules/mars/partials/MedicationStockCategoriesTable.vue'
  import CustomFieldGroupsTable from '../partials/CustomFieldGroupsTable.vue'
  import CustomFieldsTable from '../partials/CustomFieldsTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      RegReportTypesTable,
      MedicationStockCategoriesTable,
      MedicationStockTypesTable,
      CustomFieldGroupsTable,
      CustomFieldsTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Reg Report Types',
        tabs: [
          'Reg Report Types',
          'Medication Stock Types',
          'Medication Stock Categories',
          'Custom Field Groups',
          'Custom Fields',
        ],
      })

      return {
        state,
      }
    },
  })
