
  import { computed, defineComponent, onMounted, reactive, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { MenuTab } from '@/types/components/MenuTab'
  import { isTab } from '@/composables/Generic'
  import Audit from '@/views/modules/documents/Audit.vue'
  import Settings from '@/views/modules/documents/Settings.vue'
  import DocumentsTable from '@/views/modules/documents/partials/DocumentsTable.vue'
  import { DocumentClass } from '@/types/modules/documents/DocumentClass'

  export default defineComponent({
    components: {
      Audit,
      Settings,
      DocumentsTable,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const state = reactive({
        tabs: [] as MenuTab[],
      })

      const documentClasses = computed(() => store.getters['documents/classes'])

      const sensitive = computed(() => route.name === 'SensitiveIndex')

      const showDocumentsTable = computed(() => {
        return !!(
          ['AttachmentsIndex', 'SensitiveIndex'].includes(
            route.name?.toString() || ''
          ) ||
          (route.name === 'DocumentIndex' &&
            documentClasses.value.length &&
            !isTab('audit') &&
            !isTab('settings'))
        )
      })

      const getDocumentCategories = () => {
        return store
          .dispatch('documentCategories/index', {})
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getDocumentClasses = () => {
        return store
          .dispatch('documents/getDocumentClasses', {})
          .then((response) => {
            state.tabs = response.data.map((documentClass: DocumentClass) => {
              return {
                name: documentClass.name,
                url: `${
                  route.fullPath
                }?tab=${documentClass.name.toLowerCase()}`,
              }
            })

            state.tabs.push(
              { name: 'Audit', url: `${route.path}?tab=audit` },
              { name: 'Settings', url: `${route.path}?tab=settings` }
            )
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const loadDependencies = () => {
        return new Promise((resolve) => {
          if (route.name === 'DocumentIndex') {
            getDocumentClasses().then((response) => {
              store.commit('genericStore/setTabs', { tabs: state.tabs })
              router.replace(state.tabs[0].url)

              resolve(response)
            })
          }

          getDocumentCategories().then((response) => resolve(response))
        })
      }
      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Documents', url: '/documents' },
        ]

        if (!showDocumentsTable) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: state.tabs.find((tab) => tab.url === route.fullPath)?.name!,
          url: route.fullPath,
        })

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        if (route.name === 'DocumentIndex') {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
          store.commit('genericStore/setTabs', { tabs: [] })
        }

        loadDependencies().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        isTab,
        sensitive,
        showDocumentsTable,
      }
    },
  })
