import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-max gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datasource_card = _resolveComponent("datasource-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datasources, (datasource, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "col-span-1 h-full"
      }, [
        _createVNode(_component_datasource_card, {
          datasource: datasource,
          "onUpdate:datasource": _ctx.update,
          class: "h-full"
        }, null, 8, ["datasource", "onUpdate:datasource"])
      ]))
    }), 128))
  ]))
}