import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-display p-1 h-max m-1 rounded-md border-2 text-black" }
const _hoisted_2 = { class: "text-xxs font-semibold block truncate" }
const _hoisted_3 = { class: "space-y-1" }
const _hoisted_4 = { class: "float-left text-4xs block italic truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "popover-default",
      class: _normalizeClass(["border-l-4 pl-2 rounded-sm", _ctx.statusClasses])
    }, [
      _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.props.data.text), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, " Relates To: " + _toDisplayString(_ctx.props.data.relates_to), 1),
        _createElementVNode("div", {
          class: "project-colour float-right",
          style: _normalizeStyle(_ctx.projectColour)
        }, "   ", 4)
      ])
    ], 2)
  ]))
}