
  import { defineComponent, computed } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const authenticatedUser = computed(
        () => store.getters['auth/authenticatedUser']
      )
      const acceptTerms = () => {
        store
          .dispatch('auth/acceptTermsConditions')
          .then(() => store.dispatch('auth/redirect'))
          .catch(() => logout())
      }
      const logout = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('auth/logout')
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }
      return {
        authenticatedUser,
        acceptTerms,
        logout,
        t,
      }
    },
  })
