import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16a4c65b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "aria-disabled", "aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "far fa-circle-exclamation text-red-300 group-hover:text-white mr-2.5"
}
const _hoisted_3 = {
  key: 1,
  class: "far fa-circle-exclamation text-white mr-2.5"
}
const _hoisted_4 = {
  key: 5,
  class: "fas fa-chevron-right ml-2",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button-clicked'))),
    class: _normalizeClass(["group", _ctx.classes]),
    disabled: _ctx.isDisabled,
    "aria-disabled": _ctx.isDisabled,
    "aria-label": _ctx.label || _ctx.icon,
    role: "button"
  }, [
    (_ctx.props.type === 'tab-inactive-error')
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.props.type === 'tab-active-error')
      ? (_openBlock(), _createElementBlock("i", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 2,
          class: _normalizeClass([_ctx.icon, _ctx.label ? 'mr-2.5' : '', 'fa-fw']),
          "aria-hidden": "true"
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 3,
          class: _normalizeClass(_ctx.type == 'tertiary' ? 'underline' : '')
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    (_ctx.secondaryIcon && _ctx.secondaryIcon !== 'none')
      ? (_openBlock(), _createElementBlock("i", {
          key: 4,
          class: _normalizeClass([_ctx.secondaryIcon, _ctx.label ? 'ml-2.5' : '']),
          "aria-hidden": "true"
        }, null, 2))
      : _createCommentVNode("", true),
    (!_ctx.secondaryIcon && _ctx.type == 'tertiary')
      ? (_openBlock(), _createElementBlock("i", _hoisted_4))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}