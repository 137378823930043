import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e7b767a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scheduler-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataCellTemplate = _resolveComponent("DataCellTemplate")!
  const _component_DateCellTemplate = _resolveComponent("DateCellTemplate")!
  const _component_TimeCellTemplate = _resolveComponent("TimeCellTemplate")!
  const _component_ShiftTemplate = _resolveComponent("ShiftTemplate")!
  const _component_ShiftTooltipTemplate = _resolveComponent("ShiftTooltipTemplate")!
  const _component_ResourceCellTemplate = _resolveComponent("ResourceCellTemplate")!
  const _component_DxScheduler = _resolveComponent("DxScheduler")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.view_as === 'timelineDay')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "date-rotate mr-3",
          style: _normalizeStyle(`width: ${_ctx.calculateHeight}`)
        }, [
          _renderSlot(_ctx.$slots, "date-rotate", {}, undefined, true)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["rota-scheduler", _ctx.view_as === 'timelineDay' ? 'ml-6' : ''])
    }, [
      _createVNode(_component_DxScheduler, {
        ref: 
          (el) => {
            _ctx.timelineRef[_ctx.timeline_ref] = el
          }
        ,
        "data-source": _ctx.shifts,
        "current-view": _ctx.view_as,
        "current-date": _ctx.current_date,
        "start-date-expr": _ctx.start_date_field,
        "end-date-expr": _ctx.end_date_field,
        "first-day-of-week": 1,
        "cell-duration": _ctx.duration,
        "show-all-day-panel": _ctx.show_all_day,
        "show-current-time-indicator": _ctx.show_indicator,
        "max-appointments-per-cell": "unlimited",
        "on-cell-click": _ctx.onCellClick,
        "on-appointment-adding": _ctx.onAdd,
        "on-appointment-updating": _ctx.onUpdate,
        "on-appointment-deleting": _ctx.onDelete,
        "on-appointment-click": _ctx.onClick,
        "on-appointment-dbl-click": _ctx.onDoubleClick,
        "on-appointment-rendered": _ctx.appointmentRendered,
        scrolling: _ctx.scroll_mode,
        editing: _ctx.can_edit,
        views: _ctx.views,
        height: _ctx.calculateHeight,
        "data-cell-template": "DataCellTemplate",
        "date-cell-template": "DateCellTemplate",
        "appointment-template": "ShiftTemplate",
        "appointment-tooltip-template": "ShiftTooltipTemplate",
        "time-cell-template": "TimeCellTemplate",
        "resource-cell-template": "ResourceCellTemplate",
        onInitialized: _ctx.saveSchedulerInstance,
        onContentReady: _ctx.ready,
        onAppointmentFormOpening: _ctx.configureForm,
        onOptionChanged: _ctx.onOptionChanged,
        "time-zone": "Europe/London"
      }, {
        DataCellTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_DataCellTemplate, {
            "disable-cell": _ctx.shouldDisableDate(cellData.startDate),
            "cell-data": cellData
          }, null, 8, ["disable-cell", "cell-data"])
        ]),
        DateCellTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_DateCellTemplate, {
            "hide-dates": _ctx.hide_dates,
            "cell-data": cellData
          }, null, 8, ["hide-dates", "cell-data"])
        ]),
        TimeCellTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_TimeCellTemplate, {
            duration: _ctx.duration,
            "hide-times": _ctx.hide_times,
            "cell-data": cellData
          }, null, 8, ["duration", "hide-times", "cell-data"])
        ]),
        ShiftTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_ShiftTemplate, {
            "current-date": _ctx.current_date,
            "current-view": _ctx.view_as,
            "template-type": _ctx.shift_template_type,
            "cell-data": cellData
          }, null, 8, ["current-date", "current-view", "template-type", "cell-data"])
        ]),
        ShiftTooltipTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_ShiftTooltipTemplate, {
            onDelete: _ctx.deleteAppointment,
            "current-date": _ctx.current_date,
            "current-view": _ctx.view_as,
            "template-type": _ctx.shift_template_type,
            "can-delete": _ctx.can_edit.allowDeleting,
            "cell-data": cellData
          }, null, 8, ["onDelete", "current-date", "current-view", "template-type", "can-delete", "cell-data"])
        ]),
        ResourceCellTemplate: _withCtx(({ data: cellData }) => [
          _createVNode(_component_ResourceCellTemplate, { "cell-data": cellData }, null, 8, ["cell-data"])
        ]),
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "views", {}, undefined, true),
          _renderSlot(_ctx.$slots, "resources", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["data-source", "current-view", "current-date", "start-date-expr", "end-date-expr", "cell-duration", "show-all-day-panel", "show-current-time-indicator", "on-cell-click", "on-appointment-adding", "on-appointment-updating", "on-appointment-deleting", "on-appointment-click", "on-appointment-dbl-click", "on-appointment-rendered", "scrolling", "editing", "views", "height", "onInitialized", "onContentReady", "onAppointmentFormOpening", "onOptionChanged"])
    ], 2)
  ]))
}