import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    key: _ctx.key,
    "grid-heading": "Custom Field Groups",
    action: "projectTypeSections/index",
    columns: _ctx.columns,
    "query-filter": _ctx.filters,
    "extra-options": _ctx.state.extraOptions,
    onRowsSelected: _ctx.rowsSelected,
    onOptionClicked: _ctx.optionClicked,
    onInitMoveMode: _ctx.initMoveMode,
    onMoveItemTo: _ctx.reorderItems,
    "in-move-mode": _ctx.isMoveMode,
    onPrimaryButtonClicked: _ctx.addClicked
  }, _createSlots({ _: 2 }, [
    (_ctx.isMoveMode)
      ? {
          name: "toolbar-addon",
          fn: _withCtx(() => [
            _createVNode(_component_cc_button, {
              label: "Cancel",
              type: "danger-outline",
              icon: "fa fa-close",
              onClick: _ctx.cancelMoveMode
            }, null, 8, ["onClick"])
          ])
        }
      : undefined
  ]), 1032, ["columns", "query-filter", "extra-options", "onRowsSelected", "onOptionClicked", "onInitMoveMode", "onMoveItemTo", "in-move-mode", "onPrimaryButtonClicked"]))
}