import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32cdedb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gap-4 grid grid-cols-3 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_graph_card = _resolveComponent("graph-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgets, (widget, index) => {
      return (_openBlock(), _createBlock(_component_graph_card, {
        key: widget,
        widget: widget,
        class: _normalizeClass(_ctx.getClass(widget, index)),
        draggable: _ctx.movable.sourceIndex === index,
        onMoveWidget: ($event: any) => (_ctx.movable.sourceIndex = index),
        onDragover: _withModifiers(($event: any) => (_ctx.movable.targetIndex = index), ["prevent"]),
        onDrop: _ctx.moveWidget,
        onExportToPng: ($event: any) => (_ctx.exportGraph(index, widget.title, 'png')),
        onDeleteWidget: ($event: any) => (_ctx.deleteWidget(widget))
      }, {
        chart: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.chartComponents[widget.graph_type]), _mergeProps(_ctx.getProps(widget), {
            ref_for: true,
            ref: (el) => (_ctx.widgetRefs[index] = el)
          }), null, 16))
        ]),
        _: 2
      }, 1032, ["widget", "class", "draggable", "onMoveWidget", "onDragover", "onDrop", "onExportToPng", "onDeleteWidget"]))
    }), 128))
  ]))
}