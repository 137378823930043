
  import { defineComponent, reactive } from 'vue'
  import SchoolsTable from '@/views/modules/projects/schools/partials/SchoolsTable.vue'

  export default defineComponent({
    components: { SchoolsTable },
    props: {
      regionId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
