import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-body" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "flex items-center justify-center flex-col font-semibold text-center text-gray-300 text-1xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_file_uploader = _resolveComponent("dx-file-uploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: "h-60 w-60 flex items-center justify-center flex-col border-2 border-dashed p-6 bg-gray-100"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.t('components.fileDropZone.text')), 1)
      ])
    ], 8, _hoisted_2),
    _createVNode(_component_dx_file_uploader, {
      id: "file-uploader",
      "dialog-trigger": `#${_ctx.id}`,
      "drop-zone": `#${_ctx.id}`,
      multiple: false,
      "allowed-file-extensions": _ctx.allowedFileExtensions,
      "upload-mode": "useButtons",
      visible: false,
      onValueChanged: _ctx.uploadFile
    }, null, 8, ["dialog-trigger", "drop-zone", "allowed-file-extensions", "onValueChanged"])
  ]))
}