import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Legal Statuses",
    "create-view": "YoungPersonLegalStatusCreate",
    "edit-view": "YoungPersonLegalStatusEdit",
    "delete-view": "YoungPersonLegalStatusDelete",
    "restore-view": "YoungPersonLegalStatusRestore",
    action: "youngPersonLegalStatuses/index",
    "toggle-visibility-action": "youngPersonLegalStatuses/toggleManyVisibility",
    "delete-many-action": "youngPersonLegalStatuses/deleteMany",
    "reorder-action": "youngPersonLegalStatuses/saveOrderings"
  }))
}