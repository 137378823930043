import Login from '@/views/Login.vue'
import Overview from '@/views/modules/overview/Index.vue'
import Terms from '@/views/Terms.vue'
import Settings from '@/views/modules/settings/Index.vue'
import ManageProfile from '@/views/modules/auth/ManageProfile.vue'
import { RouteRecordRaw } from 'vue-router'
import RecycleBin from '@/views/RecycleBin.vue'
import guest from '@/middleware/auth/guest'
import auth from '@/middleware/auth/auth'
import { canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/',
    redirect: () => 'dashboard',
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      title: 'Overview',
      middleware: [auth],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'skeleton',
      title: 'Login',
      middleware: [guest],
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsConditions',
    component: Terms,
    meta: {
      layout: 'skeleton',
      title: 'Terms and Conditions',
      middleware: [auth],
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: 'Settings',
      module: 'sysadmin',
      middleware: [auth, canView],
    },
  },
  {
    path: '/manage-profile',
    name: 'ManageProfile',
    component: ManageProfile,
    meta: {
      title: 'Manage Profile',
      middleware: [auth],
    },
  },
  {
    path: '/recycle-bin',
    name: 'RecycleBin',
    component: RecycleBin,
    meta: {
      title: 'Recycle Bin',
      middleware: [auth],
    },
  },
] as RouteRecordRaw[]
