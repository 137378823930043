import { ActionTree, GetterTree, MutationTree } from 'vuex'
import MarsService from '@/services/mars/mars'
import { MedicationStock } from '@/types/modules/mars/MedicationStock'
import { MedicationLocation } from '@/types/modules/mars/MedicationLocation'
import { MedicationStockAudit } from '@/types/modules/mars/MedicationStockAudit'

export type MarsGetter = GetterTree<MarsState, any>

export interface MarsState {
  medicationLocations: MedicationLocation[]
  stockHistory: MedicationStockAudit[]
  stocksToTransfer: MedicationStock[]
  medications: MedicationStock[]
  stocksToDelete: MedicationStock[]
  medicationStock: MedicationStock | null
}

export const state: MarsState = {
  medicationLocations: [],
  stockHistory: [],
  stocksToTransfer: [],
  medications: [],
  stocksToDelete: [],
  medicationStock: null,
}

export const getters: MarsGetter = {
  medicationLocations: (state) => state.medicationLocations,
  stockHistory: (state) => state.stockHistory,
  stocksToTransfer: (state) => state.stocksToTransfer,
  medications: (state) => state.medications,
  stocksToDelete: (state) => state.stocksToDelete,
  getMedicationStock: (state) => state.medicationStock,
}

export const actions: ActionTree<MarsState, any> = {
  createMedication: async (_context, params) => {
    return await MarsService.createMedication(params)
  },
  getMedicationStock: (context, params) => {
    const result = Promise.resolve(
      MarsService.getMedicationStock(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setMedicationStock', response.data)
      }
    })

    return result
  },
  deleteMedications: async (_context, params) => {
    return await MarsService.deleteMedications(params)
  },
  getStocksToDelete: (context) => {
    return {
      data: context.getters.stocksToDelete,
      totalCount: context.getters.stocksToDelete.length,
    }
  },
  getStocksToTransfer: (context) => {
    return {
      data: context.getters.stocksToTransfer,
      totalCount: context.getters.stocksToTransfer.length,
    }
  },
  transferStocks: async (_context, params) => {
    return await MarsService.transferStocks(params)
  },
  editMedication: async (_context, params) => {
    return await MarsService.editMedication(params)
  },
  updateStockLevel: async (_context, params) => {
    return await MarsService.updateStockLevel(params)
  },
  getStockToAdjust: (context) => {
    const response = [context.getters.getMedicationStock]
    return {
      data: response,
      totalCount: response.length,
    }
  },
  setStockToAdjust: (context, stock: MedicationStock) => {
    context.commit('setMedicationStock', stock)
  },
  setStocksToDelete: (context, stocks: MedicationStock[]) => {
    context.commit('setStocksToDelete', stocks)
  },
  getMedications: async (context, params) => {
    const response = await MarsService.getMedications(params)

    if (response.success && params?.per_page) {
      context.commit('setMedications', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setMedications', response.data)

      return response
    }

    return response
  },
  getMedicationLocations: async (context, params) => {
    const results = await MarsService.getMedicationLocations(params)

    if (results.success) {
      context.commit('setMedicationLocations', results.data)
    }

    return results
  },
  getStockHistory: async (context, params) => {
    const response = await MarsService.getStockHistory(params)

    if (response.success && params?.per_page) {
      context.commit('setStockHistory', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setStockHistory', response.data)

      return response
    }

    return response
  },
  setStockToTransfer: (context, stocks) => {
    context.commit('setStockToTransfer', stocks)
  },
  updateMedicationEventInstance: (_context, payload) => {
    let results = Promise.resolve(
      MarsService.updateMedicationEventInstance(payload.id, payload.data)
    )

    return results
  },
  manageMedicationLocations: (_context, payload) => {
    return Promise.resolve(MarsService.manageMedicationLocations(payload))
  },
}

export const mutations: MutationTree<MarsState> = {
  setMedicationLocations: (state, medicationLocations) => {
    state.medicationLocations = medicationLocations
  },
  setStockHistory: (state, history) => {
    state.stockHistory = history
  },
  setStockToTransfer: (state, stocks: MedicationStock[]) => {
    state.stocksToTransfer = stocks
  },
  setMedications: (state, stocks: MedicationStock[]) => {
    state.medications = stocks
  },
  setMedicationStock: (state, stock: MedicationStock) => {
    state.medicationStock = stock
  },
  setStocksToDelete: (state, stocks: MedicationStock[]) => {
    state.stocksToDelete = stocks
  },
}

export const mars = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
