import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_job_titles_table = _resolveComponent("job-titles-table")!
  const _component_qualification_types_table = _resolveComponent("qualification-types-table")!
  const _component_qualification_issuing_bodies_table = _resolveComponent("qualification-issuing-bodies-table")!
  const _component_evidence_types_table = _resolveComponent("evidence-types-table")!
  const _component_contract_types_table = _resolveComponent("contract-types-table")!
  const _component_shift_types_table = _resolveComponent("shift-types-table")!
  const _component_custom_field_groups_table = _resolveComponent("custom-field-groups-table")!
  const _component_custom_fields_table = _resolveComponent("custom-fields-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_cc_button, {
              key: tab,
              label: tab,
              type: _ctx.state.activeTab === tab ? 'tab-active' : 'tab-inactive',
              onClick: ($event: any) => (_ctx.state.activeTab = tab)
            }, null, 8, ["label", "type", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (_ctx.state.activeTab === 'Job Titles')
      ? (_openBlock(), _createBlock(_component_job_titles_table, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Qualification Types')
      ? (_openBlock(), _createBlock(_component_qualification_types_table, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Qualification Issuing Bodies')
      ? (_openBlock(), _createBlock(_component_qualification_issuing_bodies_table, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Evidence Types')
      ? (_openBlock(), _createBlock(_component_evidence_types_table, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Contract Types')
      ? (_openBlock(), _createBlock(_component_contract_types_table, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Shift Types')
      ? (_openBlock(), _createBlock(_component_shift_types_table, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Custom Field Groups')
      ? (_openBlock(), _createBlock(_component_custom_field_groups_table, {
          key: 6,
          type: "Employee",
          "create-view": "EmployeeProjectTypeSectionCreate",
          "edit-view": "EmployeeProjectTypeSectionEdit",
          "delete-view": "EmployeeProjectTypeSectionDelete",
          "restore-view": "EmployeeProjectTypeSectionRestore"
        }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Custom Fields')
      ? (_openBlock(), _createBlock(_component_custom_fields_table, {
          key: 7,
          type: "Employee",
          "create-view": "EmployeeProjectTypeAttributeCreate",
          "edit-view": "EmployeeProjectTypeAttributeEdit",
          "delete-view": "EmployeeProjectTypeAttributeDelete",
          "restore-view": "EmployeeProjectTypeAttributeRestore"
        }))
      : _createCommentVNode("", true)
  ], 64))
}