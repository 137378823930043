
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import { defineComponent, PropType, reactive } from 'vue'
  import FormHeader from '../../FormHeader/FormHeader.vue'

  export default defineComponent({
    components: {
      FormHeader,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['collapse-toggle'],
    setup(props, { emit }) {
      props = reactive(props)

      const collapseToggle = (isCollapsed: any) => {
        emit('collapse-toggle', {
          value: props.fieldAttributes.value,
          shouldCollapse: isCollapsed,
        })
      }
      return { props, collapseToggle }
    },
  })
