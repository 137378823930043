import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "block font-semibold text-1xs text-gray-300 tracking-slight mb-1" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.fieldAttributes[0].field.name), 1),
        _createElementVNode("img", {
          src: _ctx.getFileUrl(_ctx.value, _ctx.fieldAttributes[0].field.field.name),
          class: "w-20 h-20"
        }, null, 8, _hoisted_3)
      ]))
    : _createCommentVNode("", true)
}