
  import { defineComponent, reactive, computed } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import { TaskLogComment } from '@/types/modules/tasks/TaskLogComment'
  import { useStore } from 'vuex'
  import { canEditModule } from '@/composables/Permissions'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      taskLogId: {
        type: Number,
        required: true,
      },
      taskId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      props = reactive(props)

      const columns = computed((): Column[] => {
        return [
          {
            caption: 'ID',
            width: 100,
            dataField: 'id',
            allowGrouping: false,
            dataType: 'number',
          },
          {
            dataField: 'comment',
            caption: 'Comment',
            allowGrouping: false,
            cellTemplate: function (container, options) {
              renderCellLink(
                container,
                options.data.comment,
                'TaskLogCommentEdit',
                {
                  commentId: options.data?.id,
                  taskId: props.taskId.toString(),
                  taskLogId: props.taskLogId.toString(),
                },
                {},
                !canEditModule('tasks')
              )
            },
          },
          {
            dataField: 'created_by.username',
            caption: 'Username',
            cellTemplate: function (container, options) {
              renderCellLink(
                container,
                options.data.created_by.username,
                'UserShow',
                {
                  id: options.data?.created_by.id,
                }
              )
            },
            allowGrouping: false,
          },
          {
            dataField: 'commented_at',
            dataType: 'datetime',
            caption: 'Date and Time',
            allowGrouping: false,
          },
        ]
      })

      const setSelectedTaskLogComments = (rows: TaskLogComment[]) => {
        store.commit('taskLogComments/setSelectedTaskLogComments', [])

        if (rows.length) {
          store.commit('taskLogComments/setSelectedTaskLogComments', rows)
        }
      }

      return {
        props,
        columns,
        setSelectedTaskLogComments,
      }
    },
  })
