import { GetterTree, MutationTree, ActionTree } from 'vuex'
import RegReportingTypeService from '@/services/regulatory_reports/reportTypes'
import { RegulatoryReportType } from '@/types/modules/regulatory_reports/RegulatoryReportType'

export type RegReportingTypeGetter = GetterTree<RegReportingTypeState, any>

export interface RegReportingTypeState {
  types: RegulatoryReportType[]
}

export const state: RegReportingTypeState = {
  types: [],
}

export const getters: RegReportingTypeGetter = {
  types: (state) => state.types,
}

export const actions: ActionTree<RegReportingTypeState, any> = {
  index: async (context, params) => {
    const response = await RegReportingTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTypes', response.data)

      return response
    }

    return response
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(RegReportingTypeService.saveOrderings(payload))
  },
}

export const mutations: MutationTree<RegReportingTypeState> = {
  setTypes: (state, types: RegulatoryReportType[]) => {
    state.types = types
  },
}

export const regReportTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
