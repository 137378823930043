
  import { Crumb } from '@/types/components/Crumb'
  import { MenuTab } from '@/types/components/MenuTab'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    Ref,
    ref,
  } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { goBack } from '@/composables/Generic'

  export default defineComponent({
    components: {
      TextBox,
      CcButton,
      ButtonGroup,
      ValidationErrors,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      publishedReportId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const tabs = reactive<MenuTab[]>([
        {
          name: 'Report',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=report`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
      ])
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Published Reports',
          url: `/bespoke-reporting/${props.id}/published-reports`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
        {
          name: 'Create Comment',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}/comments/create`,
        },
      ])
      const errors = ref(null) as Ref<Record<string, string> | null>
      const report = computed(
        () => store.getters['publishedBespokeReports/report']
      )
      const payload = reactive({
        body: '',
      })

      const payloadValidation = object({
        body: string().required('Please provide a comment'),
      })

      const createClicked = async () => {
        errors.value = await validatePayload(
          payloadValidation,
          payload as unknown as Record<string, unknown>
        )

        if (errors.value) {
          return
        }
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('publishedBespokeReportComments/store', {
            id: props.publishedReportId,
            ...payload,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            return router.push({
              name: 'BespokeReportPublishedShow',
              params: {
                id: props.id,
                publishedReportId: props.publishedReportId,
              },
              query: {
                tab: 'comments',
              },
            })
          })
          .catch((error) => (errors.value = error.response.data.errors))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: tabs })
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (!report.value) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('publishedBespokeReports/show', props.publishedReportId)
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      onUnmounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('publishedBespokeReports/setPublishedBespokeReport', null)
      })

      return {
        report,
        errors,
        goBack,
        payload,
        createClicked,
      }
    },
  })
