export default function pipeline(context: any, middleware: any, index: number) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return (params: any) => {
    if (params) {
      return context.next(params)
    }

    nextMiddleware({
      ...context,
      next: pipeline(context, middleware, index + 1),
    })
  }
}
