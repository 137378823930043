import { GetterTree, MutationTree, ActionTree } from 'vuex'
import LocalAuthorityService from '@/services/projects/localAuthorities'
import { LocalAuthority } from '@/types/modules/projects/LocalAuthority'

export type LocalAuthorityGetter = GetterTree<LocalAuthorityState, any>

export interface LocalAuthorityState {
  localAuthorities: LocalAuthority[]
  localAuthority: LocalAuthority | null
}

export const state: LocalAuthorityState = {
  localAuthorities: [],
  localAuthority: null,
}

export const getters: LocalAuthorityGetter = {
  getLocalAuthorities: (state) => state.localAuthorities,
  getLocalAuthority: (state) => state.localAuthority,
}

export const actions: ActionTree<LocalAuthorityState, any> = {
  index: async (context, params) => {
    const response = await LocalAuthorityService.index(params)

    if (response.success && params.per_page) {
      context.commit('setLocalAuthorities', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setLocalAuthorities', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      LocalAuthorityService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setLocalAuthority', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(LocalAuthorityService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(LocalAuthorityService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(LocalAuthorityService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(LocalAuthorityService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(LocalAuthorityService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(LocalAuthorityService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(LocalAuthorityService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<LocalAuthorityState> = {
  setLocalAuthorities: (state, authorities: LocalAuthority[]) =>
    (state.localAuthorities = authorities),
  setLocalAuthority: (state, authority: LocalAuthority) =>
    (state.localAuthority = authority),
}

export const localAuthorities = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
