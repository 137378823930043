import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "read-only": true,
    "show-grid-toolbar": false,
    "grid-heading": "Reg Report Types",
    "create-view": "RegReportTypeCreate",
    "edit-view": "RegReportTypeEdit",
    "delete-view": "RegReportTypeDelete",
    "restore-view": "RegReportTypeRestore",
    action: "regReportTypes/index",
    "toggle-visibility-action": "regReportTypes/toggleManyVisibility",
    "delete-many-action": "regReportTypes/deleteMany",
    "reorder-action": "regReportTypes/saveOrderings"
  }))
}