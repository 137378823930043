import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_toggle = _resolveComponent("cc-toggle")!
  const _component_expand_button = _resolveComponent("expand-button")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_extra_options_button = _resolveComponent("extra-options-button")!
  const _component_button_group = _resolveComponent("button-group")!

  return (_openBlock(), _createBlock(_component_button_group, null, {
    default: _withCtx(() => [
      (_ctx.props.showToggle)
        ? (_openBlock(), _createBlock(_component_cc_toggle, {
            key: 0,
            label: _ctx.props.toggleLabel,
            onInputToggled: _ctx.toggleClicked
          }, null, 8, ["label", "onInputToggled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_expand_button, {
        "show-button": _ctx.props.showExpandButton,
        onClick: _ctx.expandClicked
      }, null, 8, ["show-button", "onClick"]),
      _renderSlot(_ctx.$slots, "toolbar-primary-button"),
      (_ctx.props.exportOptions.length)
        ? (_openBlock(), _createBlock(_component_export_button, {
            key: 1,
            options: _ctx.props.exportOptions,
            onExportClicked: _ctx.exportClicked
          }, null, 8, ["options", "onExportClicked"]))
        : _createCommentVNode("", true),
      (_ctx.props.extraOptions.length)
        ? (_openBlock(), _createBlock(_component_extra_options_button, {
            key: 2,
            extraOptions: _ctx.props.extraOptions,
            onOptionClicked: _ctx.optionClicked
          }, null, 8, ["extraOptions", "onOptionClicked"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}