import { ActionTree, GetterTree, MutationTree } from 'vuex'
import RotasService from '@/services/rotas/Rotas'

export type RotaGetter = GetterTree<RotaState, any>

export interface RotaState {
  summary: any
}

export const state: RotaState = {
  summary: null,
}

export const getters: RotaGetter = {
  getSummary: (state) => state.summary,
}

export const actions: ActionTree<RotaState, any> = {
  getSummary: (context, params) => {
    let results = Promise.resolve(RotasService.getShiftSummary(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setSummary', response.data)
    })

    return results
  },
  getProjects: () => {
    return Promise.resolve(RotasService.getProjects())
  },
}

export const mutations: MutationTree<RotaState> = {
  setSummary: (state, summary) => (state.summary = summary),
}

export const rotas = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
