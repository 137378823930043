
  import { useStore } from 'vuex'
  import { defineComponent, Ref, ref } from 'vue'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import AcknowledgementsTable from './partials/AcknowledgementsTable.vue'
  import { Document } from '@/types/modules/documents/Document'
  export default defineComponent({
    components: {
      CcButton,
      AcknowledgementsTable,
    },
    setup() {
      const store = useStore()
      const documentIds = ref([]) as Ref<number[]>

      const acknowledge = () => {
        let data = new FormData()
        documentIds.value.forEach((id) => {
          data.append('document_ids[]', id.toString())
        })

        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('documents/storeAcknowledgements', data)
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      const updateDocumentsToAcknowledge = (documents: Document[]) => {
        documentIds.value = documents.map((document: Document) => document.id)
      }

      return {
        goBack,
        documentIds,
        acknowledge,
        updateDocumentsToAcknowledge,
      }
    },
  })
