<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('ypDetails.contactDetails.address1')"
          v-model="contact.address_1"
        />
        <text-input
          :label="t('ypDetails.contactDetails.address2')"
          v-model="contact.address_2"
        />
        <text-input
          :label="t('ypDetails.contactDetails.postCode')"
          v-model="contact.post_code"
        />
        <text-input
          :label="t('ypDetails.contactDetails.phone')"
          v-model="contact.telephone_number"
        />
        <text-input
          :label="t('ypDetails.contactDetails.fax')"
          v-model="contact.fax_number"
          :isRequired="false"
        />
        <text-input
          :label="t('ypDetails.contactDetails.email')"
          v-model="contact.email"
          :isRequired="false"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <text-box
          lines="3"
          :label="t('ypDetails.contactDetails.prevAddress')"
          :isRequired="false"
          v-model="contact.previous_address"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      TextBox,
      SelectInput,
      DateInput,
    },
    setup() {
      const { t } = useI18n()
      let contact = {
        address_1: 'Address 1',
        address_2: 'Address 2',
        post_code: 'Post code',
        telephone_number: '0455893485948',
        fax_number: '',
        email: 'hello@email.com',
        start_date: '2000-08-09',
        end_date: '',
        previous_address:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam quae quas quos velit aliquam delectus odit maxime excepturi vitae ut et ',
      }

      return {
        contact,
        t,
      }
    },
  })
</script>
