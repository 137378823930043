
  import { defineComponent, PropType, reactive } from 'vue'
  import CcButton from './Button.vue'
  import DropDown from '../Dropdown/DropDown.vue'
  import { ExportOption } from '@/types/components/Export'
  import DropdownItem from '../Dropdown/DropdownItem.vue'

  export default defineComponent({
    components: {
      DropDown,
      CcButton,
      DropdownItem,
    },
    props: {
      options: {
        type: Array as PropType<ExportOption[]>,
        required: false,
        default: () => [
          { name: 'Export To PDF', icon: 'fas fa-file-pdf' },
          { name: 'Export To Excel', icon: 'fas fa-file-excel' },
        ],
      },
    },
    emits: ['export-clicked'],
    setup(props, { emit }) {
      props = reactive(props)

      const optionClicked = (option: string) => emit('export-clicked', option)

      return {
        props,
        optionClicked,
      }
    },
  })
