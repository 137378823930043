import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        "is-disabled": true,
        "is-required": true,
        label: _ctx.t('schoolDetails.summary.id'),
        modelValue: _ctx.payload.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.id) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('schoolDetails.summary.name'),
        error: _ctx.props.errors?.name,
        "is-required": true,
        modelValue: _ctx.payload.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.name) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('schoolDetails.summary.administrator'),
        modelValue: _ctx.payload.administrator_id,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.administrator_id) = $event)),
        error: _ctx.props.errors?.administrator_id,
        "is-required": true,
        options: _ctx.users
      }, null, 8, ["label", "modelValue", "error", "options"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('schoolDetails.summary.personInCharge'),
        modelValue: _ctx.payload.person_in_charge_id,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.person_in_charge_id) = $event)),
        error: _ctx.props.errors?.person_in_charge_id,
        "is-required": true,
        options: _ctx.users
      }, null, 8, ["label", "modelValue", "error", "options"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('schoolDetails.summary.minAgeGroup'),
        "is-required": false,
        modelValue: _ctx.payload.minimum_age_group,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.minimum_age_group) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('schoolDetails.summary.maxAgeGroup'),
        "is-required": false,
        modelValue: _ctx.payload.maximum_age_group,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.maximum_age_group) = $event))
      }, null, 8, ["label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_number_input, {
        label: _ctx.t('schoolDetails.summary.pupilNum'),
        "is-required": false,
        modelValue: _ctx.payload.number_of_pupils,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.number_of_pupils) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('schoolDetails.summary.careGroup'),
        modelValue: _ctx.payload.company_id,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.company_id) = $event)),
        error: _ctx.props.errors?.company_id,
        "is-required": true,
        options: _ctx.careGroups
      }, null, 8, ["label", "modelValue", "error", "options"]),
      (_ctx.regions.length)
        ? (_openBlock(), _createBlock(_component_select_input, {
            key: 0,
            label: _ctx.t('schoolDetails.summary.region'),
            modelValue: _ctx.payload.region_id,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.region_id) = $event)),
            "is-required": false,
            options: _ctx.regions
          }, null, 8, ["label", "modelValue", "options"]))
        : _createCommentVNode("", true),
      _createVNode(_component_date_input, {
        label: _ctx.t('schoolDetails.summary.startDate'),
        error: _ctx.props.errors?.start_date,
        "is-required": true,
        modelValue: _ctx.payload.start_date,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.start_date) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('schoolDetails.summary.endDate'),
        error: _ctx.props.errors?.end_date,
        "is-required": false,
        modelValue: _ctx.payload.end_date,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.end_date) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_text_box, {
        label: _ctx.t('schoolDetails.summary.description'),
        "is-required": false,
        modelValue: _ctx.payload.description,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.description) = $event))
      }, null, 8, ["label", "modelValue"])
    ])
  ]))
}