
  import { computed, defineComponent, PropType } from 'vue'
  import TextInput from '../TextInput.vue'

  export default defineComponent({
    components: {
      TextInput,
    },
    props: {
      value: {
        type: String,
        required: false,
      },
      fieldAttributes: {
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      const formattedValue = computed(() => {
        if (typeof props.value === 'string') {
          if (props.value.includes('||')) {
            return props.value.split('||').join(', ')
          }

          if (props.value.includes(',')) {
            return props.value.split(',').join(', ')
          }

          return props.value
        }

        return ''
      })

      return {
        formattedValue,
      }
    },
  })
