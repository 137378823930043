
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import {
    defineComponent,
    PropType,
    reactive,
    ref,
    computed,
    watch,
    Ref,
  } from 'vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SignaturePad from 'signature_pad'
  import FileDropzone from '../FileDropzone.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import { getApiUrl } from '@/composables/Generic'

  export default defineComponent({
    components: {
      CcButton,
      FileDropzone,
      ButtonGroup,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const canvas = ref()
      const error = ref() as Ref<string | undefined>

      const state = reactive({
        showForm: false,
        signaturePad: null as SignaturePad | null,
        placeholder: '/placeholder.jpg',
        src: '',
        size: props.fieldAttributes?.size || 'small',
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': error.value,
          'text-gray-400': !error.value,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const closeForm = () => {
        state.showForm = false
      }

      const showForm = () => {
        state.showForm = true
      }

      const initSignaturePad = () => {
        state.signaturePad = new SignaturePad(canvas.value)
      }

      const clearImage = () => {
        state.signaturePad?.clear()
        state.src = ''
      }

      const text = computed(() => {
        return props.fieldAttributes?.value
          ? 'Change Signature'
          : 'Add Signature'
      })

      watch(
        () => state.showForm,
        () => {
          if (state.showForm) {
            initSignaturePad()
          }
        }
      )

      const validateInput = () => {
        if (state.src === '' && props.fieldAttributes.required == true) {
          error.value = 'This field is required'
          return false
        }

        error.value = ''
        return true
      }

      watch(
        () => state.src,
        () => {
          if (validateInput() && isDataURL(state.src)) {
            let copiedData = JSON.parse(JSON.stringify(props.fieldAttributes))
            copiedData.value = state.src

            emit('value-updated', {
              form_field_id: props.fieldAttributes.id,
              system_name: props.fieldAttributes.field?.system_name,
              row_index: props.fieldAttributes.row_index,
              value: state.src ? getBlobFromDataUrl(state.src) : undefined,
            })
          }
        }
      )

      const getBlobFromDataUrl = (dataUrl: string) => {
        const blobBin = atob(dataUrl.split(',')[1])
        const arrayBin = []

        for (let i = 0; i < blobBin.length; i++) {
          arrayBin.push(blobBin.charCodeAt(i))
        }

        return new Blob([new Uint8Array(arrayBin)], { type: 'image/png' })
      }

      const isDataURL = (value: string) => {
        return !!value.match(
          /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
        )
      }

      const saveImage = () => {
        if (state.signaturePad?.isEmpty()) {
          state.src = ''
          return
        }

        state.src = state.signaturePad?.toDataURL()!
      }

      const isJsonString = (json: string) => {
        try {
          const result = JSON.parse(json)

          if (result && typeof result === 'object') {
            return true
          }
        } catch (e) {}

        return false
      }

      const getImage = computed(() => {
        if (state.src) {
          return state.src
        }

        if (!props.fieldAttributes?.value) {
          state.src = state.placeholder
        }

        if (Array.isArray(props.fieldAttributes?.value)) {
          state.src = `/view_file?file_id=${props.fieldAttributes?.value[0].fileID}&module=signature`
        }

        if (typeof props.fieldAttributes?.value === 'string') {
          if (isDataURL(props.fieldAttributes?.value)) {
            state.src = props.fieldAttributes?.value
          }

          if (isJsonString(props.fieldAttributes?.value)) {
            let file = JSON.parse(props.fieldAttributes?.value)[0]
            state.src =
              getApiUrl() + `/view_file?file_id=${file.fileID}&module=signature`
          }

          if (props.fieldAttributes?.value.includes('http')) {
            state.src =
              getApiUrl() +
              '/' +
              props.fieldAttributes?.value.substring(
                props.fieldAttributes?.value.indexOf('view_file') + 1
              )
          } else {
            state.src = getApiUrl() + props.fieldAttributes?.value
          }
        }

        return state.src
      })

      const setFileSrc = (event: File) => {
        let fileReader = new FileReader()

        fileReader.onload = () => {
          if (fileReader.result && typeof fileReader.result == 'string') {
            state.src = fileReader.result
          }
        }
        fileReader.readAsDataURL(event)
      }

      return {
        setFileSrc,
        clearImage,
        text,
        state,
        props,
        labelClasses,
        closeForm,
        showForm,
        canvas,
        getImage,
        saveImage,
        error,
      }
    },
  })
