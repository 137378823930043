import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_young_person_table = _resolveComponent("young-person-table")!
  const _component_young_people_settings = _resolveComponent("young-people-settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTab('young-people'))
      ? (_openBlock(), _createBlock(_component_young_person_table, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_young_people_settings, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}