
  import { CareGroupImage } from '@/types/modules/care_groups/CareGroupImage'
  import { defineComponent, PropType } from 'vue'
  import DropDown from '@/components/Generic/Dropdown/DropDown.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'

  export default defineComponent({
    emits: ['rename'],
    components: {
      DropDown,
      TextInput,
    },
    props: {
      image: {
        type: Object as PropType<CareGroupImage>,
        required: true,
      },
    },
  })
