
  import { reactive, defineComponent } from 'vue'
  import FormHeaderStatus from './FormHeaderStatus.vue'
  export default defineComponent({
    components: {
      FormHeaderStatus,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
        default: 'in-progress',
        validator: (value: string) =>
          ['complete', 'in-progress', 'not-started'].indexOf(value) !== -1,
      },
      showCollapse: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: ['collapse-toggle'],
    setup(props, { emit }) {
      props = reactive(props)
      const state = reactive({ isCollapsed: false })
      const formHeader = [
        'bg-white',
        'border-b-4',
        'border-blue-200',
        'flex',
        'font-body',
        'items-center',
        'justify-between',
        'p-1.5',
        'w-full',
        'cursor-pointer',
      ]

      const toggleCollapse = () => {
        state.isCollapsed = !state.isCollapsed
        emit('collapse-toggle', state.isCollapsed)
      }

      return {
        props,
        state,
        toggleCollapse,
        formHeader,
      }
    },
  })
