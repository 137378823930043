import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "flex items-center h-screen w-full font-display" }
const _hoisted_2 = { class: "flex relative flex-1 bg-white h-full flex-col justify-around items-center py-4 w-2/3 mx-auto" }
const _hoisted_3 = { class: "flex flex-col items-left space-y-10 w-2/3 mx-auto lg:max-w-md" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "font-bold text-gray-400 text-3xl tracking-slight" }
const _hoisted_6 = { class: "font-extrabold text text-5xl tracking-slight" }
const _hoisted_7 = {
  key: 0,
  class: "mb-8 rounded-md bg-red-100 border-2 border-red-400 w-full py-4 px-2 font-display"
}
const _hoisted_8 = { class: "text-red-400 font-semibold" }
const _hoisted_9 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_logo = _resolveComponent("cc-logo")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_cc_footer = _resolveComponent("cc-footer")!
  const _component_splash_image = _resolveComponent("splash-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_cc_logo, {
            class: "block mb-6 max-w-[250px] mx-auto",
            image: _ctx.getters.clientLogo
          }, null, 8, ["image"]),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t('login.heading')), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.t('login.tagline')), 1)
        ]),
        (_ctx.state.hasErrors)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('login.error')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_text_input, {
            class: "mb-6",
            label: "Username",
            modelValue: _ctx.state.payload.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.payload.username) = $event)),
            error: _ctx.errors?.username,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errors?.username ? (_ctx.errors.username = undefined) : ''))
          }, null, 8, ["modelValue", "error"]),
          _createVNode(_component_text_input, {
            class: "mb-6",
            type: "password",
            label: "Password",
            error: _ctx.errors?.password,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors?.password ? (_ctx.errors.password = undefined) : '')),
            modelValue: _ctx.state.payload.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.payload.password) = $event)),
            onKeyup: _withKeys(_withModifiers(_ctx.login, ["stop"]), ["enter"])
          }, null, 8, ["error", "modelValue", "onKeyup"]),
          _createVNode(_component_cc_button, {
            onButtonClicked: _ctx.login,
            "is-disabled": _ctx.state.disableLoginAttempt,
            "full-width": true,
            label: "Login",
            icon: "fas fa-sign-in-alt"
          }, null, 8, ["onButtonClicked", "is-disabled"])
        ])
      ]),
      _createVNode(_component_cc_footer)
    ]),
    _createVNode(_component_splash_image, {
      class: "hidden lg:flow-root border-blue-400 border-l-4",
      image: _ctx.getters.clientSplashImage
    }, null, 8, ["image"])
  ]))
}