import { EnComponents } from "@/locales/messages/components";

export default {
    forms: {
        header: 'Forms',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                name: EnComponents.grid.headers.name,
                description: EnComponents.grid.headers.description,
                usedFor: 'Used For',
                status: EnComponents.grid.headers.status,
                emailNotifications: 'Email Notifications'
            }
        }
    }
}