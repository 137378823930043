import { ActionTree, GetterTree, MutationTree } from 'vuex'
import StaffsService from '@/services/staffs/staffs'
import { StaffProcessFile } from '@/types/modules/staffs/StaffProcessFile'
import { EvidenceType } from '@/types/modules/staffs/EvidenceType'
import { DbsStatus } from '@/types/modules/staffs/DbsStatus'
import { OverseasCriminalRecordStatus } from '@/types/modules/staffs/OverseasCriminalRecordStatus'
import { Dbs } from '@/types/modules/staffs/Dbs'
import { ProofOfIdentity } from '@/types/modules/staffs/ProofOfIdentity'
import { OverseasCriminalRecord } from '@/types/modules/staffs/OverseasCriminalRecord'
import { RightToWork } from '@/types/modules/staffs/RightToWork'

export type StaffGetter = GetterTree<StaffState, any>

export interface StaffState {
  recruitmentCheckFiles: StaffProcessFile[]
  employeeContracts: object[]
  contractualEvents: object[]
  evidenceTypes: EvidenceType[]
  dbsStatuses: DbsStatus[]
  overseasCriminalRecordStatuses: OverseasCriminalRecordStatus[]
  recruitmentCheckHistory:
    | Dbs[]
    | ProofOfIdentity[]
    | OverseasCriminalRecord[]
    | RightToWork[]
    | []
}

export const state: StaffState = {
  recruitmentCheckFiles: [],
  employeeContracts: [],
  contractualEvents: [],
  evidenceTypes: [] as EvidenceType[],
  dbsStatuses: [] as DbsStatus[],
  overseasCriminalRecordStatuses: [] as OverseasCriminalRecordStatus[],
  recruitmentCheckHistory: [],
}

export const getters: StaffGetter = {
  recruitmentCheckFiles: (state) => state.recruitmentCheckFiles,
  employeeContracts: (state) => state.employeeContracts,
  contractualEvents: (state) => state.contractualEvents,
  evidenceTypes: (state) => state.evidenceTypes,
  dbsStatuses: (state) => state.dbsStatuses,
  overseasCriminalRecordStatuses: (state) =>
    state.overseasCriminalRecordStatuses,
  recruitmentCheckHistory: (state) => state.recruitmentCheckHistory,
}

export const actions: ActionTree<StaffState, any> = {
  getRecruitmentCheckFiles: async (context, params) => {
    const response = await StaffsService.getRecruitmentCheckFiles(params)

    if (response.success && params?.per_page) {
      context.commit('setRecruitmentCheckFiles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setRecruitmentCheckFiles', response.data)

      return response
    }

    return response
  },
  getEvidenceTypes: async (context, params) => {
    const response = await StaffsService.getEvidenceTypes(params)

    if (response.success) {
      context.commit('setEvidenceTypes', response.data)
    }
    return response
  },
  getDbsStatuses: async (context, params) => {
    const response = await StaffsService.getDbsStatuses(params)

    if (response.success) {
      context.commit('setDbsStatuses', response.data)
    }
    return response
  },
  getOverseasCriminalRecordStatuses: async (context, params) => {
    const response = await StaffsService.getOverseasCriminalRecordStatuses(
      params
    )

    if (response.success) {
      context.commit('setOverseasCriminalStatuses', response.data)
    }
    return response
  },
  getSensitiveRecruitmentCheckFiles: async (context, params) => {
    const response = await StaffsService.getSensitiveRecruitmentCheckFiles(
      params
    )

    if (response.success && params?.per_page) {
      context.commit('setRecruitmentCheckFiles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setRecruitmentCheckFiles', response.data)

      return response
    }

    return response
  },
  getRecruitmentCheckHistory: async (context, params) => {
    const response = await StaffsService.getRecruitmentCheckHistory(params)

    if (response.success && params?.per_page) {
      context.commit('setRecruitmentCheckHistory', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setRecruitmentCheckHistory', response.data)

      return response
    }

    return response
  },
  getEmployeeContractRecords: async (context, params) => {
    const response = await StaffsService.getEmployeeContractRecords(params)

    if (response.success && params?.per_page) {
      context.commit('setEmployeeContracts', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setEmployeeContracts', response.data)

      return response
    }

    return response
  },
  getContractualEvents: async (context) => {
    const response = await StaffsService.getContractualEvents()

    if (response.success) {
      context.commit('setContractualEvents', response.data)
      return response
    }

    return response
  },
}

export const mutations: MutationTree<StaffState> = {
  setRecruitmentCheckFiles: (state, files: StaffProcessFile[]) =>
    (state.recruitmentCheckFiles = files),
  setRecruitmentCheckHistory: (
    state,
    files: Dbs[] | OverseasCriminalRecord[] | ProofOfIdentity[] | RightToWork[]
  ) => (state.recruitmentCheckHistory = files),
  setEmployeeContracts: (state, contracts) => {
    state.employeeContracts = contracts
  },
  setContractualEvents: (state, events) => {
    state.contractualEvents = events
  },
  setEvidenceTypes: (state, data) => {
    state.evidenceTypes = data
  },
  setDbsStatuses: (state, data) => {
    state.dbsStatuses = data
  },
  setOverseasCriminalStatuses: (
    state,
    statuses: OverseasCriminalRecordStatus[]
  ) => {
    state.overseasCriminalRecordStatuses = statuses
  },
}

export const staffs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
