import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_header = _resolveComponent("form-header")!

  return (_openBlock(), _createBlock(_component_form_header, {
    onCollapseToggle: _ctx.collapseToggle,
    title: _ctx.props.value,
    status: 'complete'
  }, null, 8, ["onCollapseToggle", "title"]))
}