import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_procedures_index = _resolveComponent("procedures-index")!
  const _component_forms_index = _resolveComponent("forms-index")!
  const _component_form_settings = _resolveComponent("form-settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTab('procedures'))
      ? (_openBlock(), _createBlock(_component_procedures_index, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('forms'))
      ? (_openBlock(), _createBlock(_component_forms_index, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('hidden-forms'))
      ? (_openBlock(), _createBlock(_component_forms_index, {
          key: 2,
          hidden: true
        }))
      : _createCommentVNode("", true),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_form_settings, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}