
  import { defineComponent, PropType, reactive, useSlots, computed } from 'vue'
  import DxFileUploader from 'devextreme-vue/file-uploader'
  const UUID = require('uuid-js')
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      DxFileUploader,
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      uploadText: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      allowedExtensions: {
        type: Array as PropType<string[]>,
        required: true,
        default: () => [],
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: ['files-uploaded'],
    setup(props, { emit }) {
      const slots = useSlots()
      props = reactive(props)

      const id = `file-uploader-${UUID.create().toString()}`

      const validFiles = (files: File[]) => {
        if (!props.allowedExtensions.length) {
          throw new Error('No allowed extensions provided')
        }

        let valid = false

        return files.filter((file: File) => {
          valid = false

          props.allowedExtensions.forEach((extension: string) => {
            if (file.name.endsWith(extension)) {
              valid = true
            }
          })

          return valid
        })
      }

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-400': true,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const uploadFiles = (e: any) => {
        emit('files-uploaded', validFiles(e.value))
      }

      return {
        labelClasses,
        props,
        uploadFiles,
        slots,
        id,
      }
    },
  })
