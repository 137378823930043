
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    onMounted,
    watch,
    Ref,
    ref,
  } from 'vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SelectInput from '../SelectInput.vue'
  import { useStore } from 'vuex'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import format from 'date-fns/format'
  import { Project } from '@/types/modules/projects/Project'
  import InputLabel from './../../InputLabel.vue'
  import { TaskLog } from '@/types/modules/tasks/TaskLog'

  export default defineComponent({
    components: {
      CcButton,
      SelectInput,
      InputLabel,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const availableProjects = ref([]) as Ref<Project[]>
      const project = ref() as Ref<string>
      const type = ref() as Ref<string>

      const availableLogs = ref([]) as Ref<TaskLog[]>
      const selectedLogs = ref([]) as Ref<TaskLog[]>

      const showForm = ref(false) as Ref<boolean>
      const toggleFormVisibility = () => (showForm.value = !showForm.value)

      const addToSelectedLogs = (task: any) => selectedLogs.value.push(task)

      const removeFromSelectedLogs = (task: any) => {
        selectedLogs.value = selectedLogs.value.filter((selectedTask: any) => {
          return selectedTask.id !== task.id
        })
      }

      const logs = computed(() => {
        if (availableLogs.value.length) {
          return availableLogs.value.filter((log: TaskLog) => {
            let found = selectedLogs.value.find((selectedLog: TaskLog) => {
              return Number(selectedLog.id) === Number(log.id)
            })

            return found ? false : true
          })
        }

        return null
      })

      const types = computed(() => {
        if (store.getters['projects/types']) {
          return store.getters['projects/types'].map((type: ProjectType) => {
            return {
              label: type.name,
              value: type.name,
            }
          })
        }

        return null
      })

      const projects = computed(() => {
        if (availableProjects.value.length) {
          return availableProjects.value.map((project: Project) => {
            return {
              label: project.name,
              value: project.id,
            }
          })
        }

        return []
      })

      const getProjects = (params: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/index', params)
          .then((response) => {
            if (response.success) {
              availableProjects.value = response.data
            }
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const getTaskLogs = (params: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('taskLogs/index', params)
          .then((response) => {
            if (response.success) {
              availableLogs.value = response.data
            }
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      watch(type, () => getProjects({ type: [type.value] }))

      watch(project, () => getTaskLogs({ project_id: project.value }))

      watch(
        selectedLogs,
        () => {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            row_index: props.fieldAttributes.row_index,
            value: selectedLogs.value.length
              ? selectedLogs.value.map((log: TaskLog) => log.id)
              : null,
          })
        },
        { deep: true }
      )

      onMounted(() => {
        if (props.fieldAttributes?.related_tasks) {
          selectedLogs.value = props.fieldAttributes?.related_tasks
        }

        if (!store.getters['projects/types'].length) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('projects/getProjectTypes')
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      return {
        showForm,
        toggleFormVisibility,
        props,
        types,
        type,
        projects,
        project,
        logs,
        format,
        addToSelectedLogs,
        removeFromSelectedLogs,
        selectedLogs,
      }
    },
  })
