import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_school_summary = _resolveComponent("school-summary")!
  const _component_school_contact_details = _resolveComponent("school-contact-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_chips, {
      chips: _ctx.state.chips,
      errors: _ctx.state.chipsWithErrors,
      "default-chip": _ctx.activeChip,
      onChipClicked: _ctx.chipClicked
    }, null, 8, ["chips", "errors", "default-chip", "onChipClicked"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_toolbar, {
        class: "flex justify-end",
        onSubmitClicked: _ctx.submitClicked,
        onExportClicked: _ctx.exportClicked
      }, null, 8, ["onSubmitClicked", "onExportClicked"])
    ]),
    _createVNode(_component_validation_errors, {
      errors: _ctx.state.errors
    }, null, 8, ["errors"]),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Summary')
        ? (_openBlock(), _createBlock(_component_school_summary, {
            key: 0,
            school: _ctx.props.school,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["school", "errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Contact Details')
        ? (_openBlock(), _createBlock(_component_school_contact_details, {
            key: 0,
            school: _ctx.props.school,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["school", "errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024))
  ], 64))
}