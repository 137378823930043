import { GetterTree, MutationTree, ActionTree } from 'vuex'
import EvidenceTypeService from '@/services/staffs/evidenceTypes'
import { EvidenceType } from '@/types/modules/staffs/EvidenceType'

export type EvidenceTypeGetter = GetterTree<EvidenceTypeState, any>

export interface EvidenceTypeState {
  evidenceTypes: EvidenceType[]
  evidenceType: EvidenceType | null
}

export const state: EvidenceTypeState = {
  evidenceTypes: [],
  evidenceType: null,
}

export const getters: EvidenceTypeGetter = {
  getEvidenceTypes: (state) => state.evidenceTypes,
  getEvidenceType: (state) => state.evidenceType,
}

export const actions: ActionTree<EvidenceTypeState, any> = {
  index: async (context, params) => {
    const response = await EvidenceTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setEvidenceTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setEvidenceTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(EvidenceTypeService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setEvidenceType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(EvidenceTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(EvidenceTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(EvidenceTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(EvidenceTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(EvidenceTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(EvidenceTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(EvidenceTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<EvidenceTypeState> = {
  setEvidenceTypes: (state, types: EvidenceType[]) =>
    (state.evidenceTypes = types),
  setEvidenceType: (state, type: EvidenceType) => (state.evidenceType = type),
}

export const evidenceTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
