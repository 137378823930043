import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "genericStore/getLookupsToBeDeleted",
    columns: _ctx.columns,
    "allow-selection": false,
    "allow-column-chooser": false,
    "allow-filtering": false,
    "allow-grouping": false,
    "remote-operations": {},
    "export-options": []
  }, {
    "toolbar-addon": _withCtx(() => [
      _createVNode(_component_cc_button, {
        label: "Back",
        type: "tertiary",
        icon: "fa fa-arrow-left",
        "secondary-icon": "none",
        onClick: _ctx.goBack
      }, null, 8, ["onClick"])
    ]),
    "toolbar-primary-button": _withCtx(() => [
      _createVNode(_component_cc_button, {
        type: "danger-outline",
        label: "Delete",
        icon: "fa fa-trash",
        onClick: _ctx.deleteRecords
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["columns"]))
}