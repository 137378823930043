import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { CalendarEventStatus } from '@/types/modules/calendar/CalendarEventStatus'
import EventStatusService from '@/services/calendar/eventStatuses'

export type CalendarEventStatusGetter = GetterTree<
  CalendarEventStatusState,
  any
>

export interface CalendarEventStatusState {
  eventStatuses: CalendarEventStatus[]
  eventStatus: CalendarEventStatus | null
}

export const state: CalendarEventStatusState = {
  eventStatuses: [],
  eventStatus: null,
}

export const getters: CalendarEventStatusGetter = {
  getEventStatuses: (state) => state.eventStatuses,
  getEventStatus: (state) => state.eventStatus,
}

export const actions: ActionTree<CalendarEventStatusState, any> = {
  index: async (context, params) => {
    const response = await EventStatusService.index(params)

    if (response.success && params.per_page) {
      context.commit('setEventStatuses', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setEventStatuses', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(EventStatusService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setEventStatus', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(EventStatusService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(EventStatusService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(EventStatusService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(EventStatusService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(EventStatusService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(EventStatusService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(EventStatusService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<CalendarEventStatusState> = {
  setEventStatuses: (state, statuses: CalendarEventStatus[]) =>
    (state.eventStatuses = statuses),
  setEventStatus: (state, status: CalendarEventStatus) =>
    (state.eventStatus = status),
}

export const eventStatuses = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
