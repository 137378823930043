import { ProofOfIdentity } from '@/types/modules/staffs/ProofOfIdentity'
import EmployeeProofOfIdentityService from '@/services/projects/employees/employeeProofOfIdentity'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface EmployeeProofOfIdentityState {
  proofOfIdentity: ProofOfIdentity | null
}

export const state: EmployeeProofOfIdentityState = {
  proofOfIdentity: null,
}

export const getters: GetterTree<EmployeeProofOfIdentityState, any> = {
  proofOfIdentity: (state) => state.proofOfIdentity,
}

export const actions: ActionTree<EmployeeProofOfIdentityState, any> = {
  show: (context, params) => {
    const response = Promise.resolve(
      EmployeeProofOfIdentityService.show(params.id, params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setProofOfIdentity', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = Promise.resolve(
      EmployeeProofOfIdentityService.store(payload.id, payload.data)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setProofOfIdentity', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<EmployeeProofOfIdentityState> = {
  setProofOfIdentity: (state, proofOfIdentity: ProofOfIdentity) =>
    (state.proofOfIdentity = proofOfIdentity),
}

export const employeeProofOfIdentity = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
