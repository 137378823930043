
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { computed, defineComponent } from 'vue'
  import CcAvatar from '../Generic/Avatar/Avatar.vue'
  import CcHeadIcon from '../Generic/HeadIcon/HeadIcon.vue'
  import CcBreadcrumb from '../Generic/Breadcrumb/Breadcrumb.vue'
  import SearchBar from '../Generic/Search/SearchBar.vue'

  export default defineComponent({
    components: {
      CcAvatar,
      CcHeadIcon,
      CcBreadcrumb,
      SearchBar,
    },
    setup() {
      const store = useStore()
      const getters = computed(() => {
        return {
          crumbs: store.getters['genericStore/breadCrumbs'],
          url: store.getters['genericStore/pageAvatar'],
          taskId: store.getters['genericStore/pageTaskId'],
          icon: store.getters['genericStore/icon'],
        }
      })

      const currentRoute = computed(() => useRoute().name)
      const isDetailedRecord = computed(() =>
        currentRoute.value?.toString().includes('Show')
      )

      return {
        getters,
        currentRoute,
        isDetailedRecord,
      }
    },
  })
