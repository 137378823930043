import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end font-display mb-4" }
const _hoisted_2 = { class: "p-6 shadow-md border border-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_setting_section = _resolveComponent("setting-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_cc_button, {
        icon: "fas fa-save",
        label: "save",
        "is-disabled": !Object.keys(_ctx.payload).length,
        onButtonClicked: _ctx.saveSettings
      }, null, 8, ["is-disabled", "onButtonClicked"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settings, (section, index) => {
        return (_openBlock(), _createBlock(_component_setting_section, {
          key: index,
          data: section,
          onSettingUpdated: _ctx.pushSettingToPayload,
          errors: _ctx.errors ? _ctx.errors : undefined
        }, null, 8, ["data", "onSettingUpdated", "errors"]))
      }), 128))
    ])
  ], 64))
}