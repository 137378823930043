
  import RotaTimeline from '../partials/RotaTimeline.vue'
  import RotaDateRange from '../partials/RotaDateRange.vue'
  import { DxResource, DxView } from 'devextreme-vue/scheduler'
  import { useStore } from 'vuex'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    watch,
  } from 'vue'
  import { Project } from '@/types/modules/projects/Project'
  import {
    sharedState,
    loadDatesBetweenDates,
    getDefaultShiftColour,
  } from '@/composables/rotas/Rotas'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import startOfISOWeek from 'date-fns/startOfISOWeek/index'
  import { format } from 'date-fns/esm'
  import { endOfISOWeek } from 'date-fns'

  export default defineComponent({
    components: {
      RotaDateRange,
      DxResource,
      DxView,
      RotaTimeline,
      MultiSelect,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const getShifts = (params: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        return store.dispatch('employeeShifts/index', params).finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
        })
      }
      const allShiftTypes = () => {
        store.dispatch('genericStore/showPageLoader', true)
        return store
          .dispatch('shiftTypes/index')
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }
      const state = reactive({
        shifts: [] as any[],
        timeline: {
          current_date: new Date() as any,
          start_date: startOfISOWeek(new Date()) as any,
          end_date: endOfISOWeek(new Date()) as any,
        },
        shift_types: null as any,
        filtered_shift_types: [],
      })

      watch(
        () => state.filtered_shift_types,
        (value) => {
          let select_types = value.map((type: string) => {
            let shiftType = state.shift_types.find(
              (shiftType: any) => shiftType.name === type
            )

            return shiftType.id
          })

          state.shifts.forEach((shift: any) => {
            if (
              !select_types.includes(shift.shift_type_id) &&
              select_types.length > 0
            ) {
              shift.hidden = true
            } else {
              shift.hidden = false
            }
          })
        }
      )

      const loadShifts = (project_id: any, start_date: any, end_date: any) => {
        state.shifts = []

        getShifts({
          start: start_date,
          end: end_date,
          employee_project_id: project_id,
        }).then((response) => {
          response.data.forEach((shift: any) => {
            state.shifts.push({
              id: shift.id ? shift.id : null,
              valid_job_titles: shift.valid_job_titles
                ? shift.valid_job_titles
                : [],
              project_name: shift.project ? shift.project.name : null,
              text: setShiftText(shift),
              default_shift_id: shift.id ? shift.id : null,
              employee: shift.employee ? shift.employee : null,
              shift_type_id: shift.shift_type ? shift.shift_type.id : null,
              shift_type_name: shift.shift_type ? shift.shift_type.name : null,
              shift_type_icon:
                shift.shift_type && shift.shift_type.tag_icon
                  ? shift.shift_type.tag_icon.icon
                  : null,
              shift_type: shift.shift_type ? shift.shift_type : null,
              image:
                shift.project && shift.project.image
                  ? shift.project.image.thumbnail
                  : '',
              snapshot: shift.snapshot ? shift.snapshot : null,
              shift_start: shift.shift_start,
              shift_end: shift.shift_end,
              color: getDefaultShiftColour(shift),
              address:
                shift.project && shift.project.home
                  ? shift.project.home.address
                  : null,
              hidden: false,
            })
          })
        })
      }

      const computedShiftTypes = computed(() => {
        if (state.shift_types) {
          return state.shift_types.map((type: any) => {
            return {
              text: type.name,
            }
          })
        }
        return []
      })

      const handlePopupModal = (data: any) => (data.cancel = true)

      const changeDate = (event: { selected_date: Date }) => {
        state.shifts = []

        state.timeline.current_date = event.selected_date
        state.timeline.start_date = startOfISOWeek(
          new Date(event.selected_date)
        )
        state.timeline.end_date = endOfISOWeek(new Date(event.selected_date))

        loadDatesBetweenDates(
          state.timeline.start_date,
          state.timeline.end_date
        )

        return loadShifts(
          props.project?.id,
          format(state.timeline.start_date, 'yyyy-MM-dd'),
          format(state.timeline.end_date, 'yyyy-MM-dd')
        )
      }

      const setShiftText = (shift: any) => {
        return shift.project.name
      }

      const getShiftTypes = () => {
        store.dispatch('genericStore/showPageLoader', true)
        allShiftTypes()
          .then((result) => (state.shift_types = result.data))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const init = () => {
        getShiftTypes()
        state.timeline.start_date = startOfISOWeek(new Date())
        state.timeline.end_date = endOfISOWeek(new Date())

        loadDatesBetweenDates(
          state.timeline.start_date,
          state.timeline.end_date
        )

        loadShifts(
          props.project?.id,
          format(state.timeline.start_date, 'yyyy-MM-dd'),
          format(state.timeline.end_date, 'yyyy-MM-dd')
        )
      }

      onMounted(() => {
        init()
      })

      return {
        changeDate,
        handlePopupModal,
        state,
        sharedState,
        computedShiftTypes,
      }
    },
  })
