import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_drop_down = _resolveComponent("drop-down")!

  return (_openBlock(), _createBlock(_component_drop_down, null, {
    button: _withCtx(() => [
      _createVNode(_component_cc_button, {
        type: "secondary",
        label: "Export",
        icon: "fas fa-file-export",
        secondaryIcon: "fas fa-angle-down",
        isDisabled: false
      })
    ]),
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.options, (option, index) => {
        return (_openBlock(), _createBlock(_component_dropdown_item, {
          key: index,
          onClick: ($event: any) => (_ctx.optionClicked(option.name)),
          icon: option.icon
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.name), 1)
          ]),
          _: 2
        }, 1032, ["onClick", "icon"]))
      }), 128))
    ]),
    _: 1
  }))
}