import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createBlock(_component_text_input, {
    isRequired: false,
    isDisabled: true,
    label: _ctx.fieldAttributes[0].field.name,
    modelValue: _ctx.formattedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formattedValue) = $event))
  }, null, 8, ["label", "modelValue"]))
}