
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import FileCategoriesTable from '../files/partials/FileCategoriesTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      FileCategoriesTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'File Categories',
        tabs: ['File Categories'],
      })

      return {
        state,
      }
    },
  })
