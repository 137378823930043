import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_young_person_types_table = _resolveComponent("young-person-types-table")!
  const _component_local_authorities_table = _resolveComponent("local-authorities-table")!
  const _component_legal_statuses_table = _resolveComponent("legal-statuses-table")!
  const _component_custom_field_groups_table = _resolveComponent("custom-field-groups-table")!
  const _component_custom_fields_table = _resolveComponent("custom-fields-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_cc_button, {
              key: tab,
              label: tab,
              type: _ctx.state.activeTab === tab ? 'tab-active' : 'tab-inactive',
              onClick: ($event: any) => (_ctx.state.activeTab = tab)
            }, null, 8, ["label", "type", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (_ctx.state.activeTab === 'Young Person Types')
      ? (_openBlock(), _createBlock(_component_young_person_types_table, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Local Authorities')
      ? (_openBlock(), _createBlock(_component_local_authorities_table, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Legal Statuses')
      ? (_openBlock(), _createBlock(_component_legal_statuses_table, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Custom Field Groups')
      ? (_openBlock(), _createBlock(_component_custom_field_groups_table, {
          key: 3,
          type: "Resident",
          "create-view": "YoungPersonProjectTypeSectionCreate",
          "edit-view": "YoungPersonProjectTypeSectionEdit",
          "delete-view": "YoungPersonProjectTypeSectionDelete",
          "restore-view": "YoungPersonProjectTypeSectionRestore"
        }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Custom Fields')
      ? (_openBlock(), _createBlock(_component_custom_fields_table, {
          key: 4,
          type: "Resident",
          "create-view": "YoungPersonProjectTypeAttributeCreate",
          "edit-view": "YoungPersonProjectTypeAttributeEdit",
          "delete-view": "YoungPersonProjectTypeAttributeDelete",
          "restore-view": "YoungPersonProjectTypeAttributeRestore"
        }))
      : _createCommentVNode("", true)
  ], 64))
}