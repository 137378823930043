
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    watch,
  } from 'vue'
  import { FieldAttribute } from '@/types/components/FieldAttribute'

  export default defineComponent({
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const value = ref()

      watch(value, () => {
        emit('value-updated', {
          form_field_id: props.fieldAttributes.id,
          row_index: props.fieldAttributes.row_index,
          system_name: props.fieldAttributes.field?.system_name,
          value: value.value,
        })
      })

      onMounted(() => {
        value.value = props.fieldAttributes.value
      })

      return {
        value,
        props,
      }
    },
  })
