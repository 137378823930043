
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      formStatus: {
        type: String,
        required: true,
        default: 'in-progress',
        validator: (value: string) =>
          ['complete', 'in-progress', 'not-started'].indexOf(value) !== -1,
      },
    },
    setup(props) {
      props = reactive(props)

      const icon = computed(() => ({
        'far': true,
        'fa-check-circle text-green-400': props.formStatus == 'complete',
        'fa-exclamation-circle text-red-400': props.formStatus == 'not-started',
        'fa-tasks text-orange-400': props.formStatus == 'in-progress',
      }))

      return {
        props,
        icon,
      }
    },
  })
