import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center divide-x divide-gray-200 font-body space-x-4" }
const _hoisted_2 = { class: "pl-4" }
const _hoisted_3 = {
  key: 0,
  class: "text-right pl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_pill = _resolveComponent("cc-pill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cc_pill, {
      type: _ctx.type,
      label: _ctx.statusText
    }, null, 8, ["type", "label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.valueClasses)
      }, _toDisplayString(_ctx.formattedDate), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.labelClasses)
      }, _toDisplayString(_ctx.taskStatus), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.labelClasses)
      }, _toDisplayString(_ctx.daysOverdue), 3)
    ]),
    (_ctx.taskId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.valueClasses)
          }, " #" + _toDisplayString(_ctx.taskId), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.labelClasses)
          }, " task id ", 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}