
  import { defineComponent, PropType, reactive } from 'vue'
  import { Form } from '@/types/modules/forms/Form'

  export default defineComponent({
    props: {
      form: {
        type: Object as PropType<Form>,
        required: true,
      },
      active: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
