
  import { reactive, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      label: {
        type: String,
        required: true,
      },
      useRouter: {
        type: Boolean,
        required: false,
        default: true,
      },
      href: {
        type: String,
        required: true,
      },
      lowercase: {
        type: Boolean,
        default: true,
      },
      icon: {
        type: String,
        required: false,
        default: '',
      },
      target: {
        type: String,
        required: false,
        default: '_self',
      },
      isSensitive: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      return { props }
    },
  })
