import BespokeReportDatasourcesService from '@/services/reporting/bespokeReportDatasources'
import { Datasource } from '@/types/modules/reporting/Datasource'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface BespokeReportDatasourceState {
  datasources: Datasource[]
  datasets: Record<string, any>[][]
  dataset: Record<string, any>[]
}

export const state: BespokeReportDatasourceState = {
  datasources: [],
  datasets: [],
  dataset: [],
}

export const getters: GetterTree<BespokeReportDatasourceState, any> = {
  datasources: (state: BespokeReportDatasourceState) => state.datasources,
  datasets: (state: BespokeReportDatasourceState) => state.datasets,
  dataset: (state: BespokeReportDatasourceState) => state.dataset,
}

export const actions: ActionTree<BespokeReportDatasourceState, any> = {
  index: async (context: any, id: number) => {
    const response = await BespokeReportDatasourcesService.index(id)

    if (response.success) {
      context.commit('setBespokeReportDatasources', response.data)
    }

    return response
  },
  loadDataset: async (context, payload) => {
    const response = await BespokeReportDatasourcesService.loadDataset(
      payload.id,
      payload
    )

    if (response.success) {
      context.commit('setDataset', response.data)
    }

    return response
  },
}

export const mutations: MutationTree<BespokeReportDatasourceState> = {
  setBespokeReportDatasources: (
    state: BespokeReportDatasourceState,
    datasources: Datasource[]
  ) => {
    state.datasources = datasources
  },
  setDatasets: (
    state: BespokeReportDatasourceState,
    datasets: Record<string, any>[][]
  ) => {
    state.datasets = datasets
  },
  setDataset: (
    state: BespokeReportDatasourceState,
    dataset: Record<string, any>[]
  ) => {
    state.dataset = dataset
  },
}

export const bespokeReportDatasources = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
