import { GetterTree, ActionTree, MutationTree } from 'vuex'
import TaskLogCommentsService from '@/services/tasks/taskLogComments'
import { TaskLogComment } from '@/types/modules/tasks/TaskLogComment'

export type TaskLogCommentGetter = GetterTree<TaskLogCommentState, any>

export interface TaskLogCommentState {
  taskLogComments: TaskLogComment[]
  taskLogComment: TaskLogComment | null
  selectedTaskLogComments: TaskLogComment[]
}

export const state: TaskLogCommentState = {
  taskLogComments: [],
  taskLogComment: null,
  selectedTaskLogComments: [],
}

export const getters: TaskLogCommentGetter = {
  getTaskLogComments: (state) => state.taskLogComments,
  getTaskLogComment: (state) => state.taskLogComment,
  getSelectedTaskLogComments: (state) => state.selectedTaskLogComments,
}

export const actions: ActionTree<TaskLogCommentState, any> = {
  index: async (context, params) => {
    let response = await TaskLogCommentsService.index(params)

    if (response.success && params.per_page) {
      context.commit('setComments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setComments', response.data)

      return response
    }

    return response
  },
  show: (context, id) => {
    const result = Promise.resolve(TaskLogCommentsService.show(id))

    result.then((response) => {
      if (response.success) {
        context.commit('setTaskLogComment', response.data)
      }
    })

    return result
  },
  store: (_context, payload) => {
    return Promise.resolve(TaskLogCommentsService.store(payload))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      TaskLogCommentsService.update(payload.id, payload.data)
    )
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(TaskLogCommentsService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(TaskLogCommentsService.restoreMany(payload))
  },
  getSelectedTaskLogComments: (context) => {
    const response = context.getters['getSelectedTaskLogComments']

    return {
      data: response,
      totalCount: response.length,
    }
  },
}

export const mutations: MutationTree<TaskLogCommentState> = {
  setComments: (state, comments: TaskLogComment[]) => {
    state.taskLogComments = comments
  },
  setTaskLogComment: (state, comment: TaskLogComment) => {
    state.taskLogComment = comment
  },
  setSelectedTaskLogComments: (state, comments: TaskLogComment[]) => {
    state.selectedTaskLogComments = comments
  },
}

export const taskLogComments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
