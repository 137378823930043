import { GetterTree, ActionTree, MutationTree } from 'vuex'
import TaskAccessLogService from '@/services/tasks/taskAccessLogs'
import { TaskAccessLog } from '@/types/modules/tasks/TaskAccessLog'

export type TaskAccessLogGetter = GetterTree<TaskAccessLogState, any>

export interface TaskAccessLogState {
  taskAccessLogs: TaskAccessLog[]
}

export const state: TaskAccessLogState = {
  taskAccessLogs: [],
}

export const getters: TaskAccessLogGetter = {
  getTaskAccessLogs: (state) => state.taskAccessLogs,
}

export const actions: ActionTree<TaskAccessLogState, any> = {
  index: async (context, params) => {
    let response = await TaskAccessLogService.index(params)

    if (response.success && params.per_page) {
      context.commit('setTaskAccessLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTaskAccessLogs', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<TaskAccessLogState> = {
  setTaskAccessLogs: (state, logs: TaskAccessLog[]) => {
    state.taskAccessLogs = logs
  },
}

export const taskAccessLogs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
