import { tasks } from './tasks'
import { taskLogs } from './taskLogs'
import { taskLogComments } from './taskLogComments'
import { taskAccessLogs } from './taskAccessLogs'

export default {
  tasks,
  taskLogs,
  taskLogComments,
  taskAccessLogs,
}
