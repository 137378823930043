import { GetterTree, MutationTree, ActionTree } from 'vuex'
import CareGroupRoleService from '@/services/care_groups/careGroupRoles'
import { CareGroupRole } from '@/types/modules/care_groups/CareGroupRole'

export type CareGroupRoleGetter = GetterTree<CareGroupRoleState, any>

export interface CareGroupRoleState {
  careGroupRoles: CareGroupRole[]
  careGroupRole: CareGroupRole | null
}

export const state: CareGroupRoleState = {
  careGroupRoles: [],
  careGroupRole: null,
}

export const getters: CareGroupRoleGetter = {
  getCareGroupRoles: (state) => state.careGroupRoles,
  getCareGroupRole: (state) => state.careGroupRole,
}

export const actions: ActionTree<CareGroupRoleState, any> = {
  index: async (context, params) => {
    const response = await CareGroupRoleService.index(params)

    if (response.success && params.per_page) {
      context.commit('setCareGroupRoles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setCareGroupRoles', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(CareGroupRoleService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setCareGroupRole', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(CareGroupRoleService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(CareGroupRoleService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(CareGroupRoleService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(CareGroupRoleService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(CareGroupRoleService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(CareGroupRoleService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(CareGroupRoleService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<CareGroupRoleState> = {
  setCareGroupRoles: (state, roles) => (state.careGroupRoles = roles),
  setCareGroupRole: (state, role) => (state.careGroupRole = role),
}

export const careGroupRoles = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
