
  import CountBadge from '@/components/Generic/Badge/CountBadge.vue'
  import { useStore } from 'vuex'
  import { onMounted, computed, reactive } from 'vue'
  import CalendarDay from '@/components/Generic/Calendar/CalendarDay.vue'
  import { Project } from '@/types/modules/projects/Project'
  import { useI18n } from 'vue-i18n'
  import DropDown from '@/components/Generic/Dropdown/DropDown.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DropdownItem from '@/components/Generic/Dropdown/DropdownItem.vue'
  import { AvailableFilter } from '@/store/modules/dashboard'
  import CardGrid from '@/components/Generic/Grid/CardGrid.vue'
  import TaskCardGrid from './partials/TaskCardGrid.vue'

  export default {
    components: {
      CardGrid,
      CountBadge,
      CalendarDay,
      DropDown,
      ButtonGroup,
      CcButton,
      DropdownItem,
      TaskCardGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const authUser = computed(() => store.getters['auth/authenticatedUser'])
      const state = reactive({
        availableFilters: [
          { id: 0, name: 'All Homes & Schools' },
        ] as AvailableFilter[],
        activeProject: 0,
      })

      const setupPage = () => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '#' },
            {
              name: t('dashboard.breadcrumb', {
                username: authUser.value.first_name,
              }),
              url: '#',
            },
          ],
        })
      }

      const activeFilter = computed(
        () => store.getters['dashboard/activeFilter']
      )
      const projectParents = computed(() => store.getters['projects/parents'])

      const approvalsCount = computed(() => {
        if (
          store.getters['taskLogs/taskLogs'] &&
          store.getters['taskLogs/taskLogs'].totalCount
        ) {
          return store.getters['taskLogs/taskLogs'].totalCount
        }
        return null
      })

      const missedTasksCount = computed(() => {
        if (
          store.getters['tasks/tasks'] &&
          store.getters['tasks/tasks'].totalCount
        ) {
          return store.getters['tasks/tasks'].totalCount
        }
        return null
      })

      const changeItem = (item: AvailableFilter) => {
        store.dispatch('dashboard/setActiveFilter', item)
        state.activeProject = item.id
      }

      onMounted(() => {
        setupPage()
        store
          .dispatch('projects/getAllowedParents', {
            only: ['parents'],
          })
          .then((response) => {
            response.data
              .map((project: Project) => {
                return { id: project.id, name: project.name }
              })
              .forEach((item: AvailableFilter) =>
                state.availableFilters.push(item)
              )
          })
      })

      return {
        projectParents,
        approvalsCount,
        state,
        authUser,
        t,
        changeItem,
        activeFilter,
        missedTasksCount,
      }
    },
  }
