
  import {
    defineComponent,
    reactive,
    computed,
    PropType,
    onMounted,
    watch,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import NumberInput from '@/components/Generic/Forms/Inputs/NumberInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { School } from '@/types/modules/projects/School'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'
  import { Region } from '@/types/modules/regions/Region'
  import { useI18n } from 'vue-i18n'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      TextInput,
      SelectInput,
      DateInput,
      TextBox,
      NumberInput,
    },
    props: {
      school: {
        type: Object as PropType<School>,
        required: true,
      },
      errors: {
        type: Object,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      props = reactive(props)
      const payload = reactive({
        id: undefined,
        name: undefined,
        administrator_id: undefined,
        person_in_charge_id: undefined,
        minimum_age_group: undefined,
        maximum_age_group: undefined,
        number_of_pupils: undefined,
        company_id: undefined,
        region_id: undefined,
        start_date: undefined,
        end_date: undefined,
        description: undefined,
      })

      const users = computed(() => {
        return store.getters['users/users'].map((user: User) => {
          return { label: user.username, value: user.id }
        })
      })

      const regions = computed(() => {
        return store.getters['regions/regions'].map((region: Region) => {
          return { label: region.name, value: region.id }
        })
      })

      const careGroups = computed(() => {
        return store.getters['careGroups/careGroups'].map(
          (careGroup: CareGroup) => {
            return {
              label: careGroup.company_name,
              value: careGroup.company_id,
            }
          }
        )
      })

      const mapToPayload = () => {
        Object.assign(payload, {
          id: props.school.id,
          name: props.school.project?.name,
          administrator_id: props.school.project?.administrator_id,
          person_in_charge_id: props.school.person_in_charge_id,
          minimum_age_group: props.school.minimum_age_group,
          maximum_age_group: props.school.maximum_age_group,
          number_of_pupils: props.school.number_of_pupils,
          company_id: props.school.project?.company_id,
          region_id: props.school.project?.region_id,
          start_date: props.school.project?.start_date
            ? format(new Date(props.school.project?.start_date), 'yyyy-MM-dd')
            : undefined,
          end_date: props.school.project?.end_date
            ? format(new Date(props.school.project?.end_date), 'yyyy-MM-dd')
            : undefined,
          description: props.school.project?.description,
        })
      }

      watch(payload, () => emit('update-payload', payload))

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        mapToPayload()
        Promise.all([
          store.dispatch('users/index'),
          store.dispatch('regions/index'),
          store.dispatch('careGroups/index').then((response) => {
            if (response.data.length === 1) {
              payload.company_id = response.data[0].company_id
            }
          }),
        ]).finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        users,
        regions,
        props,
        t,
        payload,
        careGroups,
      }
    },
  })
