import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bcbff59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-h-40 overflow-hidden overflow-y-scroll divide-y divide-solid pr-2 has-custom-scroll" }
const _hoisted_2 = { class: "text-base font-display font-semibold" }
const _hoisted_3 = { class: "text-1xs font-display font-semibold text-gray-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item,
        class: "flex items-center space-x-2 py-4"
      }, [
        _createVNode(_component_cc_avatar, {
          url: item.img
        }, null, 8, ["url"]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(item.name), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.info), 1)
        ])
      ]))
    }), 128))
  ]))
}