import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_dx_toolbar = _resolveComponent("dx-toolbar")!
  const _component_dx_html_editor = _resolveComponent("dx-html-editor")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.hasErrors ? 'border-l-4 border-red-300 pl-3' : '')
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass([_ctx.labelClasses, "mb-1"])
        }, [
          (_ctx.props.fieldAttributes.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_dx_html_editor, {
      "element-attr": {
        class: 'large-text-html-editor',
        required: _ctx.props.fieldAttributes.required ? true : false,
      },
      modelValue: _ctx.props.fieldAttributes.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.fieldAttributes.value) = $event)),
      height: "300px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxValidator, null, {
          default: _withCtx(() => [
            (_ctx.props.fieldAttributes.required == true)
              ? (_openBlock(), _createBlock(_component_DxRequiredRule, {
                  key: 0,
                  "element-attr": { class: 'bg-green-200' },
                  message: "This field is required"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_dx_toolbar, {
          multiline: true,
          items: _ctx.toolbarItems
        }, null, 8, ["items"])
      ]),
      _: 1
    }, 8, ["element-attr", "modelValue"])
  ], 2))
}