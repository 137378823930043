
  import { defineComponent, reactive, ref, onMounted, watch } from 'vue'
  import TextInput from './TextInput.vue'
  import SelectInput from './SelectInput.vue'

  export default defineComponent({
    components: {
      TextInput,
      SelectInput,
    },
    props: {
      period: {
        type: String,
        required: false,
        default: 'months',
      },
      value: {
        type: [String, Number],
        required: false,
        default: 1,
      },
    },
    emits: ['update'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({}) as Record<string, any>

      const options = [
        { label: 'seconds', value: 'seconds' },
        { label: 'minutes', value: 'minutes' },
        { label: 'hours', value: 'hours' },
        { label: 'days', value: 'days' },
        { label: 'weeks', value: 'weeks' },
        { label: 'months', value: 'months' },
      ]

      watch(
        payload,
        () => {
          emit('update', payload.value)
        },
        { deep: true }
      )

      onMounted(() => {
        payload.value.saved_unit = props.period
        payload.value.value = props.value
      })

      return {
        options,
        props,
        payload,
      }
    },
  })
