
  import { defineComponent, reactive } from 'vue'
  import YoungPersonTable from '../../projects/young_people/partials/YoungPersonTable.vue'

  export default defineComponent({
    components: { YoungPersonTable },
    props: {
      regionId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
