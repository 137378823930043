export default {
  dateRanges: {
    today: 'Today',
    pastWeek: 'Past Week',
    pastMonth: 'Past Month',
    pastSixMonths: 'Past Six Months',
    pastYear: 'Past Year',
    all: 'All',
    customDateRange: 'Custom Date Range',
  },
}
