import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-4 mb-8 gap-4"
}
const _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-4 mb-8 gap-4"
}
const _hoisted_3 = { class: "space-x-4 grid grid-cols-3 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overview_card = _resolveComponent("overview-card")!
  const _component_widget_container = _resolveComponent("widget-container")!
  const _component_add_chart = _resolveComponent("add-chart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.tasksLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskCounts, (count, index) => {
            return (_openBlock(), _createBlock(_component_overview_card, {
              onClick: ($event: any) => (_ctx.redirectToTasksOverview(count)),
              key: `overview-card-${index}`,
              value: count.total,
              icon: count.icon,
              label: count.group,
              type: count.type
            }, null, 8, ["onClick", "value", "icon", "label", "type"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskGroups, (tasks, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["placeholder", _ctx.placeholderClasses]),
              key: index
            }, _toDisplayString(tasks), 3))
          }), 128))
        ])),
    _createVNode(_component_widget_container, { widgets: _ctx.widgets }, null, 8, ["widgets"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_add_chart, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-chart')))
      })
    ])
  ], 64))
}