import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["required", "disabled", "value", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-display w-full", _ctx.hasErrors ? 'border-l-4 border-red-300 pl-3' : ''])
  }, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: _ctx.error,
      "is-required": _ctx.isRequired,
      "is-disabled": _ctx.isDisabled
    }, null, 8, ["label", "error", "is-required", "is-disabled"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("textarea", _mergeProps({
        required: _ctx.isRequired,
        disabled: _ctx.isDisabled,
        class: _ctx.inputClasses
      }, _ctx.$attrs, {
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        value: _ctx.modelValue,
        innerHTML: _ctx.modelValue
      }), null, 16, _hoisted_2)
    ]),
    (_ctx.message && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}