import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get(`projects/${params.project_id}/associations`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: async (payload: any) => {
    return axios
      .put(
        `projects/${payload.projectId}/associations/${payload.associationId}`,
        payload
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: async (payload: any) => {
    return axios
      .post(`projects/${payload.id}/associations`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: async (payload: any) => {
    return axios
      .post(`projects/${payload.project_id}/associations/delete-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post(`projects/${payload.project_id}/associations/restore-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
