
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, reactive, watch } from 'vue'
  import FilingCabinet from '../partials/FilingCabinet/FilingCabinet.vue'
  import ProjectAttachments from '../partials/Attachments/ProjectAttachments.vue'
  import UserPermissions from '../partials/UserPermissions/UserPermissions.vue'
  import ProjectAssociations from '../partials/ProjectAssociations/ProjectAssociations.vue'
  import { isTab } from '@/composables/Generic'
  import VehicleDetails from './partials/VehicleDetails.vue'
  import { Vehicle } from '@/types/modules/projects/Vehicle'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      VehicleDetails,
      FilingCabinet,
      ProjectAttachments,
      UserPermissions,
      ProjectAssociations,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const vehicle = computed((): Vehicle => store.getters['vehicles/vehicle'])

      const tabs = [
        { name: 'Filing Cabinet', url: `${route.path}?tab=filing-cabinet` },
        { name: 'Details', url: `${route.path}?tab=details` },
        {
          name: 'Vehicle Attachments',
          url: `${route.path}?tab=vehicle-attachments`,
        },
        { name: 'Log Attachments', url: `${route.path}?tab=log-attachments` },
        { name: 'User Permissions', url: `${route.path}?tab=user-permissions` },
        { name: 'Associations', url: `${route.path}?tab=associations` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: t('vehicles.breadcrumb'), url: '/projects/vehicles' },
        ]

        if (!vehicle.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: vehicle.value ? vehicle.value.project?.name! : '',
          url: `/projects/vehicles/${vehicle.value.id}`,
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'filing-cabinet' } })
        }

        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: tabs })

        store
          .dispatch('vehicles/show', { id: props.id })
          .finally(() => {
            store.commit('genericStore/setBreadcrumbs', {
              crumbs: crumbs.value,
            })
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        vehicle,
        isTab,
      }
    },
  })
