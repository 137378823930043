
  import CcAvatar from '@/components/Generic/Avatar/Avatar.vue'
  import { Project } from '@/types/modules/projects/Project'
  import { computed, defineComponent, PropType } from 'vue'
  export default defineComponent({
    components: {
      CcAvatar,
    },
    props: {
      data: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    setup(props) {
      const id = computed(() => props.data.id)
      const project = computed((): Project => props.data.project)

      return {
        id,
        project,
      }
    },
  })
