
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { EmployeeJobTitle } from '@/types/modules/projects/EmployeeJobTitle'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const state = reactive({
        selectedRows: [] as EmployeeJobTitle[],
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Name',
          dataField: 'name',
          width: 200,
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
      ])

      const filters = computed(() => {
        return {
          only: ['trashed'],
        }
      })

      const rowsSelected = (rows: EmployeeJobTitle[]) =>
        (state.selectedRows = rows)

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('employeeJobTitles/restoreMany', {
            ids: state.selectedRows.map((row: EmployeeJobTitle) => row.id),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => goBack())
      }

      return {
        t,
        state,
        goBack,
        columns,
        filters,
        restore,
        rowsSelected,
      }
    },
  })
