
  import {
    computed,
    defineComponent,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import SelectBox from '@/components/Generic/Forms/Inputs/SelectBox.vue'
  import { RuntimeParameter } from '@/types/modules/reporting/Datasource'
  import { SelectOption } from '@/types/components/SelectOption'

  export default defineComponent({
    components: {
      SelectBox,
    },
    props: {
      parameter: {
        type: Object as PropType<RuntimeParameter>,
        required: true,
      },
    },
    emits: ['update:parameter'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({
        data_type: '',
        default: '',
        friendly_name: '',
        friendly_operator: '',
        name: '',
        operator: '',
        options: '',
        value: '',
      }) as Ref<RuntimeParameter>

      const options = computed((): SelectOption[] => {
        return props.parameter?.options
          .split(',')
          .concat(props.parameter?.default)
          .filter((option: string) => option)
          .map((option: string) => {
            return {
              label: option,
              value: option,
            }
          })
      })

      watch(
        props.parameter,
        (parameter: RuntimeParameter) => {
          payload.value = {
            ...parameter,
            value: parameter.default || 'Any',
          }
        },
        { immediate: true }
      )

      watch(payload.value, () => emit('update:parameter', payload.value), {
        immediate: true,
      })

      return {
        options,
        payload,
      }
    },
  })
