import store from '@/store'

export const canViewModule = (module: string) => {
  return (
    store.getters['auth/authenticatedUserPermissions'][module] === 'RO' ||
    store.getters['auth/authenticatedUserPermissions'][module] === 'RW'
  )
}

export const canEditModule = (module: string) => {
  return store.getters['auth/authenticatedUserPermissions'][module] === 'RW'
}

export const cannotViewModule = (module: string) => {
  return !canViewModule(module)
}

export const cannotEditModule = (module: string) => {
  return !canEditModule(module)
}
