import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field-drag-item flex items-center space-x-2 p-2 bg-white" }
const _hoisted_2 = { class: "text-gray-500 font-semibold text-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["rounded-lg px-2 py-2", _ctx.props.data.icon_bg_class])
    }, [
      _createElementVNode("i", {
        class: _normalizeClass([_ctx.props.data.icon, 'fa-fw'])
      }, null, 2)
    ], 2),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.props.data.name), 1)
  ]))
}