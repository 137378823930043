import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59472c45"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-template-id"]
const _hoisted_2 = { class: "tooltip-container" }
const _hoisted_3 = {
  key: 0,
  class: "delete"
}
const _hoisted_4 = { class: "shift-image" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "shift-text" }
const _hoisted_7 = {
  key: 1,
  class: "shift-job-title"
}
const _hoisted_8 = {
  key: 2,
  class: "shift-job-title"
}
const _hoisted_9 = { class: "shift-time" }
const _hoisted_10 = { class: "shift-type" }
const _hoisted_11 = {
  key: 3,
  class: "shift-tags"
}
const _hoisted_12 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_dx_tooltip = _resolveComponent("dx-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    "data-template-id": _ctx.state.templateIdentifier
  }, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.template('employee') && _ctx.canDelete)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DxButton, {
              icon: "trash",
              onClick: _cache[0] || (_cache[0] = 
            (e) =>
              _ctx.$emit('delete', {
                event: e,
                cellData: _ctx.props.cellData,
              })
          )
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _ctx.state.imageLoading ? _ctx.state.defaultImage : _ctx.state.shift.image,
          alt: _ctx.state.shift.text,
          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setDefaultImage && _ctx.setDefaultImage(...args))),
          onLoad: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.imageLoading = false))
        }, null, 40, _hoisted_5)
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.state.shift.text), 1),
      (!_ctx.template('employee') && _ctx.state.shift.snapshot)
        ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.historicalJobTitle), 1)
          ]))
        : (
          !_ctx.template('employee') &&
          _ctx.state.shift.employee_project_id &&
          _ctx.state.shift.valid_job_titles
        )
          ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(_ctx.expectedJobTitles), 1)
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.startTime) + " - " + _toDisplayString(_ctx.endTime), 1),
      _createElementVNode("span", _hoisted_10, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.state.shift.shift_type_icon)
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(_ctx.state.shift.shift_type_name), 1)
      ]),
      (!_ctx.template('employee') && _ctx.state.tags.length)
        ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tags, (tag, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: `tag-${index}`,
                id: tag.tag_name,
                onMouseenter: _cache[4] || (_cache[4] = () => (_ctx.state.showToolTip = true)),
                onMouseleave: _cache[5] || (_cache[5] = () => (_ctx.state.showToolTip = false))
              }, [
                _createVNode(_component_dx_tooltip, {
                  visible: _ctx.state.showToolTip,
                  "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.showToolTip) = $event)),
                  target: `#${tag.tag_name}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("i", {
                      class: _normalizeClass(tag.tag_icon)
                    }, null, 2),
                    _createTextVNode(" " + _toDisplayString(tag.tag_name), 1)
                  ]),
                  _: 2
                }, 1032, ["visible", "target"])
              ], 40, _hoisted_12))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}