import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('companies/roles', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any, params: any) => {
    return axios
      .get(`companies/roles/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('companies/roles', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: any, payload: any) => {
    return axios
      .post(`companies/roles/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  delete: (id: any) => {
    return axios
      .delete(`companies/roles/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  saveOrderings: (payload: any) => {
    return axios
      .post(`companies/roles/orderings`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: (payload: any) => {
    return axios
      .post('companies/roles/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: (payload: any) => {
    return axios
      .post('companies/roles/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  toggleManyVisibility: (payload: any) => {
    return axios
      .post('companies/roles/toggle-visibility', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
