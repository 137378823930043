import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = { class: "flex items-center space-x-4 mb-8" }
const _hoisted_3 = { class: "border-r border-gray-200 px-4" }
const _hoisted_4 = { class: "h-max p-6" }
const _hoisted_5 = { class: "flex items-center space-x-8 mb-8" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "fa fa-xmark fa-xl text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cc_button, {
            icon: "fas fa-arrow-left",
            "secondary-icon": "none",
            type: "tertiary",
            label: _ctx.t('components.buttons.back'),
            onClick: _ctx.goBack
          }, null, 8, ["label", "onClick"])
        ]),
        _createVNode(_component_cc_button, {
          label: "Add",
          icon: "fa fa-plus",
          onClick: _ctx.addLocation
        }, null, 8, ["onClick"]),
        _createVNode(_component_cc_button, {
          icon: "fa-solid fa-save",
          type: "success",
          label: "Save",
          onClick: _ctx.submitPayload
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payload, (location, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_text_input, {
              label: "cabinet name",
              "is-required": true,
              modelValue: location.name,
              "onUpdate:modelValue": ($event: any) => ((location.name) = $event),
              error: !location.name ? 'Please provide a name' : undefined
            }, null, 8, ["modelValue", "onUpdate:modelValue", "error"]),
            (_ctx.canDeleteLocation(location))
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "fa fa-xmark fa-xl text-red-300 cursor-pointer",
                  title: "Delete",
                  onClick: ($event: any) => (_ctx.deleteLocation(location.id, index))
                }, null, 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("i", _hoisted_7))
          ])
        ]))
      }), 128))
    ])
  ], 64))
}