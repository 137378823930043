
  import { defineComponent, onMounted, computed, reactive } from 'vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import { useStore } from 'vuex'
  import { Project } from '@/types/modules/projects/Project'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import Toggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import { validatePayload } from '@/composables/Validation'
  import { object, date, number } from 'yup'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { ProjectAssociation } from '@/types/modules/projects/ProjectAssociation'
  import { format } from 'date-fns'

  export default defineComponent({
    components: {
      PageToolbar,
      CcButton,
      Toggle,
      DateInput,
      ValidationErrors,
      TextInput,
    },
    props: {
      projectType: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      associationId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const state = reactive({
        errors: {} as Record<string, string>,
      })
      const payload = reactive({
        is_default: 0,
        admission_date: undefined,
        leaving_date: undefined,
        association_name: undefined,
      }) as Record<string, any>
      const projectInstance = computed(() => {
        return props.projectType === 'young_people'
          ? store.getters[`youngPeople/getYoungPerson`]
          : store.getters[
              `${props.projectType}/${props.projectType.slice(0, -1)}`
            ]
      })

      const associations = computed(
        () => store.getters['projectAssociations/projectAssociations']
      )
      const association = computed((): ProjectAssociation | null => {
        if (associations.value.length) {
          return associations.value?.find(
            (association: Project) =>
              String(association.id) === props.associationId
          )
        }

        return null
      })

      const inputToggled = (value: boolean) => {
        payload.is_default = Number(value)
      }

      const validationSchema = object({
        admission_date: date()
          .typeError('Please select an admission date')
          .required('Please select an admission date'),
        leaving_date: date()
          .nullable()
          .typeError('Please select a leaving date'),
        is_default: number()
          .oneOf([0, 1])
          .typeError('Please select a default association'),
      })

      const validate = async () => {
        const errors = await validatePayload(
          validationSchema,
          payload as Record<string, any>
        )
        state.errors = errors ? errors : {}
        return !errors
      }

      const submitClicked = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (await validate()) {
          await store
            .dispatch('projectAssociations/update', {
              associationId: props.associationId,
              projectId: projectInstance.value.project_id,
              ...payload,
            })
            .catch((error) => {
              store.dispatch('genericStore/pushNotification', error.message)
              state.errors = error.data
            })
            .then((response) => {
              if (response.success) {
                goBack()
              }
            })
        }
        store.dispatch('genericStore/showPageLoader', false)
      }

      const exportClicked = (option: string) => {
        if (option === 'Export To Excel') {
          exportPageToExcel(
            payload,
            `Edit Association - ${association.value?.association?.name}`
          )
        }

        if (option === 'Export To PDF') {
          exportPageToPdf(
            payload,
            `Edit Association - ${association.value?.association?.name}`
          )
        }
      }

      const mapToPayload = () => {
        payload.association_name = association.value?.association?.name
        payload.is_default = association.value?.is_default
        payload.admission_date = association.value?.admission_date
          ? format(new Date(association.value?.admission_date), 'yyyy-MM-dd')
          : null
        payload.leaving_date = association.value?.leaving_date
          ? format(new Date(association.value?.leaving_date), 'yyyy-MM-dd')
          : null
      }

      const getProjectInstance = async () => {
        await store
          .dispatch(
            `${
              props.projectType === 'young_people'
                ? 'youngPeople'
                : props.projectType
            }/show`,
            { id: props.id }
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getAssociations = async () => {
        await store
          .dispatch('projectAssociations/index', {
            project_id: projectInstance.value.project_id,
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (!projectInstance.value) {
          await getProjectInstance()
        }
        if (!associations.value?.length) {
          await getAssociations()
        }
        mapToPayload()
        store.dispatch('genericStore/showPageLoader', false)
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/' },
            { name: 'Vehicles', url: '/projects/vehicles' },
            {
              name: projectInstance.value.project?.name,
              url: `/projects/${props.projectType}/${props.id}`,
            },
            {
              name: 'Associations',
              url: `/projects/${props.projectType}/${props.id}?tab=associations`,
            },
            {
              name: `Edit Association`,
              url: '#',
            },
          ],
        })
      })

      return {
        props,
        goBack,
        payload,
        inputToggled,
        submitClicked,
        exportClicked,
        state,
        association,
        associations,
      }
    },
  })
