import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects/homes', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (params: any) => {
    return axios
      .get(`projects/homes/${params.id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (payload: any) => {
    return axios
      .put(`projects/homes/${payload.id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
