import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_select = _resolveComponent("multi-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "is-required": false,
        label: "Filter by project status",
        "tag-items": [
          { text: 'Current', value: 'current' },
          { text: 'Past', value: 'past' },
          { text: 'Planned', value: 'planned' },
        ],
        "display-expr": 'text',
        "value-expr": 'value',
        modelValue: _ctx.state.filters.project_statuses,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.filters.project_statuses) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by associated homes",
        modelValue: _ctx.state.filters.projects,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.filters.projects) = $event)),
        "tag-items": _ctx.homes
      }, null, 8, ["modelValue", "tag-items"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        isRequired: false,
        label: "Filter by associated schools",
        modelValue: _ctx.state.filters.projects,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.filters.projects) = $event)),
        "tag-items": _ctx.schools
      }, null, 8, ["modelValue", "tag-items"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by associated residents",
        modelValue: _ctx.state.filters.projects,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.filters.projects) = $event)),
        "tag-items": _ctx.youngPeople
      }, null, 8, ["modelValue", "tag-items"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by associated employees",
        modelValue: _ctx.state.filters.projects,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.filters.projects) = $event)),
        "tag-items": _ctx.employees
      }, null, 8, ["modelValue", "tag-items"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by associated vehicles",
        modelValue: _ctx.state.filters.projects,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.filters.projects) = $event)),
        "tag-items": _ctx.vehicles
      }, null, 8, ["modelValue", "tag-items"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by assigned users",
        "tag-items": _ctx.users,
        modelValue: _ctx.state.filters.assignees,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.filters.assignees) = $event))
      }, null, 8, ["tag-items", "modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by event type",
        "tag-items": _ctx.eventTypes,
        modelValue: _ctx.state.filters.event_types,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.filters.event_types) = $event))
      }, null, 8, ["tag-items", "modelValue"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_multi_select, {
        "display-expr": 'text',
        "value-expr": 'value',
        "is-required": false,
        label: "Filter by event statuses",
        "tag-items": _ctx.eventStatuses,
        modelValue: _ctx.state.filters.event_statuses,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.filters.event_statuses) = $event))
      }, null, 8, ["tag-items", "modelValue"])
    ])
  ]))
}