import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_card_grid_cell_template = _resolveComponent("task-card-grid-cell-template")!
  const _component_dx_paging = _resolveComponent("dx-paging")!
  const _component_dx_pager = _resolveComponent("dx-pager")!
  const _component_dx_data_grid = _resolveComponent("dx-data-grid")!

  return (_openBlock(), _createBlock(_component_dx_data_grid, {
    ref: "cardGrid",
    columns: [{ cellTemplate: 'TaskCardGridCellTemplate' }],
    "data-source": _ctx.dataSource,
    class: "cardGrid font-body",
    "show-borders": false,
    showRowLines: false,
    showColumnLines: false,
    "load-panel": { enabled: false },
    "remote-operations": {
      grouping: false,
      paging: true,
      groupPaging: false,
      filtering: false,
      sorting: false,
    }
  }, {
    TaskCardGridCellTemplate: _withCtx(({ data }) => [
      _createVNode(_component_task_card_grid_cell_template, {
        data: data.data
      }, null, 8, ["data"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_dx_paging, { "page-size": 2 }),
      _createVNode(_component_dx_pager, {
        "show-page-size-selector": false,
        "display-mode": "compact"
      })
    ]),
    _: 1
  }, 8, ["data-source"]))
}