import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createBlock(_component_text_input, {
    id: _ctx.props.fieldAttributes.id,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    isRequired: _ctx.props.fieldAttributes.required ? true : false,
    label: _ctx.label,
    message: _ctx.props.fieldAttributes.instructions,
    autocomplete: false,
    error: _ctx.error
  }, null, 8, ["id", "modelValue", "isRequired", "label", "message", "error"]))
}