
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, watch } from 'vue'
  import FilingCabinet from '../partials/FilingCabinet/FilingCabinet.vue'
  import YoungPersonDetails from './partials/YoungPersonDetails.vue'
  import YoungPersonRelationships from './partials/YoungPersonRelationships.vue'
  import YoungPersonPhotos from './partials/YoungPersonPhotos.vue'
  import ProjectAssociations from '../partials/ProjectAssociations/ProjectAssociations.vue'
  import ProjectAttachments from '../partials/Attachments/ProjectAttachments.vue'
  import UserPermissions from '../partials/UserPermissions/UserPermissions.vue'
  import { isTab } from '@/composables/Generic'
  import MedicationDiary from './partials/MedicationDiary.vue'
  import TaskAttachments from '../partials/Attachments/TaskAttachments.vue'

  export default defineComponent({
    components: {
      FilingCabinet,
      YoungPersonDetails,
      YoungPersonRelationships,
      ProjectAssociations,
      ProjectAttachments,
      UserPermissions,
      YoungPersonPhotos,
      MedicationDiary,
      TaskAttachments,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const youngPerson = computed(
        () => store.getters['youngPeople/getYoungPerson']
      )

      const tabs = [
        { name: 'Filing Cabinet', url: `${route.path}?tab=filing-cabinet` },
        { name: 'Details', url: `${route.path}?tab=details` },
        { name: 'Relationships', url: `${route.path}?tab=relationships` },
        { name: 'Medication Diary', url: `${route.path}?tab=medication-diary` },
        {
          name: 'Young People Attachments',
          url: `${route.path}?tab=yp-attachments`,
        },
        { name: 'Log Attachments', url: `${route.path}?tab=log-attachments` },
        { name: 'Photos', url: `${route.path}?tab=photos` },
        { name: 'User Permissions', url: `${route.path}?tab=user-permissions` },
        { name: 'Associations', url: `${route.path}?tab=associations` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Young People', url: '/projects/young_people' },
        ]

        if (!youngPerson.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: youngPerson.value.project.name,
          url: `/projects/young_people/${youngPerson.value.id}`,
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'filing-cabinet' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('youngPeople/show', props.id).finally(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        })
      })

      return {
        isTab,
        youngPerson,
      }
    },
  })
