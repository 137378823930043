
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
  } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { DocumentCategory } from '@/types/modules/documents/DocumentCategory'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      careGroup: {
        type: Object as PropType<CareGroup>,
        required: false,
      },
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const query = ref({})
      const state = reactive({
        activeChip: 'all' as string,
      })

      const action = computed(() => {
        return props.sensitive
          ? 'files/getSensitiveCareGroupAttachments'
          : 'files/getCareGroupAttachments'
      })

      const categories = computed(() => {
        return store.getters['fileCategories/categories']?.map(
          (category: DocumentCategory) => category.name
        )
      })

      const chips = computed((): string[] => {
        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { category: state.activeChip }),
          ...(route.params.id && { company_id: route.params.id }),
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'File Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'category.name',
          caption: 'Category',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      onMounted(() => {
        applyFilters()
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('fileCategories/index')
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        chips,
        state,
        query,
        action,
        columns,
        categories,
        chipClicked,
      }
    },
  })
