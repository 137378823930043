
  import { defineComponent, computed, reactive } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { cannotEditModule } from '@/composables/Permissions'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      hidden: {
        type: Boolean,
        required: false,
        default: false,
      },
      regionId: {
        type: String,
        required: false,
      },
    },

    setup(props) {
      props = reactive(props)
      const state = reactive({
        activeChip: 'All Procedures',
        chips: ['All Procedures', 'Active', 'Inactive'],
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'title',
          caption: 'Name',
          allowGrouping: false,
        },
        {
          dataField: 'description',
          caption: 'Description',
          allowGrouping: false,
        },
        {
          dataField: 'friendly_relates_to',
          caption: 'Relates To',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'status.name',
          caption: 'Status',
        },
        {
          dataField: 'recurs.name',
          caption: 'Recurs',
        },
      ])

      const chipClicked = (chip: string) => {
        state.activeChip = chip
      }

      const queryFilter = computed(() => {
        return {
          relations: ['recurs', 'status', 'form'],
          ...(props.regionId && { region_id: props.regionId }),
          ...(state.activeChip &&
            state.activeChip !== 'All Procedures' && {
              status: state.activeChip.toLowerCase(),
            }),
        }
      })

      return {
        props,
        state,
        columns,
        chipClicked,
        queryFilter,
        cannotEditModule,
      }
    },
  })
