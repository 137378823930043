import { computed, ref } from 'vue'
import { sharedState, loadDatesBetweenDates } from './Rotas'
import store from '@/store'
import { loadDefaultShifts } from './DefaultShifts'
import format from 'date-fns/format'
import { endOfISOWeek, startOfISOWeek } from 'date-fns'

export const setting = computed(
  () => store.getters['rotaSettings/getRotaSetting']
)

export const rotaSetting = ref({
  default_cycle_id: null,
  cycle_start_date: null as any,
  cycle_start_week: 1,
})

export const loadRotaSetting = (projectId: number) => {
  store
    .dispatch('rotaSettings/show', projectId)
    .then((response) => {
      if (response.data) {
        rotaSetting.value.default_cycle_id = response.data.default_cycle_id
          ? response.data.default_cycle_id
          : null
        rotaSetting.value.cycle_start_date = response.data.cycle_start_date
          ? response.data.cycle_start_date
          : format(new Date(), 'yyyy-MM-dd')
        rotaSetting.value.cycle_start_week = response.data.cycle_start_week
          ? response.data.cycle_start_week
          : 1
        sharedState.timeline.start_date = response.data.cycle_start_date
          ? startOfISOWeek(new Date(response.data.cycle_start_date))
          : startOfISOWeek(new Date())
        sharedState.timeline.end_date = response.data.cycle_start_date
          ? endOfISOWeek(new Date(response.data.cycle_start_date))
          : endOfISOWeek(new Date())
      }
    })
    .then(() => {
      let start_date = rotaSetting.value.cycle_start_date
        ? startOfISOWeek(new Date(rotaSetting.value.cycle_start_date))
        : startOfISOWeek(new Date())
      let end_date = rotaSetting.value.cycle_start_date
        ? endOfISOWeek(new Date(rotaSetting.value.cycle_start_date))
        : endOfISOWeek(new Date())
      loadDatesBetweenDates(start_date, end_date)
      loadDefaultShifts(projectId)
    })
    .then(() => {
      if (rotaSetting.value && rotaSetting.value.default_cycle_id) {
        loadCurrentWeekCycle(startOfISOWeek(new Date()), projectId)
      }
    })
}

const loadCurrentWeekCycle = (searchDate: Date, projectId: number) => {
  store
    .dispatch('rotaSettings/getCurrentWeekCycle', {
      project_id: projectId,
      search_date: format(searchDate, 'yyyy-MM-dd'),
    })
    .then((response) => {
      setting.value.cycle_start_week = response.data
      sharedState.step = response.data
    })
}
