
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      icon: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      props = reactive(props)

      const headIconClasses = computed(() => {
        return {
          'rounded-full': true,
          'h-20 w-20 min-w-20 min-h-20': true,
          'bg-green-400': true,
          'text-white': true,
          'text-3xl': true,
          'text-center': true,
          'flex': true,
          'justify-center': true,
          'items-center': true,
        }
      })

      return {
        props,
        headIconClasses,
      }
    },
  })
