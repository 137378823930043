import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('companies', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: async (id: number) => {
    return axios
      .get(`companies/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: async (payload: any) => {
    return axios
      .put(`companies/${payload.company_id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getCareGroupAuditLogs: async (params: any) => {
    return axios
      .get('companies/audit_logs', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getStakeholderAuditLogs: async (params: any) => {
    return axios
      .get('company_stakeholders/audit_logs', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getStakeholders: (params: any) => {
    return axios
      .get('company_stakeholders', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  getSplashImages: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Splash images returned successfully',
      data: [
        {
          id: 1,
          company_id: 18,
          name: 'Test Image 1',
          status: 1,
          file_path: 'Test_Image_1.jpg',
          url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
          created_at: '2022-04-14T09:08:10.000000Z',
          updated_at: '2022-04-14T09:08:10.000000Z',
          deleted_at: null,
        },
      ],
    }
  },
  deleteSplashImage: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Deleted splash image',
      data: {
        id: 1,
        company_id: 18,
        name: 'Test Image 1',
        status: 1,
        file_path: 'Test_Image_1.jpg',
        url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
        created_at: '2022-04-14T09:08:10.000000Z',
        updated_at: '2022-04-14T09:08:10.000000Z',
        deleted_at: Date.now(),
      },
    }
  },
  getLogoImages: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Logo images returned successfully',
      data: [
        {
          id: 1,
          company_id: 18,
          name: 'Test Image 1',
          status: 1,
          file_path: 'Test_Image_1.jpg',
          url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
          created_at: '2022-04-14T09:08:10.000000Z',
          updated_at: '2022-04-14T09:08:10.000000Z',
          deleted_at: null,
        },
      ],
    }
  },
  deleteLogoImage: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Deleted logo image',
      data: {
        id: 1,
        company_id: 18,
        name: 'Test Image 1',
        status: 1,
        file_path: 'Test_Image_1.jpg',
        url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
        created_at: '2022-04-14T09:08:10.000000Z',
        updated_at: '2022-04-14T09:08:10.000000Z',
        deleted_at: Date.now(),
      },
    }
  },
  uploadSplashImage: (file: File) => {
    file = file
    return {
      success: true,
      message: 'Splash image uploaded successfully',
      data: {
        name: 'Test Image 1',
        url: '',
      },
    }
  },
  getCareGroupPermissions: async (params: any) => {
    return axios
      .get('companies/permissions', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
