
  import { defineComponent, reactive } from 'vue'

  import RegionForm from '../partials/RegionForm.vue'

  export default defineComponent({
    props: {
      regionId: { type: String, required: true },
    },
    components: {
      RegionForm,
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
