
  import { useStore } from 'vuex'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { BespokeReportType } from '@/types/modules/reporting/BespokeReportType'
  import { SelectOption } from '@/types/components/SelectOption'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import Report from '../templates/Report.json'
  import Dashboard from '../templates/Dashboard.json'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    emits: ['report-details-updated'],
    components: {
      TextBox,
      TextInput,
      SelectInput,
    },
    props: {
      report: {
        type: Object as PropType<BespokeReport | PublishedBespokeReport | null>,
        required: false,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const payload = ref({
        name: '',
        description: '',
      }) as Ref<{
        name: string
        description: string
        report_type_id?: number
        definition?: string
      }>

      const reportTypes = computed((): SelectOption[] =>
        store.getters['bespokeReportTypes/types']?.map(
          (type: BespokeReportType) => ({
            label: type.name,
            value: type.id,
          })
        )
      )

      const shouldShowReportTypeSelector = computed(
        () =>
          route.name === 'BespokeReportEdit' ||
          route.name === 'BespokeReportCreate'
      )

      const setReportDefinition = (typeId: string) => {
        const type = store.getters['bespokeReportTypes/types'].find(
          (type: BespokeReportType) => type.id === Number(typeId)
        )

        payload.value.report_type_id = Number(typeId)
        payload.value.definition =
          type?.name === 'Data Focused Report'
            ? JSON.stringify(Report)
            : JSON.stringify(Dashboard)
      }

      const handleReportTypeChange = (value: string) =>
        setReportDefinition(value)

      const mapToPayload = (report: BespokeReport | PublishedBespokeReport) => {
        if (route.name === 'BespokeReportPublishedEdit') {
          let reportToMap = report as PublishedBespokeReport
          payload.value = {
            name: reportToMap.name,
            description: reportToMap.description,
          }
        } else {
          let reportToMap = report as BespokeReport
          payload.value = {
            name: reportToMap.name,
            description: reportToMap.description,
            report_type_id: reportToMap.type_id,
            definition: reportToMap.definition,
          }
        }
      }

      watch(
        () => payload.value,
        () => emit('report-details-updated', payload.value),
        { deep: true }
      )
      watch(
        () => props.report,
        () => {
          if (props.report) {
            mapToPayload(props.report)
          }
        },
        { immediate: true }
      )

      const getReportTypes = () => {
        return store.dispatch('bespokeReportTypes/index')
      }

      const loadDependencies = () => {
        return Promise.all([getReportTypes()])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        loadDependencies().finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        props,
        route,
        payload,
        reportTypes,
        handleReportTypeChange,
        shouldShowReportTypeSelector,
      }
    },
  })
