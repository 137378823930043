import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }
const _hoisted_2 = { class: "dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-fileuploader-button" }
const _hoisted_3 = { class: "dx-button-content" }
const _hoisted_4 = { class: "dx-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('components.fileUploader.button')), 1)
      ])
    ]),
    _createElementVNode("small", null, _toDisplayString(_ctx.t('components.fileUploader.dropZone')), 1)
  ]))
}