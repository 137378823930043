import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "staffs/getRecruitmentCheckHistory",
    columns: _ctx.columns,
    "read-only": true,
    "query-filter": {
      type: _ctx.props.type,
      employee_id: _ctx.props.employeeId,
      with: ['trashed'],
      relations: ['created_by'],
    }
  }, {
    "toolbar-addon": _withCtx(() => [
      _createVNode(_component_cc_button, {
        label: _ctx.t('components.buttons.back'),
        type: "tertiary",
        icon: "fa fa-arrow-left",
        "secondary-icon": "none",
        onClick: _ctx.goBack
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["columns", "query-filter"]))
}