
  import { Project } from '@/types/modules/projects/Project'
  import { defineComponent, PropType, reactive } from 'vue'
  import CcAvatar from '../../Avatar/Avatar.vue'
  export default defineComponent({
    components: {
      CcAvatar,
    },
    props: {
      data: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
