import auth from '@/middleware/auth/auth'
import { RouteRecordRaw } from 'vue-router'
import { canEdit } from '@/middleware/permissions/module'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import DeleteMedicationStock from '@/views/modules/mars/partials/DeleteMedicationStock.vue'
import MedicationStockHistory from '@/views/modules/mars/partials/MedicationStockHistory.vue'
import TransferMedicationStock from '@/views/modules/mars/partials/TransferMedicationStock.vue'
import ManageMedicationStockType from '@/views/lookups/ManageMedicationStockType.vue'
import AdjustMedicationStock from '@/views/modules/mars/partials/AdjustMedicationStock.vue'
import ManageMedicationStock from '@/views/modules/mars/partials/ManageMedicationStock.vue'
import ManageMedicationStockCategory from '@/views/lookups/ManageMedicationStockCategory.vue'
import ManageMedicationLocations from '@/views/modules/mars/partials/ManageMedicationLocations.vue'
import RestoreMedicationStockType from '@/views/modules/mars/partials/RestoreMedicationStockTypes.vue'
import RestoreMedicationStockCategory from '@/views/modules/mars/partials/RestoreMedicationStockCategories.vue'

export default [
  {
    path: '/projects/homes/:id/mars/medications/:medicationId/adjust',
    alias: '/projects/schools/:id/mars/medications/:medicationId/adjust',
    name: 'StockAdjustment',
    props: true,
    component: AdjustMedicationStock,
    meta: {
      title: 'Stock Adjustment',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id/mars/medications/:medicationId/history',
    alias: '/projects/schools/:id/mars/medications/:medicationId/history',
    name: 'StockHistory',
    props: true,
    component: MedicationStockHistory,
    meta: {
      title: 'Stock History',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id/mars/locations/:locationId/medications/create',
    alias:
      '/projects/schools/:id/mars/locations/:locationId/medications/create',
    name: 'StockCreate',
    component: ManageMedicationStock,
    props: true,
    meta: {
      title: 'Create new medication',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id/mars/locations/:locationId/medications/:medicationId/edit',
    alias:
      '/projects/schools/:id/mars/locations/:locationId/medications/:medicationId/edit',
    name: 'StockEdit',
    props: true,
    component: ManageMedicationStock,
    meta: {
      title: 'Edit medication',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id/mars/medications/delete',
    alias: '/projects/schools/:id/mars/medications/delete',
    name: 'StockDelete',
    component: DeleteMedicationStock,
    meta: {
      title: 'Stock Delete',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id/mars/locations/:medicationLocationId/transfer-medication',
    alias:
      '/projects/schools/:id/mars/locations/:medicationLocationId/transfer-medication',
    name: 'StockTransfer',
    props: true,
    component: TransferMedicationStock,
    meta: {
      title: 'Stock Transfer',
      middleware: [auth],
    },
  },
  {
    path: '/projects/:projectType/:id/mars/locations/edit',
    name: 'ManageMedicationLocations',
    props: true,
    component: ManageMedicationLocations,
    meta: {
      title: 'Manage Medical Cabinets',
      middleware: [auth],
    },
  },
  {
    path: '/medication/stocks/types/create',
    name: 'MedicationStockTypeCreate',
    component: ManageMedicationStockType,
    props: () => ({
      showAction: 'medicationStockTypes/show',
      storeAction: 'medicationStockTypes/store',
    }),
    meta: {
      title: 'Create Medication Stock Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/types/:id/edit',
    name: 'MedicationStockTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'medicationStockTypes/show',
      updateAction: 'medicationStockTypes/update',
      deleteAction: 'medicationStockTypes/delete',
    }),
    component: ManageMedicationStockType,
    meta: {
      title: 'Update Medication Stock Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/types/delete',
    name: 'MedicationStockTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Medication Stock Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/types/restore',
    name: 'MedicationStockTypeRestore',
    component: RestoreMedicationStockType,
    meta: {
      title: 'Restore Deleted Medication Stock Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/categories/create',
    name: 'MedicationStockCategoryCreate',
    component: ManageMedicationStockCategory,
    props: () => ({
      showAction: 'medicationStockCategories/show',
      storeAction: 'medicationStockCategories/store',
    }),
    meta: {
      title: 'Create Medication Stock Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/categories/:id/edit',
    name: 'MedicationStockCategoryEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'medicationStockCategories/show',
      updateAction: 'medicationStockCategories/update',
      deleteAction: 'medicationStockCategories/delete',
    }),
    component: ManageMedicationStockCategory,
    meta: {
      title: 'Update Medication Stock Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/categories/delete',
    name: 'MedicationStockCategoryDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Medication Stock Categories',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/medication/stocks/categories/restore',
    name: 'MedicationStockCategoryRestore',
    component: RestoreMedicationStockCategory,
    meta: {
      title: 'Restore Deleted Medication Stock Categories',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
