import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "far fa-circle-check text-green-300 mr-1.5",
  title: "Default"
}
const _hoisted_2 = {
  key: 0,
  class: "far fa-circle-check text-green-300 mr-1.5",
  title: "Default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.data.association?.project_type_label === 'resident')
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: `${_ctx.route.path}/associations/${_ctx.data.id}/edit`,
        class: "font-semibold text-blue-200 underline"
      }, {
        default: _withCtx(() => [
          (_ctx.data.is_default)
            ? (_openBlock(), _createElementBlock("i", _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.data.association.name), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: `${_ctx.route.path}/associations/${_ctx.data.id}/edit`,
        class: "font-semibold text-blue-200 underline"
      }, {
        default: _withCtx(() => [
          (_ctx.data.is_default)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.data.association?.name), 1)
        ]),
        _: 1
      }, 8, ["to"]))
}