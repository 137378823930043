
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { BespokeReportDraft } from '@/types/modules/reporting/BespokeReportDraft'
  import { defineComponent, PropType, reactive } from 'vue'
  import { format } from 'date-fns'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: { ButtonGroup, CcButton },
    props: {
      draft: {
        type: Object as PropType<BespokeReportDraft>,
        required: true,
      },
    },
    emits: ['draft-restored', 'draft-discarded'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()

      const restoreDraft = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        await store
          .dispatch('bespokeReportDrafts/restore', props.draft.id)
          .then((response) => {
            store.commit('bespokeReports/setBespokeReport', response.data)
            emit('draft-restored', response.data)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const discardDraft = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        await store
          .dispatch('bespokeReportDrafts/delete', props.draft.id)
          .then(() => emit('draft-discarded', props.draft))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }
      return {
        props,
        format,
        restoreDraft,
        discardDraft,
      }
    },
  })
