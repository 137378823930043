
  import { defineComponent, reactive, PropType, computed } from 'vue'
  import { InputIcon } from '@/types/components/InputIcon'
  import { SelectOption } from '@/types/components/SelectOption'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      icons: {
        type: Array as PropType<InputIcon[]>,
        required: false,
      },
      options: {
        type: Array as PropType<SelectOption[]>,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      error: {
        type: String,
        required: false,
      },
      modelValue: {
        required: false,
      },
      defaultOptionText: {
        type: String,
        required: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const hasIconPrefix = computed(() => {
        if (props.icons) {
          return props.icons.filter((icon) => icon.position == 'before')
            ? true
            : false
        }

        return false
      })

      const iconPrefix = computed(() => {
        let icon = ''

        if (props.icons) {
          icon = props.icons.filter((icon) => icon.position == 'before')[0].icon
        }

        return icon
      })

      const hasIconSuffix = computed((): boolean => {
        if (props.icons) {
          return props.icons.filter((icon) => icon.position == 'after')
            ? true
            : false
        }

        return false
      })

      const iconSuffix = computed((): string => {
        if (props.icons) {
          let icon = props.icons.filter((icon) => icon.position == 'after')
          if (icon.length > 0) {
            return icon[0].icon
          } else {
            return ''
          }
        }

        return ''
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.isDisabled && !props.error,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const selectClasses = computed(() => ({
        'cursor-not-allowed': props.isDisabled && !props.error,
        'bg-white': true,
        'border-dashed': props.isDisabled && !props.error,
        'border-b-2': true,
        'border-gray-300': props.isDisabled,
        'transition-all': true,
        'py-2': true,
        'pl-6':
          (props.icons ? props.icons.length > 0 : false) ||
          props.error ||
          props.isDisabled,
        'w-full': true,
        'border-0': true,
        'border-gray-400': true,
        'focus:border-blue-300': true,
        'text-gray-300': props.isDisabled && !props.error,
        'border-red-300': props.error,
      }))

      const hasErrors = computed((): boolean => {
        return props.error ? true : false
      })

      const handleInput = (event: any) => {
        let target = event.target as HTMLInputElement
        emit('update:modelValue', target.value)
      }

      return {
        handleInput,
        hasErrors,
        selectClasses,
        iconSuffix,
        hasIconSuffix,
        iconPrefix,
        hasIconPrefix,
        props,
        labelClasses,
      }
    },
  })
