import FieldGroupHeading from './FieldGroupHeading.vue'
import LargeText from './Largetext.vue'
import SmallText from './Smalltext.vue'
import RelatedForms from './RelatedForms.vue'
import EmbedFiles from './EmbedFiles.vue'
import CcTable from './LogTable.vue'
import ImageField from './ImageField.vue'

export default {
  SmallText,
  LargeText,
  FieldGroupHeading,
  RelatedForms,
  EmbedFiles,
  CcTable,
  ImageField,
}
