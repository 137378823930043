import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Qualification Types",
    "create-view": "QualificationTypeCreate",
    "edit-view": "QualificationTypeEdit",
    "delete-view": "QualificationTypeDelete",
    "restore-view": "QualificationTypeRestore",
    action: "qualificationTypes/index",
    "toggle-visibility-action": "qualificationTypes/toggleManyVisibility",
    "delete-many-action": "qualificationTypes/deleteMany",
    "reorder-action": "qualificationTypes/saveOrderings"
  }))
}