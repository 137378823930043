export interface LogTrigger {
  form_id: number | null
  form_field_id: number | null
  project_id: number | null
  trigger_date: string | null
  option_name?: string
  option_id?: string
}

export interface LogValue {
  form_field_id: number
  row_index: number | undefined
  system_name: string
  value: any
  triggers?: LogTrigger[] | undefined
}

export const getFieldComponent = (systemName: string) => {
  let mappings: Record<string, string> = {
    yes_no: 'YesNo',
    numeric: 'NumericInput',
    smalltext: 'SmallText',
    largetext: 'LargeText',
    time_input: 'TimeInput',
    date_input: 'DateInput',
    true_false: 'TrueFalse',
    system_list: 'SystemList',
    embed_files: 'EmbedFiles',
    related_forms: 'RelatedTasks',
    radio_buttons: 'RadioButton',
    checkbox_list: 'CheckboxList',
    override_date_input: 'OverrideDateInput',
    dropdown_select_list: 'DropdownSelectList',
    field_group_heading: 'FieldGroupHeading',
    table: 'LogTable',
    inline_image: 'InlineImage',
    signature_image: 'SignatureImage',
    image_editor: 'ImageEditor',
  }

  return mappings[systemName]
}

export const fieldsWithTriggers = (): string[] => {
  return ['yes_no', 'radio_buttons', 'dropdown_select_list', 'checkbox_list']
}
