
  import { defineComponent, computed, onMounted, ref, Ref } from 'vue'
  import { useStore } from 'vuex'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { MedicationLocation } from '@/types/modules/mars/MedicationLocation'
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { goBack } from '@/composables/Generic'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useRouter, useRoute } from 'vue-router'
  import GroupedSelectInput from '@/components/Generic/Forms/Inputs/GroupedSelectInput.vue'

  export default defineComponent({
    components: {
      CcButton,
      DataGrid,
      SelectInput,
      GroupedSelectInput,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      medicationLocationId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const medicationLocations = computed(
        (): MedicationLocation[] => store.getters['mars/medicationLocations']
      )

      const formattedLocations = computed(() => {
        let locations = {} as Record<string, any>
        for (let location of medicationLocations.value) {
          let category = location.project?.name || 'Uncategorised'

          if (!locations[category]) {
            locations[category] = []
          }

          locations[category].push({
            label: location.name,
            value: location.id,
          })
        }

        return locations
      })

      const stocks = computed(
        (): MedicationStock[] => store.getters['mars/stocksToTransfer']
      )

      const getLocations = () => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('mars/getMedicationLocations', {
            relations: ['project'],
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const payload = ref({
        old_location_id: null,
        new_location_id: null,
        medication_ids: [],
      }) as Ref<{
        old_location_id: number | null
        new_location_id: number | null
        medication_ids: number[]
      }>

      const columns = computed((): Column[] => [
        {
          caption: t('mars.medicalCabinet.grid.headers.id'),
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.name'),
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.currentStock'),
          dataField: 'stock_level',
          dataType: 'number',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.type'),
          dataField: 'stock_type.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.category'),
          dataField: 'stock_category.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.requiresAuth'),
          calculateCellValue: (row: MedicationStock) => {
            return row.requires_auth
              ? t('mars.medicalCabinet.grid.cells.yes')
              : t('mars.medicalCabinet.grid.cells.no')
          },
        },
      ])

      const transferStock = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('mars/transferStocks', payload.value)
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      const mapToPayload = () => {
        payload.value.old_location_id = Number(props.medicationLocationId)
        payload.value.medication_ids = stocks.value?.map(
          (stock: MedicationStock) => stock.id
        )
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })

        getLocations()

        mapToPayload()

        store.getters['genericStore/breadCrumbs'].push({
          name: t('mars.stockTransfer.header'),
          url: '#',
        })

        if (!Object.keys(stocks.value).length) {
          router.push(`${route.fullPath.split('mars')[0]}?tab=medical-cabinet`)
        }
      })

      return {
        stocks,
        goBack,
        payload,
        formattedLocations,
        columns,
        t,
        transferStock,
      }
    },
  })
