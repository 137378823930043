import { Stimulsoft } from 'stimulsoft-dashboards-js/Scripts/stimulsoft.blockly.editor'

export const registerLicenseKey = () => {
  Stimulsoft.Base.StiLicense.Key = process.env.VUE_APP_STIMULSOFT_LICENSE_KEY

  return Promise.resolve(Stimulsoft.Base.StiLicense.Key)
}

export const viewerOptions = () => {
  const options = new Stimulsoft.Viewer.StiViewerOptions()

  options.appearance.theme =
    Stimulsoft.Viewer.StiViewerTheme.Office2013WhiteBlue
  options.toolbar.showOpenButton = false
  options.toolbar.showSaveButton = false
  options.toolbar.viewMode = 2

  return options
}

export const designerOptions = () => {
  const options = new Stimulsoft.Designer.StiDesignerOptions()

  options.appearance.allowChangeWindowTitle = false
  options.toolbar.showSaveButton = false
  options.toolbar.showSetupToolboxButton = false
  options.toolbar.showFileMenu = false
  options.viewerOptions.toolbar.showOpenButton = false
  options.viewerOptions.toolbar.showSaveButton = false
  options.viewerOptions.toolbar.viewMode = 2
  options.appearance.theme =
    Stimulsoft.Designer.StiDesignerTheme.Office2013WhiteBlue

  return options
}
