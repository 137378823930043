
  import { defineComponent, reactive, computed } from 'vue'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      error: {
        type: String,
        required: false,
      },
      modelValue: {
        required: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.error,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const reactions = {
        smiley: 'fa-face-smile-relaxed text-green-400',
        neutral_face: 'fa-face-meh text-orange-400 ',
        angry: 'fa-face-pouting text-red-400',
      } as Record<string, string>

      const handleInput = (reaction: string) =>
        emit('update:modelValue', reaction)

      return {
        handleInput,
        props,
        reactions,
        labelClasses,
      }
    },
  })
