
  import { defineComponent, reactive, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import GridToolbar from '@/components/Generic/Grid/GridToolbar.vue'
  import CcCalendar from '@/components/Generic/Calendar/Calendar.vue'
  import CalendarSidebar from '@/components/Generic/Calendar/CalendarSidebar.vue'
  import { canEditModule } from '@/composables/Permissions'
  import { isTab } from '@/composables/Generic'
  import CalendarSettings from './Settings.vue'

  export default defineComponent({
    components: {
      CcButton,
      GridToolbar,
      CcCalendar,
      CalendarSidebar,
      CalendarSettings,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const filters = ref({})

      const state = reactive({
        exportOptions: [],
      })

      const filtersChanged = (data: any) => (filters.value = data)

      const addEvent = () => router.push({ name: 'CalendarEventCreate' })

      const tabs = [
        { name: 'Calendar', url: `${route.path}?tab=calendar` },
        { name: 'Settings', url: `${route.path}?tab=settings` },
      ]

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'calendar' } })
        }

        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Calendar', url: '/calendar' },
          ],
        })
        store.commit('genericStore/setTabs', { tabs: tabs })
      })

      return {
        addEvent,
        isTab,
        filtersChanged,
        filters,
        state,
        canEditModule,
      }
    },
  })
