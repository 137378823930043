
  import { defineComponent, reactive, computed } from 'vue'
  import sanitizeHtml from 'sanitize-html'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      error: {
        type: String,
        required: false,
      },
      modelValue: {
        type: [String, Number],
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const inputClasses = computed(() => ({
        'cursor-not-allowed': props.isDisabled && !props.error,
        'bg-white': true,
        'border-dashed': props.isDisabled && !props.error,
        'border-b-2': true,
        'border-gray-300': props.isDisabled,
        'overflow-hidden': props.isDisabled,
        'transition-all': true,
        'py-2': true,
        'px-2': true,
        'box-border resize-none': true,
        'w-full': true,
        'border-0': true,
        'border-gray-400': true,
        'focus:border-blue-300': true,
        'text-gray-300': props.isDisabled && !props.error,
        'border-red-300': props.error,
      }))

      const hasErrors = computed(() => {
        return props.error ? true : false
      })

      const handleInput = (event: any) => {
        let target = event.target as HTMLInputElement

        let data = target.value ? sanitizeHtml(target.value) : target.value

        emit('update:modelValue', data)
      }

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.isDisabled && !props.error,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      return {
        labelClasses,
        handleInput,
        hasErrors,
        inputClasses,
        props,
      }
    },
  })
