
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import FormBuilder from './partials/designer/FormBuilder.vue'
  import FormBuilderWizard from './partials/designer/FormBuilderWizard.vue'
  import FormDetails from './partials/designer/FormDetails.vue'
  import FormPreview from './partials/designer/FormPreview.vue'
  import FormAccess from './partials/designer/FormAccess.vue'
  import {
    FormPayload,
    PayloadKey,
    RenderedFormFieldSchema,
  } from '@/types/components/FormBuilder'
  import { array, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { serialize } from 'object-to-formdata'
  import { Field } from '@/types/modules/forms/Field'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'

  export default defineComponent({
    components: {
      FormBuilder,
      FormBuilderWizard,
      FormDetails,
      FormPreview,
      FormAccess,
      ValidationErrors,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const activeStep = ref(1) as Ref<number>

      const payload = ref({
        triggered_task_id: null,
        name: '',
        trigger_has_date: 0,
        always_trigger_for_same_project: 0,
        restrict_projects_by_association: 1,
        form_status_id: 1,
        form_group_id: null,
        is_restricted: 0,
        can_be_used_as_one_off_form: 0,
        can_be_used_in_procedure: 0,
        email_notifications: [],
        region_id: null,
        description: '',
        instructions: '',
        trigger_instant_edit: 0,
        fields: [],
        form_type_ids: [],
        keywords: [],
        approver_ids: [],
        restriction_ids: [],
      }) as Ref<FormPayload>

      const form = computed(() => store.getters['forms/getForm'])
      const updateFields = (values: RenderedFormFieldSchema[]) =>
        (payload.value['fields'] = values)

      const updatePayload = (details: Record<PayloadKey, never>) => {
        Object.keys(details).forEach((key: string) => {
          payload.value[key as PayloadKey] = details[key as PayloadKey]
        })
      }

      const setTemplateFields = (fields: RenderedFormFieldSchema[]) =>
        (payload.value.fields = fields)

      const validateForm = async () => {
        let data = payload.value as unknown as Record<string, unknown>

        errors.value = await validateFormDetails(data)

        let messages = await validateFormFields(data)

        if (Object.keys(messages).length) {
          errors.value = messages
        }

        return Object.keys(messages).length ? false : true
      }

      const deletedFields = ref([]) as Ref<RenderedFormFieldSchema[]>

      const pushToFieldsDeleted = (field: RenderedFormFieldSchema) => {
        deletedFields.value.push(field)
      }

      const submitForm = () => {
        if (props.id) {
          updateForm()
        } else {
          saveForm()
        }
      }

      const changedFields = computed(
        () => store.getters['forms/getChangedFieldIds']
      )

      const updateForm = () => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('forms/update', {
            id: props.id,
            payload: buildUpdatePayload(),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            router.push({ query: { form_id: response.data.id } })
            activeStep.value = 1
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const buildUpdatePayload = () => {
        let formattedFields = payload.value.fields.map(
          (field: RenderedFormFieldSchema) => {
            if (
              field.hasOwnProperty('has_changed') &&
              field.has_changed === false
            ) {
              return {
                id: field.id,
                field: { system_name: field.field.system_name },
              }
            }

            return field
          }
        )

        let updatePayload = payload.value as any
        updatePayload.fields = formattedFields
        updatePayload.deleted_fields = deletedFields.value.map(
          (deletedField) => deletedField.id
        )

        let formData = serialize(updatePayload, {
          indices: true,
          allowEmptyArrays: true,
          booleansAsIntegers: true,
        })

        formData.append('_method', 'PUT')

        return formData
      }

      const saveForm = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        if (!(await validateForm())) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        let formData = serialize(payload.value, {
          indices: true,
          allowEmptyArrays: true,
          booleansAsIntegers: true,
        })

        if (!form.value) {
          store
            .dispatch('forms/store', formData)
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)

              activeStep.value = 1

              router.push({
                query: { form_id: response.data.id },
              })
            })
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      const formDetailsValidation = object({
        name: string().required('Please enter a form name'),
        form_type_ids: array().min(1, 'At least one form type is required'),
      })

      const fieldHasOptions = (field: Field) => {
        return (
          ['checkbox_list', 'dropdown_select_list', 'radio_buttons'].indexOf(
            field.system_name
          ) !== -1
        )
      }

      const FormFieldSchema = object().shape({
        name: string().required('Field name is required'),
        field: object().required(),
        options: array()
          .of(object())
          .when('field', {
            is: (field: Field) => fieldHasOptions(field),
            then: array()
              .of(
                object({
                  name: string().required('Option name required'),
                })
              )
              .min(1, 'Fields with options need at least one option'),
          }),
      })

      const fieldValidation = object().shape({
        fields: array()
          .of(FormFieldSchema)
          .min(1, 'You should add at least one field to preview'),
      })

      const errors = ref(null) as Ref<Record<string, string> | null>

      const validateFormDetails = async (data: any) => {
        return validatePayload(formDetailsValidation, data)
      }

      const validateFormFields = async (data: any) => {
        let messages = {} as any
        let response = await validatePayload(fieldValidation, data)
        let errorMessages = response ? Object.values(response) : null

        if (
          errorMessages &&
          errorMessages.indexOf(
            'You should add at least one field to preview'
          ) !== -1
        ) {
          messages.no_fields = 'You should add at least one field to preview'
        }

        if (
          errorMessages &&
          errorMessages.indexOf('Field name is required') !== -1
        ) {
          messages.field_name = "One or more fields don't have names"
        }

        if (
          errorMessages &&
          errorMessages.indexOf(
            'Fields with options need at least one option'
          ) !== -1
        ) {
          messages.minimum_options =
            "One or more fields don't have at least one option"
        }

        let tables = payload.value.fields.filter(
          (field) => field.field.system_name === 'table'
        )

        tables.forEach((table: RenderedFormFieldSchema) => {
          let hasChild =
            table.children?.length ||
            payload.value.fields.find((field) => {
              return (
                field.parent_id === table.id &&
                field.field.system_name !== 'table_end'
              )
            })

          if (!hasChild) {
            messages.empty_table =
              'One or more tables are empty. Tables should have at least one child'
          }
        })

        return messages
      }

      const validateStep = async (step: number) => {
        errors.value = null
        let data = payload.value as unknown as Record<string, unknown>

        if (step > 1) {
          errors.value = await validateFormDetails(data)

          if (errors.value) {
            activeStep.value = 1
            return
          }
        }

        if (step > 3) {
          let messages = await validateFormFields(data)

          if (Object.keys(messages).length) {
            errors.value = messages
          }

          if (errors.value) {
            activeStep.value = 3
            return
          }
        }
      }

      const changeView = async (step: number) => {
        await validateStep(step)

        if (errors.value) {
          return
        }

        activeStep.value = step
      }

      watch(
        () => route.query.form_id,
        () => {
          if (route.query.form_id) {
            store
              .dispatch('forms/show', {
                id: route.query.form_id,
                version: 'latest',
              })
              .then(() => {
                payload.value.fields = form.value?.fields || []
              })
          }
        },
        { immediate: true }
      )

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/' },
            { name: 'Forms & Procedures', url: '/forms-procedures' },
            {
              name: 'Form Designer',
              url: `/forms-procedures/form-designer?form_id=${props.id}`,
            },
          ],
        })
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      onUnmounted(() => store.commit('forms/setForm', null))

      return {
        payload,
        updateFields,
        changeView,
        activeStep,
        form,
        updatePayload,
        submitForm,
        setTemplateFields,
        changedFields,
        errors,
        pushToFieldsDeleted,
      }
    },
  })
