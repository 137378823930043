import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cardGrid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_data_grid, {
      action: "tasks/index",
      columns: [{ cellTemplate: 'TaskCardGridTemplate' }],
      "query-filter": _ctx.filters,
      chips: _ctx.chips,
      onChipClicked: _ctx.filterTasks,
      "read-only": true,
      "allow-column-chooser": false,
      "allow-selection": false,
      "allow-grouping": false,
      "show-page-size-selector": false,
      "show-grid-toolbar": false
    }, null, 8, ["query-filter", "chips", "onChipClicked"])
  ]))
}