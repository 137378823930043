import ManageContractType from '@/views/lookups/ManageContractType.vue'
import ManageEvidenceType from '@/views/lookups/ManageEvidenceType.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canEdit } from '@/middleware/permissions/module'
import RestoreContractTypes from '@/views/modules/staffs/partials/RestoreContractTypes.vue'
import RestoreEvidenceTypes from '@/views/modules/staffs/partials/RestoreEvidenceTypes.vue'

export default [
  {
    path: '/contracts/types/create',
    name: 'ContractTypeCreate',
    component: ManageContractType,
    props: () => ({
      showAction: 'contractTypes/show',
      storeAction: 'contractTypes/store',
    }),
    meta: {
      title: 'Create Contract Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/contracts/types/:id/edit',
    name: 'ContractTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'contractTypes/show',
      updateAction: 'contractTypes/update',
      deleteAction: 'contractTypes/delete',
    }),
    component: ManageContractType,
    meta: {
      title: 'Update Contract Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/contracts/types/delete',
    name: 'ContractTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Contract Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/contracts/types/restore',
    name: 'ContractTypeRestore',
    component: RestoreContractTypes,
    meta: {
      title: 'Restore Deleted Contract Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/evidence/types/create',
    name: 'EvidenceTypeCreate',
    component: ManageEvidenceType,
    props: () => ({
      showAction: 'evidenceTypes/show',
      storeAction: 'evidenceTypes/store',
    }),
    meta: {
      title: 'Create Evidence Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/evidence/types/:id/edit',
    name: 'EvidenceTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'evidenceTypes/show',
      updateAction: 'evidenceTypes/update',
      deleteAction: 'evidenceTypes/delete',
    }),
    component: ManageEvidenceType,
    meta: {
      title: 'Update Evidence Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/evidence/types/delete',
    name: 'EvidenceTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Evidence Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/evidence/types/restore',
    name: 'EvidenceTypeRestore',
    component: RestoreEvidenceTypes,
    meta: {
      title: 'Restore Deleted Evidence Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
