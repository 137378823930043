
  import { defineComponent, reactive } from 'vue'
  import VehiclesTable from '@/views/modules/projects/vehicles/partials/VehiclesTable.vue'

  export default defineComponent({
    components: { VehiclesTable },
    props: {
      regionId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
