
  import { computed, defineComponent, onMounted, reactive, ref, Ref } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { Column } from 'devextreme/ui/data_grid'
  import { Task } from '@/types/modules/tasks/Task'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import NumberInput from '@/components/Generic/Forms/Inputs/NumberInput.vue'

  export default defineComponent({
    components: {
      SelectInput,
      CcButton,
      DataGrid,
      ValidationErrors,
      NumberInput,
    },
    props: {
      taskId: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const currentTask = computed(() => {
        if (task.value) {
          return [
            {
              label: `${task.value.name} - (${task.value.id})`,
              value: task.value.id,
            },
          ]
        }

        return []
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Title',
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: 'Task Date',
          dataType: 'date',
          dataField: 'start_date',
          allowGrouping: false,
        },
      ])

      const errors = ref(null) as Ref<Record<string, string> | null>
      const task = computed((): Task => store.getters['tasks/task'])

      const payload = ref({
        parent_id: null,
        task_id: null,
      }) as Ref<{ parent_id: number | null; task_id: number | null }>

      const getTask = () => {
        return store
          .dispatch('tasks/show', { id: props.taskId, params: {} })
          .then(() => {
            payload.value.task_id = Number(props.taskId)
            store.dispatch('tasks/setSelectedTasks', [task.value])
          })
      }

      const link = () => {
        store.dispatch('genericStore/showPageLoader', true)

        if (!payload.value.parent_id) {
          errors.value = {
            parent_id: 'Please the Task ID you want to link to this task',
          }
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        store
          .dispatch('tasks/linkTask', payload.value)
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })

        getTask().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        payload,
        goBack,
        columns,
        errors,
        currentTask,
        link,
        task,
      }
    },
  })
