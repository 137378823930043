import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ProjectTypeSectionService from '@/services/projects/projectTypeSections'
import { ProjectTypeSection } from '@/types/modules/projects/ProjectTypeSection'

export type ProjectTypeSectionGetter = GetterTree<ProjectTypeSectionState, any>

export interface ProjectTypeSectionState {
  projectTypeSections: ProjectTypeSection[]
  projectTypeSection: ProjectTypeSection | null
}

export const state: ProjectTypeSectionState = {
  projectTypeSections: [],
  projectTypeSection: null,
}

export const getters: ProjectTypeSectionGetter = {
  getProjectTypeSections: (state) => state.projectTypeSections,
  getProjectTypeSection: (state) => state.projectTypeSection,
}

export const actions: ActionTree<ProjectTypeSectionState, any> = {
  index: async (context, params) => {
    const response = await ProjectTypeSectionService.index(params)

    if (response.success && params.per_page) {
      context.commit('setProjectTypeSections', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setProjectTypeSections', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      ProjectTypeSectionService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setProjectTypeSection', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(ProjectTypeSectionService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      ProjectTypeSectionService.update(payload.id, payload)
    )
  },
  delete: (_context, id) => {
    return Promise.resolve(ProjectTypeSectionService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(ProjectTypeSectionService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(ProjectTypeSectionService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(ProjectTypeSectionService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      ProjectTypeSectionService.toggleManyVisibility(params)
    )
  },
}

export const mutations: MutationTree<ProjectTypeSectionState> = {
  setProjectTypeSections: (state, attributes: ProjectTypeSection[]) =>
    (state.projectTypeSections = attributes),
  setProjectTypeSection: (state, attribute: ProjectTypeSection) =>
    (state.projectTypeSection = attribute),
}

export const projectTypeSections = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
