
  import { defineComponent, computed, reactive, ref } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useI18n } from 'vue-i18n'
  import { renderCellLink } from '@/composables/Grids'
  import { useStore } from 'vuex'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      projectId: {
        type: Number,
        required: false,
      },
      showToolbar: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const grid = ref()

      const selectedPermissions = computed(
        () => store.getters['userProjectPermissions/selectedPermissions']
      )

      const columns = computed((): Column[] => [
        {
          caption: t('userPermissions.grid.headers.id'),
          width: 100,
          dataField: 'id',
          dataType: 'number',
          allowGrouping: false,
        },
        {
          caption: t('userPermissions.grid.headers.name'),
          dataField: 'username',
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data.username, 'UserShow', {
              id: options.data.id,
            })
          },
          allowGrouping: false,
        },
        {
          caption: t('userPermissions.grid.headers.permission'),
          cellTemplate: 'permissionCellTemplate',
        },
        {
          dataField: 'role.name',
          caption: t('userPermissions.grid.headers.role'),
        },
      ])

      const submitClicked = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('userProjectPermissions/store', {
            permissions: selectedPermissions.value,
            id: props.projectId,
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then((response: any) => {
            store.dispatch('userProjectPermissions/setSelectedPermissions', [])
            grid.value.refresh()
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      return {
        props,
        columns,
        selectedPermissions,
        submitClicked,
        grid,
      }
    },
  })
