import { GetterTree, MutationTree, ActionTree } from 'vuex'
import CareGroupService from '@/services/care_groups/care_groups'
import { CareGroup } from '@/types/modules/care_groups/CareGroup'
import { CareGroupStakeholder } from '@/types/modules/care_groups/CareGroupStakeholder'
import { CareGroupImage } from '@/types/modules/care_groups/CareGroupImage'
import { CareGroupStakeholderAudit } from '@/types/modules/care_groups/CareGroupStakeholderAudit'
import { CareGroupAudit } from '@/types/modules/care_groups/CareGroupAudit'
import { CareGroupPermission } from '@/types/modules/care_groups/CareGroupPermission'

export type CareGroupGetter = GetterTree<CareGroupState, any>

export interface CareGroupState {
  sensitiveFileCategories: any
  careGroupPermissions: CareGroupPermission[]
  careGroups: CareGroup[]
  careGroup: CareGroup | null
  stakeholders: CareGroupStakeholder[]
  splashImages: CareGroupImage[]
  logoImages: CareGroupImage[]
  companyAuditLogs: CareGroupAudit[]
  stakeholderAuditLogs: CareGroupStakeholderAudit[]
}

export const state: CareGroupState = {
  careGroups: [],
  careGroup: null,
  stakeholders: [],
  splashImages: [],
  logoImages: [],
  sensitiveFileCategories: [],
  careGroupPermissions: [],
  companyAuditLogs: [],
  stakeholderAuditLogs: [],
}

export const getters: CareGroupGetter = {
  careGroups: (state) => state.careGroups,
  careGroup: (state) => state.careGroup,
  stakeholders: (state) => state.stakeholders,
  splashImages: (state) => state.splashImages,
  logoImages: (state) => state.logoImages,
  sensitiveFileCategories: (state) => state.sensitiveFileCategories,
  careGroupPermissions: (state) => state.careGroupPermissions,
  companyAuditLogs: (state) => state.companyAuditLogs,
  stakeholderAuditLogs: (state) => state.stakeholderAuditLogs,
}

export const mutations: MutationTree<CareGroupState> = {
  setCareGroups: (state, payload: CareGroup[]) => {
    state.careGroups = payload
  },
  setCareGroup: (state, payload: CareGroup) => {
    state.careGroup = payload
  },
  setStakeholders: (state, payload: CareGroupStakeholder[]) => {
    state.stakeholders = payload
  },
  setSensitiveFileCategories: (state, sensitiveFileCategories) =>
    (state.sensitiveFileCategories = sensitiveFileCategories),
  setCareGroupPermissions: (state, permissions: CareGroupPermission[]) =>
    (state.careGroupPermissions = permissions),
  setSplashImages: (state, images: CareGroupImage[]) =>
    (state.splashImages = images),
  setLogoImages: (state, images: CareGroupImage[]) =>
    (state.logoImages = images),
  addSplashImage: (state, image: CareGroupImage) =>
    state.splashImages.push(image),
  setCareGroupAuditLogs: (state, logs: CareGroupAudit[]) =>
    (state.companyAuditLogs = logs),
  setStakeholderAuditLogs: (state, logs: CareGroupStakeholderAudit[]) =>
    (state.stakeholderAuditLogs = logs),
}

export const actions: ActionTree<CareGroupState, any> = {
  index: async (context, params) => {
    const response = await CareGroupService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setCareGroups', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setCareGroups', response.data)

      return response
    }

    return response
  },
  show: (context, id) => {
    let results = Promise.resolve(CareGroupService.show(id))

    results.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setCareGroup', response.data)
      }
    })
  },
  update: (context, payload) => {
    const response = Promise.resolve(CareGroupService.update(payload))

    response.then((response) => {
      if (response.success) {
        context.commit('setCareGroup', response.data)
      }
    })

    return response
  },
  getStakeholders: async (context, params) => {
    const response = await CareGroupService.getStakeholders(params)

    if (response.success && params.per_page) {
      context.commit('setStakeholders', {
        data: response.data,
        totalCount: response.meta.total,
      })
      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }
    if (response.success && !params.per_page) {
      context.commit('setStakeholders', response.data)
      return response
    }
    return response
  },

  getSplashImages: (context, params) => {
    let response = Promise.resolve(CareGroupService.getSplashImages(params))

    response.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setSplashImages', response.data)
      }
    })

    return response
  },
  deleteSplashImage: (context, params) => {
    let response = Promise.resolve(CareGroupService.deleteSplashImage(params))

    response.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit(
          'setSplashImages',
          state.splashImages.filter((image: any) => image.id !== params.imageId)
        )
      }
    })
  },
  getLogoImages: (context, params) => {
    let response = Promise.resolve(CareGroupService.getLogoImages(params))

    response.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setLogoImages', response.data)
      }
    })

    return response
  },
  deleteLogoImage: (context, params) => {
    let response = Promise.resolve(CareGroupService.deleteLogoImage(params))

    response.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit(
          'setLogoImages',
          state.logoImages.filter((image: any) => image.id !== params.imageId)
        )
      }
    })
  },
  uploadSplashImage: (context, image) => {
    let fileReader = new FileReader()
    let response = Promise.resolve(CareGroupService.uploadSplashImage(image))

    response.then((response) => {
      if (response.success) {
        // TODO:remove this logic once the backend is ready
        fileReader.onload = () => {
          if (fileReader.result && typeof fileReader.result == 'string') {
            response.data.url = fileReader.result
          }
          context.commit('addSplashImage', response.data)
        }
        fileReader.readAsDataURL(image)
      }
    })

    return response
  },
  getCareGroupPermissions: (context, params) => {
    let response = Promise.resolve(
      CareGroupService.getCareGroupPermissions(params)
    )

    response.then((response) => {
      context.commit('setCareGroupPermissions', response.data)
    })

    return response
  },
  getCareGroupAuditLogs: async (context, params) => {
    const response = await CareGroupService.getCareGroupAuditLogs(params)

    if (response.success && params.per_page) {
      context.commit('setCareGroupAuditLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setCareGroupAuditLogs', response.data)

      return response
    }

    return response
  },
  getStakeholderAuditLogs: async (context, params) => {
    const response = await CareGroupService.getStakeholderAuditLogs(params)

    if (response.success && params.per_page) {
      context.commit('setStakeholderAuditLogs', {
        data: response.data,
        totalCount: response.data.length,
      })

      return {
        data: response.data,
        totalCount: response.data.length,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setStakeholderAuditLogs', response.data)

      return response
    }

    return response
  },
}

export const careGroups = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
