import { EnComponents } from "@/locales/messages/components"

export default {
    filingCabinet: {
        header: 'Filing Cabinet',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                createdOn: EnComponents.grid.headers.createdOn,
                createdBy: EnComponents.grid.headers.createdBy,
                updatedOn: EnComponents.grid.headers.updatedOn,
                updatedBy: EnComponents.grid.headers.updatedBy,
                name: 'Title',
                type: `Form Group ${EnComponents.grid.headers.type}`,
                date: `Task ${EnComponents.grid.headers.date}`,
                
            }
        }
    }
}