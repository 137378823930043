
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import FileUploader from '@/components/Generic/Forms/Inputs/FileUploader.vue'
  import UploadedFile from './partials/UploadedFile.vue'
  import DropZoneAppearanceTemplate from './partials/DropZoneAppearanceTemplate.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { MultiSelect as MultiSelectType } from '@/types/components/MultiSelect'
  import { useStore } from 'vuex'
  import { Employee } from '@/types/modules/projects/Employee'
  import { EvidenceType } from '@/types/modules/staffs/EvidenceType'
  import { format } from 'date-fns'
  import { useI18n } from 'vue-i18n'
  import { Dbs } from '@/types/modules/staffs/Dbs'
  import { SelectOption } from '@/types/components/SelectOption'
  import { DbsStatus } from '@/types/modules/staffs/DbsStatus'
  import { useRouter } from 'vue-router'
  import { validatePayload } from '@/composables/Validation'
  import { array, number, object, string } from 'yup'
  import { RecruitmentCheckFile } from '@/types/modules/files/RecruitmentFile'
  import { StaffProcessFile } from '@/types/modules/staffs/StaffProcessFile'
  import { allowedFileExtensions } from '@/composables/staffs/RecruitmentChecks'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'
  export default defineComponent({
    components: {
      PageToolbar,
      SelectInput,
      MultiSelect,
      TextInput,
      TextBox,
      DateInput,
      FileUploader,
      UploadedFile,
      DropZoneAppearanceTemplate,
    },
    props: {
      employee: {
        type: Object as PropType<Employee>,
        required: true,
      },
    },
    emits: ['update:payload', 'submit-clicked'],
    setup(props, { emit }) {
      props = reactive(props)
      const { t } = useI18n()
      const router = useRouter()
      const extraOptions = ref(['View History']) as Ref<string[]>
      const errors = ref(null) as Ref<Record<string, string | undefined> | null>
      const files = ref([]) as Ref<RecruitmentCheckFile[] | null>
      const store = useStore()
      const payload = ref({
        dbs_status_id: 0,
        dbs_application_number: '',
        children_barred_list: 0,
        adult_barred_list: null as number | null,
        issues: '',
        expiration_date: '',
        refresher_date: '' as string | undefined,
        created_by: '',
        updated_by: '',
        created_at: '',
        updated_at: '',
        documents: [] as number[] | undefined,
        changed_files: [] as RecruitmentCheckFile[] | null,
        new_files: [] as RecruitmentCheckFile[] | null,
      })

      const yesNoOptions = computed((): SelectOption[] => {
        return [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 0 },
        ]
      })

      const evidenceTypes = computed(() => {
        return store.getters['staffs/evidenceTypes']
      })

      const dbsStatuses = computed(() => {
        return store.getters['staffs/dbsStatuses']
      })

      const evidenceTypeOptions = computed((): MultiSelectType[] => {
        if (evidenceTypes.value.length) {
          return evidenceTypes.value.map((type: EvidenceType) => {
            return {
              value: type.id,
              text: type.name,
            }
          })
        }
        return []
      })

      const dbsStatusOptions = computed((): SelectOption[] => {
        if (dbsStatuses.value.length) {
          return dbsStatuses.value.map((status: DbsStatus) => {
            return {
              value: status.id,
              label: status.name,
            }
          })
        }
        return []
      })

      const dbsCheck = computed((): Dbs => store.getters['employeeDbs/dbs'])

      const getDbsStatuses = () => {
        return store.dispatch('staffs/getDbsStatuses')
      }

      const getDbsCheck = () => {
        return store.dispatch('employeeDbs/show', {
          id: props.employee.id,
          relations: ['created_by', 'updated_by'],
        })
      }

      const filteredFiles = computed(() => {
        if (files.value) {
          return files.value.filter((file: RecruitmentCheckFile) => {
            return file.is_deleted === false
          })
        }
        return []
      })

      const mapToPayload = () => {
        if (dbsCheck.value) {
          payload.value.dbs_status_id = dbsCheck.value.dbs_status_id
          payload.value.dbs_application_number =
            dbsCheck.value.dbs_application_number
          payload.value.children_barred_list =
            dbsCheck.value.children_barred_list
          payload.value.adult_barred_list = dbsCheck.value.adult_barred_list
          payload.value.issues = dbsCheck.value.issues || ''
          payload.value.expiration_date = dbsCheck.value.expiration_date
          payload.value.refresher_date =
            dbsCheck.value.refresher_date || undefined
          payload.value.created_by = `${dbsCheck.value.created_by?.first_name} ${dbsCheck.value.created_by?.last_name}`
          payload.value.updated_by = `${dbsCheck.value.updated_by?.first_name} ${dbsCheck.value.updated_by?.last_name}`
          payload.value.created_at = dbsCheck.value.created_at
            ? format(new Date(dbsCheck.value.created_at), 'dd/MM/yyyy hh:mm a')
            : ''
          payload.value.updated_at = dbsCheck.value.updated_at
            ? format(new Date(dbsCheck.value.updated_at), 'dd/MM/yyyy hh:mm a')
            : ''
          payload.value.documents = dbsCheck.value.evidence_documents?.map(
            (document: EvidenceType) => document.id
          )
          files.value = dbsCheck.value.files?.map((file: StaffProcessFile) => {
            return {
              file,
              id: file.id,
              is_existing: true,
              is_deleted: false,
              is_sensitive: !!file.is_sensitive,
            }
          }) as RecruitmentCheckFile[]
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'View History') {
          router.push({
            name: 'RecruitmentCheckHistory',
            params: {
              employeeId: props.employee.id,
              type: 'dbs',
            },
          })
        }
      }

      const filesUploaded = (newFiles: File[]) => {
        let uploadedFiles = newFiles.map((file: File) => {
          return {
            file,
            id: Math.random().toString(36).substring(2, 9),
            is_existing: false,
            is_deleted: false,
            is_sensitive: false,
          }
        }) as RecruitmentCheckFile[]

        files.value = files.value
          ? [...files.value, ...uploadedFiles]
          : uploadedFiles

        payload.value.new_files = payload.value.new_files
          ? [...payload.value.new_files, ...uploadedFiles]
          : uploadedFiles
      }

      const fileSensitivityToggled = (file: RecruitmentCheckFile) => {
        if (file.is_existing) {
          if (
            payload.value.changed_files &&
            !payload.value.changed_files.find(
              (changedFile: RecruitmentCheckFile) => changedFile.id === file.id
            )
          ) {
            payload.value.changed_files.push(file)
          }
        }

        if (!file.is_existing) {
          if (
            payload.value.new_files &&
            !payload.value.new_files.find(
              (newFile: RecruitmentCheckFile) => newFile.id === file.id
            )
          ) {
            payload.value.new_files.push(file)
          }
        }
      }

      const fileDeleteClicked = (file: RecruitmentCheckFile) => {
        files.value = files.value?.filter((f: RecruitmentCheckFile) => {
          return f.id !== file.id
        }) as RecruitmentCheckFile[]
        if (file.is_existing) {
          file.is_deleted = true
          payload.value.changed_files = payload.value.changed_files
            ? [...payload.value.changed_files, file]
            : [file]
        } else {
          payload.value.new_files = payload.value.new_files?.filter(
            (f: RecruitmentCheckFile) => f.id !== file.id
          ) as RecruitmentCheckFile[]
        }
      }

      const validate = (data: Record<string, unknown>) => {
        return validatePayload(
          object().shape({
            dbs_status_id: number().required(
              'Please provide a valid DBS status'
            ),
            dbs_application_number: string().required(
              'Please provide a valid DBS application number'
            ),
            children_barred_list: number().required(
              `Please provide whether the employee is on the children\'s barred list`
            ),
            adult_barred_list: number().nullable(),
            issues: string().nullable(),
            expiration_date: string().required(
              'Please provide a valid DBS expiration date'
            ),
            refresher_date: string().nullable(),
            documents: array()
              .required()
              .min(1, 'Please select at least one document type'),
          }),
          data
        )
      }

      const exportClicked = (option: string) => {
        const name = 'Recruitment Checks - DBS Check'
        const data = {
          status: dbsStatuses.value.find(
            (status: DbsStatus) => status.id === payload.value.dbs_status_id
          )?.name,
          application_number: payload.value.dbs_application_number,
          children_barred_list: payload.value.children_barred_list
            ? 'Yes'
            : 'No',
          adult_barred_list: payload.value.adult_barred_list ? 'Yes' : 'No',
          issues: payload.value.issues,
          expiration_date: payload.value.expiration_date,
          refresher_date: payload.value.refresher_date,
          documents: payload.value.documents
            ?.map((document: number) => {
              return evidenceTypes.value.find(
                (type: EvidenceType) => type.id === document
              )?.name
            })
            .join(', '),
          created_by: payload.value.created_by,
          updated_by: payload.value.updated_by,
          created_at: payload.value.created_at,
          updated_at: payload.value.updated_at,
        }

        if (option === 'Export To Excel') {
          return exportPageToExcel(data, name)
        }
        if (option === 'Export To PDF') {
          return exportPageToPdf(data, name)
        }
      }

      const submitClicked = async () => {
        errors.value = await validate(payload.value)
        if (errors.value) return
        emit('submit-clicked', 'dbs')
        payload.value.changed_files = []
        payload.value.new_files = []
      }

      watch(
        () => dbsCheck.value,
        () => mapToPayload()
      )

      watch(
        () => payload.value,
        (value) => emit('update:payload', value),
        { deep: true }
      )

      const loadDependencies = () => {
        return Promise.all([getDbsStatuses(), getDbsCheck()])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        loadDependencies()
          .then(() => mapToPayload())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        t,
        props,
        errors,
        payload,
        yesNoOptions,
        extraOptions,
        filteredFiles,
        filesUploaded,
        optionClicked,
        submitClicked,
        exportClicked,
        dbsStatusOptions,
        fileDeleteClicked,
        evidenceTypeOptions,
        allowedFileExtensions,
        fileSensitivityToggled,
      }
    },
  })
