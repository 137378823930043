export default {
    employeeDetails: {
        header: 'Employee Details',
        summary: {
            id: 'ID',
            name: 'Employee Name',
            region: 'region',
            careHome: 'Care Home Or School',
            ccStartDate: 'ClearCare Start Date',
            ccEndDate: 'ClearCare End Date',
            careGroup: {
                label: 'Care Group',
                message: 'Hint Or Error Message',
            },
            joinedOn: 'Joined Care Group On',
            leftOn: 'Left Care GRoup On',
            residesAtCareGroup: 'Resides At Care Group'
        },
        contactDetails: {
            address1: 'Address 1',
            address2: 'Address 2',
            postCode: 'Post Code',
            phone: 'Phone Number',
            fax: 'Fax Number',
            email: 'Email Address',
            description: 'Description'
        },
        personalDetails: {
            nationality: 'Nationality',
            ethnicity: 'Ethnicity',
            birthDate: 'Date Of Birth',
            age: 'Age',
            gender: 'Gender',
            nextOfKin: 'Next Of Kin',
            medicalFiles: 'Medical File(s)',
            extraInfo: 'Extra Information'
        },
        employmentDetails: {
            jobTitle: 'Job Title',
            employmentType: 'Employment Type',
            contractType: 'Contract Type',
            currentGrading: 'Current Grading',
            contractingHours: 'Contracting Hours',
            NiNum: 'National Insurace Number',
            prevExperience: 'Previous Experience',
            probation: 'On Probation',
            administrator: 'Administrator'
        }
    }
}