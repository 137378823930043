import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_radio_button = _resolveComponent("radio-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_number_input, {
        "is-disabled": true,
        label: _ctx.t('homeDetails.summary.id'),
        modelValue: _ctx.payload.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.id) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('homeDetails.summary.name'),
        "is-required": true,
        error: _ctx.props.errors?.name,
        modelValue: _ctx.payload.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.name) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.summary.beds'),
        "is-disabled": true,
        "is-required": true,
        error: _ctx.props.errors?.number_of_beds,
        modelValue: _ctx.payload.number_of_beds,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.number_of_beds) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_number_input, {
        label: "Minimum Age Group",
        "is-required": false,
        modelValue: _ctx.payload.minimum_age_group,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.minimum_age_group) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_number_input, {
        label: "Maximum Age Group",
        "is-required": false,
        modelValue: _ctx.payload.maximum_age_group,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.maximum_age_group) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_select_input, {
        options: _ctx.users,
        label: _ctx.t('homeDetails.summary.administrator'),
        "is-required": true,
        error: _ctx.props.errors?.administrator_id,
        modelValue: _ctx.payload.administrator_id,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.administrator_id) = $event))
      }, null, 8, ["options", "label", "error", "modelValue"]),
      _createVNode(_component_select_input, {
        options: _ctx.users,
        label: _ctx.t('homeDetails.summary.personInCharge'),
        "is-required": true,
        error: _ctx.props.errors?.person_in_charge_id,
        modelValue: _ctx.payload.person_in_charge_id,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.person_in_charge_id) = $event))
      }, null, 8, ["options", "label", "error", "modelValue"]),
      _createVNode(_component_select_input, {
        options: _ctx.users,
        label: _ctx.t('homeDetails.summary.responsibleIndividual'),
        "is-required": true,
        error: _ctx.props.errors?.responsible_individual_id,
        modelValue: _ctx.payload.responsible_individual_id,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.responsible_individual_id) = $event))
      }, null, 8, ["options", "label", "error", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_date_input, {
        label: "Clearcare start date",
        "is-required": true,
        error: _ctx.props.errors?.start_date,
        modelValue: _ctx.payload.start_date,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.start_date) = $event))
      }, null, 8, ["error", "modelValue"]),
      _createVNode(_component_date_input, {
        label: "Clearcare end date",
        modelValue: _ctx.payload.end_date,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.end_date) = $event)),
        "is-required": false
      }, null, 8, ["modelValue"]),
      _createVNode(_component_radio_button, {
        buttons: _ctx.yesNoOptions,
        label: "Home is secure accomodation?",
        "is-required": false,
        modelValue: _ctx.payload.is_secure_accommodation,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.is_secure_accommodation) = $event))
      }, null, 8, ["buttons", "modelValue"]),
      _createVNode(_component_radio_button, {
        buttons: _ctx.yesNoOptions,
        label: "Home accomodates short term stays?",
        "is-required": false,
        modelValue: _ctx.payload.is_short_term_stay,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.is_short_term_stay) = $event))
      }, null, 8, ["buttons", "modelValue"]),
      _createVNode(_component_select_input, {
        options: _ctx.careGroups,
        label: "Care Group",
        error: _ctx.props.errors?.company_id,
        "is-required": true,
        modelValue: _ctx.payload.company_id,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.company_id) = $event))
      }, null, 8, ["options", "error", "modelValue"]),
      (_ctx.regions.length)
        ? (_openBlock(), _createBlock(_component_select_input, {
            key: 0,
            options: _ctx.regions,
            label: "Region",
            "is-required": false,
            modelValue: _ctx.payload.region_id,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.region_id) = $event))
          }, null, 8, ["options", "modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_text_input, {
        label: "Category",
        modelValue: _ctx.payload.category,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.category) = $event)),
        "is-required": true,
        "is-disabled": true
      }, null, 8, ["modelValue"])
    ])
  ]))
}