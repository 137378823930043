<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <radio-button
          :label="t('ypDetails.personalDetails.legalStatus')"
          layout="vertical"
          v-model="personal_details.legal_status"
          :buttons="[
            { id: 0, text: 'full care order' },
            { id: 1, text: 'Section 20' },
            { id: 2, text: 'Section 17' },
            { id: 3, text: 'Section 31' },
          ]"
        />
        <radio-button
          :label="t('ypDetails.personalDetails.emergencyPlacement')"
          layout="horizontal"
          v-model="personal_details.emergency_placement"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
        <text-input
          :label="t('ypDetails.personalDetails.placingAuthority')"
          v-model="personal_details.placing_authority"
        />
        <text-input
          :label="t('ypDetails.personalDetails.practiceManager')"
          v-model="personal_details.practice_manager"
        />
        <text-input
          :label="t('ypDetails.personalDetails.socialWorker')"
          v-model="personal_details.social_worker"
        />
        <text-input
          :label="t('ypDetails.personalDetails.independentReviewingOfficer')"
          v-model="personal_details.age"
        />
        <text-box
          lines="3"
          :label="t('ypDetails.personalDetails.billingInfo')"
          :isRequired="false"
          v-model="personal_details.extra_information"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import FileUploader from '@/components/Generic/Forms/Inputs/FileUploader.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      DateInput,
      SelectInput,
      TextBox,
      FileUploader,
      RadioButton,
    },
    setup() {
      const { t } = useI18n()
      let personal_details = {
        legal_status: 'Full Care Order',
        emergency_placement: 'No',
        placing_authority: 'Liverpool County Council',
        social_worker: 'Pam',
        gender: 'social construct',
        next_of_kin: 'Jackie',
        extra_information:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam quae quas quos velit aliquam delectus odit maxime excepturi vitae ut et',
      }

      return {
        personal_details,
        t,
      }
    },
  })
</script>
