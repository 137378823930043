import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects/schools', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (params: any) => {
    return axios
      .get(`projects/schools/${params.id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: async (payload: any) => {
    return axios
      .put(`projects/schools/${payload.id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
