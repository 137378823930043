
  import { useStore } from 'vuex'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    watch,
  } from 'vue'
  import TreeView from '@/components/Generic/List/TreeView.vue'
  import { TreeView as TreeViewType } from '@/types/components/TreeView'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { DatasourceCategory } from '@/types/modules/reporting/DatasourceCategory'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import DatasourceItem from './DatasourceItem.vue'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  const uuid = require('uuid-js')

  export default defineComponent({
    components: {
      TreeView,
      CcButton,
      DatasourceItem,
    },
    props: {
      report: {
        type: Object as PropType<BespokeReport | null>,
        required: false,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const state = reactive({
        categories: [] as TreeViewType[],
      })
      const payload = ref({
        datasources: [] as Datasource[],
      })

      watch(
        () => payload.value,
        () => emit('report-datasources-updated', payload.value),
        { deep: true }
      )

      const datasources = computed(
        () => store.getters['datasourceCategories/datasources']
      )

      const removeItem = (index: number) =>
        payload.value.datasources.splice(index, 1)
      const itemClicked = (item: TreeViewType) => {
        if (item.children) {
          return
        }

        const datasource = datasources.value.find(
          (datasource: Datasource) => datasource.id === item.id
        )

        if (!datasource) return

        payload.value.datasources.push(datasource)
      }

      const mapDatasources = (
        datasources: Datasource[] | null,
        level: number
      ): TreeViewType[] => {
        if (datasources) {
          return datasources.map((datasource) => {
            return {
              id: datasource.id,
              uuid: uuid.create().toString(),
              level: level,
              label: datasource.name,
              text: datasource.description,
            }
          })
        }

        return []
      }

      const mapCategories = (
        categories: DatasourceCategory[],
        level: number = 1
      ): TreeViewType[] => {
        return categories.map((category) => {
          return {
            id: category.id,
            uuid: uuid.create().toString(),
            level: level,
            expanded: level === 1,
            label: category.name,
            children: category?.subcategories
              ? mapCategories(category?.subcategories, level + 1).concat(
                  mapDatasources(category?.datasources, level + 1)
                )
              : [],
          }
        })
      }

      const mapToPayload = (report: BespokeReport) => {
        payload.value.datasources = report.datasources || []
      }

      watch(
        () => payload.value,
        () => emit('report-details-updated', payload.value),
        { deep: true }
      )

      watch(
        () => props.report,
        () => {
          if (props.report) {
            mapToPayload(props.report)
          }
        },
        { immediate: true }
      )

      const getDatasourceCategories = () => {
        return store.dispatch('datasourceCategories/index').then((response) => {
          state.categories = mapCategories(response.data)
        })
      }

      const loadDependencies = () => {
        return Promise.all([getDatasourceCategories()])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        loadDependencies().finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        state,
        payload,
        removeItem,
        itemClicked,
      }
    },
  })
