import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filing_cabinet = _resolveComponent("filing-cabinet")!
  const _component_employee_details = _resolveComponent("employee-details")!
  const _component_qualifications = _resolveComponent("qualifications")!
  const _component_recruitment_checks = _resolveComponent("recruitment-checks")!
  const _component_contract = _resolveComponent("contract")!
  const _component_reports = _resolveComponent("reports")!
  const _component_project_associations = _resolveComponent("project-associations")!
  const _component_user_permissions = _resolveComponent("user-permissions")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_task_attachments = _resolveComponent("task-attachments")!
  const _component_employee_references = _resolveComponent("employee-references")!
  const _component_rotas_index = _resolveComponent("rotas-index")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('filing-cabinet'))
      ? (_openBlock(), _createBlock(_component_filing_cabinet, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('details'))
      ? (_openBlock(), _createBlock(_component_employee_details, {
          key: 1,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('qualifications'))
      ? (_openBlock(), _createBlock(_component_qualifications, {
          key: 2,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('recruitment-checks') && _ctx.employee)
      ? (_openBlock(), _createBlock(_component_recruitment_checks, {
          key: 3,
          employee: _ctx.employee
        }, null, 8, ["employee"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('contract'))
      ? (_openBlock(), _createBlock(_component_contract, {
          key: 4,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('reports'))
      ? (_openBlock(), _createBlock(_component_reports, {
          key: 5,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('associations') && _ctx.employee?.project)
      ? (_openBlock(), _createBlock(_component_project_associations, {
          key: 6,
          "instance-id": _ctx.employee.id,
          project: _ctx.employee.project
        }, null, 8, ["instance-id", "project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('user-permissions') && _ctx.employee)
      ? (_openBlock(), _createBlock(_component_user_permissions, {
          key: 7,
          "project-id": _ctx.employee.project_id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('employee-attachments'))
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 8,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('log-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 9,
          "project-id": _ctx.id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('references'))
      ? (_openBlock(), _createBlock(_component_employee_references, {
          key: 10,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('rota') && _ctx.employee && _ctx.employee.project)
      ? (_openBlock(), _createBlock(_component_rotas_index, {
          key: 11,
          project: _ctx.employee.project
        }, null, 8, ["project"]))
      : _createCommentVNode("", true)
  ]))
}