
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: {
      TextInput,
      CcButton,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const user = computed(() => store.getters['auth/authenticatedUser'])
      const state = reactive({
        disableSubmission: false,
        payload: {
          username: '',
          email: '',
          first_name: '',
          last_name: '',
          password: '' as string | undefined,
          new_password: '' as string | undefined,
          new_password_confirmation: '' as string | undefined,
        },
        response: {
          errors: {
            first_name: '',
            last_name: '',
            password: '',
            new_password: '',
            new_password_confirmation: '',
          },
        },
      })
      const isCurrentPasswordRequired = computed(() => {
        return (
          !!state.payload.new_password ||
          !!state.payload.new_password_confirmation
        )
      })

      const submit = () => {
        if (
          state.payload.new_password !== state.payload.new_password_confirmation
        ) {
          state.response.errors.new_password = t(
            'auth.errors.newPassword.mismatch'
          )
          return
        }

        if (
          (state.payload.new_password ||
            state.payload.new_password_confirmation) &&
          !state.payload.password
        ) {
          state.response.errors.password = t('auth.errors.password.missing')
          return
        }

        if (
          !state.payload.new_password ||
          !state.payload.new_password_confirmation
        ) {
          delete state.payload.password
          delete state.payload.new_password
          delete state.payload.new_password_confirmation
        }

        state.disableSubmission = true
        store
          .dispatch('auth/updateProfile', state.payload)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            reset
          })
          .catch((error) => {
            state.response.errors = error.errors
            reset
          })
          .finally(() => (state.disableSubmission = false))
      }

      const reset = () => {
        state.disableSubmission = false
        state.payload.password = ''
        state.payload.new_password = ''
        state.payload.new_password_confirmation = ''
      }

      onMounted(() => {
        state.payload.username = user.value.username
        state.payload.email = user.value.email
        state.payload.first_name = user.value.first_name
        state.payload.last_name = user.value.last_name

        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/' },
            { name: t('auth.breadcrumb'), url: '/manage-profile' },
          ],
        })
      })

      return {
        t,
        state,
        submit,
        isCurrentPasswordRequired,
      }
    },
  })
