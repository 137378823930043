
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import {
    defineComponent,
    PropType,
    reactive,
    ref,
    computed,
    Ref,
    watch,
  } from 'vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import { useRoute } from 'vue-router'
  import ImageEditor from '@/composables/ImageEditor.js'
  import { getApiUrl } from '@/composables/Generic'

  export default defineComponent({
    components: {
      CcButton,
      ButtonGroup,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      const route = useRoute()
      props = reactive(props)

      const error = ref() as Ref<string | undefined>

      const state = reactive({
        showForm: false,
        module: route.query.template_id ? 'shared' : 'form',
        placeholder: '/placeholder.jpg',
        src: '',
        size: props.fieldAttributes?.size || 'small',
        imageEditor: null as any,
        allowedColors: [],
        activeColor: null as string | null,
        activeTool: 'pencil',
        editorInstantiated: false,
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': error.value,
          'text-gray-400': !error.value,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const canvas = ref()

      const closeForm = () => {
        state.showForm = false
      }

      const showForm = () => {
        state.showForm = true
      }

      const isDataURL = (value: string) => {
        return !!value.match(
          /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
        )
      }

      const getImage = computed(() => {
        if (state.src) {
          return state.src
        }

        if (
          !props.fieldAttributes?.value ||
          props.fieldAttributes?.value === ''
        ) {
          state.src = state.placeholder
        }

        if (Array.isArray(props.fieldAttributes?.value)) {
          setFileSrc(props.fieldAttributes?.value[0])
        }

        if (typeof props.fieldAttributes?.value === 'string') {
          if (isDataURL(props.fieldAttributes?.value)) {
            state.src = props.fieldAttributes?.value
          } else {
            try {
              let files = JSON.parse(props.fieldAttributes?.value)

              files.forEach((file: any) => {
                if (!file.fileUrl) {
                  state.src = state.placeholder
                } else {
                  state.src =
                    getApiUrl() +
                    `/files/view?file_id=${file.fileID}&module=${state.module}`
                }
              })
            } catch (e) {
              state.src = props.fieldAttributes?.value
            }
          }
        }

        return state.src
      })

      const setFileSrc = (event: File) => {
        let fileReader = new FileReader()

        fileReader.onload = () => {
          if (fileReader.result && typeof fileReader.result === 'string') {
            state.src = fileReader.result
          }
        }
        fileReader.readAsDataURL(event)
      }

      watch(
        () => state.showForm,
        () => initImageEditor()
      )

      const initImageEditor = () => {
        state.imageEditor = new ImageEditor()

        state.imageEditor.initCanvas(canvas.value, {
          width: 380,
          height: 300,
          imgSrc: getImage.value,
        })

        state.allowedColors = state.imageEditor.allowedColors()
        state.activeColor = state.imageEditor.activeColor()

        state.editorInstantiated = true
      }

      const changeColor = (color: string) => {
        state.imageEditor.color(color)
        state.activeColor = state.imageEditor.activeColor()
      }

      const changeTool = (tool: string) => {
        const toolActionMap: Record<string, any> = {
          pencil: state.imageEditor.freePath,
          circle: state.imageEditor.drawCircle,
          eraser: state.imageEditor.color.bind(state.imageEditor, 'white'),
        }

        if (toolActionMap[tool]) {
          toolActionMap[tool]()
          state.activeTool = tool

          if (tool !== 'eraser') {
            state.imageEditor.color(state.activeColor)
          }
        }
      }

      const saveImage = () => {
        state.src = state.imageEditor.save()
      }

      const clearImage = () => {
        state.imageEditor.clear()
        state.src = ''
      }

      watch(
        () => state.src,
        () => {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: state.src ? getBlobFromDataUrl(state.src) : undefined,
            row_index: props.fieldAttributes.row_index,
          })
        }
      )

      const getBlobFromDataUrl = (dataUrl: string) => {
        const blobBin = atob(dataUrl.split(',')[1])
        const arrayBin = []

        for (let i = 0; i < blobBin.length; i++) {
          arrayBin.push(blobBin.charCodeAt(i))
        }

        return new Blob([new Uint8Array(arrayBin)], { type: 'image/png' })
      }

      return {
        clearImage,
        saveImage,
        changeColor,
        setFileSrc,
        state,
        props,
        labelClasses,
        closeForm,
        showForm,
        getImage,
        error,
        canvas,
        changeTool,
      }
    },
  })
