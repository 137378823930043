import { ActionTree, GetterTree, MutationTree } from 'vuex'
import ProjectService from '@/services/projects/projects'
import { ProjectType } from '@/types/modules/projects/ProjectType'
import { ProjectFile } from '@/types/modules/files/ProjectFile'
import { Project } from '@/types/modules/projects/Project'

export type ProjectGetter = GetterTree<ProjectState, any>

export interface ProjectState {
  projects: Project[]
  types: ProjectType[]
  allowedParents: Project[]
  sensitiveFileCategories: any
  projectPermissions: object[]
  sensitiveAttachments: ProjectFile[]
  customFields: object[]
  customFieldGroups: object[]
  userPermissions: object[]
  selectedAttachments: object[]
}

// Change once backend connection setup
export const state: ProjectState = {
  projects: [],
  types: [],
  allowedParents: [],
  sensitiveAttachments: [],
  sensitiveFileCategories: [],
  projectPermissions: [],
  customFields: [],
  customFieldGroups: [],
  userPermissions: [],
  selectedAttachments: [],
}

export const getters: ProjectGetter = {
  projects: (state) => state.projects,
  types: (state) => state.types,
  allowedParents: (state) => state.allowedParents,
  sensitiveAttachments: (state) => state.sensitiveAttachments,
  sensitiveFileCategories: (state) => state.sensitiveFileCategories,
  projectPermissions: (state) => state.projectPermissions,
  customFields: (state) => state.customFields,
  customFieldGroups: (state) => state.customFieldGroups,
  userPermissions: (state) => state.userPermissions,
  selectedAttachments: (state) => state.selectedAttachments,
}

export const actions: ActionTree<ProjectState, any> = {
  index: async (context, params) => {
    const response = await ProjectService.index(params)
    if (response.success && params?.per_page) {
      context.commit('setProjects', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setProjects', response.data)

      return response
    }

    return response
  },
  deleteMany: async (_context, payload) => {
    return await ProjectService.deleteMany(payload)
  },
  restoreMany: async (_context, payload) => {
    return await ProjectService.restoreMany(payload)
  },
  getAllowedParents: (context, params) => {
    let results = Promise.resolve(ProjectService.getAllowedParents(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setAllowedParents', response.data)
    })

    return results
  },
  getProjectTypes: async (context, params) => {
    const response = await ProjectService.getProjectTypes(params)

    if (response.success && params?.per_page) {
      context.commit('setTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setTypes', response.data)

      return response
    }

    return response
  },
  getSelectedAttachments: async (context) => {
    const response = await context.getters['selectedAttachments']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  sensitiveAttachments: (context, params) => {
    let results = Promise.resolve(ProjectService.sensitiveAttachments(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setSensitiveAttachments', response.data)
    })
  },
  customFields: (context, params) => {
    let results = Promise.resolve(ProjectService.getCustomFields(params)) // Service to fetch stuff

    results.then((response) => {
      console.log(response.message)
      context.commit('setCustomFields', response.data) // use mutations to set it into state
      context.commit('setCustomFieldGroups', response.data)
    })
  },
  projectPermissions: (context, params) => {
    let results = Promise.resolve(ProjectService.projectPermissions(params))

    results.then((response) => {
      context.commit('setProjectPermissions', response.data)
    })

    return results
  },
  updateProjectPermission: (context, params) => {
    let results = Promise.resolve(
      ProjectService.updateProjectPermission(params)
    )
    results.then((response) => {
      console.log(response.message)
      context
    })
  },
  moveAttachments: async (_context, payload) => {
    return await ProjectService.moveAttachments(payload)
  },
}

export const mutations: MutationTree<ProjectState> = {
  setProjects: (state, projects: Project[]) => (state.projects = projects),
  setTypes: (state, types: ProjectType[]) => {
    state.types = types
  },
  setAllowedParents: (state, parents) => (state.allowedParents = parents),

  setSensitiveAttachments: (state, sensitiveAttachments) => {
    state.sensitiveAttachments = sensitiveAttachments
  },
  setCustomFields: (state, data) => {
    state.customFields = data // set it into state, getter can now use it
  },
  setCustomFieldGroups: (state, data) => {
    const fields = Object()
    for (const field of data) {
      const category = field.section_heading.name
        ? field.section_heading.name.toLowerCase()
        : 'uncategorised'

      if (!fields[category]) {
        fields[category] = []
      }

      fields[category].push(field)
    }
    state.customFieldGroups = fields
  },
  setUserPermissions: (state, permissions) =>
    (state.userPermissions = permissions),
  setProjectPermissions: (state, projectPermissions) => {
    state.projectPermissions = projectPermissions
  },
  setSelectedAttachments: (state, attachments) => {
    state.selectedAttachments = attachments
  },
}

export const projects = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
