
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import PermissionTile from '@/components/Generic/Button/PermissionTile.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { ModulePermission } from '@/types/modules/permissions/ModulePermission'
  import { UserModulePermissionMatrix } from '@/types/modules/users/UserModulePermission'

  export default defineComponent({
    components: {
      PermissionTile,
      CcButton,
    },
    props: {
      userId: {
        type: Number,
        required: true,
      },
    },
    emits: ['permission-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const permissions = computed(
        (): ModulePermission[] => store.getters['permissions/modulePermissions']
      )
      const userPermissions = computed(
        (): UserModulePermissionMatrix[] =>
          store.getters['users/userModulePermissions']
      )

      const updatePermission = (value: number, item: ModulePermission) => {
        payload.permissions[item.id] = value
        emit('permission-updated', payload)
      }

      const getModulePermissions = store
        .dispatch('permissions/getModulePermissions')
        .catch((error) =>
          store.dispatch('genericStore/pushNotification', error.message)
        )

      const getUserModulePermissions = store
        .dispatch('users/getUserModulePermissions', {
          user_id: props.userId,
        })
        .catch((error) =>
          store.dispatch('genericStore/pushNotification', error.message)
        )

      const loadDependencies = () => {
        return Promise.all([getModulePermissions, getUserModulePermissions])
      }

      const payload = reactive({
        type: 'module_permissions',
        user_id: props.userId as number,
        permissions: {} as Record<number, number>,
        granular_permissions: {},
      })

      const setInitialPermissionValues = async () => {
        userPermissions.value.forEach(
          (userPermission: UserModulePermissionMatrix) => {
            payload.permissions[userPermission.module_permission_id] =
              userPermission.value
          }
        )
      }

      const assignPermission = (item: ModulePermission) => {
        return (
          userPermissions.value.find(
            (userPermission: UserModulePermissionMatrix) =>
              userPermission.module_permission_id === item.id
          )?.value ?? 0
        )
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        await loadDependencies()
          .then(() => setInitialPermissionValues())
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        store,
        payload,
        permissions,
        assignPermission,
        updatePermission,
      }
    },
  })
