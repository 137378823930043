
  import { useStore } from 'vuex'
  import { onMounted, reactive } from 'vue'
  import { useI18n } from 'vue-i18n'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useRoute, useRouter } from 'vue-router'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import EmployeeSettings from './Settings.vue'
  import { isTab } from '@/composables/Generic'
  import EmployeesTable from './partials/EmployeesTable.vue'

  export default {
    components: {
      DataGrid,
      ConfirmDialog,
      EmployeeSettings,
      EmployeesTable,
    },
    setup() {
      const { t } = useI18n()
      const route = useRoute()
      const store = useStore()
      const router = useRouter()

      const state = reactive({
        tabs: [
          { name: 'Employees', url: `${route.path}?tab=employees` },
          { name: 'Settings', url: `${route.path}?tab=settings` },
        ],
      })

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: t('employees.breadcrumb'), url: '/project/employees' },
      ]
      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'employees' } })
        }

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: state.tabs })
      })

      return {
        isTab,
        state,
      }
    },
  }
