import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-display w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_DxTagBox = _resolveComponent("DxTagBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: _ctx.error,
      "is-required": _ctx.isRequired,
      "is-disabled": _ctx.isDisabled
    }, null, 8, ["label", "error", "is-required", "is-disabled"]),
    _createVNode(_component_DxTagBox, _mergeProps({ value: _ctx.modelValue }, _ctx.$attrs, {
      items: _ctx.tagItems,
      "search-enabled": true,
      "styling-mode": "underlined",
      class: _ctx.inputClasses,
      onValueChanged: _ctx.handleInput
    }), null, 16, ["value", "items", "class", "onValueChanged"]),
    (_ctx.message && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ]))
}