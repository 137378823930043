
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import { Form } from '@/types/modules/forms/Form'
  import { FormType } from '@/types/modules/forms/FormType'
  import { canEditModule, cannotEditModule } from '@/composables/Permissions'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { defineComponent, computed, reactive, onMounted, ref } from 'vue'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      hidden: {
        type: Boolean,
        required: false,
        default: false,
      },
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      action: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const grid = ref()
      const formTypes = computed(() => store.getters['formTypes/formTypes'])
      const state = reactive({
        activeChip: 'All Forms',
        chips: ['All Forms'],
        extraOptions: [] as string[],
        selectedRows: [] as Form[],
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data?.name, 'FormPreview', {
              id: options.data?.id,
            })
          },
        },
        {
          dataField: 'description',
          caption: 'Description',
          allowGrouping: false,
        },
        {
          dataField: 'status.name',
          caption: 'Status',
        },
        {
          showInColumnChooser: canEditModule('procedures') && !props.readOnly,
          visible: canEditModule('procedures') && !props.readOnly,
          caption: 'Form Designer',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              'Form Designer',
              'FormDesigner',
              undefined,
              {
                form_id: options.data?.id,
              }
            )
          },
        },
      ])
      const chipClicked = (chip: string) => {
        state.activeChip = chip
      }
      const queryFilter = computed(() => {
        const onlyArr = []

        if (props.hidden) {
          onlyArr.push('hidden')
        }
        return {
          relations: ['types', 'status'],
          only: onlyArr,
          ...(state.activeChip &&
            state.activeChip !== 'All Forms' && {
              form_type: store.getters['formTypes/formTypes'].find(
                (type: FormType) => type.name === state.activeChip
              ).id,
            }),
          ...props.filters,
        }
      })

      const rowsSelected = (rows: Form[]) => {
        if (props.readOnly) {
          return
        }

        const optionText = props.hidden ? 'Unhide Forms' : 'Hide Forms'
        state.selectedRows = rows

        if (rows.length && !state.extraOptions.includes(optionText)) {
          state.extraOptions.push(optionText)
        }

        if (!rows.length && state.extraOptions.includes(optionText)) {
          state.extraOptions.splice(state.extraOptions.indexOf(optionText), 1)
        }

        if (rows.length === 1 && !state.extraOptions.includes('Update Email')) {
          state.extraOptions.push('Update Email')
        }

        if (!rows.length && state.extraOptions.includes('Update Email')) {
          state.extraOptions.splice(
            state.extraOptions.indexOf('Update Email'),
            1
          )
        }
      }

      const addNewForm = () => router.push({ name: 'FormDesigner' })

      const hideSelectedForms = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('forms/toggleManyVisibility', {
            ids: state.selectedRows.map((row: Form) => row.id),
          })
          .then(() => grid.value?.refresh())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const optionClicked = (option: string) => {
        if (option === 'Hide Forms') {
          hideSelectedForms()
        }

        if (option === 'Update Email') {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('forms/setSelectedForms', state.selectedRows)
            .then(() => {
              router.push({ name: 'UpdateEmail' })
            })
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      onMounted(() => {
        store.dispatch('formTypes/index').then(() => {
          formTypes.value.forEach((type: FormType) => {
            state.chips.push(type.name)
          })
        })
      })

      return {
        grid,
        props,
        state,
        columns,
        chipClicked,
        queryFilter,
        rowsSelected,
        optionClicked,
        cannotEditModule,
        addNewForm,
      }
    },
  })
