import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ContractTypeService from '@/services/staffs/contractTypes'
import { ContractType } from '@/types/modules/staffs/ContractType'

export type ContractTypeGetter = GetterTree<ContractTypeState, any>

export interface ContractTypeState {
  contractTypes: ContractType[]
  contractType: ContractType | null
}

export const state: ContractTypeState = {
  contractTypes: [],
  contractType: null,
}

export const getters: ContractTypeGetter = {
  getContractTypes: (state) => state.contractTypes,
  getContractType: (state) => state.contractType,
}

export const actions: ActionTree<ContractTypeState, any> = {
  index: async (context, params) => {
    const response = await ContractTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setContractTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setContractTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(ContractTypeService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setContractType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(ContractTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(ContractTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(ContractTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(ContractTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(ContractTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(ContractTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(ContractTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<ContractTypeState> = {
  setContractTypes: (state, types: ContractType[]) =>
    (state.contractTypes = types),
  setContractType: (state, type: ContractType) => (state.contractType = type),
}

export const contractTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
