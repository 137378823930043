import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ProcedureService from '@/services/procedures/procedures'
import { Procedure } from '@/types/modules/procedures/Procedure'
import { ProcedureRecur } from '@/types/modules/procedures/ProcedureRecur'

export type ProcedureGetter = GetterTree<ProcedureState, any>

export interface ProcedureState {
  procedures: Procedure[]
  recurs: ProcedureRecur[]
}

export const state: ProcedureState = {
  procedures: [],
  recurs: [],
}

export const getters: ProcedureGetter = {
  procedures: (state) => state.procedures,
  getProcedureRecurs: (state) => state.recurs,
}

export const actions: ActionTree<ProcedureState, any> = {
  index: async (context, params) => {
    const response = await ProcedureService.index(params)

    if (response.success && params.per_page) {
      context.commit('setProcedures', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setProcedures', response.data)

      return response
    }

    return response
  },
  getProcedureRecurs: async (context, params = {}) => {
    let results = Promise.resolve(ProcedureService.getProcedureRecurs(params))

    results.then((response) => {
      if (response.success) {
        context.commit('setProcedureRecurs', response.data)
      }
    })
  },
}

export const mutations: MutationTree<ProcedureState> = {
  setProcedureRecurs: (state, recurs: ProcedureRecur[]) => {
    state.recurs = recurs
  },
  setProcedures: (state, procedures: Procedure[]) => {
    state.procedures = procedures
  },
}

export const procedures = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
