
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import PermissionTile from '@/components/Generic/Button/PermissionTile.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { DocumentPermission } from '@/types/modules/documents/DocumentPermission'

  export default defineComponent({
    components: {
      PermissionTile,
      CcButton,
    },
    props: {
      userId: {
        type: Number,
        required: true,
      },
    },
    emits: ['permission-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const permissions = computed(
        (): DocumentPermission[] =>
          store.getters['documents/documentPermissions']
      )

      const updatePermission = (value: number, item: any) => {
        payload.permissions[item.id] = value
        emit('permission-updated', payload)
      }

      const getDocumentPermissions = store
        .dispatch('documents/getDocumentPermissions', {
          user_id: props.userId,
        })
        .catch((error) =>
          store.dispatch('genericStore/pushNotification', error.message)
        )

      const loadDependencies = () => {
        return Promise.all([getDocumentPermissions])
      }

      const payload = reactive({
        type: 'document_permissions',
        user_id: props.userId,
        permissions: {} as Record<number, number>,
        granular_permissions: {},
      })

      const setInitialPermissionValues = async () => {
        permissions.value.forEach((permission: DocumentPermission) => {
          payload.permissions[permission.id] = permission.value
        })
      }

      const assignPermission = (item: any) => {
        return (
          permissions.value.find(
            (userPermission: DocumentPermission) =>
              userPermission.id === item.id
          )?.value ?? 0
        )
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        await loadDependencies()
          .then(() => setInitialPermissionValues())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        permissions,
        assignPermission,
        updatePermission,
        payload,
      }
    },
  })
