
  import { defineComponent } from '@vue/runtime-core'
  import ProceduresTable from './partials/ProceduresTable.vue'

  export default defineComponent({
    components: { ProceduresTable },
    setup() {
      return {}
    },
  })
