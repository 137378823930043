import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    "query-filter": _ctx.filters,
    action: "regReports/index",
    columns: _ctx.columns,
    onChipClicked: _ctx.setChip,
    chips: _ctx.state.chips
  }, null, 8, ["query-filter", "columns", "onChipClicked", "chips"]))
}