import { GetterTree, MutationTree, ActionTree } from 'vuex'
import MedicationStockCategoryService from '@/services/mars/medicationStockCategories'
import { MedicationStockCategory } from '@/types/modules/mars/MedicationStockCategory'

export type MedicationStockCategoryGetter = GetterTree<
  MedicationStockCategoryState,
  any
>

export interface MedicationStockCategoryState {
  stockCategories: MedicationStockCategory[]
  stockCategory: MedicationStockCategory | null
}

export const state: MedicationStockCategoryState = {
  stockCategories: [],
  stockCategory: null,
}

export const getters: MedicationStockCategoryGetter = {
  getStockCategories: (state) => state.stockCategories,
  getStockCategory: (state) => state.stockCategories,
}

export const actions: ActionTree<MedicationStockCategoryState, any> = {
  index: async (context, params) => {
    const response = await MedicationStockCategoryService.index(params)

    if (response.success && params.per_page) {
      context.commit('setStockCategories', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setStockCategories', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      MedicationStockCategoryService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setStockCategory', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(MedicationStockCategoryService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      MedicationStockCategoryService.update(payload.id, payload)
    )
  },
  delete: (_context, id) => {
    return Promise.resolve(MedicationStockCategoryService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(
      MedicationStockCategoryService.saveOrderings(payload)
    )
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(MedicationStockCategoryService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(MedicationStockCategoryService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      MedicationStockCategoryService.toggleManyVisibility(params)
    )
  },
}

export const mutations: MutationTree<MedicationStockCategoryState> = {
  setStockCategories: (state, categories: MedicationStockCategory[]) => {
    state.stockCategories = categories
  },
  setStockCategory: (state, category: MedicationStockCategory) => {
    state.stockCategory = category
  },
}

export const medicationStockCategories = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
