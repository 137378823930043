
  import { reactive, computed, defineComponent } from 'vue'
  import UserAccount from '@/components/Generic/User/UserAccount.vue'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'

  export default defineComponent({
    components: {
      UserAccount,
    },
    props: {
      isCollapsed: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const userAccount = computed(
        (): User => store.getters['auth/authenticatedUser']
      )

      const logout = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('auth/logout')
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const logoutClasses = computed(() => ({
        'px-5': true,
        'py-4': true,
        'text-sm': true,
        'block': true,
        'hover:bg-white': true,
        'hover:text-blue-500': true,
        'transition-all': true,
        'leading-5': true,
        'tracking-slight': true,
        'font-semibold': true,
        'text-center border-none': props.isCollapsed,
        'flex items-center': !props.isCollapsed,
      }))

      const helpClasses = computed(() => ({
        'px-5': true,
        'py-4': true,
        'text-sm': true,
        'block': true,
        'hover:bg-white': true,
        'hover:text-blue-500': true,
        'transition-all': true,
        'leading-5': true,
        'tracking-slight': true,
        'font-semibold': true,
        'text-center border-none': props.isCollapsed,
        'flex items-center': !props.isCollapsed,
      }))

      return {
        props,
        logout,
        logoutClasses,
        helpClasses,
        userAccount,
      }
    },
  })
