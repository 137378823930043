
  import { defineComponent, onMounted, PropType, reactive, Ref, ref } from 'vue'
  import { Stimulsoft } from 'stimulsoft-dashboards-js/Scripts/stimulsoft.blockly.editor'
  import { useStore } from 'vuex'
  import { viewerOptions, registerLicenseKey } from '@/composables/Reporting'
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import { goBack } from '@/composables/Generic'
  import { useI18n } from 'vue-i18n'
  import { ExportOption } from '@/types/components/Export'

  export default defineComponent({
    components: { PageToolbar, CcButton, ButtonGroup },
    props: {
      report: {
        type: Object as PropType<PublishedBespokeReport>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const exportOptions = ref<ExportOption[]>([
        {
          name: 'Export To PDF',
          icon: 'fas fa-file-pdf',
        },
      ])

      const dataSets = ref([]) as Ref<object[][]>
      const report = new Stimulsoft.Report.StiReport()
      const viewer = new Stimulsoft.Viewer.StiViewer(
        viewerOptions(),
        'StiViewer',
        false
      )

      const exportClicked = async (exportType: string) => {
        const exportMap = {
          'Export To PDF': {
            type: Stimulsoft.Report.StiExportFormat.Pdf,
            extension: 'pdf',
          },
        } as Record<string, any>

        return exportReport(exportMap[exportType])
      }

      const exportReport = (exportType: Record<string, any>) => {
        report.exportDocumentAsync((data: any) => {
          Stimulsoft.System.StiObject.saveAs(
            data,
            `${props.report.name}.${exportType.extension}`
          )
        }, exportType.type)
      }

      const setupReport = () => {
        report.load(props.report.definition)
        report.dictionary.databases.clear()
        return Promise.resolve('Report setup complete.')
      }

      const buildDataSets = async () => {
        dataSets.value = props.report.datasets
        dataSets.value.forEach((data) => {
          let dataSet = new Stimulsoft.System.Data.DataSet(
            'ClearCare Solutions'
          )

          dataSet.readJson(data)
          report.regData(dataSet.dataSetName, '', dataSet)
        })

        return Promise.resolve('Building datasets complete.')
      }

      const renderViewer = () => {
        report.dictionary.synchronize()
        viewer.report = report
        report.cacheAllData = true
        viewer.renderHtml('viewer')
        return Promise.resolve('Viewer rendered.')
      }

      const init = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        await Promise.all([
          registerLicenseKey(),
          setupReport(),
          buildDataSets().then(() => renderViewer()),
        ])

        store.dispatch('genericStore/showPageLoader', false)
      }

      onMounted(() => init())

      return {
        t,
        report,
        viewer,
        goBack,
        dataSets,
        exportOptions,
        exportClicked,
      }
    },
  })
