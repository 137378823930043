
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, reactive, watch } from 'vue'
  import ProjectAssociations from '../partials/ProjectAssociations/ProjectAssociations.vue'
  import UserPermissions from '../partials/UserPermissions/UserPermissions.vue'
  import ProjectAttachments from '../partials/Attachments/ProjectAttachments.vue'
  import FilingCabinet from '../partials/FilingCabinet/FilingCabinet.vue'
  import SchoolDetails from './partials/SchoolDetails.vue'
  import { isTab } from '@/composables/Generic'
  import { School } from '@/types/modules/projects/School'
  import MedicalCabinet from '../../mars/Index.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      ProjectAssociations,
      UserPermissions,
      ProjectAttachments,
      FilingCabinet,
      SchoolDetails,
      MedicalCabinet,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const school = computed((): School => store.getters['schools/school'])

      const tabs = [
        { name: 'Filing Cabinet', url: `${route.path}?tab=filing-cabinet` },
        { name: 'Details', url: `${route.path}?tab=details` },
        {
          name: 'School Attachments',
          url: `${route.path}?tab=school-attachments`,
        },
        { name: 'Log Attachments', url: `${route.path}?tab=log-attachments` },
        { name: 'User Permissions', url: `${route.path}?tab=user-permissions` },
        { name: 'Associations', url: `${route.path}?tab=associations` },
        { name: 'Medical Cabinets', url: `${route.path}?tab=medical-cabinet` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: t('schools.breadcrumb'), url: '/projects/schools' },
        ]

        if (!school.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: school.value ? school.value.project?.name! : '',
          url: `/projects/schools/${school.value.id}`,
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'filing-cabinet' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('schools/show', { id: props.id }).finally(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        })
      })

      return {
        school,
        isTab,
      }
    },
  })
