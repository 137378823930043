import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "text-xs break-words truncate whitespace-normal mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["whitespace-normal w-full p-4 bg-white h-32 rounded-lg shadow-md hover:bg-gray-100 border-2", _ctx.props.active ? 'border-blue-200' : 'border-gray-200'])
    }, [
      _createElementVNode("h3", {
        class: "font-bold text-lg tracking-slight break-words truncate",
        title: _ctx.props.form.name
      }, _toDisplayString(_ctx.props.form.name), 9, _hoisted_1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.props.form.description), 1)
    ], 2)
  ]))
}