import axios from 'axios'

export default {
  index: (params: any) => {
    return axios
      .get('forms/fields/groups', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
