import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects/residents', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any) => {
    return axios
      .get(`projects/residents/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getRelationships: (params: any) => {
    return axios
      .get(`projects/residents/${params.resident_id}/relationships`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getPhotos: (params: any) => {
    params = params // contains task photos or regular

    return {
      success: true,
      message: 'Young person photos returned successfully',
      data: [
        {
          id: 1,
          company_id: 18,
          name: 'Test Image 1',
          status: 1,
          file_path: 'Test_Image_1.jpg',
          url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
          created_at: '2022-04-14T09:08:10.000000Z',
          updated_at: '2022-04-14T09:08:10.000000Z',
          deleted_at: null,
        },
      ],
    }
  },
  deletePhoto: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Deleted photo',
      data: {
        id: 1,
        company_id: 18,
        name: 'Test Image 1',
        status: 1,
        file_path: 'Test_Image_1.jpg',
        url: 'https://images.unsplash.com/photo-1635719013119-8b8f03d0f72a?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60',
        created_at: '2022-04-14T09:08:10.000000Z',
        updated_at: '2022-04-14T09:08:10.000000Z',
        deleted_at: Date.now(),
      },
    }
  },
}
