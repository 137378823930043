
  import { Project } from '@/types/modules/projects/Project'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { useStore } from 'vuex'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { FormType } from '@/types/modules/forms/FormType'
  import { format } from 'date-fns'
  import { TriggerConfig } from '@/types/modules/tasks/TriggerConfig'
  import ProjectService from '@/services/projects/projects'
  import { LogTrigger } from '@/composables/ManageLog'

  export default defineComponent({
    components: {
      DateInput,
      TextInput,
      SelectInput,
      CcButton,
    },
    props: {
      config: {
        required: true,
        type: Object as PropType<TriggerConfig>,
      },
      showConfigByDefault: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['config-changed'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const payload = ref({
        form_id: props.config.triggered_form?.id,
        trigger_date: format(new Date(), 'yyyy-MM-dd'),
        project_id: null,
      }) as Ref<LogTrigger>
      const configToggle = ref(props.showConfigByDefault) as Ref<boolean>
      const closeConfig = () => (configToggle.value = false)
      const toggleConfig = () => (configToggle.value = !configToggle.value)
      const availableProjects = ref([]) as Ref<Project[]>
      const projectType = ref() as Ref<string | undefined>
      const selectedProjectType = computed(() => {
        if (props.config.triggered_form && props.config.triggered_form.types) {
          return props.config.triggered_form.types.find((type: FormType) => {
            return type.name === projectType.value
          })?.project_type
        }

        return undefined
      })

      const isProjectTypeFieldDisabled = computed(() => {
        if (
          props.config.triggered_form &&
          props.config.triggered_form.types.length > 1
        ) {
          return false
        }

        return true
      })

      const types = computed(() => {
        if (props.config.triggered_form) {
          return props.config.triggered_form.types?.map((type: FormType) => {
            return {
              label: type.name,
              value: type.name,
            }
          })
        }

        return []
      })

      // Project stuff
      const isProjectFieldDisabled = computed(() => {
        return (
          availableProjects.value.length === 1 ||
          (props.config.form?.always_trigger_for_same_project === 1 &&
            props.config.form?.types
              .map((type: FormType) => type.name)
              .includes(props.config.project?.project_type?.name || ''))
        )
      })

      const selectedProject = computed((): Project | undefined => {
        return availableProjects.value?.find(
          (project: Project) => project.id === Number(payload.value.project_id)
        )
      })

      const getProjects = () => {
        let filters = {} as Record<string, any>

        if (props.config.triggered_form && projectType.value) {
          filters['type'] = [projectType.value]
          if (
            props.config.form &&
            props.config.form.restrict_projects_by_association === 1
          ) {
            if (!selectedProjectType.value?.is_parent) {
              // we want all the parents children
              filters['child_id'] = props.config.project_parent_id
            } else {
              // we want all the childs parents
              filters['parent_id'] = props.config.project?.id
            }
          }
        }

        store.dispatch('genericStore/showPageLoader', true)

        const results = Promise.resolve(ProjectService.index(filters))

        results
          .then((response) => {
            availableProjects.value = response.data
            if (response.data.length === 1) {
              payload.value.project_id = response.data[0].id
            } else if (
              response.data
                .map((project: Project) => project.id)
                .includes(props.config.project_parent_id)
            ) {
              payload.value.project_id = props.config.project_parent_id || null
            }
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const projects = computed(() => {
        if (availableProjects.value.length) {
          return availableProjects.value.map((project: Project) => {
            return {
              label: project.name,
              value: project.id,
            }
          })
        }

        return []
      })

      // Trigger / Task stuff
      const triggerTaskTypes = computed((): string[] => {
        return props.config.triggered_form?.types.length
          ? props.config.triggered_form?.types.map((type: FormType) =>
              type.name.toLowerCase()
            )
          : []
      })

      const triggerConfigMessage = computed(() => {
        if (props.config.triggered_form) {
          if (
            !selectedProject.value ||
            (selectedProject.value &&
              !triggerTaskTypes.value.includes(
                selectedProject.value.project_type_label.toLowerCase()
              ))
          ) {
            let reason = null

            if (!projects.value.length) {
              return 'there are no projects to be selected. This will not prevent form submission.'
            }

            if (!payload.value.project_id) {
              reason = 'no project has been selected.'
            }

            if (reason) {
              return `( ${props.config.triggered_form.name} task would not be triggered because ${reason} )`
            }
          }

          if (selectedProject.value) {
            if (props.config.form?.trigger_has_date) {
              let triggered_date = payload.value.trigger_date
                ? format(new Date(payload.value.trigger_date), 'do MMMM yyyy')
                : 'N/A'
              return `( ${props.config.triggered_form.name} would be triggered for ${selectedProject.value.name} on ${triggered_date} )`
            }

            return `( ${props.config.triggered_form.name} would be triggered for ${selectedProject.value.name} )`
          }
        }

        return null
      })

      const triggerIsConfigurable = computed(() => {
        if (!projects.value.length) {
          return false
        }

        if (
          props.config.triggered_form &&
          props.config.triggered_form.types?.length > 1
        ) {
          return true
        }

        if (
          !props.config.form?.trigger_has_date &&
          projects.value.length === 1
        ) {
          return false
        }

        if (
          props.config.form?.trigger_has_date ||
          props.config.form?.always_trigger_for_same_project !== 1
        ) {
          return true
        }

        return false
      })

      const mapToPayload = () => {
        payload.value.project_id = props.config.form?.types
          ? props.config.form?.types
              .map((type: FormType) => type.name)
              .includes(props.config.project?.project_type?.name || '')
            ? props.config.project?.id || null
            : null
          : null
      }

      watch(payload, () => emit('config-changed', payload.value), {
        deep: true,
      })

      watch(
        () => props.config.project_parent_id,
        () => {
          getProjects()
        }
      )

      watch(projectType, () => {
        getProjects()
      })

      onMounted(() => {
        mapToPayload()

        if (props.config.triggered_form) {
          if (props.config.triggered_form.types.length === 1) {
            projectType.value = props.config.triggered_form.types[0].name
          }

          if (props.config.triggered_form.types.length > 1) {
            projectType.value = props.config.triggered_form.types.find(
              (type: FormType) => {
                return type.name === props.config.project?.project_type?.name
              }
            )?.name
          }

          if (!projectType.value) {
            projectType.value = props.config.triggered_form.types.find(
              (type: FormType) => {
                return (
                  type.name ===
                  props.config.project?.parents?.find((parent: Project) => {
                    return parent.id === props.config.project_parent_id
                  })?.project_type?.name
                )
              }
            )?.name
          }
        }
      })

      return {
        props,
        format,
        payload,
        closeConfig,
        configToggle,
        toggleConfig,
        triggerIsConfigurable,
        triggerConfigMessage,
        projects,
        isProjectFieldDisabled,
        types,
        projectType,
        isProjectTypeFieldDisabled,
        selectedProjectType,
      }
    },
  })
