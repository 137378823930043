import HomeIndex from '../views/modules/projects/homes/Index.vue'
import HomeShow from '../views/modules/projects/homes/Show.vue'
import EmployeeIndex from '../views/modules/projects/employees/Index.vue'
import EmployeeShow from '../views/modules/projects/employees/Show.vue'
import SchoolShow from '../views/modules/projects/schools/Show.vue'
import VehicleIndex from '../views/modules/projects/vehicles/Index.vue'
import YoungPeopleIndex from '../views/modules/projects/young_people/Index.vue'
import YoungPersonShow from '../views/modules/projects/young_people/Show.vue'
import SchoolIndex from '../views/modules/projects/schools/Index.vue'
import { RouteRecordRaw } from 'vue-router'
import VehicleShow from '@/views/modules/projects/vehicles/Show.vue'
import RegReport from '@/views/modules/projects/partials/RegReporting/RegReport.vue'
import MoveProjectAttachments from '@/views/modules/projects/partials/Attachments/MoveProjectAttachments.vue'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'
import CreateTask from '@/views/modules/tasks/CreateTask.vue'
import ManageYoungPersonType from '@/views/lookups/ManageYoungPersonType.vue'
import ManageLocalAuthority from '@/views/lookups/ManageLocalAuthority.vue'
import ManageLegalStatus from '@/views/lookups/ManageLegalStatus.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import RestoreYoungPersonTypes from '@/views/modules/projects/young_people/partials/RestoreYoungPersonTypes.vue'
import RestoreLocalAuthorities from '@/views/modules/projects/young_people/partials/RestoreLocalAuthorities.vue'
import RestoreLegalStatuses from '@/views/modules/projects/young_people/partials/RestoreLegalStatuses.vue'
import RestoreCustomFields from '@/views/modules/projects/partials/RestoreCustomFields.vue'
import ManageCustomField from '@/views/lookups/ManageCustomField.vue'
import RestoreCustomFieldGroups from '@/views/modules/projects/partials/RestoreCustomFieldGroups.vue'
import ManageCustomFieldGroup from '@/views/lookups/ManageCustomFieldGroup.vue'
import VehicleCreate from '@/views/modules/projects/vehicles/partials/VehicleCreate.vue'
import AssociationCreate from '@/views/modules/projects/partials/ProjectAssociations/partials/AssociationCreate.vue'
import AssociationEdit from '@/views/modules/projects/partials/ProjectAssociations/partials/AssociationEdit.vue'
import AssociationDelete from '@/views/modules/projects/partials/ProjectAssociations/partials/AssociationDelete.vue'
import AssociationRestore from '@/views/modules/projects/partials/ProjectAssociations/partials/AssociationRestore.vue'

export default [
  {
    path: '/projects/:projectType/:id/associations/:associationId/edit',
    name: 'AssociationEdit',
    props: true,
    component: AssociationEdit,
    meta: {
      title: 'Association Edit',
      middleware: [auth],
    },
  },
  {
    path: '/projects/:projectType/:id/associations/delete',
    name: 'AssociationDelete',
    props: true,
    component: AssociationDelete,
    meta: {
      title: 'Delete Association',
      middleware: [auth],
    },
  },
  {
    path: '/projects/:projectType/:id/associations/restore',
    name: 'AssociationRestore',
    props: true,
    component: AssociationRestore,
    meta: {
      title: 'Restore Association',
      middleware: [auth],
    },
  },
  {
    path: '/projects/:projectType/:id/associations/create',
    name: 'AssociationCreate',
    props: true,
    component: AssociationCreate,
    meta: {
      title: 'Association Create',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/regulatory-reporting/:id',
    name: 'RegulatoryReporting',
    props: true,
    component: RegReport,
    meta: {
      title: 'Regulatory Reporting',
      middleware: [auth],
    },
  },
  {
    path: '/projects/homes/:id',
    name: 'HomeShow',
    props: true,
    component: HomeShow,
    meta: {
      title: 'Homes',
      module: 'homes',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/homes',
    name: 'Home',
    component: HomeIndex,
    meta: {
      title: 'Homes',
      module: 'homes',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/employees',
    name: 'Employees',
    component: EmployeeIndex,
    meta: {
      title: 'Employees',
      module: 'employees',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/employees/:id',
    name: 'EmployeeShow',
    props: true,
    component: EmployeeShow,
    meta: {
      title: 'Employees',
      module: 'employees',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/vehicles/create',
    name: 'VehicleCreate',
    component: VehicleCreate,
    meta: {
      title: 'Vehicle Create',
      module: 'vehicles',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles',
    name: 'Vehicles',
    component: VehicleIndex,
    meta: {
      title: 'Vehicles',
      module: 'vehicles',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/vehicles/:id',
    name: 'VehicleShow',
    props: true,
    component: VehicleShow,
    meta: {
      title: 'Vehicles',
      module: 'vehicles',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/young_people',
    name: 'YoungPeople',
    component: YoungPeopleIndex,
    meta: {
      title: 'Young People',
      module: 'residents',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/young_people/:id',
    name: 'YoungPersonShow',
    props: true,
    component: YoungPersonShow,
    meta: {
      title: 'Young People',
      module: 'residents',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/schools',
    name: 'Schools',
    component: SchoolIndex,
    meta: {
      title: 'Schools',
      module: 'schools',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/schools/:id',
    name: 'SchoolShow',
    props: true,
    component: SchoolShow,
    meta: {
      title: 'Schools',
      module: 'schools',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/:id/move-attachments',
    name: 'MoveProjectAttachments',
    props: true,
    component: MoveProjectAttachments,
    meta: {
      title: 'Move Attachments',
      module: 'files',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/:projectType/:id/tasks/create',
    name: 'CreateTask',
    props: true,
    component: CreateTask,
    meta: {
      title: 'Create Task',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/types/create',
    name: 'YoungPersonTypeCreate',
    component: ManageYoungPersonType,
    props: () => ({
      showAction: 'youngPersonTypes/show',
      storeAction: 'youngPersonTypes/store',
    }),
    meta: {
      title: 'Create Young Person Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/types/:id/edit',
    name: 'YoungPersonTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'youngPersonTypes/show',
      updateAction: 'youngPersonTypes/update',
      deleteAction: 'youngPersonTypes/delete',
    }),
    component: ManageYoungPersonType,
    meta: {
      title: 'Update Young Person Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/types/delete',
    name: 'YoungPersonTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Young Person Type(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/types/restore',
    name: 'YoungPersonTypeRestore',
    component: RestoreYoungPersonTypes,
    meta: {
      title: 'Restore Deleted Young Person Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/local-authorities/create',
    name: 'LocalAuthorityCreate',
    component: ManageLocalAuthority,
    props: () => ({
      showAction: 'localAuthorities/show',
      storeAction: 'localAuthorities/store',
    }),
    meta: {
      title: 'Create Local Authority',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/local-authorities/:id/edit',
    name: 'LocalAuthorityEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'localAuthorities/show',
      updateAction: 'localAuthorities/update',
      deleteAction: 'localAuthorities/delete',
    }),
    component: ManageLocalAuthority,
    meta: {
      title: 'Update Local Authority',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/local-authorities/delete',
    name: 'LocalAuthorityDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Local Authorities',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/local-authorities/restore',
    name: 'LocalAuthorityRestore',
    component: RestoreLocalAuthorities,
    meta: {
      title: 'Restore Deleted Local Authorities',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/legal-statuses/create',
    name: 'YoungPersonLegalStatusCreate',
    component: ManageLegalStatus,
    props: () => ({
      showAction: 'youngPersonLegalStatuses/show',
      storeAction: 'youngPersonLegalStatuses/store',
    }),
    meta: {
      title: 'Create Legal Status',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/legal-statuses/:id/edit',
    name: 'YoungPersonLegalStatusEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'youngPersonLegalStatuses/show',
      updateAction: 'youngPersonLegalStatuses/update',
      deleteAction: 'youngPersonLegalStatuses/delete',
    }),
    component: ManageLegalStatus,
    meta: {
      title: 'Update Legal Status',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/legal-statuses/delete',
    name: 'YoungPersonLegalStatusDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Legal Statuses',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/legal-statuses/restore',
    name: 'YoungPersonLegalStatusRestore',
    component: RestoreLegalStatuses,
    meta: {
      title: 'Restore Deleted Legal Statuses',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/create',
    name: 'YoungPersonProjectTypeAttributeCreate',
    component: ManageCustomField,
    props: () => ({
      type: 'resident',
      showAction: 'projectTypeAttributes/show',
      storeAction: 'projectTypeAttributes/store',
    }),
    meta: {
      title: 'Create Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/:id/edit',
    name: 'YoungPersonProjectTypeAttributeEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'resident',
      showAction: 'projectTypeAttributes/show',
      updateAction: 'projectTypeAttributes/update',
      deleteAction: 'projectTypeAttributes/delete',
    }),
    component: ManageCustomField,
    meta: {
      title: 'Update Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/delete',
    name: 'YoungPersonProjectTypeAttributeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/restore',
    name: 'YoungPersonProjectTypeAttributeRestore',
    component: RestoreCustomFields,
    props: () => ({
      type: 'resident',
    }),
    meta: {
      title: 'Restore Deleted Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/create',
    name: 'EmployeeProjectTypeAttributeCreate',
    component: ManageCustomField,
    props: () => ({
      type: 'employee',
      showAction: 'projectTypeAttributes/show',
      storeAction: 'projectTypeAttributes/store',
    }),
    meta: {
      title: 'Create Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/:id/edit',
    name: 'EmployeeProjectTypeAttributeEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'employee',
      showAction: 'projectTypeAttributes/show',
      updateAction: 'projectTypeAttributes/update',
      deleteAction: 'projectTypeAttributes/delete',
    }),
    component: ManageCustomField,
    meta: {
      title: 'Update Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/delete',
    name: 'EmployeeProjectTypeAttributeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/restore',
    name: 'EmployeeProjectTypeAttributeRestore',
    component: RestoreCustomFields,
    props: () => ({
      type: 'employee',
    }),
    meta: {
      title: 'Restore Deleted Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/create',
    name: 'VehicleProjectTypeAttributeCreate',
    component: ManageCustomField,
    props: () => ({
      type: 'vehicle',
      showAction: 'projectTypeAttributes/show',
      storeAction: 'projectTypeAttributes/store',
    }),
    meta: {
      title: 'Create Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/:id/edit',
    name: 'VehicleProjectTypeAttributeEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'vehicle',
      showAction: 'projectTypeAttributes/show',
      updateAction: 'projectTypeAttributes/update',
      deleteAction: 'projectTypeAttributes/delete',
    }),
    component: ManageCustomField,
    meta: {
      title: 'Update Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/delete',
    name: 'VehicleProjectTypeAttributeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/restore',
    name: 'VehicleProjectTypeAttributeRestore',
    component: RestoreCustomFields,
    props: () => ({
      type: 'vehicle',
    }),
    meta: {
      title: 'Restore Deleted Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/create',
    name: 'SchoolProjectTypeAttributeCreate',
    component: ManageCustomField,
    props: () => ({
      type: 'school',
      showAction: 'projectTypeAttributes/show',
      storeAction: 'projectTypeAttributes/store',
    }),
    meta: {
      title: 'Create Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/:id/edit',
    name: 'SchoolProjectTypeAttributeEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'school',
      showAction: 'projectTypeAttributes/show',
      updateAction: 'projectTypeAttributes/update',
      deleteAction: 'projectTypeAttributes/delete',
    }),
    component: ManageCustomField,
    meta: {
      title: 'Update Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/delete',
    name: 'SchoolProjectTypeAttributeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/restore',
    name: 'SchoolProjectTypeAttributeRestore',
    component: RestoreCustomFields,
    props: () => ({
      type: 'school',
    }),
    meta: {
      title: 'Restore Deleted Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/create',
    name: 'HomeProjectTypeAttributeCreate',
    component: ManageCustomField,
    props: () => ({
      type: 'home',
      showAction: 'projectTypeAttributes/show',
      storeAction: 'projectTypeAttributes/store',
    }),
    meta: {
      title: 'Create Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/:id/edit',
    name: 'HomeProjectTypeAttributeEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'home',
      showAction: 'projectTypeAttributes/show',
      updateAction: 'projectTypeAttributes/update',
      deleteAction: 'projectTypeAttributes/delete',
    }),
    component: ManageCustomField,
    meta: {
      title: 'Update Custom Field',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/delete',
    name: 'HomeProjectTypeAttributeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/restore',
    name: 'HomeProjectTypeAttributeRestore',
    component: RestoreCustomFields,
    props: () => ({
      type: 'home',
    }),
    meta: {
      title: 'Restore Deleted Custom Fields',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/groups/create',
    name: 'YoungPersonProjectTypeSectionCreate',
    component: ManageCustomFieldGroup,
    props: () => ({
      type: 'resident',
      showAction: 'projectTypeSections/show',
      storeAction: 'projectTypeSections/store',
    }),
    meta: {
      title: 'Create Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/groups/:id/edit',
    name: 'YoungPersonProjectTypeSectionEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'resident',
      showAction: 'projectTypeSections/show',
      updateAction: 'projectTypeSections/update',
      deleteAction: 'projectTypeSections/delete',
    }),
    component: ManageCustomFieldGroup,
    meta: {
      title: 'Update Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/groups/delete',
    name: 'YoungPersonProjectTypeSectionDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/young-people/fields/groups/restore',
    name: 'YoungPersonProjectTypeSectionRestore',
    component: RestoreCustomFieldGroups,
    props: () => ({
      type: 'resident',
    }),
    meta: {
      title: 'Restore Deleted Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/groups/create',
    name: 'EmployeeProjectTypeSectionCreate',
    component: ManageCustomFieldGroup,
    props: () => ({
      type: 'employee',
      showAction: 'projectTypeSections/show',
      storeAction: 'projectTypeSections/store',
    }),
    meta: {
      title: 'Create Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/groups/:id/edit',
    name: 'EmployeeProjectTypeSectionEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'employee',
      showAction: 'projectTypeSections/show',
      updateAction: 'projectTypeSections/update',
      deleteAction: 'projectTypeSections/delete',
    }),
    component: ManageCustomFieldGroup,
    meta: {
      title: 'Update Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/groups/delete',
    name: 'EmployeeProjectTypeSectionDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/fields/groups/restore',
    name: 'EmployeeProjectTypeSectionRestore',
    component: RestoreCustomFieldGroups,
    props: () => ({
      type: 'employee',
    }),
    meta: {
      title: 'Restore Deleted Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/groups/create',
    name: 'SchoolProjectTypeSectionCreate',
    component: ManageCustomFieldGroup,
    props: () => ({
      type: 'school',
      showAction: 'projectTypeSections/show',
      storeAction: 'projectTypeSections/store',
    }),
    meta: {
      title: 'Create Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/groups/:id/edit',
    name: 'SchoolProjectTypeSectionEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'school',
      showAction: 'projectTypeSections/show',
      updateAction: 'projectTypeSections/update',
      deleteAction: 'projectTypeSections/delete',
    }),
    component: ManageCustomFieldGroup,
    meta: {
      title: 'Update Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/groups/delete',
    name: 'SchoolProjectTypeSectionDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/schools/fields/groups/restore',
    name: 'SchoolProjectTypeSectionRestore',
    component: RestoreCustomFieldGroups,
    props: () => ({
      type: 'school',
    }),
    meta: {
      title: 'Restore Deleted Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/groups/create',
    name: 'HomeProjectTypeSectionCreate',
    component: ManageCustomFieldGroup,
    props: () => ({
      type: 'home',
      showAction: 'projectTypeSections/show',
      storeAction: 'projectTypeSections/store',
    }),
    meta: {
      title: 'Create Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/groups/:id/edit',
    name: 'HomeProjectTypeSectionEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'home',
      showAction: 'projectTypeSections/show',
      updateAction: 'projectTypeSections/update',
      deleteAction: 'projectTypeSections/delete',
    }),
    component: ManageCustomFieldGroup,
    meta: {
      title: 'Update Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/groups/delete',
    name: 'HomeProjectTypeSectionDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/homes/fields/groups/restore',
    name: 'HomeProjectTypeSectionRestore',
    component: RestoreCustomFieldGroups,
    props: () => ({
      type: 'home',
    }),
    meta: {
      title: 'Restore Deleted Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/groups/create',
    name: 'VehicleProjectTypeSectionCreate',
    component: ManageCustomFieldGroup,
    props: () => ({
      type: 'vehicle',
      showAction: 'projectTypeSections/show',
      storeAction: 'projectTypeSections/store',
    }),
    meta: {
      title: 'Create Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/groups/:id/edit',
    name: 'VehicleProjectTypeSectionEdit',
    props: (route) => ({
      id: route.params.id,
      type: 'vehicle',
      showAction: 'projectTypeSections/show',
      updateAction: 'projectTypeSections/update',
      deleteAction: 'projectTypeSections/delete',
    }),
    component: ManageCustomFieldGroup,
    meta: {
      title: 'Update Custom Field Group',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/groups/delete',
    name: 'VehicleProjectTypeSectionDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/vehicles/fields/groups/restore',
    name: 'VehicleProjectTypeSectionRestore',
    component: RestoreCustomFieldGroups,
    props: () => ({
      type: 'vehicle',
    }),
    meta: {
      title: 'Restore Deleted Custom Field Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
