import { EnComponents } from "@/locales/messages/components"

export default {
    homes: {
        breadcrumb: 'Homes',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                tagging: EnComponents.grid.headers.tagging,
                status: EnComponents.grid.headers.status,
                administrator: EnComponents.grid.headers.administrator,
                careGroup: EnComponents.grid.headers.careGroup,
                startDate: EnComponents.grid.headers.startDate,
                endDate: EnComponents.grid.headers.endDate,
                reports: EnComponents.grid.headers.reports,
                createTasks: EnComponents.grid.headers.createTasks,
                name: `Home ${EnComponents.grid.headers.name}`
            }
        }
    }
}