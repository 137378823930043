export default {
    ypDetails: {
        header: 'Young Person Details',
        summary: {
            id: 'ID',
            fullName: 'Full Name',
            preferredName: 'Preferred Name',
            careHome: 'Care Home',
            region: 'region',
            roomNum: 'Room Number',
            birthDate: 'Date Of Birth',
            age: 'Age',
            gender: 'Gender',
            ethnicity: 'Ethnicity',
            religion: 'Religion',
            extraInfo: 'Extra Information',
            ccStartDate: 'ClearCare Start Date',
            ccEndDate: 'ClearCare End Date',
            admissionDate: 'Admission Date',
            placementEndDate: 'Placement End Date',
            ypAdmin: 'Young Person Administrator',
            keyWorker: 'Key Worker',
            CorrespondingUser: 'Corresponding User'
        },
        contactDetails: {
            address1: 'Address 1',
            address2: 'Address 2',
            postCode: 'Post Code',
            phone: 'Phone Number',
            fax: 'Fax Number',
            email: 'Email Address',
            prevAddress: 'Previous Address'
        },
        personalDetails: {
            legalStatus: 'Legal Status',
            emergencyPlacement: 'Emergency Placement',
            placingAuthority: 'Placing Authority',
            practiceManager: 'Practice Manager',
            socialWorker: 'Social Worker',
            independentReviewingOfficer: 'Independent Reviewing Officer',
            billingInfo: 'Billing Information'
        },
        health: {
            nhsNum: 'NHS Number',
            doctorOnAdmin: 'Doctor On Administration',
            currentDoctor: 'Current Doctor',
            hospital: 'Hospital',
            optician: 'Optician',
            dentist: 'Dentist',
            medicalNeeds: 'Medical Needs',
            allergies: 'Known Allergies',
            responsiblePerson: 'Pereson Responsible For Emergency Treatment',
            registeredDisabled: 'Registered Disabled?',
            otherDetails: 'Other Important Health Details'
        },
        education: {
            pupilNum: 'Universal Pupil Number',
            schoolAttended: 'School Attended Or Educational Provision',
            schoolCareGroup: 'Attends School Run By Care Group?',
            senStatement: 'SEN Statement?'
        }
    }
}