import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border border-gray-100 p-6 shadow-md" }
const _hoisted_2 = { class: "font-semibold text-xl tracking-slight mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, " Parameters for " + _toDisplayString(_ctx.props.datasource.pivot.name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.datasource.runtime_params, (parameters, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${index}-${_ctx.props.datasource.pivot.id}`
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parameters, (parameter, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${index}-${parameter.name}`,
            class: "mb-8"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapParameterToComponent(parameter)), {
              parameter: parameter,
              "onUpdate:parameter": _ctx.update
            }, null, 40, ["parameter", "onUpdate:parameter"]))
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}