
  import { defineComponent, reactive } from 'vue'
  import { DxProgressBar } from 'devextreme-vue/progress-bar'

  export default defineComponent({
    components: {
      DxProgressBar,
    },
    props: {
      message: {
        type: String,
        required: true,
        default: 'Loading...',
      },
      min: {
        type: Number,
        required: true,
      },
      max: {
        type: Number,
        required: true,
      },
      value: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
