
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      size: {
        type: String,
        default: 'small',
        validator: (value: string) => ['small', 'large'].indexOf(value) !== -1,
      },
      url: {
        type: String,
        required: false,
        default: '/default_profile_image.png',
      },
      alt: {
        type: String,
        required: false,
        default: 'Avatar',
      },
    },
    setup(props) {
      props = reactive(props)

      const state = reactive({
        imageLoaded: false,
        defaultImage: '/default_profile_image.png',
      })

      const avatarClasses = computed(() => {
        return {
          'rounded-full': true,
          'object-fill': true,
          'h-13 w-13 min-w-13 min-h-13': props.size == 'small',
          'h-20 w-20 min-w-20 min-h-20': props.size == 'large',
          'shadow-avatar': props.size == 'large',
        }
      })

      const computedUrl = computed(() => {
        return state.imageLoaded ? props.url : state.defaultImage
      })

      const setDefaultImage = (event: any) => {
        event.target.src = state.defaultImage
      }

      return {
        props,
        computedUrl,
        avatarClasses,
        setDefaultImage,
        state,
      }
    },
  })
