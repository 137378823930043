import { GetterTree, MutationTree, ActionTree } from 'vuex'
import TagService from '@/services/tags/tags'
import { TagIcon } from '@/types/modules/tags/TagIcon'

export type TagGetter = GetterTree<TagState, any>

export interface TagState {
  icons: TagIcon[]
}

export const state: TagState = {
  icons: [],
}

export const getters: TagGetter = {
  getIcons: (state) => state.icons,
}

export const actions: ActionTree<TagState, any> = {
  getIcons: (context, params) => {
    const results = Promise.resolve(TagService.getIcons(params))

    results.then((response) => {
      if (response.success) {
        context.commit('setIcons', response.data)
      }
    })

    return results
  },
}

export const mutations: MutationTree<TagState> = {
  setIcons: (state, icons: TagIcon[]) => (state.icons = icons),
}

export const tags = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
