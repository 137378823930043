
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        selectedRows: [] as PublishedBespokeReport[],
      })
      const columns: Column[] = reactive([
        {
          caption: t('publishedBespokeReports.grids.id'),
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: t('publishedBespokeReports.grids.name'),
          dataField: 'name',
          width: 200,
        },
        {
          caption: t('publishedBespokeReports.grids.description'),
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: t('publishedBespokeReports.grids.publisher'),
          calculateCellValue: (row: PublishedBespokeReport) => {
            return `${row.publisher?.first_name} ${row.publisher?.last_name}`
          },
          width: 200,
        },
      ])

      const filters = computed(() => {
        return {
          bespoke_report_id: props.id,
          relations: ['bespoke_report'],
          only: ['trashed'],
        }
      })

      const rowsSelected = (rows: PublishedBespokeReport[]) =>
        (state.selectedRows = rows)

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('publishedBespokeReports/restoreMany', {
            ids: state.selectedRows.map(
              (row: PublishedBespokeReport) => row.id
            ),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => goBack())
      }

      return {
        t,
        state,
        goBack,
        columns,
        filters,
        restore,
        rowsSelected,
      }
    },
  })
