import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input", true)!

  return (_openBlock(), _createBlock(_component_text_input, {
    label: `The field ${_ctx.parameter.friendly_name} ${_ctx.parameter.friendly_operator}`,
    modelValue: _ctx.payload.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.value) = $event))
  }, null, 8, ["label", "modelValue"]))
}