
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { defineComponent, PropType } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<MedicationStock>,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute()
      const { t } = useI18n()

      return {
        t,
        props,
        route,
      }
    },
  })
