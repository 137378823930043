
  import { defineComponent, onMounted, reactive, computed } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { useStore } from 'vuex'
  import { CalendarEventStatus } from '@/types/modules/calendar/CalendarEventStatus'
  import format from 'date-fns/format'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { User } from '@/types/modules/users/User'
  import { useRouter } from 'vue-router'
  import { Employee } from '@/types/modules/projects/Employee'
  import { canEditModule } from '@/composables/Permissions'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      SelectInput,
      TextBox,
      ConfirmDialog,
    },
    props: {
      id: {
        type: String,
      },
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      props = reactive(props)
      const state = reactive({
        showConfirmDialog: false,
        event_status_id: undefined,
        notes: '',
        administered_by: undefined,
        authorised_by: undefined,
      })

      const isRecurringEvent = computed(() => {
        if (eventInstance.value) {
          return (
            eventInstance.value.event && eventInstance.value.event.is_recurring
          )
        }

        return false
      })

      const eventDate = computed(() => {
        if (eventInstance.value) {
          let date = new Date(eventInstance.value?.date)

          if (date) {
            return format(date, 'EEEE do MMMM yyyy')
          }
        }

        return ''
      })

      const title = computed(() => {
        if (eventInstance.value) {
          return `${format(new Date(eventInstance.value.date), 'HH:mm')}: ${
            eventInstance.value.event.title
          }`
        }

        return ''
      })

      const relatesTo = computed(() => {
        if (eventInstance.value) {
          return eventInstance.value.event.project?.name
        }

        return ''
      })

      const eventInstance = computed(
        () => store.getters['calendar/getEventInstance']
      )

      const employees = computed(() => {
        if (store.getters['employees/allEmployees'].length) {
          return store.getters['employees/allEmployees'].map(
            (employee: Employee) => {
              return {
                label: `${employee.user?.first_name} ${employee.user?.last_name}`,
                value: employee.project_id,
              }
            }
          )
        }

        return []
      })

      const updateMedicationEventInstance = () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (eventInstance.value) {
          let payload = {
            data: {
              _method: 'put',
              administered_by: state.administered_by,
              authorised_by: state.authorised_by,
            },
            id: eventInstance.value?.id,
          }

          store
            .dispatch('mars/updateMedicationEventInstance', payload)
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
            })
            .catch((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
            })
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      const editEvent = () => {
        router.push({
          name: 'CalendarEventEdit',
          params: { id: eventInstance.value.event_id },
        })
      }

      const assignees = computed(() => {
        if (eventInstance.value?.event?.assignees.length) {
          return eventInstance.value?.event?.assignees
            .map(
              (assignee: User) => `${assignee.first_name} ${assignee.last_name}`
            )
            .join(', ')
        }

        return 'Any'
      })

      const eventStatuses = computed(() => {
        if (store.getters['eventStatuses/getEventStatuses'].length) {
          return store.getters['eventStatuses/getEventStatuses'].map(
            (status: CalendarEventStatus) => {
              return {
                label: status.name,
                value: status.id,
              }
            }
          )
        }

        return []
      })

      const saveNotes = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('calendar/saveNote', {
            id: eventInstance.value.id,
            note: state.notes,
          })
          .then((response) => {
            store.dispatch('calendar/getEventInstance', props.id)
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const stopRecurringEvent = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('calendar/stopRecurringEvent', {
            id: eventInstance.value.event_id,
            instance_id: eventInstance.value.id,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(async () => {
            await store.dispatch('calendar/getEventInstance', props.id)
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const updateStatus = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('calendar/updateEventInstanceStatus', {
            id: eventInstance.value.id,
            event_status_id: state.event_status_id,
          })
          .then((response) => {
            store.dispatch('calendar/getEventInstance', props.id)
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const deleteEvent = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('calendar/deleteEvent', {
            id: eventInstance.value.event_id,
            hard_delete: false,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Calendar', url: '/calendar' },
            { name: 'View Event', url: `/calendar/event/${props.id}` },
          ],
        })
        store.commit('genericStore/setTabs', { tabs: [] })
        await store.dispatch('eventStatuses/index', {})
        await store.dispatch('calendar/getEventInstance', props.id).then(() => {
          if (eventInstance.value) {
            store.dispatch('employees/index', {
              relations: ['user'],
              parent_ids: eventInstance.value.event_project_parent_ids,
              active_in_period: format(
                new Date(eventInstance.value.date),
                'yyyy-MM-dd'
              ),
              restrict_user_access: 0,
              bypass_project_obscure: 1,
            })

            state.notes = eventInstance.value.notes
            state.event_status_id = eventInstance.value.event_status_id

            if (eventInstance.value.medication_instance) {
              state.administered_by =
                eventInstance.value.medication_instance?.administered_by?.id
              state.authorised_by =
                eventInstance.value.medication_instance?.authorised_by?.id
            }
          }
        })

        store.dispatch('genericStore/showPageLoader', false)
      })

      return {
        updateMedicationEventInstance,
        goBack,
        eventStatuses,
        eventInstance,
        state,
        assignees,
        eventDate,
        relatesTo,
        title,
        saveNotes,
        updateStatus,
        stopRecurringEvent,
        isRecurringEvent,
        editEvent,
        deleteEvent,
        employees,
        canEditModule,
      }
    },
  })
