
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, watch, computed } from 'vue'
  import { isTab } from '@/composables/Generic'
  import ProceduresIndex from '@/views/modules/procedures/Index.vue'
  import FormsIndex from '@/views/modules/forms/Index.vue'
  import FormSettings from '@/views/modules/forms/Settings.vue'

  export default defineComponent({
    components: {
      ProceduresIndex,
      FormsIndex,
      FormSettings,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const tabs = [
        { name: 'Forms', url: `${route.path}?tab=forms` },
        { name: 'Hidden Forms', url: `${route.path}?tab=hidden-forms` },
        { name: 'Procedures', url: `${route.path}?tab=procedures` },
        { name: 'Settings', url: `${route.path}?tab=settings` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Forms & Procedures', url: '/forms-procedures' },
        ]

        if (!tabs) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: tabs.find((tab) => tab.url === route.fullPath.toString())
            ?.name!,
          url: route.fullPath.toString(),
        })

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'forms' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
      })

      return {
        isTab,
      }
    },
  })
