
  import { defineComponent, reactive } from 'vue'
  import { getApiUrl } from '@/composables/Generic'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: {
      image: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      props = reactive(props)

      store.commit(
        'genericStore/setClientSplashImage',
        getApiUrl() + '/splash-image'
      )

      return {
        props,
      }
    },
  })
