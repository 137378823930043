
  import { useStore } from 'vuex'
  import { format } from 'date-fns'
  import { useI18n } from 'vue-i18n'
  import { computed, defineComponent, reactive } from 'vue'
  import { Document } from '@/types/modules/documents/Document'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    emits: ['update-selected-documents'],
    components: {
      DataGrid,
    },
    setup(_props, { emit }) {
      const { t } = useI18n()
      const store = useStore()

      const authUserFullName = computed(() => {
        const user = store.getters['auth/authenticatedUser']
        return `${user.first_name} ${user.last_name}`
      })

      const documents = computed(
        () => store.getters['documents/getDocumentsToAcknowledge']
      )

      const updateDocumentsToAcknowledge = (documents: Document[]) => {
        emit('update-selected-documents', documents)
      }

      const columns: Column[] = reactive([
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'Document Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          dataType: 'string',
          dataField: 'type.class.name',
          caption: 'Class',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          caption: 'Acknowledgement Date',
          calculateCellValue: () => {
            return format(new Date(), 'dd/MM/yyyy')
          },
        },
      ])

      return {
        t,
        format,
        columns,
        documents,
        authUserFullName,
        updateDocumentsToAcknowledge,
      }
    },
  })
