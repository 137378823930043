import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4627aa28"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_header = _resolveComponent("menu-header")!
  const _component_menu_link = _resolveComponent("menu-link")!
  const _component_menu_tabs = _resolveComponent("menu-tabs")!
  const _component_menu_footer = _resolveComponent("menu-footer")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(_ctx.navClasses)
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_menu_header, {
        onCollapseMenu: _ctx.collapseMenu,
        isCollapsed: _ctx.state.isCollapsed && _ctx.props.tabs.length == 0
      }, null, 8, ["onCollapseMenu", "isCollapsed"]),
      _createElementVNode("div", {
        class: _normalizeClass(!_ctx.state.isTabsCollapsed ? 'flex' : ''),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.props.tabs.length > 0 ? (_ctx.state.isCollapsed = true) : null))
      }, [
        _createElementVNode("ul", {
          "aria-label": "main-menu",
          "aria-expanded": !_ctx.state.isCollapsed,
          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.props.tabs.length > 0 ? (_ctx.state.isCollapsed = false) : null
          ))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_menu_link, {
                isCollapsed: _ctx.state.isCollapsed,
                url: link.url,
                icon: link.icon,
                class: _normalizeClass(_ctx.isActive(link))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(link.name), 1)
                ]),
                _: 2
              }, 1032, ["isCollapsed", "url", "icon", "class"])
            ]))
          }), 128))
        ], 40, _hoisted_1),
        _createVNode(_component_menu_tabs, {
          isCollapsed: _ctx.state.isTabsCollapsed,
          tabs: _ctx.tabs
        }, null, 8, ["isCollapsed", "tabs"])
      ], 34)
    ]),
    _createVNode(_component_menu_footer, {
      isCollapsed: _ctx.state.isCollapsed && _ctx.props.tabs.length == 0,
      class: "mt-6"
    }, null, 8, ["isCollapsed"])
  ], 2))
}