import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "flex items-center justify-center flex-1"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-y-0 left-36 right-6 flex items-center z-10",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "z-50 px-2 flex flex-col lowercase transition-all w-28" }
const _hoisted_6 = { class: "font-semibold text-gray-300 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!

  return (_openBlock(), _createElementBlock("nav", _mergeProps({
    "aria-label": "Progress",
    class: "w-full mb-4 font-display flex items-center justify-between space-x-10 bg-blue-100 py-4 px-8"
  }, _ctx.$attrs), [
    (_ctx.props.showBack && _ctx.props.showButtons)
      ? (_openBlock(), _createBlock(_component_cc_button, {
          key: 0,
          "is-disabled": _ctx.props.activeStep === 1,
          type: "tertiary",
          label: "back",
          icon: 'fa fa-arrow-left',
          secondaryIcon: 'none',
          onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('step-changed', _ctx.props.activeStep - 1)))
        }, null, 8, ["is-disabled"]))
      : _createCommentVNode("", true),
    _createElementVNode("ol", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `step-${index + 1}`,
          class: "relative w-full pr-auto last:w-max"
        }, [
          (index !== _ctx.props.steps.length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["h-0.5 w-full transition-all", _ctx.correctColor(index + 1)])
                }, null, 2)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["z-50 group relative w-12 h-12 flex items-center justify-center rounded-full transition-all", _ctx.correctColor(index + 1)]),
              onClick: _withModifiers(($event: any) => (_ctx.goToStep(index + 1)), ["prevent"])
            }, [
              _createElementVNode("i", {
                class: _normalizeClass([
                _ctx.correctIcon(index + 1, step.icon),
                'text-white',
                'fa-fw',
              ])
              }, null, 2)
            ], 10, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", {
                class: _normalizeClass(["text-gray-500 text-base", 
                _ctx.props.activeStep === index + 1 ? 'font-bold' : 'font-semibold'
              ])
              }, _toDisplayString(step.label), 3),
              _createElementVNode("small", _hoisted_6, " step " + _toDisplayString(index + 1), 1)
            ])
          ])
        ]))
      }), 128))
    ]),
    (
        _ctx.props.showSave &&
        _ctx.props.activeStep === _ctx.props.steps.length &&
        _ctx.props.showButtons
      )
      ? (_openBlock(), _createBlock(_component_cc_button, {
          key: 1,
          type: "success",
          label: _ctx.props.saveLabel,
          secondaryIcon: 'fa fa-save',
          onButtonClicked: _ctx.submit
        }, null, 8, ["label", "onButtonClicked"]))
      : _createCommentVNode("", true),
    (
        _ctx.props.showNext &&
        _ctx.props.activeStep !== _ctx.props.steps.length &&
        _ctx.props.showButtons
      )
      ? (_openBlock(), _createBlock(_component_cc_button, {
          key: 2,
          type: "success",
          label: "next",
          secondaryIcon: 'fa fa-chevron-right',
          "is-disabled": _ctx.props.disableNext,
          onButtonClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('step-changed', _ctx.props.activeStep + 1)))
        }, null, 8, ["is-disabled"]))
      : _createCommentVNode("", true)
  ], 16))
}