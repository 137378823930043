import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Medication Stock Categories",
    "create-view": "MedicationStockCategoryCreate",
    "edit-view": "MedicationStockCategoryEdit",
    "delete-view": "MedicationStockCategoryDelete",
    "restore-view": "MedicationStockCategoryRestore",
    action: "medicationStockCategories/index",
    "toggle-visibility-action": "medicationStockCategories/toggleManyVisibility",
    "delete-many-action": "medicationStockCategories/deleteMany",
    "reorder-action": "medicationStockCategories/saveOrderings"
  }))
}