import axios from 'axios'

export default {
  sensitiveRecruitmentAttachments: (params: any) => {
    let data = Array()
    switch (params.type) {
      case 'All':
        data = [
          {
            id: 1,
            name: 'dbsfile.pdf',
            description: 'dbsfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 2,
            name: 'contractfile.pdf',
            description: 'contractfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 3,
            name: 'referencefile.pdf',
            description: 'referencefile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 4,
            name: 'overseasCrimRecfile.pdf',
            description: 'overseasCrimRecfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 5,
            name: 'proofIDfile.pdf',
            description: 'proofIDfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 6,
            name: 'rightToWorkfile.pdf',
            description: 'rightToWorkfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'DBS Files':
        data = [
          {
            id: 1,
            name: 'dbsfile.pdf',
            description: 'dbsfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'Contract Files':
        data = [
          {
            id: 2,
            name: 'contractfile.pdf',
            description: 'contractfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'Reference Files':
        data = [
          {
            id: 3,
            name: 'referencefile.pdf',
            description: 'referencefile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'Overseas Criminal Record Files':
        data = [
          {
            id: 4,
            name: 'overseasCrimRecfile.pdf',
            description: 'overseasCrimRecfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'Proof of Identity Files':
        data = [
          {
            id: 5,
            name: 'proofIDfile.pdf',
            description: 'proofIDfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'Right to Work Files':
        data = [
          {
            id: 6,
            name: 'rightToWorkfile.pdf',
            description: 'rightToWorkfile description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
    }

    return {
      message: 'recruitment check files returned successfully',
      success: true,
      data: data,
    }
  },
  sensitiveQualificationAttachments: (params: any) => {
    let data = Array()
    switch (params.type) {
      case 'All':
        data = [
          {
            id: 1,
            name: 'file.pdf',
            description: 'filetype 1 description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
          {
            id: 2,
            name: 'file.pdf',
            description: 'filetype 2 description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'returned filetype 1':
        data = [
          {
            id: 1,
            name: 'file.pdf',
            description: 'filetype 1 description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
      case 'returned filetype 2':
        data = [
          {
            id: 2,
            name: 'file.pdf',
            description: 'filetype 2 description',
            type: 'pdf',
            date: '2020-10-30T15:43:29.000000Z',
          },
        ]
        break
    }

    return {
      message: 'qualification files returned successfully',
      success: true,
      data: data,
    }
  },
  getEmployeeReferences: (params: any) => {
    params = params // will contain the employee id
    return axios
      .get(`staffs/employee_references`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  index: async (params: any) => {
    return axios
      .get('projects/employees', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any) => {
    return axios
      .get(`projects/employees/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getQualifications: (params: any) => {
    return axios
      .get(`projects/employees/qualifications`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
