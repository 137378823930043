
  import RecycleBin from '@/views/RecycleBin.vue'
  import { onMounted } from '@vue/runtime-core'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { computed, reactive } from 'vue'
  import { RecycleBin as RecycleBinType } from '@/types/modules/generic/RecycleBin'
  import { Crumb } from '@/types/components/Crumb'

  export default {
    components: {
      RecycleBin,
    },
    setup() {
      const store = useStore()
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Documents',
          url: '/documents',
        },
      ])

      const data = computed(
        (): RecycleBinType => store.getters['genericStore/recycleBinData']
      )

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Name',
          dataField: 'name',
          width: 200,
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
      ])

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })

        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('genericStore/setRecycleBinData', {
            key: 'id',
            action: 'documentTypes/index',
            restoreAction: 'documentTypes/restoreMany',
            columns: columns,
          } as RecycleBinType)
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        data,
      }
    },
  }
