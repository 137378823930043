import BespokeReportTypeService from '@/services/reporting/bespokeReportTypes'
import { BespokeReportType } from '@/types/modules/reporting/BespokeReportType'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface BespokeReportTypeState {
  types: BespokeReportType[]
}

export const state: BespokeReportTypeState = {
  types: [],
}

export const getters: GetterTree<BespokeReportTypeState, any> = {
  types: (state) => state.types,
}

export const actions: ActionTree<BespokeReportTypeState, any> = {
  index: (context, params) => {
    const response = BespokeReportTypeService.index(params)

    response.then((response) => {
      if (response.success) {
        context.commit('setBespokeReportTypes', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<BespokeReportTypeState> = {
  setBespokeReportTypes: (state, bespokeReportTypes: BespokeReportType[]) => {
    state.types = bespokeReportTypes
  },
}

export const bespokeReportTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
