import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_step = _resolveComponent("wizard-step")!
  const _component_configure_task = _resolveComponent("configure-task")!
  const _component_run_report = _resolveComponent("run-report")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_wizard_step, {
      steps: _ctx.state.steps,
      activeStep: _ctx.state.activeStep,
      "disable-next": !_ctx.state.start_date || !_ctx.state.end_date || !_ctx.state.form_id,
      showSave: _ctx.state.activeStep !== 2,
      showNext: _ctx.state.activeStep !== 3,
      onStepChanged: _ctx.stepChanged,
      onGoToStep: _ctx.goToStep
    }, null, 8, ["steps", "activeStep", "disable-next", "showSave", "showNext", "onStepChanged", "onGoToStep"]),
    (_ctx.state.activeStep === 1)
      ? (_openBlock(), _createBlock(_component_configure_task, {
          key: 0,
          onStartDateSelected: _ctx.startDateSelected,
          onEndDateSelected: _ctx.endDateSelected,
          onFormSelected: _ctx.formSelected,
          onFieldSelected: _ctx.fieldSelected,
          onTaskAuditTrailToggled: _ctx.taskAuditTrailToggled
        }, null, 8, ["onStartDateSelected", "onEndDateSelected", "onFormSelected", "onFieldSelected", "onTaskAuditTrailToggled"]))
      : _createCommentVNode("", true),
    (_ctx.state.activeStep > 1)
      ? (_openBlock(), _createBlock(_component_run_report, {
          key: 1,
          project_types_label: _ctx.state.project_types_label,
          project_type_is_parent: _ctx.state.project_type_is_parent,
          start_date: _ctx.state.start_date,
          end_date: _ctx.state.end_date,
          form_id: _ctx.state.form_id,
          field_id: _ctx.state.field_id,
          "active-step": _ctx.state.activeStep,
          show_task_audit_trail: _ctx.state.taskAuditTrail,
          onChangeStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.activeStep++))
        }, null, 8, ["project_types_label", "project_type_is_parent", "start_date", "end_date", "form_id", "field_id", "active-step", "show_task_audit_trail"]))
      : _createCommentVNode("", true)
  ], 64))
}