
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
  } from 'vue'
  import { useStore } from 'vuex'
  import DatasourceCard from './parameters/DatasourceCard.vue'

  export default defineComponent({
    components: { DatasourceCard },
    props: {
      report: {
        type: Object as PropType<BespokeReport>,
        required: true,
      },
    },
    emits: ['update'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const errors = ref(null) as Ref<Record<string, string> | null>
      const datasources = computed(
        () => store.getters['bespokeReportDatasources/datasources']
      )

      const update = (datasource: Datasource) => {
        emit('update', datasource)
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('bespokeReportDatasources/index', props.report.id)
          .catch((error) => (errors.value = error.response.data.errors))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        update,
        datasources,
      }
    },
  })
