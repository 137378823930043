import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_form = _resolveComponent("event-form")!

  return (_ctx.event)
    ? (_openBlock(), _createBlock(_component_event_form, {
        key: 0,
        event: _ctx.event,
        onFormSubmit: _ctx.updateEvent
      }, null, 8, ["event", "onFormSubmit"]))
    : _createCommentVNode("", true)
}