import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-4 font-display" }
const _hoisted_2 = { class: "font-bold text-3xl tracking-slight" }
const _hoisted_3 = { class: "h-max border border-gray-100 p-6 shadow-md" }
const _hoisted_4 = { class: "flex items-start space-x-20" }
const _hoisted_5 = { class: "w-1/2 space-y-10" }
const _hoisted_6 = { class: "w-1/2 space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_cc_switch = _resolveComponent("cc-switch")!
  const _component_icon_selector = _resolveComponent("icon-selector")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.props.id ? 'Update' : 'Create') + " Document Type ", 1),
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: "Cancel",
            type: "secondary",
            icon: "fa fa-close",
            onClick: _ctx.goBack
          }, null, 8, ["onClick"]),
          (_ctx.props.id && _ctx.payload.is_system !== 1)
            ? (_openBlock(), _createBlock(_component_cc_button, {
                key: 0,
                label: "Delete",
                type: "danger-outline",
                icon: "fa fa-trash",
                onClick: _ctx.deleteRecord
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_cc_button, {
            label: "Save",
            type: "success",
            icon: "fa fa-save",
            onClick: _ctx.saveChanges
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_text_input, {
            modelValue: _ctx.payload.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event)),
            label: "Name",
            error: _ctx.errors?.name,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errors?.name ? (_ctx.errors.name = undefined) : ''))
          }, null, 8, ["modelValue", "error"]),
          _createVNode(_component_cc_switch, {
            label: "hidden record?",
            modelValue: _ctx.payload.is_hidden,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.is_hidden) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_icon_selector, {
            label: "Select an Icon",
            modelValue: _ctx.payload.tag_icon_id,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.tag_icon_id) = $event)),
            "is-required": false,
            options: _ctx.icons,
            error: _ctx.errors?.tag_icon_id,
            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.errors?.tag_icon_id ? (_ctx.errors.tag_icon_id = undefined) : ''))
          }, null, 8, ["modelValue", "options", "error"]),
          _createVNode(_component_select_input, {
            label: "Class",
            modelValue: _ctx.payload.document_class_id,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.document_class_id) = $event)),
            "is-required": false,
            options: _ctx.classes,
            error: _ctx.errors?.document_class_id,
            onChange: _cache[6] || (_cache[6] = ($event: any) => (
            _ctx.errors?.document_class_id
              ? (_ctx.errors.document_class_id = undefined)
              : ''
          ))
          }, null, 8, ["modelValue", "options", "error"])
        ])
      ])
    ])
  ], 64))
}