import { Crumb } from '@/types/components/Crumb'
import { MenuLink } from '@/types/components/MenuLink'
import { MenuTab } from '@/types/components/MenuTab'
import { Notification } from '@/types/components/Notification'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import SettingsService from '@/services/settings/settings'
import { RecycleBin } from '@/types/modules/generic/RecycleBin'
import GenericService from '@/services/generic/generic'
import { Version } from '@/types/modules/generic/Version'

export type GenericGetter = GetterTree<GenericState, any>

export interface GenericState {
  appVersion: Version | null
  apiVersion: Version | null
  showPageLoader: boolean
  pageLoadersTriggered: number
  pageAvatar: string
  icon: string
  pageTaskId: string | null
  crumbs: Crumb[]
  links: MenuLink[]
  tabs: MenuTab[]
  notifications: Notification[]
  notification: string | null
  clientSplashImage: string
  clientLogo?: string
  recycleBinData: RecycleBin
  activeChips: Record<string, string>
  lookupsToBeDeleted: any[]
  userAboutToBeLoggedOut: boolean
}

// Change once backend connection setup
export const state: GenericState = {
  activeChips: {},
  appVersion: null,
  apiVersion: null,
  showPageLoader: false,
  pageLoadersTriggered: 0,
  pageAvatar: 'https://bit.ly/3jDg2Q6',
  icon: 'fa-solid fa-home',
  pageTaskId: null,
  crumbs: [{ name: 'Dashboard', url: '/' }],
  links: [
    {
      name: 'Dashboard',
      module: 'summary',
      url: '/dashboard',
      icon: 'fas fa-dashboard',
    },
    {
      name: 'Overview',
      module: 'overview',
      url: '/overview',
      icon: 'fas fa-chart-line',
    },
    {
      name: 'Task Explorer',
      module: 'tasks',
      url: '/task-explorer',
      icon: 'fas fa-file-chart-line',
    },
    {
      name: 'Care Groups',
      module: 'companies',
      url: '/care_groups',
      icon: 'fas fa-hand-holding-medical',
    },
    {
      name: 'Homes',
      module: 'homes',
      url: '/projects/homes',
      icon: 'fas fa-house-user',
    },
    {
      name: 'Schools',
      module: 'schools',
      url: '/projects/schools',
      icon: 'fas fa-school',
    },
    {
      name: 'Young People',
      module: 'residents',
      url: '/projects/young_people',
      icon: 'fas fa-child',
    },
    {
      name: 'Employees',
      module: 'employees',
      url: '/projects/employees',
      icon: 'fas fa-id-badge',
    },
    {
      name: 'Vehicles',
      module: 'vehicles',
      url: '/projects/vehicles',
      icon: 'fas fa-car',
    },
    {
      name: 'Calendar',
      module: 'calendar',
      url: '/calendar',
      icon: 'fas fa-calendar',
    },
    {
      name: 'Rotas',
      module: 'rotas',
      url: '/rotas',
      icon: 'fas fa-calendar-circle-user',
    },
    {
      name: 'Bespoke Reporting',
      module: 'reporting',
      url: '/bespoke-reporting',
      icon: 'fas fa-chart-pie',
    },
    {
      name: 'Attachments',
      module: 'files',
      url: '/attachments',
      icon: 'fas fa-paperclip',
    },
    {
      name: 'Sensitive Data',
      module: 'sensitive',
      url: '/sensitive-data',
      icon: 'fas fa-shield-halved',
    },
    {
      name: 'Forms & Procedures',
      module: 'procedures',
      url: '/forms-procedures',
      icon: 'far fa-cabinet-filing',
    },
    {
      name: 'Documents',
      module: 'documents',
      url: '/documents',
      icon: 'fas fa-file',
    },
    {
      name: 'Regions',
      module: 'regions',
      url: '/regions',
      icon: 'fas fa-map-location',
    },
    {
      name: 'Users',
      module: 'admin',
      url: '/users',
      icon: 'fas fa-users',
    },
    {
      name: 'Groupings',
      module: 'groupings',
      url: '/groupings',
      icon: 'fas fa-layer-group',
    },
    {
      name: 'Settings',
      module: 'sysadmin',
      url: '/settings',
      icon: 'fas fa-cog',
    },
  ],
  tabs: [],
  notifications: [
    {
      id: 1,
      title: 'New Message',
    },
  ],
  clientSplashImage: '',
  clientLogo: '',
  notification: null,
  recycleBinData: {
    key: 'id',
    action: '',
    restoreAction: '',
    columns: [],
  },
  lookupsToBeDeleted: [],
  userAboutToBeLoggedOut: false,
}

export const getters: GenericGetter = {
  appVersion: (state) => state.appVersion,
  showPageLoader: (state) => state.pageLoadersTriggered > 0,
  pageAvatar: (state) => state.pageAvatar,
  pageTaskId: (state) => state.pageTaskId,
  icon: (state) => state.icon,
  breadCrumbs: (state) => state.crumbs,
  menuLinks: (state) => state.links,
  menuTabs: (state) => state.tabs,
  notifications: (state) => state.notifications,
  notification: (state) => state.notification,
  clientSplashImage: (state) => state.clientSplashImage,
  clientLogo: (state) => state.clientLogo,
  recycleBinData: (state) => state.recycleBinData,
  activeChips: (state) => state.activeChips,
  getLookupsToBeDeleted: (state) => state.lookupsToBeDeleted,
  apiVersion: (state) => state.apiVersion,
  userAboutToBeLoggedOut: (state) => state.userAboutToBeLoggedOut,
}

export const actions: ActionTree<GenericState, any> = {
  setActiveChip: (context, activeChip: Record<string, string>) => {
    context.commit('setActiveChip', activeChip)
  },
  pushNotification: (context, notification) => {
    context.commit('setNotification', notification)
  },
  getTimeoutSetting: () => {
    return Promise.resolve(SettingsService.getTimeoutSetting())
  },
  showPageLoader: (context, visible: boolean) => {
    return context.commit('showPageLoader', visible)
  },
  getLookupsToBeDeleted: async (context) => {
    const response = await context.getters['getLookupsToBeDeleted']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  setLookupsToBeDeleted: (context, lookups: any[]) => {
    return context.commit('setLookupsToBeDeleted', lookups)
  },
  setRecycleBinData: (context, data: RecycleBin) => {
    return context.commit('setRecycleBinData', data)
  },
  setClientLogoUrl: (context, data: string) => {
    return context.commit('clientLogo', data)
  },
  setClientSplashImage: (context, data: string) => {
    return context.commit('clientSplashImage', data)
  },
  setAppVersion: (context, data: Version) => {
    return context.commit('appVersion', data)
  },
  setApiVersion: (context, data: Version) => {
    return context.commit('apiVersion', data)
  },
  getApiVersion: async (context, params) => {
    if (state.apiVersion) {
      return state.apiVersion
    }

    const results = await GenericService.getApiVersion(params)

    if (results) {
      context.commit('setApiVersion', results)
    }

    return results
  },
  getAppVersion: async (context) => {
    if (state.appVersion) {
      return state.appVersion
    }

    const results = await GenericService.getAppVersion()

    if (results) {
      context.commit('setAppVersion', results)
    }

    return results
  },
  notifyFrontendError: (_context, payload) => {
    return Promise.resolve(GenericService.notifyFrontendError(payload))
  },
  userAboutToBeLoggedOut: (context, value: boolean) => {
    context.commit('setUserAboutToBeLoggedOut', value)
  },
}

export const mutations: MutationTree<GenericState> = {
  showPageLoader: (state, visible: boolean) => {
    return visible ? state.pageLoadersTriggered++ : state.pageLoadersTriggered--
  },
  setTabs: (state, payload) => (state.tabs = payload.tabs),
  setBreadcrumbs: (state, payload) => (state.crumbs = payload.crumbs),
  setIcon: (state, icon: string) => (state.icon = icon),
  setPageAvatar: (state, url) => (state.pageAvatar = url),
  setNotification: (state, notification) => (state.notification = notification),
  setPageTaskId: (state, id: string) => (state.pageTaskId = id),
  setRecycleBinData: (state, data: RecycleBin) => (state.recycleBinData = data),
  setActiveChip: (state, activeChip: Record<string, string>) =>
    (state.activeChips = { ...state.activeChips, ...activeChip }),
  setLookupsToBeDeleted: (state, lookups: any[]) =>
    (state.lookupsToBeDeleted = lookups),
  setClientLogoUrl: (state, data: string) => (state.clientLogo = data),
  setClientSplashImage: (state, data: string) =>
    (state.clientSplashImage = data),
  setApiVersion: (state, data: Version) => (state.apiVersion = data),
  setAppVersion: (state, data: Version) => (state.appVersion = data),
  setUserAboutToBeLoggedOut: (state, value: boolean) =>
    (state.userAboutToBeLoggedOut = value),
}

export const genericStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
