import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", {
      class: "my-4 tracking-slight",
      innerHTML: 
      _ctx.t('acknowledgement.statement', {
        name: `<strong>${_ctx.authUserFullName}</strong>`,
      })
    
    }, null, 8, _hoisted_1),
    _createVNode(_component_data_grid, {
      action: "documents/getSelectedDocumentsToAcknowledge",
      columns: _ctx.columns,
      "show-grid-toolbar": false,
      "allow-column-chooser": false,
      "allow-filtering": false,
      "allow-grouping": false,
      "allow-selection": true,
      "remote-operations": {},
      onRowsSelected: _ctx.updateDocumentsToAcknowledge
    }, null, 8, ["columns", "onRowsSelected"])
  ], 64))
}