import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-1/2" }
const _hoisted_2 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_input, {
        options: _ctx.users,
        modelValue: _ctx.payload.company_owner,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.company_owner) = $event)),
        label: "Contact"
      }, null, 8, ["options", "modelValue"]),
      _createVNode(_component_text_input, {
        label: "Phone Number",
        modelValue: _ctx.payload.company_phone1,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.company_phone1) = $event)),
        class: "mb-4"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Fax Number",
        modelValue: _ctx.payload.company_fax,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.company_fax) = $event)),
        class: "mb-4"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Email Address",
        modelValue: _ctx.payload.company_email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.company_email) = $event)),
        class: "mb-4"
      }, null, 8, ["modelValue"])
    ])
  ]))
}