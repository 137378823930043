
  import { defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { Form } from '@/types/modules/forms/Form'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'

  import TransferList from '@/components/Generic/List/TransferList.vue'

  export default defineComponent({
    components: {
      RadioButton,
      TransferList,
    },
    props: {
      form: {
        type: Object as PropType<Form>,
        required: false,
      },
    },
    emits: ['form-access-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const payload = ref({}) as Ref<{
        restrictions: User[]
        approvers: User[]
        is_restricted: boolean
      }>
      const approvers = ref([]) as Ref<User[]>
      const restrictions = ref([]) as Ref<User[]>

      const yesNoButtons = [
        {
          id: 1,
          text: 'yes',
        },
        {
          id: 0,
          text: 'no',
        },
      ]

      const updateApprovers = (approvers: User[]) =>
        (payload.value.approvers = approvers)

      const updateRestrictions = (restrictions: User[]) => {
        if (restrictions.length) {
          payload.value.is_restricted = true
        } else {
          payload.value.is_restricted = false
        }

        payload.value.restrictions = restrictions
      }

      const mapPayload = () => {
        if (props.form) {
          payload.value.is_restricted = Boolean(props.form?.is_restricted)

          payload.value.approvers = props.form?.approvers
          approvers.value = approvers.value

          payload.value.restrictions = props.form?.restrictions
          restrictions.value = restrictions.value

          return
        }

        payload.value.is_restricted = false
        payload.value.approvers = []
        payload.value.restrictions = []
      }

      watch(
        payload,
        () => {
          emit('form-access-updated', {
            approver_ids: payload.value.approvers.map(
              (approver) => approver.id
            ),
            restriction_ids: payload.value.restrictions.map(
              (restriction) => restriction.id
            ),
            is_restricted: payload.value.is_restricted,
          })
        },
        { deep: true }
      )

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        mapPayload()

        store
          .dispatch('users/index', {})
          .then(() => {
            approvers.value = store.getters['users/users']
            restrictions.value = store.getters['users/users']
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        payload,
        yesNoButtons,
        approvers,
        restrictions,
        updateApprovers,
        updateRestrictions,
      }
    },
  })
