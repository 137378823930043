import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_cc_calendar = _resolveComponent("cc-calendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_cc_button, {
        type: "primary",
        label: "Add Event",
        icon: "fa fa-plus",
        onButtonClicked: _ctx.addEvent
      }, null, 8, ["onButtonClicked"])
    ]),
    (_ctx.props.youngPerson && _ctx.medicationEventTypes.length)
      ? (_openBlock(), _createBlock(_component_cc_calendar, {
          key: 0,
          filters: _ctx.filters
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true)
  ], 64))
}