import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["onClick", "checked", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trigger_config = _resolveComponent("trigger-config")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.error ? 'border-l-4 border-red-300 pl-3' : '')
  }, [
    _createElementVNode("small", {
      class: _normalizeClass([_ctx.labelClasses, "mb-1"])
    }, [
      (_ctx.props.fieldAttributes.required == true)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, " * "))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.props.fieldAttributes.label), 1)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.fieldAttributes?.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "p-3 border-2 border-gray-200 rounded-md mb-2",
        key: option.id
      }, [
        _createElementVNode("label", {
          for: option.id.toString(),
          class: "font-semibold leading-slight font-display text-sm"
        }, [
          _createElementVNode("input", {
            onClick: ($event: any) => (_ctx.updateValue(option)),
            type: "checkbox",
            checked: _ctx.value ? _ctx.value.indexOf(option.name) !== -1 : false,
            id: option.id.toString()
          }, null, 8, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(option.name), 1)
        ], 8, _hoisted_2),
        (option.show_trigger_config)
          ? (_openBlock(), _createBlock(_component_trigger_config, {
              key: 0,
              config: {
          form: {
            types: _ctx.fieldAttributes.form_types,
            always_trigger_for_same_project:
              option?.always_trigger_for_same_project || 0,
            trigger_has_date: option?.trigger_has_date || 0,
            restrict_projects_by_association: option?.trigger_has_date || 1,
          },
          triggered_form: _ctx.getTriggeredForm(option),
          project: _ctx.getTriggeredProject(option),
          project_parent_id: _ctx.fieldAttributes.project_parent_id
            ? Number(_ctx.fieldAttributes.project_parent_id)
            : undefined,
        },
              showConfigByDefault: option.show_trigger_config ? true : false,
              onConfigChanged: ($event: any) => (_ctx.updateTriggers($event, option))
            }, null, 8, ["config", "showConfigByDefault", "onConfigChanged"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (!_ctx.error && _ctx.props.fieldAttributes.instructions)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass([_ctx.labelClasses, "mt-2.5"])
        }, _toDisplayString(_ctx.props.fieldAttributes.instructions), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass([_ctx.labelClasses, "mt-2.5"])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}