
  import { reactive, defineComponent, onMounted, computed } from 'vue'
  export default defineComponent({
    props: {
      hideDates: {
        type: Boolean,
        default: false,
      },
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      props = reactive(props)
      const hideDate = computed(() => props.cellData.text.replace(/[0-9]/g, ''))

      onMounted(() => {
        document
          .querySelectorAll('.dx-scheduler-header-panel-cell')
          .forEach((el) => {
            el.classList.add('no-popover')
          })
      })

      return {
        hideDate,
        props,
      }
    },
  })
