
  import { useStore } from 'vuex'
  import { defineComponent, computed, reactive, ref, Ref } from 'vue'
  import SplashImage from '@/components/Generic/Splash/SplashImage.vue'
  import CcLogo from '@/components/Generic/Logo/Logo.vue'
  import CcFooter from '@/components/Layout/Footer.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { useI18n } from 'vue-i18n'
  import { object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'

  export default defineComponent({
    components: {
      CcLogo,
      CcButton,
      CcFooter,
      SplashImage,
      TextInput,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        disableLoginAttempt: false,
        payload: {
          username: '',
          password: '',
        },
        hasErrors: false,
      })
      const getters = computed(() => {
        return {
          clientSplashImage: store.getters['genericStore/clientSplashImage'],
          clientLogo: store.getters['genericStore/clientLogo'],
          authenticatedUser: store.getters['auth/authenticatedUser'],
        }
      })

      const loginRequest = object({
        username: string().required('please enter your username'),
        password: string().required('please enter your password'),
      })

      const errors = ref(null) as Ref<Record<string, string | undefined> | null>

      const login = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null
        state.disableLoginAttempt = true

        errors.value = await validatePayload(loginRequest, state.payload)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          state.disableLoginAttempt = false
          return
        }

        store
          .dispatch('auth/login', state.payload)
          .then(() => store.dispatch('auth/redirect'))
          .catch(() => (state.hasErrors = true))
          .finally(() => {
            state.disableLoginAttempt = false
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      return {
        errors,
        login,
        getters,
        state,
        t,
      }
    },
  })
