import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.components[field.field.system_name].component), _mergeProps({ key: field }, _ctx.getProps(field)), null, 16))
      }), 128))
    ])
  ]))
}