
  import { computed, defineComponent, onMounted } from 'vue'
  import DxList, { DxItemDragging } from 'devextreme-vue/list'
  import { useStore } from 'vuex'
  import FieldPaletteItem from './templates/FieldPaletteItem.vue'
  import { Field } from '@/types/modules/forms/Field'
  import { FieldGroup } from '@/types/modules/forms/FieldGroup'

  export default defineComponent({
    components: {
      FieldPaletteItem,
      DxList,
      DxItemDragging,
    },
    setup() {
      const store = useStore()

      const onFieldDragStart = (event: {
        itemData: Field
        fromIndex: number
        component: any
      }) => {
        event.itemData = event.component.getItemByIndex(event.fromIndex)
      }

      const fields = computed(() => {
        if (store.getters['fields/getFields'].length) {
          return store.getters['fields/getFields'].map((group: FieldGroup) => {
            return {
              key: group.name,
              items: group.fields!,
            }
          })
        }

        return []
      })

      const addFieldToCanvas = (field: Field) => {
        store.dispatch('formBuilder/addFieldToCanvas', field)
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('fields/index', { relations: ['fields'] })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        addFieldToCanvas,
        onFieldDragStart,
        fields,
      }
    },
  })
