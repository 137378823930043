import { ActionTree, GetterTree, MutationTree } from 'vuex'
import ShiftTypeService from '@/services/rotas/ShiftTypes'
import { ShiftType } from '@/types/modules/rotas/ShiftType'

export type ShiftTypeGetter = GetterTree<ShiftTypeState, any>

export interface ShiftTypeState {
  shiftTypes: ShiftType[]
  shiftType: ShiftType | null
}

export const state: ShiftTypeState = {
  shiftTypes: [],
  shiftType: null,
}

export const getters: ShiftTypeGetter = {
  getShiftTypes: (state) => state.shiftTypes,
  getShiftType: (state) => state.shiftType,
}

export const actions: ActionTree<ShiftTypeState, any> = {
  index: async (context, params) => {
    const response = await ShiftTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setShiftTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setShiftTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(ShiftTypeService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setShiftType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(ShiftTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(ShiftTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(ShiftTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(ShiftTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(ShiftTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(ShiftTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(ShiftTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<ShiftTypeState> = {
  setShiftTypes: (state, types) => (state.shiftTypes = types),
  setShiftType: (state, type) => (state.shiftType = type),
}

export const shiftTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
