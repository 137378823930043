
  import { defineComponent, computed, onMounted, ref, Ref } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { User } from '@/types/modules/users/User'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import { Crumb } from '@/types/components/Crumb'
  import TaskLogCommentTable from './TaskLogCommentTable.vue'
  import ReactionInput from '@/components/Generic/Forms/Inputs/ReactionInput.vue'
  import { validatePayload } from '@/composables/Validation'
  import { array, number, string, object } from 'yup'

  export default defineComponent({
    components: {
      DataGrid,
      TextInput,
      ButtonGroup,
      CcButton,
      MultiSelect,
      TaskLogCommentTable,
      ReactionInput,
    },
    props: {
      taskId: {
        type: String,
        required: true,
      },
      taskLogId: {
        type: String,
        required: true,
      },
      commentId: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()

      const isYoungPerson = computed(
        () => store.getters['auth/authenticatedUser']?.is_young_person
      )

      const users = computed(() => {
        if (store.getters['users/users'].length) {
          return store.getters['users/users'].map((user: User) => {
            return {
              text: user.username,
              value: user.id,
            }
          })
        }

        return []
      })

      const payload = ref({
        name: '',
        comment: '',
        task_id: Number(props.taskId),
        users: [],
        reaction: '',
      }) as Ref<{
        name: string
        comment: string
        task_id: number
        users: number[]
        reaction: string
        _method?: string
      }>

      let validationObject = object({
        name: string().required('Please provide a summary'),
        comment: string().required('Please provide a comment'),
        task_id: string().required('Task ID is required'),
        users: array().of(number()),
        reaction: string().nullable(),
      })

      const errors = ref(null) as Ref<Record<string, string | undefined> | null>

      const saveChanges = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null

        errors.value = await validatePayload(validationObject, payload.value)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            Object.values(errors.value).join(', ')
          )
          return
        }

        let method = store.dispatch('taskLogComments/store', payload.value)

        if (props.commentId) {
          payload.value._method = 'PUT'

          method = store.dispatch('taskLogComments/update', {
            id: props.commentId,
            data: payload.value,
          })
        }

        method
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const crumbs = computed(() => {
        let defaultCrumbs = store.getters['genericStore/breadCrumbs']
        let name = props.commentId ? 'Update Comment' : 'Add Comment'

        if (
          defaultCrumbs.findIndex((crumb: Crumb) => crumb.name === name) === -1
        ) {
          defaultCrumbs.push({
            name: name,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      const getUsers = () => {
        return store.dispatch('users/index', {
          status: 'active',
          filterable: true,
        })
      }

      const mapToPayload = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('taskLogComments/show', props.commentId)
          .then((response) => {
            payload.value.comment = response.data.comment
            payload.value.name = response.data.name
            payload.value.reaction = response.data.reaction
            payload.value.task_id = Number(props.taskId)
            payload.value.users = response.data.users.map(
              (user: User) => user.id
            )
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })

        getUsers().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )

        if (props.commentId) {
          mapToPayload()
        }
      })

      return {
        props,
        goBack,
        saveChanges,
        payload,
        users,
        isYoungPerson,
        errors,
      }
    },
  })
