import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.props.fieldAttributes.id.toString(),
    class: "font-semibold leading-slight font-display text-sm"
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      id: _ctx.props.fieldAttributes.id.toString()
    }, null, 8, _hoisted_2), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.props.fieldAttributes.label), 1)
  ], 8, _hoisted_1))
}