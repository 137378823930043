import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_stepper = _resolveComponent("wizard-stepper")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_vehicle_summary_details = _resolveComponent("vehicle-summary-details")!
  const _component_vehicle_ownership_details = _resolveComponent("vehicle-ownership-details")!
  const _component_association_create = _resolveComponent("association-create")!
  const _component_user_permissions = _resolveComponent("user-permissions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_wizard_stepper, {
      steps: _ctx.state.steps,
      "active-step": _ctx.state.activeStep,
      onStepChanged: _ctx.stepChanged,
      onGoToStep: _ctx.stepChanged,
      onSubmitClicked: _ctx.submitClicked
    }, null, 8, ["steps", "active-step", "onStepChanged", "onGoToStep", "onSubmitClicked"]),
    _createVNode(_component_validation_errors, {
      errors: _ctx.state.errors
    }, null, 8, ["errors"]),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.state.activeStep === 1)
        ? (_openBlock(), _createBlock(_component_vehicle_summary_details, {
            key: 0,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.state.activeStep === 2)
        ? (_openBlock(), _createBlock(_component_vehicle_ownership_details, {
            key: 0,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.state.activeStep === 3)
        ? (_openBlock(), _createBlock(_component_association_create, {
            key: 0,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.state.activeStep === 4)
        ? (_openBlock(), _createBlock(_component_user_permissions, {
            key: 0,
            errors: _ctx.state.errors,
            "project-id": 
          _ctx.payload.association?.copy_association_permissions
            ? _ctx.payload.association?.parent_id
            : undefined
        ,
            "show-toolbar": false
          }, null, 8, ["errors", "project-id"]))
        : _createCommentVNode("", true)
    ], 1024))
  ]))
}