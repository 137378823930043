
  import { defineComponent, PropType, reactive } from 'vue'
  import CcAvatar from '@/components/Generic/Avatar/Avatar.vue'

  export default defineComponent({
    components: {
      CcAvatar,
    },
    props: {
      data: {
        type: Array as PropType<any[]>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
