
  import { defineComponent, computed, onMounted, ref, Ref } from 'vue'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useStore } from 'vuex'
  import { Column } from 'devextreme/ui/data_grid'
  import { useI18n } from 'vue-i18n'
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'

  export default defineComponent({
    components: {
      CcButton,
      TextInput,
      DataGrid,
    },
    props: {
      id: {
        type: String, // Home or School ID
        required: true,
      },
      medicationId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const stock = computed(() => store.getters['mars/getMedicationStock'])

      const payload = ref({
        stock_level: null,
        reason: '',
      })

      const columns = computed((): Column[] => [
        {
          caption: t('mars.medicalCabinet.grid.headers.id'),
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.name'),
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.currentStock'),
          dataField: 'stock_level',
          dataType: 'number',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.type'),
          dataField: 'stock_type.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.category'),
          dataField: 'stock_category.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.requiresAuth'),
          calculateCellValue: (row: MedicationStock) => {
            return row.requires_auth
              ? t('components.radioButton.yes')
              : t('components.radioButton.no')
          },
        },
      ])

      let validationObject = object({
        stock_level: number()
          .typeError('Stock level must be a number')
          .required('Stock level is required')
          .moreThan(0, 'Stock level cannot be a negative value'),
        reason: string().required('Please provide a reason'),
      })

      const adjustStock = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        errors.value = null
        errors.value = await validatePayload(validationObject, payload.value)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        store
          .dispatch('mars/updateStockLevel', {
            medication_stock_id: props.medicationId,
            ...payload.value,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const errors = ref(null) as Ref<Record<string, string> | null>

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })

        store.getters['genericStore/breadCrumbs'].push({
          name: t('mars.stockAdjustment.header'),
          url: '#',
        })

        store
          .dispatch('mars/getStockToAdjust')
          .then((response) => {
            if (response.data.length) {
              store.dispatch('genericStore/showPageLoader', true)
              store
                .dispatch('mars/getMedicationStock', {
                  id: props.medicationId,
                })
                .catch((error) => {
                  store.dispatch('genericStore/pushNotification', error.message)
                  goBack()
                })
                .finally(() =>
                  store.dispatch('genericStore/showPageLoader', false)
                )
            }
          })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
            goBack()
          })
      })
      return {
        goBack,
        payload,
        stock,
        columns,
        adjustStock,
        t,
        validatePayload,
        errors,
        validationObject,
      }
    },
  })
