
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { ProjectTypeSection } from '@/types/modules/projects/ProjectTypeSection'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const { t } = useI18n()
      const state = reactive({
        selectedRows: [] as ProjectTypeSection[],
      })

      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: false,
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
      ]

      const filters = computed(() => {
        return {
          only: ['trashed'],
          type: props.type,
        }
      })

      const rowsSelected = (rows: ProjectTypeSection[]) =>
        (state.selectedRows = rows)

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projectTypeSections/restoreMany', {
            ids: state.selectedRows.map((row: ProjectTypeSection) => row.id),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => goBack())
      }

      return {
        t,
        state,
        goBack,
        columns,
        filters,
        restore,
        rowsSelected,
      }
    },
  })
