
  import { defineComponent, onMounted, onUnmounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import Alert from 'sweetalert2'

  export default defineComponent({
    setup() {
      const store = useStore()
      const state = reactive({
        timer: null as ReturnType<typeof setTimeout> | null,
        idleTime: 0,
        timeoutAfter: 5, // Default
      })

      const resetTimer = () => {
        store.dispatch('genericStore/userAboutToBeLoggedOut', false)
        stopTimer()
        getLocalStorageIdleTime()

        if (
          Alert.isVisible() &&
          Alert.getPopup()?.classList.contains('autologout-swal')
        ) {
          Alert.close()
        }

        if (state.idleTime > 0) {
          state.idleTime = 0
          setLocalStorageIdleTime()
          startTimer()
        } else {
          startTimer()
        }
      }

      const setLocalStorageIdleTime = () => {
        localStorage.setItem('CCIdleTime', state.idleTime.toString())
      }

      const registerEvents = () => {
        document.addEventListener('mousemove', resetTimer)
        document.addEventListener('keypress', resetTimer)
        document.addEventListener('mousedown', resetTimer)
      }

      const setLocalStorageLogoutState = (state = true) => {
        localStorage.setItem('CCAutoLogout', state.toString())
      }

      const stopTimer = () => {
        if (state.timer) {
          clearTimeout(state.timer)
        }
      }

      const getLocalStorageIdleTime = () => {
        state.idleTime = Number(localStorage.getItem('CCIdleTime'))
      }

      const alertUser = () => {
        store.dispatch('genericStore/userAboutToBeLoggedOut', true)
        Alert.fire({
          icon: 'warning',
          title: 'Auto Logout',
          text: 'You are about to be logged out. Interact with the window to continue session',
          customClass: {
            popup: 'autologout-swal',
          },
          showConfirmButton: false,
        })
      }

      const incrementIdleTime = () => {
        getLocalStorageIdleTime()
        state.idleTime++

        setLocalStorageIdleTime()

        if (state.idleTime == state.timeoutAfter - 1) {
          alertUser()
        }

        if (state.idleTime >= state.timeoutAfter) {
          if (Alert.isVisible()) {
            Alert.close()
          }

          store.dispatch('auth/logout')
          return
        }

        startTimer()
      }

      const startTimer = () => {
        stopTimer()
        state.timer = setTimeout(incrementIdleTime, 60000)
      }

      onMounted(() => {
        store.dispatch('genericStore/getTimeoutSetting').then((response) => {
          response.success ? (state.timeoutAfter = response.data.value) : null
        })
        registerEvents()
        setLocalStorageIdleTime()
        setLocalStorageLogoutState(false)
        startTimer()
      })

      onUnmounted(() => {
        stopTimer()
        localStorage.removeItem('CCIdleTime')
        localStorage.removeItem('CCAutoLogout')
        document.removeEventListener('mousemove', resetTimer)
        document.removeEventListener('keypress', resetTimer)
      })

      return {
        state,
      }
    },
  })
