
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import BespokeReportWizard from '@/views/modules/reporting/partials/wizard/BespokeReportWizard.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import SetRuntimeParameters from '@/views/modules/reporting/partials/SetRuntimeParameters.vue'
  import ManageReport from '@/views/modules/reporting/partials/ManageReport.vue'
  import ManageDraft from '@/views/modules/reporting/partials/ManageDraft.vue'
  import { WizardStep } from '@/types/components/WizardStep'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import { DataSet } from '@/types/modules/reporting/BespokeReportDatasource'

  export default defineComponent({
    components: {
      BespokeReportWizard,
      ValidationErrors,
      SetRuntimeParameters,
      ManageReport,
      ManageDraft,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const manageReport = ref()
      const activeStep: Ref<number> = ref(1)
      const errors = ref(null) as Ref<Record<string, string> | null>
      const report = computed(() => store.getters['bespokeReports/report'])
      const datasources = ref([]) as Ref<Datasource[]>
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Design Report',
          url: `/bespoke-reporting/${props.id}/design`,
        },
      ])
      const steps = ref([
        {
          icon: 'fas fa-cog',
          label: 'Parameters',
        },
        {
          icon: 'fas fa-file-chart-line',
          label: 'Design',
        },
      ]) as Ref<WizardStep[]>

      const stepChanged = (step: number) => {
        errors.value = null
        if (
          step === steps.value.length &&
          report.value &&
          report.value.has_draft
        ) {
          errors.value = {
            draft:
              'You cannot design a report with a draft. Please restore or discard the draft first.',
          }
          activeStep.value = steps.value.length - 1
          return
        }
        activeStep.value = step
      }

      const showManageReportStep = computed(() => {
        return (
          (activeStep.value === steps.value.length &&
            report &&
            !report.value.has_draft) ||
          (activeStep.value === steps.value.length &&
            report &&
            report.value.has_draft)
        )
      })

      const update = (updatedDatasource: Datasource) => {
        const index = datasources.value.findIndex(
          (datasource) => datasource.pivot.id === updatedDatasource.pivot.id
        )

        if (index > -1) {
          datasources.value.splice(index, 1, updatedDatasource)
        } else {
          datasources.value.push(updatedDatasource)
        }

        datasources.value[index] = updatedDatasource
      }

      const draftHandled = () => {
        if (report.value && steps.value.length > 2) {
          steps.value.splice(1, 1)
        }
        report.value.has_draft = false
        errors.value = null
        activeStep.value = steps.value.length
      }

      watch(
        () => store.getters['genericStore/userAboutToBeLoggedOut'],
        (value) => {
          if (value === true) {
            autoSaveReport()
          }
        }
      )

      const submitClicked = () => saveReport()
      const autoSaveReport = () => saveReport(true)

      const saveReport = (is_autosaved: boolean = false) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch(
            is_autosaved
              ? 'bespokeReportDrafts/store'
              : 'bespokeReports/update',
            {
              id: props.id,
              ...{
                name: report.value.name,
                description: report.value.description,
                report_type_id: report.value.report_type_id,
                datasources: datasources.value,
                definition: manageReport.value?.saveReportToJson(),
              },
            }
          )
          .then((response) => {
            if (!is_autosaved) {
              store.dispatch('genericStore/pushNotification', response.message)
            }
          })
          .catch((error) => (errors.value = error.response.data.errors))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const updateDataSets = (dataSets: DataSet[]) => {
        store.commit(
          'bespokeReportDatasources/setDatasets',
          dataSets.map((dataSet) => dataSet.data)
        )
      }

      watch(
        datasources.value,
        () => {
          store.commit(
            'bespokeReportDatasources/setBespokeReportDatasources',
            datasources.value
          )
        },
        { deep: true, immediate: true }
      )

      watch(
        report,
        () => {
          if (report.value && report.value.has_draft) {
            steps.value.splice(1, 0, {
              icon: 'fas fa-file-import',
              label: 'Draft',
            })
          }

          if (
            report.value &&
            !report.value.has_draft &&
            steps.value.length > 2
          ) {
            steps.value.splice(1, 1)
          }
        },
        { deep: true, immediate: true }
      )

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (!report.value) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('bespokeReports/show', props.id)
            .catch((error) => (errors.value = error.response.data.errors))
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      return {
        steps,
        errors,
        report,
        update,
        activeStep,
        datasources,
        stepChanged,
        manageReport,
        draftHandled,
        submitClicked,
        updateDataSets,
        showManageReportStep,
      }
    },
  })
