
  import { DxFileUploader } from 'devextreme-vue/file-uploader'
  import { DxProgressBar } from 'devextreme-vue/progress-bar'
  import { defineComponent, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      DxFileUploader,
      DxProgressBar,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    emits: ['file-uploaded'],
    setup(props, { emit }) {
      const { t } = useI18n()
      props = reactive(props)

      const store = useStore()
      const allowedFileExtensions = ['.jpg', '.jpeg', '.png', '.gif']

      const validate = (file: File) => {
        if (!allowedFileExtensions.includes(`.` + file.type.split('/')[1])) {
          store.dispatch(
            'genericStore/pushNotification',
            'Uploaded file is not a valid file type.'
          )
          return false
        }

        return true
      }

      const uploadFile = (e: any) => {
        let file: File = e.value[0]

        if (validate(file)) {
          emit('file-uploaded', file)
        }
      }

      return {
        allowedFileExtensions,
        uploadFile,
        t,
      }
    },
  })
