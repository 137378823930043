import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_3 = { class: "py-2 inline-block min-w-full sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "overflow-hidden" }
const _hoisted_5 = { class: "min-w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_permission_tile = _resolveComponent("permission-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index,
                  class: "border-b h-20"
                }, [
                  _createElementVNode("td", null, _toDisplayString(item.name), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_permission_tile, {
                      type: "access",
                      permission: _ctx.assignPermission(item),
                      onPermissionUpdated: ($event: any) => (_ctx.updatePermission($event, item)),
                      class: "float-right"
                    }, null, 8, ["permission", "onPermissionUpdated"])
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}