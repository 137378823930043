import generic from './generic'
import errors from './errors'
import projects from './projects'
import { RouteRecordRaw } from 'vue-router'
import dashboard from './dashboard'
import care_groups from './care_groups'
import forms from './forms'
import attachments from './attachments'
import sensitive from './sensitive'
import documents from './documents'
import users from './users'
import reporting from './reporting'
import groupings from './groupings'
import tasks from './tasks'
import regions from './regions'
import mars from './mars'
import employees from './employees'
import rotas from './rotas'
import calendar from './calendar'
import files from './files'
import staffs from './staffs'

export default [
  ...dashboard,
  ...care_groups,
  ...forms,
  ...generic,
  ...errors,
  ...projects,
  ...attachments,
  ...sensitive,
  ...documents,
  ...users,
  ...reporting,
  ...groupings,
  ...tasks,
  ...regions,
  ...mars,
  ...employees,
  ...rotas,
  ...calendar,
  ...files,
  ...staffs,
] as RouteRecordRaw[]
