
  import { defineComponent, reactive } from 'vue'
  import IpAddresses from './partials/Settings/IpAddresses.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import Domains from './partials/Settings/Domains.vue'
  import PasswordPolicy from '@/views/modules/settings/partials/PasswordPolicy.vue'

  export default defineComponent({
    components: {
      IpAddresses,
      ButtonGroup,
      CcButton,
      Domains,
      PasswordPolicy,
    },
    setup() {
      const state = reactive({
        activeTab: 'IP Addresses',
        tabs: ['IP Addresses', 'Authorised Domains', 'Password Policy'],
      })

      return {
        state,
      }
    },
  })
