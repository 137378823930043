import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { class: "w-full py-4 px-6 overflow-hidden" }
const _hoisted_3 = { class: "flex flex-col justify-between h-full" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_menu = _resolveComponent("cc-menu")!
  const _component_cc_header = _resolveComponent("cc-header")!
  const _component_cc_footer = _resolveComponent("cc-footer")!
  const _component_toast_notification = _resolveComponent("toast-notification")!
  const _component_auto_logout = _resolveComponent("auto-logout")!

  return (_ctx.authUser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_cc_menu, {
          links: _ctx.getters.links,
          tabs: _ctx.getters.tabs
        }, null, 8, ["links", "tabs"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_cc_header),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ]),
            _createVNode(_component_cc_footer)
          ])
        ]),
        (_ctx.hasNotification)
          ? (_openBlock(), _createBlock(_component_toast_notification, {
              key: 0,
              "fire-on": _ctx.hasNotification.value,
              message: _ctx.notification
            }, null, 8, ["fire-on", "message"]))
          : _createCommentVNode("", true),
        (_ctx.authUser)
          ? (_openBlock(), _createBlock(_component_auto_logout, { key: 1 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}