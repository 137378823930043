import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-663e833d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex grow items-start flex-col lg:w-8/12" }
const _hoisted_2 = { class: "text-right flex items-end lg:w-4/12 justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.overviewCardClasses, _ctx.borderColor])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass([_ctx.icon, _ctx.color, 'fa-2x xl:fa-4x flex items-center'])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass(['text-xs font-bold tracking-slight lowercase', _ctx.color])
      }, _toDisplayString(_ctx.label), 3),
      _createElementVNode("span", {
        class: _normalizeClass(['font-black text-3xl font tracking-slight', _ctx.color])
      }, _toDisplayString(_ctx.value), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cc_button, {
        label: "view",
        type: "tertiary",
        class: "!p-0"
      })
    ])
  ], 2))
}