import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('bespoke-reports', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: number) => {
    return axios
      .get(`bespoke-reports/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('bespoke-reports', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: number, payload: any) => {
    return axios
      .put(`bespoke-reports/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: async (payload: any) => {
    return axios
      .post('bespoke-reports/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post('bespoke-reports/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
