import axios from 'axios'

export default {
  index: async (bespokeReportId: number, params: any) => {
    return axios
      .get(`bespoke-reports/${bespokeReportId}/published-reports`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: number) => {
    return axios
      .get(`bespoke-reports/published-reports/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (id: number, payload: any) => {
    return axios
      .post(`bespoke-reports/${id}/published-reports`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: number, payload: any) => {
    return axios
      .put(`bespoke-reports/published-reports/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: async (payload: any) => {
    return axios
      .post(`bespoke-reports/published-reports/delete-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post('bespoke-reports/published-reports/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
