import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 0,
            class: "border-l-2 border-l-white flex-1 overflow-x-hidden",
            "aria-label": "menu tabs for current page",
            "aria-hidden": _ctx.isCollapsed
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (link, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass([
            _ctx.linkClasses,
            _ctx.route.fullPath === link.url
              ? 'bg-white text-blue-500 font-bold'
              : '',
          ]),
                  to: link.url
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.name), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "to"])
              ]))
            }), 128))
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}