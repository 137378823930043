import { GetterTree, ActionTree, MutationTree } from 'vuex'
import TasksService from '@/services/tasks/tasks'
import { Task } from '@/types/modules/tasks/Task'
import { TaskFile } from '@/types/modules/tasks/TaskFile'
import { TaskAccessLog } from '@/types/modules/tasks/TaskAccessLog'
import { TaskLog } from '@/types/modules/tasks/TaskLog'
import { TaskCount } from '@/types/modules/tasks/TaskCount'
import { User } from '@/types/modules/users/User'

export type TaskGetter = GetterTree<TaskState, any>

export interface TaskState {
  tasks: Task[]
  taskAttachments: TaskFile[]
  selectedAttachments: object[]
  task: Task | null
  taskAccessLogs: TaskAccessLog[]
  taskExplorerChart: object
  logsList: TaskLog[]
  taskCounts: TaskCount[]
  selectedTasks: Task[]
  blacklistedUsers: User[]
  workflowTasks: Task[]
}

export const state: TaskState = {
  tasks: [],
  taskAttachments: [],
  selectedAttachments: [],
  task: null,
  taskAccessLogs: [],
  taskExplorerChart: {},
  logsList: [],
  taskCounts: [],
  selectedTasks: [],
  blacklistedUsers: [],
  workflowTasks: [],
}

export const getters: TaskGetter = {
  tasks: (state) => state.tasks,
  taskAttachments: (state) => state.taskAttachments,
  selectedAttachments: (state) => state.selectedAttachments,
  task: (state) => state.task,
  taskAccessLogs: (state) => state.taskAccessLogs,
  taskExplorerChart: (state) => state.taskExplorerChart,
  logsList: (state) => state.logsList,
  taskCounts: (state) => state.taskCounts,
  selectedTasks: (state) => state.selectedTasks,
  blacklistedUsers: (state) => state.blacklistedUsers,
  workflowTasks: (state) => state.workflowTasks,
}

export const actions: ActionTree<TaskState, any> = {
  taskExplorerChart: async (context, params) => {
    let response = await TasksService.getTaskExplorerChart(params)
    context.commit('setTaskExplorerChart', {
      data: response.data,
    })

    return {
      data: response.data,
    }
  },
  logsList: async (context, params) => {
    const response = await TasksService.getLogsList(params)

    if (response.success && params.per_page) {
      context.commit('setLogsList', {
        data: response.data.data,
        totalCount: response.data.total,
      })

      return {
        data: response.data.data,
        totalCount: response.data.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setLogsList', response.data)

      return response
    }

    return response
  },
  index: async (context, params) => {
    let response = await TasksService.index(params)

    if (response.success && params.per_page) {
      context.commit('setTasks', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTasks', response.data)

      return response
    }

    return response
  },
  createUnscheduledTask: async (context, params) => {
    const results = Promise.resolve(TasksService.createUnscheduledTask(params))

    results.then((response) => {
      if (response.success) {
        context.commit('setTask', response.data)
      }
    })

    return results
  },
  getTaskAttachments: async (context, params) => {
    const response = await TasksService.getTaskAttachments(params)

    if (response.success && params.per_page) {
      context.commit('setTaskAttachments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTaskAttachments', response.data)

      return response
    }

    return response
  },
  getSelectedAttachments: async (context) => {
    const response = await context.getters['selectedAttachments']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  moveAttachments: async (_context, payload) => {
    return await TasksService.moveAttachments(payload)
  },
  toggleTaskSensitivity: (context, params) => {
    let results = Promise.resolve(
      TasksService.toggleTaskSensitivity(params.id, params)
    )

    results.then((response) => {
      if (response.success) {
        context.commit('setTask', response.data)
      }
    })

    return results
  },
  getSensitiveTaskAttachments: async (context, params) => {
    const response = await TasksService.getSensitiveTaskAttachments(params)

    if (response.success && params.per_page) {
      context.commit('setTaskAttachments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTaskAttachments', response.data)

      return response
    }

    return response
  },
  getTaskAccessLogs: async (context, params) => {
    const response = await TasksService.getTaskAccessLogs(params)

    if (response.success && params.per_page) {
      context.commit('setTaskAccessLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setTaskAccessLogs', response.data)

      return response
    }

    return response
  },
  getTaskCounts: (context, params) => {
    let response = Promise.resolve(TasksService.getTaskCounts(params))
    response.then((response) => {
      if (response.success) {
        context.commit('setTaskCounts', response.data)
      }
    })
    return response
  },

  show: (context, { id, params }) => {
    let results = Promise.resolve(TasksService.show(id, params))

    results.then((response) => {
      if (response.success) {
        context.commit('setTask', response.data)
      }
    })

    return results
  },
  setSelectedTasks: (context, tasks: Task[]) => {
    context.commit('setSelectedTasks', tasks)
  },
  getSelectedTasks: async (context) => {
    const response = await context.getters['selectedTasks']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  linkTask: (_context, payload) => {
    return Promise.resolve(TasksService.linkTask(payload))
  },
  unlinkTask: (_context, payload) => {
    return Promise.resolve(TasksService.unlinkTask(payload))
  },
  copyTask: (context, payload) => {
    let results = Promise.resolve(TasksService.copyTask(payload))

    results.then((response) => {
      if (response.success) {
        context.commit('setTask', response.data)
      }
    })

    return results
  },
  getBlacklistedUsers: (context, taskId) => {
    let results = Promise.resolve(TasksService.getBlacklistedUsers(taskId))

    results.then((response) => {
      if (response.success) {
        context.commit('setBlacklistedUsers', response.data)
      }
    })

    return results
  },
  updateBlacklistedUsers: (_context, payload) => {
    return Promise.resolve(TasksService.updateBlacklistedUsers(payload))
  },
  getWorkflowForTask: (context, id) => {
    let results = Promise.resolve(TasksService.getWorkflowForTask(id))

    results.then((response) => {
      if (response.success) {
        context.commit('setWorkflowForTask', response.data)
      }
    })

    return results
  },
}

export const mutations: MutationTree<TaskState> = {
  setTasks: (state, tasks: Task[]) => {
    state.tasks = tasks
  },
  setTaskAttachments: (state, files: TaskFile[]) => {
    state.taskAttachments = files
  },
  setSelectedAttachments: (state, attachments) => {
    state.selectedAttachments = attachments
  },
  setTask: (state, task: Task) => (state.task = task),
  setTaskAccessLogs: (state, logs: TaskAccessLog[]) => {
    state.taskAccessLogs = logs
  },
  setTaskExplorerChart: (state, data) => {
    state.taskExplorerChart = data
  },
  setLogsList: (state, data) => {
    state.logsList = data
  },
  setTaskCounts: (state, taskCounts) => {
    state.taskCounts = taskCounts
  },
  setSelectedTasks: (state, tasks: Task[]) => (state.selectedTasks = tasks),
  setBlacklistedUsers: (state, users: User[]) =>
    (state.blacklistedUsers = users),
  setWorkflowForTask: (state, tasks: Task[]) => (state.workflowTasks = tasks),
}

export const tasks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
