
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    Ref,
    watch,
  } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { Column } from 'devextreme/ui/data_grid'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import { Project } from '@/types/modules/projects/Project'
  import { Task } from '@/types/modules/tasks/Task'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'
  import InputLabel from '@/components/Generic/Forms/InputLabel.vue'

  export default defineComponent({
    components: {
      SelectInput,
      CcButton,
      DataGrid,
      ValidationErrors,
      CcSwitch,
      InputLabel,
    },
    props: {
      taskId: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Title',
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: 'Task Date',
          dataType: 'date',
          dataField: 'start_date',
          allowGrouping: false,
        },
      ])

      const currentTask = computed(() => {
        if (task.value) {
          return [
            {
              label: task.value.name,
              value: task.value.id,
            },
          ]
        }

        return []
      })

      const selectableProjectTypes = computed(() => {
        if (store.getters['projects/types'].length && task.value) {
          return store.getters['projects/types']
            .filter((type: ProjectType) => {
              return task.value?.form.types
                .map((type: any) => type.name)
                .includes(type.name)
            })
            .map((type: ProjectType) => {
              return {
                value: type.name,
                label: type.name,
              }
            })
        }

        return []
      })

      const selectableProjects = computed(() => {
        if (store.getters['projects/projects'].length && task.value) {
          return store.getters['projects/projects'].map((project: Project) => {
            return {
              value: project.id,
              label: project.name,
            }
          })
        }

        return []
      })

      const errors = ref(null) as Ref<Record<string, string> | null>
      const task = computed((): Task => store.getters['tasks/task'])
      const projectType = ref() as Ref<string | undefined>

      const payload = ref({
        project_id: null,
        task_id: null,
        copy_files: 0,
        link_tasks: 0,
        copy_comments: 0,
      }) as Ref<{
        project_id: number | null
        task_id: number | null
        copy_files: number
        link_tasks: number
        copy_comments: number
      }>

      const getTask = () => {
        return store
          .dispatch('tasks/show', { id: props.taskId, params: {} })
          .then(() => {
            payload.value.task_id = Number(props.taskId)
            store.dispatch('tasks/setSelectedTasks', [task.value])
          })
      }

      const getProjectTypes = () => {
        return store.dispatch('projects/getProjectTypes', {})
      }

      const getProjects = (params: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/index', params)
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      watch(projectType, () => {
        if (projectType.value) {
          getProjects({ type: [projectType.value] })
        }
      })

      const copy = () => {
        store.dispatch('genericStore/showPageLoader', true)

        if (!payload.value.project_id) {
          errors.value = {
            project_id: `Please choose a ${projectType.value} to copy the task to.`,
          }
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        store
          .dispatch('tasks/copyTask', payload.value)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            router.replace({
              name: 'ViewLog',
              params: { taskId: response.data.id },
            })
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })

        Promise.all([getTask(), getProjectTypes()]).then(() => {
          projectType.value = selectableProjectTypes.value[0].value
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      onUnmounted(() => {
        store.commit('projects/setTypes', [])
        store.commit('projects/setProjects', [])
      })

      return {
        payload,
        goBack,
        task,
        columns,
        copy,
        projectType,
        selectableProjectTypes,
        selectableProjects,
        currentTask,
        errors,
      }
    },
  })
