import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "fas fa-shield-halved",
  title: "Sensitive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.data.files, (file, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      _createElementVNode("a", {
        href: file.url,
        class: "text-blue-300",
        target: "_blank"
      }, [
        _createTextVNode(_toDisplayString(index + 1) + ". ", 1),
        (file.is_sensitive)
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(file.name), 1)
      ], 8, _hoisted_1)
    ]))
  }), 128))
}