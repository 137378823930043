import { GetterTree, MutationTree, ActionTree } from 'vuex'
import CalendarService from '@/services/calendar/calendar'
import { CalendarEventInstance } from '@/types/modules/calendar/CalendarEventInstance'
import { CalendarEvent } from '@/types/modules/calendar/CalendarEvent'

export type CalendarGetter = GetterTree<CalendarState, any>

export interface CalendarState {
  event: CalendarEvent | null
  eventInstance: CalendarEventInstance | null
  eventInstances: CalendarEventInstance[]
}

export const state: CalendarState = {
  eventInstance: null,
  event: null,
  eventInstances: [],
}

export const getters: CalendarGetter = {
  getEventInstance: (state) => state.eventInstance,
  getEvent: (state) => state.event,
  eventInstances: (state) => state.eventInstances,
}

export const actions: ActionTree<CalendarState, any> = {
  index: async (context, params) => {
    let results = Promise.resolve(CalendarService.index(params))

    results.then((response) => {
      if (response.success) {
        context.commit('setEventInstances', response.data)
      }
    })

    return results
  },
  getEventInstance: (context, id) => {
    let results = Promise.resolve(CalendarService.getEventInstance(id))

    results.then((response) => {
      if (response.success) {
        context.commit('setEventInstance', response.data)
      }
    })

    return results
  },
  getEvent: (context, id) => {
    let results = Promise.resolve(CalendarService.getEvent(id))

    results.then((response) => {
      if (response.success) {
        context.commit('setEvent', response.data)
      }
    })

    return results
  },
  updateEvent: async (_context, payload) => {
    let results = await Promise.resolve(
      CalendarService.updateEvent(payload.id, payload.payload)
    )

    return results
  },
  createEvent: async (_context, payload) => {
    let results = await Promise.resolve(CalendarService.createEvent(payload))

    return results
  },
  saveNote: (_context, payload) => {
    let results = Promise.resolve(CalendarService.saveNote(payload.id, payload))

    return results
  },
  stopRecurringEvent: (_context, payload) => {
    let results = Promise.resolve(
      CalendarService.stopRecurringEvent(payload.id, payload)
    )

    return results
  },
  updateEventInstanceStatus: (_context, payload) => {
    let results = Promise.resolve(
      CalendarService.updateEventInstanceStatus(payload.id, payload)
    )

    return results
  },
  deleteEvent: (_context, payload) => {
    let results = Promise.resolve(
      CalendarService.deleteEvent(payload.id, payload)
    )

    return results
  },
}

export const mutations: MutationTree<CalendarState> = {
  setEventInstance: (state, instance: CalendarEventInstance) => {
    state.eventInstance = instance
  },
  setEvent: (state, event: CalendarEvent) => {
    state.event = event
  },
  setEventInstances: (state, instances: CalendarEventInstance[]) => {
    state.eventInstances = instances
  },
}

export const calendar = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
