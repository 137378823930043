
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import FormGroupsTable from './partials/FormGroupsTable.vue'
  import KeywordsTable from './partials/KeywordsTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      FormGroupsTable,
      KeywordsTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Form Groups',
        tabs: ['Form Groups', 'Keywords'],
      })

      return {
        state,
      }
    },
  })
