import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "File Categories",
    "create-view": "FileCategoryCreate",
    "edit-view": "FileCategoryEdit",
    "delete-view": "FileCategoryDelete",
    "restore-view": "FileCategoryRestore",
    action: "fileCategories/index",
    "toggle-visibility-action": "fileCategories/toggleManyVisibility",
    "delete-many-action": "fileCategories/deleteMany",
    "reorder-action": "fileCategories/saveOrderings"
  }))
}