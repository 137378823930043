
  import { defineComponent, computed } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'

  export default defineComponent({
    components: {
      DataGrid,
    },

    setup() {
      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: true,
        },
        {
          dataField: 'role.name',
          caption: 'Position',
          allowGrouping: false,
        },
        {
          dataField: 'responsible_individual',
          caption: 'Responsible Individual',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'start_date',
          caption: 'Start Date',
          allowGrouping: false,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'end_date',
          caption: 'End Date',
          allowGrouping: false,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
        },
      ]
      const filters = computed(() => {
        return {
          relations: ['role'],
        }
      })

      return {
        columns,
        filters,
      }
    },
  })
