import { GetterTree, MutationTree, ActionTree } from 'vuex'
import DocumentCategoryService from '@/services/documents/documentCategories'
import { DocumentCategory } from '@/types/modules/documents/DocumentCategory'

export type DocumentCategoryGetter = GetterTree<DocumentCategoryState, any>

export interface DocumentCategoryState {
  categories: DocumentCategory[]
  category: DocumentCategory | null
}

export const state: DocumentCategoryState = {
  categories: [],
  category: null,
}

export const getters: DocumentCategoryGetter = {
  getDocumentCategories: (state) => state.categories,
  getDocumentCategory: (state) => state.category,
}

export const actions: ActionTree<DocumentCategoryState, any> = {
  index: async (context, params) => {
    const response = await DocumentCategoryService.index(params)

    if (response.success && params.per_page) {
      context.commit('setDocumentCategories', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setDocumentCategories', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      DocumentCategoryService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setDocumentCategory', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(DocumentCategoryService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(DocumentCategoryService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(DocumentCategoryService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(DocumentCategoryService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(DocumentCategoryService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(DocumentCategoryService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(DocumentCategoryService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<DocumentCategoryState> = {
  setDocumentCategories: (state, categories: DocumentCategory[]) =>
    (state.categories = categories),
  setDocumentCategory: (state, category: DocumentCategory) =>
    (state.category = category),
}

export const documentCategories = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
