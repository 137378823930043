import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-8 p-6 shadow-md border border-gray-100 font-display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_summary = _resolveComponent("form-summary")!
  const _component_cc_table = _resolveComponent("cc-table")!
  const _component_multi_step = _resolveComponent("multi-step")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_form_summary, { form: _ctx.form }, null, 8, ["form"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.formattedFields.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formattedFields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `field-${field.id}`,
              class: "my-6"
            }, [
              (field.field.system_name === 'table' && field.is_visible)
                ? (_openBlock(), _createBlock(_component_cc_table, _normalizeProps(_mergeProps({ key: 0 }, _ctx.getProps(field))), null, 16))
                : _createCommentVNode("", true),
              (field.field.system_name !== 'table' && field.is_visible)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getFieldComponent(field.field.system_name)), _mergeProps({ key: 1 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field))), null, 16))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        : _createCommentVNode("", true),
      (_ctx.state.steps.length)
        ? (_openBlock(), _createBlock(_component_multi_step, {
            key: 1,
            steps: _ctx.state.steps.map((step) => step.name)
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.state.steps, (step) => {
              return {
                name: step.name,
                fn: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.fields, (field) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `field-${field.id}`,
                      class: "my-6"
                    }, [
                      (field.field.system_name === 'table' && field.is_visible)
                        ? (_openBlock(), _createBlock(_component_cc_table, _normalizeProps(_mergeProps({ key: 0 }, _ctx.getProps(field))), null, 16))
                        : _createCommentVNode("", true),
                      (field.field.system_name !== 'table' && field.is_visible)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getFieldComponent(field.field.system_name)), _mergeProps({ key: 1 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field))), null, 16))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              }
            })
          ]), 1032, ["steps"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}