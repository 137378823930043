export default {
  careGroupDetails: {
    header: 'Summary',
    id: 'ID',
    name: 'Care Group Name',
    type: 'Type',
    fixedIp: 'Company uses fixed ip?',
    website: 'Company Website',
    description: 'Description',
    updatedOn: 'Last Updated',
    updatedBy: 'Last Updated By',
  },
}
