
  import { defineComponent, reactive, computed } from 'vue'
  import { CalendarEventInstance } from '@/types/modules/calendar/CalendarEventInstance'
  import { format } from 'date-fns'

  export default defineComponent({
    props: {
      eventInstance: {
        type: Object,
        required: true,
      },
      type: {
        type: String,
        required: true,
        validator: (value: string) =>
          [
            'general',
            'appointment',
            'meeting',
            'all-day',
            'anniversary',
            'reminder',
            'medication',
          ].indexOf(value) !== -1,
        default: 'general',
      },
    },
    setup(props) {
      props = reactive(props)

      const labelClasses = 'capitalize text-xxs text-gray-400 block'
      const labelValueClasses = 'capitalize text-xxs block font-semibold'
      const eventTitleClasses =
        'block font-semibold text-base tracking-slight mb-1'

      const borderClasses = computed(() => {
        return {
          'rounded-md': true,
          'border-2': true,
          'py-2': true,
          'px-2': true,
          'border-blue-300 bg-blue-100': props.type == 'general',
          'border-orange-300 bg-orange-100': props.type == 'reminder',
          'border-green-200 bg-green-100': props.type == 'all-day',
          'h-max': true,
        }
      })

      const eventTypeIcon = computed(() => {
        return {
          'fad': true,
          'fa-xl': true,
          'text-blue-300 fa-calendar-alt': props.type == 'general',
          'text-orange-300 fa-alarm-clock': props.type == 'reminder',
          'text-green-200 fa-calendar-star': props.type == 'all-day',
        }
      })

      const splitAssignees = (
        eventInstance: CalendarEventInstance,
        index: number | string
      ) => {
        let assignees = eventInstance.event.assignees
        if (assignees) {
          return Number(index) != assignees.length - 1
        }

        return false
      }

      return {
        labelClasses,
        labelValueClasses,
        eventTitleClasses,
        eventTypeIcon,
        borderClasses,
        props,
        splitAssignees,
        format,
      }
    },
  })
