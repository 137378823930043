import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bespoke_report_wizard = _resolveComponent("bespoke-report-wizard")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_report_details = _resolveComponent("report-details")!
  const _component_report_datasources = _resolveComponent("report-datasources")!
  const _component_report_permissions = _resolveComponent("report-permissions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bespoke_report_wizard, {
      steps: _ctx.steps,
      "active-step": _ctx.activeStep,
      onStepChanged: _ctx.stepChanged,
      onSubmitClicked: _ctx.submitClicked
    }, null, 8, ["steps", "active-step", "onStepChanged", "onSubmitClicked"]),
    (_ctx.errors && Object.values(_ctx.errors).length)
      ? (_openBlock(), _createBlock(_component_validation_errors, {
          key: 0,
          errors: _ctx.errors
        }, null, 8, ["errors"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_report_details, {
      type: "BespokeReport",
      report: _ctx.props.report,
      onReportDetailsUpdated: _ctx.payloadUpdated
    }, null, 8, ["report", "onReportDetailsUpdated"]), [
      [_vShow, _ctx.activeStep === 1]
    ]),
    _withDirectives(_createVNode(_component_report_datasources, {
      report: _ctx.props.report,
      onReportDatasourcesUpdated: _ctx.payloadUpdated
    }, null, 8, ["report", "onReportDatasourcesUpdated"]), [
      [_vShow, _ctx.activeStep === 2]
    ]),
    _withDirectives(_createVNode(_component_report_permissions, {
      report: _ctx.props.report,
      type: "BespokeReport",
      onReportPermissionsUpdated: _ctx.payloadUpdated
    }, null, 8, ["report", "onReportPermissionsUpdated"]), [
      [_vShow, _ctx.activeStep === 3]
    ])
  ], 64))
}