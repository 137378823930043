import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "font-semibold leading-slight font-display text-sm"
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.modelValue) = $event)),
        id: _ctx.id
      }, null, 40, _hoisted_2), [
        [_vModelCheckbox, _ctx.props.modelValue]
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.props.label), 1)
    ], 8, _hoisted_1)
  ]))
}