
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import EmployeeSummary from './details/EmployeeSummary.vue'
  import EmployeeContactDetails from './details/EmployeeContactDetails.vue'
  import EmployeePersonalDetails from './details/EmployeePersonalDetails.vue'
  import EmployeeEmploymentDetails from './details/EmployeeEmploymentDetails.vue'
  import { useStore } from 'vuex'
  import CustomFieldDetails from '../../partials/CustomFieldDetails.vue'
  import { useI18n } from 'vue-i18n'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import GridToolbar from '@/components/Generic/Grid/GridToolbar.vue'
  import ExpandButton from '@/components/Generic/Button/ExpandButton.vue'

  export default defineComponent({
    components: {
      EmployeeSummary,
      EmployeeContactDetails,
      EmployeePersonalDetails,
      EmployeeEmploymentDetails,
      CustomFieldDetails,
      Chips,
      GridToolbar,
      ExpandButton,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const customFieldGroups = computed(
        () => store.getters['projects/customFieldGroups']
      )
      const customFieldTabs = computed(() =>
        Object.keys(customFieldGroups.value)
      )
      props = reactive(props)
      const store = useStore()
      const state = reactive({
        activeTab: 'summary',
        availableTabs: [
          'summary',
          'contact details',
          'Personal details',
          'Employment details',
        ],
        customFieldTabs: customFieldTabs,
        expanded: false,
      })
      const showTab = (value: string) => {
        state.activeTab = value
      }

      const getAllTabs = computed(() => {
        return state.availableTabs.concat(state.customFieldTabs)
      })

      const hasCustomTab = (tab: string) => {
        return state.customFieldTabs.includes(tab)
      }

      onMounted(() => {
        store.dispatch('projects/customFields', {
          type: 'employee',
        })
      })

      return {
        showTab,
        state,
        props,
        getAllTabs,
        hasCustomTab,
        customFieldGroups,
        t,
      }
    },
  })
