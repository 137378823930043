
  import {
    defineComponent,
    computed,
    PropType,
    ref,
    reactive,
    watch,
    onMounted,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import { useStore } from 'vuex'
  import { FormType } from '@/types/modules/forms/FormType'
  import { FormGroup } from '@/types/modules/forms/FormGroup'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { Form } from '@/types/modules/forms/Form'
  import { FormStatus } from '@/types/modules/forms/FormStatus'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { Region } from '@/types/modules/regions/Region'
  import { FormKeyword } from '@/types/modules/forms/FormKeyword'
  import { isArray } from 'lodash'
  import { FormTemplate } from '@/types/modules/forms/FormTemplate'
  import SelectBox from '@/components/Generic/Forms/Inputs/SelectBox.vue'
  import { canEditModule } from '@/composables/Permissions'
  import { SelectOption } from '@/types/components/SelectOption'
  import { object, string } from 'yup'

  export default defineComponent({
    components: {
      TextInput,
      MultiSelect,
      RadioButton,
      SelectInput,
      TextBox,
      SelectBox,
    },
    props: {
      form: {
        type: Object as PropType<Form>,
        required: false,
      },
    },
    emits: ['form-details-updated', 'form-template-set'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()

      const payload = ref({
        name: '' as string,
        form_group_id: null as number | null,
        form_type_ids: [] as number[],
        form_status_id: 1 as number | null,
        description: '' as string | null,
        instructions: '' as string | null,
        can_be_used_as_one_off_form: 0 as number | null,
        can_be_used_in_procedure: 0 as number | null,
        restrict_projects_by_association: 1 as number,
        is_hidden: 0 as number | null,
        region_id: null as number | null,
        keywords: [] as number[],
        triggered_task_id: null as number | null,
        trigger_has_date: 0 as number,
        always_trigger_for_same_project: 0 as number,
        trigger_instant_edit: 0 as number,
        email_notifications: [] as any[],
      })

      const yesNoButtons = [
        {
          id: 1,
          text: 'yes',
        },
        {
          id: 0,
          text: 'no',
        },
      ]

      const showFormTemplateDropdown = computed(() => {
        if (props.form || formTemplate.value) {
          return false
        }

        return true
      })

      const addFormGroup = (e: any) => {
        e.customItem = null

        if (
          !e.text ||
          formGroups.value.find((group: SelectOption) => group.label === e.text)
        ) {
          return
        }

        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('formGroups/store', { name: e.text })
          .then((response) => {
            payload.value.form_group_id = response.data.id
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const formStatuses = computed(() => {
        if (store.getters['formStatuses/getFormStatuses'].length) {
          return store.getters['formStatuses/getFormStatuses']
            .filter((status: FormStatus) => status.name !== 'Archived')
            .map((status: FormStatus) => {
              return {
                text: status.name,
                id: status.id,
              }
            })
        }

        return []
      })

      const formTypes = computed(() => {
        if (store.getters['formTypes/formTypes'].length) {
          return store.getters['formTypes/formTypes']
            .filter((type: FormType) => type.project_type_id)
            .map((type: FormType) => {
              return {
                text: type.name,
                value: type.id,
              }
            })
        }

        return []
      })

      const formGroups = computed(() => {
        if (store.getters['formGroups/formGroups'].length) {
          return store.getters['formGroups/formGroups'].map(
            (group: FormGroup) => {
              return {
                label: group.name,
                value: group.id,
              }
            }
          )
        }

        return []
      })

      const forms = computed(() => {
        if (store.getters['forms/forms'].length) {
          return store.getters['forms/forms'].map((form: Form) => {
            return {
              label: form.name,
              value: form.id,
            }
          })
        }

        return []
      })

      const keywords = computed(() => {
        if (store.getters['keywords/getKeywords'].length) {
          return store.getters['keywords/getKeywords'].map(
            (keyword: FormKeyword) => {
              return {
                label: keyword.name,
                value: keyword.id,
              }
            }
          )
        }

        return []
      })

      const formTemplate = ref()

      const formTemplates = computed(() => {
        if (store.getters['forms/getFormTemplates'].length) {
          return store.getters['forms/getFormTemplates'].map(
            (template: FormTemplate) => {
              return {
                label: template.name,
                value: template.id,
              }
            }
          )
        }

        return []
      })

      const getForms = (params: any = {}) => {
        return store.dispatch('forms/index', params)
      }

      const getFormTypes = (params: any = {}) => {
        return store.dispatch('formTypes/index', params)
      }

      const getFormGroups = (params: any = {}) => {
        return store.dispatch('formGroups/index', params)
      }

      const getFormStatuses = (params: any = {}) => {
        return store.dispatch('formStatuses/index', params)
      }

      const getRegions = (params: any = {}) => {
        return store.dispatch('regions/index', params)
      }

      const getKeywords = (params: any = {}) => {
        return store.dispatch('keywords/index', params)
      }

      const getFormTemplates = (params: any = {}) => {
        return store.dispatch('forms/getFormTemplates', params)
      }

      const regions = computed(() => {
        return store.getters['regions/regions'].map((region: Region) => {
          return { label: region.name, value: region.id }
        })
      })

      watch(formTemplate, () =>
        emit(
          'form-template-set',
          store.getters['forms/getFormTemplates'].find(
            (t: FormTemplate) => t.id === Number(formTemplate.value)
          ).definition
        )
      )

      const loadDependencies = () => {
        return Promise.all([
          getFormTypes({}),
          getFormGroups({}),
          getFormStatuses({}),
          getRegions({}),
          getKeywords({}),
          getForms({}),
          getFormTemplates({}),
        ])
      }

      const mapToPayload = () => {
        let form = props.form!

        payload.value.name = form.name
        payload.value.form_group_id = form.form_group_id
        payload.value.form_type_ids = form.types.map(
          (type: FormType) => type.id
        )
        payload.value.form_status_id = form.form_status_id
        payload.value.description = form.description
        payload.value.instructions = form.instructions
        payload.value.can_be_used_as_one_off_form =
          form.can_be_used_as_one_off_form
        payload.value.can_be_used_in_procedure = form.can_be_used_in_procedure
        payload.value.is_hidden = form.is_hidden
        payload.value.region_id = form.region_id
        payload.value.keywords = isArray(form.keywords)
          ? form.keywords.map((keyword: FormKeyword) => keyword.id)
          : []
        payload.value.triggered_task_id = form.triggered_task_id
        payload.value.always_trigger_for_same_project =
          form.always_trigger_for_same_project ? 1 : 0
        payload.value.trigger_has_date = form.trigger_has_date ? 1 : 0
        payload.value.trigger_instant_edit = form.trigger_instant_edit ? 1 : 0
        payload.value.restrict_projects_by_association =
          form.restrict_projects_by_association ? 1 : 0
        payload.value.email_notifications = form.email_notifications
          ? form.email_notifications.split(',')
          : []
      }

      const checkIfEmail = (text: string) => {
        let schema = object({
          email: string().required().email(),
        })

        try {
          schema.validateSync({ email: text })
          return true
        } catch {
          return false
        }
      }

      const addEmail = (e: any) => {
        if (!checkIfEmail(e.text)) {
          store.dispatch(
            'genericStore/pushNotification',
            'The value you entered is not an email'
          )
          e.customItem = null
        } else {
          e.customItem = e.text
        }
      }

      watch(payload, () => emit('form-details-updated', payload.value), {
        deep: true,
      })

      watch(
        () => props.form,
        () => {
          if (props.form) {
            mapToPayload()
          }
        },
        { immediate: true }
      )

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        loadDependencies().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        formTypes,
        formGroups,
        payload,
        formStatuses,
        yesNoButtons,
        regions,
        keywords,
        forms,
        addEmail,
        formTemplates,
        formTemplate,
        props,
        addFormGroup,
        canEditModule,
        showFormTemplateDropdown,
      }
    },
  })
