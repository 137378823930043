import axios from 'axios'

export default {
  transferStocks: (params: any) => {
    return axios
      .post(`mars/medication/stocks/transfer`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  createMedication: (params: any) => {
    return axios
      .post(`mars/medication/stocks`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMedications: (params: any) => {
    return axios
      .post(`mars/medication/stocks/delete-many`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  editMedication: (params: any) => {
    return axios
      .put(`mars/medication/stocks/${params.id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateStockLevel: async (params: any) => {
    return axios
      .put(
        `mars/medication/stocks/${params.medication_stock_id}/adjust`,
        params
      )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getMedications: async (params: any) => {
    return axios
      .get(`mars/medication/stocks`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getMedicationStock: (id: number, params: any) => {
    return axios
      .get(`mars/medication/stocks/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getMedicationLocations: async (params: any) => {
    return axios
      .get(`mars/medication/locations`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getStockHistory: async (params: any) => {
    return axios
      .get(`mars/medication/stock/history`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateMedicationEventInstance: (id: any, payload: any) => {
    return axios
      .put(`mars/medication_event_instances/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  manageMedicationLocations: (payload: any) => {
    return axios
      .post(`mars/medication/locations/manage`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
