import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "font-semibold text-blue-200 underline cursor-pointer",
    to: {
      name: 'StockHistory',
      params: {
        id: _ctx.route.params.id,
        medicationId: _ctx.props.data.id,
      },
    }
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t('mars.medicalCabinet.grid.cells.view')), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}