import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end mb-8 space-x-6" }
const _hoisted_2 = { class: "border-r border-gray-200 px-6" }
const _hoisted_3 = { class: "h-max border border-gray-100 p-6 shadow-md space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_button_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_cc_button, {
              icon: "fas fa-arrow-left",
              "secondary-icon": "none",
              type: "tertiary",
              label: "back",
              onClick: _ctx.goBack
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_page_toolbar, {
        "read-only": true,
        onExportClicked: _ctx.exportClicked
      }, null, 8, ["onExportClicked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        "is-disabled": true,
        modelValue: _ctx.payload.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.id) = $event)),
        label: "id"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_box, {
        readonly: "true",
        modelValue: _ctx.payload.body,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.body) = $event)),
        label: "comment"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        "is-disabled": true,
        modelValue: _ctx.payload.posted_by,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.posted_by) = $event)),
        label: "poster"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        "is-disabled": true,
        modelValue: _ctx.payload.created_at,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.created_at) = $event)),
        label: "posted_on"
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}