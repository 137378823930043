
  import { useStore } from 'vuex'
  import { computed, onMounted, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import TaskLogs from '../tasks/TaskLogs.vue'
  import { isTab } from '@/composables/Generic'
  import Documents from '../documents/Index.vue'
  import TaskAttachments from '../tasks/TaskAttachments.vue'
  import ProjectAttachments from '../projects/partials/Attachments/ProjectAttachments.vue'
  import CareGroupAttachments from '../care_groups/partials/Attachments/Attachments.vue'
  import RecruitmentCheckAttachments from '../staffs/RecruitmentCheckAttachments.vue'
  import { useI18n } from 'vue-i18n'

  export default {
    components: {
      TaskLogs,
      Documents,
      TaskAttachments,
      ProjectAttachments,
      CareGroupAttachments,
      RecruitmentCheckAttachments,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const tabs = [
        { name: 'Documents', url: `${route.path}?tab=documents` },
        { name: 'Task Logs', url: `${route.path}?tab=logs` },
        {
          name: 'Task Attachments',
          url: `${route.path}?tab=task-attachments`,
        },
        {
          name: 'Care Group Attachments',
          url: `${route.path}?tab=caregroup-attachments`,
        },
        {
          name: 'Home Attachments',
          url: `${route.path}?tab=home-attachments`,
        },
        {
          name: 'School Attachments',
          url: `${route.path}?tab=school-attachments`,
        },
        {
          name: 'Young People Attachments',
          url: `${route.path}?tab=yp-attachments`,
        },
        {
          name: 'Employee Attachments',
          url: `${route.path}?tab=employee-attachments`,
        },
        {
          name: 'Qualification Attachments',
          url: `${route.path}?tab=qualification-attachments`,
        },
        {
          name: 'Recruitment Check Attachments',
          url: `${route.path}?tab=recruitment-attachments`,
        },
        {
          name: 'Vehicle Attachments',
          url: `${route.path}?tab=vehicle-attachments`,
        },
      ]

      const isRecruitmentCheck = computed(() => {
        return (
          isTab('recruitment-attachments') || isTab('qualification-attachments')
        )
      })

      const isProject = computed(() => {
        return [
          'home-attachments',
          'school-attachments',
          'vehicle-attachments',
          'yp-attachments',
          'employee-attachments',
        ].includes(route.query.tab?.toString() || '')
      })

      const activeTab = computed(() => route.query.tab)

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: t('sensitive.breadcrumb'), url: '/sensitive-data' },
        ]

        if (!isProject) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
          url: route.fullPath,
        })

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'documents' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
      })

      return {
        isTab,
        isProject,
        activeTab,
        isRecruitmentCheck,
      }
    },
  }
