import { forms } from './forms'
import { formGroups } from './formGroups'
import { formTypes } from './formTypes'
import { fields } from './fields'
import { formStatuses } from './formStatuses'
import { formBuilder } from './formBuilder'
import { keywords } from './keywords'

export default {
  formGroups,
  formTypes,
  forms,
  fields,
  formStatuses,
  formBuilder,
  keywords,
}
