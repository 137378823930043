import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex gap-2 mt-3 items-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "fas fa-shield-halved",
  title: "Sensitive"
}
const _hoisted_4 = { class: "text-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_true_false = _resolveComponent("true-false")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: "text-blue-300",
        href: _ctx.props.file.file.url,
        target: "_blank"
      }, [
        (_ctx.props.file.is_sensitive)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.props.file.file.name), 1)
      ], 8, _hoisted_2),
      _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.props.file.file.size / 1000) + " kb", 1),
      _createElementVNode("i", {
        class: "text-red-300 fa fa-xmark fa-lg cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteClicked && _ctx.deleteClicked(...args)))
      })
    ]),
    _createVNode(_component_true_false, {
      label: "Mark as Sensitive",
      modelValue: _ctx.props.file.is_sensitive,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.file.is_sensitive) = $event)),
        _ctx.sensitiveToggled
      ]
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}