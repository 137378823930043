
  import { computed, defineComponent, reactive, ref, Ref } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      createView: {
        type: String,
        required: true,
      },
      editView: {
        type: String,
        required: true,
      },
      deleteView: {
        type: String,
        required: true,
      },
      restoreView: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const key = ref(0) as Ref<number>
      const store = useStore()
      const router = useRouter()
      const itemBeingMoved = ref() as Ref<any | undefined>
      const isMoveMode = computed(() => (itemBeingMoved.value ? true : false))
      const initMoveMode = (data: any) => (itemBeingMoved.value = data)
      const cancelMoveMode = () => (itemBeingMoved.value = undefined)
      props = reactive(props)
      const state = reactive({
        extraOptions: ['View Deleted Records'],
        selectedRows: [] as any[],
      })

      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              options.data.name,
              props.editView,
              {
                id: options.data?.id,
              },
              undefined,
              Boolean(options.data.is_system)
            )
          },
        },
        {
          dataField: 'field.name',
          caption: 'Field Type',
        },
        {
          dataField: 'section_heading.name',
          caption: 'Heading',
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
        {
          caption: 'Move',
          allowGrouping: false,
          allowExporting: false,
          cellTemplate: 'LookupGridHandleTemplate',
        },
      ]

      const filters = computed(() => {
        return {
          relations: ['type', 'field', 'sectionHeading'],
          type: props.type,
        }
      })

      const rowsSelected = (rows: any[]) => {
        state.selectedRows = rows

        if (rows.length && !state.extraOptions.includes('Delete')) {
          state.extraOptions.push('Delete')
        }

        if (!rows.length && state.extraOptions.includes('Delete')) {
          state.extraOptions.splice(state.extraOptions.indexOf('Delete'), 1)
        }

        if (rows.length && !state.extraOptions.includes('Toggle Visibility')) {
          state.extraOptions.push('Toggle Visibility')
        }

        if (!rows.length && state.extraOptions.includes('Toggle Visibility')) {
          state.extraOptions.splice(
            state.extraOptions.indexOf('Toggle Visibility'),
            1
          )
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          deleteSelectedRecords()
        }

        if (option === 'Toggle Visibility') {
          toggleSelectedRecordsVisibility()
        }

        if (option === 'View Deleted Records') {
          viewDeletedRecords()
        }

        return
      }

      const viewDeletedRecords = () => {
        router.push({ name: props.restoreView })
      }

      const toggleSelectedRecordsVisibility = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let rows = state.selectedRows

        store
          .dispatch('projectTypeAttributes/toggleManyVisibility', {
            ids: rows.map((row: { id: number }) => row.id),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            state.selectedRows = []
            key.value++
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let rows = state.selectedRows

        let errors = [] as string[]

        rows = rows.filter(
          (row: { id: number; is_system: number; name: string }) => {
            if (row.is_system) {
              errors.push(row.name)
            }

            return row.is_system !== 1
          }
        )

        store.dispatch('genericStore/setLookupsToBeDeleted', rows).then(() => {
          store.dispatch('genericStore/showPageLoader', false)
          router.push({
            name: props.deleteView,
            params: {
              type: props.type,
              deleteManyAction: 'projectTypeAttributes/deleteMany',
            },
          })
          state.selectedRows = []
        })
      }

      const reorderItems = (to: any) => {
        store.dispatch('genericStore/showPageLoader', true)

        if (itemBeingMoved.value.order === to.order) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            'Moving an item to the same place has no effect'
          )
          return
        }

        store
          .dispatch('projectTypeAttributes/saveOrderings', {
            start_order: itemBeingMoved.value.order,
            end_order: to.order,
          })
          .then((response) => {
            itemBeingMoved.value = undefined
            key.value++
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const addClicked = () => router.push({ name: props.createView })

      return {
        state,
        rowsSelected,
        columns,
        filters,
        optionClicked,
        key,
        isMoveMode,
        initMoveMode,
        cancelMoveMode,
        addClicked,
        reorderItems,
      }
    },
  })
