import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_ctx.state.chips.length)
    ? (_openBlock(), _createBlock(_component_data_grid, {
        key: 0,
        action: "taskLogs/index",
        "query-filter": _ctx.query,
        chips: _ctx.state.chips,
        columns: _ctx.columns,
        onChipClicked: _ctx.chipClicked
      }, null, 8, ["query-filter", "chips", "columns", "onChipClicked"]))
    : _createCommentVNode("", true)
}