import { GetterTree, MutationTree, ActionTree } from 'vuex'
import YoungPersonTypeService from '@/services/projects/youngPersonTypes'
import { ResidentType } from '@/types/modules/projects/ResidentType'

export type YoungPersonTypeGetter = GetterTree<YoungPersonTypeState, any>

export interface YoungPersonTypeState {
  youngPersonTypes: ResidentType[]
  youngPersonType: ResidentType | null
}

export const state: YoungPersonTypeState = {
  youngPersonTypes: [],
  youngPersonType: null,
}

export const getters: YoungPersonTypeGetter = {
  getYoungPersonTypes: (state) => state.youngPersonTypes,
  getYoungPersonType: (state) => state.youngPersonType,
}

export const actions: ActionTree<YoungPersonTypeState, any> = {
  index: async (context, params) => {
    const response = await YoungPersonTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setYoungPersonTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setYoungPersonTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      YoungPersonTypeService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setYoungPersonType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(YoungPersonTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(YoungPersonTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(YoungPersonTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(YoungPersonTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(YoungPersonTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(YoungPersonTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(YoungPersonTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<YoungPersonTypeState> = {
  setYoungPersonTypes: (state, types: ResidentType[]) =>
    (state.youngPersonTypes = types),
  setYoungPersonType: (state, type: ResidentType) =>
    (state.youngPersonType = type),
}

export const youngPersonTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
