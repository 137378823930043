import isEqual from 'date-fns/isEqual'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import getDay from 'date-fns/getDay'
import { format, subMonths, subWeeks, subYears } from 'date-fns'
import { i18n } from '@/locales/index'

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  inclusivity = '()'
) => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)')
  }

  const isBeforeEqual = inclusivity[0] === '['
  const isAfterEqual = inclusivity[1] === ']'

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  )
}

export const isSameOrBefore = (date: Date, dateToCompare: Date) => {
  return isEqual(date, dateToCompare) || isBefore(date, dateToCompare)
}

export const isSameDayOrBefore = (date: Date, dateToCompare: Date) => {
  return getDay(dateToCompare) <= getDay(date)
}

export const isSameDayOrAfter = (date: Date, dateToCompare: Date) => {
  return getDay(dateToCompare) >= getDay(date)
}

export const isSameOrAfter = (date: Date, dateToCompare: Date) => {
  return isEqual(date, dateToCompare) || isAfter(date, dateToCompare)
}

export const dateRanges = [
  { label: i18n.global.t('components.dateRanges.today'), value: 'today' },
  {
    label: i18n.global.t('components.dateRanges.pastWeek'),
    value: 'past-week',
  },
  {
    label: i18n.global.t('components.dateRanges.pastMonth'),
    value: 'past-month',
  },
  {
    label: i18n.global.t('components.dateRanges.pastSixMonths'),
    value: 'past-six-months',
  },
  {
    label: i18n.global.t('components.dateRanges.pastYear'),
    value: 'past-year',
  },
  { label: i18n.global.t('components.dateRanges.all'), value: 'all' },
  {
    label: i18n.global.t('components.dateRanges.customDateRange'),
    value: 'custom-date-range',
  },
]

export const dateRangeSelected = (range: string) => {
  const today = new Date()

  if (range === 'today') {
    return {
      start_date: format(today, 'yyyy-MM-dd'),
      end_date: format(today, 'yyyy-MM-dd'),
    }
  }

  if (range === 'past-week') {
    return {
      start_date: format(subWeeks(today, 1), 'yyyy-MM-dd'),
      end_date: format(today, 'yyyy-MM-dd'),
    }
  }

  if (range === 'past-month') {
    return {
      start_date: format(subMonths(today, 1), 'yyyy-MM-dd'),
      end_date: format(today, 'yyyy-MM-dd'),
    }
  }

  if (range === 'past-six-months') {
    return {
      start_date: format(subMonths(today, 6), 'yyyy-MM-dd'),
      end_date: format(today, 'yyyy-MM-dd'),
    }
  }

  if (range === 'past-year') {
    return {
      start_date: format(subYears(today, 1), 'yyyy-MM-dd'),
      end_date: format(today, 'yyyy-MM-dd'),
    }
  }

  return {
    start_date: format(new Date(0), 'yyyy-MM-dd'),
    end_date: format(today, 'yyyy-MM-dd'),
  }
}
