import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10d6adf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold text-lg mb-2"
}
const _hoisted_4 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_5 = { class: "border-b-2 border-gray-500 text-gray-500 pb-1" }
const _hoisted_6 = {
  key: 2,
  class: "mb-3"
}
const _hoisted_7 = { class: "border-b-2 border-gray-500 text-gray-500 pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTreeView = _resolveComponent("DxTreeView")!

  return (_openBlock(), _createBlock(_component_DxTreeView, {
    "data-source": _ctx.props.items,
    "display-expr": "label",
    "items-expr": "children",
    "key-expr": "uuid",
    onItemClick: _ctx.itemClicked
  }, {
    item: _withCtx(({ data }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (data.level === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(data.label), 1)
              ]))
            : (data?.children && data.level > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "category", {}, () => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(data.label), 1)
                  ], true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "category-item", { value: data }, () => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(data.label), 1)
                  ], true)
                ]))
        ])
      ])
    ]),
    _: 3
  }, 8, ["data-source", "onItemClick"]))
}