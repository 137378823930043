
  import { format } from 'date-fns/esm'
  import addMinutes from 'date-fns/addMinutes'
  import { computed, onMounted, reactive, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      duration: {
        type: Number,
        default: 60,
      },
      hideTimes: {
        type: Boolean,
        default: false,
      },
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      props = reactive(props)

      const startTime = computed(() => {
        return format(new Date(props.cellData.date), 'ha')
      })

      const endTime = computed(() => {
        return format(
          addMinutes(new Date(props.cellData.date), props.duration),
          'ha'
        )
      })

      const combined = computed(() => `${startTime.value} - ${endTime.value}`)

      onMounted(() => {
        if (props.hideTimes) {
          let element = document.querySelector(
            '.dx-scheduler-header-scrollable'
          )

          if (element) {
            element.classList.add('rota-timeline-date-header')
          }
        }
      })

      return {
        props,
        combined,
      }
    },
  })
