import DatasourceCategories from '@/services/reporting/dataSourceCategories'
import { Datasource } from '@/types/modules/reporting/Datasource'
import { DatasourceCategory } from '@/types/modules/reporting/DatasourceCategory'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface DatasourceCategoryState {
  categories: DatasourceCategory[]
  datasources: Datasource[]
}

export const state: DatasourceCategoryState = {
  categories: [],
  datasources: [],
}

export const getters: GetterTree<DatasourceCategoryState, any> = {
  categories: (state) => state.categories,
  datasources: (state) => state.datasources,
}

export const actions: ActionTree<DatasourceCategoryState, any> = {
  index: (context, params) => {
    const response = DatasourceCategories.index(params)

    response.then((response) => {
      if (response.success) {
        context.commit('setDatasourceCategories', response.data)

        let datasources: Datasource[] = []

        const flattenDatasources = (categories: DatasourceCategory[]) => {
          categories.forEach((category) => {
            if (!category.datasources || !category.subcategories) {
              return
            }

            datasources.push(...category.datasources)
            flattenDatasources(category.subcategories)
          })
        }

        flattenDatasources(response.data)
        context.commit('setDatasources', datasources)
      }
    })

    return response
  },
}

export const mutations: MutationTree<DatasourceCategoryState> = {
  setDatasourceCategories: (
    state,
    datasourceCategories: DatasourceCategory[]
  ) => {
    state.categories = datasourceCategories
  },
  setDatasources: (state, datasources: Datasource[]) => {
    state.datasources = datasources
  },
}

export const datasourceCategories = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
