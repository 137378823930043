
  import { defineComponent, reactive } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    components: {
      DataGrid,
    },
    setup() {
      const state = reactive({
        header: 'Care Group Audit Logs',
        action: 'careGroups/getCareGroupAuditLogs',
      })
      const columns: Column[] = reactive([
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy HH:mm:ss',
          dataField: 'updated_at',
          caption: 'Date',
        },
        {
          caption: 'User',
          calculateCellValue: (row: any) => {
            return `${row.created_by.first_name} ${row.created_by.last_name}`
          },
          dataType: 'string',
        },
        {
          dataType: 'string',
          dataField: 'action',
          caption: 'Action',
        },
        {
          dataType: 'string',
          dataField: 'field',
          caption: 'Field',
        },
        {
          dataType: 'string',
          dataField: 'old_value',
          caption: 'Previous Value',
        },
        {
          dataType: 'string',
          dataField: 'new_value',
          caption: 'Current Value',
        },
      ])

      const chipClicked = (chip: string) => {
        if (chip === 'Care Group') {
          state.header = 'Care Group Audit Logs'
          state.action = 'careGroups/getCareGroupAuditLogs'
        } else if (chip === 'Stakeholders') {
          state.header = 'Stakeholder Audit Logs'
          state.action = 'careGroups/getStakeholderAuditLogs'
        }
      }

      return {
        state,
        columns,
        chipClicked,
      }
    },
  })
