import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Document Types",
    "create-view": "DocumentTypeCreate",
    "edit-view": "DocumentTypeEdit",
    "delete-view": "DocumentTypeDelete",
    "restore-view": "DocumentTypeRestore",
    action: "documentTypes/index",
    "toggle-visibility-action": "documentTypes/toggleManyVisibility",
    "delete-many-action": "documentTypes/deleteMany",
    "reorder-action": "documentTypes/saveOrderings"
  }))
}