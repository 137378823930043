import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_box = _resolveComponent("select-box")!

  return (_ctx.options)
    ? (_openBlock(), _createBlock(_component_select_box, {
        key: 0,
        options: _ctx.options,
        label: `The field ${_ctx.parameter.friendly_name} ${_ctx.parameter.friendly_operator}`,
        modelValue: _ctx.payload.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.value) = $event))
      }, null, 8, ["options", "label", "modelValue"]))
    : _createCommentVNode("", true)
}