
  import RegionForm from '../regions/partials/RegionForm.vue'
  import { defineComponent, onMounted } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: {
      RegionForm,
    },
    setup() {
      const store = useStore()

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Regions', url: '/regions' },
            { name: 'Create Region', url: '/regions/create' },
          ],
        })
      })
    },
  })
