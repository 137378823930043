import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end font-display" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "border-r border-gray-200 px-4" }
const _hoisted_4 = { class: "font-display w-full p-8 shadow-lg rounded-md border border-gray-100 mt-8" }
const _hoisted_5 = { class: "font-bold text-2xl tracking-slight" }
const _hoisted_6 = { class: "flex items-center justify-between space-x-8 mt-4" }
const _hoisted_7 = { class: "mt-12" }
const _hoisted_8 = { class: "font-display font-semibold text-2xl tracking-slight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_grouped_select_input = _resolveComponent("grouped-select-input")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cc_button, {
            icon: "fas fa-arrow-left",
            type: "tertiary",
            "secondary-icon": "none",
            label: _ctx.t('mars.stockTransfer.toolbar.back'),
            onClick: _ctx.goBack
          }, null, 8, ["label", "onClick"])
        ]),
        _createVNode(_component_cc_button, {
          icon: "fa-solid fa-arrow-right-arrow-left",
          type: "primary",
          label: _ctx.t('mars.stockTransfer.toolbar.transfer'),
          "is-disabled": !_ctx.payload.new_location_id,
          onClick: _ctx.transferStock
        }, null, 8, ["label", "is-disabled", "onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t('mars.stockTransfer.location.header')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_grouped_select_input, {
          options: _ctx.formattedLocations,
          isRequired: true,
          "is-disabled": true,
          label: _ctx.t('mars.stockTransfer.location.currentLocation'),
          modelValue: _ctx.payload.old_location_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.old_location_id) = $event))
        }, null, 8, ["options", "label", "modelValue"]),
        _createVNode(_component_grouped_select_input, {
          options: _ctx.formattedLocations,
          isRequired: true,
          modelValue: _ctx.payload.new_location_id,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.new_location_id) = $event)),
          label: _ctx.t('mars.stockTransfer.location.newLocation')
        }, null, 8, ["options", "modelValue", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.t('mars.stockTransfer.selectedMedications.header')), 1),
      _createVNode(_component_data_grid, {
        "remote-operations": {},
        "show-grid-toolbar": false,
        "allow-selection": false,
        "allow-grouping": false,
        columns: _ctx.columns,
        action: "mars/getStocksToTransfer"
      }, null, 8, ["columns"])
    ])
  ], 64))
}