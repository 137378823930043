
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { defineComponent, computed, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { ContractualEvent } from '@/types/modules/staffs/ContractualEvent'
  import { EmployeeContract } from '@/types/modules/staffs/EmployeeContract'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'event.name',
          caption: 'Event Type',
          allowGrouping: false,
        },
        {
          dataField: 'date',
          caption: 'Event Date',
          allowGrouping: true,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'approved_by.username',
          caption: 'Approved By',
          allowGrouping: false,
        },
        {
          dataField: 'files',
          caption: 'Evidence Files',
          allowGrouping: true,
          calculateCellValue: (row: EmployeeContract) =>
            `${row.files?.length} file(s)`,
        },
        {
          dataField: 'created_at',
          caption: 'Original Recorded On',
          allowGrouping: true,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
        },
      ]

      const chips = computed(() => {
        let names = store.getters['staffs/contractualEvents'].map(
          (type: ContractualEvent) => type.name
        )
        return ['All Events'].concat(names)
      })
      const setChip = (chip: string) => {
        state.activeChip = chip
      }
      const state = reactive({
        activeChip: 'All Events',
        chips: chips,
      })
      const filters = computed(() => {
        return {
          employee_id: props.id,
          relations: ['event', 'approved_by'],
          ...(state.activeChip &&
            state.activeChip !== 'All Events' && {
              contractual_event_id: store.getters[
                'staffs/contractualEvents'
              ].find((type: ContractualEvent) => type.name === state.activeChip)
                .id,
            }),
        }
      })
      onMounted(() => {
        store.dispatch('staffs/getContractualEvents', {})
      })
      return {
        columns,
        filters,
        state,
        chips,
        setChip,
      }
    },
  })
