
  import { Project } from '@/types/modules/projects/Project'
  import { defineComponent, PropType, reactive } from 'vue'
  import {
    sharedState,
    totalSteps,
    filteredStartWeeks,
    canEditTimeline,
    deleteShift,
    addShift,
    updateShift,
  } from '@/composables/rotas/Rotas'
  import RotaTimeline from '../../partials/RotaTimeline.vue'
  import { DxResource, DxView } from 'devextreme-vue/scheduler'
  import MultistepWizard from '../../partials/MultistepWizard.vue'
  import { rotaSetting } from '@/composables/rotas/RotaSetting'
  import { defaultShifts } from '@/composables/rotas/DefaultShifts'
  import { buildForm } from '@/composables/rotas/BuildModalForm'

  export default defineComponent({
    components: {
      MultistepWizard,
      RotaTimeline,
      DxResource,
      DxView,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      const saveSchedulerInstance = (instance: any) => {
        sharedState.schedulers.push(instance.ref)
      }

      const adding = (data: any) => addShift(data, props.project)
      const updating = (data: any) => updateShift(data, props.project)
      const deleting = (data: any) => deleteShift(data)
      const onWeekCycleStepChange = (step: any) => (sharedState.step = step)

      return {
        buildForm,
        defaultShifts,
        onWeekCycleStepChange,
        totalSteps,
        adding,
        updating,
        saveSchedulerInstance,
        rotaSetting,
        sharedState,
        props,
        filteredStartWeeks,
        canEditTimeline,
        deleting,
      }
    },
  })
