import CareGroupIndex from '../views/modules/care_groups/Index.vue'
import CareGroupShow from '../views/modules/care_groups/Show.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canView, canEdit } from '@/middleware/permissions/module'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import ManageStakeholderPosition from '@/views/lookups/ManageStakeholderPositions.vue'
import RestoreStakeholderPositions from '@/views/modules/care_groups/partials/Stakeholders/RestoreStakeholderPositions.vue'

export default [
  {
    path: '/care_groups/:id',
    name: 'CareGroupShow',
    props: true,
    component: CareGroupShow,
    meta: {
      title: 'Care Groups',
      module: 'companies',
      middleware: [auth, canView],
    },
  },
  {
    path: '/care_groups',
    name: 'CareGroupIndex',
    component: CareGroupIndex,
    meta: {
      title: 'Care Groups',
      module: 'companies',
      middleware: [auth, canView],
    },
  },
  {
    path: '/care-groups/roles/create',
    name: 'CareGroupRoleCreate',
    component: ManageStakeholderPosition,
    props: () => ({
      showAction: 'careGroupRoles/show',
      storeAction: 'careGroupRoles/store',
    }),
    meta: {
      title: 'Create Stakeholder Position',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/care-groups/roles/:id/edit',
    name: 'CareGroupRoleEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'careGroupRoles/show',
      updateAction: 'careGroupRoles/update',
      deleteAction: 'careGroupRoles/delete',
    }),
    component: ManageStakeholderPosition,
    meta: {
      title: 'Update Stakeholder Position',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/care-groups/roles/delete',
    name: 'CareGroupRoleDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Stakeholder Position(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/care-groups/roles/restore',
    name: 'CareGroupRoleRestore',
    component: RestoreStakeholderPositions,
    meta: {
      title: 'Restore Deleted Stakeholder Positions',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
