
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { StaffProcessFile } from '@/types/modules/staffs/StaffProcessFile'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { QualificationType } from '@/types/modules/projects/QualificationType'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const query = ref({})
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        activeChip: 'all',
      })

      const heading = computed(() => {
        return route.query.tab === 'qualification-attachments'
          ? 'Qualification Attachments'
          : 'Recruitment Check Attachments'
      })

      const action = computed(() => {
        return props.sensitive
          ? 'staffs/getSensitiveRecruitmentCheckFiles'
          : 'staffs/getRecruitmentCheckFiles'
      })

      const chips = ref([]) as Ref<string[]>

      const configureChipFilters = () => {
        chips.value = ['all']
        console.log(route.query.tab)
        if (route.query.tab === 'recruitment-attachments') {
          chips.value = chips.value.concat([
            'Dbs',
            'Employee Contract',
            'Employee Reference',
            'Overseas Criminal Record',
            'Proof Of Identity',
            'Right To Work',
          ])
        }

        if (route.query.tab === 'qualification-attachments') {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('qualificationTypes/index', {})
            .then((response) => {
              response.data.forEach((qualificationType: QualificationType) => {
                chips.value.push(qualificationType.name)
              })
            })
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      const filters = computed(() => {
        return {
          ...(state.activeChip &&
            state.activeChip !== 'all' &&
            route.query.tab !== 'qualification-attachments' && {
              category: state.activeChip.split(' ').join(''),
            }),
          ...(state.activeChip &&
            state.activeChip !== 'all' &&
            route.query.tab === 'qualification-attachments' && {
              qualification_type: state.activeChip,
            }),
          ...(route.query.tab === 'qualification-attachments' && {
            category: 'EmployeeQualification',
          }),
          ...query.value,
        }
      })

      const applyFilters = () => {
        query.value = {}
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'Relates to',
          dataField: 'process.employee.project.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'File Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'process_check_type',
          caption: 'Check Type',
        },
        {
          dataType: 'string',
          dataField: 'mime_type',
          caption: 'File Type',
          calculateCellValue: (row: StaffProcessFile) => {
            return row.readable_type
          },
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      watch(
        () => route.query.tab,
        () => {
          if (route.query.tab) {
            configureChipFilters()
          }
        }
      )

      onMounted(() => {
        configureChipFilters()
      })

      return {
        chips,
        state,
        action,
        heading,
        filters,
        columns,
        chipClicked,
      }
    },
  })
