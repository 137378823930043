import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../assets/logo.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.image
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}