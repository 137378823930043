import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filing_cabinet = _resolveComponent("filing-cabinet")!
  const _component_young_person_details = _resolveComponent("young-person-details")!
  const _component_young_person_relationships = _resolveComponent("young-person-relationships")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_task_attachments = _resolveComponent("task-attachments")!
  const _component_project_associations = _resolveComponent("project-associations")!
  const _component_user_permissions = _resolveComponent("user-permissions")!
  const _component_young_person_photos = _resolveComponent("young-person-photos")!
  const _component_medication_diary = _resolveComponent("medication-diary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('filing-cabinet'))
      ? (_openBlock(), _createBlock(_component_filing_cabinet, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('details'))
      ? (_openBlock(), _createBlock(_component_young_person_details, {
          key: 1,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('relationships'))
      ? (_openBlock(), _createBlock(_component_young_person_relationships, {
          key: 2,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('yp-attachments'))
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 3,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('log-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 4,
          "project-id": _ctx.id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('associations') && _ctx.youngPerson?.project)
      ? (_openBlock(), _createBlock(_component_project_associations, {
          key: 5,
          "instance-id": _ctx.youngPerson.id,
          project: _ctx.youngPerson.project
        }, null, 8, ["instance-id", "project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('user-permissions') && _ctx.youngPerson)
      ? (_openBlock(), _createBlock(_component_user_permissions, {
          key: 6,
          "project-id": _ctx.youngPerson.project_id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('photos'))
      ? (_openBlock(), _createBlock(_component_young_person_photos, { key: 7 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('medication-diary') && _ctx.youngPerson)
      ? (_openBlock(), _createBlock(_component_medication_diary, {
          key: 8,
          youngPerson: _ctx.youngPerson
        }, null, 8, ["youngPerson"]))
      : _createCommentVNode("", true)
  ]))
}