
  import { defineComponent, reactive, computed } from 'vue'
  import CcButton from '../Button/Button.vue'
  export default defineComponent({
    components: {
      CcButton,
    },
    props: {
      outline: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: 'success',
        validator: (value: string) =>
          [
            'primary',
            'success',
            'warning',
            'danger',
            'default',
            'secondary',
          ].indexOf(value) !== -1,
      },
      value: {
        type: [String, Number],
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const borderColor = computed(() => {
        return {
          'border-2 border-blue-400': props.outline && props.type == 'primary',
          'border-2 border-green-400': props.outline && props.type == 'success',
          'border-2 border-red-500': props.outline && props.type == 'danger',
          'border-2 border-orange-400':
            props.outline && props.type == 'warning',
          'border-2 border-gray-400': props.outline && props.type == 'default',
          'border-2 border-purple-300':
            props.outline && props.type == 'secondary',
        }
      })
      const color = computed(() => {
        return {
          'text-blue-400': props.type == 'primary',
          'text-green-400': props.type == 'success',
          'text-red-500': props.type == 'danger',
          'text-orange-400': props.type == 'warning',
          'text-purple-300': props.type == 'secondary',
          'text-gray-400': props.type == 'default',
        }
      })
      const overviewCardClasses = computed(() => ({
        'flex': true,
        'flex-row': true,
        'w-full': true,
        'min-w-full': true,
        'md:flex-col': true,
        'lg:flex-row': true,
        'rounded-md': true,
        'font-display': true,
        'py-2': true,
        'px-2': true,
        'space-x-2': true,
        'cursor-pointer': true,
      }))
      const linkClasses =
        'text-sm border-2 py-2 px-4 transition-colors max-w-max w-max lowercase border-none bg-none text-blue-300 underline underline-offset-8 decoration-2 focus-visible:bg-yellow-300 focus-visible:border-black focus-visible:text-black'

      return {
        color,
        borderColor,
        linkClasses,
        overviewCardClasses,
      }
    },
  })
