import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "taskLogComments/getSelectedTaskLogComments",
    columns: $setup.columns,
    "allow-selection": false,
    "allow-column-chooser": false,
    "allow-filtering": false,
    "allow-grouping": false,
    "remote-operations": {},
    "export-options": []
  }, {
    "toolbar-addon": _withCtx(() => [
      _createVNode(_component_cc_button, {
        label: $setup.t('components.buttons.back'),
        type: "tertiary",
        icon: "fa fa-arrow-left",
        "secondary-icon": "none",
        onClick: $setup.goBack
      }, null, 8, ["label", "onClick"])
    ]),
    "toolbar-primary-button": _withCtx(() => [
      _createVNode(_component_cc_button, {
        type: "danger-outline",
        label: $setup.t('components.buttons.delete'),
        icon: "fa fa-trash",
        onClick: $setup.deleteRecords
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["columns"]))
}