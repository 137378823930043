import BespokeReportDraftService from '@/services/reporting/bespokeReportDrafts'
import { ActionTree } from 'vuex'

export interface BespokeReportDraftState {}

export const actions: ActionTree<BespokeReportDraftState, any> = {
  store: async (_context, payload) => {
    return await BespokeReportDraftService.store(payload.id, payload)
  },
  restore: async (_context, id: number) => {
    return await BespokeReportDraftService.restore(id)
  },
  delete: async (_context, id: number) => {
    return await BespokeReportDraftService.delete(id)
  },
}

export const bespokeReportDrafts = {
  namespaced: true,
  actions,
}
