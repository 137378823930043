import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-disc list-inside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasErrors)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.classList)
      }, [
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.props.errors), (message, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `validation-${index}`,
              class: "font-semibold mb-2"
            }, _toDisplayString(message), 1))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}