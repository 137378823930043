
  import { computed, defineComponent, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'
  import { Project } from '@/types/modules/projects/Project'

  export default defineComponent({
    components: {
      CcButton,
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      associationId: {
        type: String,
        required: true,
      },
      projectType: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const { t } = useI18n()

      const columns = computed((): Column[] => [
        {
          width: 100,
          dataField: 'association.id',
          caption: t('projectAssociations.grid.headers.id'),
          dataType: 'number',
          allowGrouping: false,
        },
        {
          dataField: 'name',
          caption: t('projectAssociations.grid.headers.name'),
          cellTemplate: 'AssociationNameCellTemplate',
          allowGrouping: false,
        },
        {
          dataField: 'association.status',
          caption: t('projectAssociations.grid.headers.status'),
        },
        {
          dataField: 'association.project_type_label',
          caption: 'Type',
        },
        {
          dataField: 'admission_date',
          caption: t('projectAssociations.grid.headers.admissionDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'leaving_date',
          caption: t('projectAssociations.grid.headers.leavingDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
      ])

      const projectInstance = computed(() => {
        if (props.projectType === 'young_people') {
          return store.getters['youngPeople/getYoungPerson']
        }
        return store.getters[
          `${props.projectType}/${props.projectType.slice(0, -1)}`
        ]
      })

      const selectedAssociations = computed(
        () => store.getters['projectAssociations/selectedAssociations']
      )

      const deleteAssociations = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projectAssociations/deleteMany', {
            project_id: projectInstance.value.project_id,
            ids: selectedAssociations.value.map(
              (association: Project) => association.id
            ),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      onMounted(() => {
        if (!selectedAssociations.value?.length) {
          goBack()
        }
        store.getters['genericStore/breadCrumbs'].push(
          {
            name: 'Associations',
            url: `/projects/${props.projectType}/${props.id}?tab=associations`,
          },
          {
            name: 'Delete Associations',
            url: '#',
          }
        )
      })

      return {
        goBack,
        t,
        columns,
        deleteAssociations,
        props,
        selectedAssociations,
      }
    },
  })
