
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { renderCellLink } from '@/composables/Grids'
  import { PublishedBespokeReportComment } from '@/types/modules/reporting/PublishedBespokeReportComment'
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, reactive, ref, Ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: { DataGrid },
    name: 'ManageComments',
    props: {
      id: {
        type: String,
        required: true,
      },
      publishedReportId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const selectedRows = reactive<PublishedBespokeReportComment[]>([])
      const extraOptions = ref([]) as Ref<string[]>

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Comment',
          dataField: 'body',
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              options.data.body,
              'BespokeReportPublishedCommentShow',
              {
                id: options.data.id,
                publishedReportId: props.publishedReportId,
                commentId: options.data.id,
              }
            )
          },
        },
        {
          caption: 'Posted By',
          dataField: 'posted_by.username',
          width: 200,
        },
        {
          caption: 'Posted At',
          dataField: 'created_at',
          dataType: 'datetime',
          width: 200,
        },
      ])

      const addClicked = () => {
        router.push({
          name: 'BespokeReportPublishedCommentCreate',
          params: {
            id: props.id,
            publishedReportId: props.publishedReportId,
          },
        })
      }

      const filters = computed(() => {
        return {
          id: props.publishedReportId,
          relations: ['poster'],
        }
      })

      const rowsSelected = (rows: PublishedBespokeReportComment[]) => {
        selectedRows.splice(0, selectedRows.length, ...rows)

        if (selectedRows.length) {
          extraOptions.value = ['Delete']
        } else {
          extraOptions.value = []
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          deleteSelected()
        }
      }

      const deleteSelected = () => {
        store.dispatch(
          'publishedBespokeReportComments/setSelectedComments',
          selectedRows
        )
        router.push({
          name: 'BespokeReportPublishedCommentDelete',
          params: {
            id: props.id,
            publishedReportId: props.publishedReportId,
          },
        })
      }

      return {
        columns,
        filters,
        addClicked,
        rowsSelected,
        extraOptions,
        optionClicked,
      }
    },
  })
