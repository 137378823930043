
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    watch,
    onUnmounted,
  } from 'vue'
  import { useStore } from 'vuex'
  import WorkflowDiagram from '@/components/Generic/Diagram/WorkflowDiagram.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ExtraOptionsButton from '@/components/Generic/Button/ExtraOptionsButton.vue'
  import CcToggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import DropDown from '@/components/Generic/Dropdown/DropDown.vue'
  import TaskLogDetails from './partials/TaskLogDetails.vue'
  import TaskLogToolbar from './partials/TaskLogToolbar.vue'
  import { useRouter, useRoute } from 'vue-router'
  import TaskAttachments from '../projects/partials/Attachments/TaskAttachments.vue'
  import { TaskFile } from '@/types/modules/tasks/TaskFile'
  import TaskLogCommentTable from './partials/TaskLogCommentTable.vue'
  import { TaskLogComment } from '@/types/modules/tasks/TaskLogComment'
  import { canEditModule } from '@/composables/Permissions'
  import { TaskLog } from '@/types/modules/tasks/TaskLog'
  import { isTab } from '@/composables/Generic'
  import TaskPermissions from './partials/TaskPermissions.vue'
  import TaskAudit from './partials/TaskAudit.vue'
  import { format } from 'date-fns'
  import { Task } from '@/types/modules/tasks/Task'
  import CcPill from '@/components/Generic/Pill/Pill.vue'
  import { User } from '@/types/modules/users/User'

  export default defineComponent({
    components: {
      WorkflowDiagram,
      ButtonGroup,
      CcButton,
      ExtraOptionsButton,
      CcToggle,
      DropDown,
      TaskLogDetails,
      TaskLogToolbar,
      TaskAttachments,
      TaskLogCommentTable,
      TaskPermissions,
      TaskAudit,
      CcPill,
    },
    props: {
      taskId: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const relatedLogs = computed(() => {
        if (store.getters['taskLogs/taskLogs'].length && activeLog.value) {
          return store.getters['taskLogs/taskLogs'].filter((log: TaskLog) => {
            return log.id !== activeLog.value.id
          })
        }

        return []
      })

      const activeLog = computed(
        (): TaskLog => store.getters['taskLogs/logDetails']
      )

      const tabs = [
        { name: 'Details', url: `${route.path}?tab=details` },
        { name: 'Permissions', url: `${route.path}?tab=permissions` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const task = computed((): Task => store.getters['tasks/task'])

      const selectedComments = computed(
        (): TaskLogComment[] =>
          store.getters['taskLogComments/getSelectedTaskLogComments']
      )

      const selectedAttachments = computed(
        (): TaskFile[] => store.getters['tasks/selectedAttachments']
      )

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/dashboard' },
          { name: 'Tasks', url: '/overview/tasks' },
        ]

        if (!activeLog.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push(
          {
            name: activeLog.value.name,
            url: `/tasks/${props.taskId}/logs`,
          },
          {
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          }
        )

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      const toggleTaskSensitivity = () => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('tasks/toggleTaskSensitivity', {
            id: props.taskId,
            sensitivity: activeLog.value.task!.is_sensitive
              ? 'visible'
              : 'hidden',
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            getLogDetails(activeLog.value.id)
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const handleOptionClicked = (option: string) => {
        if (
          option === 'Mark as Sensitive' ||
          option === 'Declassify as Sensitive'
        ) {
          toggleTaskSensitivity()
        }

        if (option === 'Move Attachments') {
          router.push({ name: 'MoveTaskAttachments' })
        }

        if (option === 'Move Task') {
          router.push({ name: 'MoveTask', params: { taskId: props.taskId } })
        }

        if (option === 'Link Task') {
          router.push({ name: 'LinkTask', params: { taskId: props.taskId } })
        }

        if (option === 'Unlink Task') {
          router.push({ name: 'UnlinkTask', params: { taskId: props.taskId } })
        }

        if (option === 'View Deleted Comments') {
          router.push({
            name: 'TaskLogCommentRestore',
            params: { taskId: props.taskId, taskLogId: activeLog.value.id },
          })
        }

        if (option === 'Delete Comments') {
          router.push({
            name: 'TaskLogCommentDelete',
            params: { taskId: props.taskId, taskLogId: activeLog.value.id },
          })
        }
      }

      const state = reactive({
        showFormInformation: false,
        activeTab: 'form',
        tabs: ['form', 'comments', 'attachments'],
        toolbarOptions: [] as string[],
      })

      const getToolBarOptions = (tab: string) => {
        if (tab === 'comments') {
          return canEditModule('tasks') ? ['View Deleted Comments'] : []
        }

        if (tab === 'form') {
          let formOptions = ['Move Task']

          if (activeLog.value && activeLog.value.task) {
            formOptions.push(
              activeLog.value.task.is_sensitive
                ? 'Declassify as Sensitive'
                : 'Mark as Sensitive'
            )
          }

          if (activeLog.value && activeLog.value.task) {
            formOptions.push(
              activeLog.value.task.parent_id ? 'Unlink Task' : 'Link Task'
            )
          }

          return formOptions
        }

        return []
      }

      watch(
        () => state.activeTab,
        () => {
          store.commit('tasks/setSelectedAttachments', [])
          state.toolbarOptions = getToolBarOptions(state.activeTab)
        },
        { immediate: true }
      )

      watch(
        () => selectedComments.value,
        () => {
          if (canEditModule('tasks')) {
            if (
              state.toolbarOptions.includes('Delete Comments') &&
              !selectedComments.value.length
            ) {
              let index = state.toolbarOptions.findIndex(
                (option: string) => option === 'Delete Comments'
              )

              if (index) {
                state.toolbarOptions.splice(index, 1)
              }
            }

            if (
              selectedComments.value.length &&
              !state.toolbarOptions.includes('Delete Comments')
            ) {
              state.toolbarOptions.splice(1, 0, 'Delete Comments')
            }
          }
        }
      )

      watch(
        () => selectedAttachments.value,
        (value) => {
          const options = state.toolbarOptions

          if (value.length && !options.includes('Move Attachments')) {
            return state.toolbarOptions.splice(1, 0, 'Move Attachments')
          }
          if (value.length && options.includes('Move Attachments')) {
            return state.toolbarOptions
          }
          if (!value.length && options.includes('Move Attachments')) {
            return state.toolbarOptions.splice(1, 1)
          }

          return state.toolbarOptions
        }
      )

      const showFormInformation = () =>
        (state.showFormInformation = !state.showFormInformation)

      const editLog = () =>
        router.push({ name: 'EditLog', params: { taskId: props.taskId } })
      const copyLog = () =>
        router.push({ name: 'CopyTask', params: { taskId: props.taskId } })

      const addComment = () => {
        router.push({
          name: 'TaskLogCommentCreate',
          params: { taskId: props.taskId, taskLogId: activeLog.value.id },
        })
      }

      const changeActiveLog = (log: TaskLog) => {
        store.dispatch('genericStore/showPageLoader', true)
        getLogDetails(log.id).finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      }

      const getLogDetails = (logId: number) => {
        return store.dispatch('taskLogs/getLogDetails', logId).then(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
          state.toolbarOptions = getToolBarOptions(state.activeTab)
        })
      }

      const showAwaitingApproval = computed(() => {
        if (activeLog.value && activeLog.value.status) {
          if (
            activeLog.value.status.name === 'Sent For Approval' &&
            state.activeTab === 'form'
          ) {
            return true
          }
        }

        return false
      })

      const logStatusType = computed(() => {
        const types = {
          'Submitted': 'success',
          'Draft': 'default',
          'Sent For Approval': 'warning',
          'Rejected': 'danger',
        } as Record<string, string>

        if (!activeLog.value || !activeLog.value.status) {
          return 'default'
        }

        if (Object.keys(types).includes(activeLog.value.status.name)) {
          return types[activeLog.value.status.name]
        }

        return 'default'
      })

      const canApprove = computed(() => {
        if (showAwaitingApproval.value) {
          const loggedInUsersId = store.getters['auth/authenticatedUser'].id
          const approvers = activeLog.value.task?.form?.approvers?.map(
            (user: User) => user.id
          )

          if (loggedInUsersId && approvers && approvers.length) {
            if (approvers.includes(loggedInUsersId)) {
              return true
            }
          }
        }

        return false
      })

      const updateStatus = (status: string) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('taskLogs/updateLogStatus', {
            status: status,
            task_log_id: activeLog.value.id,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            getLogDetails(activeLog.value.id)
          })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.commit('taskLogs/setLogDetails', null)
        store.commit('tasks/setTask', null)
        store.commit('genericStore/setTabs', { tabs: tabs })
        store.commit('genericStore/setPageTaskId', props.taskId)
        store.dispatch('genericStore/showPageLoader', true)

        if (!route.query.tab) {
          router.replace({ query: { tab: 'details' } })
        }

        store
          .dispatch('tasks/show', {
            id: props.taskId,
            params: {},
          })
          .then((response) => {
            if (!response.data) {
              router.replace({ name: 'Dashboard' })
            }

            store
              .dispatch('taskLogs/index', {
                task_id: props.taskId,
                sort: [['created_at', 'desc']],
              })
              .then((response) => {
                if (!response.data.length) {
                  store.dispatch('genericStore/showPageLoader', false)
                  router.push({
                    name: 'CreateLog',
                    params: { taskId: props.taskId },
                  })
                  return
                }

                getLogDetails(response.data[0].id).finally(() =>
                  store.dispatch('genericStore/showPageLoader', false)
                )
              })
          })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
            router.replace({ name: 'Dashboard' })
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      onUnmounted(() => store.commit('genericStore/setPageTaskId', null))

      return {
        state,
        props,
        activeLog,
        selectedAttachments,
        showFormInformation,
        editLog,
        copyLog,
        addComment,
        handleOptionClicked,
        relatedLogs,
        changeActiveLog,
        isTab,
        format,
        task,
        showAwaitingApproval,
        logStatusType,
        canApprove,
        updateStatus,
      }
    },
  })
