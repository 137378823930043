import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_extra_options_button = _resolveComponent("extra-options-button")!
  const _component_button_group = _resolveComponent("button-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_button_group, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "toolbar-primary-button", {}, () => [
          _createVNode(_component_cc_button, {
            type: "primary",
            label: "copy to new task",
            onButtonClicked: _ctx.copyLog,
            icon: "fas fa-plus",
            "is-disabled": false
          }, null, 8, ["onButtonClicked"])
        ]),
        (_ctx.showEdit)
          ? (_openBlock(), _createBlock(_component_cc_button, {
              key: 0,
              type: "secondary",
              label: "Edit",
              onButtonClicked: _ctx.editLog,
              icon: "fas fa-pen-to-square",
              "is-disabled": false
            }, null, 8, ["onButtonClicked"]))
          : _createCommentVNode("", true),
        (_ctx.props.extraOptions.length)
          ? (_openBlock(), _createBlock(_component_extra_options_button, {
              key: 1,
              "extra-options": _ctx.props.extraOptions,
              onOptionClicked: _ctx.optionClicked
            }, null, 8, ["extra-options", "onOptionClicked"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}