import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end font-display" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "mt-10 -ml-2" }
const _hoisted_4 = {
  key: 0,
  class: "grid grid-cols-5 gap-2"
}
const _hoisted_5 = { class: "col-span-1 h-full border border-gray-100 p-6 h-full shadow-md" }
const _hoisted_6 = { class: "col-span-4 border border-gray-100 p-6 h-full shadow-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_grid_toolbar = _resolveComponent("grid-toolbar")!
  const _component_calendar_sidebar = _resolveComponent("calendar-sidebar")!
  const _component_cc_calendar = _resolveComponent("cc-calendar")!
  const _component_calendar_settings = _resolveComponent("calendar-settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_grid_toolbar, {
          "show-toggle": false,
          "show-expand-button": false,
          "export-options": _ctx.state.exportOptions
        }, {
          "toolbar-primary-button": _withCtx(() => [
            _renderSlot(_ctx.$slots, "toolbar-primary-button", {}, () => [
              _createVNode(_component_cc_button, {
                type: "primary",
                label: "Add Event",
                icon: "fa fa-plus",
                onClick: _ctx.addEvent
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 3
        }, 8, ["export-options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isTab('calendar'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_calendar_sidebar, { onFiltersChanged: _ctx.filtersChanged }, null, 8, ["onFiltersChanged"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_cc_calendar, { filters: _ctx.filters }, null, 8, ["filters"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_calendar_settings, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}