import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b2689e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full font-display" }
const _hoisted_2 = { class: "employee-timeline" }
const _hoisted_3 = { class: "flex items-center justify-between mb-16 p-6 border border-gray-100 shadow-md rounded-md" }
const _hoisted_4 = { class: "my-4" }
const _hoisted_5 = { class: "w-64" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = ["data-rota-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rota_date_range = _resolveComponent("rota-date-range")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_DxResource = _resolveComponent("DxResource")!
  const _component_rota_timeline = _resolveComponent("rota-timeline")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          _createVNode(_component_rota_date_range, {
            "current-date": _ctx.state.timeline.current_date,
            onChanged: _ctx.changeDate
          }, null, 8, ["current-date", "onChanged"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_multi_select, {
            "display-expr": 'text',
            "value-expr": 'text',
            label: "filter by shift type",
            "tag-items": _ctx.computedShiftTypes,
            modelValue: _ctx.state.filtered_shift_types,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.filtered_shift_types) = $event))
          }, null, 8, ["tag-items", "modelValue"])
        ])
      ]),
      _createElementVNode("section", _hoisted_6, [
        _createElementVNode("section", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sharedState.groupings.dates, (current_date, index) => {
            return (_openBlock(), _createElementBlock("section", {
              class: "rota-timeline",
              "data-rota-key": `rota-timeline-${index}`,
              key: index
            }, [
              _createVNode(_component_rota_timeline, {
                view_mode: "employee",
                height: "200px",
                current_date: current_date.date,
                shift_template_type: 'employee',
                datasource: _ctx.state.shifts,
                duration: 120,
                onConfigureForm: _ctx.handlePopupModal
              }, {
                "date-rotate": _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(current_date.short), 1)
                ]),
                resources: _withCtx(() => [
                  _createVNode(_component_DxResource, {
                    "use-color-as-default": true,
                    "data-source": _ctx.state.shifts,
                    "field-expr": "id"
                  }, null, 8, ["data-source"])
                ]),
                _: 2
              }, 1032, ["current_date", "datasource", "onConfigureForm"])
            ], 8, _hoisted_8))
          }), 128))
        ])
      ])
    ])
  ]))
}