import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end mb-8" }
const _hoisted_2 = { class: "flex items-start space-x-4" }
const _hoisted_3 = { class: "w-1/2 space-y-8" }
const _hoisted_4 = { class: "w-1/2 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_drop_zone_appearance_template = _resolveComponent("drop-zone-appearance-template")!
  const _component_file_uploader = _resolveComponent("file-uploader")!
  const _component_uploaded_file = _resolveComponent("uploaded-file")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_text_box = _resolveComponent("text-box")!
  const _component_date_input = _resolveComponent("date-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_toolbar, {
        "extra-options": _ctx.extraOptions,
        onOptionClicked: _ctx.optionClicked,
        onSubmitClicked: _ctx.submitClicked,
        onExportClicked: _ctx.exportClicked
      }, null, 8, ["extra-options", "onOptionClicked", "onSubmitClicked", "onExportClicked"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_select_input, {
          label: "Application status",
          "is-required": true,
          modelValue: _ctx.payload.dbs_status_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.dbs_status_id) = $event)),
          options: _ctx.dbsStatusOptions
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_text_input, {
          label: "Application number",
          modelValue: _ctx.payload.dbs_application_number,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.dbs_application_number) = $event)),
          error: _ctx.errors?.dbs_application_number,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (
          _ctx.errors?.dbs_application_number
            ? (_ctx.errors.dbs_application_number = undefined)
            : ''
        ))
        }, null, 8, ["modelValue", "error"]),
        _createVNode(_component_file_uploader, {
          "upload-text": _ctx.t('components.fileUploader.button'),
          label: _ctx.t('employeeRecruitmentChecks.proofOfIdentity.supportingFiles'),
          "is-required": false,
          onFilesUploaded: _ctx.filesUploaded,
          "allowed-extensions": _ctx.allowedFileExtensions
        }, {
          "drop-zone-appearance": _withCtx(() => [
            _createVNode(_component_drop_zone_appearance_template)
          ]),
          _: 1
        }, 8, ["upload-text", "label", "onFilesUploaded", "allowed-extensions"]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFiles, (file, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_uploaded_file, {
                file: file,
                onSensitiveToggled: _ctx.fileSensitivityToggled,
                onDeleteClicked: _ctx.fileDeleteClicked
              }, null, 8, ["file", "onSensitiveToggled", "onDeleteClicked"])
            ]))
          }), 128))
        ]),
        _createVNode(_component_multi_select, {
          label: "Document types",
          "display-expr": 'text',
          "value-expr": 'value',
          "tag-items": _ctx.evidenceTypeOptions,
          modelValue: _ctx.payload.documents,
          "onUpdate:modelValue": [
            _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.documents) = $event)),
            _cache[4] || (_cache[4] = ($event: any) => (
          _ctx.errors?.documents ? (_ctx.errors.documents = undefined) : ''
        ))
          ],
          error: _ctx.errors?.documents
        }, null, 8, ["tag-items", "modelValue", "error"]),
        _createVNode(_component_select_input, {
          label: "Children's barred list",
          "is-required": true,
          modelValue: _ctx.payload.children_barred_list,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.children_barred_list) = $event)),
          options: _ctx.yesNoOptions,
          error: _ctx.errors?.children_barred_list,
          onChange: _cache[6] || (_cache[6] = ($event: any) => (
          _ctx.errors?.children_barred_list
            ? (_ctx.errors.children_barred_list = undefined)
            : ''
        ))
        }, null, 8, ["modelValue", "options", "error"]),
        _createVNode(_component_select_input, {
          label: "Adult's barred list",
          modelValue: _ctx.payload.adult_barred_list,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.adult_barred_list) = $event)),
          options: _ctx.yesNoOptions,
          error: _ctx.errors?.adult_barred_list,
          onChange: _cache[8] || (_cache[8] = ($event: any) => (
          _ctx.errors?.adult_barred_list
            ? (_ctx.errors.adult_barred_list = undefined)
            : ''
        ))
        }, null, 8, ["modelValue", "options", "error"]),
        _createVNode(_component_text_box, {
          label: "Any issues encountered",
          modelValue: _ctx.payload.issues,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.issues) = $event)),
          "is-required": false,
          error: _ctx.errors?.issues,
          onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.errors?.issues ? (_ctx.errors.issues = undefined) : ''))
        }, null, 8, ["modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_date_input, {
          label: "Expiration date",
          icons: [{ position: 'before', icon: 'fas fa-calendar' }],
          modelValue: _ctx.payload.expiration_date,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.expiration_date) = $event)),
          error: _ctx.errors?.expiration_date,
          onChange: _cache[12] || (_cache[12] = ($event: any) => (
          _ctx.errors?.expiration_date ? (_ctx.errors.expiration_date = undefined) : ''
        ))
        }, null, 8, ["modelValue", "error"]),
        _createVNode(_component_date_input, {
          label: "Refresher date",
          icons: [{ position: 'before', icon: 'fas fa-calendar' }],
          modelValue: _ctx.payload.refresher_date,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.refresher_date) = $event)),
          "is-required": false,
          error: _ctx.errors?.refresher_date,
          onChange: _cache[14] || (_cache[14] = ($event: any) => (
          _ctx.errors?.refresher_date ? (_ctx.errors.refresher_date = undefined) : ''
        ))
        }, null, 8, ["modelValue", "error"]),
        _createVNode(_component_text_input, {
          label: "Reviewed and approved by",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.created_by,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.payload.created_by) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Date of review and approval",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.created_at,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payload.created_at) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Last updated by",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.updated_by,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.payload.updated_by) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Last updated at",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.updated_at,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payload.updated_at) = $event))
        }, null, 8, ["modelValue"])
      ])
    ])
  ], 64))
}