
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import PermissionTile from '@/components/Generic/Button/PermissionTile.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { CareGroupPermission } from '@/types/modules/care_groups/CareGroupPermission'

  export default defineComponent({
    components: {
      PermissionTile,
      CcButton,
    },
    props: {
      userId: {
        type: Number,
        required: true,
      },
    },
    emits: ['permission-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const permissions = computed(
        (): CareGroupPermission[] =>
          store.getters['careGroups/careGroupPermissions']
      )

      const updatePermission = (value: number, item: any) => {
        payload.permissions[item.id] = value
        emit('permission-updated', payload)
      }

      const getCareGroupPermissions = store
        .dispatch('careGroups/getCareGroupPermissions', {
          user_id: props.userId,
        })
        .catch((error) =>
          store.dispatch('genericStore/pushNotification', error.message)
        )

      const loadDependencies = () => {
        return Promise.all([getCareGroupPermissions])
      }

      const payload = reactive({
        type: 'care_group_permissions',
        user_id: props.userId as number,
        permissions: {} as Record<number, number>,
        granular_permissions: {},
      })

      const setInitialPermissionValues = async () => {
        permissions.value.forEach((userPermission: CareGroupPermission) => {
          payload.permissions[userPermission.id] = userPermission.value
        })
      }

      const assignPermission = (item: any) => {
        return (
          permissions.value.find(
            (permission: CareGroupPermission) => permission.id === item.id
          )?.value ?? 0
        )
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        await loadDependencies()
          .then(() => setInitialPermissionValues())
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        store,
        payload,
        permissions,
        assignPermission,
        updatePermission,
      }
    },
  })
