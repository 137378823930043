import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    ref: "grid",
    action: "regions/index",
    columns: _ctx.columns,
    "extra-options": _ctx.state.extraOptions,
    onOptionClicked: _ctx.optionClicked,
    onRowsSelected: _ctx.rowsSelected,
    onPrimaryButtonClicked: _ctx.addClicked
  }, null, 8, ["columns", "extra-options", "onOptionClicked", "onRowsSelected", "onPrimaryButtonClicked"]))
}