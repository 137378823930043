
  import { defineComponent, onMounted, reactive } from 'vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import GroupedSelectInput from '@/components/Generic/Forms/Inputs/GroupedSelectInput.vue'
  import { useStore } from 'vuex'
  import { dateRanges, dateRangeSelected } from '@/composables/Dates'
  import { useI18n } from 'vue-i18n'
  import { SelectOption } from '@/types/components/SelectOption'
  import { FormField } from '@/types/modules/forms/FormField'
  import TrueFalse from '@/components/Generic/Forms/Inputs/TrueFalse.vue'

  export default defineComponent({
    emits: [
      'next-step',
      'start-date-selected',
      'end-date-selected',
      'form-selected',
      'field-selected',
      'task-audit-trail-toggled',
    ],
    components: {
      DateInput,
      SelectInput,
      GroupedSelectInput,
      TrueFalse,
    },
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      props = reactive(props)
      const state = reactive({
        curFields: [] as SelectOption[],
        prevFields: [] as SelectOption[],
        forms: [] as SelectOption[],
        dateRange: 'past-six-months',
      })

      const dateRangeChosen = (range: string) => {
        state.dateRange = range
        const dates = dateRangeSelected(range)
        startDateSelected(dates.start_date)
        endDateSelected(dates.end_date)
      }

      const startDateSelected = (date: string) => {
        emit('start-date-selected', date)
      }

      const endDateSelected = (date: string) => {
        emit('end-date-selected', date)
      }

      const formSelected = (form: number) => {
        const selected_form = state.forms.filter((f: SelectOption) => {
          return f.value == form
        })

        emit('form-selected', form, selected_form ? selected_form[0] : [])
        getFields(form)
      }

      const fieldSelected = (field: number) => {
        emit('field-selected', field)
      }

      const taskAuditTrailToggled = (value: boolean) => {
        emit('task-audit-trail-toggled', value)
      }

      const getFields = (form_id: number) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('forms/getFormFields', {
            form_id,
            only: ['multiple_option_fields'],
          })
          .then((response: any) => {
            store.dispatch('genericStore/showPageLoader', false)
            state.curFields = response.data.current.map((field: FormField) => {
              return { label: field.name, value: field.id }
            })
            state.prevFields = response.data.previous.map(
              (field: FormField) => {
                return { label: field.name, value: field.id }
              }
            )
          })
      }

      onMounted(() => {
        const requiredRelations: string[] = ['types']
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('forms/index', { relations: requiredRelations })
          .then((response: any) => {
            store.dispatch('genericStore/showPageLoader', false)
            state.forms = response.data.map((form: any) => {
              return { label: form.name, value: form.id, types: form.types }
            })
          })
        dateRangeChosen(state.dateRange)
      })

      return {
        state,
        startDateSelected,
        endDateSelected,
        props,
        formSelected,
        fieldSelected,
        dateRanges,
        dateRangeChosen,
        t,
        taskAuditTrailToggled,
      }
    },
  })
