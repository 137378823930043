
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import TransferList from '@/components/Generic/List/TransferList.vue'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: { TransferList },
    props: {
      report: {
        type: Object as PropType<BespokeReport | PublishedBespokeReport | null>,
        required: false,
      },
    },
    emits: ['report-permissions-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const users = computed(() => store.getters['users/users'])
      const whitelist = ref([]) as Ref<User[]>
      const blacklist = ref([]) as Ref<User[]>
      const payload = ref({}) as Ref<{
        whitelist?: User[]
        blacklist?: User[]
      }>

      const updateWhitelistedUsers = (users: User[]) => {
        payload.value = {
          whitelist: users,
        }
      }

      const updateBlacklistedUsers = (users: User[]) => {
        payload.value = {
          blacklist: users,
        }
      }

      watch(
        () => props.report,
        () => {
          if (props.report && route.name === 'PublishedBespokeReportEdit') {
            let report = props.report as PublishedBespokeReport
            updateWhitelistedUsers(report.whitelisted_users)
          }
          if (
            props.report &&
            (route.name === 'BespokeReportEdit' ||
              route.name === 'BespokeReportCreate')
          ) {
            let report = props.report as BespokeReport
            updateBlacklistedUsers(report.restricted_users)
          }
        },
        { deep: true, immediate: true }
      )

      watch(
        () => payload.value,
        () => {
          emit('report-permissions-updated', {
            ...(payload.value.whitelist && {
              whitelisted_users: payload.value.whitelist.map((user) => user.id),
            }),
            ...(payload.value.blacklist && {
              restricted_users: payload.value.blacklist.map((user) => user.id),
            }),
          })
        }
      )

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('users/index')
          .then(() => {
            if (
              route.name === 'BespokeReportEdit' ||
              route.name === 'BespokeReportCreate'
            ) {
              blacklist.value = users.value
            }
            if (route.name === 'BespokeReportPublishedEdit') {
              whitelist.value = users.value
            }
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        route,
        payload,
        whitelist,
        blacklist,
        updateWhitelistedUsers,
        updateBlacklistedUsers,
      }
    },
  })
