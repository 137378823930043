
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DocumentCategoriesTable from './partials/DocumentCategoriesTable.vue'
  import DocumentTypesTable from './partials/DocumentTypesTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      DocumentCategoriesTable,
      DocumentTypesTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Document Types',
        tabs: ['Document Types', 'Document Categories'],
      })

      return {
        state,
      }
    },
  })
