import { EnComponents } from "@/locales/messages/components"

export default {
    careGroupAttachments: {
        header: 'Attachments',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                description: EnComponents.grid.headers.description,
                category: EnComponents.grid.headers.category,
                sensitive: EnComponents.grid.headers.sensitive,
                name: `Attachment ${EnComponents.grid.headers.name}`
            }
        }
    }
}