import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_ctx.state.ready)
    ? (_openBlock(), _createBlock(_component_data_grid, {
        key: 0,
        columns: _ctx.columns,
        chips: _ctx.state.chips,
        "default-chip": _ctx.activeChip,
        "extra-options": _ctx.state.extraOptions,
        "query-filter": {
      filters: [['medication_location_id', '=', _ctx.state.activeLocationId]],
      relations: ['stock_category', 'stock_type'],
    },
        action: "mars/getMedications",
        onChipClicked: _ctx.chipClicked,
        onRowsSelected: _ctx.rowsSelected,
        onOptionClicked: _ctx.extraOptionClicked,
        onPrimaryButtonClicked: _ctx.createMedication
      }, null, 8, ["columns", "chips", "default-chip", "extra-options", "query-filter", "onChipClicked", "onRowsSelected", "onOptionClicked", "onPrimaryButtonClicked"]))
    : _createCommentVNode("", true)
}