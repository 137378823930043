
  import { defineComponent } from 'vue'
  import RegReportingTable from './RegReportingTable.vue'

  import Chips from '@/components/Generic/Button/Chips.vue'

  export default defineComponent({
    components: {
      RegReportingTable,
      Chips,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  })
