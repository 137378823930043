import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects/fields', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any, params: any) => {
    return axios
      .get(`projects/fields/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('projects/fields', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: any, payload: any) => {
    return axios
      .post(`projects/fields/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  delete: (id: any) => {
    return axios
      .delete(`projects/fields/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  saveOrderings: (payload: any) => {
    return axios
      .post(`projects/fields/orderings`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: (payload: any) => {
    return axios
      .post('projects/fields/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: (payload: any) => {
    return axios
      .post('projects/fields/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  toggleManyVisibility: (payload: any) => {
    return axios
      .post('projects/fields/toggle-visibility', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getAllowedFields: () => {
    return axios
      .get('projects/fields/allowed-types')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
