import BespokeReportService from '@/services/reporting/bespokeReports'
import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface BespokeReportState {
  report: BespokeReport | null
  reports: BespokeReport[]
}
export const state: BespokeReportState = {
  report: null,
  reports: [],
}

export const getters: GetterTree<BespokeReportState, any> = {
  report: (state) => state.report,
  reports: (state) => state.reports,
}

export const actions: ActionTree<BespokeReportState, any> = {
  index: async (context, params) => {
    const response = await BespokeReportService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setBespokeReports', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setBespokeReports', response.data)

      return response
    }

    return response
  },
  show: (context, id: number) => {
    const response = BespokeReportService.show(id)

    response.then((response) => {
      if (response.success) {
        context.commit('setBespokeReport', response.data)
      }
    })

    return response
  },
  store: async (context, payload) => {
    const response = await BespokeReportService.store(payload)

    if (response.success) {
      context.commit('setBespokeReport', response.data)
    }

    return response
  },
  update: async (context, payload) => {
    const response = await BespokeReportService.update(payload.id, payload)

    if (response.success) {
      context.commit('setBespokeReport', response.data)
    }

    return response
  },
  deleteMany: async (_context, params) => {
    return await BespokeReportService.deleteMany(params)
  },
  restoreMany: async (_context, params) => {
    return await BespokeReportService.restoreMany(params)
  },
  getSelectedReports: (context) => {
    const response = context.getters['reports']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  setSelectedReports: (context, reports: BespokeReport[]) => {
    context.commit('setBespokeReports', reports)
  },
}

export const mutations: MutationTree<BespokeReportState> = {
  setBespokeReport: (state, report: BespokeReport) => {
    state.report = report
  },
  setBespokeReports: (state, bespokeReports: BespokeReport[]) => {
    state.reports = bespokeReports
  },
}

export const bespokeReports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
