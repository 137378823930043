import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-1/2" }
const _hoisted_2 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: "Address Line 1",
        modelValue: _ctx.payload.company_address1,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.company_address1) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Address Line 2",
        modelValue: _ctx.payload.company_address2,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.company_address2) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "City",
        modelValue: _ctx.payload.company_city,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.company_city) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "County/Region",
        modelValue: _ctx.payload.company_state,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.company_state) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Post Code",
        modelValue: _ctx.payload.company_zip,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.company_zip) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}