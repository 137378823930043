
  import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
  import { TaskFile } from '@/types/modules/tasks/TaskFile'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { FileCategory } from '@/types/modules/files/FileCategory'
  import { useRouter } from 'vue-router'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      projectId: {
        type: [String, Number],
        required: false,
      },
      taskId: {
        type: [String, Number],
        required: false,
      },
      allowMove: {
        type: Boolean,
        required: false,
        default: false,
      },
      showChips: {
        type: Boolean,
        required: false,
        default: true,
      },
      showGridToolbar: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const query = ref({})
      const state = reactive({
        categories: [] as string[],
        activeChip: 'all' as string,
        extraOptions: [] as string[],
      })

      const categories = computed(() =>
        store.getters['fileCategories/categories']?.map(
          (category: FileCategory) => category.name
        )
      )

      const chips = computed((): string[] => {
        if (!props.showChips) return []

        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'File Name',
          dataField: 'name',
          allowGrouping: false,
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: 'Type',
          dataField: 'mime_type',
        },
        {
          caption: 'Category',
          dataField: 'category.name',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'created_at',
          caption: 'Date',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
      ])

      const filters = computed(() => {
        return {
          ...(props.projectId && { project_id: props.projectId }),
          ...(props.taskId && { task_id: props.taskId }),
          ...query.value,
        }
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { category: state.activeChip }),
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Move Attachments') {
          router.push({
            name: 'MoveTaskAttachments',
            params: {
              id: props.taskId,
            },
          })
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const rowsSelected = (rows: TaskFile[]) => {
        if (props.allowMove) {
          allowMoving(rows)
        }
      }

      const allowMoving = (rows: TaskFile[]) => {
        if (rows.length && state.extraOptions.includes('Move Attachments')) {
          store.commit('tasks/setSelectedAttachments', rows)
          return
        }

        if (rows.length && !state.extraOptions.includes('Move Attachments')) {
          state.extraOptions.push('Move Attachments')
          store.commit('tasks/setSelectedAttachments', rows)
          return
        }

        store.commit('tasks/setSelectedAttachments', [])
        return (state.extraOptions = [])
      }

      onMounted(() => {
        store.dispatch('fileCategories/index', {
          project_id: props.projectId,
          only: ['has_active_task_files'],
        })
      })

      return {
        chips,
        props,
        state,
        columns,
        filters,
        categories,
        optionClicked,
        chipClicked,
        rowsSelected,
      }
    },
  })
