export default {
    homeDetails: {
        header: 'Home Details',
        summary: {
            id: 'ID',
            name: 'House Name',
            beds: 'No. Of Beds',
            administrator: 'Home Administrator',
            personInCharge: 'Person In Charge',
            responsibleIndividual: 'Responsible Individual'
        },
        contactDetails: {
            joined: 'Joined Care Group On',
            left: 'Left Care GRoup On',
            electricalCert: 'Electrical Certificate',
            gasCert: 'Gas Certificate',
            fireDrillDay: 'Fire Drill - day',
            fireDrillNight: 'Fire Drill - night',
            description: 'Description',
            patDate: 'Pat Date'
        },
        ofstedDetails: {
            urn: 'Ofsted Urn',
            rating: 'Full Ofsted Rating',
            ratingDate: 'Rating Date',
            requirementNum: 'Number Of Requirements',
            regRequirementNum: 'Regulation Numbers for requirements',
            recommendationNum: 'Numbers of Recommendations',
            regRecommendationNum: 'Regulation numbers of recommendations',
            interimRating: 'Interim Ofsted Rating',
        }
    }
}