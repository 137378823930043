import { EnComponents } from "@/locales/messages/components";

export default {
    employeeReports: {
        header: 'Reg Reporting',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                name: EnComponents.grid.headers.name,
                description: EnComponents.grid.headers.description,
                status: EnComponents.grid.headers.status,
                owner: EnComponents.grid.headers.owner,
                createdOn: EnComponents.grid.headers.createdOn,
                fullReport: 'Full Report',
                reportPeriod: 'Report Period'
            },
            cells: {
                viewReport: 'View Report'
            }
        }
    }
}