
  import { Crumb } from '@/types/components/Crumb'
  import { MenuTab } from '@/types/components/MenuTab'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    watch,
  } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import format from 'date-fns/format'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'

  export default defineComponent({
    components: {
      TextBox,
      TextInput,
      CcButton,
      ButtonGroup,
      PageToolbar,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      publishedReportId: {
        type: String,
        required: true,
      },
      commentId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const tabs = reactive<MenuTab[]>([
        {
          name: 'Report',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=report`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
      ])
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Published Reports',
          url: `/bespoke-reporting/${props.id}/published-reports`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
        {
          name: 'View Comment',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}/comments/${props.commentId}`,
        },
      ])
      const comment = computed(
        () => store.getters['publishedBespokeReportComments/comment']
      )
      const payload = reactive({
        id: props.commentId,
        body: '',
        posted_by: '',
        created_at: '',
      })

      watch(
        () => comment.value,
        (comment) => {
          if (comment) {
            payload.id = comment.id
            payload.body = comment.body
            payload.posted_by = comment.posted_by
              ? `${comment.posted_by.first_name} ${comment.posted_by.last_name}`
              : ''
            payload.created_at = comment.created_at
              ? format(new Date(comment.created_at), 'dd/MM/yyyy HH:mm')
              : ''
          }
        },
        { immediate: true }
      )

      const exportClicked = (exportType: string) => {
        const exportMap = {
          'Export To PDF': exportPageToPdf,
          'Export To Excel': exportPageToExcel,
        } as Record<string, any>

        return exportMap[exportType](payload, 'Comment')
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: tabs })
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (!comment.value) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('publishedBespokeReportComments/show', props.commentId)
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      onUnmounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('publishedBespokeReports/setPublishedBespokeReport', null)
      })

      return {
        goBack,
        payload,
        exportClicked,
      }
    },
  })
