
  import { defineComponent, reactive } from 'vue'
  import TrueFalse from '@/components/Generic/Forms/Inputs/TrueFalse.vue'

  export default defineComponent({
    components: {
      TrueFalse,
    },
    props: {
      file: {
        type: Object,
        required: true,
      },
    },
    emits: ['sensitive-toggled', 'delete-clicked'],
    setup(props, { emit }) {
      props = reactive(props)

      const deleteClicked = () => {
        emit('delete-clicked', props.file)
      }

      const sensitiveToggled = () => {
        emit('sensitive-toggled', props.file)
      }

      return {
        props,
        deleteClicked,
        sensitiveToggled,
      }
    },
  })
