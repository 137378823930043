
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    onMounted,
    watch,
    ref,
    Ref,
  } from 'vue'
  import { FieldAttribute } from '../../../../../types/components/FieldAttribute'
  import TextInput from '../TextInput.vue'
  import sanitizeHtml from 'sanitize-html'

  export default defineComponent({
    components: {
      TextInput,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const label = computed(() => {
        return props.fieldAttributes.in_table == false
          ? props.fieldAttributes.label
          : null
      })

      const error = ref() as Ref<string | undefined>
      const value = ref() as Ref<string | undefined>

      onMounted(() => (value.value = props.fieldAttributes.value))

      const validateInput = () => {
        if (props.fieldAttributes.required == true && !value.value) {
          error.value = 'This field is required'
          return false
        }

        error.value = undefined
        return true
      }

      watch(value, () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: value.value ? sanitizeHtml(value.value) : value.value,
            row_index: props.fieldAttributes.row_index,
          })
        }
      })

      return {
        props,
        label,
        error,
        value,
      }
    },
  })
