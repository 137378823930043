
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, watch } from 'vue'
  import FilingCabinet from '../partials/FilingCabinet/FilingCabinet.vue'
  import EmployeeDetails from './partials/EmployeeDetails.vue'
  import ProjectAssociations from '../partials/ProjectAssociations/ProjectAssociations.vue'
  import ProjectAttachments from '../partials/Attachments/ProjectAttachments.vue'
  import Qualifications from './partials/qualifications/Qualifications.vue'
  import Contract from './partials/EmployeeContract.vue'
  import RecruitmentChecks from './partials/recruitment/Index.vue'
  import { isTab } from '@/composables/Generic'
  import UserPermissions from '../partials/UserPermissions/UserPermissions.vue'
  import Reports from '../partials/RegReporting/RegReporting.vue'
  import EmployeeReferences from './partials/references/EmployeeReferencesTable.vue'
  import RotasIndex from '@/views/modules/rotas/employees/Index.vue'

  export default defineComponent({
    components: {
      RotasIndex,
      ProjectAssociations,
      ProjectAttachments,
      FilingCabinet,
      EmployeeDetails,
      Qualifications,
      RecruitmentChecks,
      Contract,
      Reports,
      UserPermissions,
      EmployeeReferences,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const employee = computed(() => store.getters['employees/employee'])

      const tabs = [
        { name: 'Filing Cabinet', url: `${route.path}?tab=filing-cabinet` },
        { name: 'Details', url: `${route.path}?tab=details` },
        { name: 'Qualifications', url: `${route.path}?tab=qualifications` },
        {
          name: 'Recruitment Checks',
          url: `${route.path}?tab=recruitment-checks`,
        },
        { name: 'Contract', url: `${route.path}?tab=contract` },
        {
          name: 'Employee Attachments',
          url: `${route.path}?tab=employee-attachments`,
        },
        {
          name: 'Log Attachments',
          url: `${route.path}?tab=log-attachments`,
        },
        { name: 'Reports', url: `${route.path}?tab=reports` },
        {
          name: 'User Permissions',
          url: `${route.path}?tab=user-permissions`,
        },
        { name: 'Associations', url: `${route.path}?tab=associations` },
        { name: 'References', url: `${route.path}?tab=references` },
        { name: 'Rota', url: `${route.path}?tab=rota` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Employees', url: '/projects/employees' },
        ]

        if (!employee.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: employee.value.project.name,
          url: `/projects/employees/${employee.value.id}`,
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'filing-cabinet' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('genericStore/showPageLoader', true)
        store.dispatch('employees/show', props.id).finally(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        isTab,
        employee,
      }
    },
  })
