
import { reactive, defineComponent } from 'vue'
import { getApiUrl } from '@/composables/Generic'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    image: {
      type: String,
      required: false
    },
  },
  setup(props) {
    const store = useStore()
    props = reactive(props)    

    store.commit('genericStore/setClientLogoUrl', getApiUrl() + '/logo')
    
    return {
      props,
    }
  },
})
