
  import { defineComponent, toRef } from 'vue'
  export default defineComponent({
    props: {
      count: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const count = toRef(props, 'count')
      const countClasses = [
        'bg-blue-200',
        'border-2',
        'border-blue-400',
        'h-10',
        'w-10',
        'flex',
        'items-center',
        'justify-center',
        'rounded-full',
        'text-white',
        'font-bold',
        'text-sm',
      ]

      return {
        countClasses,
        count,
      }
    },
  })
