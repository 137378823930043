import PublishedBespokeReportCommentService from '@/services/reporting/publishedBespokeReportComments'
import { PublishedBespokeReportComment } from '@/types/modules/reporting/PublishedBespokeReportComment'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface PublishedBespokeReportCommentState {
  comments: PublishedBespokeReportComment[]
  comment: PublishedBespokeReportComment | null
}
export const state: PublishedBespokeReportCommentState = {
  comments: [],
  comment: null,
}

export const getters: GetterTree<PublishedBespokeReportCommentState, any> = {
  comments: (state) => state.comments,
  comment: (state) => state.comment,
}

export const actions: ActionTree<PublishedBespokeReportCommentState, any> = {
  index: async (context, params) => {
    const response = await PublishedBespokeReportCommentService.index(
      params.id,
      params
    )

    if (response.success && params?.per_page) {
      context.commit('setPublishedBespokeReportComments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setPublishedBespokeReportComments', response.data)

      return response
    }

    return response
  },
  show: (context, id: number) => {
    const response = PublishedBespokeReportCommentService.show(id)

    response.then((response) => {
      if (response.success) {
        context.commit('setPublishedBespokeReportComment', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = PublishedBespokeReportCommentService.store(
      payload.id,
      payload
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setPublishedBespokeReportComment', response.data)
      }
    })

    return response
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(
      PublishedBespokeReportCommentService.deleteMany(payload)
    )
  },
  getSelectedComments: (context) => {
    const response = context.getters['comments']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  setSelectedComments: (context, payload: PublishedBespokeReportComment[]) => {
    context.commit('setPublishedBespokeReportComments', payload)
  },
}

export const mutations: MutationTree<PublishedBespokeReportCommentState> = {
  setPublishedBespokeReportComments: (
    state,
    comments: PublishedBespokeReportComment[]
  ) => {
    state.comments = comments
  },
  setPublishedBespokeReportComment: (
    state,
    comment: PublishedBespokeReportComment
  ) => {
    state.comment = comment
  },
}

export const publishedBespokeReportComments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
