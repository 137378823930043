
  import { reactive, computed, ref, defineComponent } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { renderCellLink } from '@/composables/Grids'

  export default defineComponent({
    components: { DataGrid },
    props: {
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const query = ref({})

      const state = reactive({
        activeChip: 'current' as string,
        chips: ['all', 'current', 'planned', 'past'],
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'project_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'project.name',
          caption: 'Home Name',
          allowSorting: false,
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data?.project?.name, 'HomeShow', {
              id: options.data?.id,
            })
          },
        },
        {
          dataField: 'number_of_beds',
          caption: 'Number of Beds',
          visible: false,
        },
        {
          dataField: 'project.start_date',
          caption: 'Start Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.end_date',
          caption: 'End Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.status',
          caption: 'Status',
          allowSorting: false,
        },
        {
          caption: 'Responsible Individual',
          allowSorting: false,
          calculateCellValue: (row: any) => {
            return `${row.responsible_individual?.first_name} ${row.responsible_individual?.last_name}`
          },
        },
        {
          dataField: 'pat_date',
          caption: 'PAT Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'electrical_certificate',
          caption: 'Electrical Certificate Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'gas_certificate',
          caption: 'Gas Certificate Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'health_safety_risk_date',
          caption: 'Health & Safety Risk Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'fire_risk_date',
          caption: 'Fire Risk Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'day_fire_drill_date',
          caption: 'Day Fire Drill Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'night_fire_drill_date',
          caption: 'Night Fire Drill Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'fire_service_visit_date',
          caption: 'Fire Service Visit Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'employers_liability_insurance_date',
          caption: 'Employers Liability Insurance Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'ofsted_urn',
          caption: 'Ofsted URN',
          visible: false,
        },
        {
          dataField: 'ofsted_rating',
          caption: 'Ofsted Rating',
          visible: false,
        },
        {
          dataField: 'ofsted_rating_date',
          caption: 'Ofsted Rating Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'is_secure_accommodation',
          caption: 'Is Secure Accommodation',
          visible: false,
          calculateCellValue: (row: any) => {
            return row.is_secure_accommodation ? 'Yes' : 'No'
          },
        },
        {
          dataField: 'is_short_term_stay',
          caption: 'Is Short Term Stay',
          visible: false,
          calculateCellValue: (row: any) => {
            return row.is_short_term_stay ? 'Yes' : 'No'
          },
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.project?.id,
            })
          },
          allowExporting: false,
        },
        {
          caption: 'Create Task',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Create Task', 'CreateTask', {
              id: options.data?.project?.id,
              projectType: `${options.data?.project?.project_type_label}s`,
            })
          },
          allowExporting: false,
        },
      ])
      const filters = computed(() => {
        return {
          relations: ['project', 'responsible_individual'],
          ...query.value,
          ...props.filters,
        }
      })
      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }
      const applyFilters = () => {
        query.value = {
          ...(state.activeChip && { status: state.activeChip }),
        }
      }

      return {
        state,
        chipClicked,
        props,
        columns,
        filters,
      }
    },
  })
