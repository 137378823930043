
  import { defineComponent, computed, onMounted, ref, Ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { goBack } from '@/composables/Generic'
  import { useI18n } from 'vue-i18n'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { MedicationLocation } from '@/types/modules/mars/MedicationLocation'
  import { Crumb } from '@/types/components/Crumb'
  import { object, string, array } from 'yup'
  const UUID = require('uuid-js')
  import { validatePayload } from '@/composables/Validation'

  export default defineComponent({
    components: {
      TextInput,
      CcButton,
    },
    props: {
      projectType: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const route = useRoute()
      const store = useStore()

      const payload = ref([]) as Ref<{ id: string | number; name: string }[]>
      const deletedLocations = ref([]) as Ref<number[]>
      const locations = computed(
        () => store.getters['mars/medicationLocations']
      )

      const MedicationLocationSchema = object().shape({
        name: string().required('Field name is required'),
      })

      const submitPayload = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        let response = await validatePayload(
          object().shape({
            data: array()
              .of(MedicationLocationSchema)
              .min(
                1,
                'You must have at least one medical cabinet which will be the default'
              ),
          }),
          {
            data: payload.value,
          }
        )

        let errors = response ? Object.values(response) : null
        if (errors && errors.indexOf('Field name is required') !== -1) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            'Locations require a name'
          )
          return
        }

        store
          .dispatch('mars/manageMedicationLocations', {
            project_id: props.id,
            locations: payload.value,
            deleted_location_ids: deletedLocations.value,
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      const canDeleteLocation = (location: {
        id: string | number
        name: string
      }) => {
        if (payload.value.length === 1) {
          return false
        }

        let existingLocation = locations.value.find(
          (cabinet: MedicationLocation) => {
            return cabinet.id === location.id
          }
        )

        if (existingLocation && existingLocation.medications.length) {
          return false
        }

        return true
      }

      const deleteLocation = (locationId: string | number, index: number) => {
        if (payload.value.length === 1) {
          store.dispatch(
            'genericStore/pushNotification',
            'You must have at least one medical cabinet which will be the default'
          )
          return
        }

        let existingLocation = locations.value.find(
          (cabinet: MedicationLocation) => {
            return cabinet.id === locationId
          }
        )

        if (existingLocation && existingLocation.medications.length) {
          store.dispatch(
            'genericStore/pushNotification',
            'Cannot delete locations which have medications in'
          )
          return
        }

        if (existingLocation) {
          deletedLocations.value.push(existingLocation.id)
        }

        payload.value.splice(index, 1)
      }

      const mapToPayload = () => {
        locations.value.forEach((location: MedicationLocation) => {
          payload.value.push({ id: location.id, name: location.name })
        })
      }

      const addLocation = () =>
        payload.value.push({ id: UUID.create().toString(), name: '' })

      const addCrumb = () => {
        if (
          store.getters['genericStore/breadCrumbs'].findIndex(
            (crumb: Crumb) => crumb.name === 'Manage Medical Cabinets'
          ) === -1
        ) {
          store.getters['genericStore/breadCrumbs'].push({
            name: 'Manage Medical Cabinets',
            url: route.fullPath,
          })
        }
      }

      const getLocations = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })
        store
          .dispatch('mars/getMedicationLocations', {
            project_id: props.id,
            relations: ['medications'],
          })
          .then(() => mapToPayload())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        if (!props.id) {
          goBack()
        }

        addCrumb()
        getLocations()
      })

      return {
        t,
        goBack,
        payload,
        addLocation,
        deleteLocation,
        submitPayload,
        locations,
        canDeleteLocation,
      }
    },
  })
