import store from '@/store'
import { computed } from 'vue'

export const getShiftTypes = () => store.dispatch('shiftTypes/index')
export const shiftTypes = computed(
  () => store.getters['shiftTypes/getShiftTypes']
)
export const computedShiftTypes = computed(() => {
  if (shiftTypes.value) {
    return shiftTypes.value.map((type: any) => {
      return {
        text: type.name,
      }
    })
  }
  return []
})
