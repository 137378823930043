import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "flex items-center justify-end font-display" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_expand_button = _resolveComponent("expand-button")!
  const _component_grid_toolbar = _resolveComponent("grid-toolbar")!
  const _component_employee_summary = _resolveComponent("employee-summary")!
  const _component_employee_contact_details = _resolveComponent("employee-contact-details")!
  const _component_employee_personal_details = _resolveComponent("employee-personal-details")!
  const _component_employee_employment_details = _resolveComponent("employee-employment-details")!
  const _component_custom_field_details = _resolveComponent("custom-field-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_chips, {
        chips: _ctx.getAllTabs,
        "show-expanded": _ctx.state.expanded,
        onChipClicked: _ctx.showTab
      }, null, 8, ["chips", "show-expanded", "onChipClicked"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_expand_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.expanded = !_ctx.state.expanded))
        }),
        _createVNode(_component_grid_toolbar)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.state.activeTab === 'summary')
        ? (_openBlock(), _createBlock(_component_employee_summary, {
            key: 0,
            data: []
          }))
        : _createCommentVNode("", true),
      (_ctx.state.activeTab === 'contact details')
        ? (_openBlock(), _createBlock(_component_employee_contact_details, {
            key: 1,
            data: []
          }))
        : _createCommentVNode("", true),
      (_ctx.state.activeTab === 'Personal details')
        ? (_openBlock(), _createBlock(_component_employee_personal_details, {
            key: 2,
            data: []
          }))
        : _createCommentVNode("", true),
      (_ctx.state.activeTab === 'Employment details')
        ? (_openBlock(), _createBlock(_component_employee_employment_details, {
            key: 3,
            data: []
          }))
        : _createCommentVNode("", true),
      (_ctx.hasCustomTab(_ctx.state.activeTab))
        ? (_openBlock(), _createBlock(_component_custom_field_details, {
            key: 4,
            projectId: _ctx.props.id,
            projectType: "employee",
            groupName: _ctx.state.activeTab,
            fields: _ctx.customFieldGroups[_ctx.state.activeTab]
          }, null, 8, ["projectId", "groupName", "fields"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}