
  import { SummaryWidgetType } from '@/types/modules/summary/SummaryWidgetType'
  import { defineComponent, PropType, computed, reactive } from 'vue'

  export default defineComponent({
    props: {
      type: Object as PropType<SummaryWidgetType>,
      selected: Boolean,
    },
    setup(props) {
      props = reactive(props)
      const cardColour = computed(() => `bg-${props?.type?.colour}-100`)

      return {
        props,
        cardColour,
      }
    },
  })
