
  import { useStore } from 'vuex'
  import { computed, defineComponent, reactive, ref } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { School } from '@/types/modules/projects/School'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import { isTab } from '@/composables/Generic'

  export default defineComponent({
    components: {
      DataGrid,
      ConfirmDialog,
    },
    props: {
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const query = ref({})

      const state = reactive({
        activeChip: 'current' as string,
        chips: ['all', 'current', 'planned', 'past'],
        showDeleteManyConfirmDialog: false,
        componentKey: 0,
        showHidden: false as boolean,
        extraOptions: [] as string[],
        selectedRows: [] as School[],
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'project_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'project.name',
          caption: 'School Name',
          allowSorting: false,
          cellTemplate: 'ProjectNameCellTemplate',
        },
        {
          dataField: 'number_of_pupils',
          caption: 'Number of Pupils',
          visible: false,
        },
        {
          caption: 'Person In Charge',
          allowSorting: false,
          calculateCellValue: (row: any) => {
            return `${row.person_in_charge?.first_name} ${row.person_in_charge?.last_name}`
          },
        },
        {
          dataField: 'project.start_date',
          caption: 'Start Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.end_date',
          caption: 'End Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.status',
          caption: 'Status',
          allowSorting: false,
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.project?.id,
              projectType: options.data?.project?.project_type_label,
            })
          },
          allowExporting: false,
        },
        {
          caption: 'Create Task',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Create Task', 'CreateTask', {
              id: options.data?.project?.id,
              projectType: `${options.data?.project?.project_type_label}s`,
            })
          },
          allowExporting: false,
        },
      ])
      const filters = computed(() => {
        return {
          relations: ['project', 'person_in_charge'],
          ...query.value,
          ...props.filters,
        }
      })
      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }
      const refreshGrid = () => {
        state.componentKey += 1
      }
      const applyFilters = () => {
        query.value = {
          ...(state.activeChip && { status: state.activeChip }),
        }
      }
      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/deleteMany', {
            ids: state.selectedRows.map((row: School) => row.project_id),
          })
          .then(() => {
            state.selectedRows = []
            state.showDeleteManyConfirmDialog = false
          })
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => refreshGrid())
      }
      return {
        state,
        query,
        columns,
        filters,
        isTab,
        chipClicked,
        deleteSelectedRecords,
        props,
      }
    },
  })
