
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, onMounted, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { useI18n } from 'vue-i18n'

  export default {
    components: {
      CcButton,
      DataGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const reports = computed(
        (): PublishedBespokeReport[] =>
          store.getters['publishedBespokeReports/reports']
      )
      const bespokeReport = computed((): BespokeReport | null => {
        if (!reports.value) {
          return null
        }

        return reports.value[0]?.report
      })
      const crumbs = reactive<Crumb[]>([
        {
          name: t('publishedBespokeReports.crumbs.dashboard'),
          url: '/dashboard',
        },
        {
          name: t('publishedBespokeReports.crumbs.bespokeReporting'),
          url: '/bespoke-reporting',
        },
        {
          name: t('publishedBespokeReports.crumbs.publishedReports'),
          url: `/bespoke-reporting/${bespokeReport.value?.id}/published-reports`,
        },
      ])

      const columns: Column[] = reactive([
        {
          caption: t('publishedBespokeReports.grids.id'),
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: t('publishedBespokeReports.grids.name'),
          dataField: 'name',
          width: 200,
        },
        {
          caption: t('publishedBespokeReports.grids.description'),
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: t('publishedBespokeReports.grids.publisher'),
          calculateCellValue: (row: PublishedBespokeReport) => {
            return `${row.publisher?.first_name} ${row.publisher?.last_name}`
          },
          width: 200,
        },
      ])

      const goBack = () => {
        router.push({ name: 'BespokeReportPublishedIndex' })
      }

      const deleteRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('publishedBespokeReports/deleteMany', {
            ids: reports.value.map(
              (report: PublishedBespokeReport) => report.id
            ),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            router.push({ name: 'BespokeReportPublishedIndex' })
          })
      }

      onMounted(() => {
        !store.getters['publishedBespokeReports/reports'].length &&
          router.push({ name: 'BespokeReportPublishedIndex' })

        store.commit('genericStore/setBreadcrumbs', { crumbs })
      })
      return {
        t,
        goBack,
        reports,
        columns,
        deleteRecords,
      }
    },
  }
