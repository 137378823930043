
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      isCollapsed: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    emits: ['collapse-menu'],
    setup(props, { emit }) {
      props = reactive(props)

      const classes = computed(
        (): Object => ({
          'flex': true,
          'pt-6': true,
          'px-3': true,
          'mb-4': true,
          'items-center': true,
          'justify-center': props.isCollapsed,
        })
      )

      const collapseMenu = () => emit('collapse-menu')

      return {
        props,
        classes,
        collapseMenu,
      }
    },
  })
