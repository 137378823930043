
  import {
    defineComponent,
    reactive,
    computed,
    PropType,
    onMounted,
    watch,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import {
    Vehicle,
    VehicleSummaryPayload,
  } from '@/types/modules/projects/Vehicle'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import format from 'date-fns/format'
  import { Region } from '@/types/modules/regions/Region'
  import { User } from '@/types/modules/users/User'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
      SelectInput,
      DateInput,
      TextBox,
    },
    props: {
      vehicle: {
        type: Object as PropType<Vehicle>,
        required: false,
      },
      errors: {
        type: Object as PropType<Record<string, string>>,
        required: false,
        default: () => {},
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const { t } = useI18n()
      props = reactive(props)
      const store = useStore()
      const payload = reactive({
        id: undefined,
        name: undefined,
        make: undefined,
        model: undefined,
        colour: undefined,
        vin: undefined,
        registration_number: undefined,
        registration_date: undefined,
        mot_date: undefined,
        tax_date: undefined,
        fuel: undefined,
        company_id: undefined, //caregroup id
        region_id: undefined,
        administrator_id: undefined, //user id
        contact_details: undefined,
      }) as VehicleSummaryPayload

      watch(payload, () => {
        emit('update-payload', payload)
      })

      const users = computed(() => {
        if (store.getters['users/users']?.length) {
          return store.getters['users/users'].map((user: User) => {
            return { label: user.username, value: user.id }
          })
        }
        return undefined
      })

      const regions = computed(() =>
        store.getters['regions/regions'].map((region: Region) => {
          return { label: region.name, value: region.id }
        })
      )

      const careGroups = computed(() =>
        store.getters['careGroups/careGroups'].map((careGroup: CareGroup) => {
          return {
            label: careGroup.company_name,
            value: careGroup.company_id,
          }
        })
      )

      const mapToPayload = () => {
        if (props.vehicle) {
          payload.id = props.vehicle.id
          payload.name = props.vehicle.project?.name
          payload.make = props.vehicle.make as string
          payload.model = props.vehicle.model as string
          payload.colour = props.vehicle.colour as string
          payload.vin = props.vehicle.vin as string
          payload.registration_number = props.vehicle
            .registration_number as string
          payload.registration_date = props.vehicle.registration_date
            ? format(new Date(props.vehicle.registration_date), 'yyyy-MM-dd')
            : undefined
          payload.mot_date = props.vehicle.mot_date
            ? format(new Date(props.vehicle.mot_date), 'yyyy-MM-dd')
            : undefined
          payload.tax_date = props.vehicle.tax_date
            ? format(new Date(props.vehicle.tax_date), 'yyyy-MM-dd')
            : undefined
          payload.fuel = props.vehicle.fuel as string
          payload.company_id = props.vehicle.project?.company_id
          payload.region_id = props.vehicle.project?.region_id as number
          payload.administrator_id = props.vehicle.project
            ?.administrator_id as number
          payload.contact_details = props.vehicle.contact_details as string
        }
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        mapToPayload()
        Promise.all([
          store
            .dispatch('users/index')
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            ),
          store
            .dispatch('careGroups/index')
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            ),
          store
            .dispatch('regions/index')
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            ),
        ]).finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        users,
        regions,
        props,
        t,
        payload,
        careGroups,
      }
    },
  })
