
  import {
    defineComponent,
    onMounted,
    Ref,
    ref,
    PropType,
    reactive,
    watch,
    computed,
  } from 'vue'
  import DxList, { DxItemDragging } from 'devextreme-vue/list'
  import { Field } from '@/types/modules/forms/Field'
  import { useStore } from 'vuex'
  const UUID = require('uuid-js')
  import {
    RenderedFormFieldSchema,
    FieldDroppedEvent,
    FieldReorderedEvent,
    FieldAttribute,
  } from '@/types/components/FormBuilder'
  import { FormField } from '@/types/modules/forms/FormField'
  import FieldItemTemplate from './templates/FieldItemTemplate.vue'
  import { FormKeyword } from '@/types/modules/forms/FormKeyword'
  import { SystemList } from '@/types/modules/forms/SystemList'
  import { Form } from '@/types/modules/forms/Form'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { object, string } from 'yup'

  export default defineComponent({
    name: 'field-canvas',
    components: {
      DxList,
      DxItemDragging,
      FieldItemTemplate,
      CcButton,
    },
    props: {
      fields: {
        type: Array as PropType<FormField[]>,
        required: false,
      },
    },
    emits: ['fields-changed', 'field-deleted'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const addedField = computed(
        () => store.getters['formBuilder/getAddedField']
      )

      const isDbField = (field: RenderedFormFieldSchema): boolean => {
        try {
          object({
            id: string().uuid(),
          }).validateSync({ id: field.id })
          return false
        } catch {
          return true
        }
      }

      watch(
        () => addedField.value,
        () => {
          if (addedField.value) {
            addField({
              itemData: addedField.value,
              toIndex: renderedFormFields.value.length,
            })
          }

          store.commit('formBuilder/setAddedField', null)
        }
      )
      const itemBeingMoved = ref() as Ref<RenderedFormFieldSchema | undefined>
      const fieldCanvas = ref() as Ref<DxList | undefined>
      const renderedFormFields = ref([]) as Ref<RenderedFormFieldSchema[]>
      const buildNewFormField = (field: Field): RenderedFormFieldSchema => {
        return {
          id: UUID.create().toString(),
          field: field,
          name: '',
          description: '',
          instructions: '',
          keywords: [],
          parent_id: null,
          options: [],
          field_attributes: [],
        }
      }

      /**
       * Watches the form fields for changes and emits them back up to be
       * handled by preview/submission
       */
      watch(renderedFormFields, (value: RenderedFormFieldSchema[]) =>
        emit('fields-changed', value)
      )

      /**
       * Gets the related table end for a table.
       */
      const getTableEnd = (
        table: RenderedFormFieldSchema
      ): RenderedFormFieldSchema | undefined => {
        return renderedFormFields.value.find(
          (formField: RenderedFormFieldSchema) => {
            return (
              formField.parent_id === table.id &&
              formField.field.system_name === 'table_end'
            )
          }
        )
      }

      /**
       * Sets the item being moved to the form field clicked on which
       * starts 'move mode'
       */
      const enterMoveMode = (formField: RenderedFormFieldSchema): void => {
        if (renderedFormFields.value.length <= 1) return

        itemBeingMoved.value = formField
      }

      /**
       * Removes a field from the canvas. If the field is a table it removes
       * the related table end and updates all the fields inside to no longer be
       * in a table.
       */
      const deleteField = (field: RenderedFormFieldSchema): void => {
        if (isDbField(field)) {
          emit('field-deleted', field)
        }

        if (field.field.system_name === 'table') {
          renderedFormFields.value = renderedFormFields.value.filter(
            (formField: RenderedFormFieldSchema) =>
              formField.id !== getTableEnd(field)?.id &&
              formField.id !== field.id
          )

          renderedFormFields.value.forEach(
            (formField: RenderedFormFieldSchema) => {
              if (formField.parent_id !== field.id) return

              formField.parent_id = null

              let attribute = formField.field_attributes.find(
                (attribute: FieldAttribute) => attribute.name === 'in_table'
              )

              if (attribute) {
                attribute.value = false
              }
            }
          )

          return
        }

        renderedFormFields.value = renderedFormFields.value.filter(
          (formField: RenderedFormFieldSchema) => {
            return formField.id !== field.id
          }
        )
      }

      /**
       * Returns the data needed to create a table end field.
       */
      const getTableEndData = (
        field: RenderedFormFieldSchema
      ): RenderedFormFieldSchema => {
        return {
          id: `${field.id}-table-end`,
          name: 'Table End',
          parent_id: field.id,
          field: {
            name: 'Table End',
            system_name: 'table_end',
          } as Field,
          field_attributes: [] as FieldAttribute[],
          options: [],
          keywords: [],
          description: '',
          instructions: '',
        }
      }

      /**
       * Adds a table end field after a table field.
       */
      const addTableEnd = (
        field: RenderedFormFieldSchema,
        existingFields: RenderedFormFieldSchema[],
        toIndex: number
      ) => {
        let tableEndData = getTableEndData(field)

        existingFields.splice(toIndex + 1, 0, tableEndData)
      }

      /**
       * Adds the necessary field attributes to a field
       */
      const buildFormAttributes = (
        formField: RenderedFormFieldSchema,
        toIndex: number
      ): RenderedFormFieldSchema => {
        formField.field_attributes.push({
          form_field_id: formField.id,
          name: 'required',
          value: formField.field.system_name === 'override_date_input',
        })

        if (formField.field.system_name === 'inline_image') {
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'size',
            value: undefined,
          })
        }

        if (formField.field.has_min_max_fields) {
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'min',
            value: undefined,
          })

          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'max',
            value: undefined,
          })
        }

        if (
          formField.field.system_name !== 'table' &&
          addingBetweenTable(toIndex, renderedFormFields.value)
        ) {
          let table = getClosestTableField(toIndex)
          formField.parent_id = table ? table.id : null
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'in_table',
            value: true,
          })
        }

        if (
          formField.field.system_name !== 'table' &&
          !addingBetweenTable(toIndex, renderedFormFields.value)
        ) {
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'in_table',
            value: false,
          })
        }

        formField.field_attributes.push({
          form_field_id: formField.id,
          name: 'value',
          value: formField.field.system_name === 'system_list' ? 1 : null,
        })

        if (formField.field.system_name === 'yes_no') {
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'triggered_task_id',
            value: undefined,
          })
        }

        return formField
      }

      /**
       * Builds a form field from a field that has been dropped on the canvas.
       * Also validates fields placement.
       */
      const addField = (field: FieldDroppedEvent): void => {
        let formField = buildNewFormField(field.itemData)
        const existingFields = [...renderedFormFields.value]

        const errorMessage = validateFieldPlacement(
          formField.field,
          field.toIndex
        )

        if (errorMessage) {
          store.dispatch('genericStore/pushNotification', errorMessage)
          return
        }

        formField = buildFormAttributes(formField, field.toIndex)

        // Position the field in the correct place in the list
        existingFields.splice(field.toIndex, 0, formField)

        if (formField.field.system_name === 'table') {
          addTableEnd(formField, existingFields, field.toIndex)
        }

        renderedFormFields.value = existingFields
        return
      }

      /**
       * Loops back up the rendered fields to grab the 'closest' table field
       */
      const getClosestTableField = (
        fieldIndex: number
      ): RenderedFormFieldSchema | undefined => {
        let possibleFields = renderedFormFields.value
          .slice(0, fieldIndex)
          .reverse()

        for (var index = 0; index < fieldIndex; index++) {
          let formField = possibleFields[index]

          if (formField.field.system_name === 'table') {
            return formField
          }
        }

        return undefined
      }

      /**
       * Determines whether the field we are adding is going between a table.
       */
      const addingBetweenTable = (
        index: number,
        fields: RenderedFormFieldSchema[]
      ): boolean => {
        let inTable = false

        for (var i = 0; i < index; i++) {
          let formField = fields[i]
          let fieldName = formField.field.system_name

          if (fieldName === 'table') {
            inTable = true
          }

          if (fieldName === 'table_end') {
            inTable = false
          }
        }

        return inTable
      }

      /**
       * Validates whether we can add this field where we are trying to add it
       * returns an error message if invalid placement
       */
      const validateFieldPlacement = (
        field: Field,
        index: number
      ): string | undefined => {
        if (
          field.system_name === 'table' &&
          addingBetweenTable(index, renderedFormFields.value)
        ) {
          return 'Tables cannot be inserted into other tables'
        }

        if (
          field.system_name === 'multi_step_form_section' &&
          addingBetweenTable(index, renderedFormFields.value)
        ) {
          return 'Steps cannot be inserted into tables'
        }

        if (field.system_name === 'override_date_input') {
          if (hasOneOverrideDateAlready()) {
            return 'Only one override date field is allowed on a form.'
          }
        }

        if (field.system_name === 'signature_image') {
          if (hasOneSignatureAlready()) {
            return 'Only one signature field is allowed on a form.'
          }
        }

        return undefined
      }

      const hasOneOverrideDateAlready = ():
        | RenderedFormFieldSchema
        | undefined => {
        return renderedFormFields.value.find(
          (formField: RenderedFormFieldSchema) =>
            formField.field.system_name === 'override_date_input'
        )
      }

      const hasOneSignatureAlready = ():
        | RenderedFormFieldSchema
        | undefined => {
        return renderedFormFields.value.find(
          (formField: RenderedFormFieldSchema) =>
            formField.field.system_name === 'signature_image'
        )
      }

      /**
       * This is used in order to check a fields re-ordering is valid before we
       * actually re-order it. This returns a list of fields which has been 're-ordered'.
       */
      const simulateFieldReorder = (
        items: RenderedFormFieldSchema[],
        formField: RenderedFormFieldSchema,
        toIndex: number
      ): RenderedFormFieldSchema[] => {
        let existingFields = [...items]

        existingFields = existingFields.filter(
          (existingField: RenderedFormFieldSchema) =>
            formField.id !== existingField.id
        )

        existingFields.splice(toIndex, 0, formField)

        return existingFields
      }

      const updateFieldOrder = (fieldMoved: FieldReorderedEvent): void => {
        const formField = fieldMoved.component.getItemByIndex(
          fieldMoved.fromIndex
        ) as RenderedFormFieldSchema

        const existingFields = simulateFieldReorder(
          fieldMoved.component.option('items'),
          formField,
          fieldMoved.toIndex
        )

        const error = validateFieldMove(
          existingFields,
          formField,
          fieldMoved.toIndex
        )

        if (error) {
          store.dispatch('genericStore/pushNotification', error)
          return
        }

        fieldMoved.component.reorderItem(
          fieldMoved.fromIndex,
          fieldMoved.toIndex
        )
        renderedFormFields.value = fieldMoved.component.option('items')

        updateParentIds(fieldMoved.component.option('items'))
      }

      /**
       * Updates all field attributes and parent_id based on the whether they are inside a table
       */
      const updateParentIds = (items: RenderedFormFieldSchema[]): void => {
        renderedFormFields.value.forEach(
          (item: RenderedFormFieldSchema, index: number) => {
            let attribute = item.field_attributes.find(
              (attribute) => attribute.name === 'in_table'
            )

            if (addingBetweenTable(index, items)) {
              let table = getClosestTableField(index)
              item.parent_id = table ? table.id : null

              if (attribute) {
                attribute.value = true
              }
            } else {
              item.parent_id = null

              if (attribute) {
                attribute.value = false
              }
            }
          }
        )
      }

      /**
       * Used to check if a field can be re-ordered to where it would like to go.
       * Returns a string message if invalid move.
       */
      const validateFieldMove = (
        existingFields: RenderedFormFieldSchema[],
        formField: RenderedFormFieldSchema,
        toIndex: number
      ): string | undefined => {
        if (
          formField.field.system_name === 'table_end' ||
          formField.field.system_name === 'table'
        ) {
          if (addingBetweenTable(toIndex, existingFields)) {
            return 'Tables cannot be inserted into other tables'
          }
        }

        if (formField.field.system_name === 'table_end') {
          let tableStartIndex = existingFields.findIndex(
            (field: RenderedFormFieldSchema) => field.id === formField.parent_id
          )

          if (tableStartIndex >= toIndex) {
            return "A table's end cannot go before its start"
          }
        }

        if (formField.field.system_name === 'table') {
          let tableEndIndex = existingFields.findIndex(
            (field: RenderedFormFieldSchema) =>
              field.parent_id === formField.id &&
              field.field.system_name === 'table_end'
          )

          if (tableEndIndex <= toIndex) {
            return "A table's start cannot go after its end"
          }
        }

        if (
          formField.field.system_name === 'multi_step_form_section' &&
          addingBetweenTable(toIndex, existingFields)
        ) {
          return 'Steps cannot be moved inside tables'
        }

        return undefined
      }

      const mapExistingFieldsToCanvas = (fields: FormField[]): void => {
        let existingFields = [...renderedFormFields.value]

        fields
          .filter((field: FormField) => !field.parent_id)
          .forEach((field: FormField) => {
            if (field.field) {
              let formField = buildExistingFormField(field)

              if (field.children?.length) {
                formField.children = field.children
                existingFields.push(formField)

                let endData = getTableEndData(formField)

                // @ts-ignore - TS doesn't like pushing part of FormField
                field.children.push(endData)

                field.children.forEach((child: FormField) => {
                  existingFields.push(
                    buildExistingChildFormField(child, field.id)
                  )
                })
              } else {
                existingFields.push(formField)
              }
            }
          })

        renderedFormFields.value = existingFields
      }

      /**
       * These field attributes are required for a field
       */
      const addRequiredAttributesToExistingFields = (
        field: FormField,
        formField: RenderedFormFieldSchema
      ) => {
        let existingAttributeNames = field.field_attributes?.map(
          (att) => att.name
        )

        if (!existingAttributeNames?.includes('in_table')) {
          formField.field_attributes.push({
            form_field_id: field.id,
            name: 'in_table',
            value: false,
          })
        }

        if (!existingAttributeNames?.includes('value')) {
          formField.field_attributes.push({
            form_field_id: field.id,
            name: 'value',
            value: field.field?.system_name === 'system_list' ? 1 : null,
          })
        }

        if (!existingAttributeNames?.includes('required')) {
          formField.field_attributes.push({
            form_field_id: field.id,
            name: 'required',
            value: field.field?.system_name === 'override_date_input',
          })
        }

        if (field.field?.system_name === 'yes_no') {
          if (!existingAttributeNames?.includes('triggered_task_id')) {
            formField.field_attributes.push({
              form_field_id: field.id,
              name: 'triggered_task_id',
              value: null,
            })
          }
        }

        if (
          field.field?.system_name === 'inline_image' &&
          !existingAttributeNames?.includes('size')
        ) {
          formField.field_attributes.push({
            form_field_id: formField.id,
            name: 'size',
            value: null,
          })
        }

        if (
          field.field?.has_min_max_fields &&
          !existingAttributeNames?.includes('min')
        ) {
          formField.field_attributes.push({
            form_field_id: field.id,
            name: 'min',
            value: null,
          })
        }

        if (
          field.field?.has_min_max_fields &&
          !existingAttributeNames?.includes('max')
        ) {
          formField.field_attributes.push({
            form_field_id: field.id,
            name: 'max',
            value: null,
          })
        }

        return formField
      }

      /**
       * This changes a FormField on the backend to a RenderedFormFieldSchema
       */
      const buildExistingFormField = (field: FormField) => {
        let formField = buildNewFormField(field.field!)
        formField.id = field.id
        formField.name = field.name
        formField.description = field.description
        formField.instructions = field.instructions
        formField.field_attributes = field.field_attributes || []

        formField.has_changed = false

        formField = addRequiredAttributesToExistingFields(field, formField)

        formField.options = field.options || []

        return formField
      }

      /**
       * Used to build a tables child form field
       */
      const buildExistingChildFormField = (
        child: FormField,
        fieldId: string | number
      ): RenderedFormFieldSchema => {
        let childFormField = buildNewFormField(child.field!)
        childFormField.parent_id = child.parent_id!
        childFormField.id = child.id
        childFormField.name = child.name
        childFormField.field_attributes = child.field_attributes || []
        childFormField.field_attributes.push({
          form_field_id: fieldId,
          name: 'in_table',
          value: true,
        })

        childFormField = addRequiredAttributesToExistingFields(
          child,
          childFormField
        )

        childFormField.options = child.options || []

        return childFormField
      }

      const forms = computed(() => {
        if (store.getters['forms/forms'].length) {
          return store.getters['forms/forms'].map((form: Form) => {
            return {
              label: form.name,
              value: form.id,
            }
          })
        }

        return []
      })

      const keywords = computed(() => {
        if (store.getters['keywords/getKeywords'].length) {
          return store.getters['keywords/getKeywords'].map(
            (keyword: FormKeyword) => {
              return {
                label: keyword.name,
                value: keyword.id,
              }
            }
          )
        }

        return []
      })

      const systemListValues = computed(() => {
        if (store.getters['forms/getSystemLists'].length) {
          return store.getters['forms/getSystemLists'].map(
            (systemList: SystemList) => {
              return {
                label: systemList.name,
                value: systemList.id,
              }
            }
          )
        }

        return []
      })

      /**
       * Used in move mode. Gets the right indexes and uses the update method to re-order.
       */
      const dropItemBefore = (item: RenderedFormFieldSchema): void => {
        if (!itemBeingMoved.value) {
          return
        }

        let fromIndex = renderedFormFields.value.findIndex(
          (field: RenderedFormFieldSchema) =>
            field.id === itemBeingMoved.value?.id
        )

        let toIndex = renderedFormFields.value.findIndex(
          (field: RenderedFormFieldSchema) => field.id === item.id
        )

        if (fromIndex === toIndex) {
          return
        }

        let event = {
          fromIndex: fromIndex,
          toIndex: toIndex,
          component: fieldCanvas.value?.instance,
        } as FieldReorderedEvent

        updateFieldOrder(event)

        itemBeingMoved.value = undefined

        return
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        renderedFormFields.value = []
        if (props.fields && props.fields.length) {
          mapExistingFieldsToCanvas(props.fields)
        }

        Promise.all([
          store.dispatch('keywords/index', {}),
          store.dispatch('forms/index', {}),
          store.dispatch('forms/getSystemLists', {}),
        ]).finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        updateFieldOrder,
        addField,
        renderedFormFields,
        deleteField,
        keywords,
        forms,
        fieldCanvas,
        enterMoveMode,
        itemBeingMoved,
        dropItemBefore,
        systemListValues,
      }
    },
  })
