import { EnComponents } from "@/locales/messages/components";

export default {
    ypRelationships: {
        header: 'Relationships',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                name: EnComponents.grid.headers.name,
                gender: EnComponents.grid.headers.gender,
                relation: 'Relation',
                allowedContact: 'Allowed Contact',
                reasonForNoContact: 'Reason For No Contact'
            }
        }
    }
}