<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('employeeDetails.employmentDetails.jobTitle')"
          v-model="employment_details.job_title"
        />
        <select-input
          :label="t('employeeDetails.employmentDetails.employmentType')"
          v-model="employment_details.employment_type"
          :options="[
            { label: 'None', value: 1 },
            { label: 'Agency', value: 2 },
            { label: 'Contract', value: 3 },
            { label: 'Permanent', value: 4 },
            { label: 'Temporary', value: 5 },
          ]"
        />
        <select-input
          :label="t('employeeDetails.employmentDetails.contractType')"
          v-model="employment_details.contract_type"
          :options="[
            { label: 'None', value: 1 },
            { label: 'Agency', value: 2 },
            { label: 'Contract', value: 3 },
            { label: 'Permanent', value: 4 },
            { label: 'Temporary', value: 5 },
          ]"
        />

        <text-input
          :label="t('employeeDetails.employmentDetails.currentGrading')"
          v-model="employment_details.current_grading"
        />
        <text-input
          :label="t('employeeDetails.employmentDetails.contractingHours')"
          v-model="employment_details.contract_hours"
        />
        <text-input
          :label="t('employeeDetails.employmentDetails.NiNum')"
          v-model="employment_details.ni_number"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('employeeDetails.employmentDetails.prevExperience')"
          v-model="employment_details.previous_experience"
        />
        <radio-button
          :label="t('employeeDetails.employmentDetails.probation')"
          layout="horizontal"
          v-model="employment_details.on_probation"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
        <select-input
          :label="t('employeeDetails.employmentDetails.administrator')"
          v-model="employment_details.administrator"
          :options="[
            { label: 'None', value: 1 },
            { label: 'Agency', value: 2 },
            { label: 'Contract', value: 3 },
            { label: 'Permanent', value: 4 },
            { label: 'Temporary', value: 5 },
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      DateInput,
      SelectInput,
      RadioButton,
    },
    setup() {
      const { t } = useI18n()
      let employment_details = {
        job_title: 'Class Teacher Languages',
        employment_type: 'Agency',
        contract_type: 'Full Time',
        current_grading: '5',
        contract_hours: '40',
        ni_number: 'XXXXXXXXX',
        on_probation: false,
        administrator: 'James',
      }

      return {
        employment_details,
        t,
      }
    },
  })
</script>
