import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_ctx.selectedAssociations?.length)
    ? (_openBlock(), _createBlock(_component_data_grid, {
        key: 0,
        columns: _ctx.columns,
        action: "projectAssociations/getSelectedAssociations",
        "show-export": false,
        "allow-selection": false,
        "allow-grouping": false,
        "remote-operations": {}
      }, {
        "toolbar-primary-button": _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: _ctx.t('components.buttons.delete'),
            type: "danger-outline",
            icon: "fa fa-trash",
            onClick: _ctx.deleteAssociations
          }, null, 8, ["label", "onClick"])
        ]),
        "toolbar-addon": _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: _ctx.t('components.buttons.back'),
            type: "tertiary",
            icon: "fa fa-arrow-left",
            "secondary-icon": "none",
            onClick: _ctx.goBack
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["columns"]))
    : _createCommentVNode("", true)
}