
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { reactive, PropType, defineComponent, computed, Ref, ref } from 'vue'
  import { ExportOption } from '@/types/components/Export'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { renderCellLink } from '@/composables/Grids'
  import CcButton from '../Button/Button.vue'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      action: {
        type: String,
        required: false,
        default: '',
      },
      createView: {
        type: String,
        required: true,
      },
      editView: {
        type: String,
        required: true,
      },
      deleteView: {
        type: String,
        required: true,
      },
      restoreView: {
        type: String,
        required: true,
      },
      reorderAction: {
        type: String,
        required: true,
      },
      deleteManyAction: {
        type: String,
        required: true,
      },
      toggleVisibilityAction: {
        type: String,
        required: true,
      },
      referenceOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      remoteOperations: {
        type: Object,
        default: () => {
          return {
            grouping: false,
            paging: true,
            groupPaging: false,
            filtering: true,
            sorting: true,
          }
        },
      },
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
      exportOptions: {
        type: Array as PropType<ExportOption[]>,
        required: false,
        default: () => [
          { name: 'Export To PDF', icon: 'fas fa-file-pdf' },
          { name: 'Export To Excel', icon: 'fas fa-file-excel' },
        ],
      },
      chips: {
        type: Array as PropType<string[]>,
        required: false,
      },
      defaultChip: {
        type: String,
        required: false,
      },
      queryFilter: {
        type: Object as PropType<any>,
        required: false,
      },
      gridHeading: {
        type: String,
        required: false,
        default: '',
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      toggleLabel: {
        type: String,
        required: false,
        default: null,
      },
      showGridToolbar: {
        type: Boolean,
        required: false,
        default: true,
      },
      visibleChipCount: {
        type: Number,
        required: false,
        default: 4,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const itemBeingMoved = ref() as Ref<any | undefined>
      const key = ref(0) as Ref<number>
      const isMoveMode = computed(() => (itemBeingMoved.value ? true : false))
      const initMoveMode = (data: any) => (itemBeingMoved.value = data)
      const cancelMoveMode = () => (itemBeingMoved.value = undefined)

      const state = reactive({
        extraOptions: ['View Deleted Records'],
        selectedRows: [] as any[],
      })

      const viewDeletedRecords = () => {
        router.push({ name: props.restoreView })
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          deleteSelectedRecords()
        }

        if (option === 'Toggle Visibility') {
          toggleSelectedRecordsVisibility()
        }

        if (option === 'View Deleted Records') {
          viewDeletedRecords()
        }

        return
      }

      const toggleSelectedRecordsVisibility = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let rows = state.selectedRows

        store
          .dispatch(props.toggleVisibilityAction, {
            ids: rows.map((row: { id: number }) => row.id),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            state.selectedRows = []
            key.value++
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let rows = state.selectedRows

        let errors = [] as string[]

        rows = rows.filter(
          (row: { id: number; is_system: number; name: string }) => {
            if (row.is_system) {
              errors.push(row.name)
            }

            return row.is_system !== 1
          }
        )

        store.dispatch('genericStore/setLookupsToBeDeleted', rows).then(() => {
          store.dispatch('genericStore/showPageLoader', false)
          router.push({
            name: props.deleteView,
            params: { deleteManyAction: props.deleteManyAction },
          })
          state.selectedRows = []
        })
      }

      const reorderItems = (to: any) => {
        store.dispatch('genericStore/showPageLoader', true)

        if (itemBeingMoved.value.order === to.order) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            'Moving an item to the same place has no effect'
          )
          return
        }

        store
          .dispatch(props.reorderAction, {
            start_order: itemBeingMoved.value.order,
            end_order: to.order,
          })
          .then((response) => {
            itemBeingMoved.value = undefined
            key.value++
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const addClicked = () => router.push({ name: props.createView })

      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              options.data.name,
              props.editView,
              {
                id: options.data?.id,
              },
              undefined,
              props.readOnly ? true : Boolean(options.data.is_system)
            )
          },
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
        {
          caption: 'Move',
          allowGrouping: false,
          allowExporting: false,
          cellTemplate: 'LookupGridHandleTemplate',
        },
      ]

      const rowsSelected = (rows: any[]) => {
        state.selectedRows = rows

        if (rows.length && !state.extraOptions.includes('Delete')) {
          state.extraOptions.push('Delete')
        }

        if (!rows.length && state.extraOptions.includes('Delete')) {
          state.extraOptions.splice(state.extraOptions.indexOf('Delete'), 1)
        }

        if (
          rows.length &&
          !state.extraOptions.includes('Toggle Visibility') &&
          !props.readOnly
        ) {
          state.extraOptions.push('Toggle Visibility')
        }

        if (
          !rows.length &&
          state.extraOptions.includes('Toggle Visibility') &&
          !props.readOnly
        ) {
          state.extraOptions.splice(
            state.extraOptions.indexOf('Toggle Visibility'),
            1
          )
        }
      }

      return {
        state,
        columns,
        initMoveMode,
        itemBeingMoved,
        reorderItems,
        isMoveMode,
        optionClicked,
        addClicked,
        rowsSelected,
        key,
        cancelMoveMode,
      }
    },
  })
