import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filing_cabinet = _resolveComponent("filing-cabinet")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_task_attachments = _resolveComponent("task-attachments")!
  const _component_user_permissions = _resolveComponent("user-permissions")!
  const _component_vehicle_details = _resolveComponent("vehicle-details")!
  const _component_project_associations = _resolveComponent("project-associations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('filing-cabinet'))
      ? (_openBlock(), _createBlock(_component_filing_cabinet, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('vehicle-attachments'))
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 1,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('log-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 2,
          "project-id": _ctx.id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('user-permissions') && _ctx.vehicle)
      ? (_openBlock(), _createBlock(_component_user_permissions, {
          key: 3,
          "project-id": _ctx.vehicle.project_id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('details') && _ctx.vehicle)
      ? (_openBlock(), _createBlock(_component_vehicle_details, {
          key: 4,
          vehicle: _ctx.vehicle
        }, null, 8, ["vehicle"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('associations') && _ctx.vehicle?.project)
      ? (_openBlock(), _createBlock(_component_project_associations, {
          key: 5,
          "instance-id": _ctx.vehicle.id,
          project: _ctx.vehicle.project
        }, null, 8, ["instance-id", "project"]))
      : _createCommentVNode("", true)
  ]))
}