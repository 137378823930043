import { ActionTree, GetterTree, MutationTree } from 'vuex'
import EmployeeService from '@/services/projects/employees/employees'
import { Employee } from '@/types/modules/projects/Employee'
import { TaskLog } from '@/types/modules/tasks/TaskLog'
import { EmployeeQualification } from '@/types/modules/projects/EmployeeQualification'
import { Project } from '@/types/modules/projects/Project'
import { UserProjectPermission } from '@/types/modules/users/UserProjectPermission'
import { QualificationType } from '@/types/modules/projects/QualificationType'

export type EmployeeGetter = GetterTree<EmployeeState, any>

export interface EmployeeState {
  employees: Employee[]
  employee: Employee | null
  logs: TaskLog[]
  associations: Project[]
  qualifications: EmployeeQualification[]
  sensitiveQualificationAttachments: object[]
  sensitiveRecruitmentAttachments: object[]
  qualificationTypes: QualificationType[]
  projectPermissions: UserProjectPermission[]
  employeeReferences: object[]
}

// Change once backend connection setup
export const state: EmployeeState = {
  employees: [],
  employee: null,
  logs: [],
  associations: [],
  qualifications: [],
  sensitiveQualificationAttachments: [],
  sensitiveRecruitmentAttachments: [],
  qualificationTypes: [],
  projectPermissions: [],
  employeeReferences: [],
}

export const getters: EmployeeGetter = {
  allEmployees: (state) => state.employees,
  employee: (state) => state.employee,
  employeeLogs: (state) => state.logs,
  employeeAssociations: (state) => state.associations,
  sensitiveQualificationAttachments: (state) =>
    state.sensitiveQualificationAttachments,
  sensitiveRecruitmentAttachments: (state) =>
    state.sensitiveRecruitmentAttachments,
  qualifications: (state) => state.qualifications,
  qualificationTypes: (state) => state.qualificationTypes,
  projectPermissions: (state) => state.projectPermissions,
  employeeReferences: (state) => state.employeeReferences,
}

export const actions: ActionTree<EmployeeState, any> = {
  index: async (context, params) => {
    const response = await EmployeeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setEmployees', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setEmployees', response.data)

      return response
    }

    return response
  },
  show: (context, id) => {
    let results = Promise.resolve(EmployeeService.show(id))

    results.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setEmployee', response.data)
      }
    })

    return results
  },
  getQualifications: async (context, params) => {
    const response = await EmployeeService.getQualifications(params)

    if (response.success && params.per_page) {
      context.commit('setQualifications', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setQualifications', response.data)

      return response
    }

    return response
  },
  getEmployeeReferences: async (context, params) => {
    const response = await EmployeeService.getEmployeeReferences(params)

    if (response.success && params.per_page) {
      context.commit('setEmployeeReferences', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }
    if (response.success && !params.per_page) {
      context.commit('setEmployeeReferences', response.data)

      return response
    }

    return response
  },
  sensitiveQualificationAttachments: (context, params) => {
    let results = Promise.resolve(
      EmployeeService.sensitiveQualificationAttachments(params)
    )

    results.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setSensitiveQualificationAttachments', response.data)
      }
    })
  },
  sensitiveRecruitmentAttachments: (context, params) => {
    let results = Promise.resolve(
      EmployeeService.sensitiveRecruitmentAttachments(params)
    )

    results.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setSensitiveRecruitmentAttachments', response.data)
      }
    })
  },
}

export const mutations: MutationTree<EmployeeState> = {
  setSensitiveQualificationAttachments: (
    state,
    sensitiveQualificationAttachments
  ) => {
    state.sensitiveQualificationAttachments = sensitiveQualificationAttachments
  },
  setSensitiveRecruitmentAttachments: (
    state,
    sensitiveRecruitmentAttachments
  ) => {
    state.sensitiveRecruitmentAttachments = sensitiveRecruitmentAttachments
  },
  setEmployees: (state, employees) => {
    state.employees = employees
  },
  setEmployee: (state, employee) => (state.employee = employee),
  setQualifications: (state, qualifications: EmployeeQualification[]) => {
    state.qualifications = qualifications
  },
  setQualificationTypes: (state, qualificationTypes: QualificationType[]) => {
    state.qualificationTypes = qualificationTypes
  },
  setProjectPermissions: (
    state,
    projectPermissions: UserProjectPermission[]
  ) => {
    state.projectPermissions = projectPermissions
  },
  setEmployeeReferences: (state, employeeReferences) => {
    state.employeeReferences = employeeReferences
  },
}

export const employees = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
