
  import { Dbs } from '@/types/modules/staffs/Dbs'
  import { OverseasCriminalRecord } from '@/types/modules/staffs/OverseasCriminalRecord'
  import { ProofOfIdentity } from '@/types/modules/staffs/ProofOfIdentity'
  import { RightToWork } from '@/types/modules/staffs/RightToWork'
  import { defineComponent, PropType, reactive } from 'vue'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<
          Dbs | ProofOfIdentity | OverseasCriminalRecord | RightToWork
        >,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
