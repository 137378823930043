import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end" }
const _hoisted_2 = { class: "border-r" }
const _hoisted_3 = { class: "font-display w-full p-8 shadow-lg rounded-md border border-gray-100 my-8" }
const _hoisted_4 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_cc_switch = _resolveComponent("cc-switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_cc_button, {
              icon: "fas fa-arrow-left",
              "secondary-icon": "none",
              type: "tertiary",
              label: _ctx.t('components.buttons.back'),
              onClick: _ctx.goBack
            }, null, 8, ["label", "onClick"])
          ]),
          _createVNode(_component_cc_button, {
            type: "primary",
            label: _ctx.t('components.buttons.save'),
            icon: "fas fa-save",
            onButtonClicked: _ctx.saveChanges
          }, null, 8, ["label", "onButtonClicked"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_text_input, {
          label: _ctx.t('mars.create.medication.name'),
          modelValue: _ctx.payload.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event)),
          error: _ctx.errors?.name,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errors?.name ? (_ctx.errors.name = undefined) : ''))
        }, null, 8, ["label", "modelValue", "error"]),
        _createVNode(_component_select_input, {
          label: _ctx.t('mars.create.medication.stockType'),
          options: _ctx.stockTypes,
          "is-required": true,
          modelValue: _ctx.payload.medication_stock_type_id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.medication_stock_type_id) = $event)),
          error: _ctx.errors?.medication_stock_type_id,
          onChange: _cache[3] || (_cache[3] = ($event: any) => (
          _ctx.errors?.medication_stock_type_id
            ? (_ctx.errors.medication_stock_type_id = undefined)
            : ''
        ))
        }, null, 8, ["label", "options", "modelValue", "error"]),
        _createVNode(_component_select_input, {
          label: _ctx.t('mars.create.medication.stockCategory'),
          options: _ctx.stockCategories,
          "is-required": true,
          modelValue: _ctx.payload.medication_stock_category_id,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.medication_stock_category_id) = $event)),
          error: _ctx.errors?.medication_stock_category_id,
          onChange: _cache[5] || (_cache[5] = ($event: any) => (
          _ctx.errors?.medication_stock_category_id
            ? (_ctx.errors.medication_stock_category_id = undefined)
            : ''
        ))
        }, null, 8, ["label", "options", "modelValue", "error"]),
        _createVNode(_component_cc_switch, {
          label: _ctx.t('mars.create.medication.requiresAuth'),
          modelValue: _ctx.payload.requires_auth,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.requires_auth) = $event))
        }, null, 8, ["label", "modelValue"])
      ])
    ])
  ], 64))
}