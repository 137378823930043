import { ActionTree, GetterTree, MutationTree } from 'vuex'
import ProjectAssociationsService from '@/services/projects/projectAssociations'
import { ProjectAssociation } from '@/types/modules/projects/ProjectAssociation'

export type ProjectAssociationsGetter = GetterTree<
  ProjectAssociationsState,
  any
>

export interface ProjectAssociationsState {
  projectAssociations: ProjectAssociation[]
  selectedAssociations: ProjectAssociation[]
}

export const state: ProjectAssociationsState = {
  projectAssociations: [],
  selectedAssociations: [],
}

export const getters: ProjectAssociationsGetter = {
  projectAssociations: (state) => state.projectAssociations,
  selectedAssociations: (state) => state.selectedAssociations,
}

export const actions: ActionTree<ProjectAssociationsState, any> = {
  index: async (context, params) => {
    const response = await ProjectAssociationsService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setProjectAssociations', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setProjectAssociations', response.data)

      return response
    }

    return response
  },
  update: async (context, payload) => {
    const response = await ProjectAssociationsService.update(payload)
    if (response.success) {
      context.commit('setProjectAssociations', response.data)
    }
    return response
  },
  store: async (context, payload) => {
    const response = await ProjectAssociationsService.store(payload)
    if (response.success) {
      context.commit('setProjectAssociations', response.data)
    }
    return response
  },
  setSelectedAssociations: (context, associations) => {
    context.commit('setSelectedAssociations', associations)
  },
  getSelectedAssociations: (context) => {
    return {
      data: context.getters.selectedAssociations,
      totalCount: context.getters.selectedAssociations.length,
    }
  },
  deleteMany: async (context, payload) => {
    const response = await ProjectAssociationsService.deleteMany(payload)
    if (response.success) {
      context.commit('setProjectAssociations', response.data)
    }
    return response
  },
  restoreMany: async (context, payload) => {
    const response = await ProjectAssociationsService.restoreMany(payload)
    if (response.success) {
      context.commit('setProjectAssociations', response.data)
    }
    return response
  },
}

export const mutations: MutationTree<ProjectAssociationsState> = {
  setProjectAssociations: (state, associations: ProjectAssociation[]) =>
    (state.projectAssociations = associations),
  setSelectedAssociations: (state, associations: ProjectAssociation[]) =>
    (state.selectedAssociations = associations),
}

export const projectAssociations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
