
  import { computed, defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    emits: ['permission-updated'],
    components: {
      ButtonGroup,
      CcButton,
    },
    props: {
      permission: {
        type: Number,
        required: false,
        default: 0,
      },
      type: {
        type: String,
        required: false,
        default: 'access',
        validator: (value: string) => {
          return ['access', 'binary'].includes(value)
        },
      },
    },
    setup(props, { emit }) {
      const state = reactive({
        permission: props.permission,
      })

      const updatePermission = (value: number) => {
        state.permission = value
        emit('permission-updated', value)
      }

      const buttons = computed(() => {
        if (props.type.toLowerCase() === 'access') {
          return [
            { label: 'Read Write', value: -1 },
            { label: 'Read Only', value: 1 },
            { label: 'No Access', value: 0 },
          ]
        }
        return [
          { label: 'True', value: 1 },
          { label: 'False', value: 0 },
        ]
      })

      return {
        state,
        updatePermission,
        buttons,
      }
    },
  })
