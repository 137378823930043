import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end mb-4 font-display" }
const _hoisted_2 = { class: "h-max border border-gray-100 p-6 shadow-md space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_reaction_input = _resolveComponent("reaction-input")!
  const _component_task_log_comment_table = _resolveComponent("task-log-comment-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: "back",
            type: "tertiary",
            icon: "fas fa-arrow-left",
            onClick: _ctx.goBack
          }, null, 8, ["onClick"]),
          _createVNode(_component_cc_button, {
            label: "Save changes",
            type: "success",
            icon: "fa fa-save",
            onClick: _ctx.saveChanges
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: "Summary",
        modelValue: _ctx.payload.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event)),
        error: _ctx.errors?.name,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errors?.name ? (_ctx.errors.name = undefined) : ''))
      }, null, 8, ["modelValue", "error"]),
      _createVNode(_component_text_input, {
        label: "Comment",
        modelValue: _ctx.payload.comment,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.comment) = $event)),
        error: _ctx.errors?.comment,
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.errors?.comment ? (_ctx.errors.comment = undefined) : ''))
      }, null, 8, ["modelValue", "error"]),
      (!_ctx.isYoungPerson)
        ? (_openBlock(), _createBlock(_component_multi_select, {
            key: 0,
            "is-required": false,
            "display-expr": 'text',
            "value-expr": 'value',
            label: "Notifications",
            "tag-items": _ctx.users,
            modelValue: _ctx.payload.users,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.users) = $event))
          }, null, 8, ["tag-items", "modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.isYoungPerson)
        ? (_openBlock(), _createBlock(_component_reaction_input, {
            key: 1,
            label: "Reaction",
            "is-required": false,
            modelValue: _ctx.payload.reaction,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.reaction) = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isYoungPerson)
      ? (_openBlock(), _createBlock(_component_task_log_comment_table, {
          key: 0,
          "task-id": Number(_ctx.props.taskId),
          "task-log-id": Number(_ctx.props.taskLogId)
        }, null, 8, ["task-id", "task-log-id"]))
      : _createCommentVNode("", true)
  ], 64))
}