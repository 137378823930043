import { GetterTree, MutationTree, ActionTree } from 'vuex'
import RegionsService from '@/services/regions/regions'
import { Region } from '@/types/modules/regions/Region'

export type RegionGetter = GetterTree<RegionState, any>

export interface RegionState {
  regions: Region[]
  region: Region | null
}

export const state: RegionState = {
  regions: [],
  region: null,
}

export const getters: RegionGetter = {
  regions: (state) => state.regions,
  region: (state) => state.region,
}

export const actions: ActionTree<RegionState, any> = {
  index: async (context, params) => {
    const response = await RegionsService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setRegions', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setRegions', response.data)

      return response
    }

    return response
  },
  show: async (context, params) => {
    let response = await RegionsService.show(params)
    if (response.success) {
      context.commit('setRegion', response.data)
    }
    return response
  },

  deleteMany: async (_context, params) => {
    return await RegionsService.deleteMany(params)
  },
  restoreMany: async (_context, params) => {
    return await RegionsService.restoreMany(params)
  },
  update: async (context, params) => {
    const response = await RegionsService.update(params)
    if (response.success) {
      context.commit('setRegion', response.data)
    }
    return response
  },
  store: async (context, payload) => {
    const response = await RegionsService.store(payload)

    if (response.success) {
      context.commit('setRegion', response.data)
    }

    return response
  },
  getSelectedRegions: (context) => {
    const response = context.getters['regions']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  setSelectedRegions: (context, region: Region[]) => {
    context.commit('setRegions', region)
  },
}

export const mutations: MutationTree<RegionState> = {
  setRegions: (state, regions: Region[]) => {
    state.regions = regions
  },
  setRegion: (state, region: Region) => {
    state.region = region
  },
}

export const regions = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
