import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center font-display" }
const _hoisted_2 = { class: "font-semibold text-sm mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_cc_avatar, {
        url: _ctx.data.image?.thumbnail
      }, null, 8, ["url"]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.data.name), 1)
    ])
  ]))
}