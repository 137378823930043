
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import { defineComponent, PropType, reactive, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { getApiUrl } from '@/composables/Generic'

  export default defineComponent({
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
      },
    },
    setup(props) {
      props = reactive(props)
      const route = useRoute()

      const state = reactive({
        module: route.query.template_id ? 'shared' : 'form',
        placeholder: '/placeholder.jpg',
        src: '',
        size: props.fieldAttributes?.size || 'small',
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-400': true,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const isDataURL = (value: string) => {
        return !!value.match(
          /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
        )
      }

      const setFileSrc = (event: File) => {
        let fileReader = new FileReader()

        fileReader.onload = () => {
          if (fileReader.result && typeof fileReader.result == 'string') {
            state.src = fileReader.result
          } else {
            state.src = state.placeholder
          }
        }
        fileReader.readAsDataURL(event)
      }

      const getImage = computed(() => {
        if (Array.isArray(props.fieldAttributes?.value)) {
          setFileSrc(props.fieldAttributes?.value[0])
        }

        if (typeof props.fieldAttributes?.value === 'string') {
          if (isDataURL(props.fieldAttributes?.value)) {
            state.src = props.fieldAttributes?.value
          } else {
            try {
              let files = JSON.parse(props.fieldAttributes?.value)

              files.forEach((file: any) => {
                state.src =
                  getApiUrl() +
                  `/files/view?file_id=${file.fileID}&module=${state.module}`
              })
            } catch (e) {
              state.src = props.fieldAttributes?.value
            }
          }
        }

        return state.src
      })

      return {
        state,
        getImage,
        labelClasses,
        props,
      }
    },
  })
