import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.headIconClasses)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass([_ctx.icon, 'fa-fw'])
    }, null, 2)
  ], 2))
}