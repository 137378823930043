
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, reactive, computed, watch } from 'vue'
  import { isTab } from '@/composables/Generic'
  import RegionDetails from './partials/RegionDetails.vue'
  import RegionHomes from './partials/RegionHomes.vue'
  import RegionSchools from './partials/RegionSchools.vue'
  import RegionYoungPeople from './partials/RegionYoungPeople.vue'
  import RegionEmployees from './partials/RegionEmployees.vue'
  import RegionVehicles from './partials/RegionVehicles.vue'
  import RegionForms from './partials/RegionForms.vue'
  import RegionProcedures from './partials/RegionProcedures.vue'
  import RegionUsers from './partials/RegionUsers.vue'
  import { Region } from '@/types/modules/regions/Region'

  export default defineComponent({
    components: {
      RegionDetails,
      RegionHomes,
      RegionSchools,
      RegionYoungPeople,
      RegionEmployees,
      RegionVehicles,
      RegionForms,
      RegionProcedures,
      RegionUsers,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const region = computed((): Region => store.getters['regions/region'])

      const tabs = [
        { name: 'Details', url: `${route.path}?tab=details` },
        { name: 'Homes', url: `${route.path}?tab=homes` },
        { name: 'Schools', url: `${route.path}?tab=schools` },
        { name: 'Young People', url: `${route.path}?tab=young-people` },
        { name: 'Employees', url: `${route.path}?tab=employees` },
        { name: 'Vehicles', url: `${route.path}?tab=vehicles` },
        { name: 'Forms', url: `${route.path}?tab=forms` },
        { name: 'Procedures', url: `${route.path}?tab=procedures` },
        { name: 'Users', url: `${route.path}?tab=users` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/dashboard' },
          { name: 'Regions', url: '/regions' },
        ]

        if (!region.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: region.value ? region.value?.name! : '',
          url: `/regions/${region.value.id}?tab=details`,
        })
        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }
        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'details' } })
        }
        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('genericStore/showPageLoader', true)
        store.dispatch('regions/show', { id: props.id }).finally(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        props,
        region,
        isTab,
      }
    },
  })
