import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "border-2 rounded-lg p-6 border-gray-200 mt-4 transition-all"
}
const _hoisted_3 = { class: "space-y-6" }
const _hoisted_4 = { class: "w-full text-right mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center", _ctx.triggerConfigMessage ? 'justify-between' : 'justify-end'])
    }, [
      (_ctx.triggerConfigMessage)
        ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(_ctx.triggerConfigMessage), 1))
        : _createCommentVNode("", true),
      (_ctx.triggerIsConfigurable && !_ctx.configToggle)
        ? (_openBlock(), _createBlock(_component_cc_button, {
            key: 1,
            class: "my-2",
            type: "secondary",
            label: "configure trigger",
            onClick: _ctx.toggleConfig
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.configToggle && _ctx.triggerIsConfigurable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_select_input, {
              label: "Select Project Type",
              "is-required": true,
              options: _ctx.types,
              modelValue: _ctx.projectType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.projectType) = $event))
            }, null, 8, ["options", "modelValue"]), [
              [_vShow, !_ctx.isProjectTypeFieldDisabled]
            ]),
            _createVNode(_component_select_input, {
              label: `Select ${_ctx.projectType}`,
              "is-required": true,
              options: _ctx.projects,
              modelValue: _ctx.payload.project_id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.project_id) = $event)),
              "is-disabled": _ctx.isProjectFieldDisabled,
              error: 
          _ctx.config.form?.always_trigger_for_same_project !== 1 &&
          !_ctx.payload.project_id
            ? 'This field is required'
            : undefined
        
            }, null, 8, ["label", "options", "modelValue", "is-disabled", "error"]),
            (_ctx.config.form?.trigger_has_date)
              ? (_openBlock(), _createBlock(_component_date_input, {
                  key: 0,
                  label: "Select Trigger Date",
                  icons: [{ position: 'before', icon: 'fas fa-calendar' }],
                  min: _ctx.format(new Date(), 'yyyy-MM-dd'),
                  modelValue: _ctx.payload.trigger_date,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.trigger_date) = $event))
                }, null, 8, ["min", "modelValue"]))
              : _createCommentVNode("", true),
            _createVNode(_component_text_input, {
              "is-disabled": true,
              label: "Task to be triggered",
              "model-value": _ctx.props.config.triggered_form?.name
            }, null, 8, ["model-value"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_cc_button, {
              label: 'close',
              type: "primary",
              onButtonClicked: _ctx.closeConfig
            }, null, 8, ["onButtonClicked"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}