
  import { defineComponent, reactive, PropType, computed } from 'vue'
  import DxTagBox from 'devextreme-vue/tag-box'
  import { MultiSelect } from '@/types/components/MultiSelect'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      DxTagBox,
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      tagItems: {
        type: Array as PropType<MultiSelect[]>,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      error: {
        type: String,
        required: false,
      },
      modelValue: {
        type: Array,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.isDisabled && !props.error,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const inputClasses = computed(() => ({
        'bg-white': true,
        'transition-all': true,
        'p-2': true,
        'w-full': true,
        'border-b-2': true,
        'border-gray-400': true,
      }))
      const handleInput = (event: any) => {
        if (event) {
          emit('update:modelValue', event.value)
        }
      }

      const hasErrors = computed(() => {
        return props.error ? true : false
      })

      return {
        props,
        inputClasses,
        hasErrors,
        handleInput,
        labelClasses,
      }
    },
  })
