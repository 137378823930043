
  import {
    Vehicle,
    VehicleOwnershipPayload,
  } from '@/types/modules/projects/Vehicle'
  import format from 'date-fns/format'
  import { defineComponent, PropType, reactive, onMounted, watch } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
      DateInput,
      TextBox,
    },
    props: {
      vehicle: {
        type: Object as PropType<Vehicle>,
        required: false,
      },
      errors: {
        type: Object as PropType<Record<string, string>>,
        required: false,
        default: () => {},
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      props = reactive(props)
      const { t } = useI18n()
      const payload = reactive({
        insurance_date: undefined,
        ownership: 'Purchased',
        lease_start: undefined,
        lease_end: undefined,
        purchase_price: undefined,
        purchase_date: undefined,
        start_date: undefined,
        end_date: undefined,
        description: undefined,
        balloon: undefined,
      }) as VehicleOwnershipPayload

      watch(payload, () => {
        emit('update-payload', payload)
      })

      const buttons = [
        { id: 'Purchased', text: 'Purchased' },
        { id: 'Leased', text: 'Leased' },
      ]

      onMounted(() => {
        if (props.vehicle) {
          payload.insurance_date = props.vehicle.insurance_date
            ? format(new Date(props.vehicle.insurance_date), 'yyyy-MM-dd')
            : undefined

          payload.ownership = props.vehicle.ownership as string
          payload.lease_start = props.vehicle.lease_start
            ? format(new Date(props.vehicle.lease_start), 'yyyy-MM-dd')
            : undefined
          payload.lease_end = props.vehicle.lease_end
            ? format(new Date(props.vehicle.lease_end), 'yyyy-MM-dd')
            : undefined
          payload.purchase_price = props.vehicle.purchase_price as number
          payload.purchase_date = props.vehicle.purchase_date
            ? format(new Date(props.vehicle.purchase_date), 'yyyy-MM-dd')
            : undefined
          payload.start_date = props.vehicle.project?.start_date
            ? format(new Date(props.vehicle.project?.start_date), 'yyyy-MM-dd')
            : undefined
          payload.end_date = props.vehicle.project?.end_date
            ? format(new Date(props.vehicle.project?.end_date), 'yyyy-MM-dd')
            : undefined
          payload.description = props.vehicle.project?.description as string
          payload.balloon = props.vehicle.balloon as string
        }
      })

      return {
        payload,
        props,
        t,
        buttons,
      }
    },
  })
