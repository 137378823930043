
  import { defineComponent, onMounted, reactive, ref, Ref } from 'vue'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { BespokeReportPayload } from '@/types/modules/reporting/BespokeReport'
  import ManageBespokeReport from '@/views/modules/reporting/partials/ManageBespokeReport.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      ManageBespokeReport,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const errors = ref(null) as Ref<Record<string, string> | null>
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Create Report',
          url: '/bespoke-reporting/create',
        },
      ])

      const submitClicked = (payload: BespokeReportPayload) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('bespokeReports/store', payload)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            router.push({
              name: 'BespokeReportIndex',
            })
          })
          .catch((error) => {
            if (error.errors) {
              errors.value = error.errors
            }
            store.dispatch('genericStore/pushNotification', error.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => store.commit('genericStore/setBreadcrumbs', { crumbs }))

      return {
        errors,
        submitClicked,
      }
    },
  })
