import axios from 'axios'

export default {
  index: async (id: number, params: any) => {
    return axios
      .get(`bespoke-reports/published-reports/${id}/comments`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: async (id: number) => {
    return axios
      .get(`bespoke-reports/published-reports/comments/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: async (id: number, payload: any) => {
    return axios
      .post(`bespoke-reports/published-reports/${id}/comments`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: async (payload: any) => {
    return axios
      .post(`bespoke-reports/published-reports/comments/delete-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
