import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ebd88f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timeline-block" }
const _hoisted_2 = ["data-rota-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxResource = _resolveComponent("DxResource")!
  const _component_rota_timeline = _resolveComponent("rota-timeline")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sharedState.groupings.dates, (current_date, index) => {
      return (_openBlock(), _createElementBlock("section", {
        class: "rota-timeline live-rotas",
        "data-rota-key": `rota-timeline-${index}`,
        key: `rota-timeline-${index}`
      }, [
        (_ctx.rotaSetting)
          ? (_openBlock(), _createBlock(_component_rota_timeline, {
              key: 0,
              view_mode: "home_live",
              timeline_ref: `rota-timeline-${index}`,
              existing_shifts: _ctx.liveShifts,
              show_add_modal: _ctx.sharedState.skeletonShift,
              datasource: _ctx.sharedState.shifts,
              can_edit: _ctx.canEditTimeline,
              can_edit_past_rotas_setting: _ctx.canEditTimeline,
              current_date: current_date.date,
              duration: _ctx.sharedState.timeline.duration,
              height: "200px",
              onInit: _ctx.saveSchedulerInstance,
              onAdding: _ctx.adding,
              onUpdating: _ctx.updating,
              onDeleting: _ctx.deleting,
              onConfigureForm: _ctx.buildForm
            }, {
              "date-rotate": _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(current_date.short), 1)
              ]),
              resources: _withCtx(() => [
                _createVNode(_component_DxResource, {
                  "use-color-as-default": true,
                  "data-source": _ctx.sharedState.shifts,
                  "field-expr": "id"
                }, null, 8, ["data-source"])
              ]),
              _: 2
            }, 1032, ["timeline_ref", "existing_shifts", "show_add_modal", "datasource", "can_edit", "can_edit_past_rotas_setting", "current_date", "duration", "onInit", "onAdding", "onUpdating", "onDeleting", "onConfigureForm"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}