<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('ypDetails.health.nhsNum')"
          v-model="health.nhs_number"
        />
        <text-input
          :label="t('ypDetails.health.doctorOnAdmin')"
          v-model="health.doctor_on_administation"
        />
        <text-input
          :label="t('ypDetails.health.currentDoctor')"
          v-model="health.current_doctor"
        />
        <text-input
          :label="t('ypDetails.health.hospital')"
          v-model="health.hospital"
        />
        <text-input
          :label="t('ypDetails.health.optician')"
          v-model="health.opticians"
        />
        <text-input
          :label="t('ypDetails.health.dentist')"
          v-model="health.dentist"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('ypDetails.health.medicalNeeds')"
          v-model="health.medical_needs"
        />
        <text-input
          :label="t('ypDetails.health.allergies')"
          v-model="health.allergies"
        />
        <text-input
          :label="t('ypDetails.health.responsiblePerson')"
          v-model="health.emergency_treatment"
        />
        <radio-button
          :label="t('ypDetails.health.registeredDisabled')"
          layout="horizontal"
          v-model="health.disabled"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
        <text-input
          :label="t('ypDetails.health.otherDetails')"
          v-model="health.important_details"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { useI18n } from 'vue-i18n'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'

  export default defineComponent({
    components: {
      TextInput,
      DateInput,
      RadioButton,
    },
    setup() {
      const { t } = useI18n()
      let health = {
        nhs_number: 'XXXXXXXXX',
        doctor_on_administation: 'Dr Wiseman',
        current_doctor: 'Dr Bakrat',
        hospital: 'New Cross Hospital',
        opticians: 'Lawleys Opticians',
        dentist: 'Dale Medical',
        medical_needs: '-',
        allergies: 'Nuts',
        emergency_treatment: '',
        disabled: false,
        important_details: '',
      }

      return {
        health,
        t,
      }
    },
  })
</script>
