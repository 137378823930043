
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { defineComponent, reactive } from 'vue'

  export default defineComponent({
    components: {
      DataGrid,
    },

    setup() {
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'IP Address',
          dataField: 'ipv4_address',
          allowGrouping: true,
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: true,
        },
      ])

      return {
        columns,
      }
    },
  })
