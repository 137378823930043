import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_custom_field_groups_table = _resolveComponent("custom-field-groups-table")!
  const _component_custom_fields_table = _resolveComponent("custom-fields-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_cc_button, {
              key: tab,
              label: tab,
              type: _ctx.state.activeTab === tab ? 'tab-active' : 'tab-inactive',
              onClick: ($event: any) => (_ctx.state.activeTab = tab)
            }, null, 8, ["label", "type", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (_ctx.state.activeTab === 'Custom Field Groups')
      ? (_openBlock(), _createBlock(_component_custom_field_groups_table, {
          key: 0,
          type: "Vehicle",
          "create-view": "VehicleProjectTypeSectionCreate",
          "edit-view": "VehicleProjectTypeSectionEdit",
          "delete-view": "VehicleProjectTypeSectionDelete",
          "restore-view": "VehicleProjectTypeSectionRestore"
        }))
      : _createCommentVNode("", true),
    (_ctx.state.activeTab === 'Custom Fields')
      ? (_openBlock(), _createBlock(_component_custom_fields_table, {
          key: 1,
          type: "Vehicle",
          "create-view": "VehicleProjectTypeAttributeCreate",
          "edit-view": "VehicleProjectTypeAttributeEdit",
          "delete-view": "VehicleProjectTypeAttributeDelete",
          "restore-view": "VehicleProjectTypeAttributeRestore"
        }))
      : _createCommentVNode("", true)
  ], 64))
}