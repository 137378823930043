import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full mb-6" }
const _hoisted_2 = { class: "font-black text-xl font-display mb-4" }
const _hoisted_3 = { class: "flex items-start space-x-4 flex-wrap" }
const _hoisted_4 = { class: "lowercase space-y-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "w-full mb-6" }
const _hoisted_7 = { class: "font-black text-xl font-display mb-4" }
const _hoisted_8 = { class: "flex items-start space-x-4 flex-wrap" }
const _hoisted_9 = { class: "lowercase space-y-2" }
const _hoisted_10 = { class: "text-base tracking-slight" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_dropzone = _resolveComponent("file-dropzone")!
  const _component_image_tile = _resolveComponent("image-tile")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.t('careGroupBranding.logoHeader')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_file_dropzone, { id: "care-group-file-upload" }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logoImages, (image, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mb-4",
            key: index
          }, [
            _createVNode(_component_image_tile, { image: image }, {
              dropdownContent: _withCtx(() => [
                _createElementVNode("ul", _hoisted_4, [
                  _createElementVNode("li", {
                    class: "text-base tracking-slight cursor-pointer hover:bg-blue-100",
                    onClick: ($event: any) => (_ctx.state.logoDeletion = { image, showConfirm: true })
                  }, _toDisplayString(_ctx.t('careGroupBranding.dropdown.delete')), 9, _hoisted_5)
                ])
              ]),
              _: 2
            }, 1032, ["image"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.t('careGroupBranding.splashHeader')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_file_dropzone, {
          id: "splash-image-file-upload",
          onFileUploaded: _ctx.uploadSplashImage
        }, null, 8, ["onFileUploaded"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splashImages, (image, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mb-4",
            key: index
          }, [
            _createVNode(_component_image_tile, { image: image }, {
              dropdownContent: _withCtx(() => [
                _createElementVNode("ul", _hoisted_9, [
                  _createElementVNode("li", _hoisted_10, [
                    _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.t('careGroupBranding.dropdown.show')), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showOnLogin) = $event)),
                      type: "checkbox"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.state.showOnLogin]
                    ])
                  ]),
                  _createElementVNode("li", {
                    class: "text-base tracking-slight cursor-pointer hover:bg-blue-100",
                    onClick: ($event: any) => (_ctx.state.splashDeletion = { image, showConfirm: true })
                  }, _toDisplayString(_ctx.t('careGroupBranding.dropdown.delete')), 9, _hoisted_12)
                ])
              ]),
              _: 2
            }, 1032, ["image"])
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_confirm_dialog, {
      show: _ctx.state.logoDeletion.showConfirm,
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this image?",
      onConfirmed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteLogo(_ctx.state.logoDeletion.image))),
      onCancelled: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.logoDeletion.showConfirm = false))
    }, null, 8, ["show"]),
    _createVNode(_component_confirm_dialog, {
      show: _ctx.state.splashDeletion.showConfirm,
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this image?",
      onConfirmed: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteSplash(_ctx.state.splashDeletion.image))),
      onCancelled: _cache[4] || (_cache[4] = ($event: any) => (_ctx.state.splashDeletion.showConfirm = false))
    }, null, 8, ["show"])
  ], 64))
}