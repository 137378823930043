import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskCardDetails = _resolveComponent("TaskCardDetails")!
  const _component_task_card = _resolveComponent("task-card")!

  return (_openBlock(), _createBlock(_component_task_card, {
    size: "small",
    type: _ctx.type,
    title: _ctx.data.name,
    to: _ctx.link
  }, {
    body: _withCtx(() => [
      _createVNode(_component_TaskCardDetails, {
        type: _ctx.type,
        statusText: _ctx.data.status.name,
        date: _ctx.data.created_at
      }, null, 8, ["type", "statusText", "date"])
    ]),
    _: 1
  }, 8, ["type", "title", "to"]))
}