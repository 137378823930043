import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "ml-2 font-semibold text-base lowercase tracking-slight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.icon),
      "aria-hidden": "true"
    }, null, 2),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.formStatus), 1)
  ]))
}