import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_schools_table = _resolveComponent("schools-table")!
  const _component_school_settings = _resolveComponent("school-settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isTab('schools'))
      ? (_openBlock(), _createBlock(_component_schools_table, { key: 0 }))
      : _createCommentVNode("", true),
    ($setup.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_school_settings, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}