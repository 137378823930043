
  import { ProjectAssociation } from '@/types/modules/projects/ProjectAssociation'
  import { defineComponent, PropType } from 'vue'
  import { useRoute } from 'vue-router'
  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<ProjectAssociation>,
        required: true,
      },
    },
    setup() {
      const route = useRoute()
      return {
        route,
      }
    },
  })
