import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Stakeholder Positions",
    "create-view": "CareGroupRoleCreate",
    "edit-view": "CareGroupRoleEdit",
    "delete-view": "CareGroupRoleDelete",
    "restore-view": "CareGroupRoleRestore",
    action: "careGroupRoles/index",
    "toggle-visibility-action": "careGroupRoles/toggleManyVisibility",
    "delete-many-action": "careGroupRoles/deleteMany",
    "reorder-action": "careGroupRoles/saveOrderings"
  }))
}