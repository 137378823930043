import SensitiveIndex from '../views/modules/sensitive/Index.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/sensitive-data',
    name: 'SensitiveIndex',
    component: SensitiveIndex,
    meta: {
      title: 'Sensitive Data',
      module: 'sensitive',
      middleware: [auth, canView],
    },
  },
] as RouteRecordRaw[]
