
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { renderCellLink } from '@/composables/Grids'
  import { Form } from '@/types/modules/forms/Form'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore()
      const query = ref({})
      const state = reactive({
        activeChip: 'all' as string,
        showHiddenForms: false,
      })

      const action = computed(() => {
        return props.sensitive ? 'taskLogs/getSensitiveLogs' : 'taskLogs/index'
      })

      const categories = computed(() => {
        return store.getters['forms/forms']?.map((form: Form) => form.name)
      })

      const chips = computed((): string[] => {
        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { form: state.activeChip }),
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'Task ID',
          dataField: 'task_id',
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'Summary',
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data?.name, 'ViewLog', {
              taskId: options.data?.id,
            })
          },
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('forms/index', {
            show_hidden: state.showHiddenForms,
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        chips,
        state,
        query,
        action,
        columns,
        categories,
        chipClicked,
      }
    },
  })
