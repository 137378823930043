import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_date_input, {
        label: "PAT Date",
        modelValue: _ctx.payload.pat_date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.pat_date) = $event)),
        "is-required": false
      }, null, 8, ["modelValue"]),
      _createVNode(_component_date_input, {
        label: "Electrical Certificate",
        "is-required": false,
        modelValue: _ctx.payload.electrical_certificate,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.electrical_certificate) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_date_input, {
        label: "Gas Certificate",
        "is-required": false,
        modelValue: _ctx.payload.gas_certificate,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.gas_certificate) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_date_input, {
        label: "Fire Drill - Day",
        "is-required": false,
        modelValue: _ctx.payload.day_fire_drill_date,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.day_fire_drill_date) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_date_input, {
        label: "Fire Drill - Night",
        "is-required": false,
        modelValue: _ctx.payload.night_fire_drill_date,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.night_fire_drill_date) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_box, {
        label: "Description",
        modelValue: _ctx.payload.description,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.description) = $event)),
        "is-required": false
      }, null, 8, ["modelValue"])
    ])
  ]))
}