import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cc_avatar, {
      url: _ctx.user.photo
    }, null, 8, ["url"]),
    _createVNode(_component_router_link, {
      to: { name: 'UserShow', params: { id: _ctx.user.id } },
      class: "font-semibold text-blue-200 underline"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.user?.username), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}