import axios from 'axios'
export default {
  index: (params: any) => {
    return axios
      .get('regulatory_reports', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (params: any) => {
    params = params // contains the id of the report

    return {
      success: true,
      message: 'returned reg report content successfully',
      data: {
        id: 34,
        project_id: 25,
        name: 'Utopia',
        description: 'utopi demo',
        inspection_type: 1,
        regulatory_report_type_id: 1,
        regulatory_report_job_status_id: 3,
        last_inspection_date: '2021-05-26 12:59:18',
        report_start_period: '2021-05-26 12:59:18',
        report_end_period: '2021-06-07 10:46:11',
        created_by: {
          id: 1,
          user_type_id: 1,
          role_id: 47,
          company_id: 301,
          region_id: null,
          username: 'democareadmin',
          first_name: 'Demo Care',
          last_name: 'Admin Account',
          other_names: null,
          is_active: 1,
          email: 'test@test.com',
          dob: '1989-05-09',
          pic: null,
          signature: 'view_file?file_id=1&module=signature',
          password_instant_expire: 0,
          password_expires_at: '2025-04-09T12:54:55.000000Z',
          successive_failed_logins: 0,
          accepted_tandc: '2021-04-29 11:51:23',
          use_fixed_ip: 0,
          api_token: null,
          user_hide_future_tasks: 0,
          zendesk_user_id: null,
          created_by: 1,
          updated_by: 1,
          disabled_by: null,
          deleted_by: null,
          disabled_at: null,
          deleted_at: null,
          created_at: null,
          updated_at: '2022-04-14T13:31:28.000000Z',
          password_expired: false,
          full_name: 'Demo Care Admin Account',
          account_is_locked: false,
          text: 'Demo Care Admin Account (democareadmin)',
          alternative_text: 'Admin Account, Demo Care (democareadmin)',
          user_id: 1,
          is_disabled: false,
        },
        updated_by: {
          id: 1,
          user_type_id: 1,
          role_id: 47,
          company_id: 301,
          region_id: null,
          username: 'democareadmin',
          first_name: 'Demo Care',
          last_name: 'Admin Account',
          other_names: null,
          is_active: 1,
          email: 'test@test.com',
          dob: '1989-05-09',
          pic: null,
          signature: 'view_file?file_id=1&module=signature',
          password_instant_expire: 0,
          password_expires_at: '2025-04-09T12:54:55.000000Z',
          successive_failed_logins: 0,
          accepted_tandc: '2021-04-29 11:51:23',
          use_fixed_ip: 0,
          api_token: null,
          user_hide_future_tasks: 0,
          zendesk_user_id: null,
          created_by: 1,
          updated_by: 1,
          disabled_by: null,
          deleted_by: null,
          disabled_at: null,
          deleted_at: null,
          created_at: null,
          updated_at: '2022-04-14T13:31:28.000000Z',
          password_expired: false,
          full_name: 'Demo Care Admin Account',
          account_is_locked: false,
          text: 'Demo Care Admin Account (democareadmin)',
          alternative_text: 'Admin Account, Demo Care (democareadmin)',
          user_id: 1,
          is_disabled: false,
        },
        deleted_by: null,
        deleted_at: null,
        created_at: '2021-06-07T09:46:11.000000Z',
        updated_at: '2021-06-07T09:46:19.000000Z',
        report_period: '26th May 2021 - 7th Jun 2021',
        project: {
          id: 25,
          project_type_id: 1,
          company_id: 301,
          region_id: null,
          project_status_id: 1,
          administrator_id: 96,
          created_by_id: null,
          name: 'Hilltop View',
          description: null,
          image: {
            thumbnail:
              'https://pentest.clearcare.co.uk/new_projects/25/photo?thumb=1&token=MjAyMS0wOC0zMSAxNTozMzozNg==',
            original:
              'https://pentest.clearcare.co.uk/new_projects/25/photo?token=MjAyMS0wOC0zMSAxNTozMzozNg==',
          },
          colour: '#FF0000',
          photo_last_updated_at: '2021-08-31 15:33:36',
          start_date: '2016-01-28 00:00:00',
          end_date: null,
          is_private: 0,
          deleted_at: null,
          created_at: '2019-09-18T09:10:06.000000Z',
          updated_at: '2021-08-31T14:33:36.000000Z',
          deleted_by: null,
          status: 'current',
          association_status: null,
          can_read_write: -1,
          project_type_label: 'home',
          initials: 'HV',
          home: {
            id: 3,
            sell_id: null,
            project_id: 25,
            responsible_individual_id: 108,
            person_in_charge_id: 96,
            number_of_beds: 3,
            minimum_age_group: 9,
            maximum_age_group: 18,
            address: '86 Church Street\r\nEckington\r\nWorcestershire',
            postcode: 'WR10 4NA',
            phone: '01386 556699',
            fax: '01386 556698',
            email: 'hilltop@clearcare.co.uk',
            pat_date: '2016-02-16',
            electrical_certificate: '2016-02-16',
            gas_certificate: '2016-02-16',
            health_safety_risk_date: null,
            health_safety_premises_check_date: null,
            fire_risk_date: null,
            fire_service_visit_date: null,
            employers_liability_insurance_date: null,
            day_fire_drill_date: null,
            night_fire_drill_date: null,
            ofsted_urn: 'SC767676',
            ofsted_rating: 'Outstanding',
            ofsted_rating_date: '2015-09-14',
            ofsted_requirement_no: '2',
            ofsted_req_reg_no: '12,13',
            ofsted_recommendation_no: '4',
            ofsted_rec_reg_no: '07,09,10,11',
            interim_ofsted_rating: 'Good',
            interim_rating_date: '2015-06-15',
            interim_requirement_no: null,
            interim_req_reg_no: null,
            interim_recommendation_no: '1',
            interim_rec_reg_no: null,
            is_secure_accommodation: 0,
            is_short_term_stay: 0,
            home_category_id: null,
            deleted_at: null,
            created_at: '2019-09-18T09:10:06.000000Z',
            updated_at: '2021-08-31T14:33:36.000000Z',
          },
          project_type: {
            id: 1,
            name: 'Home',
            is_parent: 1,
            created_at: '2019-09-18T09:05:54.000000Z',
            updated_at: '2022-04-25T00:03:04.000000Z',
          },
        },
        regulatory_report_type: {
          id: 1,
          name: 'Annex A',
          report_title:
            'Annex A. Request for information at a full inspection of a children’s home',
          description: '',
          system_name: 'annex-a',
          format_configurable: 0,
          project_type_id: 1,
          order_id: 1,
          configuration_reviewed_at: '2021-05-28 09:44:54',
          created_by: null,
          updated_by: null,
          deleted_by: null,
          deleted_at: null,
          created_at: '2021-03-24T14:18:03.000000Z',
          updated_at: '2021-05-28T08:44:54.000000Z',
          project_type: {
            id: 1,
            name: 'Home',
            is_parent: 1,
            created_at: '2019-09-18T09:05:54.000000Z',
            updated_at: '2022-04-25T00:03:04.000000Z',
          },
          regulatory_report_type_section: [
            {
              id: 1,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_introduction',
              value: '',
              is_system: 1,
              order_id: 0,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 1,
                  regulatory_report_type_section_id: 1,
                  regulatory_report_item_type_id: 1,
                  system_name: 'ch_name',
                  number: '1',
                  name: 'Name of children’s home:',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Field',
                    field: 'home.project.name',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2333,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 1,
                      value: 'Hilltop View',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 2,
                  regulatory_report_type_section_id: 1,
                  regulatory_report_item_type_id: 1,
                  system_name: 'ch_urn',
                  number: '2',
                  name: 'URN of children’s home:',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Field',
                    field: 'home.ofsted_urn',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2336,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 2,
                      value: 'SC767676',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 3,
                  regulatory_report_type_section_id: 1,
                  regulatory_report_item_type_id: 1,
                  system_name: 'completed_by',
                  number: '3',
                  name: 'Name of person completing this form:',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'report.get_created_by_name',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2340,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 3,
                      value: 'Demo Care Admin Account',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 4,
                  regulatory_report_type_section_id: 1,
                  regulatory_report_item_type_id: 1,
                  system_name: 'completed_at',
                  number: '4',
                  name: 'Date completed:',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'report.get_date_report_initiated',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2343,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 4,
                      value: '2021-06-07 10:46:11',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_covid',
              value: 'Covid Questions',
              is_system: 1,
              order_id: 1,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 78,
                  regulatory_report_type_section_id: 2,
                  regulatory_report_item_type_id: 1,
                  system_name: 'covid_flexabilities',
                  number: 'C1',
                  name: 'Which, if any, of the ‘flexibilities’ permitted by the Adoption and Children (Coronavirus)(Amendment) Regulations 2020, or any subsequent changes to regulations, have you used?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 170,
                    field_id: 5322,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-03-30T08:09:50.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2342,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 78,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 79,
                  regulatory_report_type_section_id: 2,
                  regulatory_report_item_type_id: 1,
                  system_name: 'covid_decision_maker',
                  number: 'C2',
                  name: 'The name and job title of the person who made the decision to use the amendments',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 170,
                    field_id: 5323,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-03-30T08:10:05.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2359,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 79,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_1',
              value: 'Section 1: Information about children',
              is_system: 1,
              order_id: 2,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 5,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_admitted',
                  number: '5',
                  name: 'Number of children and young people admitted to the home',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_total_admitted_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2360,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 5,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 6,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_left',
                  number: '6',
                  name: 'Number of children and young people  who have left the home',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_total_left_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2341,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 6,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 7,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_residing',
                  number: '7',
                  name: 'Number of children and young people  resident at the time of inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_total_residents',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T14:18:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2381,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 7,
                      value: '2',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 8,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_incidents',
                  number: '8a',
                  name: 'Number of incidents of restraint',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Aggregation',
                    form_id: '25',
                    field_id: null,
                    value: null,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T15:34:28.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2354,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 8,
                      value: '4',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 9,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_incidents',
                  number: '8b',
                  name: 'Number of children involved in these incidents',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: '25',
                    field_id: null,
                    value: null,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T15:34:32.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2334,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 9,
                      value: '1',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 10,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_unauthorisedabsence',
                  number: '9a',
                  name: 'Number of times children were away from placement without authorisation',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Aggregation',
                    form_id: 109,
                    field_id: null,
                    value: null,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T15:34:43.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2335,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 10,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 11,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_unauthorisedabsence',
                  number: '9b',
                  name: 'Number of children who were away from placement without authorisation',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: 109,
                    field_id: null,
                    value: null,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:09.000000Z',
                  updated_at: '2021-03-24T15:34:54.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2358,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 11,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 12,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_risksexualexploitation',
                  number: '10',
                  name: 'Number of children currently resident that you consider to be at risk of child  sexual exploitation (CSE)',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: '42',
                    field_id: 1015,
                    value: 'Yes',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T15:35:52.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2339,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 12,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 13,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_cse',
                  number: '11',
                  name: 'Number of children currently resident  that you consider to be subject to CSE',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 42,
                    field_id: 1019,
                    value: 'Yes',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-07T12:55:04.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2337,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 13,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 14,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_riskcriminalexploitation',
                  number: '12',
                  name: 'Number of children currently resident  that you consider to be at risk of child  criminal exploitation (CCE)',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 161,
                    field_id: 5289,
                    value: 'Yes',
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T15:49:05.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2347,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 14,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 15,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_cse2',
                  number: '13',
                  name: 'Number of children currently resident  that you consider to be subject to CCE',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 161,
                    field_id: 5293,
                    value: 'Yes',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:19:23.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2338,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 15,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 16,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_complaints_from_yp',
                  number: '14a',
                  name: 'Number of complaints from children',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Aggregation',
                    form_id: 162,
                    field_id: null,
                    value: null,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:24:29.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2345,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 16,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 17,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_complainers',
                  number: '14b',
                  name: 'Number of children making complaints',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: 162,
                    field_id: null,
                    value: null,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:24:39.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2346,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 17,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 18,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_complaints_from_others',
                  number: '15a',
                  name: 'Number of complaints from others',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Aggregation',
                    form_id: '91',
                    field_id: null,
                    value: null,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:24:58.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2344,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 18,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:13.000000Z',
                      updated_at: '2021-06-07T09:46:13.000000Z',
                    },
                  ],
                },
                {
                  id: 19,
                  regulatory_report_type_section_id: 3,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_involved_complaints',
                  number: '15b',
                  name: 'Number of children involved in these complaints',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: 91,
                    field_id: 5307,
                    scope: 'System List',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-09T14:26:19.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2383,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 19,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_2',
              value: 'Section 2: Safeguarding',
              is_system: 1,
              order_id: 3,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 20,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_allegation_against_staff',
                  number: '16a',
                  name: 'Number of allegations made against staff',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Aggregation',
                    form_id: 163,
                    field_id: null,
                    value: null,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:34:02.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2348,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 20,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 21,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_making_allegations',
                  number: '16b',
                  name: 'Number of children making these allegations',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: 163,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-30T07:12:15.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2352,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 21,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 22,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_subject_allegations',
                  number: '16c',
                  name: 'Number of staff subject to these allegations',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Scope Aggregation',
                    form_id: 163,
                    field_id: 5308,
                    scope: 'System List: Employee',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-07T13:00:04.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2351,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 22,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 23,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_sanctions',
                  number: '17a',
                  name: 'Number of sanctions given',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 163,
                    field_id: 5326,
                    value: 'Yes',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-07T13:01:24.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2362,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 23,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 24,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_sanctions',
                  number: '17b',
                  name: 'Number of children given sanctions',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Manual',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:36:53.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2402,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 24,
                      value: '0',
                      json_values: '',
                      created_by: 1,
                      updated_by: 1,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 25,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_refferal_to_la',
                  number: '18a',
                  name: "Number of child protection referrals made to local authority children's services",
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 164,
                    field_id: 5311,
                    value: 'Yes',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-30T07:13:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2349,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 25,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 26,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_refferal_radicalisation',
                  number: '19a',
                  name: 'Number of child protection referrals relating to risk of radicalisation',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 164,
                    field_id: 5310,
                    value: 'Radicalisation',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-30T07:16:09.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2350,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 26,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 27,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_subject_refferal_radicalisation',
                  number: '19b',
                  name: 'Number of children subject to these referrals',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 164,
                    field_id: 5310,
                    value: 'Radicalisation',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-30T07:16:04.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2385,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 27,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 28,
                  regulatory_report_type_section_id: 4,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_dol',
                  number: '20',
                  name: 'Number of children that were subject to a  deprivation of liberty (DOL) order',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 164,
                    field_id: 5312,
                    value: 'Yes',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-30T07:15:54.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2371,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 28,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 5,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_3',
              value:
                'Section 3: Secure homes only – restricting liberty of movement',
              is_system: 1,
              order_id: 4,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 29,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_directed_singlesep',
                  number: '21a',
                  name: 'Number of directed single separations',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 165,
                    field_id: 5315,
                    value: 'Single',
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:58:38.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2378,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 29,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 30,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_directed_singlesep',
                  number: '21b',
                  name: 'Number of children (directed single separations)',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 165,
                    field_id: 5315,
                    value: 'Single',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:59:28.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2370,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 30,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 31,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_enforced_singlesep',
                  number: '22a',
                  name: 'Number of enforced single separations',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 165,
                    field_id: 5315,
                    value: 'Enforced',
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T09:59:52.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2363,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 31,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 32,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_enforced_singlesep',
                  number: '22b',
                  name: 'Number of children (enforced single separations)',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 165,
                    field_id: 5315,
                    value: 'Enforced',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T10:00:20.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2361,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 32,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 33,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_managingaway',
                  number: '23a',
                  name: "Number of 'managing away' incidents",
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Aggregation',
                    form_id: 165,
                    field_id: 5314,
                    value: 'Yes',
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T10:00:38.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2375,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 33,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
                {
                  id: 34,
                  regulatory_report_type_section_id: 5,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_managingaway',
                  number: '23b',
                  name: "Number of children ('managing away' incidents)",
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Form Field Scope Aggregation',
                    form_id: 165,
                    field_id: 5316,
                    value: 'Yes',
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-29T10:01:01.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2364,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 34,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 6,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_4',
              value: 'Section 4: Short breaks homes only',
              is_system: 1,
              order_id: 5,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 35,
                  regulatory_report_type_section_id: 6,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_shortbreaks',
                  number: '24.1',
                  name: 'Number of children receiving a short breaks service at the time of inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_total_short_break_residents',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2386,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 35,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 36,
                  regulatory_report_type_section_id: 6,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_yp_shortbreaks_over75',
                  number: '24.2',
                  name: 'Number of children who received more than 75 days care per year since the last inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'resident.get_total_short_break_residents_with_over_75_days_care',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2393,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 36,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 7,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_5',
              value:
                'Section 5: Missing episodes and return home interviews offered since the last  inspection',
              is_system: 1,
              order_id: 6,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 37,
                  regulatory_report_type_section_id: 7,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_yp_gone_missing',
                  number: '25',
                  name: 'Please include all children who have gone missing at any point since the last full inspection, one child per row.',
                  json_options: {
                    table_options: [
                      {
                        name: 'Childs initials',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'initials',
                      },
                      {
                        name: 'Name of placing authority',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'placing_authority',
                      },
                      {
                        name: 'Number of episodes of going missing',
                        configurable: 'true',
                        type: 'Form Count',
                      },
                      {
                        name: 'Number of episodes where return interview offered by LA',
                        configurable: 'true',
                        type: 'Field Value Count',
                        field: 5324,
                        value: 'Yes',
                      },
                    ],
                    table_distinct: true,
                  },
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Tabled Form Field Aggregation',
                    form_id: 109,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: 1,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-09-13T22:57:42.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2366,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 37,
                      value: '',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 8,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_6',
              value:
                'Section 6: Information on police callouts to manage behaviour',
              is_system: 1,
              order_id: 7,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 38,
                  regulatory_report_type_section_id: 8,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_yp_police_callouts',
                  number: '26',
                  name: 'Police callouts to manage behaviour since the last full inspection',
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'initials',
                      },
                      {
                        name: 'Date of police callout',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 630,
                      },
                      {
                        name: 'Was the child arrested? (Yes, No, Not yet known)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 621,
                      },
                      {
                        name: 'Was the child convicted? (Yes, No, Not applicable, Not yet known)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 631,
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Tabled Form Field Aggregation',
                    form_id: 25,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-07T13:12:09.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2372,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 38,
                      value: '',
                      json_values: {
                        table_responses: [
                          {
                            'Child’s initials': 'SD',
                            'Date of police callout': '',
                            'Was the child arrested? (Yes, No, Not yet known)':
                              '',
                            'Was the child convicted? (Yes, No, Not applicable, Not yet known)':
                              '',
                          },
                        ],
                      },
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
                {
                  id: 39,
                  regulatory_report_type_section_id: 8,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_yp_update_police_callouts',
                  number: '27',
                  name: "Update on police callouts to manage behaviour reported at the previous full inspection,  where the outcome was 'Not yet known'",
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'initials',
                      },
                      {
                        name: 'Date of police callout',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 630,
                      },
                      {
                        name: 'Was the child arrested? (Yes, No, Not yet known)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 621,
                      },
                      {
                        name: 'Was the child convicted? (Yes, No, Not applicable, Not yet known)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 631,
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Tabled Form Field Aggregation',
                    form_id: 25,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-04-07T13:13:17.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2394,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 39,
                      value: '',
                      json_values: {
                        table_responses: [
                          {
                            'Child’s initials': 'SD',
                            'Date of police callout': '',
                            'Was the child arrested? (Yes, No, Not yet known)':
                              '',
                            'Was the child convicted? (Yes, No, Not applicable, Not yet known)':
                              '',
                          },
                        ],
                      },
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 9,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_7',
              value: 'Section 7: Staffing and other information',
              is_system: 1,
              order_id: 8,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 40,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'rm_l5_diploma',
                  number: '28.1',
                  name: 'Does the registered manager (RM) hold the Level 5 diploma or equivalent?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.check_registered_manager_has_level_5_diploma',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2379,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 40,
                      value: 'No',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 41,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'rm_l4_diploma',
                  number: '28.2',
                  name: 'Does the RM hold a Level 4 diploma or equivalent?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.check_registered_manager_has_level_4_diploma',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2353,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 41,
                      value: 'No',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 42,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'rm_quals',
                  number: '28.3',
                  name: 'If no to Q28.1 and 28.2, please state qualification held by RM, or ‘post vacant’ if there is no RM in post',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'employee.get_registered_manager_qualifications',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2384,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 42,
                      value:
                        "Level 5 Leadership & Management, Level 3 Diploma CYP, Equality & Diversity, Fire Safety, Emergency First Aid at Work (on Line), Food Safety and Hygiene (3yrs), General Data Protection Regualtion, Health & Safety, Medication, Physical Intervention 2 day Course (initial), Anit-bullying, Care Planning & Key Working, Child Sexual Exploitation, COSHH Children's Homes, Drug & Alcohol Awareness, First Aid (3yrs), Reporting & Recording Advanced, Coronnnavirus out break adult well being, Base line, surrey CC induction, T Training, Empower admin training, covid training, solace induction, Hazlemont induction",
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 43,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_new_staff',
                  number: '29',
                  name: 'Number of new staff in a care role since the last full inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_new_staff_in_care_role_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2367,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 43,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 44,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_left',
                  number: '30',
                  name: 'Number of staff in a care role who have left since the last full inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_who_left_care_role_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2395,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 44,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
                {
                  id: 45,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_perm_staff',
                  number: '31.1',
                  name: 'Number of permanent staff in a care role (people)',
                  json_options: null,
                  json_configuration: {
                    configurable: false,
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_current_permanent_staff_in_care_role',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:10.000000Z',
                  updated_at: '2021-03-24T14:18:10.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2390,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 45,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 46,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_perm_staff_pt',
                  number: '31.2',
                  name: 'How many of these permanent staff in a  care role work part time only?',
                  json_options: null,
                  json_configuration: {
                    configurable: false,
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_current_part_time_permanent_staff_in_care_role',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2355,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 46,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 47,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_contract_staff',
                  number: '32.1',
                  name: 'Number of agency/other (non permanent) staff in a care role (people)',
                  json_options: null,
                  json_configuration: {
                    configurable: false,
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_current_non_permanent_staff_in_care_role',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2382,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 47,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 48,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_contract_staff_pt',
                  number: '32.2',
                  name: 'How many of these agency/other (non permanent) staff work in a care role part time only?',
                  json_options: null,
                  json_configuration: {
                    configurable: false,
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_current_part_time_non_permanent_staff_in_care_role',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2356,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 48,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
                {
                  id: 49,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_diploma_l3',
                  number: '33',
                  name: 'Number of staff in a care role with Diploma Level 3 or equivalent',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_in_care_role_with_level_3_diploma',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2387,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 49,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 50,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_reg32_qual',
                  number: '34.1',
                  name: 'Number of staff in a care role for whom the relevant date for qualification under Regulation 32 has passed',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_in_care_role_with_reg_32_qualification',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2368,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 50,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 51,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_reg32_qual_diploma_l3',
                  number: '34.2',
                  name: 'How many of the staff in 34.1 [for whom the relevant date has passed] are  qualified to Diploma Level 3?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_in_care_role_with_reg_32_and_diploma_level_3_qualification',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2399,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 51,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
                {
                  id: 52,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_reg32_qual_undertaking_diploma_l3',
                  number: '34.3',
                  name: 'How many of the staff in 34.1 [for whom the relevant date has passed] are undertaking Diploma Level 3?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_in_care_role_with_reg_32_undertaking_diploma_level_3_qualification',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2373,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 52,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
                {
                  id: 53,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name:
                    'no_staff_reg32_qual_undertaking_diploma_l3_detail',
                  number: '34.4',
                  name: 'Name(s) and expected qualification  date(s) of staff in 34.3',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_staff_details_in_care_role_with_reg_32_undertaking_diploma_level_3_qualification',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2396,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 53,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
                {
                  id: 54,
                  regulatory_report_type_section_id: 9,
                  regulatory_report_item_type_id: 1,
                  system_name: 'no_staff_first_aid',
                  number: '34.5',
                  name: 'Number of all staff with valid first aid  qualification',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'employee.get_total_staff_with_first_aid_qualification',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2374,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 54,
                      value: '0',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 10,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_8',
              value:
                'Section 8: Information about education provision for children currently living in the home',
              is_system: 1,
              order_id: 9,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 55,
                  regulatory_report_type_section_id: 10,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_yp_placing_la',
                  number: '36',
                  name: 'Please list the placing authority, education placement and date of last placement review of all children currently in placement',
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'initials',
                      },
                      {
                        name: 'Name of placing LA',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'placing_authority',
                      },
                      {
                        name: 'Name of educational provision (the main provision if there is  more than one)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 4228,
                      },
                      {
                        name: 'Planned hours of education per week (if less than 25)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 5327,
                      },
                      {
                        name: 'Actual hours of education per week (if less than 25)',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 5328,
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Tabled Form Field Aggregation',
                    form_id: 128,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-04-07T13:20:58.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2357,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 55,
                      value: '',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:14.000000Z',
                      updated_at: '2021-06-07T09:46:14.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 11,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_9',
              value: 'Section 9: Organisational details',
              is_system: 1,
              order_id: 10,
              show_spacer: 0,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 56,
                  regulatory_report_type_section_id: 11,
                  regulatory_report_item_type_id: 1,
                  system_name: 'org_name_change',
                  number: '37.1',
                  name: 'Has there been any change to the name or status of the organisation since the  last inspection?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'company.check_company_name_or_status_changed_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2365,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 56,
                      value: 'No',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 57,
                  regulatory_report_type_section_id: 11,
                  regulatory_report_item_type_id: 1,
                  system_name: 'org_name_change_details',
                  number: '37.2',
                  name: 'If yes, please give details',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'company.get_company_name_or_status_changes_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2389,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 57,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 12,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_9a',
              value: '',
              is_system: 1,
              order_id: 11,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 58,
                  regulatory_report_type_section_id: 12,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_stakeholders',
                  number: '38',
                  name: 'For organisations and partnerships, please give the names of the current directors, secretary  and other officers of the organisation or names of current partners of the company below',
                  json_options: {
                    table_options: [
                      {
                        name: 'RI? (Y/N)',
                        field: 'responsible_individual',
                      },
                      {
                        name: 'Name',
                        field: 'name',
                      },
                      {
                        name: 'Role',
                        field: 'role_name',
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'company.get_company_stakeholder_details',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2400,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 58,
                      value: '',
                      json_values: {
                        table_responses: [],
                      },
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 13,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_10',
              value: 'Section 10: Dates of checks and updates',
              is_system: 1,
              order_id: 12,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 59,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_sop',
                  number: '39',
                  name: 'Date statement of purpose was last updated',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Document Selection',
                    document_id: 8,
                    field: 'updated_at',
                    scope: 'Document',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:09:51.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2376,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 59,
                      value: '2021-04-09 14:44:44',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
                {
                  id: 60,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_childrens_guide',
                  number: '40',
                  name: 'Date children’s guide was last updated',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Document Selection',
                    document_id: 9,
                    field: 'updated_at',
                    scope: 'Document',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:10:02.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2391,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 60,
                      value: '2021-04-01 10:49:12',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 61,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_location_assessment',
                  number: '41',
                  name: 'Date of last annual assessment of the location of the home',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Document Selection',
                    document_id: 10,
                    field: 'updated_at',
                    scope: 'Document',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:10:12.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2406,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 61,
                      value: '2021-04-09 14:44:02',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 62,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_policies_updated',
                  number: '42',
                  name: 'Name any policies that have been updated since the last full inspection',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup:
                      'document.get_policies_updated_since_last_inspection',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2369,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 62,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:15.000000Z',
                      updated_at: '2021-06-07T09:46:15.000000Z',
                    },
                  ],
                },
                {
                  id: 63,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_gas_check',
                  number: '43',
                  name: 'Date of last gas installations check',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Field',
                    field: 'home.gas_certificate',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2401,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 63,
                      value: '2016-02-16',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 64,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_pat_check',
                  number: '44',
                  name: 'Date of last portable appliance testing (PAT) check',
                  json_options: null,
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Field',
                    field: 'home.pat_date',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-24T14:18:11.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2377,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 64,
                      value: '2016-02-16',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:16.000000Z',
                      updated_at: '2021-06-07T09:46:16.000000Z',
                    },
                  ],
                },
                {
                  id: 65,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_hs_ass_check',
                  number: '45',
                  name: 'Date of last health and safety risk assessment',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Document Selection',
                    document_id: 11,
                    field: 'updated_at',
                    scope: 'Document',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:13:41.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2380,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 65,
                      value: '2021-04-09 14:44:40',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 66,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_hs_premises_check',
                  number: '46',
                  name: 'Date of last health and safety check of the premises',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 166,
                    field_id: 5317,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:21:43.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2388,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 66,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 67,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_fra_check',
                  number: '47',
                  name: 'Date of last fire risk assessment',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 167,
                    field_id: 5318,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:21:57.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2392,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 67,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:17.000000Z',
                      updated_at: '2021-06-07T09:46:17.000000Z',
                    },
                  ],
                },
                {
                  id: 68,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_fire_service_visit',
                  number: '48',
                  name: 'Date of last fire service visit',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 168,
                    field_id: 5319,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:22:17.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2408,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 68,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 69,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_fire_drill',
                  number: '49',
                  name: 'Date of last fire drill – day and night',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Latest Form Field Value',
                    form_id: 169,
                    field_id: 5320,
                    scope: null,
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:22:34.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2397,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 69,
                      value: '-',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
                {
                  id: 70,
                  regulatory_report_type_section_id: 13,
                  regulatory_report_item_type_id: 1,
                  system_name: 'date_liability_insurance',
                  number: '49',
                  name: 'Employers liability insurance – valid until:',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Document Selection',
                    document_id: 12,
                    field: 'updated_at',
                    scope: 'Document',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-03-29T10:22:46.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2398,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 70,
                      value: '2021-04-09 14:44:32',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:18.000000Z',
                      updated_at: '2021-06-07T09:46:18.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 14,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 1,
              system_name: 'section_11',
              value: 'Section 11: Linked education providers',
              is_system: 1,
              order_id: 13,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 1,
                system_name: 'annexa_standard',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 71,
                  regulatory_report_type_section_id: 14,
                  regulatory_report_item_type_id: 1,
                  system_name: 'considered_ch',
                  number: '40',
                  name: "Does the children’s home (CH) regard  itself as a residential special school registered as a children's home?",
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Manual',
                    value: 'No',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-04-01T14:51:34.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2403,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 71,
                      value: 'No',
                      json_values: '',
                      created_by: 1,
                      updated_by: 1,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 72,
                  regulatory_report_type_section_id: 14,
                  regulatory_report_item_type_id: 1,
                  system_name: 'school_on_site',
                  number: '41',
                  name: 'Does the CH provide a registered school in a separate building on the same site as the home, which most/all of the children attend ?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Manual',
                    value: 'No',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-04-01T14:51:37.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2405,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 72,
                      value: 'No',
                      json_values: '',
                      created_by: 1,
                      updated_by: 1,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 73,
                  regulatory_report_type_section_id: 14,
                  regulatory_report_item_type_id: 1,
                  system_name: 'school_adjacent',
                  number: '42',
                  name: 'Does the CH provide a registered school within or immediately adjacent to the home e.g. in a garage, outbuilding, shed  etc ?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Manual',
                    value: 'No',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:11.000000Z',
                  updated_at: '2021-04-01T14:51:40.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2407,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 73,
                      value: 'No',
                      json_values: '',
                      created_by: 1,
                      updated_by: 1,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
                {
                  id: 74,
                  regulatory_report_type_section_id: 14,
                  regulatory_report_item_type_id: 1,
                  system_name: 'school_owned',
                  number: '43',
                  name: 'Does the provider of the CH also own a  registered school on another site, which  most/all of the children attend?',
                  json_options: null,
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Manual',
                    value: 'No',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-04-01T14:51:47.000000Z',
                  regulatory_report_item_type: {
                    id: 1,
                    system_name: 'annexa_standard',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2409,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 74,
                      value: 'No',
                      json_values: '',
                      created_by: 1,
                      updated_by: 1,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 15,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_12',
              value: 'Section 12: Notable achievements',
              is_system: 1,
              order_id: 14,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 75,
                  regulatory_report_type_section_id: 15,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_notable_achievements',
                  number: '',
                  name: 'Information about children currently living in the home – notable achievements since the last full inspection. Anything you would want to tell inspectors about a child’s progress.',
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials',
                        configurable: 'false',
                        type: 'Field Value',
                        field: 'initials',
                      },
                      {
                        name: 'Notable achievements',
                        configurable: 'true',
                        type: 'Field Value',
                        field: 5331,
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'true',
                    configuration_type: 'Tabled Form Field Aggregation',
                    form_id: 172,
                    scope: 'Resident',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-04-07T13:32:10.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2411,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 75,
                      value: '',
                      json_values: '',
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 16,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_13',
              value: 'Section 13: Information on key people for each child',
              is_system: 1,
              order_id: 15,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 76,
                  regulatory_report_type_section_id: 16,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_yp_keypeople',
                  number: '',
                  name: 'Please provide contact details of just the key people for each child currently on roll. \n                This includes, but only where applicable, social worker, independent reviewing officer, looked after \n                children nurse, missing coordinator for the police, Youth Offending Service/Youth Offending Team \n                workers, child and adolescent mental health service worker, independent advocate, parents or carers, \n                head teacher and form tutor.  \n\n                NB This does not need to be an exhaustive list of everyone in the child’s life.',
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials',
                      },
                      {
                        name: 'Name',
                      },
                      {
                        name: 'Role',
                      },
                      {
                        name: 'Organisation',
                      },
                      {
                        name: 'Office phone number',
                      },
                      {
                        name: 'Mobile number',
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: 'false',
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_list_of_key_people',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-03-24T14:18:12.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2410,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 76,
                      value: '',
                      json_values: {
                        table_responses: [
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Annie Graves',
                            'Role': 'Key Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Debbie Green',
                            'Role': 'Social Worker',
                            'Organisation': 'Staffordshire',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Jim Morrison',
                            'Role': 'Practice Manager',
                            'Organisation': 'Staffordshire',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Sarah Wheatley',
                            'Role': 'Independent Reviewing Officer',
                            'Organisation': 'Staffordshire',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Chase medical practice',
                            'Role': 'Doctor on Admission',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Dr Muhammed Ali',
                            'Role': 'Doctor',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Chase Dentist',
                            'Role': 'Dentist',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'NJS',
                            'Name': 'Cannock Opticians',
                            'Role': 'Optician',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Rihannon Welch ',
                            'Role': 'Key Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Sally Hall',
                            'Role': 'Social Worker',
                            'Organisation': 'Liverpool Count Council',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Stephen Granger',
                            'Role': 'Practice Manager',
                            'Organisation': 'Liverpool Count Council',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Bob Bucket',
                            'Role': 'Independent Reviewing Officer',
                            'Organisation': 'Liverpool Count Council',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Dr Lewis',
                            'Role': 'Doctor on Admission',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Dr Pringle',
                            'Role': 'Doctor',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Mr Brace',
                            'Role': 'Dentist',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials": 'SD',
                            'Name': 'Miss Specticle',
                            'Role': 'Optician',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                        ],
                      },
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
              ],
            },
            {
              id: 17,
              regulatory_report_type_id: 1,
              regulatory_report_section_type_id: 2,
              system_name: 'section_14',
              value: 'Section 14: Other key people',
              is_system: 1,
              order_id: 16,
              show_spacer: 1,
              created_by: null,
              updated_by: null,
              deleted_by: null,
              deleted_at: null,
              created_at: '2021-03-24T14:18:08.000000Z',
              updated_at: '2021-03-24T14:18:08.000000Z',
              regulatory_report_section_type: {
                id: 2,
                system_name: 'annexa_table',
                created_by: null,
                updated_by: null,
                deleted_by: null,
                deleted_at: null,
                created_at: '2021-03-24T14:18:07.000000Z',
                updated_at: '2021-03-24T14:18:07.000000Z',
              },
              regulatory_report_type_section_item: [
                {
                  id: 77,
                  regulatory_report_type_section_id: 17,
                  regulatory_report_item_type_id: 2,
                  system_name: 'list_other_key_people',
                  number: '',
                  name: 'Please provide contact details of any other key people you would like to bring to our attention.',
                  json_options: {
                    table_options: [
                      {
                        name: 'Child’s initials (Where applicable)',
                      },
                      {
                        name: 'Name',
                      },
                      {
                        name: 'Role',
                      },
                      {
                        name: 'Organisation',
                      },
                      {
                        name: 'Office phone number',
                      },
                      {
                        name: 'Mobile number',
                      },
                    ],
                  },
                  json_configuration: {
                    configurable: false,
                    configuration_type: 'System Lookup',
                    lookup: 'resident.get_list_of_other_key_people',
                  },
                  is_system: 1,
                  created_by: null,
                  updated_by: null,
                  deleted_by: null,
                  deleted_at: null,
                  created_at: '2021-03-24T14:18:12.000000Z',
                  updated_at: '2021-03-24T14:18:12.000000Z',
                  regulatory_report_item_type: {
                    id: 2,
                    system_name: 'annexa_table',
                    created_by: null,
                    updated_by: null,
                    deleted_by: null,
                    deleted_at: null,
                    created_at: '2021-03-24T14:18:06.000000Z',
                    updated_at: '2021-03-24T14:18:06.000000Z',
                  },
                  regulatory_report_values: [
                    {
                      id: 2404,
                      regulatory_report_id: 34,
                      regulatory_report_type_section_item_id: 77,
                      value: '',
                      json_values: {
                        table_responses: [
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Kevin  Flanagan',
                            'Role': '-',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'YK Orisis',
                            'Role': '-',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Ethan Jones',
                            'Role': 'Team Leader',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '01234 876543',
                            'Mobile phone number': '09876543765',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Roxy Danson',
                            'Role': 'Registered Manager',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Stacey Henshaw',
                            'Role': 'Residentail Support Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '01952 346798',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'John Lewis',
                            'Role': 'Responsible Individiual',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '01952 346798',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Fran Snoswell',
                            'Role': '-',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Annie Graves',
                            'Role': 'Residential Care Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Fran Fonseca',
                            'Role': '-',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Jamie Styles',
                            'Role': 'Residential Care Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Rihannon Welch',
                            'Role': 'Residential Care Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Katrina Elma',
                            'Role': 'Operations Manager',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'joe bloggs',
                            'Role': 'test role',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Stephanie Harris',
                            'Role': '-',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '+44 7939 360600',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Karolina Stramska',
                            'Role': 'Residential Care Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Ryan Willis',
                            'Role': 'Residential Care Worker',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'David Abbott',
                            'Role': 'RSW',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Kuba Taylor',
                            'Role': 'RSW Nights',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Nelly Chaucer',
                            'Role': 'RSW nights',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Grace Newall',
                            'Role': 'Team Leader',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '01952 346798',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": '-',
                            'Name': 'Fabian Lockheart',
                            'Role': 'Deputy Manager',
                            'Organisation': 'Demo Care Limited',
                            'Office phone number': '01952 346798',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": 'SD',
                            'Name': '',
                            'Role': '-',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": 'SD',
                            'Name': '',
                            'Role': '-',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": 'SD',
                            'Name': '',
                            'Role': '-',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                          {
                            "Child's initials (Where applicable)": 'SD',
                            'Name': '',
                            'Role': '-',
                            'Organisation': '-',
                            'Office phone number': '-',
                            'Mobile phone number': '-',
                          },
                        ],
                      },
                      created_by: null,
                      updated_by: null,
                      deleted_by: null,
                      deleted_at: null,
                      created_at: '2021-06-07T09:46:19.000000Z',
                      updated_at: '2021-06-07T09:46:19.000000Z',
                    },
                  ],
                },
              ],
            },
          ],
        },
        regulatory_report_job_status: {
          id: 3,
          name: 'Complete',
          colour: '#B7F4BE',
          created_at: '2021-03-24T14:18:04.000000Z',
          updated_at: '2021-04-27T12:30:53.000000Z',
        },
      },
    }
  },
}
