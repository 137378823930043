export default {
  grid: {
    headers: {
      id: 'ID',
      name: 'Name',
      tagging: 'Tagging',
      phone: 'Phone Number',
      email: 'Email',
      updatedOn: 'Last Updated On',
      updatedBy: 'Last Updated By',
      createdOn: 'Originally Recorded On',
      createdBy: 'Originally Recorded By',
      owner: 'Owner',
      reports: 'Reports',
      fax: 'Fax',
      type: 'Type',
      postCode: 'Post Code',
      county: 'County',
      city: 'City',
      address: 'Address',
      position: 'Position',
      date: 'Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      status: 'Status',
      sensitive: 'Is sensitive?',
      description: 'Description',
      category: 'Category',
      administrator: 'Administrator',
      careGroup: 'Care Group',
      createTasks: 'Create Tasks',
      fileName: 'File Name',
      action: 'Action',
      location: 'Location',
      gender: 'Gender',
    },
    cells: {
      configure: 'Configure',
      view: 'View',
      yes: 'Yes',
      no: 'No',
    },
  },
}
