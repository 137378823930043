import { ActionTree, GetterTree, MutationTree } from 'vuex'
import RotaCycles from '@/services/rotas/RotaCycles'
import { RotaCycle } from '@/types/modules/rotas/RotaCycle'

export type RotaCycleGetter = GetterTree<RotaCycleState, any>

export interface RotaCycleState {
  rotaCycles: RotaCycle[]
}

export const state: RotaCycleState = {
  rotaCycles: [],
}

export const getters: RotaCycleGetter = {
  getRotaCycles: (state) => state.rotaCycles,
}

export const actions: ActionTree<RotaCycleState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(RotaCycles.index(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setRotaCycles', response.data)
    })

    return results
  },
}

export const mutations: MutationTree<RotaCycleState> = {
  setRotaCycles: (state, shifts) => (state.rotaCycles = shifts),
}

export const rotaCycles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
