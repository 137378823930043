import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('tasks/logs', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post(`tasks/logs`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getLogDetails: (logId: number) => {
    return axios
      .get(`tasks/logs/details/${logId}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  moveTaskLogs: (payload: any) => {
    return axios
      .post(`tasks/logs/move`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getLatestLog: (id: string) => {
    return axios
      .get(`tasks/${id}/logs/latest`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveLogs: async (params: any) => {
    return axios
      .get('tasks/logs/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateLogStatus: (payload: any) => {
    return axios
      .post('tasks/logs/status/toggle', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
