
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { defineComponent, PropType, reactive, watch, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { Home } from '@/types/modules/projects/Home'

  export default defineComponent({
    components: {
      TextInput,
      TextBox,
    },
    props: {
      home: {
        type: Object as PropType<Home>,
        required: true,
      },
      errors: {
        type: Object,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const payload = reactive({
        address: undefined,
        postcode: undefined,
        phone: undefined,
        fax: undefined,
        email: undefined,
      })
      const { t } = useI18n()

      const mapToPayload = () => {
        Object.assign(payload, {
          address: props.home.address,
          postcode: props.home.postcode,
          phone: props.home.phone,
          fax: props.home.fax,
          email: props.home.email,
        })
      }

      watch(payload, () => emit('update-payload', payload))

      onMounted(() => {
        mapToPayload()
      })
      return {
        props,
        t,
        payload,
      }
    },
  })
