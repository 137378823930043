
  import { defineComponent, PropType, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import ExpandButton from '@/components/Generic/Button/ExpandButton.vue'
  import CcToggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import ExportButton from '../Button/ExportButton.vue'
  import ExtraOptionsButton from '../Button/ExtraOptionsButton.vue'
  import { ExportOption } from '@/types/components/Export'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcToggle,
      ExpandButton,
      ExportButton,
      ExtraOptionsButton,
    },
    props: {
      showToggle: {
        type: Boolean,
        required: false,
        default: true,
      },
      toggleLabel: {
        type: String,
        required: false,
      },
      showExpandButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
      selectedRows: {
        type: Array,
        required: false,
        default: () => [],
      },
      exportOptions: {
        type: Array as PropType<ExportOption[]>,
        required: false,
        default: () => [
          { name: 'Export To PDF', icon: 'fas fa-file-pdf' },
          { name: 'Export To Excel', icon: 'fas fa-file-excel' },
        ],
      },
    },
    emits: [
      'toggle-clicked',
      'expand-clicked',
      'option-clicked',
      'delete-rows',
      'export-clicked',
    ],
    setup(props, { emit }) {
      props = reactive(props)

      const toggleClicked = () => emit('toggle-clicked')
      const expandClicked = () => emit('expand-clicked')
      const optionClicked = (option: string) => emit('option-clicked', option)
      const exportClicked = (option: string) => emit('export-clicked', option)

      return {
        props,
        toggleClicked,
        expandClicked,
        optionClicked,
        exportClicked,
      }
    },
  })
