
  import { defineComponent, PropType, reactive, computed } from 'vue'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      props = reactive(props)

      const statusClasses = computed(() => {
        return {
          'border-blue-500':
            props.data.status.name === 'In Progress',
          'border-gray-500':
            props.data.status.name === 'Expired',
          'border-orange-700':
            props.data.status.name === 'Pending',
          'border-green-500':
            props.data.status.name === 'Complete',
          'border-red-500':
            props.data.status.name === 'Missed',
        }
      })

      const projectColour = computed(() => {
        return 'background-color: ' + props.data.event.project.colour
      })

      return {
        props,
        statusClasses,
        projectColour,
      }
    },
  })
