
  import { defineComponent, reactive, onMounted, computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Form } from '@/types/modules/forms/Form'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const query = ref({})
      const state = reactive({
        chips: [] as string[],
        activeChip: 'all',
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && {
              form_name_filter: state.activeChip,
            }),
          project_id: props.id,
        }
      }

      const forms = computed(() => store.getters['forms/forms'])

      const createChips = () => {
        state.chips = ['all']

        forms.value.forEach((form: Form) => {
          let chip = form.form_group ? form.form_group.name : form.name

          if (!state.chips.includes(chip)) {
            state.chips.push(chip)
          }
        })
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const columns: Column[] = [
        {
          width: 100,
          dataField: 'id',
          dataType: 'number',
          caption: t('filingCabinet.grid.headers.id'),
          allowGrouping: false,
        },
        {
          caption: t('filingCabinet.grid.headers.name'),
          dataField: 'name',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data.name, 'ViewLog', {
              taskId: options.data.task_id,
            })
          },
        },
        {
          caption: t('filingCabinet.grid.headers.type'),
          dataField: 'task.form.name',
        },
        {
          caption: t('filingCabinet.grid.headers.date'),
          dataField: 'task.start_date',
          dataType: 'date',
        },
        {
          caption: t('filingCabinet.grid.headers.createdOn'),
          dataField: 'original_created_at',
          dataType: 'date',
        },
        {
          caption: t('filingCabinet.grid.headers.createdBy'),
          dataField: 'original_created_by_username',
        },
        {
          caption: t('filingCabinet.grid.headers.updatedOn'),
          dataField: 'updated_at',
          dataType: 'date',
        },
        {
          caption: t('filingCabinet.grid.headers.updatedBy'),
          dataField: 'task.form.last_updated_by',
        },
      ]

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('forms/index', {
            relations: ['form_group'],
          })
          .then(() => createChips())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        state,
        props,
        columns,
        chipClicked,
        query,
      }
    },
  })
