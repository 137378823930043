
  import { defineComponent } from 'vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import Toggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import CheckboxList from '@/components/Generic/Forms/Inputs/CheckboxList.vue'

  export default defineComponent({
    props: {
      projectId: {
        required: true,
      },
      projectType: {
        type: String,
        required: true,
      },
      groupName: {
        type: String,
        required: true,
      },
      fields: Array(),
    },
    setup() {
      const components: Record<string, any> = {
        yes_no: {
          component: RadioButton,
          props: {
            buttons: [
              { id: 0, text: 'Yes' },
              { id: 1, text: 'No' },
            ],
          },
        },
        largetext: {
          component: TextBox,
          props: {},
        },
        true_false: {
          component: Toggle,
          props: {},
        },
        checkbox_list: {
          component: CheckboxList,
          props: {},
        },
        dropdown_select_list: {
          component: SelectInput,
          props: {},
        },
        radio_buttons: {
          component: RadioButton,
          props: {},
        },
        numeric: {
          component: TextInput,
          props: { type: 'number' },
        },
        smalltext: {
          component: TextInput,
          props: {},
        },
        date_input: {
          component: DateInput,
          props: {
            type: 'date',
          },
        },
        time_input: {
          component: DateInput,
          props: {
            type: 'time',
          },
        },
      }

      const getProps = (field: any) => {
        let props = {
          label: field.name,
          isRequired: !!field.is_required,
          options: field.options.length
            ? field.options.map((option: any) => {
                return {
                  ...option,
                  ...{ label: option.name },
                }
              })
            : undefined,
          buttons:
            field.field.system_name === 'radio_buttons'
              ? field.options.map((option: any) => {
                  return {
                    ...option,
                    ...{ text: option.name },
                  }
                })
              : undefined,
          modelValue: field.default_value ? field.default_value : undefined,
          message: field.description ? field.description : undefined,
        }

        return {
          ...props,
          ...components[field.field.system_name].props,
        }
      }

      return {
        components,
        getProps,
      }
    },
  })
