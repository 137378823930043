
  import { useStore } from 'vuex'
  import { computed, onMounted, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { isTab } from '@/composables/Generic'
  import { renderCellLink } from '@/composables/Grids'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import Settings from './Settings.vue'
  import Audit from './Audit.vue'

  export default {
    components: {
      DataGrid,
      Settings,
      Audit,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const usersColumns = computed((): Column[] => {
        return [
          {
            caption: 'ID',
            dataField: 'id',
            allowGrouping: false,
            dataType: 'number',
          },
          {
            dataField: 'username',
            caption: 'Username',
            allowGrouping: false,
            cellTemplate: 'UserUsernameCellTemplate',
          },
          {
            dataField: 'first_name',
            caption: 'First Name',
            allowGrouping: false,
          },
          {
            dataField: 'last_name',
            caption: 'Last Name',
            allowGrouping: false,
          },
          {
            dataField: 'password_expires_at',
            dataType: 'date',
            format: 'dd/MM/yyyy',
            caption: 'Password Expires On',
            allowGrouping: true,
          },
          {
            dataField: 'account_is_locked',
            calculateCellValue: (row: any) => {
              return row.account_is_locked ? 'Yes' : 'No'
            },
            caption: 'Account Locked',
            allowGrouping: true,
          },
          {
            dataField: 'is_active',
            calculateCellValue: (row: any) => {
              return row.is_active ? 'Yes' : 'No'
            },
            caption: 'Active',
            allowGrouping: true,
          },
          {
            caption: 'Permissions Matrix',
            calculateCellValue: () => 'Permissions',
            cellTemplate: function (container, options) {
              renderCellLink(
                container,
                'Permissions Matrix',
                'UserPermissionsMatrix',
                {
                  id: options.data.id,
                }
              )
            },
            allowGrouping: false,
            allowExporting: false,
          },
        ]
      })

      const rolesColumns = computed((): Column[] => {
        return [
          {
            caption: 'ID',
            dataField: 'id',
            allowGrouping: false,
            dataType: 'number',
          },
          {
            dataField: 'name',
            caption: 'Role Name',
            allowGrouping: false,
          },
          {
            dataField: 'description',
            caption: 'Description',
            allowGrouping: false,
          },
          {
            dataField: 'active_users',
            caption: 'Active Users',
          },
          {
            calculateCellValue: (row: any) => {
              return row.is_active ? 'Yes' : 'No'
            },
            caption: 'Is Active',
            allowGrouping: true,
          },
          {
            calculateCellValue: (row: any) => {
              return row.is_system ? 'Yes' : 'No'
            },
            caption: 'Is System',
            allowGrouping: true,
          },
          {
            caption: 'Permissions Matrix',
            calculateCellValue: () => 'Permissions',
            cellTemplate: function (container, options) {
              renderCellLink(
                container,
                'Permissions Matrix',
                'RolePermissionsMatrix',
                {
                  id: options.data.id,
                }
              )
            },
            allowGrouping: false,
            allowExporting: false,
          },
        ]
      })

      const tabs = [
        { name: 'Users', url: `${route.path}?tab=users` },
        { name: 'Roles', url: `${route.path}?tab=roles` },
        { name: 'Settings', url: `${route.path}?tab=settings` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Users', url: '/users' },
        ]

        if (!usersColumns.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
          url: route.fullPath,
        })

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'users' } })
        }

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        store.commit('genericStore/setTabs', { tabs: tabs })
      })

      return {
        isTab,
        usersColumns,
        rolesColumns,
      }
    },
  }
