import { youngPeople } from './youngPeople'
import { localAuthorities } from './localAuthorities'
import { youngPersonLegalStatuses } from './youngPersonLegalStatuses'
import { youngPersonTypes } from './youngPersonTypes'

export default {
  youngPeople,
  localAuthorities,
  youngPersonLegalStatuses,
  youngPersonTypes,
}
