import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_documents = _resolveComponent("documents")!
  const _component_task_logs = _resolveComponent("task-logs")!
  const _component_task_attachments = _resolveComponent("task-attachments")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_recruitment_check_attachments = _resolveComponent("recruitment-check-attachments")!
  const _component_care_group_attachments = _resolveComponent("care-group-attachments")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isTab('documents'))
      ? (_openBlock(), _createBlock(_component_documents, {
          key: 0,
          sensitive: true
        }))
      : _createCommentVNode("", true),
    ($setup.isTab('logs'))
      ? (_openBlock(), _createBlock(_component_task_logs, {
          key: 1,
          sensitive: true
        }))
      : _createCommentVNode("", true),
    ($setup.isTab('task-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 2,
          sensitive: true
        }))
      : _createCommentVNode("", true),
    ($setup.isProject)
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 3,
          sensitive: true
        }))
      : _createCommentVNode("", true),
    ($setup.isRecruitmentCheck)
      ? (_openBlock(), _createBlock(_component_recruitment_check_attachments, {
          key: 4,
          sensitive: true
        }))
      : _createCommentVNode("", true),
    ($setup.isTab('caregroup-attachments'))
      ? (_openBlock(), _createBlock(_component_care_group_attachments, {
          key: 5,
          sensitive: true
        }))
      : _createCommentVNode("", true)
  ], 64))
}