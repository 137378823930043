import axios from 'axios'

export default {
  deleteMany: async (payload: any) => {
    return axios
      .post(`regions/delete-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post(`regions/restore-many`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  index: async (params: any) => {
    return axios
      .get('regions', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (params: any) => {
    return axios
      .get(`regions/${params.id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (params: any) => {
    return axios
      .post('regions', params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  update: async (params: any) => {
    return axios
      .put(`regions/${params.id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
