
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import _ from 'lodash'
  import { defineComponent, PropType, reactive, ref, Ref, onMounted } from 'vue'
  import {
    getFieldComponent,
    fieldsWithTriggers,
  } from '@/composables/ManageLog'
  import Fields from './index'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { TableState } from '@/types/components/forms/actual/Table'
  import { TaskLogValue } from '@/types/modules/tasks/TaskLogValue'
  import { FormField } from '@/types/modules/forms/FormField'
  import { Task } from '@/types/modules/tasks/Task'

  export default defineComponent({
    components: {
      ...Fields,
      CcButton,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
      task: {
        type: Object as PropType<Task>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const state: TableState = reactive({
        row_structure: null,
        rows: {},
        headings: [],
      })

      const registerCustomEventListeners = (field: any) => {
        let nonValueFields = [
          'field_group_heading',
          'multi_step_form_section',
          'embed_files',
          'inline_image',
        ]
        let listeners = {} as Record<any, any>

        if (!nonValueFields.includes(field.field.system_name)) {
          listeners.valueUpdated = valueUpdated
        }

        return listeners
      }

      const addTableRow = () => {
        // grab first row and make copy
        let row = JSON.parse(JSON.stringify(state.row_structure))
        let newRowIndex = Object.keys(state.rows).length++

        // set value to null and add new row_index
        row.forEach((field: any) => {
          field.value = null
          field.row_index = newRowIndex
        })

        // push copy to existing rows
        state.rows[newRowIndex] = row
      }

      const rowData = ref([]) as Ref<any[]>

      const valueUpdated = (field: any) => {
        const existingRowData = rowData.value.find(
          (data) =>
            data.form_field_id === field.form_field_id &&
            data.row_index === field.row_index
        )

        if (existingRowData) {
          existingRowData.value = field.value
          if (field.triggers) {
            existingRowData.triggers = field.triggers
          }
        } else {
          rowData.value.push(field)
        }

        emit('value-updated', {
          form_field_id: props.fieldAttributes.id,
          system_name: props.fieldAttributes.field?.system_name,
          value: null,
          rows: rowData.value,
        })
      }

      const getFieldAttributes = (field: FormField, rowIndex: number) => {
        let formatted: Record<string, any> = {
          id: Number(field.id),
          field: field.field,
          value: getFieldValue(field),
          label: field.name,
          instructions: field.instructions || undefined,
          description: field.description || undefined,
          in_table: true,
          table_id: field.parent_id,
          row_index: rowIndex,
        }

        field.field_attributes!.forEach((attribute: any) => {
          if (
            !formatted[attribute.name] ||
            formatted[attribute.name] === undefined
          ) {
            formatted[attribute.name] = attribute.value
            if (attribute.name === 'triggered_task_id') {
              formatted['triggered_task_id'] = Number(attribute.value)
              formatted['triggered_task'] = attribute.triggered_task
            }
          }
        })

        if (field.field!.system_name === 'system_list') {
          formatted['system_list'] = {}
          formatted['system_list']['id'] = Number(
            field.field_attributes!.find(
              (attribute: any) => attribute.name === 'value'
            ).value
          )
          formatted['system_list']['in_preview_mode'] = false
        }

        if (fieldsWithTriggers().indexOf(field.field!.system_name!) !== -1) {
          formatted['project_id'] =
            Number(props.fieldAttributes.project?.id) || null
          formatted['project'] = props.task.project || null
          formatted['form_types'] = props.task.form.types
          formatted['existing_triggered_forms'] =
            props.task.existing_triggered_forms
          formatted['project_parent_id'] = Number(
            props.fieldAttributes.project_parent_id
          )
          formatted['options'] = field.options
        }

        if (props.fieldAttributes.value.log) {
          let relatedLogValue = props.fieldAttributes.value.log.values?.find(
            (value: TaskLogValue) =>
              Number(value.form_field_id) === Number(formatted.id)
          )

          if (field.field!.system_name === 'related_forms') {
            formatted['related_tasks'] = relatedLogValue?.related_forms
          }
        }

        return formatted
      }

      const getFieldValue = (field: FormField) => {
        if (props.fieldAttributes.value.log) {
          let relatedLogValue = props.fieldAttributes.value.log.values?.find(
            (value: TaskLogValue) => value.form_field_id === field.id
          )

          return relatedLogValue?.value
        }

        // otherwise check for default attribute and use that
        let defaultValueAttribute = field.field_attributes?.find(
          (attribute: any) => {
            return attribute.name === 'value'
          }
        )

        if (defaultValueAttribute) {
          return defaultValueAttribute.value
        }

        return undefined
      }

      const getProps = (field: any, rowIndex: number) => {
        return {
          fieldAttributes: getFieldAttributes(field, rowIndex),
        }
      }

      const removeRow = (row: string | number) => {
        delete state.rows[Number(row)]
      }

      const buildTable = () => {
        state.rows = {}
        state.headings = []

        let orderedValues = _.orderBy(
          props.fieldAttributes.value.fields,
          (item) => item.order,
          ['asc']
        )

        for (let field of orderedValues) {
          let rowIndex = 0

          if (props.fieldAttributes.value.log) {
            let relatedLogValue = props.fieldAttributes.value.log.values?.find(
              (value: TaskLogValue) =>
                Number(value.form_field_id) === Number(field.id)
            )

            if (relatedLogValue) {
              rowIndex = Number(relatedLogValue.row_index)
            }
          }

          if (!state.rows[rowIndex]) {
            state.rows[rowIndex] = []
          }

          state.rows[rowIndex].push(field)
        }

        if (state.rows[0]) {
          state.row_structure = JSON.parse(JSON.stringify(state.rows[0]))
          state.headings = _.orderBy(
            state.rows[0],
            (item) => item.field.order,
            ['asc']
          ).map((field) => field.name)
        }
      }

      onMounted(() => {
        buildTable()
      })

      return {
        removeRow,
        state,
        props,
        getProps,
        getFieldComponent,
        addTableRow,
        valueUpdated,
        registerCustomEventListeners,
      }
    },
  })
