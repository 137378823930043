
  import { useStore } from 'vuex'
  import { defineComponent, computed, reactive, onMounted } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ConfigureWidgetCard from '@/components/Generic/Card/ConfigureWidgetCard.vue'

  export default defineComponent({
    emits: ['next-clicked', 'cancel-clicked', 'finish-clicked'],
    components: {
      ButtonGroup,
      CcButton,
      ConfigureWidgetCard,
    },
    setup(props, { emit }) {
      props
      const store = useStore()
      const state = reactive({
        selectedWidget: Object(),
      })
      const widgetTypes = computed(() => store.getters['overview/widgetTypes'])

      const nextClicked = () => {
        if (state.selectedWidget.type === 'tasks-per-form') {
          emit('finish-clicked', { type: 'tasks-per-form' })
        } else {
          emit('next-clicked', 'widgetConfiguration-2', state.selectedWidget)
        }
      }

      onMounted(() => {
        store.dispatch('overview/getWidgetTypes')
      })

      return {
        widgetTypes,
        state,
        nextClicked,
      }
    },
  })
