import { ActionTree, GetterTree, MutationTree } from 'vuex'
import YoungPeopleService from '@/services/projects/youngPeople'
import { Resident } from '@/types/modules/projects/Resident'
import { TaskLog } from '@/types/modules/tasks/TaskLog'
import { Project } from '@/types/modules/projects/Project'

export type YoungPeopleGetter = GetterTree<YoungPeopleState, any>

export interface YoungPeopleState {
  pageDetails: any
  customFieldGroups: object[]
  young_people: Resident[]
  logs: TaskLog[]
  associations: Project[]
  relationships: object[]
  photos: object[]
  youngPerson: Resident | null
}

export const state: YoungPeopleState = {
  youngPerson: null,
  young_people: [],
  logs: [],
  associations: [],
  relationships: [],
  photos: [],
  pageDetails: undefined,
  customFieldGroups: [],
}

export const getters: YoungPeopleGetter = {
  getYoungPerson: (state) => state.youngPerson,
  allYoungPeople: (state) => state.young_people,
  youngPeopleLogs: (state) => state.logs,
  youngPeopleAssociations: (state) => state.associations,
  relationships: (state) => state.relationships,
  photos: (state) => state.photos,
}

export const actions: ActionTree<YoungPeopleState, any> = {
  index: async (context, params) => {
    const response = await YoungPeopleService.index(params)

    if (response.success && params.per_page) {
      context.commit('setYoungPeople', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setYoungPeople', response.data)

      return response
    }

    return response
  },
  show: (context, id) => {
    const response = Promise.resolve(YoungPeopleService.show(id))

    response.then((response) => {
      if (response.success) {
        context.commit('setYoungPerson', response.data)
      }
    })

    return response
  },
  getRelationships: async (context, params) => {
    const response = await YoungPeopleService.getRelationships(params)

    if (response.success && params.per_page) {
      context.commit('setRelationships', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setRelationships', response.data)

      return response
    }

    return response
  },

  getPhotos: (context, params) => {
    let response = Promise.resolve(YoungPeopleService.getPhotos(params))

    response.then((response) => {
      if (response.success) {
        context.commit('setPhotos', response.data)
      }
    })

    return response
  },
  deletePhoto: (context, params) => {
    let response = Promise.resolve(YoungPeopleService.deletePhoto(params))

    response.then((response) => {
      if (response.success) {
        context.commit(
          'setPhotos',
          state.photos.filter((photo: any) => photo.id !== params.imageId)
        )
      }
    })
  },
}

export const mutations: MutationTree<YoungPeopleState> = {
  setYoungPerson: (state, person: Resident) => (state.youngPerson = person),
  setYoungPeople: (state, youngPeople: Resident[]) => {
    state.young_people = youngPeople
  },
  setRelationships: (state, relationships) => {
    state.relationships = relationships
  },
  setPhotos: (state, photos) => (state.photos = photos),
}

export const youngPeople = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
