import { GetterTree, MutationTree, ActionTree } from 'vuex'
import DocumentService from '@/services/documents/documents'
import { DocumentClass } from '@/types/modules/documents/DocumentClass'
import { Document } from '@/types/modules/documents/Document'
import { DocumentAcknowledgement } from '@/types/modules/documents/DocumentAcknowledegment'
import { DocumentAccessLog } from '@/types/modules/documents/DocumentAccessLog'
import { DocumentPermission } from '@/types/modules/documents/DocumentPermission'

export type DocumentGetter = GetterTree<DocumentState, any>

export interface DocumentState {
  documents: Document[]
  classes: DocumentClass[]
  documentPermissions: DocumentPermission[]
  documentsToAcknowledge: Document[]
  acknowledgements: DocumentAcknowledgement[]
  accessLogs: DocumentAccessLog[]
}

export const state: DocumentState = {
  documents: [],
  classes: [],
  documentPermissions: [],
  documentsToAcknowledge: [],
  acknowledgements: [],
  accessLogs: [],
}

export const getters: DocumentGetter = {
  documents: (state) => state.documents,
  classes: (state) => state.classes,
  documentPermissions: (state) => state.documentPermissions,
  getDocumentsToAcknowledge: (state) => state.documentsToAcknowledge,
  acknowledgements: (state) => state.acknowledgements,
  accessLogs: (state) => state.accessLogs,
}

export const actions: ActionTree<DocumentState, any> = {
  index: async (context, params) => {
    const response = await DocumentService.index(params)

    if (response.success && params.per_page) {
      context.commit('setDocuments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setDocuments', response.data)

      return response
    }

    return response
  },
  create: (context, payload) => {
    let result = Promise.resolve(DocumentService.create(payload))

    result.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setDocument', response.data)
      }
    })
  },
  update: (context, payload) => {
    let result = Promise.resolve(
      DocumentService.update(payload.id, payload.document)
    )

    result.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setDocument', response.data)
      }
    })

    return result
  },
  deleteMany: async (_context, payload) => {
    return await DocumentService.deleteMany(payload)
  },
  restoreMany: async (_context, payload) => {
    return await DocumentService.restoreMany(payload)
  },
  getSensitiveDocuments: async (context, params) => {
    const response = await DocumentService.getSensitiveDocuments(params)

    if (response.success && params.per_page) {
      context.commit('setDocuments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setDocuments', response.data)

      return response
    }

    return response
  },
  getDocumentClasses: async (context, params) => {
    let result = Promise.resolve(DocumentService.getDocumentClasses(params))

    result.then((response) => {
      if (response.success) {
        context.commit('setDocumentClasses', response.data)
      }
    })

    return result
  },
  getDocumentPermissions: async (context, params) => {
    let response = Promise.resolve(
      DocumentService.getDocumentPermissions(params)
    )

    response.then((response) => {
      console.log(response.message)
      context.commit('setDocumentPermissions', response.data)
    })

    return response
  },
  storeAcknowledgements: (_context, payload) => {
    return Promise.resolve(DocumentService.storeAcknowledgements(payload))
  },
  getDocumentAcknowledgements: async (context, params) => {
    const response = await DocumentService.getDocumentAcknowledgements(params)

    if (response.success && params?.per_page) {
      context.commit('setAcknowledgements', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setAcknowledgements', response.data)

      return response
    }

    return response
  },
  getSelectedDocumentsToAcknowledge: async (context) => {
    const response = await context.getters['getDocumentsToAcknowledge']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  getDocumentAccessLogs: async (context, params) => {
    const response = await DocumentService.getDocumentAccessLogs(params)

    if (response.success && params?.per_page) {
      context.commit('setAccessLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setAccessLogs', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<DocumentState> = {
  setDocuments: (state, documents: Document[]) => (state.documents = documents),
  setDocumentClasses: (state, classes: DocumentClass[]) =>
    (state.classes = classes),
  setDocument: (state, document: Document) => {
    let updated = state.documents.filter(
      (doc: Document) => doc.id !== document.id
    )

    updated.push(document)

    state.documents = updated
  },
  removeDocument: (state, documentId: number) => {
    let updated = state.documents.filter(
      (document: Document) => document.id !== documentId
    )

    state.documents = updated
  },
  setDocumentPermissions: (state, permissions: DocumentPermission[]) => {
    state.documentPermissions = permissions
  },
  setDocumentsToAcknowledge: (state, documents: Document[]) =>
    (state.documentsToAcknowledge = documents),
  setAcknowledgements: (state, acknowledgements: DocumentAcknowledgement[]) => {
    state.acknowledgements = acknowledgements
  },
  setAccessLogs: (state, accessLogs: DocumentAccessLog[]) => {
    state.accessLogs = accessLogs
  },
}

export const documents = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
