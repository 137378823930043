import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_ctx.recycleBinData.action)
    ? (_openBlock(), _createBlock(_component_data_grid, {
        key: 0,
        action: _ctx.recycleBinData.action,
        columns: _ctx.recycleBinData.columns,
        "read-only": true,
        "query-filter": {
      only: ['trashed'],
    },
        onRowsSelected: _ctx.selectionChanged
      }, {
        "toolbar-primary-button": _withCtx(() => [
          _createVNode(_component_cc_button, {
            type: "warning",
            label: "Restore",
            icon: "fa fa-trash-can-undo",
            isDisabled: !_ctx.state.selectedRows.length,
            onClick: _ctx.restore
          }, null, 8, ["isDisabled", "onClick"])
        ]),
        _: 1
      }, 8, ["action", "columns", "onRowsSelected"]))
    : _createCommentVNode("", true)
}