
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import OverviewCard from '@/components/Generic/Card/OverviewCard.vue'
  import AddChart from '@/components/Generic/Charts/AddChart.vue'
  import WidgetContainer from './partials/WidgetContainer.vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { TaskCount } from '@/types/modules/tasks/TaskCount'
  import { camelCase } from 'lodash'
  import DxLoadIndicator from 'devextreme-vue/load-indicator'

  export default defineComponent({
    emits: ['add-chart'],
    components: {
      OverviewCard,
      AddChart,
      WidgetContainer,
      DxLoadIndicator,
    },
    setup() {
      const { t } = useI18n()
      const router = useRouter()
      const store = useStore()
      const redirectToTasksOverview = (data: any) => {
        router.push({
          name: 'TasksOverview',
          query: {
            status: data.status,
          },
        })
      }

      const state = reactive({
        tasksLoaded: false,
      })
      const taskGroups = [
        'Completed Tasks',
        'Not Started Tasks',
        'Draft Tasks',
        'Awaiting Approval',
      ]
      const widgets = computed(() => store.getters['overview/widgets'])
      const taskCounts = computed(() => {
        let validTaskCounts = [] as TaskCount[]
        let completeValue = 0

        store.getters['tasks/taskCounts'].forEach((count: TaskCount) => {
          let overviewCount = count

          if (!count.group) {
            overviewCount.group = 'Not Started'
          }

          if (validGroups.includes(count.group)) {
            let name = camelCase(count.group)
            overviewCount.group = t('overview.taskCard.' + name + '.name')
            overviewCount.icon = t('overview.taskCard.' + name + '.icon')
            overviewCount.type = t('overview.taskCard.' + name + '.type')
            overviewCount.status = t('overview.taskCard.' + name + '.status')
            overviewCount.order = Number(
              t('overview.taskCard.' + name + '.order')
            )
            validTaskCounts.push(overviewCount)
          }

          if (completedGroups.includes(count.group)) {
            completeValue += count.total
          }
        })

        let completeCount = {} as TaskCount
        completeCount.group = t('overview.taskCard.completed.name')
        completeCount.total = completeValue
        completeCount.icon = t('overview.taskCard.completed.icon')
        completeCount.type = t('overview.taskCard.completed.type')
        completeCount.status = t('overview.taskCard.completed.status')
        completeCount.order = Number(t('overview.taskCard.completed.order'))
        validTaskCounts.push(completeCount)

        return validTaskCounts.sort((a, b) => a.order - b.order)
      })

      const validGroups = ['Sent For Approval', 'Draft', 'Not Started']
      const completedGroups = ['Submitted', 'Approved']
      const placeholderClasses = computed(() => ({
        'flex': true,
        'flex-row': true,
        'w-full': true,
        'min-w-full': true,
        'md:flex-col': true,
        'lg:flex-row': true,
        'rounded-md': true,
        'font-display': true,
        'py-6': true,
        'px-6': true,
        'space-x-2': true,
        'cursor-pointer': true,
        'border-2': true,
        'border-gray-200': true,
        'text-xs': true,
        'font-bold': true,
        'tracking-slight': true,
        'lowercase': true,
        'text-gray-400': true,
      }))
      onMounted(() => {
        store.dispatch('overview/getWidgets')
        store
          .dispatch('tasks/getTaskCounts', {
            group_by: '["log_status", "task_log_statuses.name"]',
          })
          .finally(() => (state.tasksLoaded = true))
      })

      return {
        state,
        widgets,
        taskCounts,
        redirectToTasksOverview,
        placeholderClasses,
        taskGroups,
      }
    },
  })
