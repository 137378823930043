<template>
  <div class="multi-step-wizard flex items-center" :class="`step-${step}`">
    <template v-for="entry in totalSteps" :key="entry">
      <div :class="stepClass(entry)" @click="onSelect(entry)">
        <span v-if="step <= entry" v-text="stepText(entry)"></span>
        <i class="fa fa-check" v-if="step > entry"></i>
        <i class="fa fa-close" v-if="step > entry"></i>
      </div>
      <div
        :class="'line l-' + entry"
        :key="entry + 'u'"
        v-if="entry < totalSteps"
      ></div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      totalSteps: {
        type: Number,
        default: 4,
      },
      step: {
        type: Number,
        default: 1,
        required: true,
      },
      type: {
        type: String,
      },
      prependText: {
        type: String,
      },
      errorSteps: {
        type: Array,
        default: () => [],
      },
    },

    methods: {
      stepClass(entry) {
        let classes = 'step flex justify-center items-center s-' + entry
        if (this.errorSteps.indexOf(entry) > -1) {
          classes += ' error'
        }
        return classes
      },

      /**
       * Text to prepend to step if provided.
       */
      stepText(entry) {
        return this.prependText ? `${this.prependText} ${entry}` : entry
      },

      /**
       * Emits selected step number
       *
       * @param {Number} step the step to display
       */
      onSelect(step) {
        this.$emit('onSelected', step)
      },
    },
  }
</script>
<style>
  .multi-step-wizard .step {
    background: #4d5968;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    text-transform: lowercase;
    font-weight: 600;
    font-family: 'Mulish';
    height: 50px;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    font-size: 0.8rem;
    width: 50px;
  }
  .multi-step-wizard .step .fa-close {
    display: none;
  }
  .multi-step-wizard .step.error {
    background: #e99a9f !important;

    color: #fff;
  }
  .multi-step-wizard .step.error .fa-check {
    display: none;
  }
  .multi-step-wizard .step.error .fa-close {
    display: inline-block;
  }
  .multi-step-wizard.step:hover {
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  .multi-step-wizard.step:active {
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }
  .multi-step-wizard .line {
    -webkit-box-flex: 1;

    -ms-flex-positive: 1;

    background: #ddd;

    flex-grow: 1;

    height: 5px;
  }
  .multi-step-wizard.step-1 .s-1 {
    background: #193a66;
  }
  .multi-step-wizard.step-1 .l-1,
  .multi-step-wizard.step-1 .s-2 {
    background: #4d5968;
  }
  .multi-step-wizard.step-2 .l-1,
  .multi-step-wizard.step-2 .s-1 {
    background: #136c43;
  }
  .multi-step-wizard.step-2 .s-2 {
    background: #193a66;
  }
  .multi-step-wizard.step-2 .l-2,
  .multi-step-wizard.step-2 .s-3 {
    background: #4d5968;
  }
  .multi-step-wizard.step-3 .l-1,
  .multi-step-wizard.step-3 .l-2,
  .multi-step-wizard.step-3 .s-1,
  .multi-step-wizard.step-3 .s-2 {
    background: #136c43;
  }
  .multi-step-wizard.step-3 .s-3 {
    background: #193a66;
  }
  .multi-step-wizard.step-3 .l-3,
  .multi-step-wizard.step-3 .s-4 {
    background: #4d5968;
  }
  .multi-step-wizard.step-4 .l-1,
  .multi-step-wizard.step-4 .l-2,
  .multi-step-wizard.step-4 .l-3,
  .multi-step-wizard.step-4 .s-1,
  .multi-step-wizard.step-4 .s-2,
  .multi-step-wizard.step-4 .s-3 {
    background: #136c43;
  }
  .multi-step-wizard.step-4 .l-4,
  .multi-step-wizard.step-4 .s-5 {
    background: #4d5968;
  }
  .multi-step-wizard.step-4 .s-4,
  .multi-step-wizard.step-5 .s-5 {
    background: #193a66;
  }
  .multi-step-wizard.step-5 .l-5,
  .multi-step-wizard.step-5 .s-6 {
    background: #4d5968;
  }
  .multi-step-wizard.step-5 .l-1,
  .multi-step-wizard.step-5 .l-2,
  .multi-step-wizard.step-5 .l-3,
  .multi-step-wizard.step-5 .l-4,
  .multi-step-wizard.step-5 .s-1,
  .multi-step-wizard.step-5 .s-2,
  .multi-step-wizard.step-5 .s-3,
  .multi-step-wizard.step-5 .s-4 {
    background: #136c43;
  }
  .multi-step-wizard.step-6 .s-6 {
    background: #193a66;
  }
  .multi-step-wizard.step-6 .l-6,
  .multi-step-wizard.step-6 .s-7 {
    background: #4d5968;
  }
  .multi-step-wizard.step-6 .l-1,
  .multi-step-wizard.step-6 .l-2,
  .multi-step-wizard.step-6 .l-3,
  .multi-step-wizard.step-6 .l-4,
  .multi-step-wizard.step-6 .l-5,
  .multi-step-wizard.step-6 .s-1,
  .multi-step-wizard.step-6 .s-2,
  .multi-step-wizard.step-6 .s-3,
  .multi-step-wizard.step-6 .s-4,
  .multi-step-wizard.step-6 .s-5 {
    background: #136c43;
  }
</style>
