import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects/vehicles', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: async (payload: any) => {
    return axios
      .put(`projects/vehicles/${payload.id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: async (params: any) => {
    return axios
      .get(`projects/vehicles/${params.id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: async (params: any) => {
    return axios
      .post(`projects/vehicles`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
