<template>
  <div
    @click="toggleInput"
    class="flex items-center space-x-2"
    :class="props.isDisabled ? undefined : 'cursor-pointer'"
  >
    <div
      class="transform relative rounded-full w-12 h-6 transition duration-200 ease-linear border-2 px-2 py-3"
      :class="classes"
    >
      <label
        for="toggle"
        class="top-0.5 absolute bg-white border-2 w-5 h-5 rounded-full transition-all transform duration-100 ease-linear"
        :class="[
          state.toggle ? 'right-0.5 border-white' : 'left-0.5 border-blue-300',
          props.isDisabled ? 'text-gray-300' : '',
        ]"
      ></label>
      <input
        type="checkbox"
        id="toggle"
        name="toggle"
        class="appearance-none w-full h-full active:outline-none focus:outline-none"
        @click="toggleInput"
      />
    </div>
    <small class="block font-semibold text-1xs tracking-slight lowercase">
      {{ label }}
    </small>
  </div>
</template>
<script>
  import { defineComponent, reactive, watch, computed } from 'vue'

  export default defineComponent({
    props: {
      label: {
        type: String,
        required: false,
      },
      toggle: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['input-toggled'],
    setup(props, { emit }) {
      props = reactive(props)
      const state = reactive({
        toggle: props.toggle,
      })
      watch(
        () => props.toggle,
        (val) => (state.toggle = val)
      )
      const toggleInput = () => {
        if (!props.isDisabled) {
          state.toggle = !state.toggle
          emit('input-toggled', state.toggle)
        }
      }
      const classes = computed(() => {
        return {
          'bg-gray-300': props.isDisabled,
          'border-gray-300': props.isDisabled,
          'bg-white': !props.isDisabled && !state.toggle,
          'bg-blue-300': !props.isDisabled && state.toggle,
          'border-blue-300': !props.isDisabled,
        }
      })
      return {
        toggleInput,
        state,
        props,
        classes,
      }
    },
  })
</script>
