import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.data.stock_in_use)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: {
      name: 'StockEdit',
      params: {
        id: _ctx.route.params.id,
        medicationId: _ctx.data.id,
        locationId: _ctx.data.medication_location_id,
      },
    },
        class: "text-blue-200 underline cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.name), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.data.name), 1))
}