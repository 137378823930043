import store from '@/store'
import {
  sharedState,
  getDefaultShiftColour,
  setShiftText,
  defaultPatternChanged,
} from './Rotas'
import { loadRotaSetting, rotaSetting } from './RotaSetting'
import Alert from 'sweetalert2'
import { computed } from 'vue'

export const defaultShifts = computed(
  () => store.getters['defaultShifts/getDefaultShifts']
)

export const getDefaultShifts = (params: any) =>
  store.dispatch('defaultShifts/index', params)

export const loadDefaultShifts = (projectId: number) => {
  getDefaultShifts({
    include: [projectId],
  }).then((response) => {
    response.data.forEach((shift: any) => {
      let data = {
        id: shift.id ? shift.id : null,
        default_shift_id: shift.id ? shift.id : null,
        text: setShiftText(shift.employee, shift),
        employee: shift.employee ? shift.employee : null,
        employee_project_id: shift.employee ? shift.employee.project_id : null,
        shift_type_id: shift.shift_type ? shift.shift_type.id : null,
        shift_type_name: shift.shift_type ? shift.shift_type.name : null,
        shift_type_icon: shift.shift_type ? shift.shift_type.icon : null,
        valid_job_titles: shift.valid_job_titles ? shift.valid_job_titles : [],
        image:
          shift.employee && shift.employee.image ? shift.employee.image : null,
        shift_cycle_number: shift.shift_cycle_number
          ? shift.shift_cycle_number
          : sharedState.step,
        shift_start: shift.shift_start ? shift.shift_start : new Date(),
        shift_end: shift.shift_end ? shift.shift_end : new Date(),
        color: getDefaultShiftColour(shift),
        project: shift.project ? shift.project : null,
        hidden: false,
      }

      sharedState.shifts.push(data)
    })
  })
}

export const loadDefaultShiftsForOtherProjects = (projectId: number) => {
  getDefaultShifts({
    exclude: [projectId],
  })
}

export const saveDefaultShifts = (params = {}, projectId: number) => {
  Alert.fire({
    title: 'Save Default Shifts',
    text: 'Are you sure you want to save the current changes to default shifts?',
    icon: 'warning',
  }).then((result) => {
    if (result) {
      store
        .dispatch('rotaSettings/update', {
          default_pattern_changed: defaultPatternChanged.value,
          project_id: projectId,
          shifts: sharedState.shifts,
          ...rotaSetting.value,
          ...params,
        })
        .then(() => {
          sharedState.shifts = []
          sharedState.defaultRotas = []
          loadRotaSetting(projectId)

          store.dispatch(
            'genericStore/pushNotification',
            'Default shift patterns updated successfully'
          )
        })

        .finally(() => {
          loadDefaultShiftsForOtherProjects(projectId)
        })
    }
  })
}
