
  import { reactive, computed, defineComponent } from 'vue'
  import CcPill from '@/components/Generic/Pill/Pill.vue'
  import { format, differenceInDays } from 'date-fns'

  export default defineComponent({
    components: {
      CcPill,
    },
    props: {
      type: {
        type: String,
        required: true,
        default: 'default',
      },
      statusText: {
        type: String,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
      taskId: {
        type: [String, Number],
        required: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const formattedDate = format(new Date(props.date), 'dd/MM/yyyy')
      const taskStatus = computed(() => {
        if (differenceInDays(new Date(), new Date(props.date)) > 0) {
          return 'overdue'
        }

        return 'due'
      })
      const daysOverdue = computed(() => {
        if (props.type === 'danger') {
          return (
            differenceInDays(new Date(), new Date(props.date)) + ' days ago'
          )
        }
        return ''
      })

      const labelClasses =
        'block font-semibold text-gray-300 text-xxs lowercase tracking-slight leading-5 '

      const valueClasses =
        'block font-semibold tracking-slight leading-5 text-1xs'

      return {
        valueClasses,
        labelClasses,
        taskStatus,
        formattedDate,
        daysOverdue,
        props,
      }
    },
  })
