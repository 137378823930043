import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bespoke_report_wizard = _resolveComponent("bespoke-report-wizard")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_set_runtime_parameters = _resolveComponent("set-runtime-parameters")!
  const _component_manage_draft = _resolveComponent("manage-draft")!
  const _component_manage_report = _resolveComponent("manage-report")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_bespoke_report_wizard, {
      steps: _ctx.steps,
      "active-step": _ctx.activeStep,
      "save-label": "Save",
      onStepChanged: _ctx.stepChanged,
      onSubmitClicked: _ctx.submitClicked
    }, null, 8, ["steps", "active-step", "onStepChanged", "onSubmitClicked"]),
    (_ctx.errors && Object.values(_ctx.errors).length)
      ? (_openBlock(), _createBlock(_component_validation_errors, {
          key: 0,
          errors: _ctx.errors
        }, null, 8, ["errors"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeStep === 1 && _ctx.report)
        ? (_openBlock(), _createBlock(_component_set_runtime_parameters, {
            key: 0,
            report: _ctx.report,
            onUpdate: _ctx.update
          }, null, 8, ["report", "onUpdate"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_ctx.activeStep === _ctx.steps.length - 1 && _ctx.report && _ctx.report.has_draft)
      ? (_openBlock(), _createBlock(_component_manage_draft, {
          key: 1,
          draft: _ctx.report.draft,
          onDraftRestored: _ctx.draftHandled,
          onDraftDiscarded: _ctx.draftHandled
        }, null, 8, ["draft", "onDraftRestored", "onDraftDiscarded"]))
      : _createCommentVNode("", true),
    (_ctx.showManageReportStep)
      ? (_openBlock(), _createBlock(_component_manage_report, {
          key: 2,
          "onUpdate:dataSets": _ctx.updateDataSets,
          datasources: _ctx.datasources,
          report: _ctx.report,
          ref: "manageReport"
        }, null, 8, ["onUpdate:dataSets", "datasources", "report"]))
      : _createCommentVNode("", true)
  ]))
}