
  import { defineComponent, PropType } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ExtraOptionsButton from '@/components/Generic/Button/ExtraOptionsButton.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      ExtraOptionsButton,
    },
    props: {
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
      showEdit: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: ['edit-log', 'copy-log', 'option-clicked'],
    setup(props, { emit }) {
      const editLog = () => emit('edit-log')
      const copyLog = () => emit('copy-log')
      const optionClicked = (option: string) => emit('option-clicked', option)

      return {
        props,
        editLog,
        copyLog,
        optionClicked,
      }
    },
  })
