
  import { defineComponent, PropType, reactive } from 'vue'
  import { CalendarEventInstance } from '@/types/modules/calendar/CalendarEventInstance'
  import { parseISO } from 'date-fns' 
  import format from 'date-fns/format'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<CalendarEventInstance>,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
        format,
        parseISO,
      }
    },
  })
