import store from '@/store'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import dxDataGrid from 'devextreme/ui/data_grid'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter'
import { LoadOptions } from 'devextreme/data'
import { isArray } from 'lodash'
import { RouteRecordName, RouteParams, LocationQueryRaw } from 'vue-router'
import router from '@/router'

export const renderCellLink = (
  container: HTMLElement,
  content: string,
  name: RouteRecordName,
  params?: RouteParams | undefined,
  query?: LocationQueryRaw | undefined,
  disabled: Boolean = false
) => {
  let element = disabled
    ? document.createElement('a')
    : document.createElement('p')

  element.appendChild(document.createTextNode(content))
  element.classList.add('font-semibold', 'dx-template-wrapper')

  if (!disabled) {
    element.classList.add('text-blue-200', 'underline', 'cursor-pointer')

    element.addEventListener('click', function () {
      router.push({
        name,
        params,
        query,
      })
    })
  }

  container.append(element)
}

export const exportGridToPdf = (grid?: dxDataGrid) => {
  const pdf = new jsPDF()
  grid?.beginCustomLoading('exporting')
  exportDataGridToPdf({
    jsPDFDocument: pdf,
    component: grid,
    selectedRowsOnly: grid?.getSelectedRowKeys().length ? true : false,
  })
    .then(() => {
      grid?.endCustomLoading()
      pdf.output('pdfobjectnewwindow')
    })
    .catch(() => {
      store.dispatch(
        'genericStore/pushNotification',
        'Error exporting grid to pdf'
      )
    })
}

export const exportGridToExcel = (grid?: dxDataGrid, name?: string) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Sheet 1')

  grid?.beginCustomLoading('exporting')

  exportDataGridToExcel({
    component: grid,
    worksheet,
    autoFilterEnabled: true,
    selectedRowsOnly: grid?.getSelectedRowKeys().length ? true : false,
  })
    .then(() => {
      grid?.endCustomLoading()
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          name ? `${name}.xlsx` : 'grid.xlsx'
        )
      })
    })
    .catch(() => {
      store.dispatch(
        'genericStore/pushNotification',
        'Error exporting grid to excel'
      )
    })
}

const getOperator = (operator: string) => {
  const mapping: Record<string, string> = {
    contains: `LIKE`,
    notcontains: `NOT LIKE`,
    startswith: `LIKE`,
    endswith: `LIKE`,
  }

  return mapping[operator] || operator
}

const getFilterValue = (operator: string, value: string) => {
  const mapping: Record<string, string> = {
    contains: `%${value}%`,
    notcontains: `%${value}%`,
    startswith: `${value}%`,
    endswith: `%${value}`,
  }

  return mapping[operator] || value
}

export const buildSorting = (loadOptions: LoadOptions) => {
  if (!loadOptions.sort) return

  let sorting = []

  for (const sorter of Object.values(loadOptions.sort)) {
    sorting.push([sorter.selector, sorter.desc ? 'desc' : 'asc'])
  }

  return sorting
}

export const buildPagination = (loadOptions: LoadOptions) => {
  const skip = loadOptions.skip || 0
  const take = loadOptions.take || 10

  return (skip + take) / take
}

export const buildFilters = (loadOptions: LoadOptions) => {
  if (!loadOptions.filter) return

  let filters: any[][] = []

  loadOptions.filter.forEach((filter: string[]) => {
    if (isArray(filter)) {
      if (!isArray(filter[0])) {
        filters.push([
          filter[0],
          getOperator(filter[1]),
          getFilterValue(filter[1], filter[2]),
        ])
      } else {
        filter.forEach((f: any) => {
          if (isArray(f)) {
            filters.push([f[0], getOperator(f[1]), getFilterValue(f[1], f[2])])
          }
        })
      }
    } else {
      if (loadOptions.filter.indexOf('columnIndex') && filter !== 'and') {
        filters = [
          [
            loadOptions.filter[0],
            getOperator(loadOptions.filter[1]),
            getFilterValue(loadOptions.filter[1], loadOptions.filter[2]),
          ],
        ]
      }
    }
  })

  return filters
}
