
  import { computed, defineComponent, reactive } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'Referee Name',
          dataField: 'name',
          allowGrouping: true,
        },
        {
          caption: 'Referee Position',
          dataField: 'position',
          allowGrouping: true,
        },
        {
          caption: 'Rating',
          dataField: 'rating.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Collected On',
          dataField: 'submitted_on',
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          allowGrouping: true,
        },
      ])

      const filters = computed(() => {
        return {
          employee_id: props.id,
          relations: ['rating'],
        }
      })

      return {
        columns,
        filters,
      }
    },
  })
