
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, watch } from 'vue'
  import { isTab } from '@/composables/Generic'
  import PasswordPolicy from './partials/PasswordPolicy.vue'
  import CalendarSettings from './partials/CalendarSettings.vue'
  import GeneralSettings from './partials/GeneralSettings.vue'
  import SystemListSettings from './partials/SystemListSettings.vue'

  export default defineComponent({
    components: {
      GeneralSettings,
      CalendarSettings,
      SystemListSettings,
      PasswordPolicy,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      const tabs = [
        { name: 'General', url: `${route.path}?tab=general-settings` },
        {
          name: 'Password Policies',
          url: `${route.path}?tab=password-policies`,
        },
        { name: 'Calendar', url: `${route.path}?tab=calendar-settings` },
        { name: 'System Lists', url: `${route.path}?tab=system-lists` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Settings', url: '/settings' },
          {
            name: tabs.find((tab) => tab.url === route.query.tab?.toString()!)
              ?.name!,
            url: '#',
          },
        ]

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
          immediate: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'general-settings' } })
        }

        store.commit('genericStore/setTabs', { tabs: tabs })
      })

      return {
        isTab,
      }
    },
  })
