
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import RightToWork from './RightToWork.vue'
  import DbsCheck from './DbsCheck.vue'
  import ProofOfIdentity from './ProofOfIdentity.vue'
  import OverseasCriminalRecord from './OverseasCriminalRecord.vue'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { Employee } from '@/types/modules/projects/Employee'
  import { serialize } from 'object-to-formdata'
  export default defineComponent({
    components: {
      RightToWork,
      DbsCheck,
      ProofOfIdentity,
      Chips,
      OverseasCriminalRecord,
    },
    props: {
      employee: {
        type: Object as PropType<Employee>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        chips: [
          'Right To Work',
          'Dbs',
          'Proof Of Identity',
          'Overseas Criminal Record',
        ],
      })

      const payload = ref({}) as Record<string, any>
      const formData = ref(new FormData()) as Ref<FormData>

      const getEvidenceTypes = (recruitmentCheckType: string) => {
        if (
          !recruitmentCheckType ||
          recruitmentCheckType === 'Overseas Criminal Record'
        ) {
          return
        }

        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('staffs/getEvidenceTypes', {
            evidence_type: recruitmentCheckType
              .toLowerCase()
              .replace(/ /g, '_'),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const chipClicked = (chip: string) => {
        store.dispatch('genericStore/setActiveChip', { [route.fullPath]: chip })
      }

      const activeChip = computed(
        () => store.getters['genericStore/activeChips'][route.fullPath]
      )

      const submitRightToWork = async () => {
        return store.dispatch('employeeRightToWork/store', {
          id: props.employee.id,
          data: formData.value,
        })
      }

      const submitDbs = async () => {
        return store.dispatch('employeeDbs/store', {
          id: props.employee.id,
          data: formData.value,
        })
      }

      const submitProofOfIdentity = async () => {
        return store.dispatch('employeeProofOfIdentity/store', {
          id: props.employee.id,
          data: formData.value,
        })
      }

      const submitOverseasCriminalRecord = async () => {
        return store.dispatch('employeeOverseasCriminalRecord/store', {
          id: props.employee.id,
          data: formData.value,
        })
      }

      const submitClicked = async (type: string) => {
        if (!payload.value) {
          store.dispatch(
            'genericStore/pushNotification',
            'Please make changes to the form'
          )
        }
        store.dispatch('genericStore/showPageLoader', true)

        formData.value = serialize(payload.value, {
          indices: true,
          booleansAsIntegers: true,
        })

        return await new Promise((resolve, reject) => {
          if (type === 'right-to-work') resolve(submitRightToWork())
          if (type === 'dbs') resolve(submitDbs())
          if (type === 'proof-of-identity') resolve(submitProofOfIdentity())
          if (type === 'overseas-criminal-record')
            resolve(submitOverseasCriminalRecord())

          reject('No valid type was provided')
        })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
          })
          .finally(() => {
            payload.value = {}
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      watch(activeChip, (recruitmentCheckType) => {
        getEvidenceTypes(recruitmentCheckType)
      })

      const payloadUpdated = (record: Record<string, any>) => {
        payload.value = record
      }

      onMounted(() => {
        if (!activeChip.value) {
          store.dispatch('genericStore/setActiveChip', {
            [route.fullPath]: 'Right To Work',
          })
        }
      })

      return {
        state,
        payload,
        activeChip,
        chipClicked,
        submitClicked,
        payloadUpdated,
      }
    },
  })
