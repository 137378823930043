import { GetterTree, MutationTree, ActionTree } from 'vuex'
import RegReportService from '@/services/regulatory_reports/reports'
import { RegulatoryReport } from '@/types/modules/regulatory_reports/RegulatoryReport'

export type RegReportGetter = GetterTree<RegReportState, any>

export interface RegReportState {
  reports: RegulatoryReport[]
  report: RegulatoryReport | null
}

export const state: RegReportState = {
  reports: [],
  report: null,
}

export const getters: RegReportGetter = {
  reports: (state) => state.reports,
  report: (state) => state.report,
}

export const actions: ActionTree<RegReportState, any> = {
  index: async (context, params) => {
    const response = await RegReportService.index(params)

    if (response.success && params.per_page) {
      context.commit('setReports', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setReports', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    let results = Promise.resolve(RegReportService.show(params))

    results.then((response) => {
      if (response.success) {
        console.log(response.message)
        context.commit('setReport', response.data)
      }
    })
  },
}

export const mutations: MutationTree<RegReportState> = {
  setReports: (state, reports: RegulatoryReport[]) => {
    state.reports = reports
  },
  setReport: (state, report: RegulatoryReport) => {
    state.report = report
  },
}

export const regReports = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
