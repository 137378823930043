
  import { defineComponent } from 'vue'
  import { DxLoadPanel } from 'devextreme-vue/load-panel'

  export default defineComponent({
    components: {
      DxLoadPanel,
    },
    props: {
      show: {
        type: Boolean,
        required: false,
        default: false,
      },
      shading: {
        type: String,
        required: false,
        default: 'rgba(0,0,0,0.4)',
      },
      message: {
        type: String,
        required: false,
        default: 'Loading',
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  })
