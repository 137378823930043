
  import {
    computed,
    defineComponent,
    PropType,
    reactive,
    ref,
    Ref,
    watch,
  } from 'vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import { RuntimeParameter } from '@/types/modules/reporting/Datasource'
  import { MultiSelect as MultiSelectOption } from '@/types/components/MultiSelect'

  export default defineComponent({
    components: {
      MultiSelect,
    },
    props: {
      parameter: {
        type: Object as PropType<RuntimeParameter>,
        required: true,
      },
    },
    emits: ['update:parameter'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({
        data_type: '',
        default: '',
        friendly_name: '',
        friendly_operator: '',
        name: '',
        operator: '',
        options: '',
        value: [],
      }) as Ref<MultiSelectListPayload>

      interface MultiSelectListPayload {
        data_type: string
        default: string
        friendly_name: string
        friendly_operator: string
        name: string
        operator: string
        options: string
        value: string[]
      }

      const options = computed((): MultiSelectOption[] => {
        return props.parameter?.options
          .split(',')
          .concat(props.parameter?.default)
          .filter((option: string) => option)
          .map((option: string) => {
            return {
              text: option,
              value: option,
            }
          })
      })

      const updateValueOptions = () => {
        if (payload.value.value.length && payload.value.value.includes('Any')) {
          payload.value.value = payload.value.value.filter(
            (option: string) => option !== 'Any'
          )
        }

        if (payload.value.value.length === 0) {
          payload.value.value = ['Any']
        }
      }

      watch(
        props.parameter,
        (parameter: RuntimeParameter) => {
          payload.value = {
            ...parameter,
            value: parameter.default?.split(',') || ['Any'],
          }
        },
        { immediate: true }
      )

      watch(payload.value, () => emit('update:parameter', payload.value), {
        immediate: true,
      })

      return {
        payload,
        options,
        updateValueOptions,
      }
    },
  })
