import FieldGroupHeading from './FieldGroupHeading.vue'
import SmallText from './SmallText.vue'
import NumericInput from './NumericInput.vue'
import DateInput from './DateInput.vue'
import TimeInput from './TimeInput.vue'
import LargeText from './LargeText.vue'
import OverrideDateInput from './OverrideDateInput.vue'
import TrueFalse from './TrueFalse.vue'
import YesNo from './YesNo.vue'
import DropdownSelectList from './DropdownSelectList.vue'
import RadioButton from './RadioButton.vue'
import SystemList from './SystemList.vue'
import InlineImage from './InlineImage.vue'
import EmbedFiles from './EmbedFiles.vue'
import RelatedTasks from './RelatedTasks.vue'
import CheckboxList from './CheckboxList.vue'
import SignatureImage from './SignatureImage.vue'
import ImageEditor from './ImageEditor.vue'

export default {
  FieldGroupHeading,
  SmallText,
  NumericInput,
  DateInput,
  TimeInput,
  LargeText,
  OverrideDateInput,
  TrueFalse,
  YesNo,
  DropdownSelectList,
  RadioButton,
  SystemList,
  InlineImage,
  EmbedFiles,
  RelatedTasks,
  CheckboxList,
  SignatureImage,
  ImageEditor,
}
