
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, computed, reactive, watch } from 'vue'
  import FilingCabinet from '../partials/FilingCabinet/FilingCabinet.vue'
  import ProjectAssociations from '../partials/ProjectAssociations/ProjectAssociations.vue'
  import ProjectAttachments from '../partials/Attachments/ProjectAttachments.vue'
  import HomeDetails from './partials/HomeDetails.vue'
  import RegReporting from '../partials/RegReporting/RegReporting.vue'
  import { isTab } from '@/composables/Generic'
  import UserPermissions from '../partials/UserPermissions/UserPermissions.vue'
  import MedicalCabinet from '@/views/modules/mars/Index.vue'
  import RotaIndex from '@/views/modules/rotas/homes/Index.vue'
  import TaskAttachments from '../partials/Attachments/TaskAttachments.vue'
  import { Home } from '@/types/modules/projects/Home'

  export default defineComponent({
    components: {
      ProjectAssociations,
      ProjectAttachments,
      FilingCabinet,
      HomeDetails,
      RegReporting,
      UserPermissions,
      MedicalCabinet,
      RotaIndex,
      TaskAttachments,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const home = computed((): Home => store.getters['homes/home'])

      const tabs = [
        { name: 'Filing Cabinet', url: `${route.path}?tab=filing-cabinet` },
        { name: 'Details', url: `${route.path}?tab=details` },
        {
          name: 'Home Attachments',
          url: `${route.path}?tab=home-attachments`,
        },
        {
          name: 'Log Attachments',
          url: `${route.path}?tab=log-attachments`,
        },
        {
          name: 'Medical Cabinets',
          url: `${route.path}?tab=medical-cabinet`,
        },
        { name: 'Reg Reporting', url: `${route.path}?tab=reg-reporting` },
        { name: 'Rotas', url: `${route.path}?tab=rotas` },
        {
          name: 'User Permissions',
          url: `${route.path}?tab=user-permissions`,
        },
        { name: 'Associations', url: `${route.path}?tab=associations` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Homes', url: '/projects/homes' },
        ]

        if (!home.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: home.value.project?.name || '',
          url: `/projects/homes/${home.value.id}`,
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'filing-cabinet' } })
        }

        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('homes/show', { id: props.id })
          .then(() => {
            store.commit('genericStore/setBreadcrumbs', {
              crumbs: crumbs.value,
            })
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        home,
        isTab,
      }
    },
  })
