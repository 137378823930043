
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { MenuTab } from '@/types/components/MenuTab'
  import { PublishedBespokeReportComment } from '@/types/modules/reporting/PublishedBespokeReportComment'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    components: {
      CcButton,
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      publishedReportId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const tabs = reactive<MenuTab[]>([
        {
          name: 'Report',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=report`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
      ])
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Published Reports',
          url: `/bespoke-reporting/${props.id}/published-reports`,
        },
        {
          name: 'Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}?tab=comments`,
        },
        {
          name: 'Delete Comments',
          url: `/bespoke-reporting/${props.id}/published-reports/${props.publishedReportId}/comments/delete`,
        },
      ])

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Comment',
          dataField: 'body',
        },
        {
          caption: 'Posted By',
          dataField: 'posted_by.username',
          width: 200,
        },
        {
          caption: 'Posted At',
          dataField: 'created_at',
          dataType: 'datetime',
          width: 200,
        },
      ])

      const comments = computed(
        (): PublishedBespokeReportComment[] =>
          store.getters['publishedBespokeReportComments/comments']
      )

      const goBack = () => {
        router.push({
          name: 'BespokeReportPublishedShow',
          params: { id: props.id, publishedReportId: props.publishedReportId },
        })
      }

      const deleteRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('publishedBespokeReportComments/deleteMany', {
            ids: comments.value.map(
              (comment: PublishedBespokeReportComment) => comment.id
            ),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            router.push({
              name: 'BespokeReportPublishedShow',
              params: {
                id: props.id,
                publishedReportId: props.publishedReportId,
              },
              query: { tab: 'comments' },
            })
          })
      }

      onMounted(() => {
        !store.getters['publishedBespokeReportComments/comments'].length &&
          router.push({
            name: 'BespokeReportPublishedShow',
            params: {
              id: props.id,
              publishedReportId: props.publishedReportId,
            },
            query: { tab: 'comments' },
          })

        store.commit('genericStore/setBreadcrumbs', { crumbs })
        store.commit('genericStore/setTabs', { tabs })
      })
      return {
        t,
        goBack,
        comments,
        columns,
        deleteRecords,
      }
    },
  })
