import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface AvailableFilter {
  id: number
  name: string
}

export interface DashboardState {
  activeFilter: AvailableFilter
}

export const state: DashboardState = {
  activeFilter: { id: 0, name: 'All Homes & Schools' },
}

export const getters: GetterTree<DashboardState, any> = {
  activeFilter: (state) => state.activeFilter,
}

export const mutations: MutationTree<DashboardState> = {
  setActiveFilter: (state, item: AvailableFilter) => {
    state.activeFilter = item
  },
}

export const actions: ActionTree<DashboardState, any> = {
  setActiveFilter: (context, item: AvailableFilter) => {
    context.commit('setActiveFilter', item)
  },
}

export const dashboard = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
