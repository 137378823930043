
  import { DxDateBox } from 'devextreme-vue/date-box'
  import { DxButton } from 'devextreme-vue/button'
  import { defineComponent, onMounted, PropType, reactive, watch } from 'vue'
  import isEqual from 'date-fns/isEqual'
  import add from 'date-fns/esm/add/index.js'
  import startOfISOWeek from 'date-fns/startOfISOWeek/index'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      DxDateBox,
      DxButton,
    },
    props: {
      currentDate: {
        type: Object as PropType<any>,
      },
      dateAdjustment: {
        type: String,
        default: 'week',
      },
      pickerFormat: {
        type: String,
        default: 'EEE d',
      },
    },
    emits: ['changed'],
    setup(props, { emit }) {
      props = reactive(props)

      watch(
        () => props.currentDate,
        () => {
          maskPickerValue()
        }
      )

      const state = reactive({
        picker: {
          id: Math.random().toString(36).substr(2, 9),
          date: props.currentDate as any,
          format: props.pickerFormat,
        },
      })

      const changed = (action: any, event: any) => {
        event = event
        if (action.includes('prev') || action.includes('next')) {
          handleButtonPress(action)
        }

        if (action.includes('picker')) {
          emit('changed', {
            selected_date: state.picker.date,
          })
        }

        maskPickerValue()
      }

      const handleButtonPress = (action: any) => {
        let adjustment = action === 'prev' ? -1 : 1
        let date = props.currentDate

        if (!isEqual(new Date(state.picker.date), new Date(date))) {
          state.picker.date = props.currentDate
        } else {
          let params = {} as Record<string, number>

          //i.e. weeks, months etc
          let duration = `${props.dateAdjustment}s`

          params[duration] = adjustment

          state.picker.date = add(new Date(state.picker.date), params)
        }
      }

      const maskPickerValue = () => {
        let element = document.querySelector(
          `[data-picker-id="${state.picker.id}"]`
        )
        let picker = element?.querySelector(
          '.dx-texteditor-input'
        ) as HTMLInputElement | null

        let date = props.currentDate ? new Date(props.currentDate) : new Date()

        if (picker) {
          picker.value =
            props.dateAdjustment === 'week'
              ? format(startOfISOWeek(date), 'EEE d')
              : format(date, 'EEE d')
        }
      }

      onMounted(() => {
        maskPickerValue()
      })

      return {
        state,
        changed,
      }
    },
  })
