import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FileCategoriesService from '@/services/files/fileCategories'
import { FileCategory } from '@/types/modules/files/FileCategory'

export type FileCategoryGetter = GetterTree<FileCategoryState, any>

export interface FileCategoryState {
  categories: FileCategory[]
  category: FileCategory | null
}

export const state: FileCategoryState = {
  categories: [],
  category: null,
}

export const getters: FileCategoryGetter = {
  categories: (state) => state.categories,
  getFileCategory: (state) => state.category,
}

export const actions: ActionTree<FileCategoryState, any> = {
  index: async (context, params) => {
    const response = await FileCategoriesService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setFileCategories', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setFileCategories', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      FileCategoriesService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setFileCategory', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(FileCategoriesService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(FileCategoriesService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(FileCategoriesService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(FileCategoriesService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(FileCategoriesService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(FileCategoriesService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(FileCategoriesService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<FileCategoryState> = {
  setFileCategories: (state, categories: FileCategory[]) =>
    (state.categories = categories),
  setFileCategory: (state, category: FileCategory) =>
    (state.category = category),
}

export const fileCategories = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
