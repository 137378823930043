
  import { defineComponent, computed, ref, Ref } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { format } from 'date-fns'
  import { getApiUrl } from '@/composables/Generic'
  import { useRouter } from 'vue-router'
  import SignatureImage from '@/components/Generic/Forms/Inputs/Actual/SignatureImage.vue'
  import AcknowledgementsTable from './partials/AcknowledgementsTable.vue'
  import { Document } from '@/types/modules/documents/Document'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      SignatureImage,
      AcknowledgementsTable,
    },
    setup() {
      const router = useRouter()
      const store = useStore()
      const authenticatedUser = computed(
        () => store.getters['auth/authenticatedUser']
      )
      const documents = computed(
        () => store.getters['documents/getDocumentsToAcknowledge']
      )

      const documentIds = ref([]) as Ref<number[]>
      const userSignature = ref() as Ref<Blob | undefined>

      const updateDocumentsToAcknowledge = (documents: Document[]) => {
        documentIds.value = documents.map((document: Document) => document.id)
      }

      const acknowledge = () => {
        store.dispatch('genericStore/showPageLoader', true)

        let data = new FormData()

        documentIds.value.forEach((id) => {
          data.append('document_ids[]', id.toString())
        })

        if (userSignature.value) {
          data.append('user_signature', userSignature.value)
        }

        store
          .dispatch('documents/storeAcknowledgements', data)
          .then((response) => {
            store.dispatch(
              'genericStore/pushNotification',
              response.data.message
            )

            localStorage.setItem('document_acknowledgments_dismissed', 'true')
            router.push({ name: 'Dashboard' })
          })
          .catch((response) => {
            store.dispatch('genericStore/showPageLoader', false)
            store.dispatch(
              'genericStore/pushNotification',
              response.data.message
            )
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const saveSignature = (data: { id: number; value: Blob | undefined }) => {
        userSignature.value = data.value
      }

      const cancel = () => {
        localStorage.setItem('document_acknowledgments_dismissed', 'true')
        router.push({ name: 'Dashboard' })
      }

      const signatureData = computed(() => {
        return {
          id: 1,
          value: getApiUrl() + '/' + authenticatedUser.value.signature,
          label: null,
          instructions: null,
          description: null,
          in_table: false,
        }
      })

      return {
        format,
        cancel,
        documents,
        acknowledge,
        saveSignature,
        signatureData,
        updateDocumentsToAcknowledge,
      }
    },
  })
