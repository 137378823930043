import { GetterTree, ActionTree, MutationTree } from 'vuex'
import SchoolService from '@/services/projects/schools'
import { School } from '@/types/modules/projects/School'

export type SchoolGetter = GetterTree<SchoolState, any>

export interface SchoolState {
  school: School | null
  schools: School[]
}

export const state: SchoolState = {
  school: null,
  schools: [],
}

export const getters: SchoolGetter = {
  schools: (state) => state.schools,
  school: (state) => state.school,
}

export const actions: ActionTree<SchoolState, any> = {
  index: async (context, params) => {
    const response = await SchoolService.index(params)

    if (response.success && params.per_page) {
      context.commit('setSchools', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setSchools', response.data)

      return response
    }

    return response
  },
  show: async (context, params) => {
    const response = await SchoolService.show(params)
    if (response.success) {
      context.commit('setSchool', response.data)
    }
    return response
  },
  update: async (context, payload) => {
    const response = await SchoolService.update(payload)
    if (response.success) {
      context.commit('setSchool', response.data)
    }
    return response
  },
}

export const mutations: MutationTree<SchoolState> = {
  setSchools: (state, schools: School[]) => {
    state.schools = schools
  },
  setSchool: (state, school: School) => {
    state.school = school
  },
}

export const schools = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
