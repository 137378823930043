
  import _ from 'lodash'
  import {
    defineComponent,
    PropType,
    reactive,
    watch,
    ref,
    onMounted,
  } from 'vue'
  import {
    getFieldComponent,
    fieldsWithTriggers,
  } from '@/composables/ManageLog'
  import Fields from './index'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { TableState } from '@/types/components/forms/actual/Table'
  import {
    FieldAttribute,
    RenderedFormFieldSchema,
  } from '@/types/components/FormBuilder'

  export default defineComponent({
    components: {
      ...Fields,
      CcButton,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const state: TableState = reactive({
        row_structure: null,
        rows: {},
        headings: [],
      })

      const value = ref()

      watch(
        value,
        () => {
          state.rows = {}
          state.headings = []
          let orderedValues = _.orderBy(
            value.value,
            (item) => item.field.order,
            ['asc']
          )

          for (let field of orderedValues) {
            if (!state.rows[field.field.row_index]) {
              state.rows[field.field.row_index] = []
            }

            state.rows[field.field.row_index].push(field)
          }

          if (state.rows[0]) {
            state.row_structure = state.rows[0]
            state.headings = state.rows[0].map((field: any) => {
              return field.field.name
            })
          }
        },
        { deep: true, immediate: true }
      )

      const getFieldValue = (field: RenderedFormFieldSchema) => {
        if (field.field?.system_name === 'field_group_heading') {
          return field.name
        }

        return null
      }

      const getFieldAttributes = (field: RenderedFormFieldSchema) => {
        let formatted: Record<string, any> = {
          id: field.id,
          value: getFieldValue(field),
          label: field.name,
          instructions: field.instructions,
          description: field.description,
          options: field.options,
          in_table: field.parent_id ? true : false,
        }

        // Extra attributes only added if field is in a table
        if (formatted['in_table']) {
          formatted['table_id'] = field.parent_id
        }

        field.field_attributes?.forEach((attribute: any) => {
          if (formatted[attribute.name] == undefined) {
            formatted[attribute.name] = attribute.value
            if (attribute.name === 'triggered_task_id') {
              formatted['triggered_task'] = attribute.triggered_task
            }
          }
        })

        if (field.field?.system_name === 'field_group_heading') {
          formatted['status'] = 'in-progress'
        }

        if (fieldsWithTriggers().indexOf(field.field!.system_name) !== -1) {
          formatted['project_id'] = null
          formatted['project'] = null
          formatted['form_types'] = []
          formatted['existing_triggered_tasks'] = []
        }

        if (field.field?.system_name === 'system_list') {
          let attribute = field.field_attributes?.find(
            (attribute: FieldAttribute) => attribute.name === 'value'
          )
          formatted['system_list'] = {}
          formatted['system_list']['id'] = attribute
            ? Number(attribute.value)
            : undefined
          formatted['system_list']['project_id'] = null
        }

        return formatted
      }

      const getProps = (formField: RenderedFormFieldSchema) => {
        return {
          fieldAttributes: {
            ...getFieldAttributes(formField),
          },
        }
      }

      onMounted(() => {
        value.value = props.fieldAttributes.value
      })

      return {
        state,
        props,
        getProps,
        getFieldComponent,
      }
    },
  })
