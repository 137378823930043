import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: _ctx.t('homeDetails.ofstedDetails.urn'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_urn,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.ofsted_urn) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('homeDetails.ofstedDetails.rating'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_rating,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.ofsted_rating) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('homeDetails.ofstedDetails.ratingDate'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_rating_date,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.ofsted_rating_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.requirementNum'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_requirement_no,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.ofsted_requirement_no) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.regRequirementNum'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_req_reg_no,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.ofsted_req_reg_no) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.recommendationNum'),
        "is-required": false,
        modelValue: _ctx.payload.ofsted_rec_reg_no,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.ofsted_rec_reg_no) = $event))
      }, null, 8, ["label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: _ctx.t('homeDetails.ofstedDetails.interimRating'),
        "is-required": false,
        modelValue: _ctx.payload.interim_ofsted_rating,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.interim_ofsted_rating) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('homeDetails.ofstedDetails.ratingDate'),
        "is-required": false,
        modelValue: _ctx.payload.interim_rating_date,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.interim_rating_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.requirementNum'),
        "is-required": false,
        modelValue: _ctx.payload.interim_requirement_no,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.interim_requirement_no) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.regRequirementNum'),
        "is-required": false,
        modelValue: _ctx.payload.interim_req_reg_no,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.interim_req_reg_no) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.recommendationNum'),
        "is-required": false,
        modelValue: _ctx.payload.interim_recommendation_no,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.interim_recommendation_no) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_number_input, {
        label: _ctx.t('homeDetails.ofstedDetails.regRecommendationNum'),
        "is-required": false,
        modelValue: _ctx.payload.interim_rec_reg_no,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.interim_rec_reg_no) = $event))
      }, null, 8, ["label", "modelValue"])
    ])
  ]))
}