
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { defineComponent, onMounted, reactive, ref } from 'vue'

  export default defineComponent({
    components: { DataGrid },
    setup() {
      const state = reactive({
        action: 'documents/getDocumentAcknowledgements',
        activeChip: 'Acknowledgements',
        chips: ['Acknowledgements', 'Access Logs'],
        columns: [] as Column[],
      })

      const grid = ref()

      const acknowledgementColumns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataType: 'string',
          caption: 'Document Name',
          dataField: 'document_version.document.name',
        },
        {
          width: 100,
          allowGrouping: false,
          allowSorting: false,
          dataField: 'document_version.version',
          caption: 'Version',
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataField: 'user.username',
          caption: 'Acknowledged By',
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField:
            'document_version.document.user_acknowledgement.updated_at',
          caption: 'Acknowledged At',
        },
      ])

      const accessLogColumns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataType: 'string',
          caption: 'Document Name',
          dataField: 'document.name',
        },
        {
          width: 100,
          dataField: 'access_type',
          caption: 'Access Type',
        },
        {
          dataField: 'description',
          caption: 'description',
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataField: 'user.username',
          caption: 'Accessed By',
        },
        {
          allowGrouping: false,
          allowSorting: false,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'updated_at',
          caption: 'Accessed At',
        },
      ])

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        if (chip === 'Acknowledgements') {
          state.columns = acknowledgementColumns
          state.action = 'documents/getDocumentAcknowledgements'
        }
        if (chip === 'Access Logs') {
          state.columns = accessLogColumns
          state.action = 'documents/getDocumentAccessLogs'
        }

        grid.value?.refresh()
      }

      onMounted(() => (state.columns = acknowledgementColumns))

      return {
        state,
        grid,
        chipClicked,
      }
    },
  })
