
  import {
    defineComponent,
    computed,
    reactive,
    PropType,
    ref,
    Ref,
    watch,
  } from 'vue'
  import { buildPagination } from '@/composables/Grids'
  import { DxDataGrid, DxPager, DxPaging } from 'devextreme-vue/data-grid'
  import { useStore } from 'vuex'
  import DataSource from 'devextreme/data/data_source'
  import CustomStore from 'devextreme/data/custom_store'
  import TaskCardGridCellTemplate from './Templates/TaskCardGridCellTemplate.vue'

  export default defineComponent({
    components: {
      DxDataGrid,
      DxPager,
      DxPaging,
      TaskCardGridCellTemplate,
    },
    props: {
      action: {
        type: String,
        required: false,
        default: '',
      },
      queryFilter: {
        type: Object as PropType<Record<string, any>>,
        required: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const dataSource = computed(() => buildDataSource())

      const buildDataSource = () => {
        return new DataSource({
          store: new CustomStore({
            load: async (loadOptions) => {
              store.dispatch('genericStore/showPageLoader', true)
              return await store
                .dispatch(props.action, {
                  page: buildPagination(loadOptions),
                  per_page: loadOptions.take,
                  ...props.queryFilter,
                })
                .then((response) => response)
                .catch((error) =>
                  store.dispatch('genericStore/pushNotification', error.message)
                )
                .finally(() =>
                  store.dispatch('genericStore/showPageLoader', false)
                )
            },
          }),
        })
      }

      watch(
        () => props.queryFilter?.project_id,
        () => {
          if (dataSource.value) {
            cardGrid.value?.instance?.refresh()
          }
        }
      )

      const cardGrid = ref() as Ref<DxDataGrid | undefined>

      return {
        dataSource,
        cardGrid,
      }
    },
  })
