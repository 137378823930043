import FormProcedureIndex from '@/views/FormsProcedures.vue'
import ManageKeywords from '@/views/lookups/ManageKeywords.vue'
import ManageFormGroups from '@/views/lookups/ManageFormGroups.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import { RouteRecordRaw } from 'vue-router'
import UpdateEmail from '@/views/modules/forms/partials/UpdateEmail.vue'
import FormPreview from '@/views/modules/forms/FormPreview.vue'
import FormDesigner from '@/views/modules/forms/FormDesigner.vue'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'
import KeywordRestore from '@/views/modules/forms/partials/KeywordRestore.vue'
import FormGroupRestore from '@/views/modules/forms/partials/FormGroupRestore.vue'

export default [
  {
    path: '/forms-procedures',
    name: 'FormProcedureIndex',
    component: FormProcedureIndex,
    meta: {
      title: 'Forms and Procedures',
      module: 'procedures',
      middleware: [auth, canView],
    },
  },
  {
    path: '/forms-procedures/update-email',
    name: 'UpdateEmail',
    component: UpdateEmail,
    meta: {
      title: 'Update Email',
      module: 'procedures',
      middleware: [auth, canView],
    },
  },
  {
    path: '/forms-procedures/:id/form-preview',
    name: 'FormPreview',
    component: FormPreview,
    meta: {
      title: 'Form Preview',
      module: 'procedures',
      middleware: [auth, canView],
    },
  },
  {
    path: '/forms-procedures/form-designer',
    name: 'FormDesigner',
    component: FormDesigner,
    props: (route) => ({ id: route.query.form_id }),
    meta: {
      title: 'Form Designer',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/groups/create',
    name: 'FormGroupCreate',
    component: ManageFormGroups,
    props: () => ({
      showAction: 'formGroups/show',
      storeAction: 'formGroups/store',
    }),
    meta: {
      title: 'Create Form Group',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/groups/:id/edit',
    name: 'FormGroupEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'formGroups/show',
      updateAction: 'formGroups/update',
      deleteAction: 'formGroups/delete',
    }),
    component: ManageFormGroups,
    meta: {
      title: 'Edit Form Group',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/groups/delete',
    name: 'FormGroupDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Form Group(s)',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/groups/restore',
    name: 'FormGroupRestore',
    component: FormGroupRestore,
    meta: {
      title: 'Restore Deleted Form Groups',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/keywords/create',
    name: 'KeywordCreate',
    component: ManageKeywords,
    props: () => ({
      showAction: 'keywords/show',
      storeAction: 'keywords/store',
    }),
    meta: {
      title: 'Create Keyword',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/keywords/:id/edit',
    name: 'KeywordEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'keywords/show',
      updateAction: 'keywords/update',
      deleteAction: 'keywords/delete',
    }),
    component: ManageKeywords,
    meta: {
      title: 'Update Keyword',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/keywords/delete',
    name: 'KeywordDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Keyword(s)',
      module: 'procedures',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/forms/keywords/restore',
    name: 'KeywordRestore',
    component: KeywordRestore,
    meta: {
      title: 'Restore Deleted Keywords',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
