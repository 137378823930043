
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import { isArray } from 'lodash'
  import { defineComponent, reactive, computed, PropType } from 'vue'
  const UUID = require('uuid-js')

  export default defineComponent({
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
      },
    },
    setup(props) {
      props = reactive(props)

      const formattedValue = computed(() => {
        if (isArray(props.fieldAttributes?.value)) {
          return props.fieldAttributes?.value.map((file: File) => {
            return {
              fileId: UUID.create().toString(),
              fileName: file.name,
              fileUrl: '#',
            }
          })
        }

        if (props.fieldAttributes?.value) {
          return JSON.parse(props.fieldAttributes?.value)
        }

        return []
      })

      const getFileUrl = (file: any) =>
        `/view_file?file_id=${file.fileID}&module=form`

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-300': true,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const inputClasses = computed(() => ({
        'bg-white': true,
        'border-b-2': true,
        'border-gray-300': true,
        'py-2': true,
        'box-border resize-none': true,
        'w-full': true,
        'border-0': true,
        'border-gray-400': true,
        'focus:border-blue-300': true,
      }))

      return {
        inputClasses,
        props,
        labelClasses,
        formattedValue,
        getFileUrl,
      }
    },
  })
