import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_dx_radio_group = _resolveComponent("dx-radio-group")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-display", _ctx.props.isDisabled ? 'cursor-not-allowed' : ''])
  }, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: '',
      "is-required": _ctx.isRequired,
      "is-disabled": _ctx.props.isDisabled,
      icon: _ctx.props.isDisabled ? 'fa fa-lock text-gray-300' : undefined
    }, null, 8, ["label", "is-required", "is-disabled", "icon"]),
    _createVNode(_component_dx_radio_group, {
      items: _ctx.buttons,
      "value-expr": "id",
      "display-expr": "text",
      value: _ctx.modelValue,
      onValueChanged: _ctx.changeSelection,
      layout: _ctx.layout,
      disabled: _ctx.props.isDisabled
    }, null, 8, ["items", "value", "onValueChanged", "layout", "disabled"]),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-1", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true)
  ], 2))
}