import axios from 'axios'

export default {
  index: (params: any) => {
    return axios
      .get('projects/employees/job-titles', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any, params: any) => {
    return axios
      .get(`projects/employees/job-titles/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('projects/employees/job-titles', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: any, payload: any) => {
    return axios
      .post(`projects/employees/job-titles/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  delete: (id: any) => {
    return axios
      .delete(`projects/employees/job-titles/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  saveOrderings: (payload: any) => {
    return axios
      .post(`projects/employees/job-titles/orderings`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: (payload: any) => {
    return axios
      .post('projects/employees/job-titles/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: (payload: any) => {
    return axios
      .post('projects/employees/job-titles/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  toggleManyVisibility: (payload: any) => {
    return axios
      .post('projects/employees/job-titles/toggle-visibility', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
