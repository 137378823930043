
  import { Project } from '@/types/modules/projects/Project'
  import { defineComponent, PropType, reactive } from 'vue'

  import {
    sharedState,
    canEditTimeline,
    deleteShift,
    addShift,
    updateShift,
  } from '@/composables/rotas/Rotas'

  import RotaTimeline from '../../partials/RotaTimeline.vue'
  import { DxResource, DxView } from 'devextreme-vue/scheduler'
  import { buildForm } from '@/composables/rotas/BuildModalForm'
  import { rotaSetting } from '@/composables/rotas/RotaSetting'

  import { liveShifts } from '@/composables/rotas/ShiftInstances'

  export default defineComponent({
    components: {
      RotaTimeline,
      DxResource,
      DxView,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      const saveSchedulerInstance = (instance: any) => {
        sharedState.schedulers.push(instance.ref)
      }

      const adding = (data: any) => addShift(data, props.project)
      const updating = (data: any) => updateShift(data, props.project)
      const deleting = (data: any) => deleteShift(data)

      return {
        adding,
        updating,
        deleting,
        buildForm,
        saveSchedulerInstance,
        rotaSetting,
        sharedState,
        props,
        liveShifts,
        canEditTimeline,
      }
    },
  })
