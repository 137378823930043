import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Local Authorities",
    "create-view": "LocalAuthorityCreate",
    "edit-view": "LocalAuthorityEdit",
    "delete-view": "LocalAuthorityDelete",
    "restore-view": "LocalAuthorityRestore",
    action: "localAuthorities/index",
    "toggle-visibility-action": "localAuthorities/toggleManyVisibility",
    "delete-many-action": "localAuthorities/deleteMany",
    "reorder-action": "localAuthorities/saveOrderings"
  }))
}