
  import FormsTable from './partials/FormsTable.vue'
  import { defineComponent, reactive } from 'vue'

  export default defineComponent({
    components: {
      FormsTable,
    },
    props: {
      hidden: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
