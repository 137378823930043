
  import { MenuLink as MenuLinkType } from '@/types/components/MenuLink'
  import { MenuTab } from '@/types/components/MenuTab'
  import {
    reactive,
    computed,
    watchEffect,
    defineComponent,
    PropType,
  } from 'vue'
  import { useRoute } from 'vue-router'
  import MenuFooter from './MenuFooter.vue'
  import MenuHeader from './MenuHeader.vue'
  import MenuLink from './MenuItem.vue'
  import MenuTabs from './MenuTabs.vue'

  export default defineComponent({
    components: {
      MenuHeader,
      MenuFooter,
      MenuLink,
      MenuTabs,
    },
    props: {
      links: {
        type: Array as PropType<MenuLinkType[]>,
        required: true,
      },
      tabs: {
        type: Array as PropType<MenuTab[]>,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute()
      props = reactive(props)

      const state = reactive({
        isCollapsed: false,
        isTabsCollapsed: true,
      })

      const isActive = (link: { url: string }) => {
        return link.url !== '/' && String(route.path).includes(link.url)
          ? 'router-link-active'
          : ''
      }

      /**
       * Decides whether to hide or show tabs
       */
      watchEffect(() => {
        if (props.tabs.length > 0) {
          state.isCollapsed = true
          state.isTabsCollapsed = false
        } else {
          state.isCollapsed = false
          state.isTabsCollapsed = true
        }
      })

      const navClasses = computed(() => ({
        'flex': true,
        'flex-col': true,
        'justify-between': true,
        'font-body': true,
        'transition-all': true,
        'duration-500': true,
        'relative': true,
        'bg-gradient-to-b': true,
        'from-blue-menu-top': true,
        'to-blue-menu-bottom': true,
        'min-h-screen': true,
        'text-white': true,
        'w-16': state.isCollapsed && props.tabs.length == 0,
        'w-52':
          (state.isCollapsed && props.tabs.length > 0) ||
          (!state.isCollapsed && props.tabs.length == 0),
        'w-96': !state.isCollapsed && props.tabs.length > 0,
        'lowercase': true,
      }))

      /**
       * Collapses either first or second level menu based on if second level menu exists.
       */
      const collapseMenu = () => {
        if (props.tabs.length > 0) {
          state.isCollapsed = !state.isCollapsed
        } else {
          state.isCollapsed = !state.isCollapsed
          state.isTabsCollapsed = true
        }
      }

      return {
        props,
        state,
        navClasses,
        collapseMenu,
        isActive,
      }
    },
  })
