
  import { useStore } from 'vuex'
  import { defineComponent, computed, onMounted } from 'vue'
  import GroupingsTable from './partials/GroupingsTable.vue'
  import { Grouping } from '@/types/modules/groupings/Grouping'

  export default defineComponent({
    components: {
      GroupingsTable,
    },
    setup() {
      const store = useStore()
      const groupings = computed(
        (): Grouping[] => store.getters['groupings/groupings']
      )
      const getGroupings = (params: any) =>
        store.dispatch('groupings/index', params)

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Groupings', url: '/groupings' },
      ]

      onMounted(() => {
        getGroupings({})

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      return {
        groupings,
      }
    },
  })
