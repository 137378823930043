
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    ref,
    Ref,
    onMounted,
    watch,
  } from 'vue'
  import NumberInput from '../NumberInput.vue'

  export default defineComponent({
    components: {
      NumberInput,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const error = ref() as Ref<string | undefined>
      const value = ref() as Ref<string | undefined>

      const label = computed(() => {
        return props.fieldAttributes.in_table == false
          ? props.fieldAttributes.label
          : null
      })

      const validateInput = () => {
        if (props.fieldAttributes.required == true && !value.value) {
          error.value = 'This field is required'
          return false
        }

        if (props.fieldAttributes.max && value.value) {
          if (Number(value.value) > Number(props.fieldAttributes.max)) {
            error.value = `This field has a maximum value of ${props.fieldAttributes.max}`
            return false
          }
        }

        if (props.fieldAttributes.min && value.value) {
          if (Number(value.value) < Number(props.fieldAttributes.min)) {
            error.value = `This field has a minium value of ${props.fieldAttributes.min}`
            return false
          }
        }

        error.value = undefined
        return true
      }

      watch(value, () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: value.value,
            row_index: props.fieldAttributes.row_index,
          })
        }
      })

      onMounted(() => {
        value.value = props.fieldAttributes.value
      })

      return {
        label,
        props,
        error,
        value,
      }
    },
  })
