import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('documents', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  getDocumentCategories: async (params: any) => {
    return axios
      .get('documents/categories', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  getDocumentClasses: async (params: any) => {
    return axios
      .get('documents/classes', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  update: (id: Number, document: any) => {
    // Replace with axios call to backend
    return {
      success: true,
      message: 'Document updated successfully',
      data: {
        id: id,
        name: document.name ? document.name : 'Updated document',
        document_category_id: document.document_category_id
          ? document.document_category_id
          : 1,
        category: {
          document_category_id: 1,
          name: 'Uncategorised',
        },
        version: 2,
        requires_acknowledgement: document.requires_acknowledgement
          ? document.requires_acknowledgement
          : 1,
        updated_at: new Date(),
      },
    }
  },

  create: (data: any) => {
    return {
      success: true,
      message: 'Document updated successfully',
      data: {
        id: getRandomInt(3, 500),
        name: data.name,
        document_category_id: data.document_category_id,
        category: {
          document_category_id: 1,
          name: 'Uncategorised',
        },
        version: 0,
        requires_acknowledgement: data.requires_acknowledgement,
        updated_at: new Date(),
      },
    }
  },
  deleteMany: async (payload: any) => {
    return axios
      .post('documents/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post('documents/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveDocuments: async (params: any) => {
    return axios
      .get('documents/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getDocumentPermissions: async (params: any) => {
    return axios
      .get('documents/permissions', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  storeAcknowledgements: (payload: FormData) => {
    return axios
      .post('documents/acknowledgements', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getDocumentAcknowledgements: async (params: any) => {
    return axios
      .get('documents/acknowledgements', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getDocumentAccessLogs: async (params: any) => {
    return axios
      .get('documents/access-logs', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}

//generate random integer - remove this
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}
