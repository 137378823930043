import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.props.useRouter)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.href,
        class: "font-semibold text-blue-200 underline"
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass([_ctx.props.icon, "mx-1"]),
            title: _ctx.props.isSensitive ? 'Sensitive' : ''
          }, null, 10, _hoisted_1),
          _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        class: _normalizeClass([_ctx.lowercase ? 'lowercase' : '', "font-semibold text-blue-200 underline"]),
        href: _ctx.href,
        target: _ctx.target
      }, [
        _createElementVNode("i", {
          class: _normalizeClass([_ctx.props.icon, "mx-1"]),
          title: _ctx.props.isSensitive ? 'Sensitive' : ''
        }, null, 10, _hoisted_3),
        _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
      ], 10, _hoisted_2))
}