
  import { computed, defineComponent, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import RegReportSection from './RegReportSection.vue'

  export default defineComponent({
    components: {
      RegReportSection,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const report = computed(() => store.getters['regReports/report'])
      onMounted(() => {
        store.dispatch('regReports/show', {
          id: route.params.id,
        })
        store.getters['genericStore/breadCrumbs'].push({
          name: 'Regulatory Reporting',
          url: '#',
        })
      })

      return {
        report,
      }
    },
  })
