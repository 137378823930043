
  import { computed, defineComponent, onMounted, reactive, Ref, ref } from 'vue'
  import { goBack } from '@/composables/Generic'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import { useStore } from 'vuex'
  import { format } from 'date-fns'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import { validatePayload } from '@/composables/Validation'
  import { array, object, string } from 'yup'
  import { useRouter } from 'vue-router'
  import { DataSet } from '@/types/modules/reporting/BespokeReportDatasource'

  export default defineComponent({
    components: {
      TextBox,
      CcButton,
      TextInput,
      ButtonGroup,
      ValidationErrors,
    },
    props: {
      id: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const payload = reactive({
        name: '',
        description: '',
        definition: '',
        datasources: [] as Datasource[],
        datasets: [] as DataSet[],
        comment: '',
      })
      const errors = ref(null) as Ref<Record<string, string> | null>
      const report = computed(() => store.getters['bespokeReports/report'])
      const dataSets = computed(
        () => store.getters['bespokeReportDatasources/datasets']
      )
      const datasources = computed(
        (): Datasource[] =>
          store.getters['bespokeReportDatasources/datasources']
      )

      const payloadValidation = object({
        name: string().required('Please provide a name'),
        description: string().required('Please provide a description'),
        definition: string().required(
          'Please provide a valid report definition'
        ),
        datasources: array().required('Please provide at least one datasource'),
        datasets: array().required('Please provide at least one dataset'),
        comment: string().nullable(),
      })

      const publishReport = async () => {
        errors.value = await validatePayload(
          payloadValidation,
          payload as unknown as Record<string, unknown>
        )

        if (errors.value) {
          return
        }
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('publishedBespokeReports/store', {
            id: props.id,
            ...payload,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            return router.push({
              name: 'BespokeReportPublishedIndex',
              params: { id: report.value.id },
            })
          })
          .catch((error) => (errors.value = error.response.data.errors))
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const mapToPayload = () => {
        payload.name = `${report.value.name} - ${format(
          new Date(),
          'dd/MM/yyyy'
        )}`
        payload.definition = report.value.definition
        payload.datasources = datasources.value
        payload.datasets = dataSets.value.map((dataset: DataSet) => ({
          [dataset.system_name]: dataset.data,
        }))
      }

      onMounted(() => {
        if (
          !dataSets.value.length ||
          !datasources.value.length ||
          !report.value
        ) {
          return goBack()
        }

        mapToPayload()

        const crumbs = store.getters['genericStore/breadCrumbs']

        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            ...crumbs,
            {
              name: 'Publish Report',
              url: '#',
            },
          ],
        })
      })

      return {
        errors,
        goBack,
        payload,
        dataSets,
        datasources,
        publishReport,
      }
    },
  })
