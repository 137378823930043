import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "font-display w-full" }
const _hoisted_2 = { class: "flex items-center space-x-4 mt-4" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "fa-solid fa-circle-check text-green-200 absolute right-0 text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: _ctx.error,
      "is-required": _ctx.isRequired,
      "is-disabled": false
    }, null, 8, ["label", "error", "is-required"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.reactions), (reaction) => {
        return (_openBlock(), _createElementBlock("a", {
          href: "#",
          onClick: _withModifiers(($event: any) => (_ctx.handleInput(reaction)), ["prevent"]),
          class: "relative",
          key: reaction
        }, [
          (_ctx.modelValue === reaction)
            ? (_openBlock(), _createElementBlock("i", _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("i", {
            class: _normalizeClass(["fa-regular text-8xl", _ctx.reactions[reaction]])
          }, null, 2)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.message && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ]))
}