import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bold text-sm" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!_ctx.props.hideTimes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.combined), 1))
      : _createCommentVNode("", true)
  ]))
}