import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('projects', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: async (payload: any) => {
    return axios
      .post('projects/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: async (payload: any) => {
    return axios
      .post('projects/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateProjectPermission: (params: any) => {
    params = params
    return {
      success: true,
      message: 'updated project permission',
      data: [],
    }
  },
  projectPermissions: (params: any) => {
    return axios
      .get('projects/permissions', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getCustomFieldGroups: (params: any) => {
    params = params // this will include the project type. will fetch all the custom field groups for a specific type

    // Add response here
    return {
      success: true,
      message: 'returned custom groups successfully',
      data: [
        {
          id: 1,
          name: 'Field Group 1',
        },
      ],
    }
  },
  getCustomFields: (params: any) => {
    params = params // this will include the project type. will fetch all the custom field groups for a specific type
    let names = Array()

    switch (params.type) {
      case 'employee':
        names = [
          'further information',
          'address information',
          'employee performance',
        ]
        break

      case 'home':
        names = ['home repair', 'all chores', 'works']
        break

      case 'vehicle':
        names = ['insurance details', 'vehicle repairs', 'fleet info']
        break

      case 'youngPerson':
        names = ['resident relationships', 'room history', '']
        break

      case 'school':
        names = ['class locations', 'school dinners', 'extra information']
        break
    }
    // Add response here
    return {
      success: true,
      message: 'returned custom fields successfully',
      data: [
        {
          id: 37,
          project_type_id: 4,
          project_type_section_id: 40,
          field_id: 1,
          name: 'Test field 1',
          description: null,
          default_value: '1',
          is_required: 0,
          order: 37,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:02:43.000000Z',
          updated_at: '2022-06-09T08:02:43.000000Z',
          deleted_at: null,
          field: {
            id: 1,
            field_group_id: 2,
            name: 'True or False',
            system_name: 'true_false',
            has_mandatory_field: 0,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:22.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 40,
            project_type_id: 4,
            name: names[0],
            is_system: 0,
            order: 39,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:02:10.000000Z',
            updated_at: '2022-06-09T08:02:10.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 38,
          project_type_id: 4,
          project_type_section_id: 40,
          field_id: 2,
          name: 'Test Field 2',
          description: null,
          default_value: null,
          is_required: 1,
          order: 38,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:02:59.000000Z',
          updated_at: '2022-06-09T08:02:59.000000Z',
          deleted_at: null,
          field: {
            id: 2,
            field_group_id: 2,
            name: 'Yes or No',
            system_name: 'yes_no',
            has_mandatory_field: 1,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 40,
            project_type_id: 4,
            name: names[0],
            is_system: 0,
            order: 39,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:02:10.000000Z',
            updated_at: '2022-06-09T08:02:10.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 39,
          project_type_id: 4,
          project_type_section_id: 40,
          field_id: 3,
          name: 'Test Field 3',
          description: null,
          default_value: ['Option 2'],
          is_required: 1,
          order: 39,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:03:31.000000Z',
          updated_at: '2022-06-09T08:03:31.000000Z',
          deleted_at: null,
          field: {
            id: 3,
            field_group_id: 3,
            name: 'CheckBox List',
            system_name: 'checkbox_list',
            has_mandatory_field: 1,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 40,
            project_type_id: 4,
            name: names[0],
            is_system: 0,
            order: 39,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:02:10.000000Z',
            updated_at: '2022-06-09T08:02:10.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [
            {
              id: 12,
              project_type_attribute_id: 39,
              name: 'Option 1',
              created_at: '2022-06-09T08:03:31.000000Z',
              updated_at: '2022-06-09T08:03:31.000000Z',
            },
            {
              id: 13,
              project_type_attribute_id: 39,
              name: 'Option 2',
              created_at: '2022-06-09T08:03:31.000000Z',
              updated_at: '2022-06-09T08:03:31.000000Z',
            },
          ],
          values: [],
        },
        {
          id: 40,
          project_type_id: 4,
          project_type_section_id: 41,
          field_id: 4,
          name: 'Test Field 4',
          description: null,
          default_value: 'DP 1',
          is_required: 0,
          order: 40,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:05:42.000000Z',
          updated_at: '2022-06-09T08:05:42.000000Z',
          deleted_at: null,
          field: {
            id: 4,
            field_group_id: 3,
            name: 'Dropdown Select List',
            system_name: 'dropdown_select_list',
            has_mandatory_field: 1,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 41,
            project_type_id: 4,
            name: names[1],
            is_system: 0,
            order: 40,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:03:53.000000Z',
            updated_at: '2022-06-09T08:03:53.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [
            {
              id: 14,
              project_type_attribute_id: 40,
              name: 'DP 1',
              created_at: '2022-06-09T08:05:42.000000Z',
              updated_at: '2022-06-09T08:05:42.000000Z',
            },
            {
              id: 15,
              project_type_attribute_id: 40,
              name: 'DP 2',
              created_at: '2022-06-09T08:05:42.000000Z',
              updated_at: '2022-06-09T08:05:42.000000Z',
            },
          ],
          values: [],
        },
        {
          id: 41,
          project_type_id: 4,
          project_type_section_id: 41,
          field_id: 5,
          name: 'Test Field 5',
          description: null,
          default_value: null,
          is_required: 0,
          order: 41,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:06:10.000000Z',
          updated_at: '2022-06-09T08:06:10.000000Z',
          deleted_at: null,
          field: {
            id: 5,
            field_group_id: 3,
            name: 'Radio Buttons',
            system_name: 'radio_buttons',
            has_mandatory_field: 1,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 41,
            project_type_id: 4,
            name: names[1],
            is_system: 0,
            order: 40,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:03:53.000000Z',
            updated_at: '2022-06-09T08:03:53.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [
            {
              id: 16,
              project_type_attribute_id: 41,
              name: 'RB 1',
              created_at: '2022-06-09T08:06:10.000000Z',
              updated_at: '2022-06-09T08:06:10.000000Z',
            },
            {
              id: 17,
              project_type_attribute_id: 41,
              name: 'RB 2',
              created_at: '2022-06-09T08:06:10.000000Z',
              updated_at: '2022-06-09T08:06:10.000000Z',
            },
            {
              id: 18,
              project_type_attribute_id: 41,
              name: 'RB 3',
              created_at: '2022-06-09T08:06:10.000000Z',
              updated_at: '2022-06-09T08:06:10.000000Z',
            },
          ],
          values: [],
        },
        {
          id: 42,
          project_type_id: 4,
          project_type_section_id: 42,
          field_id: 7,
          name: 'Test Field 6',
          description: null,
          default_value: '5',
          is_required: 1,
          order: 42,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:06:28.000000Z',
          updated_at: '2022-06-09T08:06:28.000000Z',
          deleted_at: null,
          field: {
            id: 7,
            field_group_id: 4,
            name: 'Numeric Input',
            system_name: 'numeric',
            has_mandatory_field: 1,
            has_min_max_fields: 1,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 42,
            project_type_id: 4,
            name: names[2],
            is_system: 0,
            order: 41,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:04:27.000000Z',
            updated_at: '2022-06-09T08:04:27.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 43,
          project_type_id: 4,
          project_type_section_id: 42,
          field_id: 8,
          name: 'Test Field 7',
          description: 'Test Field 7',
          default_value: null,
          is_required: 0,
          order: 43,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:06:48.000000Z',
          updated_at: '2022-06-09T08:06:48.000000Z',
          deleted_at: null,
          field: {
            id: 8,
            field_group_id: 4,
            name: 'Single Line Text Input',
            system_name: 'smalltext',
            has_mandatory_field: 1,
            has_min_max_fields: 1,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 42,
            project_type_id: 4,
            name: names[2],
            is_system: 0,
            order: 41,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:04:27.000000Z',
            updated_at: '2022-06-09T08:04:27.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 44,
          project_type_id: 4,
          project_type_section_id: 42,
          field_id: 9,
          name: 'Test Field 8',
          description: 'this is a description',
          default_value: null,
          is_required: 0,
          order: 44,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:07:10.000000Z',
          updated_at: '2022-06-09T08:07:10.000000Z',
          deleted_at: null,
          field: {
            id: 9,
            field_group_id: 4,
            name: 'Multi Line Text Input',
            system_name: 'largetext',
            has_mandatory_field: 1,
            has_min_max_fields: 1,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 42,
            project_type_id: 4,
            name: names[2],
            is_system: 0,
            order: 41,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:04:27.000000Z',
            updated_at: '2022-06-09T08:04:27.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 45,
          project_type_id: 4,
          project_type_section_id: 42,
          field_id: 13,
          name: 'Test Field 9',
          description: null,
          default_value: '2022-06-02',
          is_required: 1,
          order: 45,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:07:27.000000Z',
          updated_at: '2022-06-09T08:07:27.000000Z',
          deleted_at: null,
          field: {
            id: 13,
            field_group_id: 5,
            name: 'Date Input',
            system_name: 'date_input',
            has_mandatory_field: 1,
            has_min_max_fields: 1,
            is_min_max_date: 1,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 42,
            project_type_id: 4,
            name: names[2],
            is_system: 0,
            order: 41,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:04:27.000000Z',
            updated_at: '2022-06-09T08:04:27.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
        {
          id: 46,
          project_type_id: 4,
          project_type_section_id: 42,
          field_id: 15,
          name: 'Test Field 10',
          description: null,
          default_value: '09:04',
          is_required: 0,
          order: 46,
          created_by: 1,
          last_updated_by: null,
          deleted_by: null,
          created_at: '2022-06-09T08:07:49.000000Z',
          updated_at: '2022-06-09T08:07:49.000000Z',
          deleted_at: null,
          field: {
            id: 15,
            field_group_id: 5,
            name: 'Time Input',
            system_name: 'time_input',
            has_mandatory_field: 0,
            has_min_max_fields: 0,
            is_min_max_date: 0,
            created_at: '2018-11-30T11:08:22.000000Z',
            updated_at: '2018-11-30T11:08:24.000000Z',
            is_file: 0,
          },
          section_heading: {
            id: 42,
            project_type_id: 4,
            name: names[2],
            is_system: 0,
            order: 41,
            created_by: 1,
            last_updated_by: 1,
            deleted_by: null,
            created_at: '2022-06-09T08:04:27.000000Z',
            updated_at: '2022-06-09T08:04:27.000000Z',
            deleted_at: null,
            type_name: 'Employee',
            type: {
              id: 4,
              name: 'Employee',
              is_parent: 0,
              created_at: '2019-09-18T09:05:54.000000Z',
              updated_at: '2019-09-18T09:05:54.000000Z',
            },
          },
          options: [],
          values: [],
        },
      ],
    }
  },
  sensitiveAttachments: (params: any) => {
    let data = Array()
    switch (params.category) {
      case 'home-attachments':
        switch (params.type) {
          case 'All':
            data = [
              {
                id: 1,
                name: 'file1.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
          case 'returned category 1':
            data = [
              {
                id: 1,
                name: 'file.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
            ]
            break
          case 'returned category 2':
            data = [
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
        }
        break
      case 'school-attachments':
        switch (params.type) {
          case 'All':
            data = [
              {
                id: 1,
                name: 'file2.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
          case 'returned category 1':
            data = [
              {
                id: 1,
                name: 'file.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
            ]
            break
          case 'returned category 2':
            data = [
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
        }
        break
      case 'yp-attachments':
        switch (params.type) {
          case 'All':
            data = [
              {
                id: 1,
                name: 'file3.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
          case 'returned category 1':
            data = [
              {
                id: 1,
                name: 'file.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
            ]
            break
          case 'returned category 2':
            data = [
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
        }
        break
      case 'employee-attachments':
        switch (params.type) {
          case 'All':
            data = [
              {
                id: 1,
                name: 'file4.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
          case 'returned category 1':
            data = [
              {
                id: 1,
                name: 'file.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
            ]
            break
          case 'returned category 2':
            data = [
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
        }
        break
      case 'vehicle-attachments':
        switch (params.type) {
          case 'All':
            data = [
              {
                id: 1,
                name: 'file5.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
          case 'returned category 1':
            data = [
              {
                id: 1,
                name: 'file.pdf',
                description: 'filetype 1 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 1',
                },
              },
            ]
            break
          case 'returned category 2':
            data = [
              {
                id: 2,
                name: 'file.pdf',
                description: 'filetype 2 description',
                type: 'pdf',
                date: '2020-10-30T15:43:29.000000Z',
                category: {
                  name: 'file category 2',
                },
              },
            ]
            break
        }
        break
    }

    return {
      message: 'sensitive attachments returned successfully',
      success: true,
      data: data,
    }
  },
  getProjectTypes: async (params: any) => {
    return axios
      .get('projects/types', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getAllowedParents: async (params: any) => {
    return axios
      .get('projects/parents/allowed', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  moveAttachments: async (payload: object) => {
    return axios
      .post('files/project-files/move', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
