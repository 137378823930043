import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('tasks/accesses', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
