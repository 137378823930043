import { EnComponents } from "@/locales/messages/components"

export default {
    employeeContract: {
        header: 'Employee Contract',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                createdOn: EnComponents.grid.headers.createdOn,
                createdBy: EnComponents.grid.headers.createdBy,
                updatedOn: EnComponents.grid.headers.updatedOn,
                updatedBy: EnComponents.grid.headers.updatedBy,
                type: `Event ${EnComponents.grid.headers.type}`,
                date: `Event ${EnComponents.grid.headers.date}`,
                approvedBy: 'Approved By',
                evidenceFiles: 'Evidence Files'
            }
        },
    }
}