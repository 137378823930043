
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import MainModulePermissions from './partials/Matrix/MainModulePermissions.vue'
  import CareGroupPermissions from './partials/Matrix/CareGroupPermissions.vue'
  import ProjectPermissions from './partials/Matrix/ProjectPermissions.vue'
  import DocumentPermissions from './partials/Matrix/DocumentPermissions.vue'
  import { cannotEditModule } from '@/composables/Permissions'
  import { useStore } from 'vuex'

  interface permissionPayload {
    type: string
    user_id: number
    permissions: Record<number, number>
    granular_permissions: Record<number, Record<number, number>>
  }

  export default defineComponent({
    components: {
      CcButton,
      ButtonGroup,
      PageToolbar,
      MainModulePermissions,
      CareGroupPermissions,
      ProjectPermissions,
      DocumentPermissions,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const state = reactive({
        activeTab: 'Main Module Permissions',
        tabs: [
          'Main Module Permissions',
          'Care Group Permissions',
          'Document Permissions',
          'Homes',
          'Young People',
          'Schools',
          'Vehicles',
          'Employees',
        ],
      })

      const userId = Number(props.id)
      const store = useStore()

      const payload = reactive({
        type: '',
        user_id: userId,
        permissions: {},
        granular_permissions: {},
      }) as permissionPayload

      const buildPayload = (permissions: permissionPayload) => {
        payload.type = permissions.type
        payload.user_id = permissions.user_id
        payload.permissions = permissions.permissions
        payload.granular_permissions = permissions.granular_permissions
      }

      const projectType = computed(() => {
        if (state.activeTab === 'Young People') {
          return 'resident'
        }

        return state.activeTab
          .slice(0, state.activeTab.length - 1)
          .toLowerCase()
      })

      const submitClicked = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('users/updateUserPermissions', payload)
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            payload.type = ''
            payload.permissions = {}
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Users', url: '/users' },
      ]

      onMounted(() => {
        store.dispatch('users/show', { id: userId }).then((response) => {
          crumbs.push({
            name: `${response.data.first_name} ${response.data.last_name}`,
            url: '#',
          })
          store.commit('genericStore/setBreadcrumbs', { crumbs })
        })
      })

      return {
        state,
        userId,
        payload,
        buildPayload,
        submitClicked,
        cannotEditModule,
        projectType,
      }
    },
  })
