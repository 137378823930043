import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FormStatusService from '@/services/forms/formStatuses'
import { FormStatus } from '@/types/modules/forms/FormStatus'

export type FormStatusGetter = GetterTree<FormStatusState, any>

export interface FormStatusState {
  formStatuses: FormStatus[]
}

export const state: FormStatusState = {
  formStatuses: [],
}

export const getters: FormStatusGetter = {
  getFormStatuses: (state) => state.formStatuses,
}

export const actions: ActionTree<FormStatusState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(FormStatusService.index(params))

    results.then((response) => {
      context.commit('setFormStatuses', response.data)
    })

    return results
  },
}

export const mutations: MutationTree<FormStatusState> = {
  setFormStatuses: (state, statuses) => {
    state.formStatuses = statuses
  },
}

export const formStatuses = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
