import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_select = _resolveComponent("multi-select")!

  return (_ctx.systemList.length)
    ? (_openBlock(), _createBlock(_component_multi_select, {
        key: 0,
        label: 
      _ctx.props.fieldAttributes?.label ? _ctx.props.fieldAttributes.label : undefined
    ,
        "is-required": _ctx.props.fieldAttributes?.required === true,
        message: 
      _ctx.props.fieldAttributes?.instructions
        ? _ctx.props.fieldAttributes.instructions
        : undefined
    ,
        "display-expr": 'name',
        "value-expr": 'name',
        "tag-items": _ctx.systemList,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        error: _ctx.error
      }, null, 8, ["label", "is-required", "message", "tag-items", "modelValue", "error"]))
    : _createCommentVNode("", true)
}