
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { onMounted, defineComponent, reactive, computed, watch } from 'vue'
  import { isTab } from '@/composables/Generic'
  import CareGroupAttachments from './partials/Attachments/Attachments.vue'
  import CareGroupStakeholders from './partials/Stakeholders/Stakeholders.vue'
  import CareGroupDetails from './partials/Details/CareGroupDetails.vue'
  import CareGroupBranding from './partials/Branding/CareGroupBranding.vue'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import HomesTable from '@/views/modules/projects/homes/partials/HomesTable.vue'
  import EmployeesTable from '@/views/modules/projects/employees/partials/EmployeesTable.vue'
  import SchoolsTable from '@/views/modules/projects/schools/partials/SchoolsTable.vue'
  import YoungPersonTable from '@/views/modules/projects/young_people/partials/YoungPersonTable.vue'
  import VehiclesTable from '@/views/modules/projects/vehicles/partials/VehiclesTable.vue'
  import CareGroupAudit from './partials/Audit/CareGroupAudit.vue'
  import CareGroupSettings from './Settings.vue'

  export default defineComponent({
    components: {
      CareGroupDetails,
      CareGroupStakeholders,
      CareGroupAttachments,
      CareGroupBranding,
      HomesTable,
      EmployeesTable,
      SchoolsTable,
      YoungPersonTable,
      CareGroupAudit,
      CareGroupSettings,
      VehiclesTable,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const careGroup = computed(
        (): CareGroup => store.getters['careGroups/careGroup']
      )

      const tabs = [
        { name: 'Details', url: `${route.path}?tab=details` },
        { name: 'Stakeholders', url: `${route.path}?tab=stakeholders` },
        { name: 'Branding', url: `${route.path}?tab=branding` },
        { name: 'Attachments', url: `${route.path}?tab=attachments` },
        { name: 'Homes', url: `${route.path}?tab=homes` },
        { name: 'Schools', url: `${route.path}?tab=schools` },
        { name: 'Young People', url: `${route.path}?tab=yp` },
        { name: 'Employees', url: `${route.path}?tab=employees` },
        { name: 'Vehicles', url: `${route.path}?tab=vehicles` },
        { name: 'Settings', url: `${route.path}?tab=settings` },
        { name: 'Audit', url: `${route.path}?tab=audit` },
      ]
      const careGroupIdFilter = computed(() => ({
        filters: [['project.company_id', '=', props.id]],
      }))

      const crumbs = computed(() => {
        let defaultCrumbs = [
          { name: 'Dashboard', url: '/' },
          { name: 'Care Groups', url: '/care_groups' },
        ]

        if (!careGroup.value) {
          return defaultCrumbs
        }

        defaultCrumbs.push({
          name: 'Demo Care',
          url: '/care_groups/demo_care',
        })

        if (route.query.tab) {
          defaultCrumbs.push({
            name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
            url: route.fullPath,
          })
        }

        return defaultCrumbs
      })

      watch(
        () => crumbs.value,
        () => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        },
        {
          deep: true,
        }
      )

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'details' } })
        }

        store.commit('genericStore/setTabs', { tabs: tabs })

        store.dispatch('careGroups/show', props.id).finally(() => {
          store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs.value })
        })
      })

      return {
        props,
        isTab,
        careGroup,
        careGroupIdFilter,
      }
    },
  })
