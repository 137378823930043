import DocumentIndex from '../views/modules/documents/Index.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canView, canEdit } from '@/middleware/permissions/module'
import DocumentsToAcknowledge from '@/views/modules/documents/DocumentsToAcknowledge.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import DocumentCategoryRestore from '@/views/modules/documents/RestoreDocumentCategory.vue'
import ManageDocumentCategory from '@/views/lookups/ManageDocumentCategories.vue'
import DocumentTypeRestore from '@/views/modules/documents/RestoreDocumentType.vue'
import ManageDocumentType from '@/views/lookups/ManageDocumentTypes.vue'

export default [
  {
    path: '/documents',
    name: 'DocumentIndex',
    component: DocumentIndex,
    meta: {
      title: 'Documents',
      module: 'documents',
      middleware: [auth, canView],
    },
  },
  {
    path: '/documents-to-acknowledge',
    name: 'DocumentsToAcknowledge',
    component: DocumentsToAcknowledge,
    meta: {
      layout: 'skeleton',
      title: 'Documents to Acknowledge',
      middleware: [auth],
    },
  },
  {
    path: '/documents/acknowledgements',
    name: 'DocumentAcknowledgements',
    component: DocumentsToAcknowledge,
    meta: {
      title: 'Acknowledgements',
      module: 'documents',
      middleware: [auth, canView],
    },
  },
  {
    path: '/documents/categories/create',
    name: 'DocumentCategoryCreate',
    component: ManageDocumentCategory,
    props: () => ({
      showAction: 'documentCategories/show',
      storeAction: 'documentCategories/store',
    }),
    meta: {
      title: 'Create Document Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/categories/:id/edit',
    name: 'DocumentCategoryEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'documentCategories/show',
      updateAction: 'documentCategories/update',
      deleteAction: 'documentCategories/delete',
    }),
    component: ManageDocumentCategory,
    meta: {
      title: 'Update Document Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/categories/delete',
    name: 'DocumentCategoryDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Document Category(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/categories/restore',
    name: 'DocumentCategoryRestore',
    component: DocumentCategoryRestore,
    meta: {
      title: 'Restore Deleted Document Categories',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/types/create',
    name: 'DocumentTypeCreate',
    component: ManageDocumentType,
    props: () => ({
      showAction: 'documentTypes/show',
      storeAction: 'documentTypes/store',
    }),
    meta: {
      title: 'Create Document Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/types/:id/edit',
    name: 'DocumentTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'documentTypes/show',
      updateAction: 'documentTypes/update',
      deleteAction: 'documentTypes/delete',
    }),
    component: ManageDocumentType,
    meta: {
      title: 'Update Document Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/types/delete',
    name: 'DocumentTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Document Type(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/documents/types/restore',
    name: 'DocumentTypeRestore',
    component: DocumentTypeRestore,
    meta: {
      title: 'Restore Deleted Document Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
