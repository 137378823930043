
  import { defineComponent, reactive } from 'vue'

  export default defineComponent({
    props: {
      hideOnClick: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup(props) {
      const state = reactive({ show: false })
      props = reactive(props)
      const dropdownClasses = [
        'bg-white',
        'dropdown-menu',
        'text-black',
        'mt-1',
        'absolute',
        'right-0',
        'z-10',
        'shadow-lg',
        'w-max',
        'max-w-max',
        'border',
        'border-gray-200',
        'min-w-full',
      ]

      const contentClicked = () => {
        if (props.hideOnClick) {
          state.show = false
        }
      }

      return {
        dropdownClasses,
        state,
        props,
        contentClicked,
      }
    },
  })
