import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_home_summary = _resolveComponent("home-summary")!
  const _component_home_other_details = _resolveComponent("home-other-details")!
  const _component_home_contact_details = _resolveComponent("home-contact-details")!
  const _component_home_ofsted_details = _resolveComponent("home-ofsted-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_chips, {
      chips: _ctx.state.chips,
      defaultChip: _ctx.activeChip,
      errors: _ctx.state.chipsWithErrors,
      onChipClicked: _ctx.chipClicked
    }, null, 8, ["chips", "defaultChip", "errors", "onChipClicked"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_toolbar, {
        onExportClicked: _ctx.exportClicked,
        onSubmitClicked: _ctx.submitClicked
      }, null, 8, ["onExportClicked", "onSubmitClicked"])
    ]),
    _createVNode(_component_validation_errors, {
      errors: _ctx.state.errors
    }, null, 8, ["errors"]),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Summary')
        ? (_openBlock(), _createBlock(_component_home_summary, {
            key: 0,
            home: _ctx.props.home,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["home", "errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Other Details')
        ? (_openBlock(), _createBlock(_component_home_other_details, {
            key: 0,
            home: _ctx.props.home,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["home", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Contact Details')
        ? (_openBlock(), _createBlock(_component_home_contact_details, {
            key: 0,
            home: _ctx.props.home,
            errors: _ctx.state.errors,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["home", "errors", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeChip === 'Ofsted Details')
        ? (_openBlock(), _createBlock(_component_home_ofsted_details, {
            key: 0,
            home: _ctx.props.home,
            onUpdatePayload: _ctx.updatePayload
          }, null, 8, ["home", "onUpdatePayload"]))
        : _createCommentVNode("", true)
    ], 1024))
  ], 64))
}