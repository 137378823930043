import { GetterTree, MutationTree, ActionTree } from 'vuex'
import LegalStatusService from '@/services/projects/legalStatuses'
import { ResidentLegalStatus } from '@/types/modules/projects/ResidentLegalStatus'

export type YoungPersonLegalStatusGetter = GetterTree<
  YoungPersonLegalStatusState,
  any
>

export interface YoungPersonLegalStatusState {
  legalStatuses: ResidentLegalStatus[]
  legalStatus: ResidentLegalStatus | null
}

export const state: YoungPersonLegalStatusState = {
  legalStatuses: [],
  legalStatus: null,
}

export const getters: YoungPersonLegalStatusGetter = {
  getLegalStatuses: (state) => state.legalStatuses,
  getLegalStatus: (state) => state.legalStatus,
}

export const actions: ActionTree<YoungPersonLegalStatusState, any> = {
  index: async (context, params) => {
    const response = await LegalStatusService.index(params)

    if (response.success && params.per_page) {
      context.commit('setLegalStatuses', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setLegalStatuses', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(LegalStatusService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setLegalStatus', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(LegalStatusService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(LegalStatusService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(LegalStatusService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(LegalStatusService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(LegalStatusService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(LegalStatusService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(LegalStatusService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<YoungPersonLegalStatusState> = {
  setLegalStatuses: (state, statuses: ResidentLegalStatus[]) =>
    (state.legalStatuses = statuses),
  setLegalStatus: (state, status: ResidentLegalStatus) =>
    (state.legalStatus = status),
}

export const youngPersonLegalStatuses = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
