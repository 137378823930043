export default {
  overview: {
    taskCard: {
      completed: {
        name: 'Completed Tasks',
        icon: 'far fa-check-circle',
        type: 'success',
        status: 'completed',
        order: '0',
      },
      notStarted: {
        name: 'Not Started Tasks',
        icon: 'fas fa-tasks',
        type: 'default',
        status: 'incomplete',
        order: '1',
      },
      draft: {
        name: 'Draft Tasks',
        icon: 'fas fa-pencil-ruler',
        type: 'warning',
        status: 'draft',
        order: '2',
      },
      sentForApproval: {
        name: 'Awaiting Approval',
        icon: 'far fa-alarm-exclamation',
        type: 'secondary',
        status: 'awaiting-approval',
        order: '3',
      },
    },
    addWidget: 'Add',
  },
}
