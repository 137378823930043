
  import { useStore } from 'vuex'
  import {
    defineComponent,
    onMounted,
    computed,
    reactive,
    watch,
    PropType,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import NumberInput from '@/components/Generic/Forms/Inputs/NumberInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { User } from '@/types/modules/users/User'
  import { useI18n } from 'vue-i18n'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { Region } from '@/types/modules/regions/Region'
  import { Home } from '@/types/modules/projects/Home'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
      SelectInput,
      DateInput,
      NumberInput,
    },
    props: {
      home: {
        type: Object as PropType<Home>,
        required: true,
      },
      errors: {
        type: Object,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = reactive({
        id: undefined,
        name: undefined,
        number_of_beds: undefined,
        minimum_age_group: undefined,
        maximum_age_group: undefined,
        administrator_id: undefined,
        person_in_charge_id: undefined,
        responsible_individual_id: undefined,
        start_date: undefined,
        end_date: undefined,
        is_secure_accommodation: undefined,
        is_short_term_stay: undefined,
        company_id: undefined, //care group id
        region_id: undefined,
        category: undefined,
      })
      const { t } = useI18n()
      const store = useStore()
      const regions = computed(() =>
        store.getters['regions/regions'].map((region: Region) => {
          return {
            label: region.name,
            value: region.id,
          }
        })
      )
      const careGroups = computed(() =>
        store.getters['careGroups/careGroups'].map((careGroup: CareGroup) => {
          return {
            label: careGroup.company_name,
            value: careGroup.company_id,
          }
        })
      )
      const users = computed(() =>
        store.getters['users/users'].map((user: User) => {
          return {
            label: user.username,
            value: user.id,
          }
        })
      )

      const yesNoOptions = [
        {
          id: 1,
          text: 'yes',
        },
        {
          id: 0,
          text: 'no',
        },
      ]

      watch(payload, () => emit('update-payload', payload))

      const mapToPayload = () => {
        Object.assign(payload, {
          id: props.home.id,
          name: props.home.project?.name,
          number_of_beds: props.home.number_of_beds,
          minimum_age_group: props.home.minimum_age_group,
          maximum_age_group: props.home.maximum_age_group,
          administrator_id: props.home.project?.administrator_id,
          person_in_charge_id: props.home.person_in_charge_id,
          responsible_individual_id: props.home.responsible_individual_id,
          start_date: props.home.project?.start_date
            ? format(new Date(props.home.project?.start_date), 'yyyy-MM-dd')
            : undefined,
          end_date: props.home.project?.end_date
            ? format(new Date(props.home.project?.end_date), 'yyyy-MM-dd')
            : undefined,
          is_secure_accommodation: props.home.is_secure_accommodation,
          is_short_term_stay: props.home.is_short_term_stay,
          company_id: props.home.project?.company_id, //care group id
          region_id: props.home.project?.region_id,
          category: props.home.category,
        })
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        mapToPayload()
        Promise.all([
          store.dispatch('users/index'),
          store.dispatch('careGroups/index').then((response: any) => {
            if (response.data.length === 1) {
              payload.company_id = response.data[0].company_id
            }
          }),
          store.dispatch('regions/index'),
        ]).finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        users,
        payload,
        t,
        props,
        yesNoOptions,
        careGroups,
        regions,
      }
    },
  })
