import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoader = _resolveComponent("PageLoader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageLoader, { show: _ctx.showPageLoader }, null, 8, ["show"]),
    (_ctx.versionOk)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}