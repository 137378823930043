import axios from 'axios'

export default {
  index: (params: any) => {
    return axios
      .get('roles', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getRoleModulePermissionLogs: async (params: any) => {
    return axios
      .get('roles/audit/permissions/module', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getRoleCareGroupPermissionLogs: async (params: any) => {
    return axios
      .get('roles/audit/permissions/company', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getRolePermissionLogs: async (params: any) => {
    return axios
      .get('roles/audit/permissions/project', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
