
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'
  import { onMounted, defineComponent, reactive, computed } from 'vue'
  import { renderCellLink } from '@/composables/Grids'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'

  export default defineComponent({
    components: {
      DataGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()

      const columns: Column[] = reactive([
        {
          caption: t('components.grid.headers.id'),
          dataField: 'company_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'company_name',
          caption: t('careGroups.grid.headers.name'),
          cellTemplate: 'CareGroupNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'company_phone1',
          caption: t('components.grid.headers.phone'),
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'company_email',
          caption: t('components.grid.headers.email'),
        },
        {
          allowGrouping: false,
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'last_updated_timestamp',
          caption: t('components.grid.headers.updatedOn'),
          visible: false,
        },
        {
          dataType: 'string',
          dataField: 'last_updated_by.username',
          caption: t('components.grid.headers.updatedBy'),
          visible: false,
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'owner.username',
          caption: t('components.grid.headers.owner'),
          visible: false,
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.company_id,
            })
          },
          allowExporting: false,
        },
      ])

      const filters = computed(() => {
        return {
          relations: ['owner', 'last_updated_by'],
        }
      })

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: t('careGroups.breadcrumb'), url: '/care_groups' },
      ]

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      return {
        columns,
        filters,
      }
    },
  })
