<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('employeeDetails.personalDetails.nationality')"
          v-model="personal_details.nationality"
        />
        <text-input
          :label="t('employeeDetails.personalDetails.ethnicity')"
          v-model="personal_details.ethnicity"
        />
        <date-input
          :label="t('employeeDetails.personalDetails.birthDate')"
          v-model="personal_details.dob"
        />
        <text-input
          :label="t('employeeDetails.personalDetails.age')"
          v-model="personal_details.age"
        />
        <select-input
          :label="t('employeeDetails.personalDetails.gender')"
          v-model="personal_details.gender"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />

        <text-input
          :label="t('employeeDetails.personalDetails.nextOfKin')"
          v-model="personal_details.next_of_kin"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <file-uploader
          uploadText="Upload"
          :label="t('employeeDetails.personalDetails.medicalFiles')"
        />
        <text-box
          lines="3"
          :label="t('employeeDetails.personalDetails.extraInfo')"
          :isRequired="false"
          v-model="personal_details.extra_information"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import FileUploader from '@/components/Generic/Forms/Inputs/FileUploader.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      DateInput,
      SelectInput,
      TextBox,
      FileUploader,
    },
    setup() {
      const { t } = useI18n()
      let personal_details = {
        nationality: 'British',
        ethnicity: 'White',
        dob: '1980-08-09',
        age: '42',
        gender: 'social construct',
        next_of_kin: 'Jackie',
        extra_information:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam quae quas quos velit aliquam delectus odit maxime excepturi vitae ut et',
      }

      return {
        personal_details,
        t,
      }
    },
  })
</script>
