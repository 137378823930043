import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_common_series_settings = _resolveComponent("dx-common-series-settings")!
  const _component_dx_series_template = _resolveComponent("dx-series-template")!
  const _component_dx_font = _resolveComponent("dx-font")!
  const _component_dx_label = _resolveComponent("dx-label")!
  const _component_dx_argument_axis = _resolveComponent("dx-argument-axis")!
  const _component_dx_value_axis = _resolveComponent("dx-value-axis")!
  const _component_dx_legend = _resolveComponent("dx-legend")!
  const _component_dx_series = _resolveComponent("dx-series")!
  const _component_dx_chart = _resolveComponent("dx-chart")!

  return (_openBlock(), _createBlock(_component_dx_chart, {
    "data-source": _ctx.props.data,
    palette: "default-bar",
    ref: "columnChart",
    onSeriesClick: _ctx.seriesClicked
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dx_common_series_settings, {
        "ignore-empty-points": true,
        "argument-field": "label",
        "value-field": "value",
        type: "bar"
      }),
      _createVNode(_component_dx_series_template, { "name-field": "label" }),
      _createVNode(_component_dx_argument_axis, null, {
        default: _withCtx(() => [
          _createVNode(_component_dx_label, null, {
            default: _withCtx(() => [
              _createVNode(_component_dx_font, {
                family: "Mulish",
                weight: 600,
                color: "#222"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_dx_value_axis, null, {
        default: _withCtx(() => [
          _createVNode(_component_dx_label, null, {
            default: _withCtx(() => [
              _createVNode(_component_dx_font, {
                family: "Mulish",
                weight: 600,
                color: "#222"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_dx_legend, { visible: false }),
      _createVNode(_component_dx_series, {
        "argument-field": "label",
        "value-field": "value",
        type: "bar"
      })
    ]),
    _: 1
  }, 8, ["data-source", "onSeriesClick"]))
}