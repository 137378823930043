
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { Region } from '@/types/modules/regions/Region'

  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()

      const state = reactive({
        selectedRows: [] as Region[],
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          caption: 'Name',
          dataField: 'name',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'company.company_name',
          caption: 'Care Group',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'owner.username',
          caption: 'Owner',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'parent.name',
          caption: 'Enclosing Region',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'number',
          dataField: 'active_items',
          caption: 'Active Items',
          calculateCellValue: (row: Region) => {
            let count = 0

            if (row.projects) count += row.projects.length
            if (row.forms) count += row.forms.length
            if (row.procedures) count += row.procedures.length

            return count
          },
        },
      ])

      const filters = computed(() => {
        return {
          only: ['trashed'],
        }
      })

      const rowsSelected = (rows: Region[]) => (state.selectedRows = rows)

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('regions/restoreMany', {
            ids: state.selectedRows.map((row: Region) => row.id),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then((response) => {
            store.dispatch('genericStore/showPageLoader', false),
              store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => goBack())
      }
      onMounted(() => {
        store.getters['genericStore/breadCrumbs'].push({
          name: 'Restore Regions',
          url: route.fullPath,
        })
      })
      return {
        t,
        state,
        goBack,
        columns,
        filters,
        restore,
        rowsSelected,
      }
    },
  })
