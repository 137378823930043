
  import { Column } from 'devextreme/ui/data_grid'
  import { defineComponent, onMounted, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      id: {
        type: String, // Home or School ID
        required: true,
      },
      medicationId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const columns = computed((): Column[] => [
        {
          caption: t('mars.stockHistory.grid.headers.id'),
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: t('mars.stockHistory.grid.headers.action'),
          dataField: 'action',
          allowGrouping: false,
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: t('mars.stockHistory.grid.headers.oldValue'),
          dataField: 'old_value',
          dataType: 'number',
        },
        {
          caption: t('mars.stockHistory.grid.headers.newValue'),
          dataField: 'new_value',
          dataType: 'number',
        },
        {
          caption: 'Changed By',
          dataField: 'creator.username',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Changed On',
          dataField: 'created_at',
          dataType: 'date',
          format: 'dd/MM/yyyy HH:mm',
        },
      ])
      const stock = computed(() => store.getters['mars/stockToAdjust'])

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })

        store.getters['genericStore/breadCrumbs'].push({
          name: 'View Stock History',
          url: route.fullPath,
        })
      })
      return {
        props,
        columns,
        t,
        goBack,
        stock,
      }
    },
  })
