import { RouteRecordRaw } from 'vue-router'
import { canEdit } from '@/middleware/permissions/module'
import auth from '@/middleware/auth/auth'
import ManageFileCategories from '@/views/lookups/ManageFileCategories.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import FileCategoryRestore from '@/views/modules/files/partials/FileCategoriesRestore.vue'

export default [
  {
    path: '/files/categories/create',
    name: 'FileCategoryCreate',
    component: ManageFileCategories,
    props: () => ({
      showAction: 'fileCategories/show',
      storeAction: 'fileCategories/store',
    }),
    meta: {
      title: 'Create File Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/files/categories/:id/edit',
    name: 'FileCategoryEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'fileCategories/show',
      updateAction: 'fileCategories/update',
      deleteAction: 'fileCategories/delete',
    }),
    component: ManageFileCategories,
    meta: {
      title: 'Update File Category',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/files/categories/delete',
    name: 'FileCategoryDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete File Category(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/files/categories/restore',
    name: 'FileCategoryRestore',
    component: FileCategoryRestore,
    meta: {
      title: 'Restore Deleted File Categories',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
