
  import { reactive, computed, defineComponent, PropType } from 'vue'
  import { RouteRecordRaw } from 'vue-router'
  export default defineComponent({
    props: {
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
        validator: (value: string) =>
          [
            'default',
            'success',
            'warning',
            'danger',
            'primary',
            'secondary',
          ].indexOf(value) !== -1,
        default: 'default',
      },
      size: {
        type: String,
        required: true,
        validator: (value: string) => ['small', 'large'].indexOf(value) !== -1,
        default: 'small',
      },
      to: {
        type: Object as PropType<RouteRecordRaw>,
        required: false,
      },
    },
    setup(props) {
      props = reactive(props)

      const cardClasses = computed(() => ({
        'max-w-3xl': true,
        'border-2 font-body rounded-5': true,
        'border-gray-200': props.size == 'small',
        'border-gray-400 bg-gray-10':
          props.type == 'default' && props.size == 'large',
        'border-green-200 bg-green-100':
          props.type == 'success' && props.size == 'large',
        'border-blue-200 bg-blue-100':
          props.type == 'primary' && props.size == 'large',
        'border-orange-200 bg-orange-100':
          props.type == 'warning' && props.size == 'large',
        'border-red-200 bg-red-100':
          props.type == 'danger' && props.size == 'large',
      }))

      const linkClasses =
        'text-sm border-2 py-2 px-4 transition-colors max-w-max w-max lowercase border-none bg-none text-blue-300 underline underline-offset-8 decoration-2 focus-visible:bg-yellow-300 focus-visible:border-black focus-visible:text-black'

      const cardTitleClasses =
        'block font-bold text-black tracking-slight leading-5 mb-6 capitalize whitespace-normal'

      return {
        cardTitleClasses,
        linkClasses,
        cardClasses,
        props,
      }
    },
  })
