
  import { defineComponent, PropType, reactive, Ref, ref, watch } from 'vue'
  import { RuntimeParameter } from '@/types/modules/reporting/Datasource'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import InputLabel from '@/components/Generic/Forms/InputLabel.vue'
  import { format, subDays } from 'date-fns'

  export default defineComponent({
    components: {
      DateInput,
      InputLabel,
    },
    props: {
      parameter: {
        type: Object as PropType<RuntimeParameter>,
        required: true,
      },
    },
    emits: ['update:parameter'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({
        data_type: '',
        default: '',
        friendly_name: '',
        friendly_operator: '',
        name: '',
        operator: '',
        options: '',
        start_date: '',
        end_date: '',
      }) as Ref<DateRangePayload>

      interface DateRangePayload {
        data_type: string
        default: string
        friendly_name: string
        friendly_operator: string
        name: string
        operator: string
        options: string
        start_date: string
        end_date: string
      }

      watch(
        props.parameter,
        (parameter: RuntimeParameter) => {
          payload.value = {
            ...parameter,
            start_date: parameter.default
              ? format(
                  subDays(new Date(), Number(parameter.default.split(',')[0])),
                  'yyyy-MM-dd'
                )
              : format(new Date(), 'yyyy-MM-dd'),
            end_date: parameter.default
              ? format(
                  subDays(new Date(), Number(parameter.default.split(',')[1])),
                  'yyyy-MM-dd'
                )
              : format(new Date(), 'yyyy-MM-dd'),
          }
        },
        { immediate: true }
      )

      watch(
        payload.value,
        () => {
          emit('update:parameter', {
            ...payload.value,
            value: `${payload.value.start_date} - ${payload.value.end_date}`,
          })
        },
        { immediate: true }
      )

      return {
        payload,
      }
    },
  })
