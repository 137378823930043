
  import {
    defineComponent,
    onMounted,
    computed,
    Ref,
    ref,
    watch,
    reactive,
  } from 'vue'
  import { useStore } from 'vuex'
  import {
    DxScheduler,
    DxResource,
    DxView,
    DxScrolling,
  } from 'devextreme-vue/scheduler'
  import CalendarCardTemplate from './Templates/CalendarCardTemplate.vue'
  import ProjectResourceTemplate from './Templates/ProjectResourceTemplate.vue'
  import '@/assets/css/dev-extreme-scheduler.css'
  import _ from 'lodash'
  import { useI18n } from 'vue-i18n'
  import { format, startOfToday, endOfToday } from 'date-fns'
  import { CalendarEntry } from '@/types/modules/calendar/CalendarEntry'
  import { AvailableFilter } from '@/store/modules/dashboard'

  export default defineComponent({
    components: {
      DxView,
      DxResource,
      ProjectResourceTemplate,
      CalendarCardTemplate,
      DxScheduler,
      DxScrolling,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const scheduler = ref() as Ref<DxScheduler>
      const todaysDate = format(new Date(), 'EEEE, MMMM	do, yyyy')
      const activeDashboardFilter = computed((): AvailableFilter => {
        return store.getters['dashboard/activeFilter']
      })
      const eventInstances = computed((): CalendarEntry[] => {
        return store.getters['calendar/eventInstances'].map((instance: any) => {
          return {
            start_date: instance.date,
            end_date: endOfToday(),
            project_id: instance.project_id,
            event: instance.event,
          }
        })
      })

      const state = reactive({
        dataSource: [] as CalendarEntry[],
      })

      const projects = computed(() => {
        const projects = _.map(state.dataSource, 'event.project')
        return _.uniqBy(projects, 'id')
      })

      watch(
        () => activeDashboardFilter.value,
        () => filterEventsByProject()
      )

      const fetchEvents = () => {
        store
          .dispatch('calendar/index', {
            start_date: format(startOfToday(), 'yyyy-MM-dd HH:mm:ss'),
            end_date: format(endOfToday(), 'yyyy-MM-dd HH:mm:ss'),
            only: ['parent_projects'],
          })
          .then(() => (state.dataSource = eventInstances.value))
          .then(() => scheduler.value?.instance?.repaint())
      }
      const filterEventsByProject = () => {
        if (!activeDashboardFilter.value?.id) {
          state.dataSource = eventInstances.value
          return
        }

        state.dataSource = eventInstances.value.filter((instance: any) => {
          return instance.event.project_id === activeDashboardFilter.value?.id
        })
      }
      const disableEventViewer = (data: any) => (data.cancel = true)

      onMounted(() => fetchEvents())

      return {
        disableEventViewer,
        eventInstances,
        activeDashboardFilter,
        scheduler,
        projects,
        todaysDate,
        state,
        t,
      }
    },
  })
