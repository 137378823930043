
  import { computed, defineComponent, PropType, reactive } from 'vue'

  export default defineComponent({
    props: {
      errors: {
        type: Object as PropType<Record<string, string>>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const hasErrors = computed(() => Object.keys(props.errors).length > 0)
      const classList = [
        'p-4',
        'rounded-md',
        'border-2',
        'border-red-400',
        'bg-red-100',
        'text-red-400',
        'font-display',
        'my-4',
      ]
      return {
        hasErrors,
        classList,
        props,
      }
    },
  })
