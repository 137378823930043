
  import { defineComponent, reactive, computed } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'Name',
          dataField: 'name',
          allowGrouping: true,
        },
        {
          caption: 'Gender',
          dataField: 'gender.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Relation',
          dataField: 'family_member_type.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Allowed Contact',
          dataField: 'is_allowed_contact',
          allowGrouping: true,
          calculateCellValue: (row: { is_allowed_contact: number }) =>
            row.is_allowed_contact === 1 ? 'Yes' : 'No',
        },
        {
          caption: 'Reason For No Contact',
          dataField: 'no_contact_reason',
          allowGrouping: true,
        },
      ])
      const filters = computed(() => {
        return {
          resident_id: props.id,
          relations: ['family_member_type', 'gender'],
        }
      })

      return {
        filters,
        props,
        columns,
      }
    },
  })
