import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('instances', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getEvent: async (id: any) => {
    return axios
      .get(`events/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateEvent: async (id: any, payload: any) => {
    return axios
      .put(`events/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getEventInstance: async (id: any) => {
    return axios
      .get(`instances/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  saveNote: (id: string, params: any) => {
    return axios
      .post(`instances/${id}/save-note`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateEventInstanceStatus: (id: string, params: any) => {
    return axios
      .put(`instances/${id}/status`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  stopRecurringEvent: (id: string, params: any) => {
    return axios
      .post(`events/${id}/stop-recurring`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteEvent: (id: string, params: any) => {
    return axios
      .delete(`events/${id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  createEvent: (payload: any) => {
    return axios
      .post(`events`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
