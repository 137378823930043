
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { Stimulsoft } from 'stimulsoft-dashboards-js/Scripts/stimulsoft.blockly.editor'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import { useStore } from 'vuex'
  import { DataSet } from '@/types/modules/reporting/BespokeReportDatasource'
  import {
    viewerOptions,
    designerOptions,
    registerLicenseKey,
  } from '@/composables/Reporting'
  import ProgressBar from '@/components/Generic/Loaders/ProgressBar.vue'
  import { useRoute } from 'vue-router'
  import { DatasourceRelationship } from '@/types/modules/reporting/DatasourceRelationship'

  export default defineComponent({
    components: {
      ProgressBar,
    },
    props: {
      report: {
        type: Object as PropType<BespokeReport>,
        required: true,
      },
      datasources: {
        type: Array as PropType<Datasource[]>,
        required: true,
      },
    },
    emits: ['update:dataSets'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()

      const isView = (name: string): boolean => {
        return route.name === name
      }

      const loading = reactive({
        title: 'Analysing...',
        message: 'This may take a moment.',
        isLoading: true,
      })

      const dataSets = ref([]) as Ref<DataSet[]>
      const report = new Stimulsoft.Report.StiReport()
      const viewer = new Stimulsoft.Viewer.StiViewer(
        viewerOptions(),
        'StiViewer',
        false
      )
      const designer = new Stimulsoft.Designer.StiDesigner(
        designerOptions(),
        'StiDesigner',
        false
      )

      const saveReportToJson = () => designer.report.saveToJsonString()

      const getProgressMessage = (dataSet: DataSet) => {
        let message =
          dataSet.row_count === dataSet.row_total ? 'Retrieved ' : 'Retrieving '
        return `${message} ${dataSet.row_count} of ${dataSet.row_total} rows for datasource`
      }

      const calculateCount = (response: Record<string, any>) => {
        return response.data.length
      }

      const calculateTotal = (
        response: Record<string, any>,
        currentTotal: number
      ) => {
        if (response.current_page === 1) {
          return (currentTotal += response.total)
        }

        if (response.current_page > 1) {
          return currentTotal
        }

        if (!response.current_page) {
          return (currentTotal += response.data.length)
        }

        return 0
      }

      const loadDataSet = (datasource: Datasource, params: object) => {
        return store.dispatch('bespokeReportDatasources/loadDataset', {
          id: datasource.pivot.id,
          ...params,
        })
      }

      const getDataSet = async (
        datasource: Datasource,
        page: number
      ): Promise<any> => {
        const response = await loadDataSet(datasource, {
          runtime_parameters: datasource.runtime_params.runtime_parameters,
          current_page: page,
        })

        if (Object.keys(response.data).length) {
          const dataSet = dataSets.value.find(
            (dataSet) => dataSet.id === datasource.pivot.id
          )

          if (!dataSet) {
            dataSets.value.push({
              id: datasource.pivot.id,
              name: datasource.pivot.name,
              system_name: datasource.pivot.name
                .toLowerCase()
                .replace(/ /g, '_'),
              grouping: datasource.pivot.grouping,
              row_count: calculateCount(response),
              row_total: calculateTotal(response, 0),
              data: response.data,
            })
          }

          if (dataSet) {
            dataSet.data = dataSet.data.concat(response.data)
            dataSet.row_count += calculateCount(response)
            dataSet.row_total = calculateTotal(response, dataSet.row_total)
          }
        }

        if (response.current_page < response.last_page) {
          return getDataSet(datasource, response.current_page + 1)
        }

        return dataSets.value
      }

      const setupReport = () => {
        report.load(props.report.definition)
        report.dictionary.databases.clear()
        return Promise.resolve('Report setup complete.')
      }

      const getDataSets = () => {
        const promises = props.datasources.map(async (datasource) => {
          return await getDataSet(datasource, 1)
        })

        return Promise.all(promises)
      }

      const buildDataSets = async () => {
        dataSets.value.forEach((data: DataSet) => {
          let dataSet = new Stimulsoft.System.Data.DataSet(
            data.grouping || 'ClearCare Solutions'
          )

          dataSet.readJson({ [data.system_name]: data.data })

          report.regData(dataSet.dataSetName, '', dataSet)
        })

        return Promise.resolve('Building datasets complete.')
      }

      const buildRelationships = () => {
        if (isView('BespokeReportView')) {
          return Promise.resolve('Building relationships complete.')
        }

        for (const datasource of props.datasources) {
          if (!datasource.relationships?.length) continue

          for (const relationship of datasource.relationships) {
            buildRelationship(relationship, datasource)
          }
        }

        return Promise.resolve('Building relationships complete.')
      }

      const buildRelationship = (
        relationship: DatasourceRelationship,
        datasource: Datasource
      ) => {
        const existingDatasource = props.datasources.find(
          (datasource) => datasource.id === relationship.join_to
        )
        if (!existingDatasource) return

        const existingRelation = report.dictionary.relations.getByName(
          relationship.name
        )
        if (existingRelation) return

        let relationInstance = props.datasources
          .find((ds) => ds.id === relationship.join_to)
          ?.name.toLowerCase()
          .replace(/ /g, '_')!

        let fieldsToMapTo = relationship.related_fields

        let relation = new Stimulsoft.Report.Dictionary.StiDataRelation(
          relationship.name,
          relationship.name,
          relationship.name,
          report.dictionary.dataSources.getByName(relationInstance),
          report.dictionary.dataSources.getByName(
            datasource.pivot.name.toLowerCase().replace(/ /g, '_')
          ),
          fieldsToMapTo.fields.to,
          fieldsToMapTo.fields.from
        )

        report.dictionary.relations.add(relation)
      }

      const renderComponent = () => {
        if (isView('BespokeReportDesign')) {
          return renderDesigner()
        }

        return renderViewer()
      }

      const renderViewer = () => {
        report.dictionary.synchronize()
        viewer.report = report
        report.cacheAllData = true
        viewer.renderHtml('viewer')
        return Promise.resolve('Viewer rendered.')
      }

      const renderDesigner = () => {
        report.dictionary.synchronize()
        designer.report = report
        report.cacheAllData = true
        buildRelationships()
        designer.renderHtml('designer')
        return Promise.resolve('Designer rendered.')
      }

      watch(dataSets.value, (dataSets) => emit('update:dataSets', dataSets))

      const init = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        await Promise.all([
          registerLicenseKey(),
          setupReport(),
          getDataSets()
            .then(() => buildDataSets())
            .then(() => renderComponent())
            .finally(() => (loading.isLoading = false)),
        ])

        store.dispatch('genericStore/showPageLoader', false)
      }

      window.addEventListener('beforeunload', () => {
        if (isView('BespokeReportDesign')) {
          designer.jsObject.options.reportIsModified = false
          window.onbeforeunload = null
        }
      })

      onMounted(() => init())

      return {
        isView,
        report,
        viewer,
        loading,
        dataSets,
        getProgressMessage,
        saveReportToJson,
      }
    },
  })
