
  import { reactive, defineComponent } from 'vue'
  import FormHeader from '../../FormHeader/FormHeader.vue'
  export default defineComponent({
    components: {
      FormHeader,
    },
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    emits: ['collapse-toggle'],
    setup(props, { emit }) {
      props = reactive(props)
      const formHeader = [
        'bg-white',
        'border-b-4',
        'border-blue-200',
        'flex',
        'flex-wrap',
        'font-body',
        'items-center',
        'justify-between',
        'p-1.5',
        'w-full',
      ]

      const collapseToggle = (isCollapsed: any) => {
        emit('collapse-toggle', {
          value: props.value,
          shouldCollapse: isCollapsed,
        })
      }

      return {
        props,
        formHeader,
        collapseToggle,
      }
    },
  })
