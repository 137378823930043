
  import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    ref,
    Ref,
    watch,
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { FormField, ExtendedFormField } from '@/types/modules/forms/FormField'
  import { FormFieldAttribute } from '@/types/modules/forms/FormFieldAttribute'
  import {
    getFieldComponent,
    fieldsWithTriggers,
    LogTrigger,
    LogValue,
  } from '@/composables/ManageLog'
  import Fields from '@/components/Generic/Forms/Inputs/Actual'
  import CcTable from '@/components/Generic/Forms/Inputs/Actual/Table.vue'
  import MultiStep from '@/components/Generic/Forms/Inputs/Actual/MultiStep.vue'
  import { Task } from '@/types/modules/tasks/Task'
  import { TaskLogValue } from '@/types/modules/tasks/TaskLogValue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { TaskLog } from '@/types/modules/tasks/TaskLog'
  import TaskLogToolbar from './partials/TaskLogToolbar.vue'
  import { getApiUrl } from '@/composables/Generic'
  import GroupedSelectInput from '@/components/Generic/Forms/Inputs/GroupedSelectInput.vue'
  import { Setting } from '@/types/modules/settings/Setting'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import TransferList from '@/components/Generic/List/TransferList.vue'
  import { User } from '@/types/modules/users/User'
  import TriggerConfig from './partials/TriggerConfig.vue'
  import { TriggerConfig as TriggerConfigType } from '@/types/modules/tasks/TriggerConfig'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { Project } from '@/types/modules/projects/Project'
  import { serialize } from 'object-to-formdata'
  import { DraftTrigger } from '@/types/modules/tasks/DraftTrigger'
  import { buildFields } from '@/composables/FormFields'
  import { MultiStep as MultiStepType } from '@/types/components/MultiStep'
  import { object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'

  export default defineComponent({
    components: {
      ...Fields,
      CcTable,
      MultiStep,
      CcButton,
      TaskLogToolbar,
      GroupedSelectInput,
      ButtonGroup,
      TransferList,
      TriggerConfig,
      TextInput,
      ValidationErrors,
    },
    props: {
      taskId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const state = reactive({
        showFormInformation: false,
        crumbs: [
          { name: 'Dashboard', url: '/' },
          { name: 'Tasks', url: '/overview/tasks' },
        ],
        steps: [] as MultiStepType[],
        active_multi_step: undefined as number | undefined,
        activeTab: 'form',
        tabs: ['form', 'notifications'],
      })
      const associationsLoaded = ref(false) as Ref<boolean>
      const task = computed((): Task => store.getters['tasks/task'])
      const users = computed(() => store.getters['users/users'])
      const latestLog = computed(
        (): TaskLog => store.getters['taskLogs/taskLog']
      )
      const formattedFields = ref([]) as Ref<ExtendedFormField[]>
      const associationSetting = computed((): Setting | undefined => {
        if (!store.getters['users/settings'].length) {
          return undefined
        }

        return store.getters['users/settings'].find((setting: Setting) => {
          return setting.system_name === 'default_task_parent_association'
        })
      })

      const summaryValidationValues = computed(() => {
        const values = [task.value.form.name]

        if (latestLog.value) {
          values.push(latestLog.value.name)
        }

        return values
      })

      const validation = object({
        summary: string()
          .required('Please enter a form name')
          .notOneOf(
            summaryValidationValues.value,
            'Please change the Summary field to reflect the content of the form'
          ),
      })

      const updateNotifications = (users: User[]) => {
        payload.value.users_to_notify = users
      }

      const payload = ref({
        task_id: Number(props.taskId),
        is_draft: false,
        values: [],
        is_autosaved: false,
        users_to_notify: [],
        form_trigger: {
          form_field_id: null,
          project_id: null,
          form_id: null,
          trigger_date: null,
        },
        project_parent_id: null,
        summary: '',
      }) as Ref<{
        task_id: number
        is_draft: boolean
        values: LogValue[]
        is_autosaved: boolean
        users_to_notify: User[]
        form_trigger: LogTrigger
        project_parent_id: number | null
        summary: string
      }>

      const formTriggerConfig = computed((): TriggerConfigType => {
        return {
          form: {
            types: task.value.form.types,
            always_trigger_for_same_project:
              task.value?.form?.always_trigger_for_same_project,
            restrict_projects_by_association:
              task.value?.form?.restrict_projects_by_association,
            trigger_has_date: task.value?.form?.trigger_has_date,
          },
          triggered_form: triggeredForm.value,
          project: triggeredProject.value,
          project_parent_id: payload.value.project_parent_id
            ? Number(payload.value.project_parent_id)
            : undefined,
        }
      })

      const showFormInformation = () =>
        (state.showFormInformation = !state.showFormInformation)

      const shouldShowTriggerConfig = computed(() => {
        if (task.value && task.value.form.triggered_task_id) {
          if (
            !task.value.existing_triggered_forms.includes(
              task.value.form.triggered_task_id
            )
          ) {
            return true
          }
        }

        return false
      })

      const triggeredForm = computed(() => {
        if (relatedDraftTrigger.value) {
          return relatedDraftTrigger.value.form || undefined
        }

        return task.value?.form?.triggered_task || undefined
      })

      const triggeredProject = computed(() => {
        if (relatedDraftTrigger.value) {
          return relatedDraftTrigger.value.project || undefined
        }

        return task.value?.project || undefined
      })

      const relatedDraftTrigger = computed(() => {
        if (!latestLog.value || !latestLog.value.draft_triggers?.length) {
          return undefined
        }

        return latestLog.value.draft_triggers.find(
          (draftTrigger: DraftTrigger) => {
            return (
              draftTrigger.form_field_id === null &&
              draftTrigger.task_log_id === latestLog.value.id
            )
          }
        )
      })

      const updateFormTrigger = (details: LogTrigger) => {
        payload.value.form_trigger = {
          form_field_id: null,
          project_id: details.project_id,
          form_id: details.form_id || null,
          trigger_date: details.trigger_date,
        }
      }

      const saveDraft = () => {
        payload.value.is_draft = true
        submit()
      }

      const autoSaveLog = () => {
        payload.value.is_autosaved = true
        submit()
      }

      const errors = ref(null) as Ref<Record<string, string> | null>

      const submit = async () => {
        store.dispatch('genericStore/showPageLoader', true)

        errors.value = await validatePayload(validation, payload.value)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        store
          .dispatch(
            'taskLogs/store',
            serialize(
              {
                ...payload.value,
                users_to_notify: payload.value.users_to_notify.map(
                  (user: User) => user.id
                ),
              },
              {
                indices: true,
                allowEmptyArrays: true,
                booleansAsIntegers: true,
              }
            )
          )
          .then((response) => {
            if (response.success) {
              store.dispatch('genericStore/pushNotification', response.message)
              router.replace({
                name: 'ViewLog',
                params: { id: props.taskId },
              })
            }
          })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
            payload.value.is_draft = false
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const optionClicked = (option: string) => {
        if (option === 'Export Blank Form To PDF') {
          exportForm()
        }
      }

      const associations = computed(() => {
        if (!task.value || !task.value.project?.parents) {
          return []
        }

        let associations = {} as Record<
          string,
          {
            label: string
            value: Number
          }[]
        >

        for (let association of task.value.project?.parents) {
          let category = association.association_status || 'General'

          if (!associations[category]) {
            associations[category] = []
          }

          associations[category].push({
            label: association.name,
            value: association.id,
          })
        }

        return associations
      })

      const exportForm = () => {
        let params = new URLSearchParams({
          task_id: props.taskId,
        })

        window.open(
          `${getApiUrl()}/tasks/export/edit_log/tasks?${params}`,
          '_blank'
        )
      }

      const getProps = (field: ExtendedFormField) => {
        return {
          fieldAttributes: getFieldAttributes(field),
        }
      }

      const valueUpdated = (value: LogValue) => {
        const matchingValueIndex = payload.value.values.findIndex(
          (payloadValue: LogValue) => {
            return payloadValue.form_field_id === value.form_field_id
          }
        )

        matchingValueIndex !== -1
          ? payload.value.values.splice(matchingValueIndex, 1, value) // replace
          : payload.value.values.push(value) // add
      }

      const showFields = computed(() => {
        if (route.name === 'EditLog') {
          return formattedFields.value.length && latestLog.value
        }

        if (route.name === 'CreateLog') {
          return formattedFields.value.length && task.value
        }

        return formattedFields.value.length
      })

      const showStepFields = computed(() => {
        if (route.name === 'EditLog') {
          return state.steps.length && latestLog.value
        }

        if (route.name === 'CreateLog') {
          return state.steps.length && task.value
        }

        return state.steps.length
      })

      const collapseSection = (logValue: {
        value: string
        shouldCollapse: boolean
      }) => {
        let headingIndex = formattedFields.value.findIndex(
          (value: ExtendedFormField) => {
            return value.name === logValue.value
          }
        )

        let nextHeadingIndex = formattedFields.value.findIndex(
          (value: ExtendedFormField, index: number) => {
            return (
              value.field!.system_name === 'field_group_heading' &&
              index > headingIndex
            )
          }
        )

        formattedFields.value.forEach(
          (value: ExtendedFormField, index: number) => {
            if (index > headingIndex && index < nextHeadingIndex) {
              value.is_visible = !logValue?.shouldCollapse
            }

            if (index > headingIndex && nextHeadingIndex === -1) {
              value.is_visible = !logValue?.shouldCollapse
            }
          }
        )
      }

      const getFieldValue = (field: FormField) => {
        if (field.field!.system_name === 'field_group_heading') {
          return field.name
        }

        if (field.field!.system_name === 'table') {
          return {
            fields: task.value.form.fields
              .filter((formField: FormField) => {
                return Number(formField.parent_id) === Number(field.id)
              })
              .map((child: any, index: number) => {
                child.order = index
                return child
              }),
            log: latestLog.value,
          }
        }

        // if were editing we should have log values so use that
        if (latestLog.value) {
          let relatedLogValue = latestLog.value.values?.find(
            (value: TaskLogValue) => value.form_field_id === field.id
          )

          return relatedLogValue?.value
        }

        // otherwise check for default attribute and use that
        let defaultValueAttribute = field.field_attributes?.find(
          (attribute: FormFieldAttribute) => {
            return attribute.name === 'value'
          }
        )

        if (
          defaultValueAttribute &&
          field.field?.system_name !== 'system_list'
        ) {
          return defaultValueAttribute.value
        }

        // no value found return nothing
        return undefined
      }

      const getFieldAttributes = (field: FormField) => {
        let formatted: Record<string, any> = {
          id: Number(field.id),
          field: field.field,
          value: getFieldValue(field),
          label: field.name,
          instructions: field.instructions || undefined,
          description: field.description || undefined,
        }

        field.field_attributes!.forEach((attribute: FormFieldAttribute) => {
          if (
            !formatted[attribute.name] ||
            formatted[attribute.name] === undefined
          ) {
            if (attribute.name !== 'value') {
              formatted[attribute.name] = attribute.value
            }
            if (attribute.name === 'triggered_task_id') {
              formatted['triggered_task_id'] = Number(attribute.value)
              formatted['triggered_task'] = attribute.triggered_task
            }
          }
        })

        if (field.field!.system_name === 'related_forms') {
          let relatedLogValue = latestLog.value.values?.find(
            (value: TaskLogValue) => value.form_field_id === field.id
          )

          formatted['related_tasks'] = relatedLogValue
            ? relatedLogValue.related_forms
            : []
        }

        if (field.field!.system_name === 'system_list') {
          formatted['system_list'] = {}
          formatted['system_list']['id'] = Number(
            field.field_attributes!.find(
              (attribute: FormFieldAttribute) => attribute.name === 'value'
            ).value
          )
          formatted['system_list']['in_preview_mode'] = false
        }

        if (fieldsWithTriggers().indexOf(field.field!.system_name!) !== -1) {
          formatted['project_id'] = Number(task.value.project_id) || null
          formatted['project'] = task.value.project || null
          formatted['form_types'] = task.value.form.types
          formatted['existing_triggered_forms'] =
            task.value.existing_triggered_forms
          formatted['project_parent_id'] = Number(
            payload.value.project_parent_id
          )
          formatted['options'] = field.options
          formatted['draft_triggers'] = latestLog.value
            ? latestLog.value.draft_triggers
            : []
        }

        formatted['in_table'] = false // Table fields built in table component

        return formatted
      }

      const registerCustomEventListeners = (systemName: string) => {
        let nonValueFields = [
          'field_group_heading',
          'multi_step_form_section',
          'embed_files',
          'inline_image',
        ]

        let listeners = {} as Record<string, Function>

        if (systemName === 'field_group_heading') {
          listeners.collapseToggle = collapseSection
        }

        if (!nonValueFields.includes(systemName)) {
          listeners.valueUpdated = valueUpdated
        }

        return listeners
      }

      const mapAssociation = () => {
        if (task.value && task.value.project_parent_id) {
          associationsLoaded.value = true
          return task.value.project_parent_id
        }

        if (task.value && task.value.project?.parents?.length) {
          if (task.value.project?.parents?.length === 1) {
            associationsLoaded.value = true
            return task.value.project.parents[0].id
          }

          if (
            associationSetting.value &&
            associationSetting.value.value === 'Default Parent Association'
          ) {
            associationsLoaded.value = true
            return (
              task.value.project?.parents.find((parent: Project) => {
                return parent.pivot?.is_default === 1
              })?.id || null
            )
          }
        }

        return null
      }

      const mapToPayload = () => {
        if (task.value) {
          payload.value.project_parent_id = mapAssociation()
          payload.value.summary = latestLog.value
            ? latestLog.value.name
            : task.value.form.name
          payload.value.users_to_notify = task.value.notifications || []
        }
      }

      const checkDraftTriggers = () => {
        if (relatedDraftTrigger.value) {
          payload.value.form_trigger = {
            form_field_id: null,
            form_id: relatedDraftTrigger.value.triggered_task_id,
            trigger_date: relatedDraftTrigger.value.triggered_date,
            project_id: relatedDraftTrigger.value.project_id,
          }
        }
      }

      const awaitingApproval = computed(() => {
        if (latestLog.value) {
          if (latestLog.value.status?.name === 'Sent For Approval') {
            return true
          }
        }

        return false
      })

      const init = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('tasks/setTask', null)
        store.commit('taskLogs/setLog', null)
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setPageTaskId', props.taskId)

        store
          .dispatch('tasks/show', {
            id: props.taskId,
            params: { edit_log: route.name === 'EditLog' },
          })
          .then(async () => {
            state.crumbs.push({
              name: task.value.name,
              url: `/tasks/${task.value.id}/logs`,
            })

            store.commit('genericStore/setBreadcrumbs', {
              crumbs: state.crumbs,
            })

            mapToPayload()
            const formFields = buildFields(task.value.form.fields)

            formattedFields.value = formFields.fields

            state.active_multi_step = formFields.state.active_multi_step
            state.steps = formFields.state.steps

            if (route.name === 'EditLog') {
              await store.dispatch('taskLogs/getLatestLog', props.taskId)
              checkDraftTriggers()
            }

            await store.dispatch('users/index', {})
          })
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
            router.push({ name: 'ViewLog', params: { taskId: props.taskId } })
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      watch(
        () => store.getters['genericStore/userAboutToBeLoggedOut'],
        (value) => {
          if (value === true) {
            autoSaveLog()
          }
        }
      )

      onMounted(() => {
        init()
      })

      return {
        formattedFields,
        state,
        task,
        getProps,
        getFieldComponent,
        registerCustomEventListeners,
        showFormInformation,
        submit,
        optionClicked,
        associations,
        payload,
        users,
        updateNotifications,
        updateFormTrigger,
        formTriggerConfig,
        shouldShowTriggerConfig,
        associationsLoaded,
        showFields,
        saveDraft,
        latestLog,
        showStepFields,
        awaitingApproval,
        errors,
      }
    },
  })
