import { EnComponents } from "@/locales/messages/components"

export default {
    employeeQualifications: {
        header: 'Employee Qualifications',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                type: EnComponents.grid.headers.type,
                name: `Qualification ${EnComponents.grid.headers.name}`,
                issuingBody: 'Issuing Body',
                training: 'Training Course Attended',
                date: `Qualification ${EnComponents.grid.headers.date}`
            }
        }
    }
}