import { GetterTree, MutationTree, ActionTree } from 'vuex'
import SummaryService from '@/services/summary/summary'

export type SummaryGetter = GetterTree<SummaryState, any>

export interface SummaryState {
  sentApprovals: object[]
  tasksForSummary: object[]
  missedTasksForSummary: object[]
}

export const state: SummaryState = {
  sentApprovals: [],
  tasksForSummary: [],
  missedTasksForSummary: [],
}

export const getters: SummaryGetter = {
  sentApprovals: (state) => state.sentApprovals,
  tasksForSummary: (state) => state.tasksForSummary,
  missedTasksForSummary: (state) => state.missedTasksForSummary,
}

export const actions: ActionTree<SummaryState, any> = {
  getTasksForSummary: (context, params) => {
    context = context
    let results = Promise.resolve(SummaryService.getTasksForSummary(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setTasksForSummary', response.data)
      if (params.category === 'overdue tasks') {
        context.commit('setMissedTasksForSummary', response.data)
      }
    })

    return results
  },
  getSentApprovals: (context, params) => {
    let results = Promise.resolve(SummaryService.getApprovals(params))

    results.then((response) => {
      context.commit('setSentApprovals', response.data)
    })

    return results
  },
}

export const mutations: MutationTree<SummaryState> = {
  setSentApprovals: (state, approvals) => (state.sentApprovals = approvals),
  setTasksForSummary: (state, tasks) => (state.tasksForSummary = tasks),
  setMissedTasksForSummary: (state, tasks) =>
    (state.missedTasksForSummary = tasks),
}

export const summary = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
