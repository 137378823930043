import { ActionTree, GetterTree, MutationTree } from 'vuex'
import ShiftInstances from '@/services/rotas/ShiftInstances'
import { ShiftInstance } from '@/types/modules/rotas/ShiftInstance'

export type ShiftInstanceGetter = GetterTree<ShiftInstanceState, any>

export interface ShiftInstanceState {
  shiftInstances: ShiftInstance[]
}

export const state: ShiftInstanceState = {
  shiftInstances: [],
}

export const getters: ShiftInstanceGetter = {
  getShiftInstances: (state) => state.shiftInstances,
}

export const actions: ActionTree<ShiftInstanceState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(ShiftInstances.index(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setShiftInstances', response.data)
    })

    return results
  },
  update: (_context, params) => {
    let results = Promise.resolve(ShiftInstances.update(params))

    return results
  },
}

export const mutations: MutationTree<ShiftInstanceState> = {
  setShiftInstances: (state, instances) => (state.shiftInstances = instances),
}

export const shiftInstances = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
