
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    watch,
    onMounted,
    ref,
    Ref,
  } from 'vue'
  import TimeInput from '../TimeInput.vue'
  import DateInput from '../DateInput.vue'
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import { format } from 'date-fns/esm'
  import { addDays, subDays } from 'date-fns'
  import isAfter from 'date-fns/isAfter'
  import startOfDay from 'date-fns/startOfDay/index'
  import isBefore from 'date-fns/isBefore'
  import isValid from 'date-fns/isValid'

  export default defineComponent({
    components: {
      DateInput,
      TimeInput,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const error = ref() as Ref<string | undefined>

      const state = reactive({
        time: format(new Date(), 'HH:mm'),
        date: format(new Date(), 'yyyy-MM-dd'),
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': error.value,
          'text-gray-400': !error.value,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const inputClasses = computed(() => ({
        'bg-white': true,
        'border-b-2': true,
        'transition-all': true,
        'p-2': true,
        'pl-6': true,
        'w-full': true,
        'border-0': true,
        'border-gray-400': true,
        'focus:border-blue-300': true,
        'border-red-300': error.value,
      }))

      const min = computed(() => {
        if (!props.fieldAttributes.min) {
          return null
        }

        if (
          typeof props.fieldAttributes.min == 'number' &&
          props.fieldAttributes.value
        ) {
          return subDays(
            new Date(props.fieldAttributes.value),
            props.fieldAttributes.min
          )
        }

        if (
          typeof props.fieldAttributes.min == 'number' &&
          !props.fieldAttributes.value
        ) {
          return subDays(new Date(), props.fieldAttributes.min)
        }

        return new Date(props.fieldAttributes.min)
      })

      const max = computed(() => {
        if (!props.fieldAttributes.max) {
          return null
        }

        if (
          typeof props.fieldAttributes.max == 'number' &&
          props.fieldAttributes.value
        ) {
          return addDays(
            new Date(props.fieldAttributes.value),
            props.fieldAttributes.max
          )
        }

        if (
          typeof props.fieldAttributes.max == 'number' &&
          !props.fieldAttributes.value
        ) {
          return addDays(new Date(), props.fieldAttributes.max)
        }

        return new Date(props.fieldAttributes.max)
      })

      const validateInput = () => {
        if (
          (!state.date || !state.time) &&
          props.fieldAttributes.required == true
        ) {
          error.value = 'This field is required'
          return false
        }

        if (min.value) {
          if (
            isBefore(
              startOfDay(new Date(state.date)),
              startOfDay(new Date(min.value))
            )
          ) {
            error.value =
              'The date must be on or after - ' +
              format(new Date(min.value), 'dddd, MMMM Do yyyy')
            return false
          }
        }

        if (max.value) {
          if (
            isAfter(
              startOfDay(new Date(state.date)),
              startOfDay(new Date(max.value))
            )
          ) {
            error.value =
              'The date must be on or before - ' +
              format(new Date(max.value), 'dddd, MMMM Do yyyy')
            return false
          }
        }

        error.value = undefined
        return true
      }

      watch(state, () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: `${state.date} ${state.time}`,
            row_index: props.fieldAttributes.row_index,
          })
        }
      })

      const setExistingValue = (value: string | null) => {
        if (value) {
          const dateTime = new Date(value)

          if (isValid(dateTime)) {
            state.time = format(dateTime, 'HH:mm')
            state.date = format(dateTime, 'yyyy-MM-dd')
          }
        } else {
          state.time = format(new Date(), 'HH:mm')
          state.date = format(new Date(), 'yyyy-MM-dd')
        }
      }

      onMounted(() => {
        setExistingValue(props.fieldAttributes.value)
      })

      return {
        error,
        inputClasses,
        labelClasses,
        props,
        state,
        min,
        max,
        format,
      }
    },
  })
