import { GetterTree, ActionTree, MutationTree } from 'vuex'
import SettingsService from '@/services/settings/settings'
import { Setting } from '@/types/modules/settings/Setting'

export type SettingGetter = GetterTree<SettingState, any>

export interface SettingState {
  settings: Setting[]
  passwordGuidelines: string | null
}

export const state: SettingState = {
  settings: [],
  passwordGuidelines: null,
}

export const getters: SettingGetter = {
  getSettings: (state) => state.settings,
  passwordGuidelines: (state) => state.passwordGuidelines,
}

export const actions: ActionTree<SettingState, any> = {
  index: async (context, params) => {
    const response = await SettingsService.index(params)

    if (response.success && params.per_page) {
      context.commit('setSettings', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setSettings', response.data)

      return response
    }

    return response
  },
  show: (_context, setting: string) => {
    const response = SettingsService.show(setting)

    return response
  },
  getPasswordGuidelines: async (context) => {
    const response = await SettingsService.getPasswordGuidelines()

    if (response.success) {
      context.commit('setPasswordGuidelines', response.data)
    }

    return response
  },
  update: (_context, payload) => {
    const response = Promise.resolve(SettingsService.updateSettings(payload))

    return response
  },
}

export const mutations: MutationTree<SettingState> = {
  setPasswordGuidelines: (state, guidelines: string) => {
    state.passwordGuidelines = guidelines
  },
  setSettings: (state, settings) => (state.settings = settings),
}

export const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
