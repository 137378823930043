import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Contract Types",
    "create-view": "ContractTypeCreate",
    "edit-view": "ContractTypeEdit",
    "delete-view": "ContractTypeDelete",
    "restore-view": "ContractTypeRestore",
    action: "contractTypes/index",
    "toggle-visibility-action": "contractTypes/toggleManyVisibility",
    "delete-many-action": "contractTypes/deleteMany",
    "reorder-action": "contractTypes/saveOrderings"
  }))
}