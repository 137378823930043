import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_link_cell_template = _resolveComponent("link-cell-template")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_link_cell_template, {
      target: "_blank",
      icon: _ctx.props.data.is_sensitive ? 'fas fa-shield-halved' : '',
      label: _ctx.props.data?.name,
      href: _ctx.props.data?.url,
      "use-router": false,
      "is-sensitive": _ctx.props.data.is_sensitive
    }, null, 8, ["icon", "label", "href", "is-sensitive"])
  ]))
}