import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import CalendarIndex from '../views/modules/calendar/Index.vue'
import CalendarEventShow from '../views/modules/calendar/EventShow.vue'
import CalendarEventCreate from '../views/modules/calendar/EventCreate.vue'
import CalendarEventEdit from '../views/modules/calendar/EventEdit.vue'
import { canEdit, canView } from '@/middleware/permissions/module'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import ManageEventType from '@/views/lookups/ManageEventType.vue'
import RestoreEventTypes from '@/views/modules/calendar/RestoreEventTypes.vue'
import ManageEventStatus from '@/views/lookups/ManageEventStatus.vue'
import RestoreEventStatuses from '@/views/modules/calendar/RestoreEventStatuses.vue'

export default [
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarIndex,
    meta: {
      title: 'Calendar',
      module: 'calendar',
      middleware: [auth, canView],
    },
  },
  {
    path: '/calendar/events/:id',
    name: 'CalendarEventShow',
    component: CalendarEventShow,
    props: true,
    meta: {
      title: 'View Event',
      module: 'calendar',
      middleware: [auth, canView],
    },
  },
  {
    path: '/calendar/events/create',
    name: 'CalendarEventCreate',
    component: CalendarEventCreate,
    meta: {
      title: 'Create Event',
      module: 'calendar',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/calendar/events/:id/edit',
    name: 'CalendarEventEdit',
    component: CalendarEventEdit,
    props: true,
    meta: {
      title: 'Edit Event',
      module: 'calendar',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/types/create',
    name: 'EventTypeCreate',
    component: ManageEventType,
    props: () => ({
      showAction: 'eventTypes/show',
      storeAction: 'eventTypes/store',
    }),
    meta: {
      title: 'Create Event Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/types/:id/edit',
    name: 'EventTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'eventTypes/show',
      updateAction: 'eventTypes/update',
      deleteAction: 'eventTypes/delete',
    }),
    component: ManageEventType,
    meta: {
      title: 'Update Event Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/types/delete',
    name: 'EventTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Event Type(s)',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/types/restore',
    name: 'EventTypeRestore',
    component: RestoreEventTypes,
    meta: {
      title: 'Restore Deleted Event Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/statuses/create',
    name: 'EventStatusCreate',
    component: ManageEventStatus,
    props: () => ({
      showAction: 'eventStatuses/show',
      storeAction: 'eventStatuses/store',
    }),
    meta: {
      title: 'Create Event Status',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/statuses/:id/edit',
    name: 'EventStatusEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'eventStatuses/show',
      updateAction: 'eventStatuses/update',
      deleteAction: 'eventStatuses/delete',
    }),
    component: ManageEventStatus,
    meta: {
      title: 'Update Event Status',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/statuses/delete',
    name: 'EventStatusDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Event Statuses',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/events/statuses/restore',
    name: 'EventStatusRestore',
    component: RestoreEventStatuses,
    meta: {
      title: 'Restore Deleted Event Statuses',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
