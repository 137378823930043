
  import { defineComponent, computed, reactive, onMounted } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import { Column } from 'devextreme/ui/data_grid'
  import { useI18n } from 'vue-i18n'
  import { Project } from '@/types/modules/projects/Project'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      projectType: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const { t } = useI18n()

      const state = reactive({
        selectedRows: [] as Project[],
      })

      const projectInstance = computed(() => {
        if (props.projectType === 'young_people') {
          return store.getters['youngPeople/getYoungPerson']
        }
        return store.getters[
          `${props.projectType}/${props.projectType.slice(0, -1)}`
        ]
      })

      const columns = computed((): Column[] => [
        {
          width: 100,
          dataField: 'association.id',
          caption: t('projectAssociations.grid.headers.id'),
          dataType: 'number',
          allowGrouping: false,
        },
        {
          dataField: 'name',
          caption: t('projectAssociations.grid.headers.name'),
          cellTemplate: 'AssociationNameCellTemplate',
          allowGrouping: false,
        },
        {
          dataField: 'association.status',
          caption: t('projectAssociations.grid.headers.status'),
        },
        {
          dataField: 'association.project_type_label',
          caption: 'Type',
        },
        {
          dataField: 'admission_date',
          caption: t('projectAssociations.grid.headers.admissionDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'leaving_date',
          caption: t('projectAssociations.grid.headers.leavingDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
      ])

      const rowsSelected = (rows: Project[]) => {
        state.selectedRows = rows
      }

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projectAssociations/restoreMany', {
            project_id: projectInstance.value.project_id,
            ids: state.selectedRows.map((project: Project) => project.id),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then(goBack)
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(async () => {
        if (!projectInstance.value) {
          store.dispatch('genericStore/showPageLoader', true)
          if (props.projectType === 'young_people') {
            await store.dispatch(`youngPeople/show`, { id: props.id })
          } else {
            await store.dispatch(`${props.projectType}/show`, { id: props.id })
          }
          store.dispatch('genericStore/showPageLoader', false)
        }
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            {
              name: 'Dashboard',
              url: '/dashboard',
            },
            {
              name: props.projectType,
              url: `/projects/${props.projectType}`,
            },
            {
              name: projectInstance.value.project?.name,
              url: `/projects/${props.projectType}/${props.id}`,
            },
            {
              name: 'Associations',
              url: `/projects/${props.projectType}/${props.id}?tab=associations`,
            },
            {
              name: 'Deleted Associations',
              url: `#`,
            },
          ],
        })
      })

      return {
        goBack,
        columns,
        restore,
        state,
        rowsSelected,
        props,
        projectInstance,
        t,
      }
    },
  })
