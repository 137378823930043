import { OverseasCriminalRecord } from '@/types/modules/staffs/OverseasCriminalRecord'
import EmployeeOverseasCriminalRecordService from '@/services/projects/employees/employeeOverseasCriminalRecord'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface EmployeeOverseasCriminalRecordState {
  overseasCriminalRecord: OverseasCriminalRecord | null
}

export const state: EmployeeOverseasCriminalRecordState = {
  overseasCriminalRecord: null,
}

export const getters: GetterTree<EmployeeOverseasCriminalRecordState, any> = {
  overseasCriminalRecord: (state) => state.overseasCriminalRecord,
}

export const actions: ActionTree<EmployeeOverseasCriminalRecordState, any> = {
  show: (context, params) => {
    const response = Promise.resolve(
      EmployeeOverseasCriminalRecordService.show(params.id, params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setOverseasCriminalRecord', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = Promise.resolve(
      EmployeeOverseasCriminalRecordService.store(payload.id, payload.data)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setOverseasCriminalRecord', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<EmployeeOverseasCriminalRecordState> = {
  setOverseasCriminalRecord: (
    state,
    overseasCriminalRecord: OverseasCriminalRecord
  ) => (state.overseasCriminalRecord = overseasCriminalRecord),
}

export const employeeOverseasCriminalRecord = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
