import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filing_cabinet = _resolveComponent("filing-cabinet")!
  const _component_project_associations = _resolveComponent("project-associations")!
  const _component_user_permissions = _resolveComponent("user-permissions")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_task_attachments = _resolveComponent("task-attachments")!
  const _component_school_details = _resolveComponent("school-details")!
  const _component_medical_cabinet = _resolveComponent("medical-cabinet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('filing-cabinet'))
      ? (_openBlock(), _createBlock(_component_filing_cabinet, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('associations') && _ctx.school?.project)
      ? (_openBlock(), _createBlock(_component_project_associations, {
          key: 1,
          "instance-id": _ctx.school.id,
          project: _ctx.school.project
        }, null, 8, ["instance-id", "project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('user-permissions') && _ctx.school)
      ? (_openBlock(), _createBlock(_component_user_permissions, {
          key: 2,
          "project-id": _ctx.school.project_id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('school-attachments'))
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 3,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('log-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 4,
          "project-id": _ctx.id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('details') && _ctx.school)
      ? (_openBlock(), _createBlock(_component_school_details, {
          key: 5,
          school: _ctx.school
        }, null, 8, ["school"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('medical-cabinet') && _ctx.school)
      ? (_openBlock(), _createBlock(_component_medical_cabinet, {
          key: 6,
          project: _ctx.school.project
        }, null, 8, ["project"]))
      : _createCommentVNode("", true)
  ]))
}