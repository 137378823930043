
  import { reactive, defineComponent, computed, watch, ref } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { renderCellLink } from '@/composables/Grids'
  import { RecycleBin } from '@/types/modules/generic/RecycleBin'
  import { Resident } from '@/types/modules/projects/Resident'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: { DataGrid },
    props: {
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const query = ref({})
      const store = useStore()
      const router = useRouter()

      const state = reactive({
        componentKey: 0,
        activeChip: 'current' as string,
        chips: ['all', 'current', 'planned', 'past'],
        extraOptions: ['View Deleted Young People'],
        selectedRows: [] as Resident[],
        showDeleteManyConfirmDialog: false,
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'project_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'project.name',
          caption: 'Young Person Name',
          allowSorting: false,
          cellTemplate: 'ProjectNameCellTemplate',
        },
        {
          dataField: 'project.location.name',
          caption: 'Location',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'resident_type.name',
          caption: 'Resident Type',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'gender.name',
          caption: 'Gender',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'project.start_date',
          caption: 'Start Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.end_date',
          caption: 'End Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.status',
          caption: 'Status',
          allowSorting: false,
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.project?.id,
            })
          },
          allowExporting: false,
        },
        {
          caption: 'Create Task',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Create Task', 'CreateTask', {
              id: options.data?.project?.id,
              projectType: 'young_people',
            })
          },
          allowExporting: false,
        },
      ])

      const filters = computed(() => {
        return {
          relations: ['project.parents', 'resident_type', 'gender'],
          ...query.value,
          ...props.filters,
        }
      })
      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }
      const applyFilters = () => {
        query.value = {
          ...(state.activeChip && { status: state.activeChip }),
        }
      }
      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/deleteMany', {
            ids: state.selectedRows.map((row: Resident) => row.project_id),
          })
          .then(() => {
            state.selectedRows = []
            state.showDeleteManyConfirmDialog = false
          })
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => refreshGrid())
      }

      const refreshGrid = () => {
        state.componentKey += 1
      }

      const recycleBinData: RecycleBin = reactive({
        key: 'project_id',
        action: 'youngPeople/index',
        restoreAction: 'projects/restoreMany',
        columns: [
          {
            caption: 'ID',
            dataField: 'project_id',
            allowGrouping: false,
            dataType: 'number',
            width: 100,
          },
          {
            dataField: 'project.name',
            caption: 'Young Person Name',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.start_date',
            caption: 'Start Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.end_date',
            caption: 'End Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.status',
            caption: 'Status',
            allowSorting: false,
            allowGrouping: false,
          },
        ],
      })

      const viewDeletedRecords = () => {
        store.commit('genericStore/setRecycleBinData', recycleBinData)
        router.push({
          name: 'RecycleBin',
        })
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          return (state.showDeleteManyConfirmDialog = true)
        }

        return viewDeletedRecords()
      }

      watch(
        () => state.selectedRows,
        () => updateExtraOptions()
      )

      const rowsSelected = (rows: Resident[]) => {
        state.selectedRows = rows
      }

      const updateExtraOptions = () => {
        const deleteExists = state.extraOptions.indexOf('Delete')

        if (state.selectedRows.length && deleteExists < 0) {
          state.extraOptions.push('Delete')
        } else if (!state.selectedRows.length && deleteExists >= 0) {
          state.extraOptions.splice(deleteExists, 1)
        }
      }

      return {
        rowsSelected,
        optionClicked,
        deleteSelectedRecords,
        chipClicked,
        props,
        state,
        columns,
        filters,
      }
    },
  })
