import { ModulePermission } from '@/types/modules/permissions/ModulePermission'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import PermissionService from '@/services/permissions/permissions'

export interface PermissionState {
  modulePermissions: ModulePermission[]
  projectTypePermissions: any[]
}

export const state: PermissionState = {
  modulePermissions: [],
  projectTypePermissions: [],
}

export const getters: GetterTree<PermissionState, any> = {
  modulePermissions: (state) => state.modulePermissions,
  getProjectTypePermissions: (state) => state.projectTypePermissions,
}

export const actions: ActionTree<PermissionState, any> = {
  getModulePermissions: (context, params) => {
    const response = Promise.resolve(
      PermissionService.getModulePermissions(params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setModulePermissions', response.data)
      }
    })

    return response
  },
  getProjectTypePermissions: (context, params) => {
    const response = Promise.resolve(
      PermissionService.getProjectTypePermissions(params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setProjectTypePermissions', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<PermissionState> = {
  setModulePermissions: (state, permissions: ModulePermission[]) => {
    state.modulePermissions = permissions
  },
  setProjectTypePermissions: (state, permissions) =>
    (state.projectTypePermissions = permissions),
}

export const permissions = {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
