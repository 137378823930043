
  import { defineComponent, PropType, computed } from 'vue'
  import TaskCard from '../../Card/TaskCard.vue'
  import TaskCardDetails from '../../Card/TaskCardDetails.vue'

  export default defineComponent({
    components: {
      TaskCard,
      TaskCardDetails,
    },
    props: {
      data: {
        required: true,
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      const type = computed(() => {
        let status = props.data.status.name.toLowerCase()

        if (status === 'incomplete') {
          return 'danger'
        }

        if (status === 'sent for approval') {
          return 'secondary'
        }

        return 'default'
      })

      const link = computed(() => {
        let status = props.data.status.name.toLowerCase()

        if (status === 'incomplete') {
          return `/tasks/${props.data.id}/logs/edit`
        }

        if (status === 'sent for approval') {
          return null
        }

        return null
      })

      return {
        props,
        type,
        link,
      }
    },
  })
