
  import { Task } from '@/types/modules/tasks/Task'
  import { defineComponent, PropType, computed } from 'vue'
  import TaskCardDetails from '../../Card/TaskCardDetails.vue'
  import CcAvatar from '../../Avatar/Avatar.vue'
  import TaskCard from '../../Card/TaskCard.vue'
  import { isAfter, startOfDay } from 'date-fns'

  export default defineComponent({
    components: {
      TaskCardDetails,
      CcAvatar,
      TaskCard,
    },
    props: {
      data: {
        type: Object as PropType<Task>,
        required: true,
      },
    },
    setup(props) {
      const task = computed((): Task => props.data)

      const determineStatus = (task: Task) => {
        if (isAfter(startOfDay(new Date()), new Date(task.start_date!))) {
          return 'Overdue'
        }

        return 'Not Started'
      }

      const determineType = (task: Task) => {
        if (isAfter(startOfDay(new Date()), new Date(task.start_date!))) {
          return 'danger'
        }

        return 'primary'
      }

      return {
        task,
        determineType,
        determineStatus,
      }
    },
  })
