import TasksOverview from '@/views/modules/tasks/Overview.vue'
import { RouteRecordRaw } from 'vue-router'
import ViewLog from '@/views/modules/tasks/ViewLog.vue'
import ManageLog from '@/views/modules/tasks/ManageLog.vue'
import MoveTask from '@/views/modules/tasks/MoveTask.vue'
import LinkTask from '@/views/modules/tasks/LinkTask.vue'
import CopyTask from '@/views/modules/tasks/CopyTask.vue'
import UnlinkTask from '@/views/modules/tasks/UnlinkTask.vue'
import MoveTaskAttachments from '@/views/modules/projects/partials/Attachments/MoveTaskAttachments.vue'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'
import TaskExplorerIndex from '@/views/modules/task_explorer/Index.vue'
import ManageTaskLogComment from '@/views/modules/tasks/partials/ManageTaskLogComment.vue'
import DeleteTaskLogComments from '@/views/modules/tasks/DeleteTaskLogComments.vue'
import RestoreTaskLogComments from '@/views/modules/tasks/RestoreTaskLogComments.vue'

export default [
  {
    path: '/overview/tasks',
    name: 'TasksOverview',
    component: TasksOverview,
    props: (route) => ({ status: route.query.status }),
    meta: {
      title: 'Tasks Overview',
      module: 'tasks',
      middleware: [auth, canView],
    },
    beforeEnter: (to) => {
      let types = [
        'all',
        'completed',
        'incomplete',
        'draft',
        'overdue',
        'awaiting-approval',
      ]

      // @ts-ignore
      let valid = types.indexOf(to.query.status) !== -1

      if (valid) {
        return true
      }

      return { path: to.path, query: { status: 'all' } }
    },
  },
  {
    path: '/tasks/:taskId/logs',
    name: 'ViewLog',
    component: ViewLog,
    props: true,
    meta: {
      title: 'View Log',
      module: 'tasks',
      middleware: [auth, canView],
    },
  },
  {
    path: '/tasks/:taskId/logs/create',
    name: 'CreateLog',
    component: ManageLog,
    props: true,
    meta: {
      title: 'Create Log',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/logs/edit',
    name: 'EditLog',
    component: ManageLog,
    props: true,
    meta: {
      title: 'Edit Log',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/logs/move',
    name: 'MoveTask',
    component: MoveTask,
    props: true,
    meta: {
      title: 'Move Task',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/link',
    name: 'LinkTask',
    component: LinkTask,
    props: true,
    meta: {
      title: 'Link Task',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/unlink',
    name: 'UnlinkTask',
    component: UnlinkTask,
    props: true,
    meta: {
      title: 'Unlink Task',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:id/move-attachments',
    name: 'MoveTaskAttachments',
    props: true,
    component: MoveTaskAttachments,
    meta: {
      title: 'Move Attachments',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/task-explorer',
    name: 'TaskExplorer',
    component: TaskExplorerIndex,
    meta: {
      title: 'Task Explorer',
      module: 'tasks',
      middleware: [auth, canView],
    },
  },
  {
    path: '/tasks/:taskId/logs/:taskLogId/comments/create',
    name: 'TaskLogCommentCreate',
    props: true,
    component: ManageTaskLogComment,
    meta: {
      title: 'Create a comment',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/logs/:taskLogId/comments/:commentId/edit',
    name: 'TaskLogCommentEdit',
    props: true,
    component: ManageTaskLogComment,
    meta: {
      title: 'Edit a comment',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/logs/:taskLogId/comments/delete',
    name: 'TaskLogCommentDelete',
    props: true,
    component: DeleteTaskLogComments,
    meta: {
      title: 'Delete comments',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/logs/:taskLogId/comments/restore',
    name: 'TaskLogCommentRestore',
    props: true,
    component: RestoreTaskLogComments,
    meta: {
      title: 'Restore comments',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/tasks/:taskId/copy',
    name: 'CopyTask',
    component: CopyTask,
    props: true,
    meta: {
      title: 'Copy Task',
      module: 'tasks',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
