import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!
  const _component_task_card_details = _resolveComponent("task-card-details")!
  const _component_task_card = _resolveComponent("task-card")!

  return (_openBlock(), _createBlock(_component_task_card, {
    size: "large",
    type: _ctx.determineType(_ctx.task),
    title: `${_ctx.task.name} for ${_ctx.task.project?.name}`,
    to: { name: 'ViewLog', params: { taskId: _ctx.task.id } }
  }, {
    image: _withCtx(() => [
      _createVNode(_component_cc_avatar, {
        size: "large",
        url: _ctx.task.project?.image?.thumbnail
      }, null, 8, ["url"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_task_card_details, {
        type: _ctx.determineType(_ctx.task),
        "status-text": _ctx.determineStatus(_ctx.task),
        date: _ctx.task.start_date,
        "task-id": _ctx.task.id
      }, null, 8, ["type", "status-text", "date", "task-id"])
    ]),
    _: 1
  }, 8, ["type", "title", "to"]))
}