
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import MultiSelect from '../MultiSelect.vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: {
      MultiSelect,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      const store = useStore()
      props = reactive(props)

      const systemList = ref([]) as Ref<any[]>
      const error = ref() as Ref<string | undefined>
      const value = ref()

      const validateInput = () => {
        if (!value.value.length && props.fieldAttributes?.required == true) {
          error.value = 'This field is required'
          return false
        }

        error.value = undefined
        return true
      }

      watch(value, () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            row_index: props.fieldAttributes.row_index,
            value: value.value,
          })
        }
      })

      const formattedValues = (data: string | any) => {
        if (!data) {
          return []
        }

        if (Array.isArray(data)) {
          return data.length ? data : []
        }

        if (typeof data === 'string') {
          if (data.includes('||')) {
            return data.split('||')
          }

          if (data.includes(',')) {
            return data.split(',')
          }
        }

        return data ? [data] : []
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        value.value = formattedValues(props.fieldAttributes.value)

        store
          .dispatch('forms/getSystemListValues', {
            system_list_id: props.fieldAttributes?.system_list?.id,
            project_id: props.fieldAttributes?.project?.id,
            in_preview_mode:
              props.fieldAttributes?.system_list?.in_preview_mode,
          })
          .then((response: { data: any }) => {
            systemList.value = response.data
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        props,
        systemList,
        value,
        error,
      }
    },
  })
