import ProjectNameCellTemplate from './ProjectNameCellTemplate.vue'
import CareGroupNameCellTemplate from './CareGroupNameCellTemplate.vue'
import AttachmentNameCellTemplate from './AttachmentNameCellTemplate.vue'
import UserUsernameCellTemplate from './UserUsernameCellTemplate.vue'
import AdjustStockCellTemplate from './AdjustStockCellTemplate.vue'
import StockHistoryCellTemplate from './StockHistoryCellTemplate.vue'
import MedicationNameCellTemplate from './MedicationNameCellTemplate.vue'
import EvidenceDocumentsCellTemplate from './EvidenceDocumentsCellTemplate.vue'
import RecruitmentFilesTemplate from './RecruitmentFilesTemplate.vue'
import LookupGridHandleTemplate from './LookupGridHandleTemplate.vue'
import AssociationNameCellTemplate from './AssociationNameCellTemplate.vue'
import PermissionCellTemplate from './PermissionCellTemplate.vue'
import TaskCardGridTemplate from './TaskCardGridTemplate.vue'

export default {
  ProjectNameCellTemplate,
  CareGroupNameCellTemplate,
  AttachmentNameCellTemplate,
  UserUsernameCellTemplate,
  AdjustStockCellTemplate,
  StockHistoryCellTemplate,
  MedicationNameCellTemplate,
  EvidenceDocumentsCellTemplate,
  RecruitmentFilesTemplate,
  LookupGridHandleTemplate,
  AssociationNameCellTemplate,
  PermissionCellTemplate,
  TaskCardGridTemplate,
}
