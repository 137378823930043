import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    ref: "grid",
    action: "procedures/index",
    "read-only": _ctx.cannotEditModule('procedures'),
    columns: _ctx.columns,
    chips: _ctx.state.chips,
    "query-filter": _ctx.queryFilter,
    onChipClicked: _ctx.chipClicked
  }, null, 8, ["read-only", "columns", "chips", "query-filter", "onChipClicked"]))
}