
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import { format } from 'date-fns/esm'
  import addDays from 'date-fns/esm/addDays/index'
  import startOfDay from 'date-fns/fp/startOfDay/index'
  import isAfter from 'date-fns/isAfter'
  import isBefore from 'date-fns/isBefore'
  import isValid from 'date-fns/isValid'
  import subDays from 'date-fns/subDays'
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    ref,
    watch,
    Ref,
    onMounted,
  } from 'vue'
  import DateInput from '../DateInput.vue'

  export default defineComponent({
    components: {
      DateInput,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const value = ref()
      const errors = ref() as Ref<undefined | string>

      const label = computed(() => {
        return props.fieldAttributes.in_table == false
          ? props.fieldAttributes.label
          : undefined
      })

      const min = computed(() => {
        if (!props.fieldAttributes.min) {
          return null
        }

        if (
          !isValid(new Date(props.fieldAttributes.min)) &&
          props.fieldAttributes.value
        ) {
          return format(
            subDays(
              new Date(props.fieldAttributes.value),
              Number(props.fieldAttributes.min)
            ),
            'yyyy-MM-dd'
          )
        }

        if (
          !isValid(new Date(props.fieldAttributes.min)) &&
          !props.fieldAttributes.value
        ) {
          return format(
            subDays(new Date(), Number(props.fieldAttributes.min)),
            'yyyy-MM-dd'
          )
        }

        return format(new Date(props.fieldAttributes.min), 'yyyy-MM-dd')
      })

      const max = computed(() => {
        if (!props.fieldAttributes.max) {
          return null
        }

        if (
          !isValid(new Date(props.fieldAttributes.max)) &&
          props.fieldAttributes.value
        ) {
          return format(
            addDays(
              new Date(props.fieldAttributes.value),
              Number(props.fieldAttributes.max)
            ),
            'yyyy-MM-dd'
          )
        }

        if (
          !isValid(new Date(props.fieldAttributes.max)) &&
          !props.fieldAttributes.value
        ) {
          return format(
            addDays(new Date(), Number(props.fieldAttributes.max)),
            'yyyy-MM-dd'
          )
        }

        return format(new Date(props.fieldAttributes.max), 'yyyy-MM-dd')
      })

      const validateInput = () => {
        if (!value.value && props.fieldAttributes.required == true) {
          errors.value = 'Please provide a date'
          return false
        }

        if (min.value) {
          if (
            isBefore(
              startOfDay(new Date(value.value)),
              startOfDay(new Date(min.value))
            )
          ) {
            errors.value =
              'The date must be on or after - ' +
              format(new Date(min.value), 'dddd, MMMM Do yyyy')
            return false
          }
        }

        if (max.value) {
          if (
            isAfter(
              startOfDay(new Date(value.value)),
              startOfDay(new Date(max.value))
            )
          ) {
            errors.value =
              'The date must be on or before - ' +
              format(new Date(max.value), 'dddd, MMMM Do yyyy')
            return false
          }
        }

        errors.value = undefined
        return true
      }

      watch(value, () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: value.value,
            row_index: props.fieldAttributes.row_index,
          })
        }
      })

      onMounted(() => (value.value = props.fieldAttributes.value))

      return {
        errors,
        min,
        label,
        props,
        max,
        value,
      }
    },
  })
