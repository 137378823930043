import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex gap-3" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.props.icon)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(_ctx.props.icon)
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("label", { for: _ctx.label }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            class: _normalizeClass(_ctx.labelClasses)
          }, [
            (_ctx.props.isRequired)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(_ctx.props.isDisabled ? 'text-gray-300' : 'text-red-400')
                }, "*", 2))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
          ], 2))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}