import { EnComponents } from '@/locales/messages/components'

export default {
  userPermissions: {
    header: 'User Permissions',
    grid: {
      headers: {
        id: EnComponents.grid.headers.id,
        createdOn: EnComponents.grid.headers.createdOn,
        createdBy: EnComponents.grid.headers.createdBy,
        updatedOn: EnComponents.grid.headers.updatedOn,
        updatedBy: EnComponents.grid.headers.updatedBy,
        date: EnComponents.grid.headers.date,
        name: `User ${EnComponents.grid.headers.name}`,
        permission: 'Permission',
        role: 'Role',
        edit: 'Edit Permission',
      },
      cells: {
        read: 'Read',
        noAccess: 'No Access',
        readWrite: 'Read / Write',
        edit: 'Edit',
      },
    },
  },
}
