import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_general_settings = _resolveComponent("general-settings")!
  const _component_calendar_settings = _resolveComponent("calendar-settings")!
  const _component_password_policy = _resolveComponent("password-policy")!
  const _component_system_list_settings = _resolveComponent("system-list-settings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('general-settings'))
      ? (_openBlock(), _createBlock(_component_general_settings, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('calendar-settings'))
      ? (_openBlock(), _createBlock(_component_calendar_settings, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('password-policies'))
      ? (_openBlock(), _createBlock(_component_password_policy, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('system-lists'))
      ? (_openBlock(), _createBlock(_component_system_list_settings, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}