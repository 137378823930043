
  import { defineComponent, watch, reactive, PropType, onMounted } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import NumberInput from '@/components/Generic/Forms/Inputs/NumberInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { useI18n } from 'vue-i18n'
  import { Home } from '@/types/modules/projects/Home'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      TextInput,
      DateInput,
      NumberInput,
    },
    props: {
      home: {
        type: Object as PropType<Home>,
        required: true,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const payload = reactive({
        ofsted_urn: undefined,
        ofsted_rating: undefined,
        ofsted_rating_date: undefined,
        ofsted_requirement_no: undefined,
        ofsted_req_reg_no: undefined,
        ofsted_rec_reg_no: undefined,
        interim_ofsted_rating: undefined,
        interim_rating_date: undefined,
        interim_requirement_no: undefined,
        interim_req_reg_no: undefined,
        interim_recommendation_no: undefined,
        interim_rec_reg_no: undefined,
      })

      watch(payload, () => emit('update-payload', payload))

      const mapToPayload = () => {
        Object.assign(payload, {
          ofsted_urn: props.home.ofsted_urn,
          ofsted_rating: props.home.ofsted_rating,
          ofsted_rating_date: props.home.ofsted_rating_date
            ? format(new Date(props.home.ofsted_rating_date), 'yyyy-MM-dd')
            : undefined,
          ofsted_requirement_no: props.home.ofsted_requirement_no,
          ofsted_req_reg_no: props.home.ofsted_req_reg_no,
          ofsted_rec_reg_no: props.home.ofsted_rec_reg_no,
          interim_ofsted_rating: props.home.interim_ofsted_rating,
          interim_rating_date: props.home.interim_rating_date
            ? format(new Date(props.home.interim_rating_date), 'yyyy-MM-dd')
            : undefined,
          interim_requirement_no: props.home.interim_requirement_no,
          interim_req_reg_no: props.home.interim_req_reg_no,
          interim_recommendation_no: props.home.interim_recommendation_no,
          interim_rec_reg_no: props.home.interim_rec_reg_no,
        })
      }

      onMounted(() => {
        mapToPayload()
      })
      return {
        t,
        props,
        payload,
      }
    },
  })
