import { sharedState, rotaEmployees, totalSteps, jobTitles } from './Rotas'
import { shiftTypes } from './ShiftTypes'
import store from '@/store'
import {
  addDays,
  addHours,
  addWeeks,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  set,
  startOfDay,
} from 'date-fns'
import { isSameDayOrAfter, isSameDayOrBefore } from '../Dates'

export const buildForm = (e: any) => {
  let form = e.form
  let shift = e.appointmentData
  shift.daysToCopyTo = []

  sharedState.groupings.dates.forEach((date) => {
    let dataField = `CopyTo${date.week_day_long}`
    delete shift[dataField]
  })

  setShiftEmployees(shift.valid_job_titles, shift.shift_start, shift.shift_end)

  if ((!shift.id || !Number.isInteger(shift.id)) && !shift.condition) {
    form.updateData(
      'shift_end',
      setDefaultEndDate(form.getEditor('shift_start').option('value'))
    )
  }
  if (sharedState.activeTab === 'Default Shift Patterns') {
    form.updateData(
      'start_day',
      format(form.getEditor('shift_start').option('value'), 'yyyy/MM/dd')
    )
    form.updateData('start_time', form.getEditor('shift_start').option('value'))
    form.updateData(
      'end_day',
      format(form.getEditor('shift_end').option('value'), 'yyyy/MM/dd')
    )
    form.updateData('end_time', form.getEditor('shift_end').option('value'))
  }

  form.option('items', [
    {
      itemType: 'group',
      colCount: 2,
      colSpan: 2,
      items: [
        {
          itemType: 'group',
          colCount: 1,
          colSpan: 2,
          items: [
            {
              itemType: 'group',
              colCount: 2,
              colSpan: 2,
              items: [
                {
                  dataField: 'id',
                  editorType: 'dxTextBox',
                  editorOptions: {
                    value: '_' + Math.random().toString(36).substr(2, 9),
                  },
                  visible: false,
                },
                {
                  label: {
                    text: 'Valid Job Titles',
                  },
                  dataField: 'valid_job_titles',
                  editorType: 'dxTagBox',
                  editorOptions: {
                    items: jobTitles.value,
                    displayExpr: 'job_title',
                    onValueChanged(args: any) {
                      const editor = form.getEditor('employee')

                      if (!args.value.length) {
                        sharedState.filtered_employees = []
                        editor.option('value', null)
                        editor.option('dataSource', [])
                      }

                      if (args.value.length) {
                        setShiftEmployees(
                          args.value,
                          shift.shift_start,
                          shift.shift_end
                        )
                        editor.option(
                          'dataSource',
                          sharedState.filtered_employees
                        )
                      }
                    },
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message:
                        'Please select at least one job title associated with this shift.',
                    },
                  ],
                },
                {
                  label: {
                    text: 'Employee',
                  },
                  name: 'employee',
                  dataField: 'employee_project_id',
                  editorType: 'dxSelectBox',
                  editorOptions: {
                    items: sharedState.filtered_employees,
                    displayExpr: 'name',
                    valueExpr: 'id',
                    showClearButton: true,
                  },
                },
              ],
            },
            {
              itemType: 'group',
              colCount: 2,
              colSpan: 2,
              items: [
                {
                  visible: sharedState.activeTab === 'Default Shift Patterns',
                  label: {
                    text: 'Shift Start Day',
                  },
                  dataField: 'start_day',
                  editorType: 'dxSelectBox',
                  editorOptions: {
                    items: sharedState.groupings.dates,
                    displayExpr: 'week_day_long',
                    valueExpr: 'string_date',
                    onValueChanged(args: any) {
                      if (!args.value) return

                      let start_date = new Date(args.value)
                      let hours = getHours(
                        new Date(form.getEditor('shift_start').option('value'))
                      )
                      let minutes = getMinutes(
                        new Date(form.getEditor('shift_start').option('value'))
                      )

                      let newStartDate = set(start_date, {
                        hours: hours,
                        minutes: minutes,
                      })
                      form.updateData('shift_start', newStartDate)
                    },
                  },
                },
                {
                  visible: sharedState.activeTab === 'Default Shift Patterns',
                  label: {
                    text: 'Shift Start Time',
                  },
                  dataField: 'start_time',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'time',
                    onValueChanged(args: any) {
                      if (!args.value) return

                      let start_date = new Date(
                        form.getEditor('shift_start').option('value')
                      )
                      let hours = getHours(new Date(args.value))
                      let minutes = getMinutes(new Date(args.value))

                      let newStartDate = set(start_date, {
                        hours: hours,
                        minutes: minutes,
                      })
                      form.updateData('shift_start', newStartDate)
                    },
                  },
                },
                {
                  visible: sharedState.activeTab === 'Default Shift Patterns',
                  label: {
                    text: 'Shift End Day',
                  },
                  dataField: 'end_day',
                  editorType: 'dxSelectBox',
                  editorOptions: {
                    items: sharedState.groupings.dates,
                    displayExpr: 'week_day_long',
                    valueExpr: 'string_date',
                    onValueChanged(args: any) {
                      if (!args.value) return

                      let end_date = new Date(args.value)
                      let hours = getHours(
                        new Date(form.getEditor('shift_end').option('value'))
                      )
                      let minutes = getMinutes(
                        new Date(form.getEditor('shift_end').option('value'))
                      )

                      let onLastWeekCycle =
                        sharedState.step === totalSteps.value
                      let startsOnSunday =
                        format(
                          new Date(
                            form.getEditor('shift_start').option('value')
                          ),
                          'EEEE'
                        ) === 'Sunday'
                      let endsOnMonday = format(end_date, 'EEEE') === 'Monday'

                      if (onLastWeekCycle && startsOnSunday && endsOnMonday) {
                        end_date = startOfDay(
                          addDays(
                            new Date(
                              form.getEditor('shift_start').option('value')
                            ),
                            1
                          )
                        )

                        store.dispatch(
                          'genericStore/pushNotification',
                          'A shift cannot be created for Monday as the currently selected week cycle is the last. ' +
                            'Please create a separate shift to start on Monday in Week 1.'
                        )

                        form.updateData('shift_end', end_date)
                        form.updateData(
                          'end_time',
                          new Date(form.getEditor('shift_end').option('value'))
                        )

                        return
                      } else if (
                        sharedState.step &&
                        sharedState.step < totalSteps.value &&
                        startsOnSunday &&
                        endsOnMonday
                      ) {
                        end_date = addWeeks(end_date, 1)
                      }

                      let newEndDate = set(end_date, {
                        hours: hours,
                        minutes: minutes,
                      })
                      form.updateData('shift_end', newEndDate)
                    },
                  },
                },
                {
                  visible: sharedState.activeTab === 'Default Shift Patterns',
                  label: {
                    text: 'Shift End Time',
                  },
                  dataField: 'end_time',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'time',
                    onValueChanged(args: any) {
                      if (!args.value) return

                      let end_date = new Date(
                        form.getEditor('shift_end').option('value')
                      )
                      let hours = getHours(new Date(args.value))
                      let minutes = getMinutes(new Date(args.value))

                      let newEndDate = set(end_date, {
                        hours: hours,
                        minutes: minutes,
                      })
                      form.updateData('shift_end', newEndDate)
                    },
                  },
                },
                {
                  label: {
                    visible: sharedState.activeTab === 'Live Rotas',
                    text: 'Shift Start',
                  },
                  dataField: 'shift_start',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'datetime',
                    acceptCustomValue: false,
                    elementAttr: {
                      class: 'shift_start_end',
                    },
                    disabledDates: (args: any) => {
                      return disabledDates(new Date(args.date))
                    },
                    onContentReady() {
                      if (sharedState.activeTab === 'Live Rotas') return
                      let inputs = document.querySelectorAll('.shift_start_end')
                      inputs.forEach((el: any) => {
                        el.style.display = 'none'
                      })
                    },
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message:
                        'You must provide a valid start date for the shift.',
                    },
                    {
                      type: 'custom',
                      reevaluate: true,
                      message: 'The start date cannot be after the end date',
                      validationCallback: (e: any) => {
                        let updated = new Date(e.value)
                        let end_shift = new Date(
                          form.getEditor('shift_end').option('value')
                        )

                        // If we are on default shifts, and we select a sunday
                        // as start date and monday as end date, ignore the "after"
                        // validation check and return
                        if (
                          sharedState.activeTab === 'Default Shift Patterns'
                        ) {
                          if (
                            format(updated, 'EEEE') === 'Sunday' &&
                            format(end_shift, 'EEEE') === 'Monday'
                          ) {
                            return true
                          }
                        }

                        return end_shift.getTime() >= updated.getTime()
                      },
                    },
                  ],
                },
                {
                  label: {
                    visible: sharedState.activeTab === 'Live Rotas',
                    text: 'Shift End',
                  },
                  dataField: 'shift_end',
                  editorType: 'dxDateBox',
                  editorOptions: {
                    type: 'datetime',
                    acceptCustomValue: false,
                    elementAttr: {
                      class: 'shift_start_end',
                    },
                    disabledDates: (args: any) => {
                      return disabledDates(new Date(args.date))
                    },
                    onContentReady() {
                      if (sharedState.activeTab === 'Live Rotas') return
                      let inputs = document.querySelectorAll('.shift_start_end')
                      inputs.forEach((el: any) => {
                        el.style.display = 'none'
                      })
                    },
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message:
                        'You must provide a valid end date for the shift.',
                    },
                    {
                      type: 'custom',
                      reevaluate: true,
                      message: 'The end time cannot be before the start time',
                      validationCallback: (e: any) => {
                        let updated = new Date(e.value)
                        let start_shift = new Date(
                          form.getEditor('shift_start').option('value')
                        )

                        // If we are on default shifts, and we select a sunday
                        // as start date and monday as end date, ignore the "before"
                        // validation check and return
                        if (
                          sharedState.activeTab === 'Default Shift Patterns'
                        ) {
                          if (
                            format(start_shift, 'EEEE') === 'Sunday' &&
                            format(updated, 'EEEE') === 'Monday'
                          ) {
                            return true
                          }
                        }

                        return start_shift.getTime() <= updated.getTime()
                      },
                    },
                  ],
                },
                {
                  label: {
                    text: 'Shift Type',
                  },
                  name: 'shiftType',
                  dataField: 'shift_type_id',
                  editorType: 'dxSelectBox',
                  editorOptions: {
                    items: shiftTypes.value,
                    displayExpr: 'name',
                    valueExpr: 'id',
                    onValueChanged(args: any) {
                      if (!args.value) return

                      let selectedType = shiftTypes.value.find(
                        (item: any) => item.id === args.value
                      )
                      form.updateData('shift_type_name', selectedType.name)
                      form.updateData(
                        'shift_type_icon',
                        selectedType.tag_icon.icon
                      )
                    },
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message:
                        'Please select a shift type associated with this shift.',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          visible: !shift.text,
          itemType: 'group',
          caption: 'Copy to Day(s)',
          cssClass: 'day-copy-heading',
          colCount: 3,
          colSpan: 2,
          items: [
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d: any) => d.week_day_short === 'Mon'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Monday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToMonday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let monday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Monday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(monday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(monday)) {
                      sharedState.daysToCopyTo.push(monday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Monday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue && format(start_shift, 'EEEE') === 'Monday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Tue'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Tuesday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToTuesday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let tuesday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Tuesday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(tuesday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(tuesday)) {
                      sharedState.daysToCopyTo.push(tuesday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Tuesday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue && format(start_shift, 'EEEE') === 'Tuesday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Wed'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Wednesday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToWednesday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let wednesday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Wednesday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(wednesday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(wednesday)) {
                      sharedState.daysToCopyTo.push(wednesday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Wednesday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue &&
                      format(start_shift, 'EEEE') === 'Wednesday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Thu'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Thursday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToThursday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let thursday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Thursday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(thursday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(thursday)) {
                      sharedState.daysToCopyTo.push(thursday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Thursday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue &&
                      format(start_shift, 'EEEE') === 'Thursday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Fri'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Friday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToFriday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let friday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Friday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(friday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(friday)) {
                      sharedState.daysToCopyTo.push(friday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Friday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue && format(start_shift, 'EEEE') === 'Friday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Sat'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Saturday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToSaturday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let saturday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Saturday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(saturday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(saturday)) {
                      sharedState.daysToCopyTo.push(saturday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Saturday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue &&
                      format(start_shift, 'EEEE') === 'Saturday'
                    )
                  },
                },
              ],
            },
            {
              visible: !shift.text,
              label: {
                location: 'left',
                alignment: 'left',
                text:
                  sharedState.activeTab === 'Live Rotas'
                    ? format(
                        sharedState.groupings.dates.find(
                          (d) => d.week_day_short === 'Sun'
                        )?.date!,
                        'EEEE'
                      )
                    : 'Sunday',
              },
              editorType: 'dxCheckBox',
              dataField: 'CopyToSunday',
              value: null,
              editorOptions: {
                onValueChanged(selection: any) {
                  let sunday = sharedState.groupings.dates.find(
                    (d) => d.week_day_long === 'Sunday'
                  )

                  if (!selection.value) {
                    let index = sharedState.daysToCopyTo.indexOf(sunday)
                    if (index > -1) {
                      sharedState.daysToCopyTo.splice(index, 1)
                    }
                  }

                  if (selection.value) {
                    if (!sharedState.daysToCopyTo.includes(sunday)) {
                      sharedState.daysToCopyTo.push(sunday)
                    }
                  }
                },
              },
              validationRules: [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: 'Sunday already selected',
                  validationCallback: (e: any) => {
                    let checkboxValue = e.value
                    let start_shift = new Date(
                      form.getEditor('shift_start').option('value')
                    )

                    return !(
                      checkboxValue && format(start_shift, 'EEEE') === 'Sunday'
                    )
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ])
}
const setDefaultEndDate = (start_date: any) => {
  return addHours(new Date(start_date), 8)
}

const setShiftEmployees = (
  job_titles = [],
  start_date_filter: any,
  end_date_filter: any
) => {
  if (!job_titles.length) {
    return (sharedState.filtered_employees = [])
  }

  if (sharedState.activeTab === 'Default Shift Patterns') {
    return (sharedState.filtered_employees = rotaEmployees.value.filter(
      (e: any) => {
        return (
          job_titles.map((t: any) => t.id).includes(e.employee.job_title_id) &&
          isSameDayOrBefore(new Date(e.start_date), new Date()) &&
          ((e.end_date && isSameDayOrAfter(new Date(e.end_date), new Date())) ||
            !e.end_date)
        )
      }
    ))
  }

  return (sharedState.filtered_employees = rotaEmployees.value.filter(
    (e: any) => {
      return (
        job_titles.map((t: any) => t.id).includes(e.employee.job_title_id) &&
        isSameDayOrBefore(
          new Date(e.start_date),
          new Date(start_date_filter)
        ) &&
        ((e.end_date &&
          isSameDayOrAfter(new Date(e.end_date), new Date(end_date_filter))) ||
          !e.end_date)
      )
    }
  ))
}

const disabledDates = (date_to_check: Date) => {
  let current_date = date_to_check
  let start_date = new Date(sharedState.timeline.start_date!)
  let end_date = new Date(sharedState.timeline.end_date!)

  return isBefore(current_date, start_date) || isAfter(current_date, end_date)
}
