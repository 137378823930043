import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex items-center relative" }
const _hoisted_2 = {
  key: 1,
  class: "absolute left-0 text-red-300 far fa-exclamation-circle"
}
const _hoisted_3 = ["required", "disabled", "value", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-display w-full", _ctx.hasErrors ? 'border-l-4 border-red-300 pl-3' : ''])
  }, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: _ctx.error,
      "is-required": _ctx.isRequired,
      "is-disabled": _ctx.isDisabled
    }, null, 8, ["label", "error", "is-required", "is-disabled"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasIconPrefix && !_ctx.hasErrors)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass([[_ctx.iconPrefix, _ctx.isDisabled ? 'text-gray-300' : ''], "absolute left-0"])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.hasErrors)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({
        required: _ctx.isRequired,
        disabled: _ctx.isDisabled,
        class: _ctx.inputClasses,
        type: "color"
      }, _ctx.$attrs, {
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        value: _ctx.modelValue,
        id: _ctx.label
      }), null, 16, _hoisted_3),
      (_ctx.hasIconSuffix)
        ? (_openBlock(), _createElementBlock("i", {
            key: 2,
            class: _normalizeClass([[_ctx.iconSuffix, _ctx.isDisabled ? 'text-gray-300' : ''], "absolute right-0"])
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.message && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}