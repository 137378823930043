
  import { computed, defineComponent, Ref, ref, watch, reactive } from 'vue'
  import { useStore } from 'vuex'
  import PermissionTile from '@/components/Generic/Button/PermissionTile.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      PermissionTile,
      SelectInput,
      CcButton,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
    },
    emits: ['permission-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const projectPermissions = computed(
        () => store.getters['projects/projectPermissions']
      )

      const groupedPermissions = computed(() => {
        let permissions = {} as Record<string, any>

        for (let projectPermission of projectPermissions.value) {
          let category = projectPermission.parent || 'Uncategorised'

          if (!permissions[category]) {
            permissions[category] = []
          }

          permissions[category].push(projectPermission)
        }

        return permissions
      })

      const status = ref('all') as Ref<string>
      const activePermission = ref() as Ref<any | undefined>

      const toggleGranularPermissions = (permission: any) => {
        if (
          activePermission.value &&
          activePermission.value.id === permission.id
        ) {
          activePermission.value = undefined
        } else {
          activePermission.value = permission
        }
      }

      const getProjectPermissions = () => {
        return store
          .dispatch('projects/projectPermissions', {
            user_id: props.id,
            type: props.type,
            ...(status.value !== 'all' && { status: status.value }),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const projectTypePermissions = computed(
        () => store.getters['permissions/getProjectTypePermissions']
      )

      const getProjectTypePermissions = (params: any) => {
        return store
          .dispatch('permissions/getProjectTypePermissions', params)
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getGranularPermissionValue = (id: number) => {
        let perm = activePermission.value.granular_permissions.find(
          (granularPermission: any) => {
            return granularPermission.project_type_permission_id === id
          }
        )

        return perm ? Number(perm.value) : 0
      }

      watch(status, () => {
        store.dispatch('genericStore/showPageLoader', true)

        getProjectPermissions().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      watch(
        () => props,
        () => {
          store.dispatch('genericStore/showPageLoader', true)

          Promise.all([
            getProjectPermissions(),
            getProjectTypePermissions({
              type: props.type,
            }),
          ]).finally(() => store.dispatch('genericStore/showPageLoader', false))
        },
        {
          deep: true,
          immediate: true,
        }
      )

      const payload = reactive({
        type: 'project_permissions',
        user_id: props.id as number,
        permissions: {} as Record<number, number>,
        granular_permissions: {} as Record<number, Record<number, number>>,
      })

      const updatePermission = (value: number, item: { id: number }) => {
        payload.permissions[item.id] = value
        emit('permission-updated', payload)
      }

      const updateGranularPermission = (
        value: number,
        projectId: number,
        projectTypePermId: number
      ) => {
        if (!payload.granular_permissions[projectId]) {
          payload.granular_permissions[projectId] = {}
        }

        payload.granular_permissions[projectId][projectTypePermId] = value

        emit('permission-updated', payload)
      }

      return {
        activePermission,
        groupedPermissions,
        projectPermissions,
        status,
        toggleGranularPermissions,
        projectTypePermissions,
        getGranularPermissionValue,
        updatePermission,
        updateGranularPermission,
      }
    },
  })
