import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Form Groups",
    "create-view": "FormGroupCreate",
    "edit-view": "FormGroupEdit",
    "delete-view": "FormGroupDelete",
    "restore-view": "FormGroupRestore",
    action: "formGroups/index",
    "toggle-visibility-action": "formGroups/toggleManyVisibility",
    "delete-many-action": "formGroups/deleteMany",
    "reorder-action": "formGroups/saveOrderings"
  }))
}