
  import { Field } from '@/types/modules/forms/Field'
  import { defineComponent, PropType, reactive } from 'vue'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<Field>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      return {
        props,
      }
    },
  })
