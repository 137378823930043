
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { computed, defineComponent, onMounted, reactive, watch } from 'vue'
  import { goBack } from '@/composables/Generic'
  import { useStore } from 'vuex'
  import { Column } from 'devextreme/ui/data_grid'
  import { Project } from '@/types/modules/projects/Project'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import { TaskFile } from '@/types/modules/tasks/TaskFile'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'

  export default defineComponent({
    components: {
      CcButton,
      SelectInput,
      RadioButton,
      DataGrid,
      TextInput,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const selectedProject = computed((): Project => {
        return store.getters['projects/projects'].find(
          (project: Project) => project.id === Number(props.id)
        )
      })
      const projects = computed((): Project[] => {
        return store.getters['projects/projects'].filter(
          (project: Project) =>
            project.project_type_id === state.currentProjectType.id
        )
      })

      const projectTypes = computed(() =>
        store.getters['projects/types'].map((type: ProjectType) => {
          return { id: type.id, text: type.name }
        })
      )

      const moveOptionSelected = (type: ProjectType) => {
        state.currentProjectType = type
      }

      const state = reactive({
        currentProjectType: {} as ProjectType,
      })

      const payload = reactive({
        source: undefined as Number | undefined,
        destination: undefined as Number | undefined,
        file_ids: [],
      })

      watch(
        () => selectedProject.value,
        (project: Project) => (payload.source = project.id)
      )

      const selectedAttachments = computed(
        () => store.getters['tasks/selectedAttachments']
      )

      const submit = () => {
        store
          .dispatch('tasks/moveAttachments', {
            source: Number(payload.source),
            destination: Number(payload.destination),
            file_ids: payload.file_ids,
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => goBack())
      }

      onMounted(() => {
        if (!store.getters['tasks/selectedAttachments'].length) {
          goBack()
          return
        }

        payload.source = Number(props.id)
        payload.file_ids = selectedAttachments.value.map(
          (attachment: TaskFile) => attachment.id
        )
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'File Name',
          dataField: 'name',
        },
        {
          caption: 'Description',
          dataField: 'description',
        },
        {
          caption: 'Type',
          dataField: 'mime_type',
        },
        {
          caption: 'Category',
          dataField: 'category.name',
          visible: false,
        },
        {
          dataField: 'created_at',
          caption: 'Date',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
      ])

      return {
        state,
        projects,
        columns,
        payload,
        goBack,
        projectTypes,
        selectedProject,
        moveOptionSelected,
        selectedAttachments,
        submit,
      }
    },
  })
