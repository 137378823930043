
  import CcMenu from '@/components/Layout/Menu/Menu.vue'
  import CcFooter from '@/components/Layout/Footer.vue'
  import CcHeader from '@/components/Layout/Header.vue'
  import { useStore } from 'vuex'
  import { computed, defineComponent } from 'vue'
  import ToastNotification from '@/components/Generic/Notifications/ToastNotification.vue'
  import AutoLogout from '../AutoLogout.vue'
  export default defineComponent({
    components: {
      CcMenu,
      CcFooter,
      CcHeader,
      ToastNotification,
      AutoLogout,
    },
    setup() {
      const store = useStore()
      const authUser = computed(() => store.getters['auth/authenticatedUser'])
      const notification = computed(
        () => store.getters['genericStore/notification']
      )
      const hasNotification = computed(() => notification)
      const getters = computed(() => {
        return {
          links: store.getters['genericStore/menuLinks'],
          tabs: store.getters['genericStore/menuTabs'],
        }
      })

      return {
        hasNotification,
        notification,
        getters,
        authUser,
      }
    },
  })
