import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FormGroupService from '@/services/forms/formGroups'
import { FormGroup } from '@/types/modules/forms/FormGroup'

export type FormGroupGetter = GetterTree<FormGroupState, any>

export interface FormGroupState {
  formGroups: FormGroup[]
  formGroup: FormGroup | null
}

export const state: FormGroupState = {
  formGroups: [],
  formGroup: null,
}

export const getters: FormGroupGetter = {
  formGroups: (state) => state.formGroups,
  getFormGroup: (state) => state.formGroup,
}

export const actions: ActionTree<FormGroupState, any> = {
  index: async (context, params) => {
    const response = await FormGroupService.index(params)

    if (response.success && params.per_page) {
      context.commit('setFormGroups', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setFormGroups', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    let results = Promise.resolve(FormGroupService.show(params))

    results.then((response) => {
      context.commit('setFormGroup', response.data)
    })

    return results
  },
  store: (context, params) => {
    let results = Promise.resolve(FormGroupService.store(params))

    results.then((response) => {
      context.commit('pushFormGroup', response.data)
    })

    return results
  },
  update: (_context, payload) => {
    return Promise.resolve(FormGroupService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(FormGroupService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(FormGroupService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(FormGroupService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(FormGroupService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(FormGroupService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<FormGroupState> = {
  setFormGroups: (state, groups: FormGroup[]) => {
    state.formGroups = groups
  },
  setFormGroup: (state, group: FormGroup) => {
    state.formGroup = group
  },
  pushFormGroup: (state, group: FormGroup) => {
    state.formGroups.push(group)
  },
}

export const formGroups = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
