
  import { defineComponent, PropType, reactive } from 'vue'
  import WizardStep from '@/components/Generic/Wizard/WizardStep.vue'
  import { WizardStep as WizardStepType } from '@/types/components/WizardStep'

  export default defineComponent({
    components: {
      WizardStep,
    },
    emits: ['step-changed', 'submit-clicked'],
    props: {
      steps: {
        type: Array as PropType<WizardStepType[]>,
        required: true,
      },
      activeStep: {
        type: Number,
        required: true,
        default: () => 1,
      },
      saveLabel: {
        type: String,
        required: false,
        default: 'Publish',
      },
    },
    setup(props, { emit }) {
      props = reactive(props)

      const stepChanged = (step: number) => emit('step-changed', step)
      const submitClicked = () => emit('submit-clicked')

      return {
        props,
        stepChanged,
        submitClicked,
      }
    },
  })
