
  import { computed, defineComponent, onMounted, reactive, Ref, ref } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { goBack } from '@/composables/Generic'
  import { useStore } from 'vuex'
  import { MedicationStockType } from '@/types/modules/mars/MedicationStockType'
  import { MedicationStockCategory } from '@/types/modules/mars/MedicationStockCategory'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'
  import { useI18n } from 'vue-i18n'
  import { validatePayload } from '@/composables/Validation'
  import { object, string, number } from 'yup'

  export default defineComponent({
    components: {
      TextInput,
      ButtonGroup,
      CcButton,
      SelectInput,
      CcSwitch,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
      medicationId: {
        type: String,
        required: true,
      },
      locationId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()

      interface MedicationStockPayload {
        name: string
        medication_location_id: number | null
        medication_stock_type_id: number | null
        medication_stock_category_id: number | null
        requires_auth: number
        _method?: 'PUT'
      }

      const payload = ref({
        name: '',
        medication_location_id: null,
        medication_stock_type_id: null,
        medication_stock_category_id: null,
        requires_auth: 0,
      }) as Ref<MedicationStockPayload>

      const errors = ref(null) as Ref<Record<string, string | undefined> | null>

      let validationObject = object({
        name: string().required('Name is a required value'),
        medication_location_id: number().required(
          'Medical Cabinet is required'
        ),
        medication_stock_type_id: number().required(
          'Please choose a stock type'
        ),
        medication_stock_category_id: number().required(
          'Please choose a stock category'
        ),
        requires_auth: number().oneOf([0, 1]),
      })

      const saveChanges = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null

        let data = payload.value as Record<string, any>

        errors.value = await validatePayload(validationObject, data)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        if (props.medicationId) {
          payload.value._method = 'PUT'
          store
            .dispatch('mars/editMedication', {
              id: props.medicationId,
              ...payload.value,
            })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              goBack()
            })
            .catch((response) =>
              store.dispatch('genericStore/pushNotification', response.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
          return
        }

        store
          .dispatch('mars/createMedication', payload.value)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
        return
      }

      const stockTypes = computed(() => {
        return store.getters['medicationStockTypes/getStockTypes']?.map(
          (type: MedicationStockType) => {
            return { label: type.name, value: type.id }
          }
        )
      })

      const stockCategories = computed(() => {
        return store.getters[
          'medicationStockCategories/getStockCategories'
        ]?.map((type: MedicationStockCategory) => {
          return { label: type.name, value: type.id }
        })
      })

      const getStockCategories = () => {
        return store
          .dispatch('medicationStockCategories/index', {})
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getStockTypes = () => {
        return store
          .dispatch('medicationStockTypes/index', {})
          .catch((e: any) =>
            store.dispatch('genericStore/pushNotification', e.message)
          )
      }

      const loadDependencies = () => {
        return Promise.all([getStockTypes(), getStockCategories()])
      }

      const mapToPayload = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('mars/getMedicationStock', { id: props.medicationId })
          .then((response) => {
            payload.value.name = response.data.name
            payload.value.medication_location_id =
              response.data.medication_location_id
            payload.value.medication_stock_category_id =
              response.data.medication_stock_category_id
            payload.value.medication_stock_type_id =
              response.data.medication_stock_type_id
            payload.value.requires_auth = response.data.requires_auth
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })

        loadDependencies().finally(() => {
          store.dispatch('genericStore/showPageLoader', false)
          if (props.medicationId) {
            mapToPayload()
          }

          payload.value.medication_location_id = Number(props.locationId)
        })
      })

      return {
        payload,
        goBack,
        saveChanges,
        stockTypes,
        stockCategories,
        t,
        props,
        errors,
      }
    },
  })
