import auth from '@/middleware/auth/auth'
import { canView } from '@/middleware/permissions/module'
import GroupingIndex from '@/views/modules/groupings/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/groupings',
    name: 'GroupingIndex',
    component: GroupingIndex,
    meta: {
      title: 'Groupings',
      module: 'groupings',
      middleware: [auth, canView],
    },
  },
] as RouteRecordRaw[]
