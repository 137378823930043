
  import { FieldAttribute } from '@/types/components/FieldAttribute'
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    Ref,
    watch,
    computed,
  } from 'vue'
  import SelectInput from '../SelectInput.vue'
  import TriggerConfig from '@/views/modules/tasks/partials/TriggerConfig.vue'
  import { FormFieldOption } from '@/types/modules/forms/FormFieldOption'
  import { DraftTrigger } from '@/types/modules/tasks/DraftTrigger'

  export default defineComponent({
    components: {
      SelectInput,
      TriggerConfig,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const value = ref() as Ref<string>
      const trigger = ref() as Ref<any>
      const error = ref() as Ref<string | undefined>

      const formattedOptions = computed(() => {
        if (props.fieldAttributes.options) {
          return props.fieldAttributes.options.map((option: any) => {
            return {
              label: option.name,
              value: option.name,
            }
          })
        }

        return []
      })

      const selectedOption = computed((): FormFieldOption | undefined => {
        return props.fieldAttributes.options?.find(
          (option: any) => option.name === value.value
        )
      })

      const optionAlreadyTriggered = computed(() => {
        let triggeredTaskIds = props.fieldAttributes.existing_triggered_forms

        if (triggeredTaskIds) {
          return triggeredTaskIds.includes(
            selectedOption.value?.triggered_task_id
          )
        }

        return false
      })

      const showTriggerConfig = computed(() => {
        if (
          selectedOption.value &&
          selectedOption.value.triggered_task_id &&
          !optionAlreadyTriggered.value
        ) {
          return true
        }

        return false
      })

      const updateTrigger = (config: any) => {
        trigger.value = {
          option_id: selectedOption.value ? selectedOption.value.id : null,
          form_field_id: props.fieldAttributes.id,
          ...config,
        }
      }

      const validateInput = () => {
        if (props.fieldAttributes.required == true && !value.value) {
          error.value = 'This field is required'
          return false
        }

        error.value = undefined
        return true
      }

      const updateValue = () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: value.value,
            row_index: props.fieldAttributes.row_index,
            triggers:
              trigger.value && value.value === 'Yes'
                ? [trigger.value]
                : undefined,
          })
        }
      }

      const triggeredForm = computed(() => {
        if (relatedDraftTrigger.value) {
          return relatedDraftTrigger.value.form
        }

        props.fieldAttributes.triggered_task || undefined
      })

      const triggeredProject = computed(() => {
        if (relatedDraftTrigger.value) {
          return relatedDraftTrigger.value.project || undefined
        }

        return props.fieldAttributes.project || undefined
      })

      const relatedDraftTrigger = computed(() => {
        if (
          !props.fieldAttributes.draft_triggers ||
          !props.fieldAttributes.draft_triggers.length
        ) {
          return undefined
        }

        return props.fieldAttributes.draft_triggers.find(
          (draftTrigger: DraftTrigger) => {
            return draftTrigger.form_field_id === props.fieldAttributes.id
          }
        )
      })

      const checkDraftTriggers = () => {
        if (relatedDraftTrigger.value) {
          trigger.value = {
            form_field_id: props.fieldAttributes.id,
            form_id: relatedDraftTrigger.value.triggered_task_id,
            trigger_date: relatedDraftTrigger.value.triggered_date,
            project_id: relatedDraftTrigger.value.project_id,
          }
        }
      }

      watch([value, trigger], () => updateValue())

      onMounted(() => {
        value.value = props.fieldAttributes.value
        checkDraftTriggers()
      })

      return {
        props,
        value,
        error,
        formattedOptions,
        showTriggerConfig,
        selectedOption,
        updateTrigger,
        triggeredProject,
        triggeredForm,
      }
    },
  })
