import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "flex items-center justify-end font-display" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "border-r px-3 mr-2 flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_grid_toolbar = _resolveComponent("grid-toolbar")!
  const _component_dx_sorting = _resolveComponent("dx-sorting")!
  const _component_dx_column_chooser = _resolveComponent("dx-column-chooser")!
  const _component_dx_filter_row = _resolveComponent("dx-filter-row")!
  const _component_dx_selection = _resolveComponent("dx-selection")!
  const _component_dx_group_panel = _resolveComponent("dx-group-panel")!
  const _component_dx_grouping = _resolveComponent("dx-grouping")!
  const _component_dx_paging = _resolveComponent("dx-paging")!
  const _component_dx_pager = _resolveComponent("dx-pager")!
  const _component_dx_data_grid = _resolveComponent("dx-data-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.props.chips?.length)
        ? (_openBlock(), _createBlock(_component_chips, {
            key: 0,
            onChipClicked: _ctx.chipClicked,
            "show-expanded": _ctx.state.expanded,
            "default-chip": _ctx.props.defaultChip,
            chips: _ctx.props.chips
          }, null, 8, ["onChipClicked", "show-expanded", "default-chip", "chips"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "toolbar-addon")
        ]),
        (_ctx.props.showGridToolbar)
          ? (_openBlock(), _createBlock(_component_grid_toolbar, {
              key: 0,
              "read-only": _ctx.props.readOnly,
              "show-toggle": !!_ctx.props.toggleLabel,
              "toggle-label": _ctx.props.toggleLabel,
              "show-expand-button": _ctx.shouldShowExpandButton,
              "extra-options": _ctx.props.extraOptions,
              "export-options": _ctx.props.exportOptions,
              onOptionClicked: _ctx.optionClicked,
              onToggleClicked: _ctx.toggleClicked,
              onExpandClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.expanded = !_ctx.state.expanded)),
              onExportClicked: _ctx.exportClicked
            }, {
              "toolbar-primary-button": _withCtx(() => [
                _renderSlot(_ctx.$slots, "toolbar-primary-button", {}, () => [
                  (!_ctx.props.readOnly)
                    ? (_openBlock(), _createBlock(_component_cc_button, {
                        key: 0,
                        type: "primary",
                        label: _ctx.t('components.buttons.add'),
                        icon: "fa fa-plus",
                        onClick: _ctx.primaryButtonClicked
                      }, null, 8, ["label", "onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            }, 8, ["read-only", "show-toggle", "toggle-label", "show-expand-button", "extra-options", "export-options", "onOptionClicked", "onToggleClicked", "onExportClicked"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
      _ctx.props.allowSelection ? '-ml-8' : '-ml-2',
      _ctx.props.showGridToolbar ? 'mt-10' : '',
    ])
    }, [
      _createVNode(_component_dx_data_grid, {
        "load-panel": { enabled: false },
        "data-source": _ctx.dataSource,
        ref: "dataGrid",
        class: "font-body",
        columns: _ctx.columns,
        "remote-operations": _ctx.props.remoteOperations,
        onSelectionChanged: _ctx.selectionChanged,
        onRowClick: _ctx.handleRowClick,
        "hover-state-enabled": _ctx.inMoveMode
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_dx_sorting, { mode: "multiple" }),
          _createVNode(_component_dx_column_chooser, {
            height: 400,
            "allow-search": true,
            enabled: _ctx.props.allowColumnChooser,
            mode: "select",
            title: "columns"
          }, null, 8, ["enabled"]),
          _createVNode(_component_dx_filter_row, {
            visible: _ctx.props.allowFiltering,
            "reset-operation-text": "Clear Filter"
          }, null, 8, ["visible"]),
          _createVNode(_component_dx_selection, {
            "select-all-mode": "page",
            "show-check-boxes-mode": _ctx.props.allowSelection ? 'always' : 'none',
            mode: "multiple"
          }, null, 8, ["show-check-boxes-mode"]),
          _createVNode(_component_dx_group_panel, {
            visible: _ctx.props.allowGrouping
          }, null, 8, ["visible"]),
          _createVNode(_component_dx_grouping, { "auto-expand-all": true }),
          _createVNode(_component_dx_paging, {
            "page-size": 
          _ctx.queryFilter && _ctx.queryFilter.per_page
            ? _ctx.queryFilter.per_page
            : _ctx.dataSource.pageSize()
        
          }, null, 8, ["page-size"]),
          _createVNode(_component_dx_pager, {
            "show-page-size-selector": _ctx.showPageSizeSelector,
            "allowed-page-sizes": [5, 10, 20, 50, 100],
            "display-mode": "compact"
          }, null, 8, ["show-page-size-selector"])
        ]),
        _: 2
      }, [
        _renderList(_ctx.templates, (template) => {
          return {
            name: template,
            fn: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(template), _mergeProps({
                data: data.data
              }, _toHandlers(_ctx.registerCustomEventListeners(template))), null, 16, ["data"]))
            ])
          }
        })
      ]), 1032, ["data-source", "columns", "remote-operations", "onSelectionChanged", "onRowClick", "hover-state-enabled"])
    ], 2)
  ], 64))
}