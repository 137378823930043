import { reactive, computed, nextTick } from 'vue'
import { ShiftInstance } from '@/types/modules/rotas/ShiftInstance'
import store from '@/store'
import { rotaCycles } from './RotaCycles'
import { rotaSetting, setting, loadRotaSetting } from './RotaSetting'
import { Project } from '@/types/modules/projects/Project'
import {
  loadLiveShifts,
  loadLiveShiftsForOtherProjects,
} from './ShiftInstances'
import { loadDefaultShiftsForOtherProjects } from './DefaultShifts'
import {
  startOfDay,
  addDays,
  eachDayOfInterval,
  format,
  getDay,
  startOfISOWeek,
  endOfISOWeek,
  isEqual,
  subWeeks,
  set,
  differenceInDays,
  setDay,
} from 'date-fns'
import { isBetween, isSameOrAfter } from '../Dates'
import { canEditModule } from '../Permissions'

interface GroupingDate {
  date: any
  string_date: string
  day_number: number
  long: string
  short: string
  week_day_long: string
  week_day_short: string
}

export const jobTitles = computed(
  () => store.getters['employeeJobTitles/getJobTitles']
)

export const sharedState = reactive({
  overlapErrors: [] as string[],
  groupings: {
    dates: [] as GroupingDate[],
  },
  activeTab: 'Live Rotas',
  tabs: ['Live Rotas', 'Default Shift Patterns'],
  allow_rotas_past_edits: false,
  step: null as number | null,
  showCopyToModal: false,
  shifts: [] as any[],
  liveRotas: [] as any[],
  defaultRotas: [] as any[],
  filtered_shift_types: [] as any[],
  skeletonShift: {} as Record<string, any>,
  timeline: {
    duration: 120,
    current_date: new Date() as any,
    start_date: null as any,
    end_date: null as any,
  },
  instances_actions: ['ignore', 'delete'],
  schedulers: [] as any[],
  daysToCopyTo: [] as any[],
  filtered_employees: [] as any[],
})

export const loadDatesBetweenDates = (startDate: Date, endDate: Date) => {
  let start_date = startDate
  let end_date = endDate
  const dates = [] as any[]

  let intervalDates = eachDayOfInterval({
    start: start_date,
    end: end_date,
  })
  intervalDates.forEach((date: Date) => {
    dates.push({
      date: date,
      string_date: format(date, 'yyyy/MM/dd'),
      day_number: getDay(date),
      long: format(date, 'EEEE d'),
      short: format(date, 'EEE d'),
      week_day_long: format(date, 'EEEE'),
      week_day_short: format(date, 'EEE'),
    })
  })

  sharedState.groupings.dates = dates
}

export const checkOverlap = (shift: ShiftInstance, shifts: ShiftInstance[]) => {
  if (!shift.employee_project_id) return false

  return shifts.filter((existing: any) => {
    return (
      existing.id !== shift.id &&
      existing.condition !== 'deleted' &&
      existing.employee_project_id === shift.employee_project_id &&
      ((isEqual(new Date(existing.shift_start), new Date(shift.shift_start)) &&
        isEqual(new Date(existing.shift_end), new Date(shift.shift_end))) ||
        isBetween(
          new Date(existing.shift_start),
          new Date(shift.shift_start),
          new Date(shift.shift_end)
        ) ||
        isBetween(
          new Date(existing.shift_end),
          new Date(shift.shift_start),
          new Date(shift.shift_end)
        ) ||
        isBetween(
          new Date(shift.shift_start),
          new Date(existing.shift_start),
          new Date(existing.shift_end)
        ) ||
        isBetween(
          new Date(shift.shift_end),
          new Date(existing.shift_start),
          new Date(existing.shift_end)
        ))
    )
  })
}

export const overlaps = (shift: ShiftInstance, shifts: ShiftInstance[]) => {
  let overlaps = checkOverlap(shift, shifts)

  if (!overlaps || !overlaps.length) {
    return false
  }

  if (overlaps) {
    let shift_start = format(new Date(overlaps[0].shift_start), 'Do MMM, h:mma')
    let shift_end = format(new Date(overlaps[0].shift_end), 'Do MMM, h:mma')

    sharedState.overlapErrors.push(
      `${overlaps[0].employee?.project?.name} is already scheduled to work between ${shift_start} and ${shift_end} for ${overlaps[0].project?.name}`
    )
  }

  return true
}

export const setShiftText = (employee: any, shift: any) => {
  if (employee) {
    return employee.name
  }

  if (!employee && shift.valid_job_titles.length) {
    return [
      ...new Set(shift.valid_job_titles.map((title: any) => title.job_title)),
    ].join(', ')
  }

  return 'Unassigned'
}

export const getDefaultShiftColour = (shift: any = null) => {
  if (shift && shift.project && shift.project.colour) {
    return `#${shift.project.colour.replace('#', '')}`
  }

  return '#C1C1C1'
}

export const rotaEmployees = computed(() => store.getters['projects/projects'])

export const loadRotaEmployees = (projectId: number) => {
  store.dispatch('projects/index', {
    type: ['employee'],
    relations: ['employee'],
    filterable: true,
    with_project_type: true,
    with_qualification_tags: true,
    in_parent: projectId,
    // status: 'current,planned', TODO: Scope doesn't accept multiple statuses
  })
}

export const computedStartWeeks = computed(() => {
  return filteredStartWeeks.value.map((week: any) => {
    return { label: week, value: week }
  })
})

export const filteredStartWeeks = computed(() => {
  let options = []

  let cycle = rotaCycles.value.find(
    (cycle: any) => cycle.id === Number(rotaSetting.value.default_cycle_id)
  )

  if (cycle) {
    for (let i = 0; i < cycle.weeks; i++) {
      options.push(i + 1)
    }
  }

  return options
})

export const totalSteps = computed(() => {
  return Math.max(...filteredStartWeeks.value)
})

export const defaultPatternChanged = computed(() => {
  return (
    setting.value &&
    (setting.value.default_cycle_id !== rotaSetting.value.default_cycle_id ||
      setting.value.cycle_start_week !== rotaSetting.value.cycle_start_week)
  )
})

export const canEditTimeline = computed(() => {
  return {
    allowAdding: canEditModule('rotas'),
    allowDeleting: canEditModule('rotas'),
    allowUpdating: canEditModule('rotas'),
    allowResizing: canEditModule('rotas'),
    allowDragging: false,
  }
})

export const reloadSchedulers = () => {
  sharedState.schedulers.forEach((scheduler) => {
    scheduler.instance.repaint()
  })
}

const shouldDisableDate = (date: any) => {
  if (
    isSameOrAfter(new Date(date), startOfDay(new Date())) ||
    !sharedState.allow_rotas_past_edits
  ) {
    return false
  }

  if (!sharedState.allow_rotas_past_edits) {
    return true
  }

  let last_editable_date = subWeeks(startOfDay(new Date()), 1)

  return !(
    sharedState.allow_rotas_past_edits &&
    isSameOrAfter(new Date(date), last_editable_date)
  )
}

export const deleteShift = (data: any) => {
  let shift = data.appointmentData
  let search =
    sharedState.activeTab === 'Live Rotas'
      ? sharedState.liveRotas
      : sharedState.defaultRotas
  shift.condition = 'deleted'

  let index = search.findIndex((s) => s.id === shift.id)

  if (index !== -1) {
    if (sharedState.activeTab === 'Live Rotas') {
      sharedState.liveRotas.splice(index, 1)
      if (Number.isInteger(shift.id)) {
        sharedState.liveRotas.push(shift)
      }
    } else {
      sharedState.defaultRotas.splice(index, 1)
      if (Number.isInteger(shift.id)) {
        sharedState.defaultRotas.push(shift)
      }
    }
  } else {
    if (Number.isInteger(shift.id)) {
      if (sharedState.activeTab === 'Live Rotas') {
        sharedState.liveRotas.push(shift)
      } else {
        sharedState.defaultRotas.push(shift)
      }
    }
  }

  let shiftIndex = sharedState.shifts.findIndex((s) => s.id === shift.id)

  if (shiftIndex !== -1) {
    sharedState.shifts.splice(shiftIndex, 1)

    if (Number.isInteger(shift.id)) {
      sharedState.shifts.push(shift)
    }
  } else {
    if (Number.isInteger(shift.id)) {
      sharedState.shifts.push(shift)
    }
  }
}

export const addShift = (data: any, project: Project) => {
  let shift = data.appointmentData

  shift.condition = 'added'
  shift.employee = Object.assign(
    {},
    rotaEmployees.value?.find((e: any) => e.id === shift.employee_project_id)
  )
  shift.project = project
  shift.text = setShiftText(shift.employee, shift)
  shift.shift_cycle_number = sharedState.step
  shift.shift_start = shift.shift_start
  shift.shift_end = shift.shift_end
  shift.is_dirty = true
  shift.color = '#C1C1C1'
  shift.image = project.image?.thumbnail
  shift.shift_type_id = shift.shift_type_id
  shift.shift_type_name = shift.shift_type_name
  shift.shift_type_icon = shift.shift_type_icon
  shift.hidden = false
  shift.valid_job_titles = shift.valid_job_titles

  let onLastWeekCycle = sharedState.step === totalSteps.value
  let startsOnSunday = format(new Date(shift.shift_start), 'EEEE') === 'Sunday'
  let endsOnMonday = format(new Date(shift.shift_end), 'EEEE') === 'Monday'

  if (
    sharedState.activeTab === 'Default Shift Patterns' &&
    onLastWeekCycle &&
    startsOnSunday &&
    endsOnMonday
  ) {
    store.dispatch(
      'genericStore/pushNotification',
      'A shift cannot be created for Monday as the currently selected week cycle is the last. ' +
        'Please create a separate shift to start on Monday in Week 1.'
    )
    shift.shift_start = shift.shift_start
    shift.shift_end = startOfDay(addDays(shift.shift_start, 1))
  }

  sharedState.groupings.dates.forEach((date: GroupingDate) => {
    let dataField = `CopyTo${date.week_day_long}`
    delete shift[dataField]
  })

  if (sharedState.activeTab === 'Live Rotas') {
    sharedState.liveRotas.push(shift)
  } else {
    sharedState.defaultRotas.push(shift)
  }

  sharedState.shifts.push(shift)

  // Check if other days selected to copy shift to
  if (sharedState.daysToCopyTo.length) {
    let shiftsToCopy = [] as any[]

    //@ts-ignore
    sharedState.daysToCopyTo.forEach((date: any) => {
      let shiftToCopy = Object.assign({}, data.appointmentData)

      shiftToCopy.id = '_' + Math.random().toString(36).substr(2, 9)
      let onLastWeekCycle = sharedState.step === totalSteps.value

      let dayDifference = differenceInDays(
        startOfDay(new Date(shiftToCopy.shift_end)),
        startOfDay(new Date(shiftToCopy.shift_start))
      )
      let selectedStartDayNumber = date.day_number // 0
      let selectedEndDayNumber = date.day_number + dayDifference // 1
      let daysToAdd = 0

      if (selectedStartDayNumber === 0) daysToAdd = 7

      shiftToCopy.shift_start = format(
        addDays(
          setDay(new Date(shiftToCopy.shift_start), selectedStartDayNumber),
          daysToAdd
        ),
        'yyyy-MM-dd HH:mm:ss'
      )
      shiftToCopy.shift_end = format(
        addDays(
          setDay(new Date(shiftToCopy.shift_end), selectedEndDayNumber),
          daysToAdd
        ),
        'yyyy-MM-dd HH:mm:ss'
      )

      // if we're on the last default cycle week and the shift start on a sunday and ends on a monday
      // then just set the end day to the end of last sunday as we can't cycle back through to week cycle 1
      if (
        sharedState.activeTab === 'Default Shift Patterns' &&
        onLastWeekCycle &&
        selectedStartDayNumber === 0 &&
        selectedEndDayNumber
      ) {
        shiftToCopy.shift_start = format(
          new Date(shiftToCopy.shift_start),
          'yyyy-MM-dd HH:mm:ss'
        )
        shiftToCopy.shift_end = startOfDay(
          addDays(new Date(shiftToCopy.shift_start), 1)
        )
        store.dispatch(
          'genericStore/pushNotification',
          'A shift cannot be created for Monday as the currently selected week cycle is the last. ' +
            'Please create a separate shift to start on Monday in Week 1.'
        )
      }

      if (shouldDisableDate(shiftToCopy.shift_start)) {
        return (data.cancel = true)
      }

      if (overlaps(shiftToCopy, sharedState.shifts)) {
        store.dispatch(
          'genericStore/pushNotification',
          sharedState.overlapErrors.concat(', ')
        )
        sharedState.overlapErrors = []
      } else {
        shiftsToCopy.push(shiftToCopy)
      }
    })

    if (shiftsToCopy.length) {
      shiftsToCopy.forEach((s: any) => {
        sharedState.shifts.push(s)

        if (sharedState.activeTab === 'Live Rotas') {
          sharedState.liveRotas.push(s)
        } else {
          sharedState.defaultRotas.push(s)
        }
      })
    }
  }
}

export const updateShift = (data: any, project: Project) => {
  let shift = data.newData
  let search =
    sharedState.activeTab === 'Live Rotas'
      ? sharedState.liveRotas
      : sharedState.defaultRotas
  shift.condition = Number.isInteger(shift.id) ? 'updated' : 'added'
  shift.employee = Object.assign(
    {},
    rotaEmployees.value?.find((e: any) => e.id === shift.employee_project_id)
  )
  shift.project = project
  shift.text = setShiftText(shift.employee, shift)
  shift.shift_cycle_number = sharedState.step
  shift.is_dirty = true
  shift.shift_type_id = shift.shift_type_id
  shift.shift_type_name = shift.shift_type_name
  shift.shift_type_icon = shift.shift_type_icon

  let onLastWeekCycle = sharedState.step === totalSteps.value
  let startsOnSunday = format(new Date(shift.shift_start), 'EEEE') === 'Sunday'
  let endsOnMonday = format(new Date(shift.shift_end), 'EEEE') === 'Monday'

  if (
    sharedState.activeTab === 'Default Shift Patterns' &&
    onLastWeekCycle &&
    startsOnSunday &&
    endsOnMonday
  ) {
    store.dispatch(
      'genericStore/pushNotification',
      'A shift cannot be created for Monday as the currently selected week cycle is the last. ' +
        'Please create a separate shift to start on Monday in Week 1.'
    )
    shift.shift_start = format(
      new Date(shift.shift_start),
      'yyyy-MM-dd HH:mm:ss'
    )
    shift.shift_end = startOfDay(addDays(new Date(shift.shift_start), 1))
  }

  sharedState.groupings.dates.forEach((date) => {
    let dataField = `CopyTo${date.week_day_long}`
    delete shift[dataField]
  })

  let index = search.findIndex((s) => s.id === shift.id)
  if (index !== -1) {
    if (sharedState.activeTab === 'Live Rotas') {
      sharedState.liveRotas.splice(index, 1)
      sharedState.liveRotas.push(shift)
    } else {
      sharedState.defaultRotas.splice(index, 1)
      sharedState.defaultRotas.push(shift)
    }
  } else {
    if (sharedState.activeTab === 'Live Rotas') {
      sharedState.liveRotas.push(shift)
    } else {
      sharedState.defaultRotas.push(shift)
    }
  }

  nextTick(() => reloadSchedulers())
}

export const resetRotaView = (projectId: number) => {
  sharedState.filtered_shift_types = []

  if (sharedState.activeTab === 'Live Rotas') {
    sharedState.shifts = []
    sharedState.liveRotas = []
    sharedState.defaultRotas = []
    sharedState.schedulers = []
    sharedState.step = null
    sharedState.timeline.start_date = startOfISOWeek(new Date())
    sharedState.timeline.end_date = endOfISOWeek(new Date())

    nextTick().then(() => {
      loadDatesBetweenDates(
        startOfISOWeek(new Date()),
        endOfISOWeek(new Date())
      )
      loadLiveShifts(
        format(startOfISOWeek(new Date()), 'yyyy-MM-dd'),
        format(endOfISOWeek(new Date()), 'yyyy-MM-dd'),
        projectId
      )
      loadLiveShiftsForOtherProjects(
        format(startOfISOWeek(new Date()), 'yyyy-MM-dd'),
        format(endOfISOWeek(new Date()), 'yyyy-MM-dd'),
        projectId
      )
    })
  }

  if (sharedState.activeTab === 'Default Shift Patterns') {
    sharedState.shifts = []
    sharedState.defaultRotas = []
    sharedState.liveRotas = []
    sharedState.schedulers = []
    sharedState.step = 1
    loadRotaSetting(projectId)
    loadDefaultShiftsForOtherProjects(projectId)
  }
}

export const getCopyableShifts = computed(() => {
  return sharedState.shifts.filter((shift: any) => {
    return shift.condition !== 'deleted'
  })
})

export const setupAddModal = () => {
  let start_date =
    sharedState.activeTab === 'Default Shift Patterns' &&
    sharedState.timeline.start_date
      ? set(new Date(sharedState.timeline.start_date), { hours: 9, minutes: 0 })
      : set(new Date(), { hours: 9, minutes: 0 })
  let end_date =
    sharedState.activeTab === 'Default Shift Patterns' &&
    sharedState.timeline.start_date
      ? set(new Date(sharedState.timeline.start_date), {
          hours: 17,
          minutes: 0,
        })
      : set(new Date(), { hours: 17, minutes: 0 })

  sharedState.skeletonShift = {
    allDay: false,
    employee: null,
    employee_project_id: null,
    shift_type_id: null,
    shift_end: end_date,
    id: '_' + Math.random().toString(36).substr(2, 9),
    text: null,
    valid_job_titles: [],
    shift_instance_id: null,
    shift_start: start_date,
  }
}

export const filterByShiftTypes = (value: any) => {
  let select_types = value.map((type: any) => type)

  if (!select_types.length) {
    sharedState.shifts.forEach((shift: any) => (shift.hidden = false))
    return
  }

  sharedState.shifts.filter((rota) => {
    if (select_types.length) {
      if (!select_types.includes(rota.shift_type_name)) {
        rota.hidden = true
      } else {
        rota.hidden = false
      }
    }
  })
}
