import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border-b border-gray-200" }
const _hoisted_2 = {
  key: 0,
  class: "font-display flex justify-between items-center py-4"
}
const _hoisted_3 = { class: "w-1/2" }
const _hoisted_4 = { class: "block flex items-center" }
const _hoisted_5 = {
  key: 0,
  class: "text-red-300 far fa-exclamation-circle mr-2"
}
const _hoisted_6 = { class: "text-sm block" }
const _hoisted_7 = {
  key: 1,
  class: "py-2"
}
const _hoisted_8 = { class: "text-red-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_time_input = _resolveComponent("time-input")!
  const _component_cc_switch = _resolveComponent("cc-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.payload && _ctx.payload[_ctx.props.data.system_name])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("b", _hoisted_4, [
              (
              _ctx.props.errors && _ctx.props.errors[`${_ctx.props.data.system_name}.value`]
            )
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.props.data.name), 1)
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.props.data.description), 1)
          ]),
          (_ctx.props.data.type === 'number')
            ? (_openBlock(), _createBlock(_component_text_input, {
                key: 0,
                class: "w-64",
                type: "number",
                modelValue: _ctx.payload[_ctx.props.data.system_name].value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload[_ctx.props.data.system_name].value) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.props.data.type === 'text')
            ? (_openBlock(), _createBlock(_component_text_input, {
                key: 1,
                class: "w-64",
                modelValue: _ctx.payload[_ctx.props.data.system_name].value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload[_ctx.props.data.system_name].value) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.props.data.type === 'list')
            ? (_openBlock(), _createBlock(_component_select_input, {
                key: 2,
                class: "w-64",
                options: _ctx.listOptions,
                modelValue: _ctx.payload[_ctx.props.data.system_name].value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload[_ctx.props.data.system_name].value) = $event))
              }, null, 8, ["options", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.props.data.type === 'time')
            ? (_openBlock(), _createBlock(_component_time_input, {
                key: 3,
                class: "w-64",
                period: 
          _ctx.props.data.saved_unit
            ? _ctx.props.data.saved_unit
            : _ctx.props.data.preferred_unit
        ,
                onUpdate: _ctx.updateTimeInputValue,
                value: _ctx.payload[_ctx.props.data.system_name].value
              }, null, 8, ["period", "onUpdate", "value"]))
            : _createCommentVNode("", true),
          (_ctx.props.data.type === 'boolean')
            ? (_openBlock(), _createBlock(_component_cc_switch, {
                key: 4,
                modelValue: _ctx.payload[_ctx.props.data.system_name].value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload[_ctx.props.data.system_name].value) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.props.errors && _ctx.props.errors[`${_ctx.props.data.system_name}.value`])
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.props.errors[`${_ctx.props.data.system_name}.value`].join('. ')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}