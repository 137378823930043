import { OptionalObjectSchema } from 'yup/lib/object'
import { ValidationError } from 'yup'
import { AnyObject } from 'yup/lib/types'

export const validatePayload = async (
  schema: OptionalObjectSchema<AnyObject>,
  payload: Record<string, unknown>,
  context: object = {}
) => {
  let errorBag = {} as Record<string, string>
  return schema
    .validate(payload, { abortEarly: false, ...context })
    .then(() => null)
    .catch((res) => {
      const response = res as ValidationError

      response.inner.forEach((error: ValidationError) => {
        errorBag[error.path!] = error.message
      })

      return errorBag
    })
}
