import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_monthly_appointment_template = _resolveComponent("monthly-appointment-template")!
  const _component_agenda_appointment_template = _resolveComponent("agenda-appointment-template")!
  const _component_overflow_indicator_template = _resolveComponent("overflow-indicator-template")!
  const _component_tooltip_template = _resolveComponent("tooltip-template")!
  const _component_dx_scheduler = _resolveComponent("dx-scheduler")!

  return (_openBlock(), _createBlock(_component_dx_scheduler, {
    ref: "calendar",
    class: "calendar",
    "data-source": _ctx.data,
    "current-date": new Date(),
    views: _ctx.views,
    "current-view": "month",
    "max-appointments-per-cell": "auto",
    "start-date-expr": "start_date",
    "end-date-expr": "end_date",
    height: 900,
    editing: false,
    onAppointmentFormOpening: _ctx.preventFormOpening,
    onAppointmentDblClick: _ctx.viewEvent,
    onOptionChanged: _ctx.optionChanged,
    onAppointmentClick: _ctx.viewEvent,
    onAppointmentRendered: _ctx.registerTooltip
  }, {
    monthlyAppointmentTemplate: _withCtx(({ data }) => [
      _createVNode(_component_monthly_appointment_template, {
        data: data.appointmentData
      }, null, 8, ["data"])
    ]),
    agendaAppointmentTemplate: _withCtx(({ data }) => [
      _createVNode(_component_agenda_appointment_template, { data: data }, null, 8, ["data"])
    ]),
    tooltipTemplate: _withCtx(({ data }) => [
      _createVNode(_component_overflow_indicator_template, {
        count: data.appointmentCount
      }, null, 8, ["count"])
    ]),
    appointmentTooltipTemplate: _withCtx(({ data }) => [
      _createVNode(_component_tooltip_template, {
        data: data.appointmentData
      }, null, 8, ["data"])
    ]),
    _: 1
  }, 8, ["data-source", "current-date", "views", "onAppointmentFormOpening", "onAppointmentDblClick", "onOptionChanged", "onAppointmentClick", "onAppointmentRendered"]))
}