
  import {
    computed,
    defineComponent,
    onBeforeMount,
    reactive,
    ref,
    watch,
  } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { DocumentCategory } from '@/types/modules/documents/DocumentCategory'
  import { DocumentClass } from '@/types/modules/documents/DocumentClass'
  import { useRoute, useRouter } from 'vue-router'
  import { Document } from '@/types/modules/documents/Document'
  import { RecycleBin } from '@/types/modules/generic/RecycleBin'

  export default defineComponent({
    components: {
      DataGrid,
      ConfirmDialog,
    },
    props: {
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const query = ref({})
      const grid = ref()
      const state = reactive({
        activeChip: 'all' as string,
        readOnly: true,
        selectedRows: [] as Document[],
        extraOptions: [] as string[],
        columns: [] as Column[],
      })

      const confirm = reactive({
        title: '',
        content: '',
        show: false,
      })

      const attachmentColumns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'File Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'category.name',
          caption: 'Category',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      const documentColumns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'Document Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          dataType: 'string',
          dataField: 'category.name',
          caption: 'Category',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'number',
          dataField: 'version',
          caption: 'Version',
          width: 100,
        },
        {
          dataType: 'string',
          caption: 'Acknowledgement Status',
          calculateCellValue: (row: Document) => {
            if (!row.requires_acknowledgement) {
              return '-'
            }

            if (!row.user_acknowledgement) {
              return 'Required'
            }

            return 'Acknowledged'
          },
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'updated_at',
          caption: 'Last Updated',
        },
      ])

      const action = computed(() => {
        return props.sensitive
          ? 'documents/getSensitiveDocuments'
          : 'documents/index'
      })

      watch(
        () => route.query.tab,
        () => {
          if (classes.value.length) {
            if (classNameIsActiveMenuItem()) {
              applyFilters()
            }
          }
        }
      )

      const classes = computed(
        (): DocumentClass[] => store.getters['documents/classes']
      )

      const categories = computed(() => {
        return store.getters['documentCategories/getCategories']?.map(
          (category: DocumentCategory) => category.name
        )
      })

      const classNameIsActiveMenuItem = () => {
        return classes.value
          .map((classObj: DocumentClass) => {
            return classObj.name.toLowerCase()
          })
          .includes(route.query.tab?.toString() || '')
      }

      const shouldLoadGrid = computed(() => {
        return !!(
          (!props.sensitive &&
            route.name !== 'AttachmentsIndex' &&
            classes.value.length) ||
          props.sensitive ||
          route.name === 'AttachmentsIndex'
        )
      })

      const chips = computed((): string[] => {
        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { category: state.activeChip }),
          ...(!props.sensitive &&
            route.query.tab &&
            route.name !== 'AttachmentsIndex' && { class: route.query.tab }),
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const rowsSelected = (rows: Document[]) => {
        state.selectedRows = rows
        if (route.name !== 'DocumentIndex' || state.readOnly) return

        if (rows.length && !state.extraOptions.includes('Acknowledge')) {
          state.extraOptions.push('Acknowledge')
        }

        if (!rows.length && state.extraOptions.includes('Acknowledge')) {
          state.extraOptions.splice(
            state.extraOptions.indexOf('Acknowledge'),
            1
          )
        }

        if (rows.length && !state.extraOptions.includes('Delete')) {
          state.extraOptions.push('Delete')
        }

        if (!rows.length && state.extraOptions.includes('Delete')) {
          state.extraOptions.splice(state.extraOptions.indexOf('Delete'), 1)
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'View Deleted Documents') {
          viewDeletedRecords()
        }

        if (option === 'Delete') {
          confirm.title = 'Confirm Deletion'
          confirm.content =
            'Are you sure you want to delete the selected records?'
          confirm.show = true
        }

        if (option === 'Acknowledge') {
          acknowledgeSelectedRecords()
        }
      }

      const recycleBinData = computed((): RecycleBin => {
        return {
          key: 'id',
          action: 'documents/index',
          restoreAction: 'documents/restoreMany',
          columns: state.columns,
        }
      })

      const confirmed = () => {
        if (confirm.title === 'Confirm Deletion') deleteSelectedRecords()
        resetConfirmDialog()
      }

      const resetConfirmDialog = () => {
        confirm.title = ''
        confirm.content = ''
        confirm.show = false
      }

      const viewDeletedRecords = () => {
        store.commit('genericStore/setRecycleBinData', recycleBinData)
        router.push({ name: 'RecycleBin' })
      }

      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('documents/deleteMany', {
            ids: state.selectedRows.map((row: Document) => row.id),
          })
          .then(() => {
            state.selectedRows = []
            confirm.show = false
            grid.value?.refresh()
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const acknowledgeSelectedRecords = () => {
        const documentsToAcknowledge = state.selectedRows.filter(
          (row: Document) =>
            !row.user_acknowledgement && row.requires_acknowledgement
        )

        if (!documentsToAcknowledge.length) {
          store.dispatch(
            'genericStore/pushNotification',
            'The selected documents have already been acknowledged or do not require acknowledgement'
          )
          return
        }

        store.commit(
          'documents/setDocumentsToAcknowledge',
          documentsToAcknowledge
        )
        router.push({ name: 'DocumentAcknowledgements' })
      }

      onBeforeMount(async () => {
        if (classNameIsActiveMenuItem()) applyFilters()
        if (route.name !== 'DocumentIndex') {
          state.readOnly = true
          state.columns = attachmentColumns
        }

        if (route.name === 'DocumentIndex') {
          state.readOnly = false
          state.extraOptions = ['View Deleted Documents']
          state.columns = documentColumns
        }
      })

      return {
        grid,
        chips,
        props,
        state,
        route,
        query,
        action,
        confirm,
        classes,
        confirmed,
        categories,
        chipClicked,
        rowsSelected,
        optionClicked,
        shouldLoadGrid,
        resetConfirmDialog,
        deleteSelectedRecords,
      }
    },
  })
