import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('tasks', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  toggleTaskSensitivity: (id: any, params: any) => {
    return axios
      .post(`tasks/sensitivity/${id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  createUnscheduledTask: async (params: any) => {
    return axios
      .post('tasks/unscheduled', params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getTaskAttachments: async (params: any) => {
    return axios
      .get('tasks/files', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  moveAttachments: async (payload: object) => {
    return axios
      .post('tasks/files/move', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveTaskAttachments: async (params: any) => {
    return axios
      .get('tasks/files/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getTaskAccessLogs: async (params: any) => {
    return axios
      .get('tasks/accesses', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getTaskExplorerChart: async (params: any) => {
    return axios
      .get('dashboard', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getLogsList: async (params: any) => {
    return axios
      .get('dashboard/logs', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getTaskCounts: (params: any) => {
    return axios
      .get('task/counts', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },

  show: (id: string, params: any) => {
    return axios
      .get(`tasks/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  linkTask: (payload: object) => {
    return axios
      .post('tasks/links/link', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  unlinkTask: (payload: object) => {
    return axios
      .post('tasks/links/unlink', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getBlacklistedUsers: (taskId: any) => {
    return axios
      .get(`tasks/${taskId}/blacklist/users`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateBlacklistedUsers: (payload: any) => {
    return axios
      .post('tasks/accesses/blacklists', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  copyTask: (payload: object) => {
    return axios
      .post('tasks/copy', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getWorkflowForTask: (taskId: any) => {
    return axios
      .get(`tasks/${taskId}/workflow`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
