
  import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    watch,
    computed,
    PropType,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import TimeInput from '@/components/Generic/Forms/Inputs/SettingTimeInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'

  export default defineComponent({
    components: {
      TextInput,
      TimeInput,
      SelectInput,
      CcSwitch,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      errors: {
        type: Object as PropType<Record<string, string[]>>,
        required: false,
      },
    },
    emits: ['setting-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({}) as Record<string, any>

      const correctTypeForValue = (value: any) => {
        if (props.data.type === 'boolean' || props.data.type === 'number') {
          return Number(value)
        }

        return value
      }

      const mapToPayload = () => {
        payload.value[props.data.system_name] = {
          value: correctTypeForValue(props.data.value),
        }
      }

      const updateTimeInputValue = (value: any) => {
        payload.value[props.data.system_name] = {
          name: props.data.system_name,
          value: value,
        }
      }

      const listOptions = computed(() => {
        if (props.data.options) {
          let options = JSON.parse(props.data.options)

          return options.options.map((option: string) => {
            return {
              label: option,
              value: option,
            }
          })
        }

        return []
      })

      watch(
        () => payload,
        () => {
          emit('setting-updated', {
            name: props.data.system_name,
            value: payload.value[props.data.system_name].value,
          })
        },
        { deep: true }
      )

      onMounted(() => {
        mapToPayload()
      })

      return {
        props,
        payload,
        updateTimeInputValue,
        listOptions,
      }
    },
  })
