import { ActionTree, GetterTree, MutationTree } from 'vuex'
import QualificationIssuingBodyService from '@/services/projects/employees/qualificationIssuingBodies'
import { QualificationIssuingBody } from '@/types/modules/projects/QualificationIssuingBody'

export type QualificationIssuingBodyGetter = GetterTree<
  QualificationIssuingBodyState,
  any
>

export interface QualificationIssuingBodyState {
  qualificationIssuingBodies: QualificationIssuingBody[]
  qualificationIssuingBody: QualificationIssuingBody | null
}

export const state: QualificationIssuingBodyState = {
  qualificationIssuingBodies: [],
  qualificationIssuingBody: null,
}

export const getters: QualificationIssuingBodyGetter = {
  getQualificationIssuingBodies: (state) => state.qualificationIssuingBodies,
  getQualificationIssuingBody: (state) => state.qualificationIssuingBody,
}

export const actions: ActionTree<QualificationIssuingBodyState, any> = {
  index: async (context, params) => {
    const response = await QualificationIssuingBodyService.index(params)

    if (response.success && params.per_page) {
      context.commit('setQualificationIssuingBodies', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setQualificationIssuingBodies', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      QualificationIssuingBodyService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setQualificationIssuingBody', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(QualificationIssuingBodyService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      QualificationIssuingBodyService.update(payload.id, payload)
    )
  },
  delete: (_context, id) => {
    return Promise.resolve(QualificationIssuingBodyService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(
      QualificationIssuingBodyService.saveOrderings(payload)
    )
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(QualificationIssuingBodyService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(QualificationIssuingBodyService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      QualificationIssuingBodyService.toggleManyVisibility(params)
    )
  },
}

export const mutations: MutationTree<QualificationIssuingBodyState> = {
  setQualificationIssuingBodies: (state, bodies: QualificationIssuingBody[]) =>
    (state.qualificationIssuingBodies = bodies),
  setQualificationIssuingBody: (state, body: QualificationIssuingBody) =>
    (state.qualificationIssuingBody = body),
}

export const qualificationIssuingBodies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
