
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import FormCard from '@/components/Generic/Card/FormCard.vue'
  import { Form } from '@/types/modules/forms/Form'
  import { goBack } from '@/composables/Generic'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DxList from 'devextreme-vue/list'
  import { useRouter } from 'vue-router'
  import { DxSearchEditorOptions } from 'devextreme-vue/list'

  export default defineComponent({
    components: {
      FormCard,
      ButtonGroup,
      CcButton,
      DxList,
      DxSearchEditorOptions,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      projectType: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter()
      props = reactive(props)
      const store = useStore()
      const state = reactive({
        activeForm: {} as Form,
      })

      const forms = computed(() => {
        return store.getters['forms/forms'].length
          ? store.getters['forms/forms']
          : []
      })

      const formClicked = (form: Form) => {
        state.activeForm = form
      }

      const successClicked = () => {
        if (state.activeForm) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('tasks/createUnscheduledTask', {
              form_id: state.activeForm.id,
              project_id: props.id,
            })
            .then((response: any) => {
              router.push({
                name: 'CreateLog',
                params: { taskId: response.data.id },
              })
            })
            .catch((error) => {
              store.dispatch('genericStore/pushNotification', error.message)
            })
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        } else {
          store.dispatch(
            'genericStore/pushNotification',
            'Please select a form before creating a task'
          )
        }
      }

      const formattedProjectType = computed(() => {
        if (props.projectType === 'young_people') {
          return 'resident'
        }

        return props.projectType.slice(0, props.projectType.length - 1)
      })

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('forms/index', {
            filters: [['types.name', '=', formattedProjectType.value]],
          })
          .then((response: any) => {
            if (response.data.length) {
              state.activeForm = response.data[0]
            }
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        props,
        state,
        formClicked,
        goBack,
        forms,
        successClicked,
      }
    },
  })
