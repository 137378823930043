import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FieldService from '@/services/forms/fields'
import { Field } from '@/types/modules/forms/Field'

export type FieldGetter = GetterTree<FieldState, any>

export interface FieldState {
  fields: Field[]
}

export const state: FieldState = {
  fields: [],
}

export const getters: FieldGetter = {
  getFields: (state) => state.fields,
}

export const actions: ActionTree<FieldState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(FieldService.index(params))

    results.then((response) => {
      if (response.success) {
        context.commit('setFields', response.data)
      }
    })

    return results
  },
}

export const mutations: MutationTree<FieldState> = {
  setFields: (state, fields: Field[]) => {
    state.fields = fields
  },
}

export const fields = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
