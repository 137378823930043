
  import { defineComponent, watch, reactive } from 'vue'
  export default defineComponent({
    props: {
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      props = reactive(props)
      const state = reactive({
        imageLoading: true,
        defaultImage: '/images/home_default.png',
      })

      watch(
        () => props.cellData.data.image,
        () => (state.imageLoading = true)
      )

      const setDefaultImage = (e: Event) => {
        const target = e.target as HTMLImageElement | null

        if (target) {
          target.src = state.defaultImage
        }
      }

      return {
        props,
        setDefaultImage,
        state,
      }
    },
  })
