import axios from 'axios'

export default {
  index: (params: any) => {
    return axios
      .get(`settings`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.resolve(error.response.data))
  },
  updateSettings: (payload: any) => {
    return axios
      .put(`settings`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (setting: string) => {
    return axios
      .get(`settings/${setting}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.resolve(error.response.data))
  },
  getPasswordGuidelines: async () => {
    return axios
      .get('settings/password/guidelines')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.resolve(error.response.data))
  },
  getTimeoutSetting: async () => {
    return axios
      .get('settings/auto_timeout')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
