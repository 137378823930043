import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-8 p-6 shadow-md border border-gray-100 font-display h-full" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-8" }
const _hoisted_3 = { class: "cols-1 space-y-6" }
const _hoisted_4 = {
  key: 0,
  class: "cols-1 space-y-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_box = _resolveComponent("text-box")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_text_input, {
          "is-required": true,
          label: "report name",
          modelValue: _ctx.payload.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.name) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_box, {
          "is-required": true,
          label: "report description",
          modelValue: _ctx.payload.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.description) = $event))
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.shouldShowReportTypeSelector)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_select_input, {
              "is-required": true,
              "is-disabled": !!_ctx.props.report,
              label: "report type",
              options: _ctx.reportTypes,
              modelValue: _ctx.payload.report_type_id,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.report_type_id) = $event)),
                _ctx.handleReportTypeChange
              ]
            }, null, 8, ["is-disabled", "options", "modelValue", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}