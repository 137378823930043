import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "bespokeReports/index",
    columns: $setup.columns,
    "extra-options": $setup.state.extraOptions,
    onRowsSelected: $setup.rowsSelected,
    onOptionClicked: $setup.optionClicked,
    onPrimaryButtonClicked: $setup.primaryButtonClicked
  }, null, 8, ["columns", "extra-options", "onRowsSelected", "onOptionClicked", "onPrimaryButtonClicked"]))
}