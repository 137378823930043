import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 hover:text-blue-200 hover:underline hover:cursor-pointer hover:bg-blue-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass([_ctx.icon, "mr-2"])
    }, null, 2),
    _renderSlot(_ctx.$slots, "default")
  ]))
}