
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    watch,
  } from 'vue'
  import { useI18n } from 'vue-i18n'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'
  import { SelectOption } from '@/types/components/SelectOption'

  export default defineComponent({
    props: {
      careGroup: {
        type: Object as PropType<CareGroup>,
        required: true,
      },
    },
    components: {
      TextBox,
      TextInput,
      SelectInput,
      RadioButton,
    },
    emits: ['payload-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const { t } = useI18n()

      const users = computed((): SelectOption[] =>
        store.getters['users/users'].map((user: User) => {
          return {
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }
        })
      )

      const payload = reactive({
        company_owner: undefined as number | undefined,
        company_phone1: '' as string | null,
        company_fax: '' as string | null,
        company_email: '' as string | null,
      })

      const setContactDetails = () => {
        payload.company_owner = props.careGroup.owner?.id
        payload.company_phone1 = props.careGroup.company_phone1
        payload.company_fax = props.careGroup.company_fax
        payload.company_email = props.careGroup.company_email
      }

      watch(
        () => payload,
        () => emit('payload-updated', payload),
        { deep: true }
      )

      onMounted(() => {
        setContactDetails()

        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('users/index', {
            status: 'active',
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        t,
        users,
        props,
        payload,
      }
    },
  })
