import Alert from 'sweetalert2'
import store from '@/store'
import {
  sharedState,
  defaultPatternChanged,
  setShiftText,
  getDefaultShiftColour,
} from './Rotas'
import { saveDefaultShifts } from './DefaultShifts'
import { computed } from 'vue'
import { format } from 'date-fns'

const countInstances = computed(
  () => store.getters['defaultShifts/getDefaultShiftCount']
)

export const getLiveShifts = (params: any) =>
  store.dispatch('shiftInstances/index', params)

export const liveShifts = computed(
  () => store.getters['shiftInstances/shiftInstances']
)

export const saveLiveRotas = (params = {}, projectId: number) => {
  Alert.fire({
    text: 'Are you sure you want to save the current changes to live shifts?',
    showConfirmButton: true,
    showCloseButton: true,
    icon: 'warning',
  }).then((result) => {
    if (result) {
      store
        .dispatch('shiftInstances/update', {
          project_id: projectId,
          shifts: sharedState.liveRotas,
          ...params,
        })
        .then(() => {
          sharedState.shifts = []
          sharedState.liveRotas = []
          loadLiveShifts(
            format(new Date(sharedState.timeline.start_date!), 'yyyy-MM-dd'),
            format(new Date(sharedState.timeline.end_date!), 'yyyy-MM-dd'),
            projectId
          )
          store.dispatch(
            'genericStore/pushNotification',
            'Live shifts updated successfully'
          )
        })
        .finally(() => {
          loadLiveShiftsForOtherProjects(
            format(new Date(sharedState.timeline.start_date!), 'yyyy-MM-dd'),
            format(new Date(sharedState.timeline.end_date!), 'yyyy-MM-dd'),
            projectId
          )
        })
    }
  })
}

export const checkExistingLiveShifts = (projectId: number) => {
  store
    .dispatch('defaultShifts/count', {
      project_id: projectId,
    })
    .then(() => {
      if (!countInstances.value) {
        return saveDefaultShifts({}, projectId)
      }

      if (countInstances.value) {
        if (defaultPatternChanged.value) {
          Alert.fire({
            icon: 'warning',
            title: 'Cycle Changed',
            text:
              'You have changed the default cycle plan. Any future live shifts will ' +
              'be deleted and regenerated based on the new default shift pattern. Continue?',
            confirmButtonText: 'Continue',
          }).then((result) => {
            if (result) {
              return saveDefaultShifts({}, projectId)
            }
          })
        } else {
          Alert.fire({
            icon: 'warning',
            title: 'Deleting Live Shifts',
            text:
              'You have live shifts linked to your default shift setup. ' +
              'Would you like to delete these shifts?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Keep Shifts',
            confirmButtonText: 'Delete Shifts',
            allowOutsideClick: true,
          }).then((result: any) => {
            result = result.value ? 'delete' : 'ignore'

            if (sharedState.instances_actions.includes(result)) {
              return saveDefaultShifts({ instances_action: result }, projectId)
            }
          })
        }
      }
    })
}

export const loadLiveShifts = (
  start_date: string,
  end_date: string,
  projectId: number
) => {
  getLiveShifts({
    start: start_date,
    end: end_date,
    include: [projectId],
  }).then((response) => {
    response.data.forEach((shift: any) => {
      sharedState.shifts.push({
        id: shift.id ? shift.id : null,
        shift_instance_id: shift.id ? shift.id : null,
        text: setShiftText(shift.employee, shift),
        default_shift_id: shift.default_shift_id
          ? shift.default_shift_id
          : null,
        employee: shift.employee ? shift.employee : null,
        employee_project_id: shift.employee ? shift.employee.project_id : null,
        shift_type_id: shift.shift_type ? shift.shift_type.id : null,
        shift_type_name: shift.shift_type ? shift.shift_type.name : null,
        shift_type_icon: shift.shift_type ? shift.shift_type.icon : null,
        valid_job_titles: shift.valid_job_titles ? shift.valid_job_titles : [],
        shift_start: shift.shift_start,
        shift_end: shift.shift_end,
        image:
          shift.employee && shift.employee.image ? shift.employee.image : null,
        snapshot: shift.snapshot ? shift.snapshot : null,
        color: getDefaultShiftColour(shift),
        project: shift.project ? shift.project : null,
        hidden: false,
      })
    })
  })
}

export const loadLiveShiftsForOtherProjects = (
  start_date: string,
  end_date: string,
  projectId: number
) => {
  return getLiveShifts({
    start_date: start_date,
    end_date: end_date,
    exclude: [projectId],
  })
}
