import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_text_input, {
      class: "mx-1",
      "model-value": _ctx.props.value,
      modelValue: _ctx.payload.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.value) = $event))
    }, null, 8, ["model-value", "modelValue"]),
    _createVNode(_component_select_input, {
      class: "mx-1",
      options: _ctx.options,
      "model-value": _ctx.props.period,
      modelValue: _ctx.payload.saved_unit,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.saved_unit) = $event))
    }, null, 8, ["options", "model-value", "modelValue"])
  ]))
}