import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    key: _ctx.key,
    "grid-heading": _ctx.gridHeading,
    columns: _ctx.columns,
    "reference-only": _ctx.referenceOnly,
    "remote-operations": _ctx.remoteOperations,
    "extra-options": _ctx.state.extraOptions,
    "export-options": _ctx.exportOptions,
    chips: _ctx.chips,
    "default-chip": _ctx.defaultChip,
    "query-filter": _ctx.queryFilter,
    action: _ctx.action,
    "read-only": _ctx.readOnly,
    "toggle-label": _ctx.toggleLabel,
    "show-grid-toolbar": _ctx.showGridToolbar,
    "visible-chip-count": _ctx.visibleChipCount,
    onInitMoveMode: _ctx.initMoveMode,
    onMoveItemTo: _ctx.reorderItems,
    "in-move-mode": _ctx.isMoveMode,
    onPrimaryButtonClicked: _ctx.addClicked,
    onOptionClicked: _ctx.optionClicked,
    onRowsSelected: _ctx.rowsSelected
  }, _createSlots({ _: 2 }, [
    (_ctx.isMoveMode)
      ? {
          name: "toolbar-addon",
          fn: _withCtx(() => [
            _createVNode(_component_cc_button, {
              label: "Cancel",
              type: "danger-outline",
              icon: "fa fa-close",
              onClick: _ctx.cancelMoveMode
            }, null, 8, ["onClick"])
          ])
        }
      : undefined
  ]), 1032, ["grid-heading", "columns", "reference-only", "remote-operations", "extra-options", "export-options", "chips", "default-chip", "query-filter", "action", "read-only", "toggle-label", "show-grid-toolbar", "visible-chip-count", "onInitMoveMode", "onMoveItemTo", "in-move-mode", "onPrimaryButtonClicked", "onOptionClicked", "onRowsSelected"]))
}