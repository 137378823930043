
  import { defineComponent, reactive, Ref, ref, watch } from 'vue'
  import { DxSwitch } from 'devextreme-vue/switch'

  export default defineComponent({
    components: {
      DxSwitch,
    },
    props: {
      label: {
        type: String,
        required: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      modelValue: {
        required: false,
        type: Number,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)
      const enabled = ref(0) as Ref<number | undefined>

      watch(
        () => props.modelValue,
        () => (enabled.value = props.modelValue),
        { immediate: true }
      )

      const handleInput = () => {
        enabled.value = Number(!enabled.value)
        emit('update:modelValue', Number(enabled.value))
      }

      return {
        enabled,
        handleInput,
        props,
      }
    },
  })
