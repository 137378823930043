
  import { defineComponent } from 'vue'
  import ProceduresTable from '../../procedures/partials/ProceduresTable.vue'

  export default defineComponent({
    components: { ProceduresTable },
    props: {
      regionId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  })
