import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('mars/medication/stocks/types', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any, params: any) => {
    return axios
      .get(`mars/medication/stocks/types/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('mars/medication/stocks/types', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: any, payload: any) => {
    return axios
      .post(`mars/medication/stocks/types/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  delete: (id: any) => {
    return axios
      .delete(`mars/medication/stocks/types/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  saveOrderings: (payload: any) => {
    return axios
      .post(`mars/medication/stocks/types/orderings`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  deleteMany: (payload: any) => {
    return axios
      .post('mars/medication/stocks/types/delete-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restoreMany: (payload: any) => {
    return axios
      .post('mars/medication/stocks/types/restore-many', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  toggleManyVisibility: (payload: any) => {
    return axios
      .post('mars/medication/stocks/types/toggle-visibility', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
