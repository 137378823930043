
  import {
    defineComponent,
    onMounted,
    reactive,
    computed,
    ref,
    watch,
  } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { format, endOfDay } from 'date-fns'
  import { Form } from '@/types/modules/forms/Form'
  import { useStore } from 'vuex'
  import { Task } from '@/types/modules/tasks/Task'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      status: {
        type: String,
        default: 'all',
        validator: (value: string) => {
          return ['all', 'completed', 'draft'].indexOf(value) !== -1
        },
      },
      queryFilter: {
        type: Object,
        required: false,
      },
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const tasks = computed((): Task[] => store.getters['tasks/tasks'])
      const forms = computed(() => store.getters['forms/forms'])
      const pageTitle = computed(() => {
        return `${props.status
          .replace(/-/g, ' ')
          .replace(/\b\w/g, (l) => l.toUpperCase())} Tasks`
      })
      const crumbs = [
        { name: 'Overview', url: '/overview' },
        {
          name: pageTitle,
          url: `overview/tasks?status=${props.status}`,
        },
      ]
      const query = ref({})
      const state = reactive({
        chips: [] as string[],
        activeChip: 'all',
      })
      const applyFilters = () => {
        filters.value.form = state.activeChip
      }
      const createChips = () => {
        state.chips = ['all']

        forms.value.forEach((form: Form) => {
          let chip = form.form_group ? form.form_group.name : form.name

          if (!state.chips.includes(chip)) {
            state.chips.push(chip)
          }
        })
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }
      const filters = ref({
        before: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss') as string,
        task_statuses: [] as string[],
        log_statuses: [] as string[],
        sort: [['start_date', 'desc']],
        filters: [] as string[][],
        form: null as string | null,
      })
      const logStatusCheck = () => {
        if (props.status === 'draft') {
          filters.value.log_statuses = ['Draft']
          filters.value.task_statuses = ['Incomplete']
          store.commit('genericStore/setIcon', 'fas fa-pencil-ruler')
        } else if (props.status === 'incomplete') {
          filters.value.log_statuses = ['Not Started', 'Incomplete']
          filters.value.task_statuses = ['Incomplete']
          store.commit('genericStore/setIcon', 'fas fa-tasks')
        } else if (props.status === 'completed') {
          filters.value.log_statuses = ['Submitted', 'Approved']
          filters.value.task_statuses = ['Completed']
          store.commit('genericStore/setIcon', 'far fa-check-circle')
        } else if (props.status === 'awaiting-approval') {
          filters.value.log_statuses = ['Sent For Approval']
          store.commit('genericStore/setIcon', 'far fa-alarm-exclamation')
        }
      }
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Task Name',
          dataField: 'name',
          width: 200,
        },
        {
          dataField: 'is_system',
          caption: 'Related To',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Form Group Type',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
        {
          caption: 'Task Date',
          dataField: 'start_date',
          width: 200,
        },
      ])

      watch(
        () => props.queryFilter,
        () => {
          filters.value.filters = []

          if (props.queryFilter && props.queryFilter.project_id) {
            filters.value.filters.push(
              ['project_id', '=', props.queryFilter.project_id],
              ['project_parent_id', '=', props.queryFilter.project_id]
            )
          }
        }
      )
      onMounted(() => {
        logStatusCheck()
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })

        // TODO: figure out how to display font awesome icons as an image

        store.commit('genericStore/setPageAvatar', 'https://picsum.photos/300')
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('forms/index', {
            relations: ['form_group'],
          })
          .then(() => createChips())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        state,
        query,
        filters,
        chipClicked,
        logStatusCheck,
        columns,
        tasks,
        props,
        pageTitle,
      }
    },
  })
