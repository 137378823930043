
  import { defineComponent, onMounted, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import ColumnChartVue from '@/components/Generic/Charts/ColumnChart.vue'
  import { ColumnChart } from '@/types/components/Charts'
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { renderCellLink } from '@/composables/Grids'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      ColumnChartVue,
      DataGrid,
    },
    props: {
      start_date: {
        type: String,
        required: true,
      },
      end_date: {
        type: String,
        required: true,
      },
      form_id: {
        type: Number,
        required: true,
      },
      field_id: {
        type: Number || (undefined as unknown),
        required: false,
      },
      project_types_label: {
        type: String,
        required: true,
      },
      project_type_is_parent: {
        type: Boolean,
        required: true,
      },
      activeStep: {
        type: Number,
        required: true,
      },
      show_task_audit_trail: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['change-step'],
    setup(props, { emit }) {
      const { t } = useI18n()
      props = reactive(props)
      const payload = {
        start_date: props.start_date,
        end_date: props.end_date,
        form_id: props.form_id,
        field_id: props.field_id,
        show_task_audit_trail: +props.show_task_audit_trail,
      }
      const store = useStore()
      const state = reactive({
        ready: false,
        graphData: [] as ColumnChart[],
        columns: [
          ...(!props.project_type_is_parent
            ? [
                {
                  allowGrouping: false,
                  dataType: 'string',
                  caption: t(
                    'taskExplorer.runReport.grid.headers.homeOrSchool'
                  ),
                  dataField: 'task.project.parents[0].name',
                },
              ]
            : []),
          {
            allowGrouping: false,
            dataType: 'string',
            dataField: 'task.project.name',
            caption: props.project_types_label,
          },
          {
            dataType: 'string',
            dataField: 'name',
            caption: t('taskExplorer.runReport.grid.headers.summary'),
          },
          {
            allowGrouping: false,
            dataType: 'datetime',
            format: 'dd/MM/yyyy',
            dataField: 'override_date',
            caption: t('taskExplorer.runReport.grid.headers.firstFiled'),
          },
          {
            dataType: 'string',
            caption: t('taskExplorer.runReport.grid.headers.viewLog'),
            cellTemplate: function (container, options) {
              renderCellLink(
                container,
                t('taskExplorer.runReport.grid.cells.viewLog'),
                'ViewLog',
                {
                  taskId: options.data?.id,
                }
              )
            },
          },
        ] as Column[],
        project_id: undefined as unknown,
      })

      const seriesClicked = (label: string) => {
        const series = state.graphData.find(
          (series: any) => series.label === label
        ) as { children: object; id?: number }
        let children = []
        if (series?.children) {
          children = Object.values(series?.children)
        }

        if (children.length) {
          state.graphData = children
        } else {
          state.project_id = series?.id
          emit('change-step')
        }
      }

      const dataGrid = ref()

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.dispatch('tasks/taskExplorerChart', payload).then((response) => {
          state.graphData = Object.values(response.data.graph)
          state.ready = true
          store.dispatch('genericStore/showPageLoader', false)
        })
      })

      return {
        props,
        state,
        dataGrid,
        seriesClicked,
        t,
      }
    },
  })
