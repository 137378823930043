
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import EventTypesTable from './partials/EventTypesTable.vue'
  import EventStatusesTable from './partials/EventStatusesTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      EventTypesTable,
      EventStatusesTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Event Types',
        tabs: ['Event Types', 'Event Statuses'],
      })

      return {
        state,
      }
    },
  })
