
  import { defineComponent, computed, reactive, onMounted, watch } from 'vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'
  import { Project } from '@/types/modules/projects/Project'
  import { CalendarEventType } from '@/types/modules/calendar/CalendarEventType'
  import { CalendarEventStatus } from '@/types/modules/calendar/CalendarEventStatus'

  export default defineComponent({
    components: {
      MultiSelect,
    },
    emits: ['filters-changed'],
    setup(_props, { emit }) {
      const store = useStore()
      const state = reactive({
        filters: {
          assignees: [],
          projects: [],
          project_statuses: [],
          event_statuses: [],
          event_types: [],
          project_ids: [],
        },
      })

      const eventStatuses = computed(() => {
        if (store.getters['eventStatuses/getEventStatuses'].length) {
          return store.getters['eventStatuses/getEventStatuses'].map(
            (status: CalendarEventStatus) => {
              return {
                text: status.name,
                value: status.id,
              }
            }
          )
        }

        return []
      })

      const eventTypes = computed(() => {
        if (store.getters['eventTypes/getEventTypes'].length) {
          return store.getters['eventTypes/getEventTypes'].map(
            (type: CalendarEventType) => {
              return {
                text: type.name,
                value: type.id,
              }
            }
          )
        }

        return []
      })

      const users = computed(() => {
        if (store.getters['users/users'].length) {
          return store.getters['users/users'].map((user: User) => {
            return {
              text: user.username,
              value: user.id,
            }
          })
        }

        return []
      })

      const homes = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type?.name === 'Home'
            })
            .map((project: Project) => {
              return {
                text: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      const employees = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type?.name === 'Employee'
            })
            .map((project: Project) => {
              return {
                text: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      const schools = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type?.name === 'School'
            })
            .map((project: Project) => {
              return {
                text: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      const vehicles = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type?.name === 'Vehicle'
            })
            .map((project: Project) => {
              return {
                text: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      const youngPeople = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type?.name === 'Resident'
            })
            .map((project: Project) => {
              return {
                text: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      watch(
        () => state.filters,
        () => {
          emit('filters-changed', state.filters)
        },
        { deep: true }
      )

      const loadDependencies = async () => {
        const getUsers = store.dispatch('users/index', {
          module: 'calendar',
          status: 'active',
          filterable: true,
        })

        const getProjects = store.dispatch('projects/index', {
          filterable: true,
          relations: ['projectType'],
        })

        const getEventTypes = await store.dispatch('eventTypes/index', {})
        const getEventStatuses = await store.dispatch('eventStatuses/index', {})

        return Promise.all([
          getUsers,
          getProjects,
          getEventTypes,
          getEventStatuses,
        ])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        loadDependencies().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        users,
        state,
        homes,
        schools,
        employees,
        youngPeople,
        vehicles,
        eventTypes,
        eventStatuses,
      }
    },
  })
