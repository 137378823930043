
  import { defineComponent, PropType, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import ExpandButton from '@/components/Generic/Button/ExpandButton.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import CcToggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import ExportButton from '../Button/ExportButton.vue'
  import ExtraOptionsButton from '../Button/ExtraOptionsButton.vue'
  import { ExportOption } from '@/types/components/Export'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      CcToggle,
      ExpandButton,
      ExportButton,
      ExtraOptionsButton,
    },
    props: {
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      submitDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
      selectedRows: {
        type: Array,
        required: false,
        default: () => [],
      },
      exportOptions: {
        type: Array as PropType<ExportOption[]>,
        required: false,
        default: () => [
          { name: 'Export To PDF', icon: 'fas fa-file-pdf' },
          { name: 'Export To Excel', icon: 'fas fa-file-excel' },
        ],
      },
    },
    emits: ['option-clicked', 'export-clicked', 'submit-clicked'],
    setup(props, { emit }) {
      props = reactive(props)

      const optionClicked = (option: string) => emit('option-clicked', option)
      const exportClicked = (option: string) => emit('export-clicked', option)
      const submitClicked = (option: string) => emit('submit-clicked', option)

      return {
        props,
        optionClicked,
        exportClicked,
        submitClicked,
      }
    },
  })
