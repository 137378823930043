import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row space-x-4" }
const _hoisted_3 = { class: "flex flex-col w-1/2" }
const _hoisted_4 = { class: "flex flex-col w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_date_input = _resolveComponent("date-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_label, {
      "is-required": false,
      label: `The field ${_ctx.parameter.friendly_name} ${_ctx.parameter.friendly_operator}`
    }, null, 8, ["label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_date_input, {
          "is-required": false,
          modelValue: _ctx.payload.start_date,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.start_date) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_date_input, {
          "is-required": false,
          modelValue: _ctx.payload.end_date,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.end_date) = $event))
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}