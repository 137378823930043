import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_point = _resolveComponent("dx-point")!
  const _component_dx_common_series_settings = _resolveComponent("dx-common-series-settings")!
  const _component_dx_argument_axis = _resolveComponent("dx-argument-axis")!
  const _component_dx_legend = _resolveComponent("dx-legend")!
  const _component_dx_series = _resolveComponent("dx-series")!
  const _component_dx_chart = _resolveComponent("dx-chart")!

  return (_ctx.formattedData)
    ? (_openBlock(), _createBlock(_component_dx_chart, {
        key: 0,
        id: "chart",
        "data-source": _ctx.formattedData,
        palette: "Soft Blue",
        ref: "lineChart"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_dx_common_series_settings, {
            "ignore-empty-points": true,
            "argument-field": "month",
            "inner-color": "red"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dx_point, { visible: true })
            ]),
            _: 1
          }),
          _createVNode(_component_dx_argument_axis, {
            "value-margins-enabled": false,
            "discrete-axis-division-mode": "crossLabels"
          }),
          _createVNode(_component_dx_legend, {
            "vertical-alignment": "bottom",
            "horizontal-alignment": "center",
            "item-text-position": "bottom"
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSources, (source) => {
            return (_openBlock(), _createBlock(_component_dx_series, {
              key: source.value,
              "value-field": source.value,
              name: source.name,
              background: "red"
            }, null, 8, ["value-field", "name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["data-source"]))
    : _createCommentVNode("", true)
}