
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import YoungPersonSummary from './details/YoungPersonSummary.vue'
  import YoungPersonContactDetails from './details/YoungPersonContactDetails.vue'
  import YoungPersonPersonalDetails from './details/YoungPersonPersonalDetails.vue'
  import YoungPersonHealth from './details/YoungPersonHealth.vue'
  import YoungPersonEducation from './details/YoungPersonEducation.vue'
  import CustomFieldDetails from '../../partials/CustomFieldDetails.vue'
  import ExpandButton from '@/components/Generic/Button/ExpandButton.vue'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import GridToolbar from '@/components/Generic/Grid/GridToolbar.vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      YoungPersonSummary,
      YoungPersonContactDetails,
      YoungPersonPersonalDetails,
      YoungPersonHealth,
      YoungPersonEducation,
      CustomFieldDetails,
      ExpandButton,
      Chips,
      GridToolbar,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      props = reactive(props)
      const customFieldGroups = computed(
        () => store.getters['projects/customFieldGroups']
      )
      const customFieldTabs = computed(() =>
        Object.keys(customFieldGroups.value)
      )
      const state = reactive({
        activeTab: 'summary',
        availableTabs: [
          'summary',
          'contact details',
          'Personal details',
          'Health',
          'Education',
        ],
        customFieldTabs: customFieldTabs,
        expanded: false,
      })
      const showTab = (value: string) => {
        state.activeTab = value
      }

      onMounted(() => {
        store.dispatch('projects/customFields', {
          type: 'youngPerson',
        })
      })

      const getAllTabs = computed(() => {
        return state.availableTabs.concat(state.customFieldTabs)
      })

      const hasCustomTab = (tab: string) => {
        return state.customFieldTabs.includes(tab)
      }

      return {
        showTab,
        state,
        props,
        getAllTabs,
        hasCustomTab,
        customFieldGroups,
        t,
      }
    },
  })
