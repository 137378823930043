
  import { defineComponent } from 'vue'
  import LookupGrid from '@/components/Generic/Grid/LookupGrid.vue'

  export default defineComponent({
    components: {
      LookupGrid,
    },
    setup() {},
  })
