
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      icon: {
        type: String,
        required: true,
      },
      isCollapsed: {
        type: Boolean,
        required: false,
        default: false,
      },
      url: {
        type: String,
        default: '#',
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      const linkClasses = computed(() => ({
        'my-1': true,
        'first:mt-0': true,
        'px-5': true,
        'py-3': true,
        'text-1xs': true,
        'block': true,
        'hover:bg-white': true,
        'hover:text-blue-500': true,
        'font-semibold': true,
        'transition-all': true,
        'cursor-pointer': true,
        'text-center': props.isCollapsed,
        'flex items-center': !props.isCollapsed,
      }))

      return {
        props,
        linkClasses,
      }
    },
  })
