
  import { defineComponent, watch, PropType, reactive, ref, Ref } from 'vue'
  import Fields from '@/components/Generic/Forms/Inputs/Rendered'
  import { CollapseSection } from '@/types/components/forms/CollapseSection'
  import MultiStep from '@/components/Generic/Forms/Inputs/Actual/MultiStep.vue'
  import { TaskLog } from '@/types/modules/tasks/TaskLog'
  import { Task } from '@/types/modules/tasks/Task'
  import { FormField } from '@/types/modules/forms/FormField'
  import { TaskLogValue } from '@/types/modules/tasks/TaskLogValue'
  import { buildFields } from '@/composables/FormFields'
  import { MultiStep as MultiStepType } from '@/types/components/MultiStep'

  export default defineComponent({
    components: {
      ...Fields,
      MultiStep,
    },
    props: {
      details: {
        type: Object as PropType<TaskLog>,
        required: true,
      },
      task: {
        type: Object as PropType<Task>,
        required: true,
      },
    },
    setup(props) {
      const formattedFields = ref([]) as Ref<any[]>

      const state = reactive({
        sectionCollapse: null as {
          value: string
          shouldCollapse: boolean
        } | null,
        steps: [] as MultiStepType[],
        active_multi_step: undefined as number | undefined,
      })

      const getFieldComponent = (systemName: string) => {
        let mappings: Record<string, string> = {
          yes_no: 'SmallText',
          numeric: 'SmallText',
          smalltext: 'SmallText',
          largetext: 'LargeText',
          time_input: 'SmallText',
          date_input: 'SmallText',
          true_false: 'SmallText',
          system_list: 'SmallText',
          embed_files: 'EmbedFiles',
          related_forms: 'RelatedForms',
          radio_buttons: 'SmallText',
          checkbox_list: 'SmallText',
          override_date_input: 'SmallText',
          dropdown_select_list: 'SmallText',
          field_group_heading: 'FieldGroupHeading',
          table: 'CcTable',
          inline_image: 'ImageField',
          signature_image: 'ImageField',
          image_editor: 'ImageField',
        }

        return mappings[systemName]
      }

      const getProps = (field: FormField) => {
        return {
          value: getFieldValue(field),
          fieldAttributes: field.field_attributes,
        }
      }

      const getFieldValue = (field: FormField) => {
        if (field.field!.system_name === 'field_group_heading') {
          return field.name
        }

        if (props.details) {
          if (field.field!.system_name == 'table') {
            return props.details.values?.filter(
              (logValue: any) => logValue.parent_id == field.id
            )
          }

          let relatedLogValue = props.details.values?.find(
            (value: TaskLogValue) => value.form_field_id === field.id
          )

          return relatedLogValue?.value
        }

        // no value found return nothing
        return undefined
      }

      const collapseSection = (logValue: {
        value: string
        shouldCollapse: boolean
      }) => {
        let headingIndex = formattedFields.value.findIndex((value: any) => {
          return value.name === logValue.value
        })

        let nextHeadingIndex = formattedFields.value.findIndex(
          (value: any, index: number) => {
            return (
              value.field.system_name === 'field_group_heading' &&
              index > headingIndex
            )
          }
        )

        formattedFields.value.forEach((value: any, index: number) => {
          if (index > headingIndex && index < nextHeadingIndex) {
            value.is_visible = !logValue?.shouldCollapse
          }

          if (index > headingIndex && nextHeadingIndex === -1) {
            value.is_visible = !logValue?.shouldCollapse
          }
        })
      }

      const registerCustomEventListeners = (systemName: string) => {
        let listeners = {} as Record<string, CollapseSection>

        if (systemName === 'field_group_heading') {
          listeners.collapseToggle = collapseSection
        }

        return listeners
      }

      watch(
        () => props.details,
        () => {
          const fields = buildFields(props.task.form.fields)
          formattedFields.value = fields.fields
          state.active_multi_step = fields.state.active_multi_step
          state.steps = fields.state.steps
        },
        { immediate: true, deep: true }
      )

      return {
        props,
        getProps,
        getFieldComponent,
        state,
        collapseSection,
        registerCustomEventListeners,
        formattedFields,
      }
    },
  })
