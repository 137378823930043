import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_size = _resolveComponent("dx-size")!
  const _component_dx_series = _resolveComponent("dx-series")!
  const _component_dx_tooltip = _resolveComponent("dx-tooltip")!
  const _component_dx_font = _resolveComponent("dx-font")!
  const _component_dx_legend = _resolveComponent("dx-legend")!
  const _component_dx_pie_chart = _resolveComponent("dx-pie-chart")!

  return (_openBlock(), _createBlock(_component_dx_pie_chart, {
    id: "pie",
    ref: "pieChart",
    "data-source": _ctx.data,
    type: "doughnut",
    palette: "default-doughnut",
    "inner-radius": 0.75,
    onPointClick: _ctx.seriesClicked,
    class: "flex items-center h-full"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dx_size, { height: 200 }),
      _createVNode(_component_dx_series, {
        "argument-field": "label",
        "value-field": "value"
      }),
      _createVNode(_component_dx_tooltip, {
        enabled: true,
        "customize-tooltip": _ctx.customizeTooltip
      }, null, 8, ["customize-tooltip"]),
      _createVNode(_component_dx_legend, {
        margin: 0,
        "horizontal-alignment": "right",
        "vertical-alignment": "top"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_dx_font, {
            family: "Mulish",
            size: "0.833125rem",
            weight: 600,
            color: "#222"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data-source", "inner-radius", "onPointClick"]))
}