import EnButton from './Button/en'
import EnFooter from './Footer/en'
import EnGrid from './Grid/en'
import EnFileDropZone from './FileDropZone/en'
import EnExchangeTable from './ExchangeTable/en'
import EnDateRanges from './DateRanges/en'
import EnFileUploader from './FileUploader/en'
import EnRadioButton from './RadioButton/en'
import EnForm from './Form/index'

export const EnComponents = {
  ...EnButton,
  ...EnFooter,
  ...EnGrid,
  ...EnFileDropZone,
  ...EnExchangeTable,
  ...EnDateRanges,
  ...EnFileUploader,
  ...EnRadioButton,
  ...EnForm,
}
