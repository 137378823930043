import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "border text-center p-10 bg-white w-96 h-40 absolute top-1/3 left-1/2 transform -translate-x-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_column_chart_vue = _resolveComponent("column-chart-vue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.props.activeStep === 3)
      ? (_openBlock(), _createBlock(_component_data_grid, {
          key: 0,
          columns: _ctx.state.columns,
          action: "tasks/logsList",
          "query-filter": {
      ..._ctx.props,
      ...{
        project_id: _ctx.state.project_id,
      },
    },
          "show-grid-toolbar": false,
          ref: "dataGrid"
        }, null, 8, ["columns", "query-filter"]))
      : _createCommentVNode("", true),
    (_ctx.props.activeStep === 2 && _ctx.state.graphData.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_column_chart_vue, {
            id: "chart",
            data: _ctx.state.graphData,
            onSeriesClicked: _ctx.seriesClicked
          }, null, 8, ["data", "onSeriesClicked"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.props.activeStep === 2 && !_ctx.state.graphData.length && _ctx.state.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_column_chart_vue, { data: [] }),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('taskExplorer.runReport.noData')) + "... ", 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}