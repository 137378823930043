
  import { get } from 'lodash'
  import format from 'date-fns/format'
  import { computed, defineComponent, onMounted, watch, reactive } from 'vue'
  import isSameDay from 'date-fns/isSameDay'
  import { getHours } from 'date-fns'
  import getMinutes from 'date-fns/fp/getMinutes/index.js'

  export default defineComponent({
    props: {
      currentDate: {
        type: [Date, String],
        default: () => {
          return new Date()
        },
      },
      currentView: {
        type: String,
        default: 'week',
      },
      templateType: {
        type: String,
        default: null,
      },
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const state = reactive({
        shift: {} as Record<any, any>,
        templateIdentifier: Math.random().toString(36).substr(2, 9),
        imageLoading: true,
        tags: [] as any,
        defaultImage: 'https://bit.ly/3jDg2Q6', // TODO change
      })

      const formatToHoursMins = (value: any) => {
        let date = new Date(format(new Date(), 'yyyy-MM-dd') + ` ${value}`)
        let hours = getHours(date)
        let minutes = getMinutes(date)

        if (minutes === 0) {
          return `${hours} hours`
        }

        return `${hours} hours ${minutes} minutes`
      }

      watch(
        () => state.shift.image,
        () => {
          state.imageLoading = true
        },
        { flush: 'post' }
      )

      const startTime = computed(() => {
        return new Date(state.shift.shift_start)
          .toLocaleTimeString()
          .slice(0, 5)
      })

      const endTime = computed(() => {
        return new Date(state.shift.shift_end).toLocaleTimeString().slice(0, 5)
      })

      const historicalJobTitle = computed(() => {
        if (state.shift && state.shift.snapshot) {
          if (state.shift.snapshot.valid_job_titles) {
            return [
              ...new Set(
                state.shift.snapshot.valid_job_titles.map(
                  (title: any) => title.job_title
                )
              ),
            ].join(', ')
          }
        }

        return null
      })

      const expectedJobTitles = computed(() => {
        if (state.shift.valid_job_titles.length) {
          return [
            ...new Set(
              state.shift.valid_job_titles.map((title: any) => title.job_title)
            ),
          ].join(', ')
        }

        return null
      })

      const shiftSpansMultipleDays = computed(() => {
        let shift_start = new Date(state.shift.shift_start)
        let shift_end = new Date(state.shift.shift_end)

        return (
          format(shift_start, 'yyyy-MM-dd') !==
            format(shift_end, 'yyyy-MM-dd') &&
          format(shift_start, 'HH:mm') !== '00:00' &&
          format(shift_end, 'HH:mm') !== '00:00'
        )
      })

      const jobTitleTags = computed(() => {
        if (!state.shift.snapshot) {
          return get(state.shift, 'valid_job_titles', [])
        }

        return get(state.shift, 'snapshot.valid_job_titles', [])
      })

      const qualificationTags = computed(() => {
        let tags = state.shift.snapshot
          ? get(state.shift, 'snapshot.employee.qualification_tags', [])
          : get(state.shift, 'employee.qualification_tags', [])

        // Return only unique tags by id
        return tags
          .map((e: any) => e['tag_icon_id'])
          .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
          .filter((e: any) => tags[e])
          .map((e: any) => tags[e])
      })

      /**
       * Group resulting hours for summary to employee.
       */
      const groupedByEmployee = computed(() => {
        let shifts = {} as Record<string, any>

        state.shift.total_hours.forEach((shift: any) => {
          if (shifts[shift.type] === undefined) {
            shifts[shift.type] = []
            shifts[shift.type].push(shift)
          } else {
            shifts[shift.type].push(shift)
          }
        })

        return shifts
      })

      const template = (type: any) => type === props.templateType

      const setDefaultImage = (e: any) => (e.target.src = state.defaultImage)

      const buildTags = () => {
        if (jobTitleTags.value.length) {
          jobTitleTags.value.forEach((tag: any) => {
            state.tags.push(tag)
          })
        }

        if (qualificationTags.value.length) {
          qualificationTags.value.forEach((tag: any) => {
            state.tags.push(tag)
          })
        }
      }

      const checkShiftSpan = () => {
        let current_date = new Date(props.currentDate)
        let shift_start = new Date(state.shift.shift_start)

        if (shiftSpansMultipleDays.value) {
          let template = document.querySelector(
            `[data-template-id="${state.templateIdentifier}"]`
          )

          if (isSameDay(shift_start, current_date)) {
            if (template) {
              const style = {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }

              let closest = template.closest(
                '.dx-scheduler-appointment'
              ) as HTMLElement

              if (closest) {
                Object.assign(closest.style, style)
              }
            }
          }

          if (!isSameDay(shift_start, current_date)) {
            if (template) {
              const style = {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }

              let closest = template.closest(
                '.dx-scheduler-appointment'
              ) as HTMLElement
              if (closest) {
                Object.assign(closest.style, style)
              }
            }
          }
        }
      }

      const getPlannedActualHours = (type: any, shift: any) => {
        let actual_hours = formatToHoursMins(shift.actual_hours)
        let planned_hours = formatToHoursMins(shift.planned_hours)

        if (
          type.indexOf('Unplanned') > -1 &&
          type.indexOf('Shifts (Scheduled)') > -1
        ) {
          return `${actual_hours} worked`
        }

        if (
          type.indexOf('Unplanned') > -1 &&
          type.indexOf('Shifts (Worked)') > -1
        ) {
          return `${actual_hours} worked`
        }

        if (
          type.indexOf('Planned') > -1 &&
          type.indexOf('Shifts (Worked)') > -1
        ) {
          return `${actual_hours} worked; ${planned_hours} planned`
        }

        if (
          type.indexOf('Planned') > -1 &&
          type.indexOf('Shifts (Not Worked)') > -1
        ) {
          return `${planned_hours} planned`
        }

        return `${planned_hours} scheduled`
      }

      const init = () => {
        state.shift = props.cellData.appointmentData
        buildTags()
        checkShiftSpan()
      }

      onMounted(() => {
        init()
      })

      return {
        template,
        setDefaultImage,
        getPlannedActualHours,
        startTime,
        endTime,
        groupedByEmployee,
        state,
        historicalJobTitle,
        expectedJobTitles,
      }
    },
  })
