
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      label: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        validator: (value: string) =>
          [
            'primary',
            'secondary',
            'tertiary',
            'disabled',
            'tab-active',
            'tab-inactive',
            'cancel',
            'danger-outline',
            'primary-outline',
            'success-outline',
            'success',
            'warning',
          ].indexOf(value) !== -1,
        default: 'primary',
      },
      icon: {
        type: String,
        default: null,
      },
      secondaryIcon: {
        type: String,
        default: null,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      tertiaryColor: {
        type: String,
        default: 'blue',
      },
      fullWidth: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['button-clicked'],
    setup(props) {
      props = reactive(props)
      let classes = computed((): string[] => {
        const defaultClasses = [
          'text-sm',
          'border-2',
          'py-2',
          'px-4',
          'transition-colors',
          'lowercase',
          'max-w-max',
          'w-max',
          'focus-visible:bg-yellow-300',
          'focus-visible:border-black',
          'focus-visible:text-black',
        ]
        if (props.fullWidth) {
          defaultClasses.push('full-width')
        }
        if (props.isDisabled === true) {
          if (props.type === 'secondary') {
            return [
              ...defaultClasses,
              'cursor-not-allowed',
              'text-gray-200',
              'border-gray-200',
            ]
          }
          return [
            ...defaultClasses,
            'bg-gray-200',
            'border-gray-200',
            'cursor-not-allowed',
          ]
        }
        if (props.type === 'secondary') {
          return [
            ...defaultClasses,
            'hover:bg-gray-100',
            'bg-white',
            'text-blue-300',
            'border-blue-300',
          ]
        }
        if (props.type === 'primary') {
          return [
            ...defaultClasses,
            'bg-blue-300',
            'text-white',
            'hover:bg-blue-400',
            'hover:border-blue-400',
            'border-blue-300',
          ]
        }
        if (props.type === 'tertiary') {
          return [
            ...defaultClasses,
            'border-none',
            'bg-none',
            'text-' + props.tertiaryColor + '-300',
          ]
        }
        if (props.type === 'tab-active') {
          return [
            'text-xs',
            'py-2',
            'px-4',
            'transition-colors',
            'max-w-max',
            'w-max',
            'lowercase',
            'bg-purple-300',
            'text-white',
            'rounded-lg',
            'border-purple-300',
            'border',
            'whitespace-nowrap',
            'focus-visible:bg-yellow-300',
            'focus-visible:border-black',
            'focus-visible:text-black',
          ]
        }
        if (props.type === 'tab-active-error') {
          return [
            'text-xs',
            'py-2',
            'px-4',
            'transition-colors',
            'max-w-max',
            'w-max',
            'lowercase',
            'bg-red-300',
            'text-white',
            'rounded-lg',
            'border-red-300',
            'border',
            'whitespace-nowrap',
          ]
        }
        if (props.type === 'tab-inactive') {
          return [
            'text-xs',
            'py-2',
            'px-4',
            'transition-colors',
            'max-w-max',
            'w-max',
            'lowercase',
            'bg-white',
            'text-gray-300',
            'rounded-lg',
            'border-gray-300',
            'hover:bg-purple-300',
            'hover:text-white',
            'border',
            'whitespace-nowrap',
            'focus-visible:bg-yellow-300',
            'focus-visible:border-black',
            'focus-visible:text-black',
          ]
        }
        if (props.type === 'tab-inactive-error') {
          return [
            'text-xs',
            'py-2',
            'px-4',
            'transition-colors',
            'max-w-max',
            'w-max',
            'lowercase',
            'bg-white',
            'text-red-300',
            'rounded-lg',
            'border-red-300',
            'hover:bg-red-300',
            'hover:text-white',
            'border',
            'whitespace-nowrap',
          ]
        }
        if (props.type === 'cancel') {
          return [
            ...defaultClasses,
            'bg-white',
            'text-black',
            'border-black',
            'hover:bg-gray-100',
          ]
        }
        if (props.type === 'success') {
          return [
            ...defaultClasses,
            'bg-green-400',
            'text-white',
            'hover:bg-green-300',
            'border-green-400',
          ]
        }
        if (props.type === 'danger-outline') {
          return [
            ...defaultClasses,
            'bg-white',
            'text-red-300',
            'hover:bg-gray-100',
            'border-red-400',
          ]
        }
        if (props.type === 'primary-outline') {
          return [
            ...defaultClasses,
            'bg-white',
            'text-blue-300',
            'hover:bg-gray-100',
            'border-blue-400',
          ]
        }
        if (props.type === 'success-outline') {
          return [
            ...defaultClasses,
            'bg-white',
            'text-green-400',
            'hover:bg-gray-100',
            'border-green-400',
          ]
        }
        if (props.type === 'warning') {
          return [
            ...defaultClasses,
            'bg-orange-400',
            'text-white',
            'hover:bg-orange-300',
            'border-orange-400',
          ]
        }
        return []
      })
      return {
        classes,
        props,
      }
    },
  })
