export default {
  careGroupBranding: {
    header: 'Branding',
    logoHeader: 'Care Group Logo',
    splashHeader: 'Splash Images',
    dropdown: {
      delete: 'Delete',
      show: 'Show on login screen',
    },
  },
}
