
  import { defineComponent, PropType, reactive } from 'vue'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    emits: ['init-move-mode'],
    setup(props, { emit }) {
      props = reactive(props)

      const initMoveMode = () => emit('init-move-mode', props.data)

      return {
        initMoveMode,
      }
    },
  })
