import { EnComponents } from "@/locales/messages/components"

export default {
    careGroupStakeholders: {
        header: 'Stakeholders',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                name: EnComponents.grid.headers.name,
                position: EnComponents.grid.headers.position,
                startDate: EnComponents.grid.headers.startDate,
                endDate: EnComponents.grid.headers.endDate,
                status: `Responsible Individual ${EnComponents.grid.headers.status}`
            }
        }
    }
}