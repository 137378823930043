
  import { computed, defineComponent, onMounted, reactive, watch } from 'vue'
  import { useStore } from 'vuex'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { Project } from '@/types/modules/projects/Project'
  import { SelectOption } from '@/types/components/SelectOption'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import { object, number, date } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import Toggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import TrueFalse from '@/components/Generic/Forms/Inputs/TrueFalse.vue'

  export default defineComponent({
    components: {
      SelectInput,
      DateInput,
      PageToolbar,
      CcButton,
      Toggle,
      ValidationErrors,
      TrueFalse,
    },
    props: {
      projectType: {
        type: String,
        required: false,
      },
      id: {
        type: String,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const store = useStore()
      const state = reactive({
        errors: {} as Record<string, string>,
      })
      const payload = reactive({
        parent_id: undefined,
        is_default: Number(!props.id),
        leaving_date: undefined,
        admission_date: undefined,
        copy_association_permissions: false,
      })

      const projectParents = computed(
        (): Project[] => store.getters['projects/allowedParents']
      )

      const projectParentSelectOptions = computed((): SelectOption[] =>
        store.getters['projects/allowedParents']?.map((project: Project) => {
          return { label: project.name, value: project.id }
        })
      )

      const validationSchema = object({
        parent_id: number()
          .typeError('Please choose a Home or School')
          .required('Please choose a Home or School'),
        admission_date: date()
          .typeError('Please select an admission date')
          .required('Please select an admission date'),
        leaving_date: date().typeError('Please select a leaving date'),
        is_default: number()
          .oneOf([0, 1])
          .typeError('Please select a default association'),
      })

      const validate = async () => {
        const errors = await validatePayload(
          validationSchema,
          payload as Record<string, any>
        )
        state.errors = errors ? errors : {}
        return !errors
      }

      const projectInstance = computed(() => {
        return props.projectType === 'young_people'
          ? store.getters[`youngPeople/getYoungPerson`]
          : store.getters[
              `${props.projectType}/${props.projectType?.slice(0, -1)}`
            ]
      })

      const inputToggled = (value: boolean) => {
        payload.is_default = Number(value)
      }

      const submitClicked = async () => {
        if (await validate()) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('projectAssociations/store', {
              id: projectInstance.value.project_id,
              parent_id: payload.parent_id,
              is_default: Number(payload.is_default),
              leaving_date: payload.leaving_date,
              admission_date: payload.admission_date,
              copy_association_permissions: Number(
                payload.copy_association_permissions
              ),
            })
            .catch((error) => {
              store.dispatch('genericStore/pushNotification', error.message)
              state.errors = error.data
            })
            .then((response) => {
              if (response.success) {
                goBack()
              }
            })
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      watch(payload, () => {
        if (Object.keys(state.errors).length) {
          validate()
        }
        emit('update-payload', {
          association: payload.parent_id
            ? {
                ...payload,
                copy_association_permissions: Number(
                  payload.copy_association_permissions
                ),
              }
            : undefined,
        })
      })

      const exportClicked = (option: string) => {
        if (option === 'Export To Excel') {
          return exportPageToExcel(payload, 'Add Association')
        }

        if (option === 'Export To PDF') {
          return exportPageToPdf(payload, 'Add Assocation')
        }
      }

      const getProjectInstance = async () => {
        if (props.id && !projectInstance.value) {
          await store.dispatch(`${props.projectType}/show`, { id: props.id })
        }
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        Promise.all([
          await getProjectInstance(),
          store.dispatch('projects/getAllowedParents'),
        ])
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))

        if (props.id) {
          store.commit('genericStore/setBreadcrumbs', {
            crumbs: [
              { name: 'Dashboard', url: '/' },
              {
                name: props.projectType,
                url: `/projects/${props.projectType}`,
              },
              {
                name: projectInstance.value.project?.name,
                url: `/projects/${props.projectType}/${props.id}`,
              },
              {
                name: 'Associations',
                url: `/projects/${props.projectType}/${props.id}?tab=associations`,
              },
              { name: `Create Association`, url: '#' },
            ],
          })
        }
      })

      return {
        props,
        projectParents,
        projectParentSelectOptions,
        inputToggled,
        goBack,
        submitClicked,
        payload,
        state,
        validate,
        exportClicked,
      }
    },
  })
