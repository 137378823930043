import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { Field } from '@/types/modules/forms/Field'

export type FormBuilderGetter = GetterTree<FormBuilderState, any>

export interface FormBuilderState {
  addedField: Field | null
}

export const state: FormBuilderState = {
  addedField: null,
}

export const getters: FormBuilderGetter = {
  getAddedField: (state) => state.addedField,
}

export const actions: ActionTree<FormBuilderState, any> = {
  addFieldToCanvas: (context, field: Field) => {
    context.commit('setAddedField', field)
  },
}

export const mutations: MutationTree<FormBuilderState> = {
  setAddedField: (state, field) => (state.addedField = field),
}

export const formBuilder = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
