import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_ctx.projectInstance)
    ? (_openBlock(), _createBlock(_component_data_grid, {
        key: 0,
        columns: _ctx.columns,
        action: "projectAssociations/index",
        "query-filter": {
      only: ['trashed'],
      project_id: _ctx.projectInstance.project_id,
    },
        onRowsSelected: _ctx.rowsSelected
      }, {
        "toolbar-addon": _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: _ctx.t('components.buttons.back'),
            type: "tertiary",
            icon: "fa fa-arrow-left",
            "secondary-icon": "none",
            onClick: _ctx.goBack
          }, null, 8, ["label", "onClick"])
        ]),
        "toolbar-primary-button": _withCtx(() => [
          _createVNode(_component_cc_button, {
            type: "warning",
            label: "Restore",
            icon: "fa fa-trash-can-undo",
            isDisabled: !_ctx.state.selectedRows.length,
            onClick: _ctx.restore
          }, null, 8, ["isDisabled", "onClick"])
        ]),
        _: 1
      }, 8, ["columns", "query-filter", "onRowsSelected"]))
    : _createCommentVNode("", true)
}