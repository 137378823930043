
  import { defineComponent, computed, ref, Ref, watch, PropType } from 'vue'
  import { useStore } from 'vuex'
  import { format } from 'date-fns/esm'
  import DxScheduler from 'devextreme-vue/scheduler'
  import { CalendarEventInstance } from '@/types/modules/calendar/CalendarEventInstance'
  import { DxView } from 'devextreme-vue/scheduler'
  import endOfDay from 'date-fns/fp/endOfDay/index'
  import CustomStore from 'devextreme/data/custom_store'
  import DataSource from 'devextreme/data/data_source'
  import MonthlyAppointmentTemplate from './Templates/MonthlyAppointmentTemplate.vue'
  import OverflowIndicatorTemplate from './Templates/OverflowIndicatorTemplate.vue'
  import AgendaAppointmentTemplate from './Templates/AgendaAppointmentTemplate.vue'
  import TooltipTemplate from './Templates/TooltipTemplate.vue'
  import startOfWeek from 'date-fns/esm/startOfWeek/index'
  import { useRouter } from 'vue-router'
  import { Properties } from 'devextreme/ui/calendar'

  export default defineComponent({
    components: {
      DxScheduler,
      DxView,
      MonthlyAppointmentTemplate,
      OverflowIndicatorTemplate,
      AgendaAppointmentTemplate,
      TooltipTemplate,
    },
    props: {
      filters: {
        type: Object as PropType<any>,
        required: false,
      },
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      const calendar = ref() as Ref<DxScheduler | undefined>

      const views = computed(() => {
        return [
          {
            type: 'agenda',
            name: 'Day',
            agendaDuration: 1,
            appointmentTemplate: 'agendaAppointmentTemplate',
          },
          {
            type: 'agenda',
            name: 'Week',
            appointmentTemplate: 'agendaAppointmentTemplate',
          },
          {
            type: 'month',
            maxAppointmentsPerCell: 2,
            appointmentTemplate: 'monthlyAppointmentTemplate',
            appointmentCollectorTemplate: 'tooltipTemplate',
            appointmentTooltipTemplate: 'appointmentTooltipTemplate',
          },
        ]
      })

      const data = computed(() => {
        return new DataSource({
          store: new CustomStore({
            load: async () => {
              store.dispatch('genericStore/showPageLoader', true)
              return await getEventInstances({
                start_date: format(
                  calendar.value?.instance?.getStartViewDate()!,
                  'yyyy-MM-dd HH:mm:ss'
                ),
                end_date: format(
                  calendar.value?.instance?.getEndViewDate()!,
                  'yyyy-MM-dd HH:mm:ss'
                ),
                ...props.filters,
              })
                .then(({ data }) => {
                  if (data.length) {
                    return data.map((instance: CalendarEventInstance) => {
                      return {
                        id: instance.id,
                        text: `${format(new Date(instance.date), 'HH:mm')}: ${
                          instance.event.title
                        }`,
                        start_date: format(
                          new Date(instance.date),
                          'yyyy-MM-dd'
                        ),
                        end_date: endOfDay(new Date(instance.date)),
                        relates_to: instance.event.project?.name,
                        status: instance.status,
                        event: instance.event,
                      }
                    })
                  }

                  return []
                })
                .catch((error) =>
                  store.dispatch('genericStore/pushNotification', error.message)
                )
                .finally(() =>
                  store.dispatch('genericStore/showPageLoader', false)
                )
            },
          }),
        })
      })

      watch(
        () => props.filters,
        () => {
          store.dispatch('genericStore/showPageLoader', true)
          data.value.reload().then(() => {
            calendar.value?.instance?.repaint()
            store.dispatch('genericStore/showPageLoader', false)
          })
        },
        { deep: true }
      )

      const getEventInstances = (params: any) =>
        store.dispatch('calendar/index', params)

      const viewEvent = (e: any) => {
        preventFormOpening(e)
        router.push({
          name: 'CalendarEventShow',
          params: { id: e.appointmentData.id },
        })
      }

      const registerTooltip = (e: any) => {
        let element = e.appointmentElement;  
        element.onmouseenter = function(){  
            setTimeout(function(){  
                e.component.showAppointmentTooltip(e.appointmentData, e.appointmentElement, e.targetedAppointmentData);  
            }, 300);  
        };  
      }

      const preventFormOpening = (e: any) => (e.cancel = true)

      const optionChanged = (e: any) => {
        if (calendar.value && e.name === 'currentView') {
          if (
            (calendar.value?.instance?.option('currentView') as Properties) ===
            'Week'
          ) {
            calendar.value?.instance?.option(
              'currentDate',
              startOfWeek(new Date(), {
                weekStartsOn: 1,
              })
            )
          } else {
            calendar.value?.instance?.option('currentDate', new Date())
          }
        }
      }

      return {
        optionChanged,
        preventFormOpening,
        calendar,
        views,
        data,
        viewEvent,
        registerTooltip,
      }
    },
  })
