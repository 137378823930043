
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { computed, defineComponent } from 'vue'
  import { Version } from '../../types/modules/generic/Version'

  export default defineComponent({
    setup() {
      const { t } = useI18n()
      const date = new Date().getFullYear()

      const appVersion = computed(
        (): Version => useStore().getters['genericStore/appVersion']
      )
      const apiVersion = computed(
        (): Version => useStore().getters['genericStore/apiVersion']
      )

      return {
        t,
        date,
        appVersion,
        apiVersion,
      }
    },
  })
