
  import { useI18n } from 'vue-i18n'
  import { onMounted, reactive } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { renderCellLink } from '@/composables/Grids'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { useRouter } from 'vue-router'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'

  export default {
    components: {
      DataGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const crumbs = reactive<Crumb[]>([
        {
          name: t('bespokeReports.crumbs.dashboard'),
          url: '/dashboard',
        },
        {
          name: t('bespokeReports.crumbs.bespokeReporting'),
          url: '/bespoke-reporting',
        },
      ])
      const state = reactive({
        selectedRows: [] as BespokeReport[],
        extraOptions: [
          t('bespokeReports.extraOptions.viewDeletedReports'),
        ] as string[],
      })
      const columns: Column[] = reactive([
        {
          caption: t('bespokeReports.grids.id'),
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: t('bespokeReports.grids.name'),
          dataField: 'name',
          width: 200,
          cellTemplate: (container, options) => {
            renderCellLink(container, options.data.name, 'BespokeReportShow', {
              id: options.data.id,
            })
          },
        },
        {
          caption: t('bespokeReports.grids.description'),
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: t('bespokeReports.grids.owner'),
          calculateCellValue: (row: BespokeReport) => {
            return `${row.owner?.first_name} ${row.owner?.last_name}`
          },
          width: 200,
        },
        {
          visible: false,
          caption: t('bespokeReports.grids.createdAt'),
          dataField: 'created_at',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          visible: false,
          caption: t('bespokeReports.grids.updatedAt'),
          dataField: 'updated_at',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          caption: t('bespokeReports.grids.designLink.heading'),
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              t('bespokeReports.grids.designLink.cell'),
              'BespokeReportDesign',
              {
                id: options.data.id,
              }
            )
          },
        },
        {
          caption: t('bespokeReports.grids.publishedLink.heading'),
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              t('bespokeReports.grids.publishedLink.cell'),
              'BespokeReportPublishedIndex',
              {
                id: options.data.id,
              }
            )
          },
        },
        {
          caption: t('bespokeReports.grids.configureLink.heading'),
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              t('bespokeReports.grids.configureLink.cell'),
              'BespokeReportEdit',
              {
                id: options.data.id,
              }
            )
          },
        },
      ])

      const rowsSelected = (rows: BespokeReport[]) => {
        state.selectedRows = rows

        if (
          rows.length &&
          !state.extraOptions.includes(t('bespokeReports.extraOptions.delete'))
        ) {
          state.extraOptions.push(t('bespokeReports.extraOptions.delete'))
        }

        if (
          !rows.length &&
          state.extraOptions.includes(t('bespokeReports.extraOptions.delete'))
        ) {
          state.extraOptions.splice(
            state.extraOptions.indexOf(t('bespokeReports.extraOptions.delete')),
            1
          )
        }
      }

      const optionClicked = (option: string) => {
        if (option === t('bespokeReports.extraOptions.delete')) {
          store
            .dispatch('bespokeReports/setSelectedReports', state.selectedRows)
            .then(() => router.push({ name: 'BespokeReportDelete' }))
        }

        if (option === t('bespokeReports.extraOptions.viewDeletedReports')) {
          router.push({ name: 'BespokeReportRestore' })
        }

        return
      }

      const primaryButtonClicked = () =>
        router.push({ name: 'BespokeReportCreate' })

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', { crumbs })
        store.commit('bespokeReports/setBespokeReport', null)
        store.commit(
          'bespokeReportDatasources/setBespokeReportDatasources',
          null
        )
      })

      return {
        t,
        state,
        columns,
        rowsSelected,
        optionClicked,
        primaryButtonClicked,
      }
    },
  }
