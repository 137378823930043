
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    Ref,
    watch,
  } from 'vue'
  import DxList from 'devextreme-vue/list'
  import { orderBy } from 'lodash'

  export default defineComponent({
    components: {
      DxList,
    },
    props: {
      items: {
        type: Array as PropType<any[]>,
        required: true,
      },
      existingItems: {
        type: Array as PropType<any[]>,
        required: false,
        default: () => [],
      },
      keyExpr: {
        type: String,
        required: true,
      },
      valueExpr: {
        type: Array as PropType<string[]>,
        required: true,
      },
    },
    emits: ['items-changed'],
    setup(props, { emit }) {
      props = reactive(props)
      const listItems = ref([]) as Ref<any[]>
      const addedItems = ref([]) as Ref<any[]>

      const addItem = (e: any) => {
        let items = [...addedItems.value]

        items.push(e.itemData)

        addedItems.value = items

        listItems.value = listItems.value.filter(
          (item: any) => item[props.keyExpr] != e.itemData[props.keyExpr]
        )
      }

      const buildDisplayValue = (item: any) => {
        let value = ''
        props.valueExpr.forEach((key: string) => {
          value = value + ' ' + item[key]
        })

        return value
      }

      const removeItem = (e: any) => {
        let existingAddedItems = [...addedItems.value]
        let existingItems = [...listItems.value]

        addedItems.value = existingAddedItems.filter(
          (item: any) => item[props.keyExpr] != e.itemData[props.keyExpr]
        )

        existingItems.push(e.itemData)
        existingItems = orderBy(existingItems, props.valueExpr)
        listItems.value = existingItems
      }

      watch(addedItems, () => emit('items-changed', addedItems.value))

      const mapItems = () => {
        listItems.value = props.items.filter((item: any) => {
          let mappedIDs = props.existingItems.map(
            (existingItem: any) => existingItem[props.keyExpr]
          )

          return mappedIDs.length
            ? !mappedIDs.includes(item[props.keyExpr])
            : true
        })

        addedItems.value = props.existingItems
      }

      onMounted(() => {
        mapItems()
      })

      return {
        listItems,
        addedItems,
        addItem,
        removeItem,
        buildDisplayValue,
      }
    },
  })
