
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { defineComponent, onMounted, PropType, reactive, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'

  export default defineComponent({
    props: {
      careGroup: {
        type: Object as PropType<CareGroup>,
        required: true,
      },
    },
    components: {
      TextBox,
      TextInput,
      SelectInput,
      RadioButton,
    },
    emits: ['payload-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const { t } = useI18n()

      const payload = reactive({
        company_address1: '' as string | null,
        company_address2: '' as string | null,
        company_city: '' as string | null,
        company_state: '' as string | null,
        company_zip: '' as string | null,
      })

      const setAddresses = () => {
        payload.company_address1 = props.careGroup.company_address1
        payload.company_address2 = props.careGroup.company_address2
        payload.company_city = props.careGroup.company_city
        payload.company_state = props.careGroup.company_state
        payload.company_zip = props.careGroup.company_zip
      }

      watch(
        () => payload, 
        () => emit('payload-updated', payload), 
        { deep: true }
      )

      onMounted(() => setAddresses())

      return {
        t,
        props,
        payload,
      }
    },
  })
