import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { CalendarEventType } from '@/types/modules/calendar/CalendarEventType'
import EventTypeService from '@/services/calendar/eventTypes'

export type CalendarEventTypeGetter = GetterTree<CalendarEventTypeState, any>

export interface CalendarEventTypeState {
  eventTypes: CalendarEventType[]
  eventType: CalendarEventType | null
}

export const state: CalendarEventTypeState = {
  eventTypes: [],
  eventType: null,
}

export const getters: CalendarEventTypeGetter = {
  getEventTypes: (state) => state.eventTypes,
  getEventType: (state) => state.eventType,
}

export const actions: ActionTree<CalendarEventTypeState, any> = {
  index: async (context, params) => {
    const response = await EventTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setEventTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setEventTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(EventTypeService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setEventType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(EventTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(EventTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(EventTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(EventTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(EventTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(EventTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(EventTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<CalendarEventTypeState> = {
  setEventTypes: (state, types: CalendarEventType[]) =>
    (state.eventTypes = types),
  setEventType: (state, type: CalendarEventType) => (state.eventType = type),
}

export const eventTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
