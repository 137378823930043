import { EnComponents } from "@/locales/messages/components"

export default {
    employees: {
        breadcrumb: 'Employees',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                location: EnComponents.grid.headers.location,
                status: EnComponents.grid.headers.status,
                tagging: EnComponents.grid.headers.tagging,
                administrator: EnComponents.grid.headers.administrator,
                careGroup: EnComponents.grid.headers.careGroup,
                startDate: EnComponents.grid.headers.startDate,
                endDate: EnComponents.grid.headers.endDate,
                reports: EnComponents.grid.headers.reports,
                createTasks: EnComponents.grid.headers.createTasks,
                name: `Employee ${EnComponents.grid.headers.name}`,
                jobTitle: 'Job Title'
            }
        }
    }
}