
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import FileDropzone from '@/components/Generic/Forms/Inputs/FileDropzone.vue'
  import { useStore } from 'vuex'
  import ImageTile from '@/components/Generic/ImageTile/ImageTile.vue'
  import { CareGroupImage } from '@/types/modules/care_groups/CareGroupImage'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      FileDropzone,
      ImageTile,
      ConfirmDialog,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const splashImages = computed(
        (): CareGroupImage[] => store.getters['careGroups/splashImages']
      )

      // TODO: allow user to update name/show once uploaded?
      const logoImages = computed((): CareGroupImage[] => {
        return store.getters['careGroups/logoImages']
      })

      const state = reactive({
        logoDeletion: {
          image: {},
          showConfirm: false,
        },
        splashDeletion: {
          image: {},
          showConfirm: false,
        },
        showOnLogin: false,
      })

      const uploadSplashImage = (file: File) => {
        store.dispatch('careGroups/uploadSplashImage', file)
      }

      const deleteLogo = (image: any) => {
        store.dispatch('careGroups/deleteLogoImage', { imageId: image.id })
      }

      const deleteSplash = (image: any) => {
        store.dispatch('careGroups/deleteSplashImage', {
          imageId: image.id,
        })
      }

      onMounted(() => {
        store.dispatch('careGroups/getSplashImages')
        store.dispatch('careGroups/getLogoImages')
      })

      return {
        uploadSplashImage,
        splashImages,
        logoImages,
        deleteLogo,
        deleteSplash,
        state,
        t,
      }
    },
  })
