import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Evidence Types",
    "create-view": "EvidenceTypeCreate",
    "edit-view": "EvidenceTypeEdit",
    "delete-view": "EvidenceTypeDelete",
    "restore-view": "EvidenceTypeRestore",
    action: "evidenceTypes/index",
    "toggle-visibility-action": "evidenceTypes/toggleManyVisibility",
    "delete-many-action": "evidenceTypes/deleteMany",
    "reorder-action": "evidenceTypes/saveOrderings"
  }))
}