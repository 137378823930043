import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_builder_wizard = _resolveComponent("form-builder-wizard")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_form_details = _resolveComponent("form-details")!
  const _component_form_access = _resolveComponent("form-access")!
  const _component_form_builder = _resolveComponent("form-builder")!
  const _component_form_preview = _resolveComponent("form-preview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_form_builder_wizard, {
      onStepChanged: _ctx.changeView,
      onSaveForm: _ctx.submitForm,
      activeStep: _ctx.activeStep
    }, null, 8, ["onStepChanged", "onSaveForm", "activeStep"]),
    (_ctx.errors && Object.values(_ctx.errors).length)
      ? (_openBlock(), _createBlock(_component_validation_errors, {
          key: 0,
          errors: _ctx.errors
        }, null, 8, ["errors"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_form_details, {
      form: _ctx.form,
      onFormDetailsUpdated: _ctx.updatePayload,
      onFormTemplateSet: _ctx.setTemplateFields
    }, null, 8, ["form", "onFormDetailsUpdated", "onFormTemplateSet"]), [
      [_vShow, _ctx.activeStep === 1]
    ]),
    (_ctx.form)
      ? (_openBlock(), _createBlock(_KeepAlive, { key: 1 }, [
          (_ctx.activeStep === 2)
            ? (_openBlock(), _createBlock(_component_form_access, {
                key: 0,
                onFormAccessUpdated: _ctx.updatePayload,
                form: _ctx.form
              }, null, 8, ["onFormAccessUpdated", "form"]))
            : _createCommentVNode("", true)
        ], 1024))
      : (_openBlock(), _createBlock(_KeepAlive, { key: 2 }, [
          (_ctx.activeStep === 2)
            ? (_openBlock(), _createBlock(_component_form_access, {
                key: 0,
                onFormAccessUpdated: _ctx.updatePayload
              }, null, 8, ["onFormAccessUpdated"]))
            : _createCommentVNode("", true)
        ], 1024)),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.activeStep === 3)
        ? (_openBlock(), _createBlock(_component_form_builder, {
            key: 0,
            fields: _ctx.payload.fields,
            onFieldsChanged: _ctx.updateFields,
            onFieldDeleted: _ctx.pushToFieldsDeleted
          }, null, 8, ["fields", "onFieldsChanged", "onFieldDeleted"]))
        : _createCommentVNode("", true)
    ], 1024)),
    (_ctx.activeStep === 4)
      ? (_openBlock(), _createBlock(_component_form_preview, {
          key: 3,
          fields: _ctx.payload.fields,
          form: _ctx.payload
        }, null, 8, ["fields", "form"]))
      : _createCommentVNode("", true)
  ], 64))
}