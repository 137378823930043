
  import { MenuTab } from '@/types/components/MenuTab'
  import { reactive, computed, defineComponent, PropType } from 'vue'
  import { isTab } from '@/composables/Generic'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    props: {
      isCollapsed: {
        type: Boolean,
        required: true,
        default: false,
      },
      tabs: {
        type: Array as PropType<MenuTab[]>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const route = useRoute()

      const linkClasses = computed(() => ({
        'font-semibold': true,
        'leading-5': true,
        'tracking-slight': true,
        'block': true,
        'my-2': true,
        'mt-0': true,
        'px-7': true,
        'py-3': true,
        'text-1xs': true,
        'hover:bg-white': true,
        'hover:text-blue-500': true,
        'transition-color': true,
        'whitespace-nowrap': true,
        'text-ellipsis': true,
        'overflow-hidden': true,
      }))

      return {
        isTab,
        props,
        linkClasses,
        route,
      }
    },
  })
