import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.project.project_type_label == 'resident')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: `/projects/young_people/${_ctx.id}`,
          class: "font-semibold text-blue-200 underline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cc_avatar, {
              url: _ctx.project.image?.thumbnail,
              alt: `${_ctx.project.name}'s profile photo`,
              class: "inline mr-0.5 object-cover"
            }, null, 8, ["url", "alt"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.project.name), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: `/projects/${_ctx.project.project_type_label}s/${_ctx.id}`,
          class: "font-semibold text-blue-200 underline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cc_avatar, {
              url: _ctx.project.image?.thumbnail,
              alt: `${_ctx.project.name}'s profile photo`,
              class: "inline mr-0.5 object-cover"
            }, null, 8, ["url", "alt"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.project.name), 1)
          ]),
          _: 1
        }, 8, ["to"]))
  ]))
}