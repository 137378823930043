
  const UUID = require('uuid-js')
  import { RenderedFormFieldSchema } from '@/types/components/FormBuilder'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import { SelectOption } from '@/types/components/SelectOption'
  import { ExtendedFormFieldOption } from '@/types/components/FormBuilder'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: {
      TextInput,
      CcButton,
      SelectInput,
    },
    props: {
      field: {
        type: Object as PropType<RenderedFormFieldSchema>,
        required: true,
      },
      forms: {
        type: Array as PropType<SelectOption[]>,
        required: true,
      },
    },
    emits: ['options-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()

      const updateDefaults = (option: ExtendedFormFieldOption) => {
        if (props.field.field.system_name === 'checkbox_list') {
          option.is_default = option.is_default === 1 ? 0 : 1
          return
        }

        options.value.map((opt: ExtendedFormFieldOption) => {
          opt.is_default = opt.id === option.id ? 1 : 0
          return opt
        })
      }

      const removeRow = (index: number) => {
        if (options.value.length === 1) {
          store.dispatch(
            'genericStore/pushNotification',
            'This field needs at least one option'
          )
          return
        }
        options.value.splice(index, 1)
      }

      const options = ref([
        {
          id: UUID.create().toString(),
          form_field_id: props.field.id,
          name: '',
          triggered_task_id: null,
          trigger_has_date: 0,
          always_trigger_for_same_project: 0,
          restrict_projects_by_association: 1,
          is_default: 0,
        },
      ]) as Ref<ExtendedFormFieldOption[]>

      watch(options, () => emit('options-updated', options.value), {
        deep: true,
      })

      const addNewRow = () => {
        options.value.push({
          id: UUID.create().toString(),
          form_field_id: props.field.id,
          name: '',
          triggered_task_id: null,
          trigger_has_date: 0,
          always_trigger_for_same_project: 0,
          restrict_projects_by_association: 1,
          is_default: 0,
        })
      }

      onMounted(() => {
        if (props.field.options.length) {
          options.value = props.field.options

          let value = props.field.field_attributes.find(
            (att) => att.name === 'value'
          )

          options.value.forEach((option) => {
            if (value && value['value'] && value['value'].contains('||')) {
              if (value['value']?.split('||').includes(option.name)) {
                option.is_default = 1
              }
            } else if (value && value['value'] === option.name) {
              option.is_default = 1
            }
          })
        }
      })

      return {
        props,
        options,
        addNewRow,
        removeRow,
        updateDefaults,
      }
    },
  })
