import { canEditModule, canViewModule } from '@/composables/Permissions'
import { MiddlewareContext } from '@/router'

export function canView({ to, next }: MiddlewareContext) {
  // @ts-ignore
  if (!canViewModule(to.meta.module)) {
    return next({ name: 'Forbidden' })
  }

  return next()
}

export function canEdit({ to, next }: MiddlewareContext) {
  // @ts-ignore
  if (!canEditModule(to.meta.module)) {
    return next({ name: 'Forbidden' })
  }

  return next()
}
