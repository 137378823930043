import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8aa453ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timeline-block" }
const _hoisted_2 = {
  key: 0,
  class: "my-8 px-12"
}
const _hoisted_3 = ["data-rota-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multistep_wizard = _resolveComponent("multistep-wizard")!
  const _component_DxResource = _resolveComponent("DxResource")!
  const _component_rota_timeline = _resolveComponent("rota-timeline")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.filteredStartWeeks.length >= 2)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_multistep_wizard, {
            step: _ctx.sharedState.step,
            "total-steps": _ctx.totalSteps,
            "prepend-text": "Week",
            onOnSelected: _ctx.onWeekCycleStepChange
          }, null, 8, ["step", "total-steps", "onOnSelected"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.rotaSetting && _ctx.rotaSetting.default_cycle_id)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.sharedState.groupings.dates, (current_date, index) => {
          return (_openBlock(), _createElementBlock("section", {
            class: "rota-timeline default-shift-patterns",
            "data-rota-key": `rota-timeline-${index}`,
            key: `rota-timeline-${index}`
          }, [
            (_ctx.rotaSetting && _ctx.rotaSetting.default_cycle_id)
              ? (_openBlock(), _createBlock(_component_rota_timeline, {
                  key: 0,
                  view_mode: "home_default",
                  timeline_ref: `rota-timeline-${index}`,
                  existing_shifts: _ctx.defaultShifts,
                  show_add_modal: _ctx.sharedState.skeletonShift,
                  datasource: _ctx.sharedState.shifts,
                  can_edit: _ctx.canEditTimeline,
                  current_date: current_date.date,
                  duration: _ctx.sharedState.timeline.duration,
                  hide_dates: true,
                  hide_date_navigator: true,
                  height: "200px",
                  onInit: _ctx.saveSchedulerInstance,
                  onAdding: _ctx.adding,
                  onUpdating: _ctx.updating,
                  onDeleting: _ctx.deleting,
                  onConfigureForm: _ctx.buildForm
                }, {
                  "date-rotate": _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(current_date.week_day_short), 1)
                  ]),
                  resources: _withCtx(() => [
                    _createVNode(_component_DxResource, {
                      "use-color-as-default": true,
                      "data-source": _ctx.sharedState.shifts,
                      "field-expr": "id"
                    }, null, 8, ["data-source"])
                  ]),
                  _: 2
                }, 1032, ["timeline_ref", "existing_shifts", "show_add_modal", "datasource", "can_edit", "current_date", "duration", "onInit", "onAdding", "onUpdating", "onDeleting", "onConfigureForm"]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_3))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}