import { registerPalette } from 'devextreme/viz/palette'

export const registerDefaultDoughnutPalette = () => {
  registerPalette('default-doughnut', {
    simpleSet: ['#E6A770', '#7E2EB8', '#E15151'],
  })
}

export const registerDefaultBarChartPalette = () => {
  registerPalette('default-bar', {
    simpleSet: ['#193A66', '#0F233D'],
  })
}

export const registerDefaultLineChartPalette = () => {
  registerPalette('default-line', {
    simpleSet: ['#193A66', '#0F233D'],
  })
}
