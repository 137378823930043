
  import { defineComponent, reactive, PropType, computed, onMounted } from 'vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { useI18n } from 'vue-i18n'
  import SummaryDetails from '@/views/modules/care_groups/partials/Details/SummaryDetails.vue'
  import ContactDetails from '@/views/modules/care_groups/partials/Details/ContactDetails.vue'
  import Addresses from '@/views/modules/care_groups/partials/Details/Addresses.vue'
  import { useStore } from 'vuex'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import { cannotEditModule } from '@/composables/Permissions'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'

  export default defineComponent({
    props: {
      careGroup: {
        type: Object as PropType<CareGroup>,
        required: true,
      },
    },
    components: {
      CcButton,
      PageToolbar,
      SummaryDetails,
      ContactDetails,
      Addresses,
      Chips,
    },

    setup(props) {
      const store = useStore()
      props = reactive(props)
      const { t } = useI18n()
      const careGroup = computed(
        (): CareGroup => store.getters['careGroups/careGroup']
      )

      const state = reactive({
        activeChip: 'Summary',
        chips: ['Summary', 'Contact Details', 'Addresses'],
      })

      const payload = reactive({
        company_id: 0,
        company_name: '',
        company_type: 0,
        company_uses_fixed_ip: 0,
        company_primary_url: '',
        company_description: '',
        company_owner: 0,
        company_phone1: '',
        company_fax: '',
        company_email: '',
        company_address1: '',
        company_address2: '',
        company_city: '',
        company_state: '',
        company_zip: '',
      }) as Record<string, any>

      const chipClicked = (chip: string) => (state.activeChip = chip)
      const exportClicked = (option: string) => {
        if (option === 'Export To Excel') {
          return exportPageToExcel(payload, props.careGroup.company_name)
        }

        if (option === 'Export To PDF') {
          return exportPageToPdf(payload, props.careGroup.company_name)
        }
      }

      const buildPayload = (data: Record<string, any>) => {
        Object.keys(data).forEach((key) => {
          payload[key] = data[key]
        })
      }

      const saveCareGroup = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('careGroups/update', {
            ...payload,
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }
      const submitClicked = () => saveCareGroup()

      onMounted(() => {
        buildPayload({
          company_id: props.careGroup.company_id,
          company_name: props.careGroup.company_name,
          company_type: props.careGroup.company_type,
          company_uses_fixed_ip: props.careGroup.company_uses_fixed_ip,
          company_primary_url: props.careGroup.company_primary_url,
          company_description: props.careGroup.company_description,
          company_owner: props.careGroup.owner?.id,
          company_phone1: props.careGroup.company_phone1,
          company_fax: props.careGroup.company_fax,
          company_email: props.careGroup.company_email,
          company_address1: props.careGroup.company_address1,
          company_address2: props.careGroup.company_address2,
          company_city: props.careGroup.company_city,
          company_state: props.careGroup.company_state,
          company_zip: props.careGroup.company_zip,
        })
      })

      return {
        t,
        state,
        props,
        careGroup,
        payload,
        submitClicked,
        buildPayload,
        chipClicked,
        exportClicked,
        cannotEditModule,
      }
    },
  })
