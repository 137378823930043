import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FormTypeService from '@/services/forms/formTypes'

export type FormTypeGetter = GetterTree<FormTypeState, any>

export interface FormTypeState {
  formTypes: object[]
}

export const state: FormTypeState = {
  formTypes: [],
}

export const getters: FormTypeGetter = {
  formTypes: (state) => state.formTypes,
}

export const actions: ActionTree<FormTypeState, any> = {
  index: async (context, params) => {
    const response = await FormTypeService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setFormTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setFormTypes', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<FormTypeState> = {
  setFormTypes: (state, types) => {
    state.formTypes = types
  },
}

export const formTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
