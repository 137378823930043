
  import { defineComponent, PropType, reactive } from 'vue'
  import FieldPalette from './FieldPalette.vue'
  import FieldCanvas from './FieldCanvas.vue'
  import { FormField } from '@/types/modules/forms/FormField'
  import { RenderedFormFieldSchema } from '@/types/components/FormBuilder'

  export default defineComponent({
    components: {
      FieldPalette,
      FieldCanvas,
    },
    props: {
      fields: {
        type: Array as PropType<FormField[]>,
        required: false,
      },
    },
    emits: ['fields-changed', 'field-deleted'],
    setup(props, { emit }) {
      props = reactive(props)

      const fieldsChanged = (value: RenderedFormFieldSchema[]) =>
        emit('fields-changed', value)

      const fieldDeleted = (value: RenderedFormFieldSchema[]) =>
        emit('field-deleted', value)

      return {
        fieldsChanged,
        fieldDeleted,
        props,
      }
    },
  })
