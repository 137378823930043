
  import { defineComponent, reactive, PropType, computed } from 'vue'
  import DxRadioGroup from 'devextreme-vue/radio-group'
  import { RadioButton } from '@/types/components/RadioButton'
  import '@/assets/css/dev-extreme-radio-buttons.css'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      DxRadioGroup,
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      layout: {
        type: String,
        required: false,
        default: 'vertical',
        validator: (value: string) =>
          ['vertical', 'horizontal'].indexOf(value) !== -1,
      },
      buttons: {
        type: Array as PropType<RadioButton[]>,
        required: true,
      },
      modelValue: {
        type: [Number, String],
        required: false,
      },
    },
    emits: ['selectionChanged', 'update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const changeSelection = (event: HTMLInputElement) => {
        emit(
          'update:modelValue',
          typeof event.value === 'string' ? event.value : Number(event.value)
        )
      }

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-400': !props.isDisabled,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      return {
        props,
        changeSelection,
        labelClasses,
      }
    },
  })
