import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a723fee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "ml-auto w-max"
}
const _hoisted_3 = { class: "p-6 shadow-md border border-gray-100 my-6" }
const _hoisted_4 = { class: "flex items-center justify-between" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_rota_date_range = _resolveComponent("rota-date-range")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_copy_to_week = _resolveComponent("copy-to-week")!
  const _component_live_rota = _resolveComponent("live-rota")!
  const _component_default_shift_pattern = _resolveComponent("default-shift-pattern")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sharedState.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_cc_button, {
              key: tab,
              label: tab,
              type: _ctx.sharedState.activeTab === tab ? 'tab-active' : 'tab-inactive',
              onClick: () => (_ctx.sharedState.activeTab = tab)
            }, null, 8, ["label", "type", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    (_ctx.canEditModule('rotas'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_button_group, null, {
            default: _withCtx(() => [
              (
          _ctx.rotaSetting.default_cycle_id || _ctx.sharedState.activeTab === 'Live Rotas'
        )
                ? (_openBlock(), _createBlock(_component_cc_button, {
                    key: 0,
                    onClick: _ctx.setupAddModal,
                    icon: 'fa fa-plus',
                    type: "primary",
                    label: "Add shift"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.getCopyableShifts.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (
            _ctx.sharedState.activeTab === 'Default Shift Patterns' &&
            _ctx.rotaSetting.default_cycle_id &&
            !_ctx.isCycle('Weekly')
          )
                      ? (_openBlock(), _createBlock(_component_cc_button, {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.sharedState.showCopyToModal = true), ["prevent"])),
                          label: "Copy to",
                          type: "secondary",
                          icon: "fa fa-calendar-plus"
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              _createVNode(_component_cc_button, {
                onClick: _ctx.saveChanges,
                icon: "fa fa-save",
                type: "secondary",
                label: "Save Changes"
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.sharedState.activeTab === 'Live Rotas')
          ? (_openBlock(), _createBlock(_component_rota_date_range, {
              key: 0,
              "current-date": _ctx.sharedState.timeline.current_date,
              onChanged: _ctx.changeDate
            }, null, 8, ["current-date", "onChanged"]))
          : _createCommentVNode("", true),
        (_ctx.sharedState.activeTab === 'Default Shift Patterns')
          ? (_openBlock(), _createBlock(_component_select_input, {
              key: 1,
              "is-required": false,
              class: "w-64",
              modelValue: _ctx.rotaSetting.default_cycle_id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rotaSetting.default_cycle_id) = $event)),
              onChange: _ctx.cycleChanged,
              options: _ctx.computedRotaCycles,
              label: "Rota cycle",
              icons: [{ position: 'before', icon: 'fa fa-list' }]
            }, null, 8, ["modelValue", "onChange", "options"]))
          : _createCommentVNode("", true),
        (
          _ctx.sharedState.activeTab === 'Default Shift Patterns' &&
          _ctx.rotaSetting &&
          _ctx.rotaSetting.default_cycle_id &&
          !_ctx.isCycle('Weekly')
        )
          ? (_openBlock(), _createBlock(_component_select_input, {
              key: 2,
              isRequired: false,
              class: "w-64",
              modelValue: _ctx.rotaSetting.cycle_start_week,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rotaSetting.cycle_start_week) = $event)),
              options: _ctx.computedStartWeeks,
              label: "Current Week Cycle",
              icons: [{ position: 'before', icon: 'fa fa-list' }]
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true),
        (_ctx.shiftTypes)
          ? (_openBlock(), _createBlock(_component_multi_select, {
              key: 3,
              class: "w-64",
              "display-expr": 'text',
              "value-expr": 'text',
              label: "filter by shift type",
              "tag-items": _ctx.computedShiftTypes,
              modelValue: _ctx.sharedState.filtered_shift_types,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sharedState.filtered_shift_types) = $event))
            }, null, 8, ["tag-items", "modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (
      _ctx.sharedState.showCopyToModal &&
      _ctx.sharedState.activeTab == 'Default Shift Patterns' &&
      _ctx.rotaSetting.default_cycle_id &&
      !_ctx.isCycle('Weekly')
    )
      ? (_openBlock(), _createBlock(_component_copy_to_week, {
          key: 1,
          weeks: Array.from({ length: _ctx.totalSteps }, (v, k) => k + 1),
          shifts: _ctx.getCopyableShifts,
          project: _ctx.project,
          onCopy: _ctx.copyShifts,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sharedState.showCopyToModal = false))
        }, null, 8, ["weeks", "shifts", "project", "onCopy"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.sharedState.activeTab === 'Live Rotas')
        ? (_openBlock(), _createBlock(_component_live_rota, {
            key: _ctx.sharedState.activeTab,
            project: _ctx.props.project
          }, null, 8, ["project"]))
        : (_ctx.sharedState.activeTab === 'Default Shift Patterns')
          ? (_openBlock(), _createBlock(_component_default_shift_pattern, {
              key: _ctx.sharedState.activeTab,
              project: _ctx.props.project
            }, null, 8, ["project"]))
          : _createCommentVNode("", true)
    ])
  ], 64))
}