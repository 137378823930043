import { GetterTree, ActionTree, MutationTree } from 'vuex'
import TaskLogsService from '@/services/tasks/taskLogs'
import { TaskLog } from '@/types/modules/tasks/TaskLog'

export type TaskLogGetter = GetterTree<TaskLogState, any>

export interface TaskLogState {
  taskLogs: TaskLog[]
  taskLog: TaskLog | null
  logDetails: object | null
}

export const state: TaskLogState = {
  taskLogs: [],
  taskLog: null,
  logDetails: null,
}

export const getters: TaskLogGetter = {
  taskLogs: (state) => state.taskLogs,
  taskLog: (state) => state.taskLog,
  logDetails: (state) => state.logDetails,
}

export const actions: ActionTree<TaskLogState, any> = {
  index: async (context, params) => {
    let response = await TaskLogsService.index(params)

    if (response.success && params.per_page) {
      context.commit('setLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setLogs', response.data)

      return response
    }

    return response
  },
  store: (_context, payload) => {
    return Promise.resolve(TaskLogsService.store(payload))
  },
  getLatestLog: (context, id) => {
    let results = Promise.resolve(TaskLogsService.getLatestLog(id))

    results.then((response) => {
      if (response.success) {
        context.commit('setLog', response.data)
      }
    })

    return results
  },
  getLogDetails: (context, id) => {
    let results = Promise.resolve(TaskLogsService.getLogDetails(id))

    results.then((response) => {
      if (response.success) {
        context.commit('setLogDetails', response.data)
      }
    })

    return results
  },
  moveTaskLogs: (_context, payload) => {
    return Promise.resolve(TaskLogsService.moveTaskLogs(payload))
  },
  getSensitiveLogs: async (context, params) => {
    const response = await TaskLogsService.getSensitiveLogs(params)

    if (response.success && params?.per_page) {
      context.commit('setLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setLogs', response.data)

      return response
    }

    return response
  },
  updateLogStatus: (_context, payload) => {
    return Promise.resolve(TaskLogsService.updateLogStatus(payload))
  },
}

export const mutations: MutationTree<TaskLogState> = {
  setLogs: (state, logs: TaskLog[]) => {
    state.taskLogs = logs
  },
  setLog: (state, log: TaskLog) => (state.taskLog = log),
  setLogDetails: (state, details) => {
    state.logDetails = details
  },
}

export const taskLogs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
