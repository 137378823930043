import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_settings = _resolveComponent("settings")!
  const _component_audit = _resolveComponent("audit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isTab('users'))
      ? (_openBlock(), _createBlock(_component_data_grid, {
          key: 0,
          columns: $setup.usersColumns,
          action: "users/index"
        }, null, 8, ["columns"]))
      : _createCommentVNode("", true),
    ($setup.isTab('roles'))
      ? (_openBlock(), _createBlock(_component_data_grid, {
          key: 1,
          columns: $setup.rolesColumns,
          action: "roles/all"
        }, null, 8, ["columns"]))
      : _createCommentVNode("", true),
    ($setup.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_settings, { key: 2 }))
      : _createCommentVNode("", true),
    ($setup.isTab('audit'))
      ? (_openBlock(), _createBlock(_component_audit, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}