<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :icons="[{ icon: 'fa fa-lock', position: 'before' }]"
          :isDisabled="true"
          :label="t('ypDetails.summary.id')"
          v-model="summary.id"
        />
        <text-input
          :label="t('ypDetails.summary.fullName')"
          v-model="summary.young_person_name"
        />
        <text-input
          :label="t('ypDetails.summary.preferredName')"
          v-model="summary.preferred_name"
        />
        <select-input
          :label="t('ypDetails.summary.careHome')"
          v-model="summary.care_home"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <select-input
          :label="t('ypDetails.summary.region')"
          v-model="summary.region"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <text-input
          :label="t('ypDetails.summary.roomNum')"
          v-model="summary.room_number"
        />
        <date-input
          :label="t('ypDetails.summary.birthDate')"
          v-model="summary.dob"
        />
        <text-input :label="t('ypDetails.summary.age')" v-model="summary.age" />
        <select-input
          :label="t('ypDetails.summary.gender')"
          v-model="summary.gender"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <select-input
          :label="t('ypDetails.summary.ethnicity')"
          v-model="summary.ethnicity"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <select-input
          :label="t('ypDetails.summary.religion')"
          v-model="summary.religion"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <text-box
          lines="3"
          :label="t('ypDetails.summary.extraInfo')"
          :isRequired="false"
          v-model="summary.extra_information"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <date-input
          :label="t('ypDetails.summary.ccStartDate')"
          v-model="summary.start_date"
        />
        <date-input
          :label="t('ypDetails.summary.ccEndDate')"
          v-model="summary.end_date"
        />
        <date-input
          :label="t('ypDetails.summary.admissionDate')"
          v-model="summary.joined"
        />
        <date-input
          :label="t('ypDetails.summary.placementEndDate')"
          v-model="summary.left"
        />
        <text-input
          :label="t('ypDetails.summary.ypAdmin')"
          v-model="summary.yp_admin"
        />
        <text-input
          :label="t('ypDetails.summary.keyWorker')"
          v-model="summary.key_worker"
        />
        <select-input
          :label="t('ypDetails.summary.CorrespondingUser')"
          v-model="summary.corresponding_user"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
      SelectInput,
      DateInput,
      TextBox,
    },
    setup() {
      const { t } = useI18n()
      let summary = {
        id: '1234',
        young_person_name: 'Current Young Person',
        preferred_name: 'Linda',
        care_home: 'Hilltop View',
        region: 'Region name',
        room_number: '42',
        dob: '2012-08-09',
        age: '10',
        gender: 'social construct',
        ethnicity: 'Jackie',
        religion: 'Aetheist',
        extra_information: 'Lorem ipsum here',
        start_date: '2000-08-09',
        end_date: '',
        joined: '2000-08-09',
        left: '',
        yp_admin: 'Helen Richards',
        key_worker: 'Steven',
        corresponding_user: 'hello',
      }

      return {
        summary,
        t,
      }
    },
  })
</script>
