
  import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { FileCategory } from '@/types/modules/files/FileCategory'
  import { TaskFile } from '@/types/modules/tasks/TaskFile'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore()
      const query = ref({})
      const state = reactive({
        activeChip: 'all' as string,
      })

      const action = computed(() => {
        return props.sensitive
          ? 'tasks/getSensitiveTaskAttachments'
          : 'tasks/getTaskAttachments'
      })

      const categories = computed(() => {
        return store.getters['fileCategories/categories']?.map(
          (category: FileCategory) => category.name
        )
      })

      const chips = computed((): string[] => {
        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { category: state.activeChip }),
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'Task ID',
          dataField: 'task_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'File Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'category.name',
          caption: 'Category',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'mime_type',
          caption: 'File Type',
          calculateCellValue: (row: TaskFile) => {
            return row.readable_type
          },
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('fileCategories/index', {
            only: ['has_active_task_files'],
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        chips,
        state,
        query,
        action,
        columns,
        categories,
        chipClicked,
      }
    },
  })
