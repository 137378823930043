import NotFound from '@/views/errors/404.vue'
import Forbidden from '@/views/errors/403.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'

export default [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'Not Found',
      middleware: [auth],
    },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    meta: {
      title: 'Forbidden',
      middleware: [auth],
    },
  },
] as RouteRecordRaw[]
