import AttachmentsIndex from '../views/modules/attachments/Index.vue'
import auth from '@/middleware/auth/auth'
import { RouteRecordRaw } from 'vue-router'
import { canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/attachments',
    name: 'AttachmentsIndex',
    component: AttachmentsIndex,
    meta: {
      title: 'Attachments',
      module: 'files',
      middleware: [auth, canView],
    },
  },
] as RouteRecordRaw[]
