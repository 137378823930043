import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: _ctx.state.action,
    "query-filter": { relations: ['created_by'] },
    columns: _ctx.columns,
    "read-only": true,
    chips: ['Care Group', 'Stakeholders'],
    onChipClicked: _ctx.chipClicked
  }, null, 8, ["action", "columns", "onChipClicked"]))
}