import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input", true)!

  return (_openBlock(), _createBlock(_component_date_input, {
    min: _ctx.min,
    max: _ctx.max,
    id: _ctx.props.fieldAttributes.id,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    isRequired: _ctx.props.fieldAttributes.required ? true : false,
    icons: [{ position: 'before', icon: 'far fa-calendar' }],
    label: _ctx.label ? _ctx.label : undefined,
    message: 
      _ctx.props.fieldAttributes.instructions
        ? _ctx.props.fieldAttributes.instructions
        : undefined
    ,
    autocomplete: false,
    error: _ctx.errors
  }, null, 8, ["min", "max", "id", "modelValue", "isRequired", "label", "message", "error"]))
}