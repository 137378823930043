
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    computed,
    watch,
  } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
    },
    props: {
      steps: {
        type: Array as PropType<string[]>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const state = reactive({
        current_step: '',
        previous_steps: [] as string[],
      })

      const goToStep = (step: string) => {
        state.current_step = step
        let currentStepIndex = props.steps.findIndex(
          (searchStep: string) => searchStep === state.current_step
        )
        state.previous_steps = props.steps.filter(
          (step: string, index: number) => {
            step
            return currentStepIndex > index
          }
        )
      }

      watch(
        () => state.current_step,
        () => {
          window.scrollTo(0, 0)
        }
      )

      const isCurrentStep = computed((step: string) => {
        return step === state.current_step
      })

      const goToNextStep = () => {
        let currentStepIndex = props.steps.findIndex(
          (searchStep: string) => searchStep === state.current_step
        )

        // @ts-ignore - Need the index not the step. TS complains step is unused
        let nextStep = props.steps.find((step: string, index: number) => {
          return index > currentStepIndex
        })

        if (nextStep) {
          goToStep(nextStep)
        }
      }

      const goBackStep = () => {
        let lastStep = state.previous_steps[state.previous_steps.length - 1]
        goToStep(lastStep)
      }

      onMounted(() => (state.current_step = props.steps[0]))

      return {
        props,
        state,
        goToStep,
        isCurrentStep,
        goBackStep,
        goToNextStep,
      }
    },
  })
