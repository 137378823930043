import { GetterTree, MutationTree, ActionTree } from 'vuex'
import KeywordService from '@/services/forms/keywords'
import { FormKeyword } from '@/types/modules/forms/FormKeyword'

export type KeywordGetter = GetterTree<KeywordState, any>

export interface KeywordState {
  keywords: FormKeyword[]
  keyword: FormKeyword | null
}

export const state: KeywordState = {
  keywords: [],
  keyword: null,
}

export const getters: KeywordGetter = {
  getKeywords: (state) => state.keywords,
  getKeyword: (state) => state.keyword,
}

export const actions: ActionTree<KeywordState, any> = {
  index: async (context, params) => {
    const response = await KeywordService.index(params)

    if (response.success && params.per_page) {
      context.commit('setFormKeywords', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setFormKeywords', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(KeywordService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setFormKeyword', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(KeywordService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(KeywordService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(KeywordService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(KeywordService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(KeywordService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(KeywordService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(KeywordService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<KeywordState> = {
  setFormKeywords: (state, keywords: FormKeyword[]) =>
    (state.keywords = keywords),
  setFormKeyword: (state, keyword: FormKeyword) => (state.keyword = keyword),
}

export const keywords = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
