<template>
  <div>
    <input-label
      :label="label"
      :error="error"
      :is-required="isRequired"
      :is-disabled="isDisabled"
    />
    <div v-for="option in options" :key="option">
      <input
        class="h-4 w-4 mt-1 align-top mr-2 cursor-pointer"
        type="checkbox"
        :disabled="isDisabled"
        :id="option.id"
        v-model="state.options[option.id]"
        @click="$emit('option-clicked', option.id, state.options[option.id])"
      />
      <label :for="option.id">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>
<script>
  import { defineComponent, reactive } from 'vue'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      InputLabel,
    },
    props: {
      label: {
        type: String,
        required: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      options: {
        type: Array,
        required: true,
      },
    },
    emits: ['option-clicked'],
    setup(props) {
      const state = reactive({ options: {} })
      props.options.forEach((option) => {
        state.options[option.id] = false
      })

      return {
        state,
      }
    },
  })
</script>
