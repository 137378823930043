import { EnComponents } from './components'
import EnLogin from './views/Login/en'
import EnAuth from './views/Auth/en'
import EnTerms from './views/Terms/en'
import EnDashboard from './views/Dashboard/en'
import EnOverview from './views/Overview/en'
import EnCareGroups from './views/CareGroups/en'
import EnCareGroupDetails from './views/CareGroups/CareGroupDetails/en'
import EnCareGroupStakeholders from './views/CareGroups/CareGroupStakeholders/en'
import EnCareGroupBranding from './views/CareGroups/CareGroupBranding/en'
import EnCareGroupAttachments from './views/CareGroups/CareGroupAttachments/en'
import EnHomes from './views/projects/Homes/en'
import EnFilingCabinet from './views/projects/partials/FilingCabinet/en'
import EnHomeDetails from './views/projects/Homes/HomeDetails/en'
import EnMars from './views/Mars/en'
import EnSchools from './views/projects/Schools/en'
import EnSchoolDetails from './views/projects/Schools/SchoolDetails/en'
import EnUserPermissions from './views/projects/partials/UserPermissions/en'
import EnProjectAssociations from './views/projects/partials/ProjectAssociations/en'
import EnYoungPeople from './views/projects/YoungPeople/en'
import EnYoungPeopleDetails from './views/projects/YoungPeople/YoungPersonDetails/en'
import EnYoungPeopleRelationships from './views/projects/YoungPeople/YoungPersonRelationships/en'
import EnYoungPeoplePhotos from './views/projects/YoungPeople/YoungPersonPhotos/en'
import EnEmployees from './views/projects/Employees/en'
import EnEmployeeDetails from './views/projects/Employees/EmployeeDetails/en'
import EnEmployeeQualifications from './views/projects/Employees/EmployeeQualifications/en'
import EnEmployeerecruitmentChecks from './views/projects/Employees/EmployeeRecruitmentChecks/en'
import EnEmployeeContract from './views/projects/Employees/EmployeeContract/en'
import EnEmployeeReports from './views/projects/Employees/EmployeeReports/en'
import EnVehicles from './views/projects/Vehicles/en'
import EnVehicleDetails from './views/projects/Vehicles/VehicleDetails/en'
import EnSensitive from './views/Sensitive/en'
import EnTaskLogs from './views/Sensitive/TaskLogs/en'
import EnForms from './views/Forms/Forms/en'
import EnTaskExplorer from './views/TaskExplorer/en'
import EnDocumentAcknowledgements from './views/Documents/Acknowledgements/en'
import EnReporting from './views/Reporting/en'

export const messages = {
  en: {
    components: EnComponents,
    ...EnLogin,
    ...EnAuth,
    ...EnTerms,
    ...EnDashboard,
    ...EnOverview,
    ...EnCareGroups,
    ...EnCareGroupDetails,
    ...EnCareGroupStakeholders,
    ...EnCareGroupBranding,
    ...EnCareGroupAttachments,
    ...EnHomes,
    ...EnFilingCabinet,
    ...EnHomeDetails,
    ...EnMars,
    ...EnSchools,
    ...EnSchoolDetails,
    ...EnUserPermissions,
    ...EnProjectAssociations,
    ...EnYoungPeople,
    ...EnYoungPeopleDetails,
    ...EnYoungPeopleRelationships,
    ...EnYoungPeoplePhotos,
    ...EnEmployees,
    ...EnEmployeeDetails,
    ...EnEmployeeQualifications,
    ...EnEmployeerecruitmentChecks,
    ...EnEmployeeContract,
    ...EnEmployeeReports,
    ...EnVehicles,
    ...EnVehicleDetails,
    ...EnSensitive,
    ...EnTaskLogs,
    ...EnForms,
    ...EnTaskExplorer,
    ...EnDocumentAcknowledgements,
    ...EnReporting,
  },
}
