
  import { defineComponent, onMounted, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import EventForm from './partials/EventForm.vue'

  export default defineComponent({
    components: {
      EventForm,
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      props = reactive(props)

      const createEvent = async (payload: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        await store
          .dispatch('calendar/createEvent', payload)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)

            if (response.success) {
              router.push({ name: 'Calendar' })
            }
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Calendar', url: '/calendar' },
            { name: 'Create Event', url: '/calendar/event/create' },
          ],
        })
        store.commit('genericStore/setTabs', { tabs: [] })
        store.dispatch('genericStore/showPageLoader', false)
      })

      return {
        createEvent,
      }
    },
  })
