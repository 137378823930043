
  import { defineComponent, PropType, reactive, Ref, ref } from 'vue'
  import { registerDefaultBarChartPalette } from '@/composables/Charts'
  import {
    DxValueAxis,
    DxLabel,
    DxChart,
    DxSeries,
    DxLegend,
    DxFont,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
  } from 'devextreme-vue/chart'
  import { ColumnChart } from '@/types/components/Charts'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    components: {
      DxCommonSeriesSettings,
      DxSeriesTemplate,
      DxValueAxis,
      DxLabel,
      DxFont,
      DxLegend,
      DxChart,
      DxSeries,
      DxArgumentAxis,
    },
    props: {
      data: {
        type: Array as PropType<ColumnChart[]>,
        required: true,
      },
    },
    emits: ['series-clicked'],
    setup(props, { emit }) {
      props = reactive(props)
      const router = useRouter()
      const columnChart = ref() as Ref<DxChart>
      registerDefaultBarChartPalette()

      const seriesClicked = (data: any) => {
        emit('series-clicked', data.target.name)

        router.push({
          name: 'TasksOverview',
          query: {
            status: data.status,
          },
        })
      }

      return {
        columnChart,
        props,
        seriesClicked,
      }
    },
  })
