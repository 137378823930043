
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { format } from 'date-fns'
  import { defineComponent, onMounted, PropType, reactive, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'

  export default defineComponent({
    props: {
      careGroup: {
        type: Object as PropType<CareGroup>,
        required: true,
      },
    },
    components: {
      TextBox,
      TextInput,
      SelectInput,
      RadioButton,
    },
    emits: ['payload-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const { t } = useI18n()

      const payload = reactive({
        company_id: 0,
        company_name: '',
        company_type: 0,
        company_uses_fixed_ip: 0,
        company_primary_url: null as string | null,
        company_description: '',
        last_updated_timestamp: '',
        last_updated_by: undefined as string | undefined,
      })

      const setDetails = () => {
        payload.company_id = props.careGroup.company_id
        payload.company_name = props.careGroup.company_name
        payload.company_type = props.careGroup.company_type
        payload.company_uses_fixed_ip = props.careGroup.company_uses_fixed_ip
        payload.company_primary_url = props.careGroup.company_primary_url
        payload.company_description = props.careGroup.company_description
        payload.last_updated_timestamp = props.careGroup.last_updated_timestamp
          ? format(
              new Date(props.careGroup.last_updated_timestamp),
              'dd/MM/yyyy K:mma'
            )
          : ''
        payload.last_updated_by = props.careGroup.last_updated_by?.username
      }

      watch(
        () => payload,
        () => emit('payload-updated', payload),
        { deep: true }
      )

      onMounted(() => {
        setDetails()
      })

      return {
        t,
        props,
        payload,
      }
    },
  })
