
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { defineComponent, reactive, computed } from 'vue'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      regionId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const columns = computed((): Column[] => {
        return [
          {
            caption: 'ID',
            dataField: 'id',
            allowGrouping: false,
            dataType: 'number',
            visible: false,
          },
          {
            allowFiltering: false,
            allowSorting: false,
            dataField: 'full_name',
            caption: 'Full Name',
            allowGrouping: false,
          },
          {
            dataField: 'username',
            caption: 'Username',
            allowGrouping: false,
          },
          {
            dataField: 'password_expires_at',
            dataType: 'date',
            format: 'dd/MM/yyyy',
            caption: 'Password Expires On',
            allowGrouping: true,
          },
          {
            calculateCellValue: (row: any) => {
              return row.account_is_locked ? 'Yes' : 'No'
            },
            caption: 'Account Locked',
            allowGrouping: true,
          },
          {
            calculateCellValue: (row: any) => {
              return row.is_active ? 'Yes' : 'No'
            },
            caption: 'Active',
            allowGrouping: true,
          },
        ]
      })
      const filters = computed(() => {
        return {
          region_id: props.regionId,
        }
      })

      return {
        props,
        columns,
        filters,
      }
    },
  })
