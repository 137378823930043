
  import { defineComponent, PropType, reactive } from 'vue'
  import DxTreeView from 'devextreme-vue/tree-view'
  import { TreeView } from '@/types/components/TreeView'

  export default defineComponent({
    components: {
      DxTreeView,
    },
    props: {
      items: {
        type: Array as PropType<TreeView[]>,
        required: true,
      },
    },
    emits: ['item-clicked'],
    setup(props, { emit }) {
      props = reactive(props)
      const itemClicked = (item: TreeView) => emit('item-clicked', item)
      return {
        props,
        itemClicked,
      }
    },
  })
