
  import { Crumb } from '@/types/components/Crumb'
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    watch,
  } from 'vue'
  import { useStore } from 'vuex'
  import { MenuTab } from '@/types/components/MenuTab'
  import { useRoute, useRouter } from 'vue-router'
  import { isTab } from '@/composables/Generic'
  import ViewReport from '@/views/modules/reporting/Published/partials/ViewReport.vue'
  import ManageComments from '@/views/modules/reporting/Published/Comments/Index.vue'

  export default defineComponent({
    components: { ViewReport, ManageComments },
    props: {
      id: {
        type: String,
        required: true,
      },
      publishedReportId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const report = computed(
        () => store.getters['publishedBespokeReports/report']
      )
      const tabs = reactive<MenuTab[]>([
        {
          name: 'Report',
          url: `${route.path}?tab=report`,
        },
        {
          name: 'Comments',
          url: `${route.path}?tab=comments`,
        },
      ])
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Published Reports',
          url: `/bespoke-reporting/${props.id}/published-reports`,
        },
      ])

      watch(
        () => route.query.tab,
        () => {
          if (route.query.tab) {
            if (crumbs.length > 3) crumbs.splice(-1, 1)
            crumbs.push({
              name: tabs.find((tab) => tab.url === route.fullPath)?.name!,
              url: route.fullPath,
            })
          }
        },
        { immediate: true }
      )

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: tabs })
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (!route.query.tab) {
          router.replace({ query: { tab: 'report' } })
        }

        if (!report.value) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('publishedBespokeReports/show', props.publishedReportId)
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      onUnmounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs: [] })
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('publishedBespokeReports/setPublishedBespokeReport', null)
      })

      return {
        props,
        report,
        isTab,
      }
    },
  })
