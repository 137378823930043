import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-1/2" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "w-1/2" }
const _hoisted_4 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_text_input, {
          modelValue: _ctx.payload.company_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.company_id) = $event)),
          isDisabled: true,
          label: _ctx.t('careGroupDetails.id'),
          icons: [
          {
            icon: 'far fa-lock',
            position: 'before',
          },
        ]
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_text_input, {
          label: _ctx.t('careGroupDetails.name'),
          modelValue: _ctx.payload.company_name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.company_name) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_select_input, {
          label: _ctx.t('careGroupDetails.type'),
          modelValue: _ctx.payload.company_type,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.company_type) = $event)),
          options: [
          { label: 'Private', value: 0 },
          { label: 'Public', value: 1 },
        ]
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_radio_button, {
          label: _ctx.t('careGroupDetails.fixedIp'),
          layout: "horizontal",
          modelValue: _ctx.payload.company_uses_fixed_ip,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.company_uses_fixed_ip) = $event)),
          buttons: [
          { id: 0, text: 'Yes' },
          { id: 1, text: 'No' },
        ]
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_text_input, {
          isRequired: false,
          label: _ctx.t('careGroupDetails.website'),
          modelValue: _ctx.payload.company_primary_url,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.company_primary_url) = $event))
        }, null, 8, ["label", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_text_box, {
          lines: "3",
          label: _ctx.t('careGroupDetails.description'),
          isRequired: false,
          modelValue: _ctx.payload.company_description,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.company_description) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_text_input, {
          isDisabled: true,
          label: _ctx.t('careGroupDetails.updatedOn'),
          modelValue: _ctx.payload.last_updated_timestamp,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.last_updated_timestamp) = $event)),
          icons: [{ position: 'before', icon: 'far fa-lock' }]
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_text_input, {
          isDisabled: true,
          modelValue: _ctx.payload.last_updated_by,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.last_updated_by) = $event)),
          label: _ctx.t('careGroupDetails.updatedBy'),
          icons: [{ icon: 'far fa-lock', position: 'before' }]
        }, null, 8, ["modelValue", "label"])
      ])
    ])
  ], 64))
}