import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end my-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "border-r mr-2.5" }
const _hoisted_4 = { class: "h-max border border-gray-100 p-6 shadow-md space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_toggle = _resolveComponent("toggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cc_button, {
            label: "Back",
            type: "tertiary",
            icon: "fa fa-arrow-left",
            "secondary-icon": "none",
            onClick: _ctx.goBack
          }, null, 8, ["onClick"])
        ]),
        _createVNode(_component_page_toolbar, {
          onSubmitClicked: _ctx.submitClicked,
          onExportClicked: _ctx.exportClicked
        }, null, 8, ["onSubmitClicked", "onExportClicked"])
      ])
    ]),
    _createVNode(_component_validation_errors, {
      errors: _ctx.state.errors
    }, null, 8, ["errors"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_input, {
        "is-disabled": true,
        "is-required": true,
        icons: [{ position: 'before', icon: 'fa fa-lock' }],
        label: "Home / School",
        modelValue: _ctx.payload.association_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.association_name) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_date_input, {
        "is-required": true,
        label: "Admission Date",
        error: _ctx.state.errors.admission_date,
        modelValue: _ctx.payload.admission_date,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.admission_date) = $event))
      }, null, 8, ["error", "modelValue"]),
      _createVNode(_component_date_input, {
        "is-required": false,
        label: "Leaving Date",
        modelValue: _ctx.payload.leaving_date,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.leaving_date) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_toggle, {
        label: "Set as default association",
        "is-disabled": _ctx.association?.is_default,
        title: 
        _ctx.payload.is_default ? 'A default association must be set' : undefined
      ,
        toggle: _ctx.payload.is_default,
        onInputToggled: _ctx.inputToggled
      }, null, 8, ["is-disabled", "title", "toggle", "onInputToggled"])
    ])
  ], 64))
}