import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_care_group_attachments = _resolveComponent("care-group-attachments")!
  const _component_care_group_stakeholders = _resolveComponent("care-group-stakeholders")!
  const _component_care_group_branding = _resolveComponent("care-group-branding")!
  const _component_care_group_details = _resolveComponent("care-group-details")!
  const _component_homes_table = _resolveComponent("homes-table")!
  const _component_schools_table = _resolveComponent("schools-table")!
  const _component_employees_table = _resolveComponent("employees-table")!
  const _component_young_person_table = _resolveComponent("young-person-table")!
  const _component_vehicles_table = _resolveComponent("vehicles-table")!
  const _component_care_group_audit = _resolveComponent("care-group-audit")!
  const _component_care_group_settings = _resolveComponent("care-group-settings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('attachments'))
      ? (_openBlock(), _createBlock(_component_care_group_attachments, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('stakeholders'))
      ? (_openBlock(), _createBlock(_component_care_group_stakeholders, {
          key: 1,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('branding'))
      ? (_openBlock(), _createBlock(_component_care_group_branding, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('details') && _ctx.careGroup)
      ? (_openBlock(), _createBlock(_component_care_group_details, {
          key: 3,
          "care-group": _ctx.careGroup
        }, null, 8, ["care-group"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('homes'))
      ? (_openBlock(), _createBlock(_component_homes_table, {
          key: 4,
          filters: _ctx.careGroupIdFilter,
          "read-only": true
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('schools'))
      ? (_openBlock(), _createBlock(_component_schools_table, {
          key: 5,
          filters: _ctx.careGroupIdFilter,
          "read-only": true
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('employees'))
      ? (_openBlock(), _createBlock(_component_employees_table, {
          key: 6,
          "read-only": true,
          filters: _ctx.careGroupIdFilter
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('yp'))
      ? (_openBlock(), _createBlock(_component_young_person_table, {
          key: 7,
          filters: _ctx.careGroupIdFilter,
          "read-only": true
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('vehicles'))
      ? (_openBlock(), _createBlock(_component_vehicles_table, {
          key: 8,
          filters: _ctx.careGroupIdFilter,
          "read-only": true
        }, null, 8, ["filters"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('audit'))
      ? (_openBlock(), _createBlock(_component_care_group_audit, { key: 9 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_care_group_settings, { key: 10 }))
      : _createCommentVNode("", true)
  ]))
}