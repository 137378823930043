
  import { Resident } from '@/types/modules/projects/Resident'
  import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
  import CcCalendar from '@/components/Generic/Calendar/Calendar.vue'
  import { useStore } from 'vuex'
  import { CalendarEventType } from '@/types/modules/calendar/CalendarEventType'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      CcCalendar,
      CcButton,
    },
    props: {
      youngPerson: {
        type: Object as PropType<Resident>,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      props = reactive(props)

      const medicationEventTypes = computed(
        () => store.getters['eventTypes/getEventTypes']
      )

      const filters = computed(() => {
        return {
          projects: [props.youngPerson.project_id],
          event_types: medicationEventTypes.value.map(
            (type: CalendarEventType) => type.id
          ),
        }
      })

      const addEvent = () =>
        router.push({ name: 'CalendarEventCreate', query: { module: 'mars' } })

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('eventTypes/index', {
            module: 'mars',
          })
          .then(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      })

      return {
        filters,
        props,
        medicationEventTypes,
        addEvent,
      }
    },
  })
