
  import { defineComponent, PropType, reactive } from 'vue'
  import DropDown from '../Dropdown/DropDown.vue'
  import CcButton from './Button.vue'
  import DropdownItem from '../Dropdown/DropdownItem.vue'

  export default defineComponent({
    components: {
      DropDown,
      CcButton,
      DropdownItem,
    },
    props: {
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
    },
    emits: ['option-clicked'],
    setup(props, { emit }) {
      props = reactive(props)
      const optionClicked = (option: string) => {
        emit('option-clicked', option)
      }

      return {
        props,
        optionClicked,
      }
    },
  })
