import { ActionTree, GetterTree, MutationTree } from 'vuex'
import RotaSettings from '@/services/rotas/RotaSettings'
import { RotaSetting } from '@/types/modules/rotas/RotaSetting'
import { RotaCycle } from '@/types/modules/rotas/RotaCycle'

export type RottaSettingGetter = GetterTree<RottaSettingState, any>

export interface RottaSettingState {
  rotaSetting: RotaSetting | null
  currentWeekCycle: RotaCycle | null
}

export const state: RottaSettingState = {
  rotaSetting: null,
  currentWeekCycle: null,
}

export const getters: RottaSettingGetter = {
  getRotaSetting: (state) => state.rotaSetting,
  getCurrentWeekCycle: (state) => state.currentWeekCycle,
}

export const actions: ActionTree<RottaSettingState, any> = {
  show: (context, id) => {
    let results = Promise.resolve(RotaSettings.show(id))

    results.then((response) => {
      console.log(response.message)
      context.commit('setRotaSetting', response.data)
    })

    return results
  },
  getCurrentWeekCycle: (context, params) => {
    let results = Promise.resolve(
      RotaSettings.getCurrentWeekCycle(params.project_id, params)
    )

    results.then((response) => {
      console.log(response.message)
      context.commit('setCurrentWeekCycle', response.data)
    })

    return results
  },
  // @ts-ignore
  update: (context, params) => {
    let results = Promise.resolve(
      RotaSettings.update(params.project_id, params)
    )

    return results
  },
}

export const mutations: MutationTree<RottaSettingState> = {
  setRotaSetting: (state, setting) => (state.rotaSetting = setting),
  setCurrentWeekCycle: (state, cycle) => (state.currentWeekCycle = cycle),
}

export const rotaSettings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
