import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!

  return (_ctx.props.showButton)
    ? (_openBlock(), _createBlock(_component_cc_button, {
        key: 0,
        type: "tertiary",
        "tertiary-color": "gray",
        "secondary-icon": "none",
        label: _ctx.state.expanded ? 'Collapse' : 'Expand',
        icon: _ctx.state.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down',
        onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.expanded = !_ctx.state.expanded))
      }, null, 8, ["label", "icon"]))
    : _createCommentVNode("", true)
}