import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ProjectTypeAttributeService from '@/services/projects/projectTypeAttributes'
import { ProjectTypeAttribute } from '@/types/modules/projects/ProjectTypeAttribute'
import { FieldGroup } from '@/types/modules/forms/FieldGroup'

export type ProjectTypeAttributeGetter = GetterTree<
  ProjectTypeAttributeState,
  any
>

export interface ProjectTypeAttributeState {
  projectTypeAttributes: ProjectTypeAttribute[]
  projectTypeAttribute: ProjectTypeAttribute | null
  fields: FieldGroup[]
}

export const state: ProjectTypeAttributeState = {
  projectTypeAttributes: [],
  projectTypeAttribute: null,
  fields: [],
}

export const getters: ProjectTypeAttributeGetter = {
  getProjectTypeAttributes: (state) => state.projectTypeAttributes,
  getProjectTypeAttribute: (state) => state.projectTypeAttribute,
  getAllowedFields: (state) => state.fields,
}

export const actions: ActionTree<ProjectTypeAttributeState, any> = {
  index: async (context, params) => {
    const response = await ProjectTypeAttributeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setProjectTypeAttributes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setProjectTypeAttributes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      ProjectTypeAttributeService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setProjectTypeAttribute', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(ProjectTypeAttributeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      ProjectTypeAttributeService.update(payload.id, payload)
    )
  },
  delete: (_context, id) => {
    return Promise.resolve(ProjectTypeAttributeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(ProjectTypeAttributeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(ProjectTypeAttributeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(ProjectTypeAttributeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      ProjectTypeAttributeService.toggleManyVisibility(params)
    )
  },
  getAllowedFields: (context) => {
    const result = Promise.resolve(
      ProjectTypeAttributeService.getAllowedFields()
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setAllowedFields', response.data)
      }
    })

    return result
  },
}

export const mutations: MutationTree<ProjectTypeAttributeState> = {
  setProjectTypeAttributes: (state, attributes: ProjectTypeAttribute[]) =>
    (state.projectTypeAttributes = attributes),
  setProjectTypeAttribute: (state, attribute: ProjectTypeAttribute) =>
    (state.projectTypeAttribute = attribute),
  setAllowedFields: (state, fields: FieldGroup[]) => {
    state.fields = fields
  },
}

export const projectTypeAttributes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
