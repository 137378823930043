import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-8 p-6 shadow-md border border-gray-100 font-display" }
const _hoisted_2 = { class: "flex items-center justify-between mb-6" }
const _hoisted_3 = { class: "font-bold text-xl" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "grid grid-cols-2 gap-2" }
const _hoisted_6 = { class: "col-span-1" }
const _hoisted_7 = { class: "grid grid-cols-5 gap-2" }
const _hoisted_8 = { class: "col-span-2 space-y-3" }
const _hoisted_9 = { class: "flex items-center flex-wrap space-x-2" }
const _hoisted_10 = {
  key: 1,
  class: "text-gray-500 font-semibold text-sm"
}
const _hoisted_11 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_12 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_13 = { class: "col-span-3 space-y-3" }
const _hoisted_14 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_15 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_16 = { class: "flex items-center flex-wrap space-x-2" }
const _hoisted_17 = {
  key: 1,
  class: "text-gray-500 font-semibold text-sm"
}
const _hoisted_18 = { class: "col-span-1 space-y-3" }
const _hoisted_19 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_20 = { class: "text-gray-500 font-semibold text-sm" }
const _hoisted_21 = { class: "flex items-center flex-wrap space-x-2" }
const _hoisted_22 = {
  key: 1,
  class: "text-gray-500 font-semibold text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_cc_pill = _resolveComponent("cc-pill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.props.form.name), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_cc_button, {
          icon: _ctx.props.form.is_hidden === 1 ? 'far fa-eye-slash' : 'far fa-eye',
          type: "tertiary",
          secondaryIcon: 'none',
          label: _ctx.props.form.is_hidden === 1 ? 'hidden' : 'visible'
        }, null, 8, ["icon", "label"]),
        _createVNode(_component_cc_pill, {
          size: "small",
          type: 
            _ctx.formStatus && _ctx.formStatus.name === 'Draft' ? 'warning' : 'success'
          ,
          label: _ctx.formStatus ? _ctx.formStatus.name : 'blank'
        }, null, 8, ["type", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Form Type(s)", 2),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.formTypes.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formTypes, (formType) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "rounded-md bg-blue-100 py-1 px-2 max-w-max text-sm font-semibold",
                        key: `${formType.id}`
                      }, _toDisplayString(formType.name), 1))
                    }), 128))
                  : (_openBlock(), _createElementBlock("p", _hoisted_10, "blank"))
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Form Group", 2),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.formGroup ? _ctx.formGroup.name : 'blank'), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Region", 2),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.region ? _ctx.region.name : 'blank'), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Can be used as one-off form?", 2),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.props.form.can_be_used_as_one_off_form ? 'Yes' : 'No'), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Can be used in procedure?", 2),
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.props.form.can_be_used_in_procedure ? 'Yes' : 'No'), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("small", {
                class: _normalizeClass(_ctx.labelClasses)
              }, "Keywords", 2),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.keywords.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.keywords, (keyword) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "rounded-md bg-blue-100 py-1 px-2 max-w-max text-sm font-semibold",
                        key: `${keyword.id}`
                      }, _toDisplayString(keyword.name), 1))
                    }), 128))
                  : (_openBlock(), _createElementBlock("p", _hoisted_17, "blank"))
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", null, [
          _createElementVNode("small", {
            class: _normalizeClass(_ctx.labelClasses)
          }, "Description", 2),
          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.props.form.description || 'blank'), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("small", {
            class: _normalizeClass(_ctx.labelClasses)
          }, "Instructions", 2),
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.props.form.instructions || 'blank'), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("small", {
            class: _normalizeClass(_ctx.labelClasses)
          }, "Emails", 2),
          _createElementVNode("div", _hoisted_21, [
            (_ctx.props.form.email_notifications.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.props.form.email_notifications, (email) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "rounded-md bg-blue-100 py-1 px-2 max-w-max text-sm font-semibold",
                    key: `${email}`
                  }, _toDisplayString(email), 1))
                }), 128))
              : (_openBlock(), _createElementBlock("p", _hoisted_22, "blank"))
          ])
        ])
      ])
    ])
  ]))
}