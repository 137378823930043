
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      count: {
        type: Number,
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  })
