
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    Ref,
    watch,
  } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { Column } from 'devextreme/ui/data_grid'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import { Project } from '@/types/modules/projects/Project'
  import { Task } from '@/types/modules/tasks/Task'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { number, object, mixed } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'

  export default defineComponent({
    components: {
      SelectInput,
      RadioButton,
      CcButton,
      DataGrid,
      ValidationErrors,
    },
    props: {
      taskId: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const currentLocation = computed(() => {
        if (task.value) {
          return [
            {
              label: task.value.project?.name || '',
              value: task.value.project_id,
            },
          ]
        }

        return []
      })

      const selectableProjectTypes = computed(() => {
        if (store.getters['projects/types'].length && task.value) {
          return store.getters['projects/types']
            .filter((type: ProjectType) => {
              return task.value?.form.types
                .map((type: any) => type.name)
                .includes(type.name)
            })
            .map((type: ProjectType) => {
              return {
                value: type.name,
                label: type.name,
              }
            })
        }

        return []
      })

      const selectableProjects = computed(() => {
        if (store.getters['projects/projects'].length && task.value) {
          return store.getters['projects/projects'].map((project: Project) => {
            return {
              value: project.id,
              label: project.name,
            }
          })
        }

        return []
      })

      const filters = computed(() => {
        return {
          task_id: props.taskId,
          relations: ['task.form'],
        }
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Title',
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: 'Form',
          dataField: 'task.form.name',
          allowGrouping: false,
        },
        {
          caption: 'Task Date',
          dataType: 'date',
          dataField: 'task.start_date',
          allowGrouping: false,
        },
      ])

      const errors = ref(null) as Ref<Record<string, string> | null>
      const task = computed((): Task => store.getters['tasks/task'])
      const projectType = ref() as Ref<string | undefined>

      const payload = ref({
        project_id: null,
        task_id: null,
      }) as Ref<{ project_id: number | null; task_id: number | null }>

      const getTask = () => {
        return store
          .dispatch('tasks/show', { id: props.taskId, params: {} })
          .then(() => {
            payload.value.task_id = Number(props.taskId)
          })
      }

      const getProjectTypes = async () => {
        return await store.dispatch('projects/getProjectTypes', {}).then(() => {
          if (selectableProjectTypes.value[0]) {
            projectType.value = selectableProjectTypes.value[0].value
          }
        })
      }

      const getProjects = (params: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/index', params)
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      watch(projectType, () => {
        if (projectType.value) {
          getProjects({ type: [projectType.value] })
        }
      })

      const validationSchema = object().shape({
        task_id: number()
          .typeError('Task ID should be a number')
          .required(
            'Task ID is not present. Please refresh the page and try again'
          ),
        project_id: mixed().required('Please choose a new location'),
      })

      const move = async () => {
        payload.value.project_id = payload.value.project_id
          ? Number(payload.value.project_id)
          : null

        let results = await validatePayload(validationSchema, payload.value)

        store.dispatch('genericStore/showPageLoader', true)

        if (results) {
          errors.value = results
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        store
          .dispatch('taskLogs/moveTaskLogs', payload.value)
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setTabs', { tabs: [] })

        await getProjectTypes()

        getTask().then(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        payload,
        goBack,
        task,
        filters,
        columns,
        move,
        projectType,
        selectableProjectTypes,
        selectableProjects,
        currentLocation,
        errors,
      }
    },
  })
