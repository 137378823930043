import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "tasks/index",
    columns: _ctx.columns,
    "query-filter": _ctx.filters,
    chips: _ctx.state.chips,
    onChipClicked: _ctx.chipClicked,
    "read-only": true,
    "allow-column-chooser": true,
    "allow-selection": true,
    "allow-grouping": false,
    "show-page-size-selector": false,
    "show-grid-toolbar": true
  }, null, 8, ["columns", "query-filter", "chips", "onChipClicked"]))
}