
  import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
  import ButtonGroup from './ButtonGroup.vue'
  import CcButton from './Button.vue'

  export default defineComponent({
    emits: ['chip-clicked'],
    components: {
      ButtonGroup,
      CcButton,
    },
    props: {
      chips: {
        type: Array as PropType<string[]>,
        required: true,
      },
      defaultChip: {
        type: String,
        required: false,
      },
      showExpanded: {
        type: Boolean,
        required: false,
        default: false,
      },
      visibleChips: {
        type: Number,
        required: false,
        default: 4,
      },
      errors: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      props = reactive(props)

      const currentChips = computed(() => {
        if (!props.showExpanded) {
          return props.chips.slice(0, props.visibleChips)
        }
        return props.chips
      })

      const state = reactive({
        activeChip: '',
      })

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        emit('chip-clicked', chip)
      }

      const getButtonType = (chip: string) => {
        let baseType = 'tab-inactive'
        if (state.activeChip === chip) {
          baseType = 'tab-active'
        }
        return props.errors.includes(chip) ? `${baseType}-error` : baseType
      }

      onMounted(() => {
        state.activeChip = props.defaultChip || currentChips.value[0]
      })

      return {
        currentChips,
        chipClicked,
        state,
        props,
        getButtonType,
      }
    },
  })
