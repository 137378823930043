import UserIndex from '../views/modules/users/Index.vue'
import UserShow from '../views/modules/users/Show.vue'
import ForcePasswordChange from '../views/modules/users/ForcePasswordChange.vue'
import PermissionsMatrix from '@/views/modules/users/PermissionsMatrix.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/users',
    name: 'UserIndex',
    component: UserIndex,
    meta: {
      title: 'Users',
      module: 'admin',
      middleware: [auth, canView],
    },
  },
  {
    path: '/users/:id',
    name: 'UserShow',
    component: UserShow,
    meta: {
      title: 'Users',
      module: 'admin',
      middleware: [auth, canView],
    },
  },
  {
    path: '/users/password/change',
    name: 'ForcePasswordChange',
    component: ForcePasswordChange,
    meta: {
      layout: 'skeleton',
      title: 'Change Password',
      module: 'admin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/users/:id/permissions/matrix',
    name: 'UserPermissionsMatrix',
    props: true,
    component: PermissionsMatrix,
    meta: {
      title: 'Permissions Matrix',
      module: 'admin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
