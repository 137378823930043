import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "border border-gray-100 p-6 shadow-md h-32 text-center"
}
const _hoisted_3 = { class: "font-semibold text-md tracking-slight mb-4" }
const _hoisted_4 = { class: "text-1xs font-display font-semibold text-gray-300" }
const _hoisted_5 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 auto-rows-max gap-4"
}
const _hoisted_6 = { class: "font-semibold text-md tracking-slight mb-4" }
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_bar = _resolveComponent("progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.dataSets.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.loading.title), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.loading.message), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSets, (dataSet, index) => {
                  return (_openBlock(), _createElementBlock("section", {
                    key: index,
                    class: "border border-gray-100 p-6 shadow-md col-span-1 h-full text-center"
                  }, [
                    _createElementVNode("h1", _hoisted_6, _toDisplayString(dataSet.name), 1),
                    _createVNode(_component_progress_bar, {
                      min: 0,
                      max: dataSet.row_total,
                      value: dataSet.row_count,
                      message: _ctx.getProgressMessage(dataSet)
                    }, null, 8, ["max", "value", "message"])
                  ]))
                }), 128))
              ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: _ctx.isView('BespokeReportDesign') ? 'designer' : 'viewer',
      class: _normalizeClass(
      _ctx.loading.isLoading ? '' : 'h-max border border-gray-100 p-6 shadow-md'
    )
    }, null, 10, _hoisted_7)
  ], 64))
}