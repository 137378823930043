
  import { Task } from '@/types/modules/tasks/Task'
  import { defineComponent, reactive, computed, PropType } from 'vue'

  export default defineComponent({
    props: {
      value: {
        type: Array as PropType<Task[]>,
      },
      fieldAttributes: {
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      props = reactive(props)

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-300': true,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const inputClasses = computed(() => ({
        'bg-white': true,
        'border-dashed': true,
        'border-b-2': true,
        'border-gray-300': true,
        'py-2': true,
        'box-border resize-none': true,
        'w-full': true,
        'border-0': true,
        'border-gray-400': true,
        'focus:border-blue-300': true,
      }))

      return {
        inputClasses,
        props,
        labelClasses,
      }
    },
  })
