
  import {
    DxDataGrid,
    DxFilterRow,
    DxColumnChooser,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxPager,
    DxPaging,
  } from 'devextreme-vue/data-grid'
  import GridToolbar from '@/components/Generic/Grid/GridToolbar.vue'
  import Templates from './Templates/index'
  import {
    exportGridToExcel,
    exportGridToPdf,
    buildFilters,
    buildPagination,
    buildSorting,
  } from '@/composables/Grids'
  import {
    defineComponent,
    PropType,
    Ref,
    ref,
    computed,
    watch,
    reactive,
  } from 'vue'
  import DataSource from 'devextreme/data/data_source'
  import '@/assets/css/dev-extreme-grid.css'
  import { Column, SelectionChangedInfo } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import CustomStore from 'devextreme/data/custom_store'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import { ExportOption } from '@/types/components/Export'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      CcButton,
      GridToolbar,
      DxDataGrid,
      DxColumnChooser,
      DxSorting,
      DxFilterRow,
      DxSelection,
      DxGroupPanel,
      DxGrouping,
      DxPager,
      DxPaging,
      Chips,
      ...Templates,
    },
    props: {
      columns: {
        type: Array as PropType<Column[]>,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: '',
      },
      data: {
        type: Array,
        required: false,
        default: () => [],
      },
      allowFiltering: {
        type: Boolean,
        required: false,
        default: true,
      },
      allowColumnChooser: {
        type: Boolean,
        required: false,
        default: true,
      },
      allowSelection: {
        type: Boolean,
        required: false,
        default: true,
      },
      allowGrouping: {
        type: Boolean,
        required: false,
        default: true,
      },
      remoteOperations: {
        type: Object,
        default: () => {
          return {
            grouping: false,
            paging: true,
            groupPaging: false,
            filtering: true,
            sorting: true,
          }
        },
      },
      extraOptions: {
        type: Array as PropType<string[]>,
        required: false,
        default: () => [],
      },
      exportOptions: {
        type: Array as PropType<ExportOption[]>,
        required: false,
        default: () => [
          { name: 'Export To PDF', icon: 'fas fa-file-pdf' },
          { name: 'Export To Excel', icon: 'fas fa-file-excel' },
        ],
      },
      chips: {
        type: Array as PropType<string[]>,
        required: false,
      },
      defaultChip: {
        type: String,
        required: false,
      },
      queryFilter: {
        type: Object as PropType<any>,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      toggleLabel: {
        type: String,
        required: false,
        default: null,
      },
      showGridToolbar: {
        type: Boolean,
        required: false,
        default: true,
      },
      visibleChipCount: {
        type: Number,
        required: false,
        default: 4,
      },
      inMoveMode: {
        type: Boolean,
        required: false,
        default: false,
      },
      showPageSizeSelector: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: [
      'option-clicked',
      'toggle-clicked',
      'chip-clicked',
      'rows-selected',
      'export-clicked',
      'reorder-rows',
      'init-move-mode',
      'move-item-to',
      'primary-button-clicked',
    ],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()

      const templates = computed(() => {
        if (!props.columns || !props.columns.length) {
          return []
        }
        return props.columns
          .filter((column: Column) => {
            if (
              column.cellTemplate &&
              typeof column.cellTemplate === 'string'
            ) {
              return column.cellTemplate?.toString()
            }

            return false
          })
          .map((column: Column) => column.cellTemplate?.toString()!)
      })

      const dataGrid = ref() as Ref<DxDataGrid | undefined>
      const dataSource = computed(() => buildDataSource())
      const buildDataSource = () => {
        return new DataSource({
          store: new CustomStore({
            load: async (loadOptions) => {
              store.dispatch('genericStore/showPageLoader', true)
              return await store
                .dispatch(props.action, {
                  ...props.queryFilter,
                  sort: buildSorting(loadOptions),
                  filters:
                    props.queryFilter && 'filters' in props.queryFilter
                      ? props.queryFilter.filters
                          .concat(buildFilters(loadOptions))
                          .filter(Boolean)
                      : buildFilters(loadOptions),
                  page: buildPagination(loadOptions),
                  per_page: loadOptions.take,
                })
                .then((response) => response)
                .catch((error) =>
                  store.dispatch('genericStore/pushNotification', error.message)
                )
                .finally(() =>
                  store.dispatch('genericStore/showPageLoader', false)
                )
            },
          }),
        })
      }

      watch(
        () => props.queryFilter,
        () => refresh(),
        { deep: true }
      )

      const state = reactive({
        activeChip: '' as string,
        toggled: false,
        expanded: false,
      })
      const shouldShowExpandButton = computed((): boolean => {
        return (
          !!props.chips?.length && props.chips.length > props.visibleChipCount
        )
      })

      const refresh = () => {
        dataGrid.value?.instance?.refresh()
      }

      const optionClicked = (option: string) => emit('option-clicked', option)
      const primaryButtonClicked = () => emit('primary-button-clicked')
      const toggleClicked = () => {
        state.toggled = !state.toggled
        emit('toggle-clicked', state.toggled)
      }
      const chipClicked = (chip: string) => {
        state.activeChip = chip
        emit('chip-clicked', state.activeChip)
      }
      const selectionChanged = (data: SelectionChangedInfo) =>
        emit('rows-selected', data.selectedRowsData)

      const exportClicked = (option: string) => {
        if (option === 'Export To PDF') {
          return exportGridToPdf(dataGrid.value?.instance)
        }

        if (option === 'Export To Excel') {
          return exportGridToExcel(dataGrid.value?.instance)
        }

        emit('export-clicked', option)
      }

      const initMoveMode = (data: any) => emit('init-move-mode', data)
      const handleRowClick = (e: any) => {
        if (props.inMoveMode) emit('move-item-to', e.data)
      }

      const registerCustomEventListeners = (template: string) => {
        let listeners = {} as Record<any, any>

        if (template === 'LookupGridHandleTemplate') {
          listeners.initMoveMode = initMoveMode
        }

        return listeners
      }

      return {
        t,
        props,
        state,
        dataGrid,
        shouldShowExpandButton,
        primaryButtonClicked,
        exportGridToExcel,
        exportGridToPdf,
        selectionChanged,
        exportClicked,
        optionClicked,
        toggleClicked,
        chipClicked,
        dataSource,
        templates,
        refresh,
        registerCustomEventListeners,
        handleRowClick,
      }
    },
  })
