import { ActionTree, GetterTree, MutationTree } from 'vuex'
import QualificationTypeService from '@/services/projects/employees/qualificationTypes'
import { QualificationType } from '@/types/modules/projects/QualificationType'

export type QualificationTypeGetter = GetterTree<QualificationTypeState, any>

export interface QualificationTypeState {
  qualificationTypes: QualificationType[]
  qualificationType: QualificationType | null
}

export const state: QualificationTypeState = {
  qualificationTypes: [],
  qualificationType: null,
}

export const getters: QualificationTypeGetter = {
  getQualificationTypes: (state) => state.qualificationTypes,
  getQualificationType: (state) => state.qualificationType,
}

export const actions: ActionTree<QualificationTypeState, any> = {
  index: async (context, params) => {
    const response = await QualificationTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setQualificationTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setQualificationTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      QualificationTypeService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setQualificationType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(QualificationTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(QualificationTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(QualificationTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(QualificationTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(QualificationTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(QualificationTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      QualificationTypeService.toggleManyVisibility(params)
    )
  },
}

export const mutations: MutationTree<QualificationTypeState> = {
  setQualificationTypes: (state, types: QualificationType[]) =>
    (state.qualificationTypes = types),
  setQualificationType: (state, type: QualificationType) =>
    (state.qualificationType = type),
}

export const qualificationTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
