import axios from 'axios'

export default {
  show: (id: any, params: any) => {
    return axios
      .get(`projects/employees/${id}/proof-of-identity`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (id: any, payload: any) => {
    return axios
      .post(`projects/employees/${id}/proof-of-identity`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
