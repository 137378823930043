
  import { defineComponent, PropType, reactive, Ref, ref, watch } from 'vue'
  import { RuntimeParameter } from '@/types/modules/reporting/Datasource'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { format, subDays } from 'date-fns'

  export default defineComponent({
    components: {
      DateInput,
    },
    props: {
      parameter: {
        type: Object as PropType<RuntimeParameter>,
        required: true,
      },
    },
    emits: ['update:parameter'],
    setup(props, { emit }) {
      props = reactive(props)
      const payload = ref({
        data_type: '',
        default: '',
        friendly_name: '',
        friendly_operator: '',
        name: '',
        operator: '',
        options: '',
        value: '',
      }) as Ref<RuntimeParameter>

      watch(
        props.parameter,
        (parameter: RuntimeParameter) => {
          payload.value = {
            ...parameter,
            value: parameter.default
              ? format(
                  subDays(new Date(), Number(parameter.default)),
                  'yyyy-MM-dd'
                )
              : format(new Date(), 'yyyy-MM-dd'),
          }
        },
        { immediate: true }
      )

      watch(payload.value, () => emit('update:parameter', payload.value), {
        immediate: true,
      })

      return {
        payload,
      }
    },
  })
