
  import { defineComponent, reactive, onMounted, computed, PropType } from 'vue'
  import { useStore } from 'vuex'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import { useRoute } from 'vue-router'
  import { Home } from '@/types/modules/projects/Home'
  import HomeSummary from './details/HomeSummary.vue'
  import HomeContactDetails from './details/HomeContactDetails.vue'
  import HomeOtherDetails from './details/HomeOtherDetails.vue'
  import HomeOfstedDetails from './details/HomeOfstedDetails.vue'
  import format from 'date-fns/format'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import { date, number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'

  export default defineComponent({
    components: {
      Chips,
      PageToolbar,
      HomeSummary,
      HomeContactDetails,
      HomeOtherDetails,
      HomeOfstedDetails,
      ValidationErrors,
    },
    props: {
      home: {
        type: Object as PropType<Home>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const route = useRoute()
      const store = useStore()
      const state = reactive({
        chips: [
          'Summary',
          'Other Details',
          'Contact Details',
          'Ofsted Details',
        ],
        errors: {} as Record<string, string>,
        chipsWithErrors: [] as string[],
      })
      const activeChip = computed(
        () => store.getters['genericStore/activeChips'][route.fullPath]
      )

      const chipClicked = (chip: string) => {
        store.dispatch('genericStore/setActiveChip', { [route.fullPath]: chip })
      }

      const submitClicked = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        const submissionPayload = {
          ...payload,
          number_of_beds: undefined,
          category: undefined,
        }
        if (await validate()) {
          await store
            .dispatch('homes/update', submissionPayload)
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
            })
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            )
        }
        store.dispatch('genericStore/showPageLoader', false)
      }

      const exportClicked = (option: string) => {
        const name = `Home Details - ${props.home.project?.name}`
        if (option === 'Export To Excel') {
          return exportPageToExcel(payload, name)
        }
        if (option === 'Export To PDF') {
          return exportPageToPdf(payload, name)
        }
      }

      let payload = reactive({
        // summary
        id: undefined,
        name: undefined,
        number_of_beds: undefined,
        minimum_age_group: undefined,
        maximum_age_group: undefined,
        administrator_id: undefined,
        person_in_charge_id: undefined,
        responsible_individual_id: undefined,
        start_date: undefined,
        end_date: undefined,
        is_secure_accommodation: undefined,
        is_short_term_stay: undefined,
        company_id: undefined, //care group id
        region_id: undefined,
        category: undefined,

        // other details
        pat_date: undefined,
        electrical_certificate: undefined,
        gas_certificate: undefined,
        day_fire_drill_date: undefined,
        night_fire_drill_date: undefined,
        description: undefined,

        // contact details
        address: undefined,
        postcode: undefined,
        phone: undefined,
        fax: undefined,
        email: undefined,

        // ofsted details
        ofsted_urn: undefined,
        ofsted_rating: undefined,
        ofsted_rating_date: undefined,
        ofsted_requirement_no: undefined,
        ofsted_req_reg_no: undefined,
        ofsted_rec_reg_no: undefined,
        interim_ofsted_rating: undefined,
        interim_rating_date: undefined,
        interim_requirement_no: undefined,
        interim_req_reg_no: undefined,
        interim_recommendation_no: undefined,
        interim_rec_reg_no: undefined,
      })

      const summaryParams = [
        'id',
        'name',
        'number_of_beds',
        'minimum_age_group',
        'maximum_age_group',
        'administrator_id',
        'person_in_charge_id',
        'responsible_individual_id',
        'start_date',
        'end_date',
        'is_secure_accommodation',
        'is_short_term_stay',
        'company_id',
        'region_id',
        'category',
      ]

      const otherDetailsParams = [
        'pat_date',
        'electrical_certificate',
        'gas_certificate',
        'day_fire_drill_date',
        'night_fire_drill_date',
        'description',
      ]

      const contactDetailsParams = [
        'address',
        'postcode',
        'phone',
        'fax',
        'email',
      ]

      const ofstedDetailsParams = [
        'ofsted_urn',
        'ofsted_rating',
        'ofsted_rating_date',
        'ofsted_requirement_no',
        'ofsted_req_reg_no',
        'ofsted_rec_reg_no',
        'interim_ofsted_rating',
        'interim_rating_date',
        'interim_requirement_no',
        'interim_req_reg_no',
        'interim_recommendation_no',
        'interim_rec_reg_no',
      ]

      const validationSchema = object({
        name: string().required('Please enter a name'),
        administrator_id: number().required(
          'Please select a home administrator'
        ),
        person_in_charge_id: number().required(
          'Please select a person in charge'
        ),
        responsible_individual_id: number().required(
          'Please select a responsible individual'
        ),
        start_date: date()
          .typeError('Please Select a ClearCare start date')
          .required('Please Select a ClearCare start date'),
        company_id: number().required('Please select a care group'),
        end_date: date().typeError('ClearCare end date must be a date'),
        minimum_age_group: number().typeError(
          'The minimum age group must be a number'
        ),
        maximum_age_group: number().typeError(
          'The maximum age group must be a number'
        ),
        pat_date: date().typeError('PAT date must be a date'),
        electrical_certificate: date().typeError(
          'Electrical certificate must be a date'
        ),
        gas_certificate: date().typeError('Gas certificate must be a date'),
        day_fire_drill_date: date().typeError(
          'Fire drill - day must be a date'
        ),
        night_fire_drill_date: date().typeError(
          'Fire drill - night must be a date'
        ),
        ofsted_rating_date: date().typeError(
          'Ofsted rating date must be a date'
        ),
        ofsted_requirement_no: number().typeError(
          'Number of requirements must be a number'
        ),
        ofsted_req_reg_no: number().typeError(
          'Regulation numbers for requirements must be a number'
        ),
        ofsted_rec_reg_no: number().typeError(
          'Number of recommendations must be a number'
        ),
        interim_rating_date: date().typeError(
          'Interim Ofsted rating date must be a date'
        ),
        interim_requirement_no: number().typeError(
          'Interim number of requirements must be a number'
        ),
        interim_req_reg_no: number().typeError(
          'Interim regulation numbers for requirements must be a number'
        ),
        interim_recommendation_no: number().typeError(
          'Interim numbers of recommendations must be a number'
        ),
        interim_rec_reg_no: number().typeError(
          'Interim regulation numbers for recommendations must be a number'
        ),
        email: string().email('Email must be a valid email address'),
      })

      const validate = async () => {
        const errors = await validatePayload(validationSchema, payload)
        state.errors = {}
        state.chipsWithErrors = []
        if (errors) {
          state.errors = errors
          if (summaryParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Summary')
          }
          if (otherDetailsParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Other Details')
          }
          if (contactDetailsParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Contact Details')
          }
          if (ofstedDetailsParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Ofsted Details')
          }
        }
        return !errors
      }

      const updatePayload = (updatedPayload: Record<string, any>) => {
        Object.assign(payload, updatedPayload)
        if (Object.keys(state.errors).length) {
          validate()
        }
      }

      const mapToPayload = () => {
        updatePayload({
          // summary
          id: props.home.id,
          name: props.home.project?.name,
          number_of_beds: props.home.number_of_beds,
          minimum_age_group: props.home.minimum_age_group,
          maximum_age_group: props.home.maximum_age_group,
          administrator_id: props.home.project?.administrator_id,
          person_in_charge_id: props.home.person_in_charge_id,
          responsible_individual_id: props.home.responsible_individual_id,
          start_date: props.home.project?.start_date
            ? format(new Date(props.home.project?.start_date), 'yyyy-MM-dd')
            : undefined,
          end_date: props.home.project?.end_date
            ? format(new Date(props.home.project?.end_date), 'yyyy-MM-dd')
            : undefined,
          is_secure_accommodation: props.home.is_secure_accommodation,
          is_short_term_stay: props.home.is_short_term_stay,
          company_id: props.home.project?.company_id, //care group id
          region_id: props.home.project?.region_id,
          category: props.home.category,

          // other details
          pat_date: props.home.pat_date
            ? format(new Date(props.home.pat_date), 'yyyy-MM-dd')
            : undefined,
          electrical_certificate: props.home.electrical_certificate
            ? format(new Date(props.home.electrical_certificate), 'yyyy-MM-dd')
            : undefined,
          gas_certificate: props.home.gas_certificate
            ? format(new Date(props.home.gas_certificate), 'yyyy-MM-dd')
            : undefined,
          day_fire_drill_date: props.home.day_fire_drill_date
            ? format(new Date(props.home.day_fire_drill_date), 'yyyy-MM-dd')
            : undefined,
          night_fire_drill_date: props.home.night_fire_drill_date
            ? format(new Date(props.home.night_fire_drill_date), 'yyyy-MM-dd')
            : undefined,
          description: props.home.project?.description,

          // contact details
          address: props.home.address,
          postcode: props.home.postcode,
          phone: props.home.phone,
          fax: props.home.fax,
          email: props.home.email,

          // ofsted details
          ofsted_urn: props.home.ofsted_urn,
          ofsted_rating: props.home.ofsted_rating,
          ofsted_rating_date: props.home.ofsted_rating_date
            ? format(new Date(props.home.ofsted_rating_date), 'yyyy-MM-dd')
            : undefined,
          ofsted_requirement_no: props.home.ofsted_requirement_no,
          ofsted_req_reg_no: props.home.ofsted_req_reg_no,
          ofsted_rec_reg_no: props.home.ofsted_rec_reg_no,
          interim_ofsted_rating: props.home.interim_ofsted_rating,
          interim_rating_date: props.home.interim_rating_date
            ? format(new Date(props.home.interim_rating_date), 'yyyy-MM-dd')
            : undefined,
          interim_requirement_no: props.home.interim_requirement_no,
          interim_req_reg_no: props.home.interim_req_reg_no,
          interim_recommendation_no: props.home.interim_recommendation_no,
          interim_rec_reg_no: props.home.interim_rec_reg_no,
        })
      }

      onMounted(async () => {
        mapToPayload()
        if (!activeChip.value) {
          store.dispatch('genericStore/setActiveChip', {
            [route.fullPath]: state.chips[0],
          })
        }
      })

      return {
        props,
        payload,
        updatePayload,
        state,
        activeChip,
        chipClicked,
        exportClicked,
        submitClicked,
      }
    },
  })
