export default {
  widgetTypes: (params: any) => {
    params = params

    return {
      success: true,
      message: 'Widget types returned successfully',
      data: [
        {
          name: 'Data Card',
          icon: 'far fa-address-card',
          type: 'data-card',
          colour: 'orange',
          description: 'Add a view for data',
        },
        {
          name: 'Bar Chart',
          icon: 'fa fa-chart-column',
          type: 'bar-chart',
          colour: 'green',
          description: 'Create a bar chart',
        },
        {
          name: 'Pie Chart',
          icon: 'far fa-chart-pie-simple',
          type: 'pie-chart',
          colour: 'purple',
          description: 'Create a pie chart',
        },
        {
          name: 'Doughnut Chart',
          icon: 'fak fa-doughnut-chart',
          type: 'doughnut-chart',
          colour: 'red',
          description: 'Create a doughnut chart',
        },
        {
          name: 'Tasks per form',
          icon: 'far fa-area-chart',
          type: 'tasks-per-form',
          colour: 'blue',
          description: 'Create a tasks per form view',
        },
      ],
    }
  },

  widgets: (params: any) => {
    params = params

    return {
      success: true,
      message: 'returned widgets successfully',
      data: [
        {
          row: 1,
          period: 'All',
          column: 2,
          size: 1,
          end_date: null,
          start_date: null,
          configuration:
            '{"form_id":65,"project_ids":[25,1],"form_version":12}',
          row_cols_count: 4,
          report_on_fields: 0,
          id: 2,
          title: 'Physical interventions',
          graph_type: 'Doughnut Chart',
          form_id: 65,
          project_ids: [25, 1],
          form_version: 12,
          data: {
            '25': {
              name: 'Hilltop View',
              count: 263,
              logs: [],
            },
            '1': {
              name: 'Simon Update',
              count: 27,
              logs: [],
            },
          },
        },
        {
          row: 1,
          period: 'All',
          column: 2,
          end_date: null,
          start_date: null,
          configuration:
            '{"form_id":65,"project_ids":[25,1],"form_version":12}',
          row_cols_count: 4,
          report_on_fields: 0,
          id: 2,
          size: 1,
          title: 'Column Chart',
          graph_type: 'Trends',
          form_id: 65,
          project_ids: [25, 1],
          form_version: 12,
          data: {
            '25': {
              name: 'Hilltop View',
              count: 263,
              logs: [],
            },
            '1': {
              name: 'Simon Update',
              count: 27,
              logs: [],
            },
          },
        },
        {
          row: 1,
          period: 'All',
          column: 2,
          size: 1,
          end_date: null,
          start_date: null,
          configuration:
            '{"form_id":65,"project_ids":[25,1],"form_version":12}',
          row_cols_count: 4,
          report_on_fields: 0,
          id: 2,
          title: 'Pie chart',
          graph_type: 'Pie Chart',
          form_id: 65,
          project_ids: [25, 1],
          form_version: 12,
          data: {
            '25': {
              name: 'Hilltop View',
              count: 263,
              logs: [],
            },
            '1': {
              name: 'Simon Update',
              count: 27,
              logs: [],
            },
          },
        },
        {
          row: 2,
          period: 'All',
          column: 1,
          size: 1,
          end_date: null,
          start_date: null,
          configuration: '{"reports_on":"Tasks"}',
          row_cols_count: null,
          report_on_fields: null,
          id: 5,
          title: 'Data Card',
          graph_type: 'Data Card',
          reports_on: 'Tasks',
          data: [
            {
              id: 7566,
              img: 'https://randomuser.me/api/portraits/men/87.jpg',
              info: 'some info here',
              name: 'Incident Form completed by Dee',
              task_id: 15809,
              project_name: 'Sophie Dunstall',
              override_date: '2021-05-27 10:28:00',
            },
            {
              id: 7494,
              name: 'Incident Form completed by jo',
              task_id: 13693,
              project_name: 'Sophie Dunstall',
              override_date: '2021-05-27 10:28:00',
              img: 'https://randomuser.me/api/portraits/men/89.jpg',
              info: 'some info here',
            },
          ],
        },
        {
          row: 3,
          period: null,
          size: 3,
          column: 1,
          end_date: null,
          start_date: null,
          configuration: '{}',
          row_cols_count: 1,
          report_on_fields: null,
          id: 9,
          title: 'Tasks Per Form',
          graph_type: 'Tasks Per Form',
          data: {
            'Daily Summary': {
              Jan: 132,
              Feb: 120,
              Mar: 134,
              Apr: 128,
              May: 134,
              Jun: 128,
              Jul: 34,
              Aug: 0,
              Sep: 0,
              Oct: 0,
              Nov: 0,
              Dec: 0,
            },
            'Fridge/Freezer Temps': {
              Jan: 62,
              Feb: 56,
              Mar: 62,
              Apr: 60,
              May: 62,
              Jun: 60,
              Jul: 16,
              Aug: 0,
              Sep: 0,
              Oct: 0,
              Nov: 0,
              Dec: 0,
            },
            'Incident Form': {
              Jan: 13,
              Feb: 1,
              Mar: 0,
              Apr: 0,
              May: 0,
              Jun: 1,
              Jul: 0,
              Aug: 0,
              Sep: 0,
              Oct: 0,
              Nov: 0,
              Dec: 0,
            },
            'Supervision': {
              Jan: 57,
              Feb: 57,
              Mar: 116,
              Apr: 58,
              May: 58,
              Jun: 58,
              Jul: 0,
              Aug: 0,
              Sep: 0,
              Oct: 0,
              Nov: 0,
              Dec: 0,
            },
            'Weekly Deep Cleaning': {
              Jan: 62,
              Feb: 56,
              Mar: 62,
              Apr: 60,
              May: 62,
              Jun: 60,
              Jul: 16,
              Aug: 0,
              Sep: 0,
              Oct: 0,
              Nov: 0,
              Dec: 0,
            },
          },
        },
        // something like this eventually...
        // {
        //   title: 'Add Chart',
        //   component: AddChart,
        //   size: 1,
        //   props: {},
        // },
      ],
    }
  },
  deleteWidget: (params: any) => {
    params = params

    return {
      success: true,
      message: 'deleted widget successfully',
      data: {
        id: 3,
        title: 'Incidents',
        component: 'column',
        size: 1,
        props: {
          data: [
            { label: 'whitegates', value: 100 },
            { label: 'argent court', value: 53 },
            { label: 'hilltop view', value: 72 },
          ],
        },
      },
    }
  },
  moveWidget: (params: any) => {
    params = params

    return {
      success: true,
      message: 'moved widget successfully',
      data: {},
    }
  },
  newWidget: (payload: any) => {
    payload = payload

    return {
      success: true,
      message: 'Created new widget',
      data: null,
    }
  },
}
