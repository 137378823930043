import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_report = _resolveComponent("view-report")!
  const _component_manage_comments = _resolveComponent("manage-comments")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.report && _ctx.isTab('report'))
      ? (_openBlock(), _createBlock(_component_view_report, {
          key: 0,
          report: _ctx.report
        }, null, 8, ["report"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('comments'))
      ? (_openBlock(), _createBlock(_component_manage_comments, {
          key: 1,
          id: _ctx.props.id,
          publishedReportId: _ctx.props.publishedReportId
        }, null, 8, ["id", "publishedReportId"]))
      : _createCommentVNode("", true)
  ], 64))
}