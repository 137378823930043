import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_number_input = _resolveComponent("number-input")!

  return (_openBlock(), _createBlock(_component_number_input, {
    id: _ctx.props.fieldAttributes.id,
    max: _ctx.props.fieldAttributes.max ? _ctx.props.fieldAttributes.max : null,
    min: _ctx.props.fieldAttributes.min ? _ctx.props.fieldAttributes.min : null,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    isRequired: _ctx.props.fieldAttributes.required ? true : false,
    label: _ctx.label ? _ctx.label : undefined,
    message: 
      _ctx.props.fieldAttributes.instructions
        ? _ctx.props.fieldAttributes.instructions
        : undefined
    ,
    autocomplete: false,
    error: _ctx.error
  }, null, 8, ["id", "max", "min", "modelValue", "isRequired", "label", "message", "error"]))
}