import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shouldLoadGrid)
      ? (_openBlock(), _createBlock(_component_data_grid, {
          key: 0,
          ref: "grid",
          action: _ctx.action,
          "read-only": _ctx.state.readOnly,
          "extra-options": _ctx.state.extraOptions,
          columns: _ctx.state.columns,
          "default-chip": _ctx.state.activeChip,
          chips: _ctx.chips,
          "query-filter": _ctx.query,
          onChipClicked: _ctx.chipClicked,
          onRowsSelected: _ctx.rowsSelected,
          onOptionClicked: _ctx.optionClicked
        }, null, 8, ["action", "read-only", "extra-options", "columns", "default-chip", "chips", "query-filter", "onChipClicked", "onRowsSelected", "onOptionClicked"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirm_dialog, {
      show: _ctx.confirm.show,
      title: _ctx.confirm.title,
      content: _ctx.confirm.content,
      onConfirmed: _ctx.confirmed,
      onCancelled: _ctx.resetConfirmDialog
    }, null, 8, ["show", "title", "content", "onConfirmed", "onCancelled"])
  ], 64))
}