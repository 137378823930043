import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end mb-8" }
const _hoisted_2 = { class: "flex items-start space-x-4" }
const _hoisted_3 = { class: "w-1/2 space-y-8" }
const _hoisted_4 = { class: "w-1/2 space-y-6" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2.5 block font-semibold text-1xs text-red-300 tracking-slight lowercase"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_multi_select = _resolveComponent("multi-select")!
  const _component_drop_zone_appearance_template = _resolveComponent("drop-zone-appearance-template")!
  const _component_file_uploader = _resolveComponent("file-uploader")!
  const _component_uploaded_file = _resolveComponent("uploaded-file")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_true_false = _resolveComponent("true-false")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_toolbar, {
        "extra-options": _ctx.extraOptions,
        onOptionClicked: _ctx.optionClicked,
        onSubmitClicked: _ctx.submitClicked
      }, null, 8, ["extra-options", "onOptionClicked", "onSubmitClicked"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_multi_select, {
          label: "Document types",
          "display-expr": 'text',
          "value-expr": 'value',
          "tag-items": _ctx.evidenceTypeOptions,
          modelValue: _ctx.payload.documents,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.documents) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.errors?.documents ? (_ctx.errors.documents = undefined) : ''
        ))
          ],
          error: _ctx.errors?.documents
        }, null, 8, ["tag-items", "modelValue", "error"]),
        _createVNode(_component_file_uploader, {
          "upload-text": _ctx.t('components.fileUploader.button'),
          label: _ctx.t('employeeRecruitmentChecks.proofOfIdentity.supportingFiles'),
          "is-required": false,
          onFilesUploaded: _ctx.filesUploaded,
          "allowed-extensions": _ctx.allowedFileExtensions
        }, {
          "drop-zone-appearance": _withCtx(() => [
            _createVNode(_component_drop_zone_appearance_template)
          ]),
          _: 1
        }, 8, ["upload-text", "label", "onFilesUploaded", "allowed-extensions"]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFiles, (file, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_uploaded_file, {
                file: file,
                onSensitiveToggled: _ctx.fileSensitivityToggled,
                onDeleteClicked: _ctx.fileDeleteClicked
              }, null, 8, ["file", "onSensitiveToggled", "onDeleteClicked"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_text_input, {
          label: "Reviewed and approved by",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.created_by,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.created_by) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Date of review and approval",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.created_at,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.created_at) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Last updated by",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.updated_by,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.updated_by) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_text_input, {
          label: "Last updated at",
          icons: [{ icon: 'fa fa-lock', position: 'before' }],
          "is-disabled": true,
          "is-required": false,
          modelValue: _ctx.payload.updated_at,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.updated_at) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_true_false, {
          label: _ctx.t('employeeRecruitmentChecks.proofOfIdentity.confirmation'),
          "is-required": true,
          modelValue: _ctx.isConfirmed,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isConfirmed) = $event))
        }, null, 8, ["label", "modelValue"]),
        (_ctx.showConfirmationError)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, " Please confirm that you have reviewed and approved the documents to verify the identity of the employee "))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}