import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_documents_table = _resolveComponent("documents-table")!
  const _component_settings = _resolveComponent("settings")!
  const _component_audit = _resolveComponent("audit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showDocumentsTable)
      ? (_openBlock(), _createBlock(_component_documents_table, {
          key: 0,
          sensitive: _ctx.sensitive
        }, null, 8, ["sensitive"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_settings, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('audit'))
      ? (_openBlock(), _createBlock(_component_audit, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}