
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { defineComponent, PropType } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<MedicationStock>,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const linkClicked = () => {
        store.dispatch('mars/setStockToAdjust', props.data).then(() => {
          router.push({
            name: 'StockAdjustment',
            params: { id: route.params.id, medicationId: props.data.id },
          })
        })
      }
      return {
        linkClicked,
        t,
      }
    },
  })
