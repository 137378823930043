
  import {
    computed,
    defineComponent,
    reactive,
    onUnmounted,
    onMounted,
  } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import { Column } from 'devextreme/ui/data_grid'
  import { RecycleBin } from '@/types/modules/generic/RecycleBin'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    setup() {
      const store = useStore()
      const state = reactive({
        selectedRows: [],
        hasRows: false,
        key: 'id',
        columns: [] as Column[],
        action: null,
        restoreAction: null,
        queryFilter: {},
      })

      const selectionChanged = (selection: any) => {
        state.selectedRows = selection
      }

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch(recycleBinData.value.restoreAction, {
            ids: state.selectedRows.map(
              (row: any) => row[recycleBinData.value.key]
            ),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const recycleBinData = computed(
        (): RecycleBin => store.getters['genericStore/recycleBinData']
      )

      onUnmounted(() => {
        store.commit('genericStore/setRecycleBinData', {
          key: 'id',
          action: '',
          restoreAction: '',
          columns: [],
        })
      })

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      return {
        state,
        selectionChanged,
        restore,
        recycleBinData,
      }
    },
  })
