
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { defineComponent, PropType } from 'vue'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<MedicationStock>,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute()
      return {
        route,
        props,
      }
    },
  })
