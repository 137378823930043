import { computed } from 'vue'
import store from '@/store'
import { RotaCycle } from '@/types/modules/rotas/RotaCycle'
import { sharedState, totalSteps } from './Rotas'
import { rotaSetting } from './RotaSetting'

export const rotaCycles = computed(
  () => store.getters['rotaCycles/getRotaCycles']
)

export const computedRotaCycles = computed(() => {
  let cycles = rotaCycles.value.map((cycle: RotaCycle) => {
    return {
      label: cycle.name,
      value: cycle.id,
    }
  })

  return [{ label: 'None', value: null }, ...cycles]
})

export const isCycle = (name: string) => {
  let cycle = rotaCycles.value.find((cycle: RotaCycle) => cycle.name === name)

  return cycle ? rotaSetting.value.default_cycle_id === cycle.id : false
}

export const loadRotaCycles = () => store.dispatch('rotaCycles/index')

export const cycleChanged = () => {
  if (rotaSetting.value.cycle_start_week > totalSteps.value) {
    sharedState.step = 1
    rotaSetting.value.cycle_start_week = 1
  }
}
