import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_vehicle_summary_details = _resolveComponent("vehicle-summary-details")!
  const _component_vehicle_ownership_details = _resolveComponent("vehicle-ownership-details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_chips, {
        chips: _ctx.props.vehicle ? _ctx.allChips : _ctx.state.chips,
        "default-chip": _ctx.activeChip,
        errors: _ctx.state.chipsWithErrors,
        onChipClicked: _ctx.chipClicked
      }, null, 8, ["chips", "default-chip", "errors", "onChipClicked"])
    ]),
    (_ctx.props.vehicle)
      ? (_openBlock(), _createBlock(_component_page_toolbar, {
          key: 0,
          class: "flex justify-end",
          onSubmitClicked: _ctx.submitClicked,
          onExportClicked: _ctx.exportClicked
        }, null, 8, ["onSubmitClicked", "onExportClicked"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_validation_errors, {
        errors: _ctx.state.errors
      }, null, 8, ["errors"]),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.activeChip === 'Summary')
          ? (_openBlock(), _createBlock(_component_vehicle_summary_details, {
              key: 0,
              ref: "summary",
              vehicle: _ctx.props.vehicle,
              errors: _ctx.state.errors,
              onUpdatePayload: _ctx.updatePayload
            }, null, 8, ["vehicle", "errors", "onUpdatePayload"]))
          : _createCommentVNode("", true)
      ], 1024)),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.activeChip === 'Ownership Details')
          ? (_openBlock(), _createBlock(_component_vehicle_ownership_details, {
              key: 0,
              vehicle: _ctx.props.vehicle,
              errors: _ctx.state.errors,
              onUpdatePayload: _ctx.updatePayload
            }, null, 8, ["vehicle", "errors", "onUpdatePayload"]))
          : _createCommentVNode("", true)
      ], 1024))
    ])
  ], 64))
}