<template>
  <slot />
  <toast-notification
    v-if="hasNotification"
    :fire-on="hasNotification.value"
    :message="notification"
  />
</template>
<script>
import { useStore } from 'vuex'
import { computed, defineComponent } from 'vue'
import ToastNotification from '@/components/Generic/Notifications/ToastNotification.vue'
export default defineComponent({
  components: {
    ToastNotification,
  },
  setup() {
    const store = useStore()
    const notification = computed(
      () => store.getters['genericStore/notification']
    )
    const hasNotification = computed(() => notification)

    return {
      hasNotification,
      notification,
    }
  },
})
</script>
