export default {
  vehicleDetails: {
    header: 'Vehicle Details',
    summary: {
      id: 'ID',
      name: 'Vehicle Name',
      make: 'Vehicle Make',
      model: 'Vehicle Model',
      colour: 'Vehicle Colour',
      idNumber: 'Vehicle Identification Number',
      registration: 'Vehicle Registration',
      registrationDate: 'Registration date',
      motDate: 'MOT Date',
      taxDate: 'Tax Date',
      fuelType: 'Fuel Type',
      contactDetails: 'Contact Details',
      region: 'Region',
      administrator: 'Administrator',
      careGroup: 'Care Group',
    },
    ownershipDetails: {
      insuranceDate: 'Insurance date',
      price: 'Purchase Price',
      purchaseDate: 'Purchase Date',
      balloon: 'Balloon',
      description: 'Description',
      leaseStart: 'Lease Start Date',
      leaseEnd: 'Lease End Date',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
}
