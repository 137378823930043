import { User } from '@/types/modules/users/User'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import UsersService from '@/services/users/users'
import { UserAccessIplist } from '@/types/modules/users/UserAccessIplist'
import { WhitelistedEmailDomain } from '@/types/modules/users/WhitelistedEmailDomain'
import { UserProjectPermissionAudit } from '@/types/modules/users/UserProjectPermissionAudit'
import { UserModulePermissionAudit } from '@/types/modules/users/UserModulePermissionAudit'
import { UserCompanyPermissionAudit } from '@/types/modules/users/UserCompanyPermissionAudit'
import { UserModulePermission } from '@/types/modules/users/UserModulePermission'
import { UserCompanyPermission } from '@/types/modules/users/UserCompanyPermission'
import { UserDocumentPermission } from '@/types/modules/users/UserDocumentPermission'
import { Setting } from '@/types/modules/settings/Setting'
export type UserGetter = GetterTree<UserState, any>

export interface UserState {
  users: User[]
  user: User | null
  ipAddresses: UserAccessIplist[]
  authorisedDomains: WhitelistedEmailDomain[]
  passwordPolicy: object[]
  userModulePermissions: UserModulePermission[]
  userCareGroupPermissions: UserCompanyPermission[]
  userDocumentPermissions: UserDocumentPermission[]
  userPermissionLogs: UserProjectPermissionAudit[]
  userModulePermissionLogs: UserModulePermissionAudit[]
  userCareGroupPermissionLogs: UserCompanyPermissionAudit[]
  settings: Setting[]
}

export const state: UserState = {
  users: [],
  user: null,
  ipAddresses: [],
  authorisedDomains: [],
  passwordPolicy: [],
  userModulePermissions: [],
  userCareGroupPermissions: [],
  userDocumentPermissions: [],
  userPermissionLogs: [],
  userModulePermissionLogs: [],
  userCareGroupPermissionLogs: [],
  settings: [],
}

export const getters: UserGetter = {
  users: (state) => state.users,
  user: (state) => state.user,
  ipAddresses: (state) => state.ipAddresses,
  authorisedDomains: (state) => state.authorisedDomains,
  passwordPolicy: (state) => state.passwordPolicy,
  userModulePermissions: (state) => state.userModulePermissions,
  userCareGroupPermissions: (state) => state.userCareGroupPermissions,
  userDocumentPermissions: (state) => state.userDocumentPermissions,
  userPermissionLogs: (state) => state.userPermissionLogs,
  userModulePermissionLogs: (state) => state.userModulePermissionLogs,
  userCareGroupPermissionLogs: (state) => state.userCareGroupPermissionLogs,
  settings: (state) => state.settings,
}

export const actions: ActionTree<UserState, any> = {
  index: async (context, params) => {
    const response = await UsersService.index(params)

    if (response.success && params?.per_page) {
      context.commit('setUsers', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setUsers', response.data)

      return response
    }

    return response
  },
  getUserModulePermissions: async (context, params) => {
    const response = Promise.resolve(
      UsersService.getUserModulePermissions(params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setUserModulePermissions', response.data)
      }
    })

    return response
  },
  getUserCareGroupPermissions: async (context, params) => {
    const response = Promise.resolve(
      UsersService.getUserCareGroupPermissions(params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setUserCareGroupPermissions', response.data)
      }
    })

    return response
  },
  getUserDocumentPermissions: async (context, params) => {
    const response = Promise.resolve(
      UsersService.getUserDocumentPermissions(params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setUserDocumentPermissions', response.data)
      }
    })

    return response
  },
  updateUserPermissions: async (_context, payload) => {
    return Promise.resolve(UsersService.updateUserPermissions(payload))
  },
  getUserPermissionLogs: async (context, params) => {
    const response = await UsersService.getUserPermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setUserPermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setUserPermissionLogs', response.data)

      return response
    }

    return response
  },
  getUserModulePermissionLogs: async (context, params) => {
    const response = await UsersService.getUserModulePermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setUserModulePermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setUserModulePermissionLogs', response.data)

      return response
    }

    return response
  },
  getUserCareGroupPermissionLogs: async (context, params) => {
    const response = await UsersService.getUserCareGroupPermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setUserCareGroupPermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setUserCareGroupPermissionLogs', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    let results = Promise.resolve(UsersService.show(params))

    results.then((response) => {
      context.commit('setUser', response.data)
    })

    return results
  },
  ipAddresses: async (context, params) => {
    const response = await UsersService.ipAddresses(params)

    if (response.success && params.per_page) {
      context.commit('setIpAddresses', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setIpAddresses', response.data)

      return response
    }

    return response
  },
  authorisedDomains: async (context, params) => {
    const response = await UsersService.authorisedDomains(params)

    if (response.success && params.per_page) {
      context.commit('setAuthorisedDomains', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setAuthorisedDomains', response.data)

      return response
    }

    return response
  },
  passwordPolicy: (context, params) => {
    let results = Promise.resolve(UsersService.passwordPolicy(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setPasswordPolicy', response.data)
    })
  },
  submitPasswordPolicy: (context, payload) => {
    let results = Promise.resolve(UsersService.submitPasswordPolicy(payload))

    results.then((response) => {
      console.log(response.message)
      context.commit('setPasswordPolicy', response.data)
    })
  },
}

export const mutations: MutationTree<UserState> = {
  setUsers: (state, users: User[]) => {
    state.users = users
  },
  setUser: (state, user) => {
    state.user = user
  },
  setIpAddresses: (state, data) => {
    state.ipAddresses = data
  },
  setAuthorisedDomains: (state, data) => {
    state.authorisedDomains = data
  },
  setPasswordPolicy: (state, data) => {
    state.passwordPolicy = data
  },
  setUserModulePermissions: (state, permissions: UserModulePermission[]) => {
    state.userModulePermissions = permissions
  },
  setUserCareGroupPermissions: (
    state,
    permissions: UserCompanyPermission[]
  ) => {
    state.userCareGroupPermissions = permissions
  },
  setUserDocumentPermissions: (
    state,
    permissions: UserDocumentPermission[]
  ) => {
    state.userDocumentPermissions = permissions
  },
  setUserPermissionLogs: (state, logs: UserProjectPermissionAudit[]) => {
    state.userPermissionLogs = logs
  },
  setUserModulePermissionLogs: (state, logs: UserModulePermissionAudit[]) => {
    state.userModulePermissionLogs = logs
  },
  setUserCareGroupPermissionLogs: (
    state,
    logs: UserCompanyPermissionAudit[]
  ) => {
    state.userCareGroupPermissionLogs = logs
  },
  setSettings: (state, settings: Setting[]) => (state.settings = settings),
}

export const users = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
