
  import { DxScheduler } from 'devextreme-vue/scheduler'
  import { locale } from 'devextreme/localization'
  import ShiftTemplate from './ShiftTemplate.vue'
  import ShiftTooltipTemplate from './ShiftTooltipTemplate.vue'
  import TimeCellTemplate from './TimeCellTemplate.vue'
  import ResourceCellTemplate from './ResourceCellTemplate.vue'
  import DateCellTemplate from './DateCellTemplate.vue'
  import DataCellTemplate from './DataCellTemplate.vue'
  import {
    computed,
    defineComponent,
    reactive,
    watch,
    PropType,
    ref,
  } from 'vue'
  import { overlaps, sharedState } from '@/composables/rotas/Rotas'
  import { useStore } from 'vuex'
  import { ShiftInstance } from '@/types/modules/rotas/ShiftInstance'
  import isEqual from 'date-fns/isEqual'
  import startOfDay from 'date-fns/startOfDay/index'
  import isAfter from 'date-fns/isAfter'
  import subWeeks from 'date-fns/subWeeks'
  import differenceInHours from 'date-fns/differenceInHours/index.js'
  import { isBetween } from '@/composables/Dates'
  import endOfDay from 'date-fns/fp/endOfDay'
  import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
  import format from 'date-fns/format'

  locale('en-gb')

  export default defineComponent({
    components: {
      DataCellTemplate,
      DateCellTemplate,
      ResourceCellTemplate,
      TimeCellTemplate,
      ShiftTemplate,
      ShiftTooltipTemplate,
      DxScheduler,
      locale,
    },
    props: {
      timeline_ref: {
        type: String,
        default: 'rota-timeline-0',
      },
      existing_shifts: {
        type: Array,
        default: () => [],
      },
      show_add_modal: {
        type: Object,
        default: () => {},
      },
      views: {
        type: Array,
        default: () => {
          return ['timelineDay']
        },
      },
      view_as: {
        type: String,
        default: 'timelineDay',
      },
      shift_template_type: {
        type: String,
        default: null,
      },
      height: {
        type: String,
        default: '100%',
      },
      show_all_day: {
        type: Boolean,
        default: false,
      },
      show_indicator: {
        type: Boolean,
        default: false,
      },
      hide_times: {
        type: Boolean,
        default: false,
      },
      hide_dates: {
        type: Boolean,
        default: false,
      },
      hide_date_navigator: {
        type: Boolean,
        default: false,
      },
      duration: {
        type: Number,
        default: 60,
      },
      datasource: {
        required: true,
        type: Array as PropType<any[]>,
        default: () => {
          return []
        },
      },
      scroll_mode: {
        type: Object,
        default: () => {
          return {
            mode: 'standard',
          }
        },
      },
      current_date: {
        type: Object as PropType<any>,
        default: () => {
          return new Date()
        },
      },
      start_date_field: {
        type: String,
        default: 'shift_start',
      },
      end_date_field: {
        type: String,
        default: 'shift_end',
      },
      can_edit: {
        type: Object,
        default: () => {
          return {
            allowAdding: false,
            allowDeleting: false,
            allowUpdating: false,
            allowResizing: false,
            allowDragging: false,
          }
        },
      },
      can_edit_past_rotas_setting: {
        type: Object,
        default: () => {},
      },
      view_mode: {
        type: String,
        default: 'summary',
      },
    },
    emits: [
      'changed',
      'init',
      'ready',
      'configure-form',
      'adding',
      'updating',
      'deleting',
      'cell-clicked',
      'clicked',
      'dbl-clicked',
    ],
    setup(props, { emit }) {
      props = reactive(props)

      const timelineRef = ref({}) as any

      const state = reactive({
        intervals: [] as any[],
      })

      watch(
        () => props.show_add_modal,
        (value) => {
          if (value) {
            addButtonCalled(value)
          }
        }
      )

      const shifts = computed(() => {
        if (props.view_mode != 'summary') {
          return props.datasource.filter((shift: any) => {
            let isEqualToStartDateDay = isEqual(
              startOfDay(new Date(shift.shift_start)),
              startOfDay(new Date(props.current_date))
            )
            let isEqualToEndDateDay = isEqual(
              startOfDay(new Date(shift.shift_end)),
              startOfDay(new Date(props.current_date))
            )

            return (
              isEqualToStartDateDay ||
              (isEqualToEndDateDay &&
                shift.condition !== 'deleted' &&
                !shift.hidden)
            )
          })
        } else {
          return props.datasource
        }
      })

      const calculateHeight = computed(() => {
        if (props.view_as !== 'timelineDay') return props.height

        setIntervals()

        for (let i = 0; i < props.datasource.length; i++) {
          for (let j = 0; j < state.intervals.length; j++) {
            if (
              isBetween(
                new Date(props.datasource[i].shift_start),
                new Date(state.intervals[j].start),
                new Date(state.intervals[j].end),
                '[)'
              ) ||
              isBetween(
                new Date(state.intervals[j].start),
                new Date(props.datasource[i].shift_start),
                new Date(props.datasource[i].shift_end),
                '[)'
              )
            ) {
              state.intervals[j].shifts = state.intervals[j].shifts + 1
            }
          }
        }

        let highestShiftCount = Math.max(
          ...state.intervals.map((i) => i.shifts)
        )
        let height = highestShiftCount <= 1 ? 1 : highestShiftCount

        height *= 75

        if (props.timeline_ref === 'rota-timeline-0') {
          height += 50
        }

        return `${height}px`
      })

      const canEditPastRotas = computed(() => {
        if (!props.can_edit_past_rotas_setting) {
          return false
        }

        return !!parseInt(props.can_edit_past_rotas_setting.value)
      })

      const saveSchedulerInstance = () => {
        emit('init', {
          ref: timelineRef.value[props.timeline_ref],
        })
      }

      const setIntervals = () => {
        state.intervals = []
        const rangeStart = startOfDay(props.current_date)
        const rangeEnd = endOfDay(props.current_date)

        eachMinuteOfInterval(
          {
            start: rangeStart,
            end: rangeEnd,
          },
          { step: props.duration }
        ).reduce((previousValue: Date, currentValue: Date) => {
          state.intervals.push({
            shifts: 0,
            start: format(previousValue, 'yyyy-MM-dd HH:mm'),
            end: format(currentValue, 'yyyy-MM-dd HH:mm'),
          })

          return currentValue
        })
      }

      const addButtonCalled = (skeleton_shift: any) => {
        if (timelineRef.value['rota-timeline-0']) {
          timelineRef.value['rota-timeline-0'].instance.showAppointmentPopup(
            skeleton_shift,
            true
          )
        }
      }

      const appointmentRendered = () => {
        removeDefaultPopover()
        setShiftTextColour()
      }

      const removeDefaultPopover = () => {
        let element = document.querySelectorAll('.dx-scheduler-appointment')
        element.forEach((el) => {
          el.classList.add('no-popover')
        })
      }

      const setShiftTextColour = () => {
        document
          .querySelectorAll('.dx-scheduler-appointment')
          .forEach(function (e: any) {
            let background = e.style.backgroundColor
            let rgb = background.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
            let colour = '#f8f8ff'

            if (rgb && rgb.length) {
              let check = Math.round(
                (parseInt(rgb[1]) * 299 +
                  parseInt(rgb[2]) * 587 +
                  parseInt(rgb[3]) * 114) /
                  1000
              )

              colour = check >= 125 ? '#313639' : '#f8f8ff'
            }

            return (e.style.color = colour)
          })
      }

      const ready = (data: any) => {
        let el = document.querySelectorAll(
          '.dx-scheduler-date-table-scrollable'
        )

        el.forEach((e: any) => {
          e.style.marginBottom = '0'
          e.style.paddingBottom = '0'
        })

        emit('ready', data)
      }

      const configureForm = (data: any) => {
        if (shouldDisableDate(data.appointmentData.shift_start)) {
          return (data.cancel = true)
        }

        emit('configure-form', data)
        return
      }

      const onAdd = (data: any) => {
        if (shouldDisableDate(data.appointmentData.shift_start)) {
          return (data.cancel = true)
        }

        if (
          overlaps(
            data.appointmentData,
            props.existing_shifts.concat(props.datasource) as ShiftInstance[]
          ) ||
          spansMoreThanAllowedHours(data.appointmentData)
        ) {
          useStore().dispatch(
            'genericStore/pushNotification',
            sharedState.overlapErrors.join(', ')
          )
          sharedState.overlapErrors = []

          return (data.cancel = true)
        }

        emit('adding', data)
        return
      }

      const onUpdate = (data: any) => {
        if (shouldDisableDate(data.newData.shift_start)) {
          return (data.cancel = true)
        }

        if (
          overlaps(
            data.newData,
            props.existing_shifts.concat(props.datasource) as ShiftInstance[]
          ) ||
          spansMoreThanAllowedHours(data.newData)
        ) {
          useStore().dispatch(
            'genericStore/pushNotification',
            sharedState.overlapErrors.join(', ')
          )
          sharedState.overlapErrors = []

          return (data.cancel = true)
        }

        emit('updating', data)
        return
      }

      const onDelete = (data: any) => {
        if (shouldDisableDate(data.appointmentData.shift_start)) {
          return (data.cancel = true)
        }

        emit('deleting', data)
        return
      }

      const deleteAppointment = (data: any) => {
        let scheduler = timelineRef.value[props.timeline_ref]

        scheduler.instance.deleteAppointment(data.cellData.appointmentData)
        scheduler.instance.hideAppointmentTooltip()
        data.event.event.stopPropagation()
      }

      const onCellClick = (data: any) => emit('cell-clicked', data)

      const onClick = (data: any) => emit('clicked', data)

      const onDoubleClick = (data: any) => {
        if (shouldDisableDate(data.appointmentData.shift_start)) {
          return (data.cancel = true)
        }

        emit('dbl-clicked', data)
        return
      }

      const onOptionChanged = (data: any) => emit('changed', data)

      const shouldDisableDate = (date: any) => {
        let isDateEqual = isEqual(new Date(date), startOfDay(new Date()))
        let isDateAfter = isAfter(new Date(date), startOfDay(new Date()))

        if (isDateEqual || isDateAfter || !props.can_edit_past_rotas_setting) {
          return false
        }

        if (!canEditPastRotas.value) {
          return true
        }

        let last_editable_date = subWeeks(startOfDay(new Date()), 1)

        let editableDateEqual = isEqual(new Date(date), last_editable_date)
        let editableDateAfter = isAfter(new Date(date), last_editable_date)

        return !(
          canEditPastRotas.value &&
          (editableDateEqual || editableDateAfter)
        )
      }

      const spansMoreThanAllowedHours = (shift: any, hours = 48) => {
        let duration = differenceInHours(
          new Date(shift.shift_end),
          new Date(shift.shift_start)
        )

        if (duration <= hours) return false

        useStore().dispatch(
          'genericStore/pushNotification',
          `The shift spans over ${Math.round(
            duration
          )} hours. A shift cannot span over more than ${hours} hours. Please amend the shift start and/or end times.`
        )

        return true
      }

      return {
        timelineRef,
        ready,
        onOptionChanged,
        onClick,
        onDoubleClick,
        appointmentRendered,
        onAdd,
        onCellClick,
        onUpdate,
        onDelete,
        shifts,
        calculateHeight,
        spansMoreThanAllowedHours,
        saveSchedulerInstance,
        configureForm,
        shouldDisableDate,
        deleteAppointment,
      }
    },
  })
