import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_extra_options_button = _resolveComponent("extra-options-button")!
  const _component_button_group = _resolveComponent("button-group")!

  return (_openBlock(), _createBlock(_component_button_group, null, {
    default: _withCtx(() => [
      (!_ctx.readOnly)
        ? (_openBlock(), _createBlock(_component_cc_button, {
            key: 0,
            type: "success",
            label: "Save",
            icon: "fas fa-save",
            isDisabled: _ctx.props.submitDisabled,
            onButtonClicked: _ctx.submitClicked
          }, null, 8, ["isDisabled", "onButtonClicked"]))
        : _createCommentVNode("", true),
      _createVNode(_component_export_button, {
        options: _ctx.props.exportOptions,
        onExportClicked: _ctx.exportClicked
      }, null, 8, ["options", "onExportClicked"]),
      (_ctx.props.extraOptions.length)
        ? (_openBlock(), _createBlock(_component_extra_options_button, {
            key: 1,
            extraOptions: _ctx.props.extraOptions,
            onOptionClicked: _ctx.optionClicked
          }, null, 8, ["extraOptions", "onOptionClicked"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}