
  import { useStore } from 'vuex'
  import { defineComponent, computed, reactive, onMounted } from 'vue'
  import SplashImage from '@/components/Generic/Splash/SplashImage.vue'
  import CcLogo from '@/components/Generic/Logo/Logo.vue'
  import CcFooter from '@/components/Layout/Footer.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      CcLogo,
      CcButton,
      CcFooter,
      SplashImage,
      TextInput,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        payload: {
          user_id: null,
          current: '',
          password: '',
          password_confirmation: '',
        },
      })
      const getters = computed(() => {
        return {
          authenticatedUser: store.getters['auth/authenticatedUser'],
          clientSplashImage: store.getters['genericStore/clientSplashImage'],
          clientLogo: store.getters['genericStore/clientLogo'],
          passwordGuidelines: store.getters['settings/passwordGuidelines'],
        }
      })

      const changePassword = () => {
        if (!state.payload.password || !state.payload.password_confirmation) {
          store.dispatch(
            'genericStore/pushNotification',
            'Please fill in both password fields'
          )
          return
        }

        if (state.payload.password !== state.payload.password_confirmation) {
          store.dispatch(
            'genericStore/pushNotification',
            'The passwords provided do not match'
          )
          return
        }

        state.payload.user_id = getters.value.authenticatedUser?.id

        store
          .dispatch('auth/changePassword', state.payload)
          .then(() => store.dispatch('auth/redirect'))
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
      }

      onMounted(() => {
        store.dispatch('settings/getPasswordGuidelines')
      })

      return {
        changePassword,
        getters,
        state,
        t,
      }
    },
  })
