import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-picker-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!

  return (_openBlock(), _createElementBlock("section", {
    class: "timeline-date-picker",
    "data-picker-id": _ctx.state.picker.id
  }, [
    _createVNode(_component_DxButton, {
      icon: "chevronleft",
      "styling-mode": "text",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changed('prev', $event)))
    }),
    _createVNode(_component_DxDateBox, {
      type: "date",
      "apply-value-mode": "useButtons",
      modelValue: _ctx.state.picker.date,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.picker.date) = $event)),
      "accept-custom-value": false,
      "display-format": _ctx.state.picker.format,
      onValueChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changed('picker', $event)))
    }, null, 8, ["modelValue", "display-format"]),
    _createVNode(_component_DxButton, {
      icon: "chevronright",
      "styling-mode": "text",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changed('next', $event)))
    })
  ], 8, _hoisted_1))
}