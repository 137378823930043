import { EnComponents } from "@/locales/messages/components";

export default {
    taskLogs: {
        header: 'Logs',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                startDate: `Start ${EnComponents.grid.headers.date}`,
                logTitle: 'Log Title',
                createdOn: EnComponents.grid.headers.createdOn,
                updatedOn: EnComponents.grid.headers.updatedOn
            }
        }
    }
}