import axios from 'axios'

export default {
  index: async (id: number) => {
    return axios
      .get(`bespoke-reports/${id}/datasources`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  loadDataset: async (id: number, payload: any) => {
    return axios
      .post(`bespoke-reports/datasources/${id}/dataset`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
