
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'

  import { Column } from 'devextreme/ui/data_grid'
  import { computed, onMounted, reactive } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { Region } from '@/types/modules/regions/Region'

  export default {
    components: {
      CcButton,
      DataGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          caption: 'Name',
          dataField: 'name',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'company.company_name',
          caption: 'Care Group',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'owner.username',
          caption: 'Owner',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'parent.name',
          caption: 'Enclosing Region',
          allowGrouping: false,
          allowSorting: false,
        },
      ])

      const regions = computed((): Region[] => store.getters['regions/regions'])

      const goBack = () => {
        router.push({ name: 'RegionIndex' })
      }

      const deleteRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('regions/deleteMany', {
            ids: regions.value.map((region: Region) => region.id),
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .then((response) => {
            store.dispatch('genericStore/showPageLoader', false),
              store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => {
            router.push({ name: 'RegionIndex' })
          })
      }

      onMounted(() => {
        !store.getters['regions/regions'].length &&
          router.push({ name: 'RegionIndex' })

        store.getters['genericStore/breadCrumbs'].push({
          name: 'Delete Region',
          url: route.fullPath,
        })
      })
      return {
        t,
        goBack,
        regions,
        columns,
        deleteRecords,
      }
    },
  }
