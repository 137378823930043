
  import { get } from 'lodash'
  import { DxButton } from 'devextreme-vue/button'
  import { DxTooltip } from 'devextreme-vue/tooltip'

  import { computed, defineComponent, onMounted, reactive, watch } from 'vue'

  export default defineComponent({
    components: {
      DxTooltip,
      DxButton,
    },
    props: {
      currentDate: {
        type: [Object, Date],
        default: () => {
          return new Date()
        },
      },
      currentView: {
        type: String,
        default: 'week',
      },
      templateType: {
        type: String,
        default: null,
      },
      canDelete: {
        type: Boolean,
        default: false,
      },
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    emits: ['delete'],
    setup(props) {
      props = reactive(props)
      const state = reactive({
        shift: {} as any,
        templateIdentifier: Math.random().toString(36).substr(2, 9),
        imageLoading: true,
        tags: [] as any[],
        defaultImage: 'https://bit.ly/3jDg2Q6',
        showToolTip: false,
      })

      watch(
        () => state.shift.image,
        () => (state.imageLoading = true)
      )

      const startTime = computed(() => {
        return new Date(state.shift.shift_start)
          .toLocaleTimeString()
          .slice(0, 5)
      })

      const endTime = computed(() => {
        return new Date(state.shift.shift_end).toLocaleTimeString().slice(0, 5)
      })

      const historicalJobTitle = computed(() => {
        if (state.shift && state.shift.snapshot) {
          if (state.shift.snapshot.valid_job_titles) {
            return [
              ...new Set(
                state.shift.snapshot.valid_job_titles.map(
                  (title: any) => title.job_title
                )
              ),
            ].join(', ')
          }
        }
        return null
      })

      const expectedJobTitles = computed(() => {
        if (state.shift.valid_job_titles.length) {
          return [
            ...new Set(
              state.shift.valid_job_titles.map((title: any) => title.job_title)
            ),
          ].join(', ')
        }

        return null
      })

      const jobTitleTags = computed(() => {
        if (!state.shift.snapshot) {
          return get(state.shift, 'valid_job_titles', [])
        }

        return get(state.shift, 'snapshot.valid_job_titles', [])
      })

      const qualificationTags = computed(() => {
        let tags = state.shift.snapshot
          ? get(state.shift, 'snapshot.employee.qualification_tags', [])
          : get(state.shift, 'employee.qualification_tags', [])

        // Return only unique tags by id
        return tags
          .map((e: any) => e['tag_icon_id'])
          .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
          .filter((e: any) => tags[e])
          .map((e: any) => tags[e])
      })

      const template = (type: any) => type === props.templateType

      const setDefaultImage = (event: Event) => {
        let target = event.target as HTMLImageElement

        if (target) {
          target.src = state.defaultImage
        }
      }

      const buildTags = () => {
        if (jobTitleTags.value.length) {
          jobTitleTags.value.forEach((tag: any) => {
            if (tag.tag_icon) {
              state.tags.push(tag)
            }
          })
        }

        if (qualificationTags.value.length) {
          qualificationTags.value.forEach((tag: any) => {
            if (tag.tag_icon) {
              state.tags.push(tag)
            }
          })
        }
      }

      const init = () => {
        state.shift = props.cellData.appointmentData
        buildTags()
      }

      onMounted(() => init())

      return {
        props,
        startTime,
        endTime,
        state,
        historicalJobTitle,
        setDefaultImage,
        template,
        expectedJobTitles,
      }
    },
  })
