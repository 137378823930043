import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_step = _resolveComponent("wizard-step")!

  return (_openBlock(), _createBlock(_component_wizard_step, {
    steps: _ctx.props.steps,
    "active-step": _ctx.props.activeStep,
    "save-label": _ctx.props.saveLabel,
    onStepChanged: _ctx.stepChanged,
    onGoToStep: _ctx.stepChanged,
    onSubmitClicked: _ctx.submitClicked
  }, null, 8, ["steps", "active-step", "save-label", "onStepChanged", "onGoToStep", "onSubmitClicked"]))
}