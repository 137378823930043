
  import { defineComponent, onMounted, reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import { Region } from '@/types/modules/regions/Region'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: { DataGrid },
    setup() {
      const store = useStore()
      const router = useRouter()
      const state = reactive({
        extraOptions: ['View Deleted Regions'],
        selectedRows: [] as Region[],
      })

      const grid = ref()

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Regions', url: '/regions' },
      ]

      const addClicked = () => {
        router.push({
          name: 'RegionCreate',
        })
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          allowGrouping: false,
          dataType: 'string',
          caption: 'Name',
          cellTemplate: function (container, options) {
            renderCellLink(container, options.data?.name, 'RegionShow', {
              id: options.data?.id,
            })
          },
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'company.company_name',
          caption: 'Care Group',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'owner.username',
          caption: 'Owner',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'parent.name',
          caption: 'Enclosing Region',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'number',
          dataField: 'active_items',
          caption: 'Active Items',
          calculateCellValue: (row: Region) => {
            let count = 0

            if (row.projects) count += row.projects.length
            if (row.forms) count += row.forms.length
            if (row.procedures) count += row.procedures.length

            return count
          },
        },
      ])

      const rowsSelected = (rows: Region[]) => {
        state.selectedRows = rows
        if (rows.length && !state.extraOptions.includes('Delete')) {
          state.extraOptions.push('Delete')
        }

        if (!rows.length && state.extraOptions.includes('Delete')) {
          state.extraOptions.splice(state.extraOptions.indexOf('Delete'), 1)
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          store
            .dispatch('regions/setSelectedRegions', state.selectedRows)
            .then(() => router.push({ name: 'RegionDelete' }))
        }
        if (option === 'View Deleted Regions') {
          router.push({ name: 'RegionRestore' })
        }
        return
      }

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      return {
        addClicked,
        columns,
        state,
        grid,
        rowsSelected,
        optionClicked,
      }
    },
  })
