export default function () {
  var canvas,
    ctx,
    canvasOffset,
    flag = false,
    w = 0,
    h = 0,
    prevX = 0,
    currX = 0,
    prevY = 0,
    currY = 0,
    dot_flag = false,
    // object with image information
    imageBg,
    // flag for circle shape
    circle_flag = false

  var x = 'black',
    y = 1,
    x1 = 0,
    y1 = 0

  var allowedColors = [
    'green',
    'blue',
    'red',
    'yellow',
    'orange',
    'black',
    'white',
  ]

  /**
   * Init setup for canvas environment
   * @var object canvasElement
   * @var object image
   */
  this.initCanvas = function (canvasElement, image) {
    // set all global variables on their default state
    canvas,
      ctx,
      canvasOffset,
      (flag = false),
      (w = 0),
      (h = 0),
      (prevX = 0),
      (currX = 0),
      (prevY = 0),
      (currY = 0),
      (dot_flag = false),
      imageBg,
      (circle_flag = false)
    ;(x = 'black'), (y = 1)

    if (canvasElement) {
      canvas = canvasElement
    }

    ctx = canvas.getContext('2d')
    imageBg = typeof image == 'undefined' ? null : image

    // get context width & height
    w = canvas.width
    h = canvas.height

    canvas.addEventListener(
      'mousemove',
      function (e) {
        findxy('move', e)
      },
      false
    )
    canvas.addEventListener(
      'mousedown',
      function (e) {
        findxy('down', e)
      },
      false
    )
    canvas.addEventListener(
      'mouseup',
      function (e) {
        findxy('up', e)
      },
      false
    )
    canvas.addEventListener(
      'mouseout',
      function (e) {
        findxy('out', e)
      },
      false
    )

    drawImage()
  }

  /**
   * Draw image in canvas
   */
  function drawImage() {
    // check if image back-ground is set
    if (!imageBg) {
      return
    }

    var img = new Image()
    img.onload = function () {
      ctx.drawImage(img, 0, 0, imageBg.width, imageBg.height)
    }
    img.src = imageBg.imgSrc
  }

  /**
   * Draw lines based on X and Y coordinates
   * @var string res
   * @var object e
   */
  function findxy(res, e) {
    // specify canvas offset to calculate coordinates
    canvasOffset = canvas.getBoundingClientRect()

    if (res == 'down') {
      currX = e.clientX - canvasOffset.left
      currY = e.clientY - canvasOffset.top
      prevX = currX
      prevY = currY
      x1 = currX = e.clientX - canvasOffset.left
      y1 = currY = e.clientY - canvasOffset.top

      flag = true
      dot_flag = true
      if (dot_flag && !circle_flag) {
        ctx.beginPath()
        ctx.fillStyle = x
        ctx.fillRect(currX, currY, 2, 2)
        ctx.closePath()
        dot_flag = false
      }
    }
    if (res == 'up') {
      prevX = currX
      prevY = currY
      currX = e.clientX - canvasOffset.left
      currY = e.clientY - canvasOffset.top

      if (circle_flag) {
        var radius = Math.sqrt(
          Math.pow(currX - x1, 2) + Math.pow(currY - y1, 2)
        )

        ctx.beginPath()
        ctx.arc(prevX, prevY, radius, 0, Math.PI * 2, true)
        ctx.strokeStyle = x
        ctx.lineWidth = y
        ctx.stroke()
        ctx.closePath()
      }
    }
    if (res == 'up' || res == 'out') {
      flag = false
    }
    if (res == 'move') {
      if (flag && !circle_flag) {
        prevX = currX
        prevY = currY
        currX = e.clientX - canvasOffset.left
        currY = e.clientY - canvasOffset.top
        draw()
      }
    }
  }

  /**
   * Draw operation
   */
  function draw() {
    ctx.beginPath()
    ctx.moveTo(prevX, prevY)
    ctx.lineTo(currX, currY)
    ctx.strokeStyle = x
    ctx.lineWidth = y
    ctx.stroke()
    ctx.closePath()
  }

  /**
   * Change color of the path in canvas
   * @var HTML object obj
   */
  this.color = function (color) {
    if (allowedColors.indexOf(color) !== -1) {
      x = color
    }

    y = x === 'white' ? 14 : 2
  }

  /**
   * Return created image as URI data
   */
  this.save = function () {
    return canvas.toDataURL()
  }

  /**
   * Clear canvas field from previous image
   */
  this.clear = function () {
    var m = confirm('Want to clear?')
    if (m) {
      ctx.clearRect(0, 0, w, h)
      drawImage()
    }
  }

  /**
   * Enable global flag for circle shape
   */
  this.drawCircle = function () {
    circle_flag = true
  }

  /**
   * Disable global flag for circle shape
   */
  this.freePath = function () {
    circle_flag = false
  }

  /**
   * Update global variable imageBg
   */
  this.updateBG = function (newFile) {
    imageBg = newFile
    drawImage()
  }

  /**
   * Return acceptable colors
   */
  this.allowedColors = function () {
    return allowedColors.filter(function (color) {
      return color !== 'white'
    })
  }

  /**
   * The active color
   */
  this.activeColor = function () {
    return x
  }
}
