export default {
    taskExplorer: {
        stepWizard: {
            configTask: 'Configure',
            runReport: 'Run Report'
        },
        configureTask: {
            dateRange: 'Select Date Range',
            startDate: 'Select A Start Date',
            endDate: 'Select An End Date',
            form: 'Select A Form',
            field: 'Select Field',
            taskAuditTrail: 'Show Task Audit Trail'
        },
        runReport: {
            noData: 'No Data To Show',
            grid: {
                headers: {
                    homeOrSchool: 'Home Or School',
                    summary: 'Summary',
                    firstFiled: 'First Filed',
                    viewLog: 'View Log'
                },
                cells: {
                    viewLog: 'Log Link'
                }
            }
        }
    }
}