import { shiftTypes } from './shiftTypes'
import { employeeShifts } from './employeeShifts'
import { rotaSettings } from './rotaSettings'
import { defaultShifts } from './defaultShifts'
import { shiftInstances } from './shiftInstances'
import { rotaCycles } from './rotaCycles'
import { rotas } from './rotas'

export default {
  rotaSettings,
  shiftTypes,
  employeeShifts,
  defaultShifts,
  shiftInstances,
  rotaCycles,
  rotas,
}
