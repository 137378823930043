import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a3cd7be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shadow h-72 border border-dashed flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "font-semibold font-display text-blue-300 underline text-base",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
      href: "#"
    }, "+ " + _toDisplayString(_ctx.t('overview.addWidget')), 1)
  ]))
}