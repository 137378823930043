import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center relative" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_dx_text_box = _resolveComponent("dx-text-box")!
  const _component_dx_select_box = _resolveComponent("dx-select-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-display w-3/12", _ctx.hasErrors ? 'border-l-4 border-red-300 pl-3' : ''])
  }, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: _ctx.error,
      "is-required": _ctx.isRequired,
      "is-disabled": _ctx.isDisabled
    }, null, 8, ["label", "error", "is-required", "is-disabled"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.options.length)
        ? (_openBlock(), _createBlock(_component_dx_select_box, _mergeProps({
            key: 0,
            "data-source": _ctx.options,
            value: _ctx.props.modelValue,
            "display-expr": "label",
            "value-expr": "value",
            required: _ctx.isRequired,
            disabled: _ctx.isDisabled,
            class: _ctx.selectClasses
          }, _ctx.$attrs, {
            onSelectionChanged: _ctx.handleInput,
            "styling-mode": "underlined",
            "field-template": "field"
          }), {
            field: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("i", {
                  class: _normalizeClass([data && data.label, "mr-2 inline-block fa-2xl"])
                }, null, 2),
                _createVNode(_component_dx_text_box, { "read-only": true })
              ])
            ]),
            item: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("i", {
                  class: _normalizeClass([data && data.label, "inline-block icon-list-item fa-2xl"])
                }, null, 2)
              ])
            ]),
            _: 1
          }, 16, ["data-source", "value", "required", "disabled", "class", "onSelectionChanged"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.message && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["mt-2.5", _ctx.labelClasses])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}