
  import { reactive, ref, computed, defineComponent } from 'vue'
  import { useRouter } from 'vue-router'
  import DropDown from '../Dropdown/DropDown.vue'
  import DropdownItem from '../Dropdown/DropdownItem.vue'

  export default defineComponent({
    components: {
      DropDown,
      DropdownItem,
    },
    setup() {
      const focus = ref(null)
      const router = useRouter()

      const state = reactive({
        search_input: '',
        showInput: false,
        dropdownText: 'Search By Task ID',
        dropdownItems: ['Search By Task ID', 'Search By Log ID'],
      })

      const searchInput = computed(
        (): Object => ({
          'border-b-2': true,
          'border-black': true,
          'transition-all': true,
          'duration-300': true,
          'focus:border-blue-200': true,
          'focus:outline-none': true,
          'w-72': state.showInput,
          'w-0': !state.showInput,
        })
      )

      const toggleSearchInput = () => {
        state.showInput = !state.showInput

        if (focus.value && state.showInput) {
          const value: HTMLElement = focus.value!
          value.focus()
        }

        // Use search icon as button to start search
        if (!state.showInput && state.search_input != '') {
          search()
        }
      }

      // TODO: implement search logic. - Use sanitizeHtml here when implementing
      const search = () => {
        if (state.dropdownText.includes('Task ID')) {
          router.push({
            name: 'ViewLog',
            params: { taskId: state.search_input },
          })
        }
        if (state.dropdownText.includes('Log ID')) {
          // Do something
        }
      }

      return {
        focus,
        search,
        searchInput,
        state,
        toggleSearchInput,
      }
    },
  })
