import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "font-display w-1/2 p-8 shadow-lg rounded-md border border-gray-100 mt-8 space-y-8" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-between gap-4"
}
const _hoisted_4 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_grouped_select_input = _resolveComponent("grouped-select-input")!
  const _component_true_false = _resolveComponent("true-false")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_input, {
        "is-required": true,
        options: _ctx.dateRanges,
        label: _ctx.t('taskExplorer.configureTask.dateRange'),
        modelValue: "past-six-months",
        "onUpdate:modelValue": _ctx.dateRangeChosen
      }, null, 8, ["options", "label", "onUpdate:modelValue"]),
      (_ctx.state.dateRange === 'custom-date-range')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_date_input, {
              label: _ctx.t('taskExplorer.configureTask.startDate'),
              "is-required": true,
              "onUpdate:modelValue": _ctx.startDateSelected
            }, null, 8, ["label", "onUpdate:modelValue"]),
            _createVNode(_component_date_input, {
              label: _ctx.t('taskExplorer.configureTask.endDate'),
              "is-required": true,
              "onUpdate:modelValue": _ctx.endDateSelected
            }, null, 8, ["label", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_select_input, {
        "is-required": true,
        options: _ctx.state.forms,
        label: _ctx.t('taskExplorer.configureTask.form'),
        "onUpdate:modelValue": _ctx.formSelected
      }, null, 8, ["options", "label", "onUpdate:modelValue"]),
      _createVNode(_component_grouped_select_input, {
        "is-disabled": !_ctx.state.curFields.length && !_ctx.state.prevFields.length,
        "is-required": false,
        options: {
          Current: _ctx.state.curFields,
          Previous: _ctx.state.prevFields,
        },
        label: _ctx.t('taskExplorer.configureTask.field'),
        "onUpdate:modelValue": _ctx.fieldSelected
      }, null, 8, ["is-disabled", "options", "label", "onUpdate:modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_true_false, {
          label: _ctx.t('taskExplorer.configureTask.taskAuditTrail'),
          value: false,
          onValueUpdated: _ctx.taskAuditTrailToggled
        }, null, 8, ["label", "onValueUpdated"])
      ])
    ])
  ]))
}