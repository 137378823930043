
  import { computed, defineComponent, reactive } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'

  export default defineComponent({
    components: { DataGrid },
    setup() {
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'Grouping Name',
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: true,
        },
        {
          caption: 'Status',
          dataField: 'status.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Reports',
          //todo:
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.id,
            })
          },
          allowExporting: false,
        },
      ])
      const filters = computed(() => {
        return {
          relations: ['status'],
        }
      })

      return {
        columns,
        filters,
      }
    },
  })
