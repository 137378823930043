import { homes } from './homes'
import employees from './employees'
import { schools } from './schools'
import youngPeople from './young_people'
import { vehicles } from './vehicles'
import { projects } from './projects'
import { projectTypeSections } from './projectTypeSections'
import { projectTypeAttributes } from './projectTypeAttributes'
import { projectAssociations } from './projectAssociations'

export default {
  homes,
  ...employees,
  schools,
  vehicles,
  ...youngPeople,
  projects,
  projectTypeAttributes,
  projectTypeSections,
  projectAssociations,
}
