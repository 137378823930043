
  import { defineComponent, ref, watch } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { format, endOfDay, subDays } from 'date-fns'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      queryFilter: {
        type: Object,
        required: false,
      },
    },
    setup(props) {
      const filters = ref({
        before: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss') as string,
        task_statuses: ['Incomplete'] as string[],
        log_statuses: [] as string[],
        per_page: 5,
        sort: [['start_date', 'desc']],
        filters: [] as string[][],
      })

      const chips = [
        'all tasks to do',
        'tasks not started',
        'overdue tasks',
        'draft tasks',
      ]

      const filterTasks = (chip: string) => {
        chip === 'overdue tasks'
          ? (filters.value.before = format(
              endOfDay(subDays(new Date(), 1)),
              'yyyy-MM-dd HH:mm:ss'
            ))
          : (filters.value.before = format(
              endOfDay(new Date()),
              'yyyy-MM-dd HH:mm:ss'
            ))

        chip === 'draft tasks'
          ? (filters.value.log_statuses = ['Draft'])
          : (filters.value.log_statuses = ['Incomplete'])

        chip === 'tasks not started'
          ? (filters.value.log_statuses = ['Not Started', 'Incomplete'])
          : (filters.value.log_statuses = ['Incomplete'])
      }

      watch(
        () => props.queryFilter,
        () => {
          filters.value.filters = []

          if (props.queryFilter && props.queryFilter.project_id) {
            filters.value.filters.push(
              ['project_id', '=', props.queryFilter.project_id],
              ['project_parent_id', '=', props.queryFilter.project_id]
            )
          }
        }
      )

      return {
        filters,
        props,
        chips,
        filterTasks,
      }
    },
  })
