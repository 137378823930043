
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { reactive, defineComponent, computed, onMounted } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { RegulatoryReportType } from '@/types/modules/regulatory_reports/RegulatoryReportType'
  import { renderCellLink } from '@/composables/Grids'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const chips = computed(() => {
        let names = store.getters['regReportTypes/types'].map(
          (type: RegulatoryReportType) => type.name
        )
        return ['all'].concat(names)
      })

      const state = reactive({
        activeChip: 'all',
        chips: chips,
      })

      const setChip = (chip: string) => {
        state.activeChip = chip
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'Name',
          dataField: 'name',
          allowGrouping: true,
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Full Report',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'View Report', 'View Report', {
              id: options.data?.project?.id,
              projectType: `${options.data?.project?.project_type_label}s`,
            })
          },
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Status',
          dataField: 'regulatory_report_job_status.name',
          allowGrouping: false,
        },
        {
          caption: 'Report Period',
          dataField: 'report_period',
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          allowGrouping: true,
        },
      ])

      const filters = computed(() => {
        return {
          project_id: props.id,
          relations: ['regulatory_report_job_status'],
          ...(state.activeChip &&
            state.activeChip !== 'all' && {
              report_type_id: store.getters['regReportTypes/types'].find(
                (type: RegulatoryReportType) => type.name === state.activeChip
              ).id,
            }),
        }
      })
      onMounted(() => {
        store.dispatch('regReportTypes/index', {})
      })
      return { setChip, columns, state, filters }
    },
  })
