import axios from 'axios'

export default {
  store: async (id: number, payload: object) => {
    return axios
      .post(`bespoke-reports/${id}/drafts`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  restore: async (id: number) => {
    return axios
      .post(`bespoke-reports/drafts/${id}/restore`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  delete: async (id: number) => {
    return axios
      .delete(`bespoke-reports/drafts/${id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
