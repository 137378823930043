import { useRoute } from 'vue-router'
import router from '@/router'

export const isTab = (value: string) => useRoute().query.tab === value

export const goBack = () => {
  if (router.options.history.state.back) {
    router.push(router.options.history.state.back.toString())
  }
}

export const getApiUrl = () => {
  let protocol = process.env.VUE_APP_PROTOCOL
  let domain = getClientSubdomain()
  let api_subdomain = process.env.VUE_APP_API_SUBDOMAIN
  let base_domain = process.env.VUE_APP_BASE_DOMAIN

  return `${protocol}${domain}.${api_subdomain}.${base_domain}`
}

export const getClientSubdomain = () => {
  return new URL(location.href).hostname.split('.')[0]
}

export const getZapierBaseUrl = () => {
  return process.env.VUE_APP_ZAPIER_BASE_DOMAIN
}

export const getZapierErrorHook = () => {
  return process.env.VUE_APP_ZAPIER_ERROR_HOOK
}

export const getPreviewUrl = (element: any, isDirectFile = false) => {
  return new Promise((resolve, reject) => {
    const uploadImage = isDirectFile ? element : element.files[0]
    const reader = new FileReader()

    if (!uploadImage) {
      reject('File provided is invalid')
    }

    // Success and Error event handlers
    reader.onload = (event) => resolve(event.target?.result)
    reader.onerror = (event) => reject(event.target?.error)

    // Read the uploaded image file
    reader.readAsDataURL(uploadImage)
  })
}
