
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import isWithinInterval from 'date-fns/isWithinInterval'
  export default defineComponent({
    props: {
      disableCell: {
        type: Boolean,
        default: true,
      },
      cellData: {
        required: true,
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const state = reactive({
        disabled: false,
      })

      const getDisabledClass = computed(() =>
        state.disabled ? 'disabled-date' : ''
      )

      const setCurrentDateTimeStyles = () => {
        if (
          isWithinInterval(new Date(), {
            start: props.cellData.startDate,
            end: props.cellData.endDate,
          })
        ) {
          let cell = document.querySelector(
            `[data-cell-date="${props.cellData.startDate}"]`
          )

          if (cell) {
            cell
              .closest('.dx-scheduler-date-table-cell')
              ?.classList.add('is-current-time-cell')
          }
        }
      }

      const checkDateDisabled = () => (state.disabled = props.disableCell)

      onMounted(() => {
        setCurrentDateTimeStyles()
        checkDateDisabled()
      })

      return {
        props,
        getDisabledClass,
      }
    },
  })
