import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_table = _resolveComponent("home-table")!
  const _component_home_settings = _resolveComponent("home-settings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTab('homes'))
      ? (_openBlock(), _createBlock(_component_home_table, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isTab('settings'))
      ? (_openBlock(), _createBlock(_component_home_settings, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}