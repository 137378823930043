
  import { defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import AuthAcknowledgements from './partials/Acknowledgements/AuthAcknowledgements.vue'
  import DocumentAcknowledgements from './partials/Acknowledgements/DocumentAcknowledgements.vue'
  export default defineComponent({
    components: {
      AuthAcknowledgements,
      DocumentAcknowledgements,
    },
    setup() {
      const route = useRoute()
      return {
        route,
      }
    },
  })
