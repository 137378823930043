import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_component_data_grid, {
      "read-only": _ctx.props.readOnly,
      key: _ctx.state.componentKey,
      action: "youngPeople/index",
      "extra-options": _ctx.state.extraOptions,
      columns: _ctx.columns,
      "query-filter": _ctx.filters,
      chips: _ctx.state.chips,
      "default-chip": _ctx.state.activeChip,
      onChipClicked: _ctx.chipClicked,
      onOptionClicked: _ctx.optionClicked,
      onRowsSelected: _ctx.rowsSelected
    }, null, 8, ["read-only", "extra-options", "columns", "query-filter", "chips", "default-chip", "onChipClicked", "onOptionClicked", "onRowsSelected"])),
    _createVNode(_component_confirm_dialog, {
      show: _ctx.state.showDeleteManyConfirmDialog,
      title: "Confirm Deletion",
      content: "Are you sure you want to delete the selected records?",
      onConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteSelectedRecords())),
      onCancelled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.showDeleteManyConfirmDialog = false))
    }, null, 8, ["show"])
  ], 64))
}