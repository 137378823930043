export default {
  publishedBespokeReports: {
    index: {
      title: 'Published Reports',
    },
    delete: {
      title: 'Delete Published Reports',
    },
    restore: {
      title: 'Deleted Published Reports',
    },
    crumbs: {
      dashboard: 'Dashboard',
      bespokeReporting: 'Bespoke Reporting',
      publishedReports: 'Published Reports',
    },
    extraOptions: {
      delete: 'Delete',
      viewDeletedReports: 'View Deleted Reports',
    },
    grids: {
      id: 'ID',
      name: 'Report Name',
      description: 'Description',
      publisher: 'Publisher',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      deletedAt: 'Deleted At',
      configureLink: {
        heading: 'Configure Reports',
        cell: 'Configure',
      },
    },
  },
}
