import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.show = !_ctx.state.show))
    }, [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.state.show)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 0,
              class: _normalizeClass(_ctx.dropdownClasses),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.contentClicked && _ctx.contentClicked(...args)))
            }, [
              _renderSlot(_ctx.$slots, "content")
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ])), [
    [_directive_click_outside, () => (_ctx.state.show = false)]
  ])
}