
  import { defineComponent, PropType, reactive, Ref, ref, watch } from 'vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import BespokeReportWizard from '@/views/modules/reporting/partials/wizard/BespokeReportWizard.vue'
  import ReportDetails from '@/views/modules/reporting/partials/wizard/ReportDetails.vue'
  import ReportDatasources from '@/views/modules/reporting/partials/wizard/ReportDatasources.vue'
  import ReportPermissions from '@/views/modules/reporting/partials/wizard/ReportPermissions.vue'
  import { object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import {
    PayloadKey,
    PublishedBespokeReport,
    PublishedBespokeReportPayload,
  } from '@/types/modules/reporting/PublishedBespokeReport'
  import { WizardStep } from '@/types/components/WizardStep'

  export default defineComponent({
    components: {
      ValidationErrors,
      BespokeReportWizard,
      ReportDetails,
      ReportDatasources,
      ReportPermissions,
    },
    emits: ['step-changed', 'payload-updated', 'submit-clicked'],
    props: {
      report: {
        type: Object as PropType<PublishedBespokeReport | null>,
        required: false,
      },
      errors: {
        type: Object as PropType<Record<string, string> | null>,
        required: false,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)
      const activeStep = ref(1) as Ref<number>
      const errors = ref(null) as Ref<Record<string, string> | null>
      const steps = ref([
        {
          icon: 'far fa-memo-circle-info',
          label: 'Details',
        },
        {
          icon: 'far fa-shield-keyhole',
          label: 'Permissions',
        },
      ] as WizardStep[])

      const payload = ref({
        name: '',
        description: '',
        whitelisted_users: [] as number[],
      }) as Ref<PublishedBespokeReportPayload>

      const publishedBespokeReportDetailsValidation = object({
        name: string().required('Please enter a report name'),
        description: string().required('Please enter a report description'),
      })

      const validateStep = async (step: number) => {
        if (step > 1) {
          errors.value = await validatePayload(
            publishedBespokeReportDetailsValidation,
            payload.value as unknown as Record<string, unknown>
          )
          if (errors.value) {
            activeStep.value = 1
            return false
          }
        }

        return true
      }

      const stepChanged = async (step: number) => {
        if (!(await validateStep(step))) {
          return
        }
        activeStep.value = step
        emit('step-changed', step)
      }

      const payloadUpdated = (details: Record<PayloadKey, never>) => {
        Object.keys(details).forEach((key: string) => {
          payload.value[key as PayloadKey] = details[key as PayloadKey]
        })
      }

      const submitClicked = () => emit('submit-clicked', payload.value)

      watch(
        () => props.errors,
        (value) => {
          if (value) {
            errors.value = value
          }
        }
      )

      return {
        props,
        steps,
        payload,
        errors,
        activeStep,
        stepChanged,
        submitClicked,
        payloadUpdated,
      }
    },
  })
