export default {
  employeeRecruitmentChecks: {
    header: 'Recruitment Checks',
    rightToWork: {
      reviewedBy: 'Reviewed And Approved By',
      reviewDate: 'Date Of Review And Approval',
      updatedBy: 'Last Updated By',
      updatedOn: 'Last Updated on',
      documentType: 'Document Type',
      supportingFiles: 'Supporting File(s)',
      confirmation:
        'I have reviewed the provided document types and confirm that the employee {name} has the right to work',
    },
    dbsCheck: {
      dbsNum: 'DBS Application Number',
      applicationStatus: 'Application Status',
      childrensBarredList: "Children's Barred List",
      adultsBarredList: "Adult's Barred List",
      issuesEncountered: 'Issues Encountered',
      expiresOn: 'Expires On',
      refresherDate: 'Refresher Date',
      createdBy: 'Created By',
      createdOn: 'Created On',
      updatedBy: 'Last Updated By',
      updatedOn: 'Last Updated On',
      documentType: 'Document Type',
      supportingFiles: 'Supporting File(s)',
    },
    proofOfIdentity: {
      reviewedBy: 'Reviewed And Approved By',
      reviewDate: 'Date Of Review And Approval',
      updatedBy: 'Last Updated By',
      updatedOn: 'Last Updated On',
      documentType: 'Document Type',
      supportingFiles: 'Supporting File(s)',
      confirmation:
        "I have reviewed the documents and confirmed the employee's identity",
    },
    overseasCriminalRecord: {
      checkRequired: 'Is Check Required?',
      applicationStatus: 'Application Status',
      reviewedBy: 'Reviewed And Approved By',
      reviewDate: 'Date Of review And Approval',
      updatedBy: 'Last Updated By',
      updatedOn: 'Last Updated On',
      documentType: 'Document Type',
      supportingFiles: 'Supporting File(s)',
    },
    history: {
      header: 'History',
      grid: {
        headers: {
          createdAt: 'Date of Review',
          reviewedBy: 'Reviewed By',
          selectedEvidenceTypes: 'Document Types',
          supportingFiles: 'Supporting Files',
        },
      },
    },
  },
}
