
  import { defineComponent, onMounted, Ref, ref, reactive, computed } from 'vue'
  import { useStore } from 'vuex'
  import { User } from '@/types/modules/users/User'
  import TransferList from '@/components/Generic/List/TransferList.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      TransferList,
      CcButton,
    },
    props: {
      taskId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const key = ref(0)
      const users = computed(() => store.getters['users/users'])
      const payload = ref({ task_id: null, users: [] }) as Ref<{
        task_id: number | null
        users: User[]
      }>

      const updatePayload = (users: User[]) => (payload.value.users = users)

      const getTaskAccessBlacklist = () => {
        return store
          .dispatch('tasks/getBlacklistedUsers', props.taskId)
          .then((response) => {
            payload.value.users = response.data
            key.value++
          })
      }

      const updateTaskBlacklist = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('tasks/updateBlacklistedUsers', {
            task_id: props.taskId,
            user_ids: payload.value.users.map((user: User) => user.id),
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            getTaskAccessBlacklist()
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)

        getTaskAccessBlacklist()
          .then(() => {
            store.dispatch('users/index', {}).then(() => {
              payload.value.task_id = props.taskId
            })
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        props,
        users,
        payload,
        updatePayload,
        updateTaskBlacklist,
        key,
      }
    },
  })
