import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import Dashboard from '../views/modules/dashboard/Index.vue'
import { canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      module: 'summary',
      middleware: [auth, canView],
    },
  },
] as RouteRecordRaw[]
