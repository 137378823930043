import axios from 'axios'

export default {
  index: async (params: any) => {
    return axios
      .get('users', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: async (params: any) => {
    return axios
      .get(`users/${params.id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserModulePermissions: async (params: any) => {
    return axios
      .get('users/permissions/module', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserCareGroupPermissions: async (params: any) => {
    return axios
      .get('users/permissions/company', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserDocumentPermissions: async (params: any) => {
    return axios
      .get('users/permissions/document', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateUserPermissions: async (payload: any) => {
    return axios
      .post('users/permissions', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserPermissionLogs: async (params: any) => {
    return axios
      .get('users/audit/permissions/project', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserModulePermissionLogs: async (params: any) => {
    return axios
      .get('users/audit/permissions/module', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserCareGroupPermissionLogs: async (params: any) => {
    return axios
      .get('users/audit/permissions/caregroup', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  changePassword: async (payload: {
    user_id: number
    current: string
    password: string
    password_confirmation: string
  }) => {
    return axios
      .put(`users/${payload.user_id}/change-password`, {
        current: payload.current,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  passwordPolicy: (params: any) => {
    params = params

    return {
      success: true,
      message: 'returned password policy',
      data: [
        {
          id: 16,
          setting_category_id: 2,
          name: 'Default Password Expire Time',
          system_name: 'password_expire_time',
          description:
            'The time that should elapse before a user default password expires',
          value: '2016',
          updated_by: 'null',
          type: 'time',
          created_at: 'null',
          updated_at: 'null',
          preferred_unit: 'months',
          saved_unit: 'hours',
          is_read_only: 0,
          options: '',
        },
        {
          id: 22,
          setting_category_id: 2,
          name: 'Enforce at least one lower case letter',
          system_name: 'enforce_one_or_more_lowercase',
          description:
            'When turned on, new passwords must include one or more lowercase letters.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 23,
          setting_category_id: 2,
          name: 'Enforce at least one number',
          system_name: 'enforce_one_or_more_number',
          description:
            'When turned on, new passwords must include at least one number.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 24,
          setting_category_id: 2,
          name: 'Enforce at least one special character',
          system_name: 'enforce_one_or_more_special_char',
          description:
            'When turned on, new passwords must include at least one special character. e.g ! @ # $ % ^ & * ( ) + > < ? \\/ } { [ ] |',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:57.000000Z',
          updated_at: '2021-09-06T10:43:14.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 21,
          setting_category_id: 2,
          name: 'Enforce at least one upper case letter',
          system_name: 'enforce_one_or_more_uppercase',
          description:
            'When turned on, new passwords must include one or more uppercase letters.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 26,
          setting_category_id: 2,
          name: 'Failed login attempts',
          system_name: 'failed_login_attempt_count',
          description:
            'This refers to the number of incorrect login attempts users of the system can make before their account is locked.',
          value: '5',
          updated_by: 'null',
          type: 'number',
          created_at: '2021-09-13T22:57:42.000000Z',
          updated_at: '2021-09-13T22:57:42.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 25,
          setting_category_id: 2,
          name: 'Minimum number of characters',
          system_name: 'minimum_password_length',
          description:
            'Specifies the minimum number of characters that a password should have.',
          value: '8',
          updated_by: 'null',
          type: 'number',
          created_at: '2020-08-23T22:34:57.000000Z',
          updated_at: '2020-08-23T22:34:57.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 20,
          setting_category_id: 2,
          name: 'Prevent passwords with common words',
          system_name: 'prevent_common_words',
          description:
            'When this is turned on, new passwords will be checked against existing list of common, less secure passwords. If the password contains any of the common words, it will be rejected.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2021-09-06T10:43:14.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
      ],
    }
  },
  submitPasswordPolicy: (payload: any) => {
    payload = payload

    return {
      success: true,
      message: `submitted password policy: ${JSON.stringify(payload)}`,
      data: [
        {
          id: 16,
          setting_category_id: 2,
          name: 'Default Password Expire Time',
          system_name: 'password_expire_time',
          description:
            'The time that should elapse before a user default password expires',
          value: '2016',
          updated_by: 'null',
          type: 'time',
          created_at: 'null',
          updated_at: 'null',
          preferred_unit: 'months',
          saved_unit: 'hours',
          is_read_only: 0,
          options: '',
        },
        {
          id: 22,
          setting_category_id: 2,
          name: 'Enforce at least one lower case letter',
          system_name: 'enforce_one_or_more_lowercase',
          description:
            'When turned on, new passwords must include one or more lowercase letters.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 23,
          setting_category_id: 2,
          name: 'Enforce at least one number',
          system_name: 'enforce_one_or_more_number',
          description:
            'When turned on, new passwords must include at least one number.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 24,
          setting_category_id: 2,
          name: 'Enforce at least one special character',
          system_name: 'enforce_one_or_more_special_char',
          description:
            'When turned on, new passwords must include at least one special character. e.g ! @ # $ % ^ & * ( ) + > < ? \\/ } { [ ] |',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:57.000000Z',
          updated_at: '2021-09-06T10:43:14.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 21,
          setting_category_id: 2,
          name: 'Enforce at least one upper case letter',
          system_name: 'enforce_one_or_more_uppercase',
          description:
            'When turned on, new passwords must include one or more uppercase letters.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2020-08-23T22:34:56.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 26,
          setting_category_id: 2,
          name: 'Failed login attempts',
          system_name: 'failed_login_attempt_count',
          description:
            'This refers to the number of incorrect login attempts users of the system can make before their account is locked.',
          value: '5',
          updated_by: 'null',
          type: 'number',
          created_at: '2021-09-13T22:57:42.000000Z',
          updated_at: '2021-09-13T22:57:42.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 25,
          setting_category_id: 2,
          name: 'Minimum number of characters',
          system_name: 'minimum_password_length',
          description:
            'Specifies the minimum number of characters that a password should have.',
          value: '8',
          updated_by: 'null',
          type: 'number',
          created_at: '2020-08-23T22:34:57.000000Z',
          updated_at: '2020-08-23T22:34:57.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
        {
          id: 20,
          setting_category_id: 2,
          name: 'Prevent passwords with common words',
          system_name: 'prevent_common_words',
          description:
            'When this is turned on, new passwords will be checked against existing list of common, less secure passwords. If the password contains any of the common words, it will be rejected.',
          value: '1',
          updated_by: 'null',
          type: 'boolean',
          created_at: '2020-08-23T22:34:56.000000Z',
          updated_at: '2021-09-06T10:43:14.000000Z',
          preferred_unit: 'null',
          saved_unit: 'null',
          is_read_only: 0,
          options: '',
        },
      ],
    }
  },
  authorisedDomains: (params: any) => {
    params = params
    return axios
      .get('users/domains/whitelist', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  ipAddresses: (params: any) => {
    return axios
      .get('ip_addresses', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
