import axios from 'axios'

export default {
  store: async (payload: any) => {
    return axios
      .post(`users/permissions/projects/${payload.id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
