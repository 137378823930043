
  import { computed, defineComponent, onMounted, Ref, ref, reactive } from 'vue'
  import {
    DxDiagram,
    DxNodes,
    DxAutoLayout,
    DxCustomShape,
  } from 'devextreme-vue/diagram'
  import { useStore } from 'vuex'
  import TaskCardTemplate from './partials/TaskCardTemplate.vue'

  export default defineComponent({
    components: {
      DxDiagram,
      DxNodes,
      DxAutoLayout,
      DxCustomShape,
      TaskCardTemplate,
    },
    props: {
      taskId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const data = computed(() => store.getters['tasks/workflowTasks'])
      const itemTypeExpr = (obj: { id: number }) => `task${obj.id}`
      const diagram = ref() as Ref<DxDiagram>

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('tasks/getWorkflowForTask', props.taskId)
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        data,
        diagram,
        itemTypeExpr,
      }
    },
  })
