
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import StakeholderPositionsTable from './partials/Stakeholders/StakeholderPositionsTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      StakeholderPositionsTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Stakeholder Positions',
        tabs: ['Stakeholder Positions'],
      })

      return {
        state,
      }
    },
  })
