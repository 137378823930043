import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    action: "publishedBespokeReportComments/index",
    columns: _ctx.columns,
    "query-filter": _ctx.filters,
    "extra-options": _ctx.extraOptions,
    onOptionClicked: _ctx.optionClicked,
    onPrimaryButtonClicked: _ctx.addClicked,
    onRowsSelected: _ctx.rowsSelected
  }, null, 8, ["columns", "query-filter", "extra-options", "onOptionClicked", "onPrimaryButtonClicked", "onRowsSelected"]))
}