import { MultiStep } from '@/types/components/MultiStep'
import { FormField, ExtendedFormField } from '@/types/modules/forms/FormField'
import { cloneDeep } from 'lodash'
import { reactive, ref, Ref } from 'vue'

export const buildFields = (fields: FormField[]) => {
  let formFields = cloneDeep(fields) as ExtendedFormField[]
  const formattedFields = ref([]) as Ref<ExtendedFormField[]>
  const state = reactive({
    steps: [] as MultiStep[],
    active_multi_step: undefined as number | undefined,
  })

  formFields
    .filter((formField: ExtendedFormField) => !formField.parent_id)
    .forEach((formField: ExtendedFormField) => {
      if (
        formField.field?.system_name !== 'multi_step_form_section' &&
        !state.active_multi_step
      ) {
        formField.multistep = undefined
        formField.is_visible = true
        formattedFields.value.push(formField)
        return
      }

      if (formField.field?.system_name === 'multi_step_form_section') {
        state.steps.push({
          id: formField.id,
          name: formField.name || `step: ${state.steps.length}`,
          fields: [],
        })

        state.active_multi_step = formField.id
      }

      formField.multistep = state.active_multi_step
      formField.is_visible = true

      if (
        state.active_multi_step &&
        formField.field?.system_name !== 'multi_step_form_section'
      ) {
        for (let step of state.steps) {
          if (step.id === formField.multistep) {
            step.fields.push(formField)
            break
          }
        }
      }
    })

  return {
    fields: formattedFields.value,
    state: state,
  }
}
