import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d467a6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full flex flex-col items-center justify-center" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.props.cellData.data.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "resource-image",
          src: _ctx.state.imageLoading ? _ctx.state.defaultImage : _ctx.cellData.data.image,
          alt: _ctx.props.cellData.data.name,
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setDefaultImage && _ctx.setDefaultImage(...args))),
          onLoad: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.imageLoading = false))
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.cellData.data.name), 1)
  ]))
}