import { RouteRecordRaw } from 'vue-router'
import RotasIndex from '@/views/modules/rotas/Index.vue'
import auth from '@/middleware/auth/auth'
import { canView, canEdit } from '@/middleware/permissions/module'
import ManageShiftType from '@/views/lookups/ManageShiftType.vue'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import RestoreShiftTypes from '@/views/modules/rotas/employees/partials/RestoreShiftTypes.vue'

export default [
  {
    path: '/rotas',
    name: 'Rotas',
    component: RotasIndex,
    meta: {
      title: 'Rotas Overview',
      module: 'rotas',
      middleware: [auth, canView],
    },
  },
  {
    path: '/rotas/shifts/types/create',
    name: 'ShiftTypeCreate',
    component: ManageShiftType,
    props: () => ({
      showAction: 'shiftTypes/show',
      storeAction: 'shiftTypes/store',
    }),
    meta: {
      title: 'Create Shift Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/rotas/shifts/types/:id/edit',
    name: 'ShiftTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'shiftTypes/show',
      updateAction: 'shiftTypes/update',
      deleteAction: 'shiftTypes/delete',
    }),
    component: ManageShiftType,
    meta: {
      title: 'Update Shift Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/rotas/shifts/types/delete',
    name: 'ShiftTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Shift Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/rotas/shifts/types/restore',
    name: 'ShiftTypeRestore',
    component: RestoreShiftTypes,
    meta: {
      title: 'Restore Deleted Shift Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
