export default {
    terms: {
        heading: 'Terms and Conditions of Use',
        introduction: `Before subscribing to www.clearcare.co.uk please read this Agreement
          relating to the use of this website carefully. Once you have read and
          understand the conditions, please confirm your acceptance of this
          Agreement by selecting Accept below.`,
        definitions: {
          heading: 'Definitions',
          text: ` Company means Clearcare Solutions Limited Site means the website of
              the Company www.clearcare.co.uk and all materials relating to the
              Companys services on the site or sent to you by email or any other
              means. Reference to the site includes all Intellectual Property rights
              in the site and moral rights. User means the person, firm or company
              entering into this Agreement. Terms means these Terms and Conditions
              and any subsequent amendments as may be notified to you.`,
        },
        service: {
          heading: 'Our Service',
          1: {
            heading: '1. Acceptance of Terms',
            text: `By using www.clearcare.co.uk, you agree to be bound by these terms and
              conditions of use (Terms). If you do not agree to these Terms, please
              do not use Clearcare.co.uk. Clearcare Solutions Ltd provides the
              information and services on www.clearcare.co.uk to you, the user,
              conditioned upon your acceptance, without modification, of the Terms
              contained herein. Your use of www.clearcare.co.uk constitutes your
              agreement with such Terms. We reserve the right, at our discretion, to
              change, modify, add or remove portions of these Terms periodically.
              Such modifications shall be effective immediately upon posting of the
              modified Terms to www.clearcare.co.uk. Your continued use of the
              Clearcare.co.uk website following the posting of changes to these
              Terms will mean that you accept those changes. In addition, each
              user's use of a particular www.clearcare.co.uk service (Service) may
              be subject to specific guidelines or rules (Service-specific Rules)
              posted from time to time and incorporated by this reference into the
              Terms. Use of www.clearcare.co.uk and/or its Services constitutes full
              acceptance of and agreement to the Terms; if a user does not accept
              our Terms, he or she is not granted rights to use www.clearcare.co.uk
              or any of its Services, as defined herein, and should refrain from
              accessing www.clearcare.co.uk and its Services. To update the Terms,
              we will both post the changed version and its effective date at
              http://www.clearcare.co.uk. If we change any Service-specific Rules,
              we will post the changed version on the location where those
              Service-specific Rules normally appear, reference the change on the
              primary page for that Service and include a link to the previous
              version of the terms or rules. Clearcare Solutions Ltd reserves the
              right at any time and from time to time to modify or discontinue,
              temporarily or permanently, www.clearcare.co.uk or any Service thereon
              (or any part thereof). Clearcare Solutions Ltd shall not be liable to
              any user or other third party for any such modification, suspension or
              discontinuance except as expressly provided herein.`,
          },
        },
      },
}