import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!

  return (_openBlock(), _createBlock(_component_button_group, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
        return (_openBlock(), _createBlock(_component_cc_button, {
          key: index,
          label: button.label,
          type: _ctx.state.permission === button.value ? 'tab-active' : 'tab-inactive',
          onButtonClicked: ($event: any) => (_ctx.updatePermission(button.value))
        }, null, 8, ["label", "type", "onButtonClicked"]))
      }), 128))
    ]),
    _: 1
  }))
}