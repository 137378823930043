
  import { defineComponent, reactive, computed, onMounted, PropType } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useI18n } from 'vue-i18n'
  import { Column } from 'devextreme/ui/data_grid'
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { MedicationLocation } from '@/types/modules/mars/MedicationLocation'
  import { Project } from '@/types/modules/projects/Project'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const router = useRouter()

      const columns = computed((): Column[] => [
        {
          caption: t('mars.medicalCabinet.grid.headers.id'),
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.name'),
          dataField: 'name',
          allowGrouping: false,
          cellTemplate: 'MedicationNameCellTemplate',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.currentStock'),
          dataField: 'stock_level',
          dataType: 'number',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.type'),
          dataField: 'stock_type.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.category'),
          dataField: 'stock_category.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.requiresAuth'),
          dataField: 'requires_auth',
          calculateCellValue: (row: MedicationStock) => {
            return row.requires_auth
              ? t('components.grid.cells.yes')
              : t('components.grid.cells.no')
          },
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.adjustStock'),
          cellTemplate: 'AdjustStockCellTemplate',
          allowExporting: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.stockHistory'),
          cellTemplate: 'StockHistoryCellTemplate',
          allowExporting: false,
        },
      ])

      const activeChip = computed(() =>
        store.getters['genericStore/activeChips']
          ? store.getters['genericStore/activeChips'][route.fullPath]
          : null
      )

      const state = reactive({
        medicationLocations: [] as MedicationLocation[],
        chips: [] as string[],
        activeLocationId: undefined as number | undefined,
        extraOptions: [
          t('mars.medicalCabinet.toolbarOptions.modifyMedicalCabinets'),
        ] as string[],
        selectedRows: [] as MedicationStock[],
        ready: false,
      })

      const chipClicked = (chip: string) => {
        store.dispatch('genericStore/setActiveChip', { [route.fullPath]: chip })
        state.activeLocationId = state.medicationLocations.find(
          (location: MedicationLocation) => location.name === chip
        )?.id
      }

      const rowsSelected = (rows: MedicationStock[]) => {
        state.selectedRows = rows
        if (
          !state.extraOptions.includes(
            t('mars.medicalCabinet.toolbarOptions.transferStock')
          ) &&
          rows.length
        ) {
          state.extraOptions.push(
            t('mars.medicalCabinet.toolbarOptions.transferStock')
          )
        } else if (
          state.extraOptions.includes(
            t('mars.medicalCabinet.toolbarOptions.transferStock')
          ) &&
          !rows.length
        ) {
          state.extraOptions.splice(
            state.extraOptions.indexOf(
              t('mars.medicalCabinet.toolbarOptions.transferStock')
            ),
            1
          )
        }
        if (
          !state.extraOptions.includes(
            t('mars.medicalCabinet.toolbarOptions.delete')
          ) &&
          rows.length
        ) {
          state.extraOptions.push(
            t('mars.medicalCabinet.toolbarOptions.delete')
          )
        } else if (
          state.extraOptions.includes(
            t('mars.medicalCabinet.toolbarOptions.delete')
          ) &&
          !rows.length
        ) {
          state.extraOptions.splice(
            state.extraOptions.indexOf(
              t('mars.medicalCabinet.toolbarOptions.delete')
            ),
            1
          )
        }
      }

      const extraOptionClicked = (option: string) => {
        if (option === t('mars.medicalCabinet.toolbarOptions.delete')) {
          let validStocks = state.selectedRows.filter(
            (stock: MedicationStock) => !stock.stock_in_use
          )

          store.dispatch('mars/setStocksToDelete', validStocks)
          router.push({
            name: 'StockDelete',
            params: {
              id: route.params.id,
            },
          })
        }

        if (option === t('mars.medicalCabinet.toolbarOptions.transferStock')) {
          store.dispatch('mars/setStockToTransfer', state.selectedRows)
          router.push({
            name: 'StockTransfer',
            params: {
              medicationLocationId: state.activeLocationId,
              id: route.params.id,
            },
          })
        }

        if (
          option ===
          t('mars.medicalCabinet.toolbarOptions.modifyMedicalCabinets')
        ) {
          router.push({
            name: 'ManageMedicationLocations',
            params: {
              projectType: `${props.project.project_type_label}s`,
              id: props.project.id,
            },
          })
        }
      }

      const createMedication = () => {
        if (!state.medicationLocations.length) {
          store.dispatch(
            'genericStore/pushNotification',
            'Please create a medical cabinet before adding medication'
          )
          return
        }

        if (!state.activeLocationId) {
          store.dispatch(
            'genericStore/pushNotification',
            'Please select a medical cabinet before adding medication'
          )
          return
        }

        router.push({
          name: 'StockCreate',
          params: {
            id: route.params.id,
            locationId: state.activeLocationId,
          },
        })
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('mars/getMedicationLocations', {
            project_id: props.project.id,
          })
          .then((response: any) => {
            state.chips = response.data.map(
              (location: MedicationLocation) => location.name
            )
            state.medicationLocations = response.data
            if (response.data.length) {
              chipClicked(
                activeChip.value ? activeChip.value : response.data[0].name
              )
            }
            state.ready = true
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        props,
        state,
        columns,
        activeChip,
        t,
        chipClicked,
        rowsSelected,
        extraOptionClicked,
        createMedication,
      }
    },
  })
