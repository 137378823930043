import { ActionTree, GetterTree, MutationTree } from 'vuex'
import EmployeeShiftService from '@/services/rotas/EmployeeShifts'
import { ShiftInstance } from '@/types/modules/rotas/ShiftInstance'

export type EmployeeShiftGetter = GetterTree<EmployeeShiftState, any>

export interface EmployeeShiftState {
  shifts: ShiftInstance[]
}

export const state: EmployeeShiftState = {
  shifts: [],
}

export const getters: EmployeeShiftGetter = {
  getShifts: (state) => state.shifts,
}

export const actions: ActionTree<EmployeeShiftState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(EmployeeShiftService.index(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setShifts', response.data)
    })

    return results
  },
}

export const mutations: MutationTree<EmployeeShiftState> = {
  setShifts: (state, shifts) => (state.shifts = shifts),
}

export const employeeShifts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
