
  import { defineComponent, reactive } from 'vue'
  import DropDown from '../Dropdown/DropDown.vue'
  import CountBadge from '@/components/Generic/Badge/CountBadge.vue'
  import SelectInput from '../Forms/Inputs/SelectInput.vue'
  import CcButton from '../Button/Button.vue'

  export default defineComponent({
    components: {
      DropDown,
      CountBadge,
      SelectInput,
      CcButton,
    },
    props: {
      widget: {
        type: Object,
        required: true,
      },
    },
    emits: ['delete-widget', 'export-to-png', 'move-widget'],
    setup(props, { emit }) {
      props = reactive(props)

      const exportToPng = () => emit('export-to-png')

      return {
        props,
        exportToPng,
      }
    },
  })
