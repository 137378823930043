
  import { Project } from '@/types/modules/projects/Project'
  import { defineComponent, PropType, reactive, watch, onMounted } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useStore } from 'vuex'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import RotaDateRange from '../partials/RotaDateRange.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DefaultShiftPattern from './partials/DefaultShiftPattern.vue'
  import CopyToWeek from '../partials/CopyToWeek.vue'
  import LiveRota from './partials/LiveRota.vue'
  import { rotaSetting } from '@/composables/rotas/RotaSetting'
  import {
    loadDefaultShifts,
    loadDefaultShiftsForOtherProjects,
    saveDefaultShifts,
  } from '@/composables/rotas/DefaultShifts'
  import {
    shiftTypes,
    computedShiftTypes,
    getShiftTypes,
  } from '@/composables/rotas/ShiftTypes'
  import {
    loadDatesBetweenDates,
    sharedState,
    computedStartWeeks,
    defaultPatternChanged,
    loadRotaEmployees,
    resetRotaView,
    setupAddModal,
    getCopyableShifts,
    filterByShiftTypes,
    totalSteps,
    overlaps,
    reloadSchedulers,
  } from '@/composables/rotas/Rotas'
  import {
    isCycle,
    cycleChanged,
    computedRotaCycles,
    loadRotaCycles,
  } from '@/composables/rotas/RotaCycles'
  import {
    saveLiveRotas,
    loadLiveShifts,
    loadLiveShiftsForOtherProjects,
    checkExistingLiveShifts,
  } from '@/composables/rotas/ShiftInstances'
  import { addWeeks, format } from 'date-fns/esm'
  import startOfISOWeek from 'date-fns/startOfISOWeek/index'
  import endOfISOWeek from 'date-fns/fp/endOfISOWeek'
  import isDate from 'date-fns/isDate'
  import { canEditModule } from '@/composables/Permissions'

  export default defineComponent({
    components: {
      CcButton,
      ButtonGroup,
      MultiSelect,
      RotaDateRange,
      SelectInput,
      DefaultShiftPattern,
      LiveRota,
      CopyToWeek,
    },
    props: {
      project: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const changeDate = (event: any) => {
        sharedState.shifts = []
        sharedState.liveRotas = []
        sharedState.defaultRotas = []

        sharedState.timeline.current_date = new Date(event.selected_date)
        sharedState.timeline.start_date = startOfISOWeek(
          new Date(event.selected_date)
        )
        sharedState.timeline.end_date = endOfISOWeek(
          new Date(event.selected_date)
        )

        loadDatesBetweenDates(
          sharedState.timeline.start_date,
          sharedState.timeline.end_date
        )

        if (sharedState.activeTab === 'Live Rotas') {
          loadLiveShifts(
            format(sharedState.timeline.start_date, 'yyyy-MM-dd'),
            format(sharedState.timeline.end_date, 'yyyy-MM-dd'),
            props.project.id
          )

          loadLiveShiftsForOtherProjects(
            format(sharedState.timeline.start_date, 'yyyy-MM-dd'),
            format(sharedState.timeline.end_date, 'yyyy-MM-dd'),
            props.project.id
          )
        } else {
          loadDefaultShifts(props.project.id)
          loadDefaultShiftsForOtherProjects(props.project.id)
        }
      }

      const saveChanges = () => {
        if (sharedState.activeTab === 'Live Rotas') {
          if (!sharedState.liveRotas.length) {
            store.dispatch(
              'genericStore/pushNotification',
              'There are no changes to the live shifts to be saved.'
            )
            return
          }
          saveLiveRotas({}, props.project.id)
        }

        if (sharedState.activeTab === 'Default Shift Patterns') {
          if (
            !defaultPatternChanged.value &&
            !sharedState.defaultRotas.length
          ) {
            store.dispatch(
              'genericStore/pushNotification',
              'There are no changes to the default shift pattern to be saved.'
            )
            return
          }

          if (defaultPatternChanged.value) {
            return checkExistingLiveShifts(props.project.id)
          }

          if (
            sharedState.defaultRotas.filter(
              (rota: any) => rota.condition === 'deleted'
            ).length
          ) {
            return checkExistingLiveShifts(props.project.id)
          }

          return saveDefaultShifts({}, props.project.id)
        }
      }

      const init = () => {
        store
          .dispatch('settings/show', 'allow_rotas_past_edits')
          .then((response) => {
            sharedState.allow_rotas_past_edits = response.data
            getShiftTypes()
            sharedState.timeline.start_date = startOfISOWeek(new Date())
            sharedState.timeline.end_date = endOfISOWeek(new Date())
            loadRotaEmployees(props.project.id)
            loadDatesBetweenDates(
              startOfISOWeek(new Date()),
              endOfISOWeek(new Date())
            )
            loadLiveShifts(
              format(startOfISOWeek(new Date()), 'yyyy-MM-dd'),
              format(endOfISOWeek(new Date()), 'yyyy-MM-dd'),
              props.project.id
            )
            loadRotaCycles()
          })
          .finally(() => {
            loadRotaEmployees(props.project.id)
            loadLiveShiftsForOtherProjects(
              format(startOfISOWeek(new Date()), 'yyyy-MM-dd'),
              format(endOfISOWeek(new Date()), 'yyyy-MM-dd'),
              props.project.id
            )
          })
      }

      const copyShifts = (data: any) => {
        data.shifts.forEach((shift: any) => {
          if (!overlaps(shift, sharedState.shifts)) {
            sharedState.defaultRotas.push(shift)
            sharedState.shifts.push(shift)
          }
        })

        if (sharedState.overlapErrors.length) {
          store.dispatch(
            'genericStore/pushNotification',
            sharedState.overlapErrors.concat(', ')
          )
          return (sharedState.overlapErrors = [])
        }

        reloadSchedulers()
        sharedState.showCopyToModal = false
        return
      }

      watch(
        () => sharedState.activeTab,
        () => resetRotaView(props.project.id)
      )

      watch(
        () => rotaSetting.value.default_cycle_id,
        () => (sharedState.schedulers = [])
      )

      watch(
        () => sharedState.step,
        (value: any) => {
          if (value > 1) {
            sharedState.timeline.start_date = rotaSetting.value.cycle_start_date
              ? addWeeks(
                  startOfISOWeek(new Date(rotaSetting.value.cycle_start_date)),
                  value - 1
                )
              : startOfISOWeek(new Date())
            sharedState.timeline.end_date = rotaSetting.value.cycle_start_date
              ? addWeeks(
                  endOfISOWeek(new Date(rotaSetting.value.cycle_start_date)),
                  value - 1
                )
              : endOfISOWeek(new Date())
            return loadDatesBetweenDates(
              sharedState.timeline.start_date,
              sharedState.timeline.end_date
            )
          }

          sharedState.timeline.start_date = rotaSetting.value.cycle_start_date
            ? startOfISOWeek(new Date(rotaSetting.value.cycle_start_date))
            : startOfISOWeek(new Date())
          sharedState.timeline.end_date = rotaSetting.value.cycle_start_date
            ? endOfISOWeek(new Date(rotaSetting.value.cycle_start_date))
            : endOfISOWeek(new Date())
          return loadDatesBetweenDates(
            sharedState.timeline.start_date,
            sharedState.timeline.end_date
          )
        }
      )

      watch(
        () => sharedState.timeline.start_date,
        (value: any) => {
          if (isDate(value)) {
            let start_date = sharedState.timeline.start_date
              ? new Date(sharedState.timeline.start_date)
              : startOfISOWeek(new Date())
            let end_date = sharedState.timeline.end_date
              ? new Date(sharedState.timeline.end_date)
              : endOfISOWeek(new Date())

            store.dispatch('employeeJobTitles/index', {
              relations: ['tags'],
              parents: [props.project.id],
              start_date: format(start_date, 'yyyy-MM-dd'),
              end_date: format(end_date, 'yyyy-MM-dd'),
            })
          }
        }
      )

      watch(
        () => sharedState.filtered_shift_types,
        (value) => filterByShiftTypes(value)
      )

      onMounted(() => init())

      return {
        saveChanges,
        getCopyableShifts,
        rotaSetting,
        isCycle,
        sharedState,
        props,
        shiftTypes,
        computedShiftTypes,
        setupAddModal,
        changeDate,
        computedRotaCycles,
        cycleChanged,
        computedStartWeeks,
        totalSteps,
        copyShifts,
        canEditModule,
      }
    },
  })
