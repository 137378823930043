
  import { useStore } from 'vuex'
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    watch,
  } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useRouter } from 'vue-router'
  import { Vehicle } from '@/types/modules/projects/Vehicle'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import { RecycleBin } from '@/types/modules/generic/RecycleBin'
  import { isTab } from '@/composables/Generic'

  export default defineComponent({
    components: {
      DataGrid,
      ConfirmDialog,
    },
    props: {
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const query = ref({})
      const router = useRouter()

      const state = reactive({
        componentKey: 0,
        showHidden: false as boolean,
        activeChip: 'current' as string,
        chips: ['all', 'current', 'planned', 'past'],
        extraOptions: [] as string[],
        selectedRows: [] as Vehicle[],
        showDeleteManyConfirmDialog: false,
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'project_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'project.name',
          caption: 'Vehicle Name',
          allowSorting: false,
          cellTemplate: 'ProjectNameCellTemplate',
        },
        {
          dataField: 'project.location.name',
          caption: 'Location',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'project.start_date',
          caption: 'Start Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.end_date',
          caption: 'End Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.status',
          caption: 'Status',
          allowSorting: false,
        },
        {
          dataField: 'make',
          caption: 'Make',
        },
        {
          dataField: 'model',
          caption: 'Model',
        },
        {
          dataField: 'registration_number',
          caption: 'Registration No',
          visible: false,
        },
        {
          dataField: 'registration_date',
          caption: 'Registration Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'insurance_date',
          caption: 'Insurance Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'tax_date',
          caption: 'Tax Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'mot_date',
          caption: 'MOT Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'fuel',
          caption: 'Fuel',
          visible: false,
        },
        {
          dataField: 'ownership',
          caption: 'Ownership',
          visible: false,
        },
        {
          dataField: 'purchase_price',
          caption: 'Purchase Price',
          visible: false,
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.project?.id,
            })
          },
          allowExporting: false,
        },
        {
          caption: 'Create Task',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Create Task', 'CreateTask', {
              id: options.data?.project?.id,
              projectType: `${options.data?.project?.project_type_label}s`,
            })
          },
          allowExporting: false,
        },
      ])

      const filters = computed(() => {
        return {
          relations: ['project.parents'],
          ...query.value,
          ...props.filters,
        }
      })

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip && { status: state.activeChip }),
        }
      }

      const addClicked = () => {
        router.push({
          name: 'VehicleCreate',
        })
      }

      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/deleteMany', {
            ids: state.selectedRows.map((row: Vehicle) => row.project_id),
          })
          .then(() => {
            state.selectedRows = []
            state.showDeleteManyConfirmDialog = false
          })
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => refreshGrid())
      }

      const refreshGrid = () => {
        state.componentKey += 1
      }

      const recycleBinData: RecycleBin = reactive({
        key: 'project_id',
        action: 'vehicles/index',
        restoreAction: 'projects/restoreMany',
        columns: [
          {
            caption: 'ID',
            dataField: 'project_id',
            allowGrouping: false,
            dataType: 'number',
            width: 100,
          },
          {
            dataField: 'project.name',
            caption: 'Vehicle Name',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.start_date',
            caption: 'Start Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.end_date',
            caption: 'End Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.status',
            caption: 'Status',
            allowSorting: false,
            allowGrouping: false,
          },
        ],
      })

      const viewDeletedRecords = () => {
        store.commit('genericStore/setRecycleBinData', recycleBinData)
        router.push({
          name: 'RecycleBin',
        })
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          return (state.showDeleteManyConfirmDialog = true)
        }

        return viewDeletedRecords()
      }

      watch(
        () => state.selectedRows,
        () => updateExtraOptions()
      )

      const rowsSelected = (rows: Vehicle[]) => {
        state.selectedRows = rows
      }

      const updateExtraOptions = () => {
        if (props.readOnly) {
          return
        }

        const deleteExists = state.extraOptions.indexOf('Delete')

        if (state.selectedRows.length && deleteExists < 0) {
          state.extraOptions.push('Delete')
        } else if (!state.selectedRows.length && deleteExists >= 0) {
          state.extraOptions.splice(deleteExists, 1)
        }
      }

      onMounted(() => {
        applyFilters()
        if (!props.readOnly) {
          state.extraOptions.push('View Deleted Vehicles')
        }
      })

      return {
        state,
        props,
        query,
        columns,
        filters,
        isTab,
        chipClicked,
        rowsSelected,
        optionClicked,
        deleteSelectedRecords,
        addClicked,
      }
    },
  })
