import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    columns: _ctx.columns,
    "show-grid-toolbar": false,
    "query-filter": { task_log_id: _ctx.props.taskLogId, relations: ['creator'] },
    action: "taskLogComments/index",
    onRowsSelected: _ctx.setSelectedTaskLogComments
  }, null, 8, ["columns", "query-filter", "onRowsSelected"]))
}