import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { User } from '@/types/modules/users/User'
import AuthService from '@/services/auth/auth'
import UserService from '@/services/users/users'
import router from '@/router'
import { UserAccessLog } from '@/types/modules/users/UserAccessLog'
import { UserAccessFailed } from '@/types/modules/users/UserAccessFailed'
import store from '@/store'

export interface Redirect {
  name: string
  route: string
}

export interface AuthState {
  authenticated: boolean
  authenticatedUser: User | null
  authenticatedUserPermissions: object
  authenticatedRedirects: Redirect[]
  acceptedTerms: boolean
  passwordExpired: boolean
  userAccessLogs: UserAccessLog[]
  userAccessFailedLogs: UserAccessFailed[]
}

export const state: AuthState = {
  authenticated: false,
  authenticatedUser: null,
  authenticatedUserPermissions: {},
  authenticatedRedirects: [],
  acceptedTerms: false,
  passwordExpired: false,
  userAccessLogs: [],
  userAccessFailedLogs: [],
}

export const getters: GetterTree<AuthState, any> = {
  authenticated: (state) => state.authenticated,
  authenticatedUser: (state) => state.authenticatedUser,
  authenticatedUserPermissions: (state) => state.authenticatedUserPermissions,
  authenticatedRedirects: (state) => state.authenticatedRedirects,
  acceptedTerms: (state) => state.acceptedTerms,
  passwordExpired: (state) => state.passwordExpired,
  userAccessLogs: (state) => state.userAccessLogs,
  userAccessFailedLogs: (state) => state.userAccessFailedLogs,
}

export const actions: ActionTree<AuthState, any> = {
  login: async (context, payload) => {
    await AuthService.csrf()
    const response = await AuthService.login(payload)

    if (response.success) {
      await context.dispatch('attempt', {
        redirect: true,
      })
    }

    return response
  },
  attempt: async (context, redirect: boolean) => {
    const response = await AuthService.getAuthenticatedUser()

    if (response.success) {
      localStorage.setItem('authenticated', 'true')
      context.commit('setAuthenticated', true)
      context.commit('setAuthenticatedUser', response.data.user)
      context.commit(
        'setAuthenticatedUserPermissions',
        response.data.permissions
      )

      context.commit('setAcceptedTerms', !!response.data.acceptedTerms)
      context.commit('setPasswordExpired', response.data.password_expired)
      store.commit(
        'documents/setDocumentsToAcknowledge',
        response.data.documents_to_acknowledge
      )
      store.commit('users/setSettings', response.data.app_settings)

      if (redirect) {
        const redirects: Redirect[] = [
          { name: 'summary', route: 'Dashboard' },
          { name: 'homes', route: 'Home' },
          { name: 'schools', route: 'Schools' },
          { name: 'residents', route: 'YoungPeople' },
          { name: 'employees', route: 'Employees' },
          { name: 'vehicles', route: 'Vehicle' },
          { name: 'companies', route: 'CareGroupIndex' },
          { name: 'admin', route: 'UserIndex' },
          { name: 'sysadmin', route: 'Settings' },
        ]
        context.commit('setAuthenticatedRedirects', redirects)

        context.dispatch('redirect')
      }
    }

    return response
  },
  redirect: (context) => {
    const allowed = Object.keys(context.getters['authenticatedUserPermissions'])
    const redirects = context.getters['authenticatedRedirects']
    const redirect: Redirect = redirects.find((item: Redirect) =>
      allowed.includes(item.name)
    )

    return router.push({ name: redirect.route })
  },
  logout: async (context) => {
    await AuthService.logout()
    context.commit('setAuthenticated', false)
    context.commit('setAuthenticatedUser', null)
    context.commit('setAuthenticatedUserPermissions', null)

    localStorage.removeItem('authenticated')
    localStorage.removeItem('document_acknowledgments_dismissed')

    return window.location.replace('/login')
  },
  acceptTermsConditions: async (context) => {
    const response = await AuthService.acceptTermsConditions()

    if (response.success) {
      context.commit('setAuthenticatedUser', response.data)
    }

    return response
  },
  changePassword: async (context, payload) => {
    const response = await UserService.changePassword(payload)

    if (response.success) {
      context.commit('setAuthenticatedUser', response.data)
    }

    return response
  },
  updateProfile: (context, payload) => {
    let results = Promise.resolve(AuthService.updateProfile(payload))

    results.then((response) => {
      console.log(response.message)
      context.commit('setAuthenticatedUser', response.data)
    })

    return results
  },
  getUserAccessLogs: async (context, params) => {
    const response = await AuthService.getUserAccessLogs(params)

    if (response.success && params.per_page) {
      context.commit('setUserAccessLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setUserAccessLogs', response.data)

      return response
    }

    return response
  },
  getUserAccessFailedLogs: async (context, params) => {
    const response = await AuthService.getUserAccessFailedLogs(params)

    if (response.success && params.per_page) {
      context.commit('setUserAccessFailedLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setUserAccessFailedLogs', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<AuthState> = {
  setAuthenticated: (state, authenticated: boolean) => {
    state.authenticated = authenticated
  },
  setAuthenticatedUser: (state, user: User) => {
    state.authenticatedUser = user
  },
  setAcceptedTerms: (state, accepted: boolean) => {
    state.acceptedTerms = accepted
  },
  setPasswordExpired: (state, expired: boolean) => {
    state.passwordExpired = expired
  },
  setAuthenticatedUserPermissions: (state, permissions: Object) => {
    state.authenticatedUserPermissions = permissions
  },
  setAuthenticatedRedirects: (state, redirects) => {
    state.authenticatedRedirects = redirects
  },
  setUserAccessLogs: (state, logs: UserAccessLog[]) => {
    state.userAccessLogs = logs
  },
  setUserAccessFailedLogs: (state, logs: UserAccessFailed[]) => {
    state.userAccessFailedLogs = logs
  },
}

export const auth = {
  namespaced: true,
  actions,
  mutations,
  getters,
}
