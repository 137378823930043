import { ProjectFile } from '@/types/modules/files/ProjectFile'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import FileService from '@/services/files/files'
import { CareGroupFile } from '@/types/modules/files/CareGroupFile'

export type FileGetter = GetterTree<FileState, any>

export interface FileState {
  careGroupAttachments: CareGroupFile[]
  projectFiles: ProjectFile[]
}

export const state: FileState = {
  careGroupAttachments: [],
  projectFiles: [],
}

export const getters: FileGetter = {
  careGroupAttachments: (state) => state.careGroupAttachments,
  projectFiles: (state) => state.projectFiles,
}

export const actions: ActionTree<FileState, any> = {
  getCareGroupAttachments: async (context, params) => {
    const response = await FileService.getCareGroupAttachments(params)

    if (response.success && params?.per_page) {
      context.commit('setCareGroupAttachments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setCareGroupAttachments', response.data)

      return response
    }

    return response
  },
  getSensitiveCareGroupAttachments: async (context, params) => {
    const response = await FileService.getSensitiveCareGroupAttachments(params)

    if (response.success && params?.per_page) {
      context.commit('setCareGroupAttachments', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setCareGroupAttachments', response.data)

      return response
    }

    return response
  },
  getProjectFiles: async (context, params) => {
    const response = await FileService.getProjectFiles(params)

    if (response.success && params?.per_page) {
      context.commit('setProjectFiles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setProjectFiles', response.data)

      return response
    }

    return response
  },
  getSensitiveProjectFiles: async (context, params) => {
    const response = await FileService.getSensitiveProjectFiles(params)

    if (response.success && params?.per_page) {
      context.commit('setProjectFiles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setProjectFiles', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<FileState> = {
  setCareGroupAttachments: (state, files: CareGroupFile[]) =>
    (state.careGroupAttachments = files),

  setProjectFiles: (state, projectFiles: ProjectFile[]) =>
    (state.projectFiles = projectFiles),
}

export const files = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
