
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
  import { renderCellLink } from '@/composables/Grids'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { BespokeReport } from '@/types/modules/reporting/BespokeReport'
  import { useRouter } from 'vue-router'
  import { goBack } from '@/composables/Generic'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      CcButton,
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const state = reactive({
        selectedRows: [] as PublishedBespokeReport[],
        extraOptions: [
          t('publishedBespokeReports.extraOptions.viewDeletedReports'),
        ] as string[],
      })
      const reports = computed(
        (): PublishedBespokeReport[] =>
          store.getters['publishedBespokeReports/reports']?.data
      )
      const bespokeReport = computed((): BespokeReport | null => {
        if (!reports.value) {
          return null
        }

        return reports.value[0]?.report
      })
      const gridHeading = computed((): string => {
        return (
          bespokeReport?.value?.name || t('publishedBespokeReports.index.title')
        )
      })
      const crumbs = reactive<Crumb[]>([
        {
          name: t('publishedBespokeReports.crumbs.dashboard'),
          url: '/dashboard',
        },
        {
          name: t('publishedBespokeReports.crumbs.bespokeReporting'),
          url: '/bespoke-reporting',
        },
        {
          name: t('publishedBespokeReports.crumbs.publishedReports'),
          url: `/bespoke-reporting/${bespokeReport.value?.id}/published-reports`,
        },
      ])
      const columns: Column[] = reactive([
        {
          caption: t('publishedBespokeReports.grids.id'),
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: t('publishedBespokeReports.grids.name'),
          dataField: 'name',
          width: 200,
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              options.data.name,
              'BespokeReportPublishedShow',
              {
                id: props.id,
                publishedReportId: options.data.id,
              }
            )
          },
        },
        {
          caption: t('publishedBespokeReports.grids.description'),
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: t('publishedBespokeReports.grids.publisher'),
          calculateCellValue: (row: PublishedBespokeReport) => {
            return `${row.publisher?.first_name} ${row.publisher?.last_name}`
          },
          width: 200,
        },
        {
          visible: false,
          caption: t('publishedBespokeReports.grids.createdAt'),
          dataField: 'created_at',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          visible: false,
          caption: t('publishedBespokeReports.grids.updatedAt'),
          dataField: 'updated_at',
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          caption: t('publishedBespokeReports.grids.configureLink.heading'),
          cellTemplate: (container, options) => {
            renderCellLink(
              container,
              t('publishedBespokeReports.grids.configureLink.cell'),
              'BespokeReportPublishedEdit',
              {
                bespokeReportId: options.data.bespoke_report_id,
                id: options.data.id,
              }
            )
          },
        },
      ])

      const filters = computed(() => {
        return {
          bespoke_report_id: props.id,
          relations: ['bespoke_report'],
        }
      })

      const rowsSelected = (rows: PublishedBespokeReport[]) => {
        state.selectedRows = rows

        if (
          rows.length &&
          !state.extraOptions.includes(
            t('publishedBespokeReports.extraOptions.delete')
          )
        ) {
          state.extraOptions.push(
            t('publishedBespokeReports.extraOptions.delete')
          )
        }

        if (
          !rows.length &&
          state.extraOptions.includes(
            t('publishedBespokeReports.extraOptions.delete')
          )
        ) {
          state.extraOptions.splice(
            state.extraOptions.indexOf(
              t('publishedBespokeReports.extraOptions.delete')
            ),
            1
          )
        }
      }

      const optionClicked = (option: string) => {
        if (option === t('publishedBespokeReports.extraOptions.delete')) {
          store
            .dispatch(
              'publishedBespokeReports/setSelectedReports',
              state.selectedRows
            )
            .then(() => router.push({ name: 'BespokeReportPublishedDelete' }))
        }

        if (
          option ===
          t('publishedBespokeReports.extraOptions.viewDeletedReports')
        ) {
          router.push({ name: 'BespokeReportPublishedRestore' })
        }

        return
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', { crumbs })
      })

      return {
        t,
        state,
        columns,
        filters,
        reports,
        goBack,
        gridHeading,
        rowsSelected,
        bespokeReport,
        optionClicked,
      }
    },
  })
