
  import { defineComponent, reactive, ref, Ref, onMounted, computed } from 'vue'
  import { useStore } from 'vuex'
  import { number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { goBack } from '@/composables/Generic'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { TagIcon } from '@/types/modules/tags/TagIcon'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'
  import IconSelector from '@/components/Generic/Forms/Inputs/IconSelector.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      TextInput,
      SelectInput,
      CcSwitch,
      IconSelector,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
      showAction: {
        type: String,
        required: true,
      },
      storeAction: {
        type: String,
        required: true,
      },
      updateAction: {
        type: String,
        required: true,
      },
      deleteAction: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      props = reactive(props)

      const payload = ref({
        name: '',
        tag_icon_id: null,
        is_hidden: 0,
      }) as Ref<{
        name: string
        tag_icon_id: number | null
        is_system?: number
        is_hidden: number
        _method?: 'PUT'
      }>

      const icons = computed(() => {
        if (store.getters['tags/getIcons'].length) {
          return store.getters['tags/getIcons'].map((icon: TagIcon) => {
            return {
              label: icon.icon,
              value: icon.id,
            }
          })
        }

        return []
      })

      let validationObject = object({
        name: string().required('Name is a required value'),
        tag_icon_id: number().nullable().optional(),
        is_hidden: number().oneOf([0, 1]),
      })

      const saveChanges = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null

        errors.value = await validatePayload(validationObject, payload.value)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        if (props.id) {
          payload.value._method = 'PUT'
          store
            .dispatch(props.updateAction, { id: props.id, ...payload.value })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              goBack()
            })
            .catch((response) =>
              store.dispatch('genericStore/pushNotification', response.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
          return
        }

        store
          .dispatch(props.storeAction, payload.value)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
        return
      }

      const deleteRecord = () => {
        store.dispatch('genericStore/showPageLoader', true)

        if (!payload.value || payload.value.is_system === 1) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            'System records cannot be deleted'
          )
          return
        }

        store
          .dispatch(props.deleteAction, props.id)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const mapToPayload = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch(props.showAction, { id: props.id })
          .then((response) => {
            payload.value = response.data
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const getIcons = () => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('tags/getIcons', {})
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        if (props.id) {
          mapToPayload()
        }

        getIcons()

        store.commit('genericStore/setTabs', { tabs: [] })
      })

      const errors = ref(null) as Ref<Record<string, string | undefined> | null>

      return {
        props,
        payload,
        errors,
        deleteRecord,
        saveChanges,
        goBack,
        icons,
      }
    },
  })
