import axios from 'axios'
import appVersion from '../../../public/version.json'
import {
  getClientSubdomain,
  getZapierBaseUrl,
  getZapierErrorHook,
} from '@/composables/Generic'

export default {
  getApiVersion: async (params: any) => {
    return axios
      .get('version', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getAppVersion: async () => {
    return appVersion
  },
  notifyFrontendError: (params: any) => {
    let client = getClientSubdomain()
    let zapierBase = getZapierBaseUrl()
    let zapierErrorHook = getZapierErrorHook()

    params.client = client

    const ZapierClient = axios.create({
      baseURL: zapierBase,
      withCredentials: false,
    })

    return ZapierClient.get(zapierErrorHook, { params })
  },
}
