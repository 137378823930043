import { EnComponents } from '../../components'

export default {
  mars: {
    medicalCabinet: {
      header: 'Medical Cabinet',
      grid: {
        headers: {
          id: EnComponents.grid.headers.id,
          name: EnComponents.grid.headers.name,
          type: EnComponents.grid.headers.type,
          category: EnComponents.grid.headers.category,
          currentStock: 'Current Stock',
          requiresAuth: 'Requires Authorisation',
          stockHistory: 'Stock History',
          adjustStock: 'Adjust Stock',
        },
        cells: {
          adjust: 'Adjust',
          view: 'View',
          yes: 'Yes',
          no: 'No',
        },
      },
      toolbarOptions: {
        delete: 'Delete',
        transferStock: 'Transfer Stock',
        modifyMedicalCabinets: 'Manage Medical Cabinets',
      },
    },
    stockHistory: {
      header: 'History - { medication }',
      grid: {
        headers: {
          id: EnComponents.grid.headers.id,
          action: EnComponents.grid.headers.action,
          oldValue: 'Old Value',
          newValue: 'New Value',
        },
      },
    },
    stockTransfer: {
      header: 'Stock Transfer',
      toolbar: {
        back: 'Back',
        transfer: 'Transfer',
      },
      location: {
        header: 'Location',
        currentLocation: 'Current Location',
        newLocation: 'Select New Location',
      },
      selectedMedications: {
        header: 'Selected Medications',
      },
    },
    stockAdjustment: {
      header: 'Stock Adjustment',
      stockLevel: 'Stock Level',
      newStockLevel: 'New Stock Level',
      currentStock: 'Current Stock',
      reasonForAdjustment: 'Reason For Adjustment',
      selectedStock: 'Selected Stock',
    },
    create: {
      medicalCabinet: {
        header: 'Add Medical Cabinet',
        name: 'Medical Cabinet Name',
      },
      medication: {
        header: 'Add Medication',
        name: 'Medication Name',
        stockType: 'Select Stock Type',
        stockCategory: 'Select Stock Category',
        requiresAuth: 'Requires Second Authorisation',
      },
    },
    edit: {
      medication: {
        header: 'Edit Medication - {medication}',
        name: 'Medication Name',
        stockType: 'Select Stock Type',
        stockCategory: 'Select Stock Category',
        requiresAuth: 'Requires Second Authorisation',
        hasFutureEvents:
          'This medication has future events that depend on it. Deleting this will also delete those events.',
      },
    },
  },
}
