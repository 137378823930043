import { EnComponents } from "@/locales/messages/components";

export default {
    projectAssociations: {
        header: 'Associations',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                name: EnComponents.grid.headers.name,
                status: EnComponents.grid.headers.status,
                admissionDate: 'Admission Date',
                leavingDate: 'Leaving Date'
            }
        }
    }
}