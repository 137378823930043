import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "flex items-start justify-around"
}
const _hoisted_2 = { class: "w-max flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "image-holder small"
}
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_file_uploader = _resolveComponent("file-uploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.requiresTextInput)
      ? (_openBlock(), _createBlock(_component_text_input, {
          key: 0,
          "is-required": false,
          modelValue: _ctx.valueAttribute,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueAttribute) = $event)),
          label: "Default value",
          type: _ctx.props.field.field.system_name === 'numeric' ? 'number' : 'text'
        }, null, 8, ["modelValue", "type"]))
      : _createCommentVNode("", true),
    (_ctx.requiresBinaryInput)
      ? (_openBlock(), _createBlock(_component_select_input, {
          key: 1,
          "is-required": false,
          modelValue: _ctx.valueAttribute,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueAttribute) = $event)),
          label: "Default value",
          options: 
        _ctx.props.field.field.system_name === 'yes_no'
          ? _ctx.yesNoOptions
          : _ctx.trueFalseOptions
      
        }, null, 8, ["modelValue", "options"]))
      : _createCommentVNode("", true),
    (_ctx.requiresFileInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_file_uploader, {
              label: 
            _ctx.field.field.system_name === 'embed_files'
              ? 'Embed Files'
              : 'Upload Image'
          ,
              uploadText: 'upload',
              onFilesUploaded: _ctx.setFilesAsValue,
              "ready-to-upload-message": "",
              allowedExtensions: _ctx.allowedExtensions
            }, null, 8, ["label", "onFilesUploaded", "allowedExtensions"])
          ]),
          (_ctx.imageSrc)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.imageSrc,
                  alt: `image for field with label: ${_ctx.props.field.name}`
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.props.field.field.system_name === 'system_list')
      ? (_openBlock(), _createBlock(_component_select_input, {
          key: 3,
          "is-required": true,
          modelValue: _ctx.valueAttribute,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueAttribute) = $event)),
          label: "Default value",
          options: _ctx.systemListValues
        }, null, 8, ["modelValue", "options"]))
      : _createCommentVNode("", true)
  ], 64))
}