import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-3 whitespace-nowrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    class: _normalizeClass(_ctx.linkClasses),
    "aria-label": `navigate to ${_ctx.url}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("i", {
        class: _normalizeClass([_ctx.icon, "fa-fw"]),
        "aria-hidden": "true"
      }, null, 2),
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "class", "aria-label"]))
}