
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    computed,
    watch,
  } from 'vue'
  import { FieldAttribute } from '../../../../../types/components/FieldAttribute'
  import TriggerConfig from '@/views/modules/tasks/partials/TriggerConfig.vue'
  import { FormFieldOption } from '@/types/modules/forms/FormFieldOption'
  import { LogTrigger } from '@/composables/ManageLog'
  import { DraftTrigger } from '@/types/modules/tasks/DraftTrigger'

  export default defineComponent({
    components: {
      TriggerConfig,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const value = ref([]) as Ref<any[]>
      const triggers = ref([]) as Ref<LogTrigger[]>
      const error = ref() as Ref<string | undefined>

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': error.value,
          'text-gray-400': !error.value,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const updateValue = (option: any) => {
        if (value.value && Array.isArray(value.value)) {
          let updated = [...value.value]

          if (updated.includes(option.name)) {
            updated = value.value.filter((name: any) => name !== option.name)
            triggers.value = triggers.value.filter(
              (trigger) => trigger.option_name !== option.name
            )
          } else {
            updated.push(option.name)
          }

          value.value = updated
          option.show_trigger_config = showTriggerConfig(option)
          return
        }

        value.value = [option.name]
        option.show_trigger_config = showTriggerConfig(option)
      }

      const validateInput = () => {
        if (props.fieldAttributes.required === true && !value.value) {
          error.value = 'This field is required'
          return false
        }

        error.value = undefined
        return true
      }

      const updateTriggers = (config: any, option: FormFieldOption) => {
        const alreadyTriggered = triggers.value
          .map((trigger) => trigger.option_name)
          .includes(option.name)

        if (alreadyTriggered) {
          if (config) {
            triggers.value.push({
              option_id: option.id,
              option_name: option.name,
              form_field_id: props.fieldAttributes.id,
              ...config,
            })
          }
        } else {
          triggers.value.push({
            option_id: option.id,
            option_name: option.name,
            form_field_id: props.fieldAttributes.id,
            ...config,
          })
        }

        value.value = value.value
      }

      const emitChanges = () => {
        if (validateInput()) {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: value.value,
            triggers: triggers.value,
            row_index: props.fieldAttributes.row_index,
          })
        }
      }

      watch(value, () => emitChanges())

      const showTriggerConfig = (option: FormFieldOption) => {
        if (!option.triggered_task_id) {
          return false
        }

        if (!value.value.includes(option.name)) {
          return false
        }

        let triggeredTaskIds = props.fieldAttributes.existing_triggered_forms

        if (
          triggeredTaskIds &&
          triggeredTaskIds.includes(option.triggered_task_id)
        ) {
          return false
        }

        return true
      }

      const getTriggeredForm = (option: FormFieldOption) => {
        if (
          props.fieldAttributes.draft_triggers &&
          props.fieldAttributes.draft_triggers.length
        ) {
          const relatedDraftTrigger =
            props.fieldAttributes.draft_triggers?.find(
              (draftTrigger: DraftTrigger) => {
                return draftTrigger.option_id === Number(option.id)
              }
            )

          if (relatedDraftTrigger) {
            return relatedDraftTrigger.form || undefined
          }

          return option?.triggered_task
        }
      }

      const getTriggeredProject = (option: FormFieldOption) => {
        if (
          props.fieldAttributes.draft_triggers &&
          props.fieldAttributes.draft_triggers.length
        ) {
          const relatedDraftTrigger = props.fieldAttributes.draft_triggers.find(
            (draftTrigger: DraftTrigger) => {
              return draftTrigger.option_id === Number(option.id)
            }
          )

          if (relatedDraftTrigger) {
            return relatedDraftTrigger.project || undefined
          }
        }

        return props.fieldAttributes.project || undefined
      }

      onMounted(() => {
        if (props.fieldAttributes.value) {
          value.value = props.fieldAttributes.value?.split('||')
        }
      })

      return {
        props,
        value,
        updateValue,
        labelClasses,
        error,
        updateTriggers,
        getTriggeredForm,
        getTriggeredProject,
      }
    },
  })
