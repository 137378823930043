import axios from 'axios'

export default {
  getCareGroupAttachments: async (params: any) => {
    return axios
      .get('files/company-files', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveCareGroupAttachments: async (params: any) => {
    return axios
      .get('files/company-files/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getProjectFiles: async (params: any) => {
    return axios
      .get('files/project-files', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveProjectFiles: async (params: any) => {
    return axios
      .get('files/project-files/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
