
  import { computed, defineComponent, reactive, onMounted } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { goBack } from '@/composables/Generic'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { Dbs } from '@/types/modules/staffs/Dbs'
  import { RightToWork } from '@/types/modules/staffs/RightToWork'
  import { ProofOfIdentity } from '@/types/modules/staffs/ProofOfIdentity'
  import { OverseasCriminalRecord } from '@/types/modules/staffs/OverseasCriminalRecord'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      employeeId: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n()
      props = reactive(props)
      const store = useStore()
      const columns = computed((): Column[] => [
        {
          caption: t(
            'employeeRecruitmentChecks.history.grid.headers.createdAt'
          ),
          dataField: 'created_at',
          dataType: 'datetime',
          sortOrder: 'desc',
        },
        {
          caption: t(
            'employeeRecruitmentChecks.history.grid.headers.reviewedBy'
          ),
          allowSorting: false,
          allowGrouping: false,
          dataField: 'created_by.username',
          calculateCellValue: (
            row: Dbs | RightToWork | ProofOfIdentity | OverseasCriminalRecord
          ) => {
            return `${row?.created_by?.first_name} ${row?.created_by?.last_name}`
          },
        },
        {
          caption: t(
            'employeeRecruitmentChecks.history.grid.headers.selectedEvidenceTypes'
          ),
          allowSorting: false,
          allowGrouping: false,
          cellTemplate: 'EvidenceDocumentsCellTemplate',
        },
        {
          caption: t(
            'employeeRecruitmentChecks.history.grid.headers.supportingFiles'
          ),
          allowSorting: false,
          allowGrouping: false,
          cellTemplate: 'RecruitmentFilesTemplate',
        },
      ])

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Employees', url: '/projects/employees' },
        {
          name: 'Recruitment Checks',
          url: `/projects/employees/${props.employeeId}?tab=recruitment-checks`,
        },
        {
          name: t('employeeRecruitmentChecks.history.header'),
          url: '#',
        },
      ]

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs })
      })

      return {
        t,
        props,
        goBack,
        columns,
      }
    },
  })
