<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :label="t('ypDetails.education.pupilNum')"
          v-model="education.pupil_number"
        />
        <text-input
          :label="t('ypDetails.education.schoolAttended')"
          v-model="education.school_attended"
        />
        <radio-button
          :label="t('ypDetails.education.schoolCareGroup')"
          layout="horizontal"
          v-model="education.care_group_school"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
        <radio-button
          :label="t('ypDetails.education.senStatement')"
          layout="horizontal"
          v-model="education.sen_statement"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
    },
    setup() {
      const { t } = useI18n()
      let education = {
        pupil_number: 'Class Teacher Languages',
        school_attended: 'Agency',
        care_group_school: false,
        sen_statement: false,
      }

      return {
        education,
        t,
      }
    },
  })
</script>
