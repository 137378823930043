import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b509b4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-max" }
const _hoisted_2 = { class: "font-display font-bold text-3xl text-black tracking-slight leading-12 capitalize" }
const _hoisted_3 = { class: "block mt-1 font-semibold text-gray-400 font-body tracking-slight" }
const _hoisted_4 = {
  class: "mt-10 font-semibold",
  id: "calendar-day"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_view = _resolveComponent("dx-view")!
  const _component_dx_resource = _resolveComponent("dx-resource")!
  const _component_calendar_card_template = _resolveComponent("calendar-card-template")!
  const _component_project_resource_template = _resolveComponent("project-resource-template")!
  const _component_dx_scrolling = _resolveComponent("dx-scrolling")!
  const _component_dx_scheduler = _resolveComponent("dx-scheduler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.activeDashboardFilter.name) + " " + _toDisplayString(_ctx.t('dashboard.calendarHeading')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.todaysDate), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_dx_scheduler, {
        ref: "scheduler",
        "text-expr": "name",
        "start-date-expr": "start_date",
        "end-date-expr": "end_date",
        "show-current-time-indicator": true,
        "shade-until-current-time": true,
        "current-view": "calendarDayGrouping",
        "data-source": _ctx.state.dataSource,
        groups: ['project_id'],
        "appointment-template": "cell-template",
        "resource-cell-template": "project-cell-template",
        "on-appointment-dbl-click": _ctx.disableEventViewer,
        height: "500",
        "max-appointments-per-cell": 2,
        editing: false,
        "read-only": true
      }, {
        "cell-template": _withCtx(({ data }) => [
          _createVNode(_component_calendar_card_template, {
            "event-instance": data.appointmentData,
            type: data.appointmentData.event.type.name.toLowerCase()
          }, null, 8, ["event-instance", "type"])
        ]),
        "project-cell-template": _withCtx(({ data: project }) => [
          _createVNode(_component_project_resource_template, {
            data: project.data
          }, null, 8, ["data"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_dx_view, {
            "cell-duration": 30,
            type: "timelineDay",
            name: "calendarDayGrouping",
            "group-orientation": "vertical"
          }),
          _createVNode(_component_dx_resource, {
            "data-source": _ctx.projects,
            "allow-multiple": true,
            "field-expr": "project_id"
          }, null, 8, ["data-source"]),
          _createVNode(_component_dx_scrolling, { mode: "virtual" })
        ]),
        _: 1
      }, 8, ["data-source", "on-appointment-dbl-click"])
    ])
  ]))
}