import UserProjectPermissionService from '@/services/users/userProjectPermissions'
import { UserProjectPermission } from '@/types/modules/users/UserProjectPermission'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface UserProjectPermissionState {
  permissions: UserProjectPermission[]
  selectedPermissions: UserProjectPermission[]
}

export const state: UserProjectPermissionState = {
  permissions: [],
  selectedPermissions: [],
}

export const actions: ActionTree<UserProjectPermissionState, any> = {
  store: async (_context, payload) => {
    return await UserProjectPermissionService.store(payload)
  },
  setSelectedPermissions: (context, permissions: UserProjectPermission[]) => {
    context.commit('setSelectedPermissions', permissions)
  },
}

export const getters: GetterTree<UserProjectPermissionState, any> = {
  permissions: (state: UserProjectPermissionState) => state.permissions,
  selectedPermissions: (state) => state.selectedPermissions,
}

export const mutations: MutationTree<UserProjectPermissionState> = {
  setUserProjectPermissions: (
    state: UserProjectPermissionState,
    permissions: UserProjectPermission[]
  ) => {
    state.permissions = permissions
  },
  setSelectedPermissions: (state, permissions: UserProjectPermission[]) =>
    (state.selectedPermissions = permissions),
}

export const userProjectPermissions = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
