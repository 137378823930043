
  import { defineComponent, reactive, PropType, computed } from 'vue'
  import { InputIcon } from '@/types/components/InputIcon'
  import sanitizeHtml from 'sanitize-html'
  import InputLabel from '../InputLabel.vue'

  export default defineComponent({
    components: {
      InputLabel,
    },
    props: {
      message: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      icons: {
        type: Array as PropType<InputIcon[]>,
        required: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      error: {
        type: String,
        required: false,
      },
      modelValue: {
        required: false,
      },
      minimal: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)

      const hasIconPrefix = computed(() => {
        if (props.icons) {
          return !!props.icons.filter((icon) => icon.position == 'before')
            .length
        }

        return false
      })

      const iconPrefix = computed(() => {
        let icon = ''

        if (hasIconPrefix.value && props.icons) {
          icon = props.icons.filter((icon) => icon.position == 'before')[0].icon
        }

        return icon
      })

      const hasIconSuffix = computed(() => {
        if (props.icons) {
          return !!props.icons.filter((icon) => icon.position == 'after')
        }

        return false
      })

      const iconSuffix = computed(() => {
        if (props.icons) {
          let icon = props.icons.filter((icon) => icon.position == 'after')
          if (icon.length > 0) {
            return icon[0].icon
          } else {
            return ''
          }
        }

        return ''
      })

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.isDisabled && !props.error,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const inputClasses = computed(() => ({
        'cursor-not-allowed': props.isDisabled && !props.error,
        'bg-white': true,
        'border-dashed': props.isDisabled && !props.error,
        'border-b-2': true,
        'border-gray-300': props.isDisabled,
        'transition-all': true,
        'p-2': true,
        'pl-6':
          (props.icons ? props.icons.length > 0 : false) ||
          (props.error && !props.minimal ? true : false) ||
          props.isDisabled,
        'w-full': true,
        'border-0': true,
        'border-gray-400': !props.isDisabled,
        'focus:border-blue-300': true,
        'text-gray-300': props.isDisabled && !props.error,
        'border-red-300': props.error,
        'truncate': true,
        'leading-[22px]': true,
      }))

      const hasErrors = computed(() => {
        return props.error && !props.minimal ? true : false
      })

      const handleInput = (event: any) => {
        let target = event.target as HTMLInputElement

        let data = target.value ? sanitizeHtml(target.value) : target.value

        emit('update:modelValue', data)
      }

      return {
        handleInput,
        hasErrors,
        inputClasses,
        iconSuffix,
        hasIconSuffix,
        iconPrefix,
        hasIconPrefix,
        props,
        labelClasses,
      }
    },
  })
