import { GetterTree, ActionTree, MutationTree } from 'vuex'
import VehicleService from '@/services/projects/vehicles'
import { Vehicle } from '@/types/modules/projects/Vehicle'

export type VehicleGetter = GetterTree<VehicleState, any>

export interface VehicleState {
  vehicles: Vehicle[]
  vehicle: Vehicle | null
}

export const state: VehicleState = {
  vehicles: [],
  vehicle: null,
}

export const getters: VehicleGetter = {
  vehicles: (state) => state.vehicles,
  vehicle: (state) => state.vehicle,
}

export const actions: ActionTree<VehicleState, any> = {
  index: async (context, params) => {
    const response = await VehicleService.index(params)

    if (response.success && params.per_page) {
      context.commit('setVehicles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setVehicles', response.data)

      return response
    }

    return response
  },
  show: async (context, params) => {
    const response = await VehicleService.show(params)

    if (response.success) {
      context.commit('setVehicle', response.data)
    }
    return response
  },
  update: async (context, payload) => {
    const response = await VehicleService.update(payload)
    if (response.success) {
      context.commit('setVehicle', response.data)
    }
    return response
  },
  store: async (context, params) => {
    const response = await VehicleService.store(params)
    if (response.success) {
      context.commit('setVehicle', response.data)
    }
    return response
  },
}

export const mutations: MutationTree<VehicleState> = {
  setVehicles: (state, vehicles: Vehicle[]) => {
    state.vehicles = vehicles
  },
  setVehicle: (state, vehicle: Vehicle) => {
    state.vehicle = vehicle
  },
}

export const vehicles = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
