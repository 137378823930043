import { GetterTree, MutationTree, ActionTree } from 'vuex'
import FormService from '@/services/forms/forms'
import { Form } from '@/types/modules/forms/Form'
import { FormTemplate } from '@/types/modules/forms/FormTemplate'
import { SystemList } from '@/types/modules/forms/SystemList'

export type FormGetter = GetterTree<FormState, any>

export interface FormState {
  forms: any
  form: Form | null
  formFields: any
  selectedForms: object[]
  templates: FormTemplate[]
  systemLists: SystemList[]
}

export const state: FormState = {
  forms: [],
  form: null,
  formFields: [],
  selectedForms: [],
  templates: [],
  systemLists: [],
}

export const getters: FormGetter = {
  forms: (state) => state.forms,
  getForm: (state) => state.form,
  getFormFields: (state) => state.formFields,
  selectedForms: (state) => state.selectedForms,
  getFormTemplates: (state) => state.templates,
  getSystemLists: (state) => state.systemLists,
}

export const actions: ActionTree<FormState, any> = {
  toggleManyVisibility: async (_context, params) => {
    return await FormService.toggleManyVisibility(params)
  },
  index: async (context, params) => {
    const response = await FormService.index(params)

    if (response.success && params.per_page) {
      context.commit('setForms', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setForms', response.data)

      return response
    }

    return response
  },
  getFormFields: async (context, params) => {
    const form_id = params.form_id
    params.form_id = undefined
    const response = await FormService.getFormFields(form_id, params)

    if (response.success && params.per_page) {
      context.commit('setFormFields', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setFormFields', response.data)

      return response
    }

    return response
  },
  store: (_context, payload) => {
    let result = Promise.resolve(FormService.store(payload))

    return result
  },
  update: (_context, payload: { id: any; payload: any }) => {
    let result = Promise.resolve(
      FormService.update(payload.id, payload.payload)
    )

    return result
  },
  show: (context, params) => {
    const result = Promise.resolve(FormService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setForm', response.data)
      }
    })

    return result
  },
  getSelectedForms: (context) => {
    const response = context.getters['selectedForms']

    return {
      data: response,
      totalCount: response.length,
    }
  },

  setSelectedForms: (context, forms) => {
    context.commit('setSelectedForms', forms)
  },
  updateEmail: (context, payload) => {
    context = context
    let results = Promise.resolve(FormService.updateEmail(payload))

    results.then((response) => {
      console.log(response.message)
    })
  },
  getFormTemplates: (context, params) => {
    const result = Promise.resolve(FormService.getFormTemplates(params))

    result.then((response) => {
      if (response.success && !params.per_page) {
        context.commit('setFormTemplates', response.data)
      }
    })

    return result
  },
  getSystemLists: (context, params) => {
    const result = Promise.resolve(FormService.getSystemLists(params))

    result.then((response) => {
      if (response.success && !params.per_page) {
        context.commit('setSystemLists', response.data)
      }
    })

    return result
  },
  getSystemListValues: (_context, params) => {
    const result = Promise.resolve(
      FormService.getSystemListValues(params.system_list_id, params)
    )

    return result
  },
}

export const mutations: MutationTree<FormState> = {
  setForms: (state, forms) => {
    state.forms = forms
  },
  setForm: (state, form) => (state.form = form),
  setFormFields: (state, formFields) => {
    state.formFields = formFields
  },
  setSelectedForms: (state, forms) => {
    state.selectedForms = forms
  },
  setFormTemplates: (state, templates: FormTemplate[]) =>
    (state.templates = templates),
  setSystemLists: (state, systemLists: SystemList[]) =>
    (state.systemLists = systemLists),
}

export const forms = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
