
  import { defineComponent, reactive, ref, Ref, onMounted, computed } from 'vue'
  import { useStore } from 'vuex'
  import { number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { goBack } from '@/composables/Generic'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { Region, RegionDetailsPayload } from '@/types/modules/regions/Region'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      MultiSelect,
      CcButton,
      TextInput,
      SelectInput,
      TextBox,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      const store = useStore()
      props = reactive(props)
      const errors = ref(null) as Ref<Record<string, string | undefined> | null>
      const regions = computed(() => {
        if (store.getters['regions/regions'].length) {
          return store.getters['regions/regions'].map((region: Region) => {
            return {
              label: region.name,
              value: region.id,
            }
          })
        }
        return []
      })

      const payload = ref({
        name: '',
        description: '',
        parent_id: null,
        phone: '',
        fax: '',
        url: '',
        address1: '',
        address2: '',
        city: '',
        zip: '',
        state: '',
        projects: [],
        is_hidden: 0,
      }) as Ref<RegionDetailsPayload>

      let regionDetailsValidation = object({
        name: string().required('Name is a required value'),
        description: string().nullable().optional(),
        parent_id: number().nullable(),
        phone: number().typeError('Please enter valid number'),
        fax: string().nullable().optional(),
        url: string().nullable().optional(),
        address1: string().nullable().optional(),
        address2: string().nullable().optional(),
        city: string().nullable().optional(),
        state: string().nullable().optional(),
        zip: string().nullable().optional(),
        is_hidden: number().oneOf([0, 1]),
      })

      const saveChanges = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null
        errors.value = await validatePayload(
          regionDetailsValidation,
          payload.value as unknown as Record<string, unknown>
        )
        if (errors.value != null) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        if (props.id) {
          store
            .dispatch('regions/update', {
              id: props.id,
              ...payload.value,
            })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              goBack()
            })
            .catch((response) => {
              errors.value = response.errors
              store.dispatch('genericStore/pushNotification', response.message)
            })
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
          return
        } else {
          store
            .dispatch('regions/store', payload.value)
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              goBack()
            })
            .catch((response) =>
              store.dispatch('genericStore/pushNotification', response.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
          return
        }
      }

      const mapToPayload = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('regions/show', { id: props.id })
          .then((response) => {
            payload.value = response.data
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        if (props.id) {
          mapToPayload()
        }
        store
          .dispatch('regions/index', {})
          .then(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
      })

      return {
        props,
        payload,
        errors,
        saveChanges,
        goBack,
        regions,
      }
    },
  })
