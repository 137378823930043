import { ActionTree, GetterTree, MutationTree } from 'vuex'
import EmployeeJobTitleService from '@/services/projects/employees/employeeJobTitles'
import { EmployeeJobTitle } from '@/types/modules/projects/EmployeeJobTitle'

export type EmployeeJobTitleGetter = GetterTree<EmployeeJobTitleState, any>

export interface EmployeeJobTitleState {
  jobTitles: EmployeeJobTitle[]
  jobTitle: EmployeeJobTitle | null
}

export const state: EmployeeJobTitleState = {
  jobTitles: [],
  jobTitle: null,
}

export const getters: EmployeeJobTitleGetter = {
  getJobTitles: (state) => state.jobTitles,
  getJobTitle: (state) => state.jobTitle,
}

export const actions: ActionTree<EmployeeJobTitleState, any> = {
  index: async (context, params) => {
    const response = await EmployeeJobTitleService.index(params)

    if (response.success && params.per_page) {
      context.commit('setJobTitles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setJobTitles', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      EmployeeJobTitleService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setJobTitle', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(EmployeeJobTitleService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(EmployeeJobTitleService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(EmployeeJobTitleService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(EmployeeJobTitleService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(EmployeeJobTitleService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(EmployeeJobTitleService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(EmployeeJobTitleService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<EmployeeJobTitleState> = {
  setJobTitles: (state, titles) => (state.jobTitles = titles),
  setJobTitle: (state, title) => (state.jobTitle = title),
}

export const employeeJobTitles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
