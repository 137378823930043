
  import { defineComponent, reactive, watch } from 'vue'
  import { custom } from 'devextreme/ui/dialog'

  export default defineComponent({
    emits: ['confirmed', 'cancelled'],
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: false,
        default: 'Confirm Changes',
      },
      content: {
        type: String,
        required: false,
        default: 'Are you sure?',
      },
      confirmButton: {
        type: String,
        required: false,
        default: 'Yes',
      },
      cancelButton: {
        type: String,
        required: false,
        default: 'No',
      },
      dragEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)

      watch(props, () => {
        const dialog = custom({
          title: props.title,
          messageHtml: props.content,
          buttons: [
            {
              text: props.confirmButton,
              onClick: () => true,
            },
            {
              text: props.cancelButton,
              onClick: () => false,
            },
          ],
          dragEnabled: props.dragEnabled,
          showTitle: props.showTitle,
        })

        if (props.show) {
          dialog.show().then((dialogResult: any) => {
            if (dialogResult) {
              emit('confirmed')
            } else {
              emit('cancelled')
            }
          })
        }
      })
    },
  })
