import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.vehicle)
        ? (_openBlock(), _createBlock(_component_text_input, {
            key: 0,
            icons: [{ icon: 'fa fa-lock', position: 'before' }],
            "is-required": true,
            "is-disabled": true,
            label: _ctx.t('vehicleDetails.summary.id'),
            modelValue: _ctx.payload.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.id) = $event))
          }, null, 8, ["label", "modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.name'),
        "is-required": true,
        error: _ctx.props.errors.name,
        modelValue: _ctx.payload.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.name) = $event))
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.make'),
        "is-required": false,
        modelValue: _ctx.payload.make,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.make) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.model'),
        "is-required": false,
        modelValue: _ctx.payload.model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.model) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.colour'),
        "is-required": false,
        modelValue: _ctx.payload.colour,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.colour) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.idNumber'),
        "is-required": false,
        modelValue: _ctx.payload.vin,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.vin) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.registration'),
        "is-required": false,
        modelValue: _ctx.payload.registration_number,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.registration_number) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.summary.registrationDate'),
        "is-required": false,
        modelValue: _ctx.payload.registration_date,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.registration_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.summary.motDate'),
        "is-required": false,
        modelValue: _ctx.payload.mot_date,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.mot_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.summary.taxDate'),
        "is-required": false,
        modelValue: _ctx.payload.tax_date,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.tax_date) = $event))
      }, null, 8, ["label", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.summary.fuelType'),
        "is-required": false,
        modelValue: _ctx.payload.fuel,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.fuel) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('vehicleDetails.summary.careGroup'),
        error: _ctx.props.errors.company_id,
        modelValue: _ctx.payload.company_id,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.company_id) = $event)),
        "is-required": true,
        options: _ctx.careGroups
      }, null, 8, ["label", "error", "modelValue", "options"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('vehicleDetails.summary.region'),
        modelValue: _ctx.payload.region_id,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.region_id) = $event)),
        options: _ctx.regions
      }, null, 8, ["label", "modelValue", "options"]),
      _createVNode(_component_select_input, {
        label: _ctx.t('vehicleDetails.summary.administrator'),
        error: _ctx.props.errors.administrator_id,
        modelValue: _ctx.payload.administrator_id,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.administrator_id) = $event)),
        options: _ctx.users,
        "is-required": true
      }, null, 8, ["label", "error", "modelValue", "options"]),
      _createVNode(_component_text_box, {
        lines: "5",
        label: _ctx.t('vehicleDetails.summary.contactDetails'),
        "is-required": false,
        modelValue: _ctx.payload.contact_details,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.contact_details) = $event))
      }, null, 8, ["label", "modelValue"])
    ])
  ]))
}