
  import { defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import OverviewContainer from './OverviewContainer.vue'
  import ConfigureWidget from './partials/ConfigureWidget.vue'
  import ConfigureWidgetForm from './partials/ConfigureWidgetForm.vue'

  export default defineComponent({
    components: {
      OverviewContainer,
      ConfigureWidget,
      ConfigureWidgetForm,
    },
    setup() {
      const store = useStore()
      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Overview', url: '/overview' },
      ]
      const state = reactive({
        currentView: 'overview',
        selectedWidget: {},
      })
      const switchView = (view: string, widget: object) => {
        state.currentView = view
        state.selectedWidget = widget
      }

      const postWidget = (payload: object) => {
        store.dispatch('overview/newWidget', payload)
        switchView('overview', {})
      }

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setIcon', 'fas fa-chart-line')
      })

      return {
        state,
        switchView,
        postWidget,
      }
    },
  })
