import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_box = _resolveComponent("text-box")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_box, {
        label: "Address",
        modelValue: _ctx.payload.address,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.address) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Post Code",
        modelValue: _ctx.payload.postcode,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.postcode) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: "Telephone Number",
        modelValue: _ctx.payload.phone,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.phone) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Fax Number",
        modelValue: _ctx.payload.fax,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.fax) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_text_input, {
        label: "Email address",
        modelValue: _ctx.payload.email,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.email) = $event)),
        error: _ctx.props.errors?.email
      }, null, 8, ["modelValue", "error"])
    ])
  ]))
}