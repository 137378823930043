
  import { defineComponent, onMounted, PropType, reactive, ref, Ref } from 'vue'
  import {
    getFieldComponent,
    fieldsWithTriggers,
  } from '@/composables/ManageLog'
  import Fields from '@/components/Generic/Forms/Inputs/Actual'
  import CcTable from '@/components/Generic/Forms/Inputs/Actual/FormPreviewTable.vue'
  import MultiStep from '@/components/Generic/Forms/Inputs/Actual/MultiStep.vue'
  import {
    FieldAttribute,
    FormPayload,
    RenderedFormFieldSchema,
  } from '@/types/components/FormBuilder'
  import FormSummary from './FormSummary.vue'
  import { cloneDeep } from 'lodash'

  export default defineComponent({
    components: {
      MultiStep,
      CcTable,
      ...Fields,
      FormSummary,
    },
    props: {
      fields: {
        type: Array as PropType<RenderedFormFieldSchema[]>,
        required: true,
      },
      form: {
        type: Object as PropType<FormPayload>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const formattedFields = ref([]) as Ref<RenderedFormFieldSchema[]>

      const state = reactive({
        steps: [] as {
          id: number | string
          name: string
          fields: RenderedFormFieldSchema[]
        }[],
        active_multi_step: undefined as number | string | undefined,
      })

      const buildFields = () => {
        let formFields = cloneDeep(props.fields)

        formFields
          .filter((formField: RenderedFormFieldSchema) => !formField.parent_id)
          .forEach((formField: RenderedFormFieldSchema) => {
            if (formField) {
              if (
                formField.field?.system_name !== 'multi_step_form_section' &&
                !state.active_multi_step
              ) {
                formField.multistep = undefined
                formField.is_visible = true
                formattedFields.value.push(formField)
                return
              }

              if (formField.field?.system_name === 'multi_step_form_section') {
                state.steps.push({
                  id: formField.id,
                  name: formField.name || `step: ${state.steps.length}`,
                  fields: [],
                })

                state.active_multi_step = formField.id
              }

              formField.multistep = state.active_multi_step
              formField.is_visible = true

              if (
                state.active_multi_step &&
                formField.field?.system_name !== 'multi_step_form_section'
              ) {
                for (let step of state.steps) {
                  if (step.id === formField.multistep) {
                    step.fields.push(formField)
                    break
                  }
                }
              }
            }
          })
      }

      type CollapseSection = (logValue: {
        value: string
        shouldCollapse: boolean
      }) => void

      const collapseSection = (logValue: {
        value: string
        shouldCollapse: boolean
      }) => {
        let headingIndex = formattedFields.value.findIndex(
          (value: RenderedFormFieldSchema) => {
            return value.name === logValue.value
          }
        )

        let nextHeadingIndex = formattedFields.value.findIndex(
          (value: RenderedFormFieldSchema, index: number) => {
            return (
              value.field.system_name === 'field_group_heading' &&
              index > headingIndex
            )
          }
        )

        formattedFields.value.forEach(
          (value: RenderedFormFieldSchema, index: number) => {
            if (index > headingIndex && index < nextHeadingIndex) {
              value.is_visible = !logValue?.shouldCollapse
            }

            if (index > headingIndex && nextHeadingIndex === -1) {
              value.is_visible = !logValue?.shouldCollapse
            }
          }
        )
      }

      const registerCustomEventListeners = (field: RenderedFormFieldSchema) => {
        let listeners = {} as Record<string, CollapseSection>

        if (field.field.system_name === 'field_group_heading') {
          listeners.collapseToggle = collapseSection
        }

        return listeners
      }

      const getFieldValue = (field: RenderedFormFieldSchema) => {
        if (field.field?.system_name === 'field_group_heading') {
          return field.name
        }

        if (field.field?.system_name === 'table') {
          let children = JSON.parse(JSON.stringify(props.fields))
            .filter((f: any) => f.parent_id === field.id)
            ?.filter((child: any) => child.field.system_name !== 'table_end')
            .map((child: any, index: number) => {
              child.field.order = index
              child.field.row_index = 0
              return child
            })

          return children
        }

        if (
          ['inline_image', 'embed_files', 'image_editor'].includes(
            field.field.system_name
          )
        ) {
          return field.field_attributes.find((att) => att.name === 'value')
            ?.value
        }

        return null
      }

      const getFieldAttributes = (field: RenderedFormFieldSchema) => {
        let formatted: Record<string, any> = {
          id: field.id,
          value: getFieldValue(field),
          field: field.field,
          label: field.name,
          instructions: field.instructions,
          description: field.description,
          options: field.options,
          in_table: field.parent_id ? true : false,
        }

        if (formatted['in_table']) {
          formatted['table_id'] = field.parent_id
        }

        field.field_attributes?.forEach((attribute: FieldAttribute) => {
          if (formatted[attribute.name] === undefined) {
            formatted[attribute.name] = attribute.value
          }
        })

        if (field.field?.system_name === 'field_group_heading') {
          formatted['status'] = 'in-progress'
        }

        if (fieldsWithTriggers().indexOf(field.field!.system_name) !== -1) {
          formatted['project_id'] = null
          formatted['project'] = null
          formatted['form_types'] = []
          formatted['existing_triggered_tasks'] = []
        }

        if (field.field?.system_name === 'system_list') {
          let attribute = field.field_attributes?.find(
            (attribute: FieldAttribute) => attribute.name === 'value'
          )
          formatted['system_list'] = {}
          formatted['system_list']['id'] = attribute
            ? Number(attribute.value)
            : undefined
          formatted['system_list']['in_preview_mode'] = true
        }

        return formatted
      }

      const getProps = (formField: RenderedFormFieldSchema) => {
        return {
          fieldAttributes: {
            ...getFieldAttributes(formField),
          },
        }
      }

      onMounted(() => {
        if (props.fields.length) {
          buildFields()
        }
      })

      return {
        props,
        formattedFields,
        state,
        registerCustomEventListeners,
        getFieldComponent,
        getProps,
      }
    },
  })
