import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2" }
const _hoisted_2 = { class: "flex items-center justify-end font-display mb-8" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "flex items-start space-x-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_summary_details = _resolveComponent("summary-details")!
  const _component_contact_details = _resolveComponent("contact-details")!
  const _component_addresses = _resolveComponent("addresses")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.state.chips?.length)
        ? (_openBlock(), _createBlock(_component_chips, {
            key: 0,
            onChipClicked: _ctx.chipClicked,
            "default-chip": "Summary",
            chips: _ctx.state.chips
          }, null, 8, ["onChipClicked", "chips"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_page_toolbar, {
          "read-only": _ctx.cannotEditModule('companies'),
          onSubmitClicked: _ctx.submitClicked,
          onExportClicked: _ctx.exportClicked
        }, null, 8, ["read-only", "onSubmitClicked", "onExportClicked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.state.activeChip === 'Summary')
          ? (_openBlock(), _createBlock(_component_summary_details, {
              key: 0,
              "care-group": _ctx.careGroup,
              onPayloadUpdated: _ctx.buildPayload
            }, null, 8, ["care-group", "onPayloadUpdated"]))
          : _createCommentVNode("", true)
      ], 1024)),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.state.activeChip === 'Contact Details')
          ? (_openBlock(), _createBlock(_component_contact_details, {
              key: 0,
              "care-group": _ctx.careGroup,
              onPayloadUpdated: _ctx.buildPayload
            }, null, 8, ["care-group", "onPayloadUpdated"]))
          : _createCommentVNode("", true)
      ], 1024)),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.state.activeChip === 'Addresses')
          ? (_openBlock(), _createBlock(_component_addresses, {
              key: 0,
              "care-group": _ctx.careGroup,
              onPayloadUpdated: _ctx.buildPayload
            }, null, 8, ["care-group", "onPayloadUpdated"]))
          : _createCommentVNode("", true)
      ], 1024))
    ])
  ], 64))
}