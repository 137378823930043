
  import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import BespokeReportWizard from '@/views/modules/reporting/partials/wizard/BespokeReportWizard.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import SetRuntimeParameters from '@/views/modules/reporting/partials/SetRuntimeParameters.vue'
  import ManageReport from '@/views/modules/reporting/partials/ManageReport.vue'
  import { WizardStep } from '@/types/components/WizardStep'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { Datasource } from '@/types/modules/reporting/Datasource'
  import { useRouter } from 'vue-router'
  import { DataSet } from '@/types/modules/reporting/BespokeReportDatasource'

  export default defineComponent({
    components: {
      BespokeReportWizard,
      ValidationErrors,
      SetRuntimeParameters,
      ManageReport,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const router = useRouter()
      const activeStep: Ref<number> = ref(1)
      const errors = ref(null) as Ref<Record<string, string> | null>
      const report = computed(() => store.getters['bespokeReports/report'])
      const datasources = ref([]) as Ref<Datasource[]>
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'View Report',
          url: `/bespoke-reporting/${props.id}`,
        },
      ])
      const steps = ref([
        {
          icon: 'fas fa-cog',
          label: 'Parameters',
        },
        {
          icon: 'fas fa-file-chart-line',
          label: 'View',
        },
      ]) as Ref<WizardStep[]>

      const stepChanged = (step: number) => {
        activeStep.value = step
      }

      const update = (updatedDatasource: Datasource) => {
        const index = datasources.value.findIndex(
          (datasource) => datasource.pivot.id === updatedDatasource.pivot.id
        )

        if (index > -1) {
          datasources.value.splice(index, 1, updatedDatasource)
        } else {
          datasources.value.push(updatedDatasource)
        }

        datasources.value[index] = updatedDatasource
      }

      const submitClicked = () => {
        router.push({
          name: 'BespokeReportPublish',
        })
      }

      const updateDataSets = (dataSets: DataSet[]) => {
        store.commit(
          'bespokeReportDatasources/setDatasets',
          dataSets.map((dataSet) => dataSet)
        )
      }

      watch(
        datasources.value,
        () => {
          store.commit(
            'bespokeReportDatasources/setBespokeReportDatasources',
            datasources.value
          )
        },
        { deep: true, immediate: true }
      )

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (!report.value) {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('bespokeReports/show', props.id)
            .catch((error) => (errors.value = error.response.data.errors))
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      })

      return {
        steps,
        errors,
        report,
        update,
        activeStep,
        datasources,
        stepChanged,
        submitClicked,
        updateDataSets,
      }
    },
  })
