import RegionIndex from '../views/modules/regions/Index.vue'
import RegionShow from '../views/modules/regions/Show.vue'
import RegionDelete from '../views/modules/regions/Delete.vue'
import RegionRestore from '../views/modules/regions/Restore.vue'
import RegionCreate from '../views/modules/regions/Create.vue'
import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/regions',
    name: 'RegionIndex',
    component: RegionIndex,
    meta: {
      title: 'Regions',
      module: 'regions',
      middleware: [auth, canView],
    },
  },
  {
    path: '/regions/:id',
    name: 'RegionShow',
    props: true,
    component: RegionShow,
    meta: {
      title: 'Regions',
      module: 'regions',
      middleware: [auth, canView],
    },
  },
  {
    path: '/regions/create',
    name: 'RegionCreate',
    component: RegionCreate,
    meta: {
      title: 'Create Region',
      module: 'regions',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/regions/delete',
    name: 'RegionDelete',
    component: RegionDelete,
    meta: {
      title: 'Delete Region',
      module: 'regions',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/regions/restore',
    name: 'RegionRestore',
    component: RegionRestore,
    meta: {
      title: 'Restore Region',
      module: 'regions',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
