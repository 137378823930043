
  import CcButton from '@/components/Generic/Button/Button.vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, onMounted, reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { renderCellLink } from '@/composables/Grids'
  import { TaskLogComment } from '@/types/modules/tasks/TaskLogComment'
  import { goBack } from '@/composables/Generic'

  export default {
    components: {
      CcButton,
      DataGrid,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          width: 100,
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
        },
        {
          dataField: 'created_by.username',
          caption: 'Username',
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              options.data.created_by.username,
              'UserShow',
              {
                id: options.data?.created_by.id,
              }
            )
          },
          allowGrouping: false,
        },
        {
          dataField: 'comment',
          caption: 'Comment',
          allowGrouping: false,
        },

        {
          dataField: 'commented_at',
          dataType: 'datetime',
          caption: 'Date and Time',
          allowGrouping: false,
        },
      ])

      const comments = computed(
        (): TaskLogComment[] =>
          store.getters['taskLogComments/getSelectedTaskLogComments']
      )

      const deleteRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('taskLogComments/deleteMany', {
            ids: comments.value.map((row: TaskLogComment) => row.id),
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      onMounted(() => {
        if (
          !store.getters['taskLogComments/getSelectedTaskLogComments'].length
        ) {
          goBack()
        }

        store.getters['genericStore/breadCrumbs'].push({
          name: 'Delete Comments',
          url: route.fullPath,
        })
      })

      return {
        t,
        goBack,
        columns,
        deleteRecords,
      }
    },
  }
