
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    Ref,
    ref,
  } from 'vue'
  import { useStore } from 'vuex'
  import { Crumb } from '@/types/components/Crumb'
  import { BespokeReportPayload } from '@/types/modules/reporting/BespokeReport'
  import ManageBespokeReport from '@/views/modules/reporting/partials/ManageBespokeReport.vue'
  import ManagePublishedBespokeReport from '@/views/modules/reporting/partials/ManagePublishedBespokeReport.vue'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      ManageBespokeReport,
      ManagePublishedBespokeReport,
    },
    props: {
      id: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const errors = ref(null) as Ref<Record<string, string> | null>
      const crumbs = reactive<Crumb[]>([
        {
          name: 'Dashboard',
          url: '/dashboard',
        },
        {
          name: 'Bespoke Reporting',
          url: '/bespoke-reporting',
        },
        {
          name: 'Published Reports',
          url: `/bespoke-reporting/${props.id}/published-reports`,
        },
        {
          name: 'Edit Report',
          url: `/bespoke-reporting/${props.id}/edit`,
        },
      ])

      const report = computed(() => {
        if (route.name === 'BespokeReportEdit') {
          return store.getters['bespokeReports/report']
        }

        return store.getters['publishedBespokeReports/report']
      })

      const submitClicked = (payload: BespokeReportPayload) => {
        if (route.name === 'BespokeReportEdit') {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('bespokeReports/update', { id: props.id, ...payload })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              router.push({
                name: 'BespokeReportIndex',
              })
            })
            .catch((error) => (errors.value = error.errors))
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        } else {
          store.dispatch('genericStore/showPageLoader', true)
          store
            .dispatch('publishedBespokeReports/update', {
              id: props.id,
              ...payload,
            })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              router.push({
                name: 'BespokeReportPublishedIndex',
                params: { id: props.id },
              })
            })
            .catch((error) => (errors.value = error.errors))
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      const loadBespokeReport = () => {
        store.dispatch('genericStore/showPageLoader', true)
        return store
          .dispatch('bespokeReports/show', props.id)
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const loadPublishedBespokeReport = () => {
        store.commit('genericStore/setBreadcrumbs', {
          ...crumbs,
        })
        store.dispatch('genericStore/showPageLoader', true)
        return store
          .dispatch('publishedBespokeReports/show', props.id)
          .catch((error) => {
            store.dispatch('genericStore/pushNotification', error.message)
          })
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs })

        if (route.name === 'BespokeReportEdit') {
          crumbs.splice(2, 1)
        }

        if (route.name === 'BespokeReportEdit' && !report.value) {
          loadBespokeReport()
        }

        if (route.name === 'BespokeReportPublishedEdit' && !report.value) {
          loadPublishedBespokeReport()
        }

        store.commit('genericStore/setBreadcrumbs', { crumbs })

        return
      })

      onUnmounted(() => {
        if (route.name === 'BespokeReportEdit') {
          store.commit('bespokeReports/setBespokeReport', null)
          return
        }

        store.commit('publishedBespokeReports/setPublishedBespokeReport', null)
        return
      })

      return {
        route,
        props,
        report,
        errors,
        submitClicked,
      }
    },
  })
