import axios from 'axios'

export default {
  getRecruitmentCheckFiles: async (params: any) => {
    return axios
      .get('staffs/process-files', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSensitiveRecruitmentCheckFiles: async (params: any) => {
    return axios
      .get('staffs/process-files/sensitive', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getEvidenceTypes: async (params: any) => {
    return axios
      .get('staffs/evidence/types', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getDbsStatuses: async (params: any) => {
    return axios
      .get(`staffs/dbs_statuses`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getOverseasCriminalRecordStatuses: async (params: any) => {
    return axios
      .get(`staffs/overseas_criminal_record_statuses`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getRecruitmentCheckHistory: async (params: any) => {
    return axios
      .get(`staffs/${params.type}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getEmployeeContractRecords: (params: object) => {
    return axios
      .get('staffs/employee_contracts', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getContractualEvents: async () => {
    return axios
      .get(`staffs/contractual_events`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
