import { GetterTree, MutationTree, ActionTree } from 'vuex'
import MedicationStockTypeService from '@/services/mars/medicationStockTypes'
import { MedicationStockType } from '@/types/modules/mars/MedicationStockType'

export type MedicationStockTypeGetter = GetterTree<
  MedicationStockTypeState,
  any
>

export interface MedicationStockTypeState {
  stockTypes: MedicationStockType[]
  stockType: MedicationStockType | null
}

export const state: MedicationStockTypeState = {
  stockTypes: [],
  stockType: null,
}

export const getters: MedicationStockTypeGetter = {
  getStockTypes: (state) => state.stockTypes,
  getStockType: (state) => state.stockType,
}

export const actions: ActionTree<MedicationStockTypeState, any> = {
  index: async (context, params) => {
    const response = await MedicationStockTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setStockTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setStockTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(
      MedicationStockTypeService.show(params.id, params)
    )

    result.then((response) => {
      if (response.success) {
        context.commit('setStockType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(MedicationStockTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(
      MedicationStockTypeService.update(payload.id, payload)
    )
  },
  delete: (_context, id) => {
    return Promise.resolve(MedicationStockTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(MedicationStockTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(MedicationStockTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(MedicationStockTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(
      MedicationStockTypeService.toggleManyVisibility(params)
    )
  },
}

export const mutations: MutationTree<MedicationStockTypeState> = {
  setStockTypes: (state, types: MedicationStockType[]) => {
    state.stockTypes = types
  },
  setStockType: (state, type: MedicationStockType) => {
    state.stockType = type
  },
}

export const medicationStockTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
