import { ActionTree, GetterTree, MutationTree } from 'vuex'
import HomesService from '@/services/projects/homes'
import { Home } from '@/types/modules/projects/Home'
import { TaskLog } from '@/types/modules/tasks/TaskLog'
import { UserProjectPermission } from '@/types/modules/users/UserProjectPermission'
import { Project } from '@/types/modules/projects/Project'

export type HomeGetter = GetterTree<HomeState, any>

export interface HomeState {
  homes: Home[]
  home: Home | null
  logs: TaskLog[]
  userPermissions: UserProjectPermission[]
  associations: Project[]
}

// Change once backend connection setup
export const state: HomeState = {
  homes: [],
  home: null,
  logs: [],
  associations: [],
  userPermissions: [],
}

export const getters: HomeGetter = {
  allHomes: (state) => state.homes,
  home: (state) => state.home,
  homeLogs: (state) => state.logs,
  homeAssociations: (state) => state.associations,
  userPermissions: (state) => state.userPermissions,
}

export const actions: ActionTree<HomeState, any> = {
  index: async (context, params) => {
    const response = await HomesService.index(params)

    if (response.success && params.per_page) {
      context.commit('setHomes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setHomes', response.data)

      return response
    }

    return response
  },
  show: async (context, params) => {
    const response = await HomesService.show(params)

    if (response.success) {
      context.commit('setHome', response.data)
    }

    return response
  },
  update: async (context, payload) => {
    const response = await HomesService.update(payload)

    if (response.success) {
      context.commit('setHome', response.data)
    }

    return response
  },
}

export const mutations: MutationTree<HomeState> = {
  setHomes: (state, homes) => {
    state.homes = homes
  },
  setHome: (state, home) => (state.home = home),
}

export const homes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
