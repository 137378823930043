
  import { reactive, computed, defineComponent } from 'vue'
  export default defineComponent({
    props: {
      size: {
        type: String,
        default: 'small',
        validator: (value: string) => ['small', 'large'].indexOf(value) !== -1,
      },
      type: {
        type: String,
        required: true,
        default: 'primary',
        validator: (value: string) =>
          [
            'primary',
            'success',
            'success-alt',
            'warning',
            'danger',
            'default',
            'secondary',
          ].indexOf(value) !== -1,
      },
      label: {
        type: String,
        required: true,
        default: '',
      },
    },
    setup(props) {
      props = reactive(props)

      const classes = computed(
        (): Object => ({
          // Default styles
          'border-2': true,
          'font-body': true,
          'font-medium': true,
          'leading-5.5': true,
          'lowercase': true,
          'max-w-max': true,
          'w-max': true,
          'rounded-full': true,
          'text-pill': true,
          'tracking-slight': true,

          // Type related styles
          'bg-blue-200 text-white border-blue-500': props.type == 'primary',
          'bg-purple-200 text-white border-purple-500':
            props.type == 'secondary',
          'bg-gray-100 text-gray-400 border-gray-400': props.type == 'default',
          'bg-green-200 text-white border-green-500': props.type == 'success',
          'bg-green-100 text-green-400 border-green-400':
            props.type == 'success-alt',
          'bg-red-200 text-white border-red-300': props.type == 'danger',
          'bg-orange-200 text-black border-orange-400': props.type == 'warning',

          // Size related styles
          'px-2.5': props.size == 'small',
          'py-1.25': props.size == 'small',
          'py-2.5': props.size == 'large',
          'px-5': props.size == 'large',
        })
      )

      return {
        classes,
        props,
      }
    },
  })
