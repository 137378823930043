import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79fe24a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", null, [
      (_ctx.props.fieldAttributes?.label && !_ctx.props.fieldAttributes.in_table)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            class: _normalizeClass([_ctx.labelClasses, "mb-1"])
          }, [
            (_ctx.props.fieldAttributes?.required == true)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.props.fieldAttributes?.label), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.state.size, 'image-holder'])
    }, [
      _createElementVNode("img", {
        src: _ctx.getImage,
        alt: `image for field with label: ${_ctx.props.fieldAttributes?.label}`
      }, null, 8, _hoisted_2)
    ], 2)
  ], 64))
}