
  import { defineComponent, reactive, ref, Ref } from 'vue'
  import DxPieChart, {
    DxLegend,
    DxSeries,
    DxTooltip,
    DxFont,
    DxSize,
  } from 'devextreme-vue/pie-chart'
  import DxChart from 'devextreme-vue/chart'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      DxSize,
      DxPieChart,
      DxFont,
      DxLegend,
      DxSeries,
      DxTooltip,
    },
    props: {
      data: {
        type: Array,
        required: true,
      },
    },
    emits: ['series-clicked'],
    setup(props, { emit }) {
      const pieChart = ref() as Ref<DxChart>
      props = reactive(props)
      const router = useRouter()
      const customizeTooltip = (data: any) => {
        return {
          text: `${data.argument} - ${data.value} (${data.percentText})`,
        }
      }
      const seriesClicked = (data: any) => {
        emit('series-clicked', data.target.name)
        router.push({
          name: 'TasksOverview',
          query: {
            status: data.status,
          },
        })
      }

      return {
        props,
        customizeTooltip,
        pieChart,
        seriesClicked,
      }
    },
  })
