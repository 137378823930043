import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    ref: "grid",
    action: _ctx.props.action,
    "read-only": _ctx.props.readOnly,
    columns: _ctx.columns,
    chips: _ctx.state.chips,
    "query-filter": _ctx.queryFilter,
    "extra-options": _ctx.state.extraOptions,
    onChipClicked: _ctx.chipClicked,
    onRowsSelected: _ctx.rowsSelected,
    onOptionClicked: _ctx.optionClicked,
    onPrimaryButtonClicked: _ctx.addNewForm
  }, null, 8, ["action", "read-only", "columns", "chips", "query-filter", "extra-options", "onChipClicked", "onRowsSelected", "onOptionClicked", "onPrimaryButtonClicked"]))
}