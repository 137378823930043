
  import { reactive, defineComponent, PropType } from 'vue'
  import { User } from '@/types/modules/users/User'
  import CcAvatar from '@/components/Generic/Avatar/Avatar.vue'
  export default defineComponent({
    components: {
      CcAvatar,
    },
    props: {
      user: {
        type: Object as PropType<User>,
        required: true,
      },
      isCollapsed: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      return {
        props,
      }
    },
  })
