
  import { computed, defineComponent, reactive } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'

  export default defineComponent({
    components: {
      DataGrid,
    },
    setup() {
      const state = reactive({
        gridAction: 'auth/getUserAccessLogs',
        activeChip: 'Logins',
        chips: [
          'Logins',
          'Failed Logins',
          'Task Access',
          'User Permissions',
          'User Module Permissions',
          'Care Group Permissions',
          'Role Permissions',
          'Role Module Permissions',
          'Role Care Group Permissions',
        ],
      })

      const columns = computed((): Column[] => {
        let columns: Column[] = []

        switch (state.activeChip) {
          case 'Logins':
            columns = [
              {
                name: 'logins.id',
                caption: 'ID',
                dataField: 'id',
                dataType: 'number',
                width: 100,
              },
              {
                name: 'logins.user',
                dataField: 'user.username',
                caption: 'User',
                allowGrouping: false,
                allowSorting: false,
                calculateCellValue: (row: any) => {
                  return `${row.user?.first_name} ${row.user?.last_name} (${row.user?.username})`
                },
              },
              {
                name: 'logins.date_time_in',
                dataField: 'date_time_in',
                caption: 'Logged In At',
                dataType: 'datetime',
                format: 'dd/MM/yyyy HH:mm:ss',
              },
              {
                name: 'logins.date_time_out',
                dataField: 'date_time_out',
                caption: 'Logged Out At',
                dataType: 'datetime',
                format: 'dd/MM/yyyy HH:mm:ss',
              },
              {
                name: 'logins.action',
                dataField: 'action',
                caption: 'Last Action',
              },
              {
                name: 'logins.date_time_last_action',
                dataField: 'date_time_last_action',
                caption: 'Last Action At',
                dataType: 'datetime',
                sortOrder: 'desc',
                format: 'dd/MM/yyyy HH:mm:ss',
              },
              {
                name: 'logins.ip_address',
                dataField: 'ip_address',
                caption: 'IP Address',
              },
              {
                name: 'logins.user_agent',
                dataField: 'user_agent',
                caption: 'User Agent',
                visible: false,
              },
            ]
            break
          case 'Failed Logins':
            columns = [
              {
                name: 'failed_logins.id',
                caption: 'ID',
                dataField: 'id',
                dataType: 'number',
                width: 100,
              },
              {
                name: 'failed_logins.name',
                dataField: 'name',
                caption: 'Failed Username',
              },
              {
                name: 'failed_logins.date_time_in',
                dataField: 'date_time_in',
                caption: 'Date & Time',
                dataType: 'datetime',
                sortOrder: 'desc',
                format: 'dd/MM/yyyy HH:mm:ss',
              },
              {
                name: 'failed_logins.ip_address',
                dataField: 'ip_address',
                caption: 'IP Address',
              },
              {
                name: 'failed_logins.reason',
                dataField: 'reason.reason',
                caption: 'Reason for Failure',
              },
              {
                name: 'failed_logins.user_agent',
                dataField: 'user_agent',
                caption: 'User Agent',
                visible: false,
              },
            ]
            break
          case 'Task Access':
            columns = [
              {
                name: 'task_accesses.id',
                caption: 'ID',
                dataField: 'id',
                dataType: 'number',
              },
              {
                name: 'task_accesses.name',
                dataField: 'task.name',
                caption: 'Task Name',
              },
              {
                name: 'task_accesses.relates_to',
                dataField: 'task.project.name',
                caption: 'Relates To',
              },
              {
                name: 'task_accesses.access_type',
                dataField: 'access_type',
                caption: 'Access Type',
              },
              {
                name: 'task_accesses.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'task_accesses.username',
                dataField: 'user.username',
                caption: 'User',
                allowGrouping: false,
                allowSorting: false,
                calculateCellValue: (row: any) => {
                  return `${row.user?.first_name} ${row.user?.last_name} (${row.user?.username})`
                },
              },
              {
                name: 'task_accesses.updated_at',
                dataField: 'updated_at',
                caption: 'Access Date',
                dataType: 'datetime',
                sortOrder: 'desc',
                format: 'dd/MM/yyyy HH:mm:ss',
              },
            ]
            break
          case 'User Permissions':
            columns = [
              {
                name: 'user_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'user_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'user_permissions.project_name',
                dataField: 'project_name',
                caption: 'Name',
                groupIndex: 0,
              },
              {
                name: 'user_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'user_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'user_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
          case 'Role Permissions':
            columns = [
              {
                name: 'role_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'role_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'role_permissions.project_name',
                dataField: 'project_name',
                caption: 'Name',
                groupIndex: 0,
              },
              {
                name: 'role_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'role_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'role_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
          case 'User Module Permissions':
            columns = [
              {
                name: 'user_module_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'user_module_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'user_module_permissions.module_name',
                dataField: 'module_name',
                caption: 'Module',
                groupIndex: 0,
              },
              {
                name: 'user_module_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'user_module_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'user_module_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
          case 'Role Module Permissions':
            columns = [
              {
                name: 'role_module_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'role_module_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'role_module_permissions.module_name',
                dataField: 'module_name',
                caption: 'Module',
                groupIndex: 0,
              },
              {
                name: 'role_module_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'role_module_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'role_module_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
          case 'Care Group Permissions':
            columns = [
              {
                name: 'care_group_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'care_group_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'care_group_permissions.company_name',
                dataField: 'company_name',
                caption: 'Care Group',
                groupIndex: 0,
              },
              {
                name: 'care_group_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'care_group_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'care_group_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
          case 'Role Care Group Permissions':
            columns = [
              {
                name: 'role_care_group_permissions.old_value',
                dataField: 'old_value',
                caption: 'Previous Access Level',
              },
              {
                name: 'role_care_group_permissions.new_value',
                dataField: 'new_value',
                caption: 'Current Access Level',
              },
              {
                name: 'role_care_group_permissions.company_name',
                dataField: 'company_name',
                caption: 'Care Group',
                groupIndex: 0,
              },
              {
                name: 'role_care_group_permissions.description',
                dataField: 'description',
                caption: 'Description',
              },
              {
                name: 'role_care_group_permissions.created_by',
                dataField: 'created_by',
                caption: 'Changed By',
              },
              {
                name: 'role_care_group_permissions.updated_at',
                dataField: 'updated_at',
                caption: 'Date',
                dataType: 'date',
                format: 'dd/MM/yyyy HH:mm:ss',
                sortOrder: 'desc',
              },
            ]
            break
        }

        return columns
      })

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        if (chip === 'Logins') state.gridAction = 'auth/getUserAccessLogs'
        if (chip === 'Failed Logins')
          state.gridAction = 'auth/getUserAccessFailedLogs'
        if (chip === 'Task Access') state.gridAction = 'tasks/getTaskAccessLogs'
        if (chip === 'User Permissions')
          state.gridAction = 'users/getUserPermissionLogs'
        if (chip === 'User Module Permissions')
          state.gridAction = 'users/getUserModulePermissionLogs'
        if (chip === 'Care Group Permissions')
          state.gridAction = 'users/getUserCareGroupPermissionLogs'
        if (chip === 'Role Permissions')
          state.gridAction = 'roles/getRolePermissionLogs'
        if (chip === 'Role Module Permissions')
          state.gridAction = 'roles/getRoleModulePermissionLogs'
        if (chip === 'Role Care Group Permissions')
          state.gridAction = 'roles/getRoleCareGroupPermissionLogs'
      }

      return {
        chipClicked,
        columns,
        state,
      }
    },
  })
