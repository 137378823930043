
  import { computed, defineComponent, onMounted } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { useI18n } from 'vue-i18n'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { MedicationStock } from '@/types/modules/mars/MedicationStock'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import { useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const store = useStore()
      const columns = computed((): Column[] => [
        {
          caption: t('mars.medicalCabinet.grid.headers.id'),
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.name'),
          dataField: 'name',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.currentStock'),
          dataField: 'stock_level',
          dataType: 'number',
          allowGrouping: false,
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.type'),
          dataField: 'stock_type.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.category'),
          dataField: 'stock_category.name',
        },
        {
          caption: t('mars.medicalCabinet.grid.headers.requiresAuth'),
          calculateCellValue: (row: MedicationStock) => {
            return row.requires_auth
              ? t('mars.medicalCabinet.grid.cells.yes')
              : t('mars.medicalCabinet.grid.cells.no')
          },
        },
      ])

      const stocks = computed(() => store.getters['mars/stocksToDelete'])

      const deleteStock = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('mars/deleteMedications', {
            ids: stocks.value
              .filter((stock: MedicationStock) => !stock.stock_in_use)
              .map((stock: MedicationStock) => stock.id),
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      onMounted(() => {
        store.getters['genericStore/breadCrumbs'].push({
          name: 'Delete Medications',
          url: '#',
        })

        if (!store.getters['mars/stocksToDelete'].length) {
          router.push(`${route.fullPath.split('mars')[0]}?tab=medical-cabinet`)
        }
      })

      return {
        t,
        columns,
        deleteStock,
        goBack,
      }
    },
  })
