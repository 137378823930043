
  import {
    BespokeReport,
    BespokeReportPayload,
    PayloadKey,
  } from '@/types/modules/reporting/BespokeReport'
  import { defineComponent, PropType, reactive, Ref, ref, watch } from 'vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import BespokeReportWizard from '@/views/modules/reporting/partials/wizard/BespokeReportWizard.vue'
  import ReportDetails from '@/views/modules/reporting/partials/wizard/ReportDetails.vue'
  import ReportDatasources from '@/views/modules/reporting/partials/wizard/ReportDatasources.vue'
  import ReportPermissions from '@/views/modules/reporting/partials/wizard/ReportPermissions.vue'
  import { array, number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { WizardStep } from '@/types/components/WizardStep'

  export default defineComponent({
    components: {
      ValidationErrors,
      BespokeReportWizard,
      ReportDetails,
      ReportDatasources,
      ReportPermissions,
    },
    emits: ['step-changed', 'payload-updated', 'submit-clicked'],
    props: {
      report: {
        type: Object as PropType<BespokeReport | null>,
        required: false,
      },
      errors: {
        type: Object as PropType<Record<string, string> | null>,
        required: false,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)
      const activeStep = ref(1) as Ref<number>
      const errors = ref(null) as Ref<Record<string, string> | null>
      const steps = ref([
        {
          icon: 'far fa-memo-circle-info',
          label: 'Details',
        },
        {
          icon: 'far fa-database',
          label: 'Datasources',
        },
        {
          icon: 'far fa-shield-keyhole',
          label: 'Permissions',
        },
      ] as WizardStep[])

      const payload = ref({
        name: '',
        description: '',
        definition: '',
        report_type_id: 0,
        datasources: [],
        restricted_users: [] as number[],
      }) as Ref<BespokeReportPayload>

      const DatasourceSchema = object().shape({
        id: number().required('Please select a valid datasource'),
        name: string().required('Please enter a datasource name'),
        grouping: string().required('Please enter a grouping name'),
        description: string().required('Please enter a description'),
      })

      const bespokeReportDetailsValidation = object({
        name: string().required('Please enter a report name'),
        report_type_id: number()
          .typeError('Please select a valid report type')
          .min(1, 'Please select a valid report type')
          .required('Please select a valid report type'),
        description: string().required('Please enter a report description'),
      })

      const bespokeReportDatasourceValidation = object({
        datasources: array()
          .of(DatasourceSchema)
          .min(1, 'Please select at least one datasource')
          .required('Please select at least one datasource'),
      })

      const validateStep = async (step: number) => {
        if (step > 1) {
          errors.value = await validatePayload(
            bespokeReportDetailsValidation,
            payload.value as unknown as Record<string, unknown>
          )
          if (errors.value) {
            activeStep.value = 1
            return false
          }
        }

        if (step > 2) {
          errors.value = await validatePayload(
            bespokeReportDatasourceValidation,
            payload.value as unknown as Record<string, unknown>
          )
          if (errors.value) {
            activeStep.value = 2
            return false
          }
        }

        return true
      }

      const stepChanged = async (step: number) => {
        if (!(await validateStep(step))) {
          return
        }
        activeStep.value = step
        emit('step-changed', step)
      }

      const payloadUpdated = (details: Record<PayloadKey, never>) => {
        Object.keys(details).forEach((key: string) => {
          payload.value[key as PayloadKey] = details[key as PayloadKey]
        })
      }

      const submitClicked = () => emit('submit-clicked', payload.value)

      watch(
        () => props.errors,
        (value) => {
          if (value) {
            errors.value = value
          }
        }
      )

      return {
        props,
        steps,
        payload,
        errors,
        activeStep,
        stepChanged,
        submitClicked,
        payloadUpdated,
      }
    },
  })
