import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-4 truncate"
}
const _hoisted_2 = { class: "truncate leading-5 tracking-slight font-semibold text-1xs lowercase" }
const _hoisted_3 = { class: "truncate text-sm leading-5 tracking-slight font-semibold text-1xs lowercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_avatar = _resolveComponent("cc-avatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'ManageProfile' },
    class: _normalizeClass(["flex items-center flex-nowrap font-body hover:bg-white hover:text-blue-500 hover:font-bold transition-all cursor-pointer py-1", 
      _ctx.$route.name === 'ManageProfile' ? 'bg-white text-blue-500 font-bold' : ''
    ]),
    title: "Manage User Profile"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_cc_avatar, {
        url: _ctx.user.pic ? _ctx.user.pic : 'https://bit.ly/3jDg2Q6',
        alt: `${_ctx.user.username}'s profile picture`,
        class: "border border-blue-500"
      }, null, 8, ["url", "alt"]),
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.user.username), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.user.email), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}