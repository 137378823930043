
  import { Column } from 'devextreme/ui/data_grid'
  import { defineComponent, reactive, computed } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { renderCellLink } from '@/composables/Grids'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      taskId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          width: 100,
        },
        {
          caption: 'Username',
          dataField: 'user.username',
          allowGrouping: false,
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              options.data.user.username,
              'UserShow',
              {
                id: options.data?.user.id,
              },
              undefined
            )
          },
        },
        {
          caption: 'Description',
          dataField: 'description',
          allowGrouping: false,
        },
        {
          caption: 'Date and Time',
          dataField: 'created_at',
          dataType: 'datetime',
          allowGrouping: false,
        },
        {
          caption: 'Type',
          dataField: 'access_type',
          allowGrouping: true,
        },
      ])

      const filters = computed(() => {
        return {
          task_id: props.taskId,
        }
      })

      return {
        props,
        filters,
        columns,
      }
    },
  })
