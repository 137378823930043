import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chips = _resolveComponent("chips")!
  const _component_right_to_work = _resolveComponent("right-to-work")!
  const _component_dbs_check = _resolveComponent("dbs-check")!
  const _component_proof_of_identity = _resolveComponent("proof-of-identity")!
  const _component_overseas_criminal_record = _resolveComponent("overseas-criminal-record")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_chips, {
      chips: _ctx.state.chips,
      "default-chip": _ctx.activeChip,
      onChipClicked: _ctx.chipClicked
    }, null, 8, ["chips", "default-chip", "onChipClicked"]),
    (_ctx.activeChip === 'Right To Work')
      ? (_openBlock(), _createBlock(_component_right_to_work, {
          key: 0,
          employee: _ctx.employee,
          "onUpdate:payload": _ctx.payloadUpdated,
          onSubmitClicked: _ctx.submitClicked
        }, null, 8, ["employee", "onUpdate:payload", "onSubmitClicked"]))
      : _createCommentVNode("", true),
    (_ctx.activeChip === 'Dbs')
      ? (_openBlock(), _createBlock(_component_dbs_check, {
          key: 1,
          employee: _ctx.employee,
          "onUpdate:payload": _ctx.payloadUpdated,
          onSubmitClicked: _ctx.submitClicked
        }, null, 8, ["employee", "onUpdate:payload", "onSubmitClicked"]))
      : _createCommentVNode("", true),
    (_ctx.activeChip === 'Proof Of Identity')
      ? (_openBlock(), _createBlock(_component_proof_of_identity, {
          key: 2,
          employee: _ctx.employee,
          "onUpdate:payload": _ctx.payloadUpdated,
          onSubmitClicked: _ctx.submitClicked
        }, null, 8, ["employee", "onUpdate:payload", "onSubmitClicked"]))
      : _createCommentVNode("", true),
    (_ctx.activeChip === 'Overseas Criminal Record')
      ? (_openBlock(), _createBlock(_component_overseas_criminal_record, {
          key: 3,
          employee: _ctx.employee,
          "onUpdate:payload": _ctx.payloadUpdated,
          onSubmitClicked: _ctx.submitClicked
        }, null, 8, ["employee", "onUpdate:payload", "onSubmitClicked"]))
      : _createCommentVNode("", true)
  ], 64))
}