
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import FileDropzone from '@/components/Generic/Forms/Inputs/FileDropzone.vue'
  import { useStore } from 'vuex'
  import ImageTile from '@/components/Generic/ImageTile/ImageTile.vue'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import GridToolbar from '@/components/Generic/Grid/GridToolbar.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      FileDropzone,
      ImageTile,
      ConfirmDialog,
      ButtonGroup,
      CcButton,
      GridToolbar,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const photos = computed(() => {
        return store.getters['youngPeople/photos']
      })

      const state = reactive({
        photoDeletion: {
          image: {},
          showConfirm: false,
        },
        tabs: [t('ypPhotos.tabs.photos'), t('ypPhotos.tabs.taskPhotos')],
        activeTab: 'photos',
        photoRollExclude: false,
      })

      const uploadPhoto = (file: File) => {
        console.log('upload file', file)
      }

      const deletePhoto = (image: any) => {
        store.dispatch('youngPeople/deletePhoto', {
          imageId: image.id,
        })
      }

      onMounted(() => {
        store.dispatch('youngPeople/getPhotos')
      })

      return {
        uploadPhoto,
        photos,
        deletePhoto,
        state,
        t,
      }
    },
  })
