
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    emits: ['cancel-clicked', 'finish-clicked'],
    components: {
      SelectInput,
      MultiSelect,
      TextInput,
      CcButton,
      ButtonGroup,
      RadioButton,
    },
    props: {
      selectedWidget: {
        type: Object,
        required: true,
      },
      type: String,
    },
    setup(props, { emit }) {
      const store = useStore()
      const formData = computed(() => {
        return {
          availableLocations: store.getters['homes/allHomes']
            .concat(store.getters['schools/schools'])
            .map((location: any) => location.project.name),
          availablePeriods: [
            { label: 'all', value: 0 },
            { label: 'last week', value: 1 },
            { label: 'this week', value: 2 },
            { label: 'last month', value: 3 },
            { label: 'this month', value: 4 },
            { label: 'last year', value: 5 },
            { label: 'this year', value: 6 },
          ],
          availableForms: store.getters['forms/forms'].map(
            (form: any, index: number) => {
              return {
                label: form.name,
                value: index,
                id: form.id,
              }
            }
          ),
          availableFields: store.getters['forms/getFormFields'].map(
            (field: any, index: number) => {
              return {
                label: field.name,
                value: index,
                options: field.options,
              }
            }
          ),
          availableEntities: [
            {
              label: 'tasks',
              value: 0,
            },
            {
              label: 'forms',
              value: 1,
            },
            {
              label: 'logs',
              value: 2,
            },
            {
              label: 'employees',
              value: 3,
            },
            {
              label: 'residents',
              value: 4,
            },
            {
              label: 'schools',
              value: 5,
            },
            {
              label: 'homes',
              value: 6,
            },
            {
              label: 'vehicles',
              value: 7,
            },
          ],
        }
      })

      const postData = reactive({
        reportOnFields: true,
        title: '',
        form: '',
        field: '',
        location: [],
        period: '',
        option: [],
        reportOnEntity: '',
        type: props.selectedWidget.type,
      })

      const formSelected = (index: number) => {
        store.dispatch(
          'forms/formFields',
          formData.value.availableForms[index].id
        )
      }

      var availableOptions: any = reactive({ value: [] })

      const fieldSelected = (index: number) => {
        availableOptions.value = formData.value.availableFields[
          index
        ].options.map((option: any) => option.name)
      }

      const periodSelected = (index: number) => {
        postData.period = formData.value.availablePeriods[index].label
      }

      const onFinish = () => {
        if (postData.form) {
          postData.form = formData.value.availableForms[postData.form].label
        }
        if (postData.field) {
          postData.field = formData.value.availableFields[postData.field].label
        }
        emit('finish-clicked', postData)
      }

      const entitySelected = (index: number) => {
        postData.reportOnEntity = formData.value.availableEntities[index].label
      }

      onMounted(() => {
        // store.dispatch('homes/index') // should work when action is added
        store.dispatch('schools/index')
        store.dispatch('forms/index')
      })

      return {
        formData,
        postData,
        formSelected,
        fieldSelected,
        availableOptions,
        periodSelected,
        onFinish,
        entitySelected,
      }
    },
  })
