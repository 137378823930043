
  import { useStore } from 'vuex'
  import {
    onMounted,
    computed,
    reactive,
    ref,
    watch,
    defineComponent,
  } from 'vue'

  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { renderCellLink } from '@/composables/Grids'
  import { useRouter } from 'vue-router'
  import { Employee } from '@/types/modules/projects/Employee'
  import ConfirmDialog from '@/components/Generic/Notifications/ConfirmDialog.vue'
  import { RecycleBin } from '@/types/modules/generic/RecycleBin'

  export default defineComponent({
    components: { DataGrid, ConfirmDialog },
    props: {
      filters: {
        type: Object,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const query = ref({})
      const store = useStore()
      const router = useRouter()

      const state = reactive({
        componentKey: 0,
        activeChip: 'current' as string,
        chips: ['all', 'current', 'planned', 'past'],
        extraOptions: ['View Deleted Employees'],
        selectedRows: [] as Employee[],
        showDeleteManyConfirmDialog: false,
      })
      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'project_id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'project.name',
          caption: 'Employee Name',
          allowSorting: false,
          cellTemplate: 'ProjectNameCellTemplate',
        },
        {
          dataField: 'project.location.name',
          caption: 'Location',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataField: 'job_title.job_title',
          caption: 'Job Title',
          allowSorting: false,
        },
        {
          dataField: 'in_care_role',
          caption: 'In Care Role',
          allowSorting: false,
          visible: false,
          calculateCellValue: (row: any) => {
            return row.in_care_role ? 'Yes' : 'No'
          },
        },
        {
          dataField: 'years_experience',
          caption: 'Experience (Years)',
          visible: false,
        },
        {
          dataField: 'months_experience',
          caption: 'Experience (Months)',
          visible: false,
        },
        {
          dataField: 'contracting_hours',
          caption: 'Contracted Hours',
          visible: false,
        },
        {
          dataField: 'on_probation',
          caption: 'On Probation',
          visible: false,
        },
        {
          dataField: 'employment_type.name',
          caption: 'Employment Type',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'contract_type.name',
          caption: 'Contract Type',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'ethnicity.name',
          caption: 'Ethnicity',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'nationality.name',
          caption: 'Nationality',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'gender.name',
          caption: 'Gender',
          visible: false,
          allowSorting: false,
        },
        {
          dataField: 'project.start_date',
          caption: 'Start Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'project.end_date',
          caption: 'End Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
          allowSorting: false,
        },
        {
          dataField: 'caregroup_joining_date',
          caption: 'Care Group Joining Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'caregroup_leaving_date',
          caption: 'Care Group Leaving Date',
          visible: false,
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'project.status',
          caption: 'Status',
          allowSorting: false,
        },
        {
          caption: 'Reports',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Reports', 'Reports', {
              id: options.data?.project?.id,
            })
          },
          allowExporting: false,
        },
        {
          caption: 'Create Task',
          cellTemplate: function (container, options) {
            renderCellLink(container, 'Create Task', 'CreateTask', {
              id: options.data?.project?.id,
              projectType: `${options.data?.project?.project_type_label}s`,
            })
          },
          allowExporting: false,
        },
      ])

      const filters = computed(() => {
        return {
          relations: [
            'project.parents',
            'job_title',
            'ethnicity',
            'nationality',
            'gender',
            'employment_type',
            'contract_type',
          ],
          ...query.value,
          ...props.filters,
        }
      })
      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }
      const applyFilters = () => {
        query.value = {
          ...(state.activeChip && { status: state.activeChip }),
        }
      }
      const deleteSelectedRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('projects/deleteMany', {
            ids: state.selectedRows.map((row: Employee) => row.project_id),
          })
          .then(() => {
            state.selectedRows = []
            state.showDeleteManyConfirmDialog = false
          })
          .then(() => store.dispatch('genericStore/showPageLoader', false))
          .finally(() => refreshGrid())
      }
      const refreshGrid = () => {
        state.componentKey += 1
      }
      const recycleBinData: RecycleBin = reactive({
        key: 'project_id',
        action: 'employees/index',
        restoreAction: 'projects/restoreMany',
        columns: [
          {
            caption: 'ID',
            dataField: 'project_id',
            allowGrouping: false,
            dataType: 'number',
            width: 100,
          },
          {
            dataField: 'project.name',
            caption: 'Employee Name',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.start_date',
            caption: 'Start Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.end_date',
            caption: 'End Date',
            visible: true,
            dataType: 'date',
            format: 'dd/MM/yyyy',
            allowSorting: false,
            allowGrouping: false,
          },
          {
            dataField: 'project.status',
            caption: 'Status',
            allowSorting: false,
            allowGrouping: false,
          },
        ],
      })
      const viewDeletedRecords = () => {
        store.commit('genericStore/setRecycleBinData', recycleBinData)
        router.push({
          name: 'RecycleBin',
        })
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete') {
          return (state.showDeleteManyConfirmDialog = true)
        }

        return viewDeletedRecords()
      }

      watch(
        () => state.selectedRows,
        () => updateExtraOptions()
      )
      const rowsSelected = (rows: Employee[]) => {
        state.selectedRows = rows
      }

      const updateExtraOptions = () => {
        const deleteExists = state.extraOptions.indexOf('Delete')

        if (state.selectedRows.length && deleteExists < 0) {
          state.extraOptions.push('Delete')
        } else if (!state.selectedRows.length && deleteExists >= 0) {
          state.extraOptions.splice(deleteExists, 1)
        }
      }
      onMounted(() => {
        applyFilters()
        if (!props.readOnly) {
          state.extraOptions.push('View Deleted Employees')
        }
      })

      return {
        rowsSelected,
        recycleBinData,
        chipClicked,
        deleteSelectedRecords,
        optionClicked,
        state,
        columns,
        filters,
        props,
      }
    },
  })
