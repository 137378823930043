import { DocumentType } from '@/types/modules/documents/DocumentType'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import DocumentTypeService from '@/services/documents/documentTypes'

export type DocumentTypeGetter = GetterTree<DocumentTypeState, any>

export interface DocumentTypeState {
  types: DocumentType[]
  type: DocumentType | null
}

export const state: DocumentTypeState = {
  types: [],
  type: null,
}

export const getters: DocumentTypeGetter = {
  getDocumentTypes: (state) => state.types,
  getDocumentType: (state) => state.type,
}

export const actions: ActionTree<DocumentTypeState, any> = {
  index: async (context, params) => {
    const response = await DocumentTypeService.index(params)

    if (response.success && params.per_page) {
      context.commit('setDocumentTypes', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setDocumentTypes', response.data)

      return response
    }

    return response
  },
  show: (context, params) => {
    const result = Promise.resolve(DocumentTypeService.show(params.id, params))

    result.then((response) => {
      if (response.success) {
        context.commit('setDocumentType', response.data)
      }
    })

    return result
  },
  store: (_context, params) => {
    return Promise.resolve(DocumentTypeService.store(params))
  },
  update: (_context, payload) => {
    return Promise.resolve(DocumentTypeService.update(payload.id, payload))
  },
  delete: (_context, id) => {
    return Promise.resolve(DocumentTypeService.delete(id))
  },
  saveOrderings: (_context, payload) => {
    return Promise.resolve(DocumentTypeService.saveOrderings(payload))
  },
  deleteMany: (_context, payload) => {
    return Promise.resolve(DocumentTypeService.deleteMany(payload))
  },
  restoreMany: (_context, payload) => {
    return Promise.resolve(DocumentTypeService.restoreMany(payload))
  },
  toggleManyVisibility: (_context, params) => {
    return Promise.resolve(DocumentTypeService.toggleManyVisibility(params))
  },
}

export const mutations: MutationTree<DocumentTypeState> = {
  setDocumentTypes: (state, types: DocumentType[]) => (state.types = types),
  setDocumentType: (state, type: DocumentType) => (state.type = type),
}

export const documentTypes = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
