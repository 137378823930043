
  import { useStore } from 'vuex'
  import { onMounted, reactive, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import VehiclesTable from '@/views/modules/projects/vehicles/partials/VehiclesTable.vue'
  import VehicleSettings from '@/views/modules/projects/vehicles/Settings.vue'
  import { isTab } from '@/composables/Generic'

  export default {
    components: {
      VehiclesTable,
      VehicleSettings,
    },
    setup() {
      const store = useStore()
      const query = ref({})
      const router = useRouter()
      const route = useRoute()

      const state = reactive({
        tabs: [
          { name: 'Vehicles', url: `${route.path}?tab=vehicles` },
          { name: 'Settings', url: `${route.path}?tab=settings` },
        ],
      })

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'Vehicles', url: '/project/vehicles' },
      ]

      onMounted(() => {
        if (!route.query.tab) {
          router.replace({ query: { tab: 'vehicles' } })
        }

        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })
        store.commit('genericStore/setTabs', { tabs: state.tabs })
      })

      return {
        state,
        query,
        isTab,
      }
    },
  }
