
  import { defineComponent, reactive } from 'vue'
  const UUID = require('uuid-js')
  export default defineComponent({
    props: {
      label: {
        type: String,
        required: false,
        default: '',
      },
      modelValue: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const id = `true-false-${UUID.create().toString()}`
      props = reactive(props)

      const handleInput = () => {
        emit('update:modelValue', !props.modelValue)
      }

      return {
        id,
        props,
        handleInput,
      }
    },
  })
