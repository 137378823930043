import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user_permissions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_data_grid, {
      action: "users/index",
      ref: "grid",
      columns: _ctx.columns,
      "query-filter": {
        relations: ['role'],
        with: ['permissions'],
        project_id: _ctx.props.projectId,
      },
      "show-grid-toolbar": _ctx.props.showToolbar
    }, {
      "toolbar-primary-button": _withCtx(() => [
        _createVNode(_component_cc_button, {
          "is-disabled": !_ctx.selectedPermissions.length,
          icon: "fas fa-save",
          label: "Save",
          onClick: _ctx.submitClicked
        }, null, 8, ["is-disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["columns", "query-filter", "show-grid-toolbar"])
  ]))
}