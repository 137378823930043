
  import { defineComponent, PropType } from 'vue'
  import LinkCellTemplate from './LinkCellTemplate.vue'

  export default defineComponent({
    components: {
      LinkCellTemplate,
    },
    props: {
      data: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    setup(props) {
      return {
        props,
      }
    },
  })
