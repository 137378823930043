import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_manage_bespoke_report = _resolveComponent("manage-bespoke-report")!
  const _component_manage_published_bespoke_report = _resolveComponent("manage-published-bespoke-report")!

  return (_ctx.route.name === 'BespokeReportEdit')
    ? (_openBlock(), _createBlock(_component_manage_bespoke_report, {
        key: 0,
        report: _ctx.report,
        errors: _ctx.errors,
        onSubmitClicked: _ctx.submitClicked
      }, null, 8, ["report", "errors", "onSubmitClicked"]))
    : (_openBlock(), _createBlock(_component_manage_published_bespoke_report, {
        key: 1,
        report: _ctx.report,
        errors: _ctx.errors,
        onSubmitClicked: _ctx.submitClicked
      }, null, 8, ["report", "errors", "onSubmitClicked"]))
}