import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end mb-8" }
const _hoisted_2 = { class: "mt-6 mb-6" }
const _hoisted_3 = { class: "flex items-start space-x-4" }
const _hoisted_4 = { class: "w-1/2 space-y-8" }
const _hoisted_5 = { class: "w-1/2 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_toolbar = _resolveComponent("page-toolbar")!
  const _component_toggle = _resolveComponent("toggle")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_drop_zone_appearance_template = _resolveComponent("drop-zone-appearance-template")!
  const _component_file_uploader = _resolveComponent("file-uploader")!
  const _component_uploaded_file = _resolveComponent("uploaded-file")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_toolbar, {
        "extra-options": _ctx.extraOptions,
        onOptionClicked: _ctx.optionClicked,
        onSubmitClicked: _ctx.submitClicked,
        onExportClicked: _ctx.exportClicked
      }, null, 8, ["extra-options", "onOptionClicked", "onSubmitClicked", "onExportClicked"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_toggle, {
        label: "Required",
        toggle: _ctx.payload.is_required,
        onInputToggled: _ctx.requiredChanged
      }, null, 8, ["toggle", "onInputToggled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.payload.is_required)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_select_input, {
                label: "Status",
                "is-required": true,
                modelValue: _ctx.payload.status_id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.status_id) = $event)),
                options: _ctx.statusOptions,
                error: _ctx.errors?.status_id,
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errors?.status_id ? (_ctx.errors.status_id = undefined) : ''))
              }, null, 8, ["modelValue", "options", "error"]),
              _createVNode(_component_file_uploader, {
                "upload-text": _ctx.t('components.fileUploader.button'),
                label: 
            _ctx.t(
              'employeeRecruitmentChecks.overseasCriminalRecord.supportingFiles'
            )
          ,
                "is-required": false,
                "allowed-extensions": _ctx.allowedFileExtensions,
                onFilesUploaded: _ctx.filesUploaded
              }, {
                "drop-zone-appearance": _withCtx(() => [
                  _createVNode(_component_drop_zone_appearance_template)
                ]),
                _: 1
              }, 8, ["upload-text", "label", "allowed-extensions", "onFilesUploaded"]),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFiles, (file, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createVNode(_component_uploaded_file, {
                      file: file,
                      onSensitiveToggled: _ctx.fileSensitivityToggled,
                      onDeleteClicked: _ctx.fileDeleteClicked
                    }, null, 8, ["file", "onSensitiveToggled", "onDeleteClicked"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_text_input, {
                label: "Reviewed and approved by",
                icons: [{ icon: 'fa fa-lock', position: 'before' }],
                "is-disabled": true,
                modelValue: _ctx.payload.created_by,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.created_by) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_text_input, {
                label: "Date of review and approval",
                icons: [{ icon: 'fa fa-lock', position: 'before' }],
                "is-disabled": true,
                modelValue: _ctx.payload.created_at,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.created_at) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_text_input, {
                label: "Last updated by",
                icons: [{ icon: 'fa fa-lock', position: 'before' }],
                "is-disabled": true,
                modelValue: _ctx.payload.updated_by,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.updated_by) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_text_input, {
                label: "Last updated at",
                icons: [{ icon: 'fa fa-lock', position: 'before' }],
                "is-disabled": true,
                modelValue: _ctx.payload.updated_at,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.updated_at) = $event))
              }, null, 8, ["modelValue"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 64))
}