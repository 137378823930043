import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Qualification Issuing Bodies",
    "create-view": "QualificationIssuingBodyCreate",
    "edit-view": "QualificationIssuingBodyEdit",
    "delete-view": "QualificationIssuingBodyDelete",
    "restore-view": "QualificationIssuingBodyRestore",
    action: "qualificationIssuingBodies/index",
    "toggle-visibility-action": "qualificationIssuingBodies/toggleManyVisibility",
    "delete-many-action": "qualificationIssuingBodies/deleteMany",
    "reorder-action": "qualificationIssuingBodies/saveOrderings"
  }))
}