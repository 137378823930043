import { bespokeReports } from './bespokeReports'
import { bespokeReportDrafts } from './bespokeReportDrafts'
import { bespokeReportDatasources } from './bespokeReportDatasources'
import { datasourceCategories } from './dataSourceCategories'
import { bespokeReportTypes } from './bespokeReportTypes'
import { publishedBespokeReports } from './publishedBespokeReports'
import { publishedBespokeReportComments } from './publishedBespokeReportComments'

export default {
  bespokeReports,
  bespokeReportDrafts,
  bespokeReportDatasources,
  datasourceCategories,
  bespokeReportTypes,
  publishedBespokeReports,
  publishedBespokeReportComments,
}
