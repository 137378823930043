
  import { defineComponent, computed, reactive } from 'vue'

  export default defineComponent({
    props: {
      label: {
        type: String,
        required: false,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      error: {
        type: String,
        required: false,
      },
      icon: {
        type: String,
        required: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-red-300': props.error,
          'text-gray-400': !props.isDisabled && !props.error,
          'text-gray-300': props.isDisabled,
          'tracking-slight': true,
          'lowercase': true,
          'mb-1': true,
        }
      })
      return {
        labelClasses,
        props,
      }
    },
  })
