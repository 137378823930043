
  import { defineComponent, reactive, ref, Ref } from 'vue'
  import { registerDefaultLineChartPalette } from '@/composables/Charts'
  import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxArgumentAxis,
    DxLegend,
    DxPoint,
  } from 'devextreme-vue/chart'
  export default defineComponent({
    components: {
      DxPoint,
      DxArgumentAxis,
      DxCommonSeriesSettings,
      DxChart,
      DxSeries,
      DxLegend,
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const lineChart = ref() as Ref<DxChart>
      props = reactive(props)

      const dataSources = Object.keys(props.data).map((form) => {
        return {
          value: form,
          name: form,
        }
      })

      let formattedData = {} as Record<string, any>

      Object.entries(props.data).forEach(([formName, values]: any) => {
        Object.entries(values).forEach(([month, value]: any) => {
          if (formattedData[month] == undefined) {
            formattedData[month] = [{ [formName]: value }]
          } else {
            formattedData[month].push({ [formName]: value })
          }
        })
      })

      formattedData = Object.entries(formattedData).map(
        ([month, value]: any) => {
          let values = Object.entries(value).map((value) => {
            return value[1]
          })

          let result = {
            month: month,
          }

          return Object.assign(result, ...values)
        }
      )

      registerDefaultLineChartPalette()

      return {
        lineChart,
        dataSources,
        formattedData,
        props,
      }
    },
  })
