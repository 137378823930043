
  import { defineComponent, PropType, reactive, computed, watch } from 'vue'
  import {
    DxHtmlEditor,
    DxToolbar,
    DxMediaResizing,
    DxItem,
  } from 'devextreme-vue/html-editor'
  import { FieldAttribute } from '../../../../../types/components/FieldAttribute'
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
  import sanitizeHtml from 'sanitize-html'

  export default defineComponent({
    components: {
      DxValidator,
      DxRequiredRule,
      DxHtmlEditor,
      DxToolbar,
      DxMediaResizing,
      DxItem,
    },
    props: {
      fieldAttributes: {
        type: Object as PropType<FieldAttribute>,
        required: true,
      },
    },
    emits: ['value-updated'],
    setup(props, { emit }) {
      props = reactive(props)

      const toolbarItems = [
        'undo',
        'redo',
        'size',
        { name: 'font', acceptedValues: ['Mulish'] },
        'bold',
        'italic',
        'strike',
        'underline',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        'orderedList',
        'bulletList',
        'header',
        'color',
        'background',
        'link',
        'image',
        'clear',
        'blockquote',
      ]

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-400': true,
          'tracking-slight': true,
          'lowercase': true,
        }
      })

      const hasErrors = computed(() => {
        if (
          props.fieldAttributes.required == true &&
          !props.fieldAttributes.value
        ) {
          return true
        }
        return false
      })

      const label = computed(() => {
        return props.fieldAttributes.in_table == false
          ? props.fieldAttributes.label
          : null
      })

      watch(
        () => props.fieldAttributes.value,
        (newValue: any) => {
          emit('value-updated', {
            form_field_id: props.fieldAttributes.id,
            system_name: props.fieldAttributes.field?.system_name,
            value: newValue ? sanitizeHtml(newValue) : newValue,
            row_index: props.fieldAttributes.row_index,
          })
        }
      )

      return {
        props,
        label,
        labelClasses,
        hasErrors,
        toolbarItems,
      }
    },
  })
