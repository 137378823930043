import PublishedBespokeReportService from '@/services/reporting/publishedBespokeReports'
import { PublishedBespokeReport } from '@/types/modules/reporting/PublishedBespokeReport'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface PublishedBespokeReportState {
  report: PublishedBespokeReport | null
  reports: PublishedBespokeReport[]
}
export const state: PublishedBespokeReportState = {
  report: null,
  reports: [],
}

export const getters: GetterTree<PublishedBespokeReportState, any> = {
  report: (state) => state.report,
  reports: (state) => state.reports,
}

export const actions: ActionTree<PublishedBespokeReportState, any> = {
  index: async (context, params) => {
    const response = await PublishedBespokeReportService.index(
      params.bespoke_report_id,
      Object.fromEntries(
        Object.entries(params).filter(([key]) => key !== 'bespoke_report_id')
      )
    )

    if (response.success && params?.per_page) {
      context.commit('setPublishedBespokeReports', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params?.per_page) {
      context.commit('setPublishedBespokeReports', response.data)

      return response
    }

    return response
  },
  show: (context, id: number) => {
    const response = PublishedBespokeReportService.show(id)

    response.then((response) => {
      if (response.success) {
        context.commit('setPublishedBespokeReport', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = PublishedBespokeReportService.store(payload.id, payload)

    response.then((response) => {
      if (response.success) {
        context.commit('setPublishedBespokeReport', response.data)
      }
    })

    return response
  },
  update: async (context, payload) => {
    const response = await PublishedBespokeReportService.update(
      payload.id,
      payload
    )

    if (response.success) {
      context.commit('setPublishedBespokeReport', response.data)
    }

    return response
  },
  deleteMany: async (_context, params) => {
    return await PublishedBespokeReportService.deleteMany(params)
  },
  restoreMany: async (_context, params) => {
    return await PublishedBespokeReportService.restoreMany(params)
  },
  getSelectedReports: (context) => {
    const response = context.getters['reports']

    return {
      data: response,
      totalCount: response.length,
    }
  },
  setSelectedReports: (context, reports: PublishedBespokeReport[]) => {
    context.commit('setPublishedBespokeReports', reports)
  },
}

export const mutations: MutationTree<PublishedBespokeReportState> = {
  setPublishedBespokeReport: (state, report: PublishedBespokeReport) => {
    state.report = report
  },
  setPublishedBespokeReports: (
    state,
    PublishedBespokeReports: PublishedBespokeReport[]
  ) => {
    state.reports = PublishedBespokeReports
  },
}

export const publishedBespokeReports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
