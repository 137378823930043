import { GetterTree, ActionTree, MutationTree } from 'vuex'
import RolesService from '@/services/roles/roles'
import { Role } from '@/types/modules/roles/Role'
import { RoleModulePermissionAudit } from '@/types/modules/roles/RoleModulePermissionAudit'
import { RoleProjectPermissionAudit } from '@/types/modules/roles/RoleProjectPermissionAudit'
import { RoleCompanyPermissionAudit } from '@/types/modules/roles/RoleCompanyPermissionAudit'

export type RoleGetter = GetterTree<RoleState, any>

export interface RoleState {
  roles: Role[]
  rolePermissionLogs: RoleProjectPermissionAudit[]
  roleModulePermissionLogs: RoleModulePermissionAudit[]
  roleCareGroupPermissionLogs: RoleCompanyPermissionAudit[]
}

export const state: RoleState = {
  roles: [],
  rolePermissionLogs: [],
  roleModulePermissionLogs: [],
  roleCareGroupPermissionLogs: [],
}

export const getters: RoleGetter = {
  roles: (state) => state.roles,
  rolePermissionLogs: (state) => state.rolePermissionLogs,
  roleModulePermissionLogs: (state) => state.roleModulePermissionLogs,
  roleCareGroupPermissionLogs: (state) => state.roleCareGroupPermissionLogs,
}

export const actions: ActionTree<RoleState, any> = {
  all: async (context, params) => {
    const response = await RolesService.index(params)

    if (response.success && params.per_page) {
      context.commit('setRoles', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setRoles', response.data)

      return response
    }

    return response
  },
  getRolePermissionLogs: async (context, params) => {
    const response = await RolesService.getRolePermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setRolePermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setRolePermissionLogs', response.data)

      return response
    }

    return response
  },
  getRoleCareGroupPermissionLogs: async (context, params) => {
    const response = await RolesService.getRoleCareGroupPermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setRoleCareGroupPermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setRoleCareGroupPermissionLogs', response.data)

      return response
    }

    return response
  },
  getRoleModulePermissionLogs: async (context, params) => {
    const response = await RolesService.getRoleModulePermissionLogs(params)

    if (response.success && params.per_page) {
      context.commit('setRoleModulePermissionLogs', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setRoleModulePermissionLogs', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<RoleState> = {
  setRoles: (state, roles: Role[]) => {
    state.roles = roles
  },
  setRolePermissionLogs: (state, logs: RoleProjectPermissionAudit[]) => {
    state.rolePermissionLogs = logs
  },
  setRoleModulePermissionLogs: (state, logs: RoleModulePermissionAudit[]) => {
    state.roleModulePermissionLogs = logs
  },
  setRoleCareGroupPermissionLogs: (
    state,
    logs: RoleCompanyPermissionAudit[]
  ) => {
    state.roleCareGroupPermissionLogs = logs
  },
}

export const roles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
