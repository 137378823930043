import { Dbs } from '@/types/modules/staffs/Dbs'
import EmployeeDbsService from '@/services/projects/employees/employeeDbs'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface EmployeeDbsState {
  dbs: Dbs | null
}

export const state: EmployeeDbsState = {
  dbs: null,
}

export const getters: GetterTree<EmployeeDbsState, any> = {
  dbs: (state) => state.dbs,
}

export const actions: ActionTree<EmployeeDbsState, any> = {
  show: (context, params) => {
    const response = Promise.resolve(EmployeeDbsService.show(params.id, params))

    response.then((response) => {
      if (response.success) {
        context.commit('setDbs', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = Promise.resolve(
      EmployeeDbsService.store(payload.id, payload.data)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setDbs', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<EmployeeDbsState> = {
  setDbs: (state, dbs: Dbs) => (state.dbs = dbs),
}

export const employeeDbs = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
