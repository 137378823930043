import { employees } from './employees'
import { employeeJobTitles } from './employeeJobTitles'
import { employeeDbs } from './employeeDbs'
import { employeeOverseasCriminalRecord } from './employeeOverseasCriminalRecord'
import { employeeProofOfIdentity } from './employeeProofOfIdentity'
import { employeeRightToWork } from './employeeRightToWork'
import { qualificationTypes } from './qualificationTypes'
import { qualificationIssuingBodies } from './qualificationIssuingBodies'

export default {
  employees,
  employeeJobTitles,
  employeeDbs,
  employeeOverseasCriminalRecord,
  employeeProofOfIdentity,
  employeeRightToWork,
  qualificationTypes,
  qualificationIssuingBodies,
}
