import OverviewService from '@/services/overview/overview'
import { SummaryWidgetType } from '@/types/modules/summary/SummaryWidgetType'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
export type OverviewGetter = GetterTree<OverviewState, any>

export interface OverviewState {
  widgetTypes: SummaryWidgetType[]
  widgets: object[]
}

export const state: OverviewState = {
  widgetTypes: [],
  widgets: [],
}

export const getters: OverviewGetter = {
  widgetTypes: (state) => state.widgetTypes,
  widgets: (state) => state.widgets,
}

export const actions: ActionTree<OverviewState, any> = {
  getWidgetTypes: (context, params) => {
    let results = Promise.resolve(OverviewService.widgetTypes(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setWidgetTypes', response.data)
    })

    return results
  },
  getWidgets: (context: any, params: any) => {
    let results = Promise.resolve(OverviewService.widgets(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setWidgets', response.data)
    })

    return results
  },
  deleteWidget: (context: any, params: any) => {
    let results = Promise.resolve(OverviewService.deleteWidget(params))

    results.then((response) => {
      console.log(response.message)
      context.commit(
        'setWidgets',
        state.widgets.filter((widget: any) => widget.id !== params.widget.id)
      )
    })
  },
  moveWidget: (context: any, params: any) => {
    context.commit('moveWidget', params)
    let results = Promise.resolve(
      OverviewService.moveWidget(
        state.widgets.map((widget: any, index: number) => {
          return {
            id: widget.id,
            order: index,
            size: widget.size,
          }
        })
      )
    )
    results.then((response) => {
      console.log(response.message)
    })
  },
  newWidget: (context, payload) => {
    context = context
    let results = Promise.resolve(OverviewService.newWidget(payload))

    results.then((response) => {
      console.log(response.message)
    })
  }
}

export const mutations: MutationTree<OverviewState> = {
  setWidgetTypes: (state, widgetTypes) => {
    state.widgetTypes = widgetTypes
  },
  setWidgets: (state, widgets) => {
    state.widgets = widgets
  },
  moveWidget: (state, params) => {
    const sourceWidget = state.widgets[params.sourceIndex]
    state.widgets.splice(params.sourceIndex, 1)
    state.widgets.splice(params.targetIndex, 0, sourceWidget)
  },
}

export const overview = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
