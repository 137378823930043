
  import {
    defineComponent,
    reactive,
    ref,
    Ref,
    onMounted,
    computed,
    watch,
  } from 'vue'
  import { useStore } from 'vuex'
  import { object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import { goBack } from '@/composables/Generic'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'
  import { TagIcon } from '@/types/modules/tags/TagIcon'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import GroupedSelectInput from '@/components/Generic/Forms/Inputs/GroupedSelectInput.vue'
  import { ProjectTypeSection } from '@/types/modules/projects/ProjectTypeSection'
  import { FieldGroup } from '@/types/modules/forms/FieldGroup'
  import { Field } from '@/types/modules/forms/Field'
  import Fields from '@/components/Generic/Forms/Inputs/Actual'
  import IconSelector from '@/components/Generic/Forms/Inputs/IconSelector.vue'
  const UUID = require('uuid-js')

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      TextInput,
      CcSwitch,
      SelectInput,
      GroupedSelectInput,
      IconSelector,
      ...Fields,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
      showAction: {
        type: String,
        required: true,
      },
      storeAction: {
        type: String,
        required: true,
      },
      updateAction: {
        type: String,
        required: true,
      },
      deleteAction: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      props = reactive(props)

      const payload = ref({
        name: '',
        description: '',
        is_required: 0,
        field_id: null,
        project_type_id: null,
        project_type_section_id: null,
        default_value: null,
        tag_icon_id: null,
        is_hidden: 0,
        options: [],
      }) as Ref<{
        name: string
        description: string
        is_hidden: number
        is_required: number
        project_type_id: number | null
        project_type_section_id: number | null
        field_id: number | null
        tag_icon_id: number | null
        is_system?: number
        default_value: any
        options: { name: string }[]
        _method?: 'PUT'
      }>

      const updateDefaultValue = (value: any) =>
        (payload.value.default_value = value)

      const type = computed(() => {
        if (store.getters['projects/types'].length) {
          return store.getters['projects/types'].find(
            (type: ProjectType) => type.name.toLowerCase() === props.type
          )
        }

        return null
      })

      const components = {
        smalltext: 'SmallText',
        yes_no: 'YesNo',
        numeric: 'NumericInput',
        true_false: 'TrueFalse',
        date_input: 'DateInput',
        time_input: 'TimeInput',
      } as Record<string, string>

      const selectedField = computed(() => {
        if (fields.value.length) {
          let fieldCollection = [] as Field[]
          fields.value.forEach((field: FieldGroup) =>
            fieldCollection.push(...field.fields!)
          )

          return fieldCollection.find(
            (field: Field) => field.id === Number(payload.value.field_id)
          )
        }

        return null
      })

      const fieldHasOptions = computed(() => {
        if (fields.value.length) {
          if (selectedField.value) {
            return (
              [
                'checkbox_list',
                'dropdown_select_list',
                'radio_buttons',
              ].indexOf(selectedField.value.system_name) !== -1
            )
          }
        }

        return false
      })

      const defaultValueComponent = computed(() => {
        if (selectedField.value) {
          return components[selectedField.value.system_name] || null
        }

        return null
      })

      const fields = computed(() => {
        if (store.getters['projectTypeAttributes/getAllowedFields'].length) {
          return store.getters['projectTypeAttributes/getAllowedFields']
        }

        return []
      })

      const groupedFields = computed(() => {
        let formatted = {} as Record<string, any>
        for (let group of fields.value) {
          let category = group.name

          if (!formatted[category] && group.fields.length > 0) {
            formatted[category] = []
          }

          if (group.fields.length > 0) {
            for (let field of group.fields) {
              formatted[category].push({
                label: field.name,
                value: field.id,
              })
            }
          }
        }

        return formatted
      })

      let validationObject = object({
        name: string().required('Name is a required value'),
        project_type_id: string().required('Project type is a required value'),
      })

      const saveChanges = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        errors.value = null

        errors.value = await validatePayload(validationObject, payload.value)

        if (errors.value) {
          store.dispatch('genericStore/showPageLoader', false)
          return
        }

        payload.value.field_id = Number(payload.value.field_id)
        payload.value.project_type_section_id = Number(
          payload.value.project_type_section_id
        )

        if (props.id) {
          payload.value._method = 'PUT'
          store
            .dispatch(props.updateAction, { id: props.id, ...payload.value })
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
              goBack()
            })
            .catch((response) =>
              store.dispatch('genericStore/pushNotification', response.message)
            )
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
          return
        }

        store
          .dispatch(props.storeAction, payload.value)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
        return
      }

      const deleteRecord = () => {
        store.dispatch('genericStore/showPageLoader', true)

        if (!payload.value || payload.value.is_system === 1) {
          store.dispatch('genericStore/showPageLoader', false)
          store.dispatch(
            'genericStore/pushNotification',
            'System records cannot be deleted'
          )
          return
        }

        store
          .dispatch(props.deleteAction, props.id)
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const getSections = () => {
        store
          .dispatch('projectTypeSections/index', { type: props.type })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getFields = () => {
        store
          .dispatch('projectTypeAttributes/getAllowedFields')
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const getIcons = () => {
        store
          .dispatch('tags/getIcons', {})
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const icons = computed(() => {
        if (store.getters['tags/getIcons'].length) {
          return store.getters['tags/getIcons'].map((icon: TagIcon) => {
            return {
              label: icon.icon,
              value: icon.id,
            }
          })
        }

        return []
      })

      const sections = computed(() => {
        if (
          store.getters['projectTypeSections/getProjectTypeSections'].length
        ) {
          return store.getters['projectTypeSections/getProjectTypeSections']
            .filter((section: ProjectTypeSection) => section.is_system === 0)
            .map((section: ProjectTypeSection) => {
              return {
                label: section.name,
                value: section.id,
              }
            })
        }

        return []
      })

      const mapToPayload = () => {
        store
          .dispatch(props.showAction, { id: props.id })
          .then((response) => {
            payload.value = response.data
            payload.value.field_id = Number(response.data.field_id)
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
      }

      const getProjectTypes = () => {
        return store
          .dispatch('projects/getProjectTypes', {})
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
      }

      const isChecked = (option: { name: string }) => {
        if (payload.value.default_value) {
          return payload.value.default_value.includes(option.name)
        }

        return false
      }

      const updateDefaults = (option: any) => {
        payload.value.default_value = option.name
      }

      watch(
        () => payload.value.field_id,
        () => {
          if (!props.id) {
            payload.value.default_value = undefined
            payload.value.options = fieldHasOptions.value ? [{ name: '' }] : []
          }
        }
      )

      const getProps = computed(() => {
        return {
          fieldAttributes: {
            id: UUID.create().toString(),
            label: 'Default value',
            value: payload.value.default_value,
            options: payload.value.options,
            in_table: false,
          },
        }
      })

      const loadDependencies = () => {
        return Promise.all([
          getIcons(),
          getFields(),
          getSections(),
          getProjectTypes(),
        ])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        loadDependencies()
          .then(() => {
            if (props.id) {
              mapToPayload()
            }
            payload.value.project_type_id = type.value.id
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))

        store.commit('genericStore/setTabs', { tabs: [] })
      })

      const removeRow = (index: number) => {
        if (payload.value.options.length === 1) {
          store.dispatch(
            'genericStore/pushNotification',
            'This field needs at least one option'
          )
          return
        }

        payload.value.options.splice(index, 1)
      }

      const addNewRow = () => {
        payload.value.options.push({
          name: '',
        })
      }

      const errors = ref(null) as Ref<Record<string, string | undefined> | null>

      return {
        props,
        payload,
        errors,
        deleteRecord,
        saveChanges,
        goBack,
        icons,
        groupedFields,
        sections,
        defaultValueComponent,
        getProps,
        updateDefaultValue,
        fieldHasOptions,
        removeRow,
        addNewRow,
        updateDefaults,
        isChecked,
      }
    },
  })
