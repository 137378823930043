
  import { defineComponent, computed, onMounted, reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import DefaultLayout from '@/views/layout/default.vue'
  import SkeletonLayout from '@/views/layout/skeleton.vue'
  import PageLoader from '@/components/Generic/Loaders/PageLoader.vue'
  import { useStore } from 'vuex'
  import { Version } from './types/modules/generic/Version'

  export default defineComponent({
    components: {
      DefaultLayout,
      SkeletonLayout,
      PageLoader,
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const defaultLayout = 'default-layout'

      const state = reactive({
        apiVersion: null as Version | null,
        appVersion: null as Version | null,
      })

      const layout = computed(() => {
        return route.meta.layout ? `${route.meta.layout}-layout` : defaultLayout
      })

      const showPageLoader = computed(
        () => store.getters['genericStore/showPageLoader']
      )

      const versionOk = computed(() => {
        if (!state.apiVersion || !state.appVersion) {
          return true
        }

        if (
          state.appVersion.version['major'] !==
          state.apiVersion.version['major']
        ) {
          store.dispatch('genericStore/notifyFrontendError', {
            message: 'Major version mismatch',
          })
        }

        if (
          state.appVersion.version['minor'] !==
          state.apiVersion.version['minor']
        ) {
          store.dispatch('genericStore/notifyFrontendError', {
            message: 'Minor version mismatch',
          })
        }

        return true
      })

      onMounted(() => {
        store
          .dispatch('genericStore/getApiVersion', {})
          .then((response) => (state.apiVersion = response))

        store
          .dispatch('genericStore/getAppVersion')
          .then((response) => (state.appVersion = response))
      })

      return {
        layout,
        showPageLoader,
        state,
        versionOk,
      }
    },
  })
