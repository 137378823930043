import { RouteRecordRaw } from 'vue-router'
import RecruitmentCheckHistory from '@/views/modules/projects/employees/partials/recruitment/RecruitmentCheckHistory.vue'
import auth from '@/middleware/auth/auth'
import { canView, canEdit } from '@/middleware/permissions/module'
import DeleteLookup from '@/views/lookups/DeleteLookup.vue'
import ManageJobTitle from '@/views/lookups/ManageJobTitle.vue'
import ManageQualificationType from '@/views/lookups/ManageQualificationType.vue'
import ManageQualificationIssuingBody from '@/views/lookups/ManageQualificationIssuingBody.vue'
import RestoreQualificationTypes from '@/views/modules/projects/employees/partials/qualifications/RestoreQualificationTypes.vue'
import RestoreQualificationIssuingBodies from '@/views/modules/projects/employees/partials/qualifications/RestoreQualificationIssuingBodies.vue'
import RestoreJobTitles from '@/views/modules/projects/employees/partials/RestoreJobTitles.vue'

export default [
  {
    path: '/projects/employees/:employeeId/recruitment-checks/:type/history',
    name: 'RecruitmentCheckHistory',
    component: RecruitmentCheckHistory,
    props: true,
    meta: {
      title: 'Recruitment Check History',
      module: 'employees',
      middleware: [auth, canView],
    },
  },
  {
    path: '/projects/employees/qualifications/types/create',
    name: 'QualificationTypeCreate',
    component: ManageQualificationType,
    props: () => ({
      showAction: 'qualificationTypes/show',
      storeAction: 'qualificationTypes/store',
    }),
    meta: {
      title: 'Create Qualification Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/types/:id/edit',
    name: 'QualificationTypeEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'qualificationTypes/show',
      updateAction: 'qualificationTypes/update',
      deleteAction: 'qualificationTypes/delete',
    }),
    component: ManageQualificationType,
    meta: {
      title: 'Update Qualification Type',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/types/delete',
    name: 'QualificationTypeDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Qualification Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/types/restore',
    name: 'QualificationTypeRestore',
    component: RestoreQualificationTypes,
    meta: {
      title: 'Restore Deleted Qualification Types',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/issuing-bodies/create',
    name: 'QualificationIssuingBodyCreate',
    component: ManageQualificationIssuingBody,
    props: () => ({
      showAction: 'qualificationIssuingBodies/show',
      storeAction: 'qualificationIssuingBodies/store',
    }),
    meta: {
      title: 'Create Qualification Issuing Body',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/issuing-bodies/:id/edit',
    name: 'QualificationIssuingBodyEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'qualificationIssuingBodies/show',
      updateAction: 'qualificationIssuingBodies/update',
      deleteAction: 'qualificationIssuingBodies/delete',
    }),
    component: ManageQualificationIssuingBody,
    meta: {
      title: 'Update Qualification Body',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/issuing-bodies/delete',
    name: 'QualificationIssuingBodyDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Qualification Issuing Bodies',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/qualifications/issuing-bodies/restore',
    name: 'QualificationIssuingBodyRestore',
    component: RestoreQualificationIssuingBodies,
    meta: {
      title: 'Restore Deleted Qualification Issuing Bodies',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/job-titles/create',
    name: 'JobTitleCreate',
    component: ManageJobTitle,
    props: () => ({
      showAction: 'employeeJobTitles/show',
      storeAction: 'employeeJobTitles/store',
    }),
    meta: {
      title: 'Create Job Title',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/job-titles/:id/edit',
    name: 'JobTitleEdit',
    props: (route) => ({
      id: route.params.id,
      showAction: 'employeeJobTitles/show',
      updateAction: 'employeeJobTitles/update',
      deleteAction: 'employeeJobTitles/delete',
    }),
    component: ManageJobTitle,
    meta: {
      title: 'Update Job Title',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/job-titles/delete',
    name: 'JobTitleDelete',
    props: (route) => ({
      deleteManyAction: route.params.deleteManyAction,
    }),
    component: DeleteLookup,
    meta: {
      title: 'Delete Job Titles',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/projects/employees/job-titles/restore',
    name: 'JobTitleRestore',
    component: RestoreJobTitles,
    meta: {
      title: 'Restore Deleted Job Titles',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
