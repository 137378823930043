import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-400"
}
const _hoisted_2 = { class: "flex items-start" }
const _hoisted_3 = { class: "flex items-center relative w-full" }
const _hoisted_4 = {
  key: 0,
  class: "far fa-calendar absolute left-0"
}
const _hoisted_5 = {
  key: 1,
  class: "absolute left-0 text-red-300 far fa-exclamation-circle"
}
const _hoisted_6 = ["min", "max", "required"]
const _hoisted_7 = { class: "flex items-center relative w-full" }
const _hoisted_8 = {
  key: 0,
  class: "far fa-clock absolute left-0"
}
const _hoisted_9 = ["required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.error ? 'border-l-4 border-red-300 pl-3' : '')
  }, [
    _createElementVNode("small", {
      class: _normalizeClass([_ctx.labelClasses, "mb-1"])
    }, [
      (_ctx.props.fieldAttributes.required == true)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.props.fieldAttributes.label), 1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.error)
          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("i", _hoisted_5))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.date) = $event)),
          min: _ctx.min ? _ctx.format(_ctx.min, 'yyyy-MM-dd') : undefined,
          max: _ctx.max ? _ctx.format(_ctx.max, 'yyyy-MM-dd') : undefined,
          required: _ctx.props.fieldAttributes.required == true ? true : false,
          class: _normalizeClass(_ctx.inputClasses),
          type: "date"
        }, null, 10, _hoisted_6), [
          [_vModelText, _ctx.state.date]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (!_ctx.error)
          ? (_openBlock(), _createElementBlock("i", _hoisted_8))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          required: _ctx.props.fieldAttributes.required == true ? true : false,
          class: _normalizeClass(_ctx.inputClasses),
          type: "time",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.time) = $event))
        }, null, 10, _hoisted_9), [
          [_vModelText, _ctx.state.time]
        ])
      ])
    ]),
    (_ctx.props.fieldAttributes.instructions && !_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass([_ctx.labelClasses, "my-1"])
        }, _toDisplayString(_ctx.props.fieldAttributes.instructions), 3))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass([_ctx.labelClasses, "my-1"])
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}