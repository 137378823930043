
  import { defineComponent, reactive, onMounted } from 'vue'
  import VehicleSummaryDetails from './details/VehicleSummaryDetails.vue'
  import VehicleOwnershipDetails from './details/VehicleOwnershipDetails.vue'
  import WizardStepper from '@/components/Generic/Wizard/WizardStep.vue'
  import { WizardStep } from '@/types/components/WizardStep'
  import { useStore } from 'vuex'
  import { object, string, date, number } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import AssociationCreate from '../../partials/ProjectAssociations/partials/AssociationCreate.vue'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import UserPermissions from '@/views/modules/projects/partials/UserPermissions/UserPermissions.vue'
  import { VehiclePayload } from '@/types/modules/projects/Vehicle'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      VehicleSummaryDetails,
      WizardStepper,
      VehicleOwnershipDetails,
      AssociationCreate,
      ValidationErrors,
      UserPermissions,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const state = reactive({
        steps: [
          {
            label: 'Vehicle Details',
            icon: 'fa fa-memo-circle-info',
          },
          {
            label: 'Ownership Details',
            icon: 'fa fa-car-building',
          },
          {
            label: 'Default Association',
            icon: 'fa fa-chart-network',
          },
          {
            label: 'User Permissions',
            icon: 'fa fa-user-lock',
          },
        ] as WizardStep[],
        activeStep: 1,
        furthestStep: 1,
        errors: {} as Record<string, string>,
      })

      const payload = reactive({
        // details
        // summary
        id: undefined,
        name: undefined,
        make: undefined,
        model: undefined,
        colour: undefined,
        vin: undefined,
        registration_number: undefined,
        registration_date: undefined,
        mot_date: undefined,
        tax_date: undefined,
        fuel: undefined,
        company_id: undefined, //caregroup id
        region_id: undefined,
        administrator_id: undefined, //user id
        contact_details: undefined,

        // ownership
        insurance_date: undefined,
        ownership: 'Purchased',
        lease_start: undefined,
        lease_end: undefined,
        purchase_price: undefined,
        purchase_date: undefined,
        start_date: undefined,
        end_date: undefined,
        description: undefined,
        balloon: undefined,
        association: undefined,
        permissions: undefined,
      }) as VehiclePayload

      const summaryValidationSchema = object({
        name: string().required('Please enter a vehicle name'),
        administrator_id: number().required('Please select an administrator'),
        company_id: number().required('Please select a Care Group'),
      })

      const ownershipValidationSchema = object({
        start_date: date()
          .required('Please enter a start date')
          .typeError('Please enter a start date'),
      })

      const associationValidcationSchema = object({
        parent_id: string().required(
          'Please select a Home or School to associate with'
        ),
        admission_date: date().required('Please choose an admission date'),
      })

      const validate = async (step: number) => {
        let validationSchema = object({})
        if (step === 1) {
          validationSchema = summaryValidationSchema
        }
        if (step === 2) {
          validationSchema = ownershipValidationSchema
        }
        if (
          step === 3 &&
          (payload.association?.admission_date ||
            payload.association?.leaving_date ||
            payload.association?.parent_id)
        ) {
          validationSchema = associationValidcationSchema
        }
        const errors = await validatePayload(validationSchema, {
          ...payload,
          ...payload.association,
        } as Record<string, any>)
        state.errors = errors ? errors : {}
        return !errors
      }

      const updatePayload = (updatedPayload: Record<string, any>) => {
        Object.assign(payload, updatedPayload)
        if (Object.keys(state.errors).length) {
          validate(state.activeStep)
        }
      }

      const submitClicked = () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (
          store.getters['userProjectPermissions/selectedPermissions']?.length
        ) {
          payload.permissions =
            store.getters['userProjectPermissions/selectedPermissions']
        }
        store
          .dispatch('vehicles/store', payload)
          .then((response) => {
            if (response.success) {
              router.push({
                name: 'Vehicles',
              })
            }
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
          })
      }

      const stepChanged = async (step: number) => {
        if (step === state.activeStep) {
          return
        }
        state.errors = {}
        if (step < state.activeStep) {
          state.activeStep = step
          return
        }

        for (let i = 1; i < step; i++) {
          if (!(await validate(i))) {
            state.activeStep = i
            return
          }
        }
        state.activeStep = step
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/' },
            { name: 'Vehicles', url: '/projects/vehicles' },
            { name: 'Create Vehicle', url: '/projects/vehicles/create' },
          ],
        })
      })

      return {
        state,
        submitClicked,
        updatePayload,
        stepChanged,
        payload,
      }
    },
  })
