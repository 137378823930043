import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_step_vue = _resolveComponent("wizard-step-vue")!

  return (_openBlock(), _createBlock(_component_wizard_step_vue, {
    steps: _ctx.steps,
    activeStep: _ctx.props.activeStep,
    onStepChanged: _ctx.stepChanged,
    onGoToStep: _ctx.stepChanged,
    onSubmitClicked: _ctx.saveForm
  }, null, 8, ["steps", "activeStep", "onStepChanged", "onGoToStep", "onSubmitClicked"]))
}