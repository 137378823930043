
  import { defineComponent, PropType, reactive } from 'vue'
  import {
    Datasource,
    RuntimeParameter,
  } from '@/types/modules/reporting/Datasource'
  import MultiSelectList from '@/views/modules/reporting/partials/parameters/partials/MultiSelectList.vue'
  import SingleSelectList from '@/views/modules/reporting/partials/parameters/partials/SingleSelectList.vue'
  import SingleDate from '@/views/modules/reporting/partials/parameters/partials/SingleDate.vue'
  import DateRange from '@/views/modules/reporting/partials/parameters/partials/DateRange.vue'
  import TextInput from '@/views/modules/reporting/partials/parameters/partials/TextInput.vue'
  export default defineComponent({
    components: {
      MultiSelectList,
      SingleSelectList,
      SingleDate,
      DateRange,
      TextInput,
    },
    props: {
      datasource: {
        type: Object as PropType<Datasource>,
        required: true,
      },
    },
    emits: ['update:datasource'],
    setup(props, { emit }) {
      props = reactive(props)

      const mapParameterToComponent = (parameter: RuntimeParameter) => {
        if (isType('list-single', parameter) && isOperator('equal', parameter))
          return SingleSelectList
        if (isType('list-single', parameter) && isOperator('in', parameter))
          return MultiSelectList
        if (isType('date-single', parameter)) return SingleDate
        if (isType('date-range', parameter)) return DateRange

        return TextInput
      }

      const isType = (type: string, parameter: RuntimeParameter): boolean => {
        return (
          parameter.data_type === type ||
          (parameter.data_type.startsWith(type) && type === 'list-single')
        )
      }

      const isOperator = (operator: string, parameter: RuntimeParameter) => {
        return parameter.operator === operator
      }

      const update = (parameter: RuntimeParameter) => {
        const index =
          props.datasource.runtime_params.runtime_parameters.findIndex(
            (param: RuntimeParameter) => param.name === parameter.name
          )
        props.datasource.runtime_params.runtime_parameters[index] = parameter
        emit('update:datasource', props.datasource)
      }

      return {
        props,
        update,
        mapParameterToComponent,
      }
    },
  })
