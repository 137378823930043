import { genericStore } from './generic/index'
import mars from './mars'
import projects from './projects'
import { auth } from './auth/index'
import { dashboard } from './dashboard/index'
import users from './user/index'
import { permissions } from './permissions/index'
import { roles } from './roles/index'
import careGroups from './care_groups/index'
import documents from './documents'
import reporting from './reporting'
import { groupings } from './groupings/index'
import forms from './forms/index'
import { procedures } from './procedures/index'
import tasks from './tasks'
import { regions } from './regions/index'
import { settings } from './settings'
import calendar from './calendar/index'
import { summary } from './summary'
import files from './files/index'
import { overview } from './overview/index'
import regReports from './regulatory_reports/index'
import staffs from './staffs/index'
import rotas from './rotas/index'
import { tags } from './tags'

export default {
  ...documents,
  dashboard,
  genericStore,
  auth,
  ...users,
  permissions,
  roles,
  ...projects,
  ...careGroups,
  ...reporting,
  groupings,
  ...forms,
  procedures,
  ...tasks,
  regions,
  settings,
  ...calendar,
  summary,
  overview,
  ...files,
  ...regReports,
  ...staffs,
  ...rotas,
  tags,
  ...mars,
}
