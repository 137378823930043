
  import { RenderedFormFieldSchema } from '@/types/components/FormBuilder'
  import FileUploader from '@/components/Generic/Forms/Inputs/FileUploader.vue'
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    ref,
    Ref,
    onMounted,
    watch,
  } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { SelectOption } from '@/types/components/SelectOption'

  export default defineComponent({
    components: {
      SelectInput,
      TextInput,
      FileUploader,
    },
    props: {
      field: {
        type: Object as PropType<RenderedFormFieldSchema>,
        required: true,
      },
      systemListValues: {
        type: Array as PropType<SelectOption[]>,
        required: true,
      },
      modelValue: {},
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      props = reactive(props)
      const valueAttribute = ref()

      watch(valueAttribute, () => {
        let value = valueAttribute.value

        if (props.field.field.system_name === 'true_false') {
          value = value === '1' ? true : false
        }

        emit('update:modelValue', value)
      })

      const imageSrc = ref() as Ref<string | undefined>

      const setFileSrc = (event: File) => {
        let fileReader = new FileReader()

        fileReader.onload = () => {
          if (fileReader.result && typeof fileReader.result == 'string') {
            imageSrc.value = fileReader.result
          }
        }
        fileReader.readAsDataURL(event)
      }

      const allowedExtensions = computed(() => {
        let imageExts = ['.jpg', '.jpeg', '.png', '.gif']

        if (props.field.field.system_name === 'inline_image') {
          return [...imageExts]
        }

        return [...imageExts, '.pdf', '.doc', '.txt', '.docx']
      })

      const setFilesAsValue = (files: File[]) => {
        if (
          ['inline_image', 'image_editor'].includes(
            props.field.field.system_name
          )
        ) {
          setFileSrc(files[0])
        }
        emit('update:modelValue', files)
      }

      const requiresTextInput = computed(() => {
        return (
          ['smalltext', 'largetext', 'numeric'].indexOf(
            props.field.field.system_name
          ) !== -1
        )
      })

      const requiresBinaryInput = computed(() => {
        return (
          ['true_false', 'yes_no'].indexOf(props.field.field.system_name) !== -1
        )
      })

      const requiresFileInput = computed(() => {
        return (
          ['embed_files', 'inline_image', 'image_editor'].indexOf(
            props.field.field.system_name
          ) !== -1
        )
      })

      const yesNoOptions = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ] as SelectOption[]

      const trueFalseOptions = [
        { value: 1, label: 'True' },
        { value: 0, label: 'False' },
      ] as SelectOption[]

      onMounted(() => {
        valueAttribute.value = props.modelValue
      })

      return {
        props,
        requiresTextInput,
        requiresBinaryInput,
        requiresFileInput,
        yesNoOptions,
        trueFalseOptions,
        valueAttribute,
        setFilesAsValue,
        allowedExtensions,
        imageSrc,
      }
    },
  })
