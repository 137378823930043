
  import { defineComponent, PropType, reactive, onMounted, watch } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { School } from '@/types/modules/projects/School'
  import { useI18n } from 'vue-i18n'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'

  export default defineComponent({
    components: {
      TextInput,
      TextBox,
    },
    props: {
      school: {
        type: Object as PropType<School>,
        required: true,
      },
      errors: {
        type: Object,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const { t } = useI18n()
      props = reactive(props)

      const payload = reactive({
        address: undefined,
        postcode: undefined,
        phone: undefined,
        fax: undefined,
        email: undefined,
      })

      const mapToPayload = () => {
        Object.assign(payload, {
          address: props.school.address,
          postcode: props.school.postcode,
          phone: props.school.phone,
          fax: props.school.fax,
          email: props.school.email,
        })
      }

      watch(payload, () => emit('update-payload', payload))

      onMounted(() => {
        mapToPayload()
      })

      return {
        props,
        t,
        payload,
      }
    },
  })
