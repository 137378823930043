import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_grid = _resolveComponent("data-grid")!

  return (_openBlock(), _createBlock(_component_data_grid, {
    chips: _ctx.state.chips,
    columns: _ctx.columns,
    defaultChip: _ctx.state.activeChip,
    action: _ctx.state.gridAction,
    onChipClicked: _ctx.chipClicked,
    "read-only": true
  }, null, 8, ["chips", "columns", "defaultChip", "action", "onChipClicked"]))
}