
  import { getApiUrl } from '@/composables/Generic'
  import _ from 'lodash'
  import { defineComponent, PropType, reactive, watch } from 'vue'

  interface TableState {
    rows: any
    headings: any
  }

  export default defineComponent({
    props: {
      value: {
        type: Array as PropType<any>,
      },
      fieldAttributes: {
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      const state: TableState = reactive({
        rows: {},
        headings: [],
      })

      watch(
        () => props.value,
        () => {
          state.rows = {}
          state.headings = []
          let orderedValues = _.orderBy(
            props.value,
            (item) => item.field.order,
            ['asc']
          )

          for (let field of orderedValues) {
            let rowIndex = Number.parseInt(field.row_index)

            if (!state.rows[rowIndex]) {
              state.rows[rowIndex] = []
            }

            state.rows[rowIndex].push(field)
          }

          if (state.rows[0]) {
            state.headings = _.orderBy(
              state.rows[0],
              (item) => item.field.order,
              ['asc']
            ).map((field) => field.field.name)
          }
        },
        { deep: true, immediate: true }
      )

      const isLargeTextField = (value: string) => {
        return value === 'large_text'
      }

      const isImageField = (value: string) => {
        return value === 'image' // TODO
      }

      const isEmbedFilesField = (value: string) => {
        return value === 'embed_files'
      }

      const getFileUrl = (value: any, field: any) => {
        if (value) {
          value = value.toString()

          if (
            field.toLowerCase() === 'signature image' &&
            value.indexOf('empty_signature') !== false
          ) {
            return value
          }
        } else {
          value = ''
        }

        // For inline images and image editor values parse the JSON file data and
        // return the view_file endpoint with the file ID and form module param.
        if (
          field.toLowerCase() === 'inline image' ||
          field.toLowerCase() === 'image editor'
        ) {
          let files = JSON.parse(value)
          let url = ''

          if (files) {
            for (const file of files) {
              url =
                getApiUrl() + `/view_file?file_id=${file.fileID}&module=form`
            }
          }

          return url
        }

        let url =
          value.indexOf('http') === 0 || value.indexOf('/') === 0
            ? value
            : '/' + value
        url = `${url}&${new Date().getTime()}`
        return url
      }

      const formattedValue = (value: any) => {
        if (typeof value === 'string') {
          if (value.includes('||')) {
            return value.split('||').join(', ')
          }

          if (value.includes(',')) {
            return value.split(',').join(', ')
          }

          return value
        }

        return ''
      }

      return {
        getFileUrl,
        isLargeTextField,
        isImageField,
        isEmbedFilesField,
        state,
        formattedValue,
      }
    },
  })
