
  import { defineComponent, watch, reactive } from 'vue'
  import Alert from 'sweetalert2'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: {
      fireOn: {
        required: false,
        default: false,
      },
      message: {
        type: String as () => string | null,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      watch(
        props,
        () => {
          if (props.fireOn) {
            Alert.fire({
              toast: true,
              timer: 10000,
              text: props.message
                ? props.message
                : 'Oops! Something went wrong',
              position: 'top-right',
              customClass: {
                popup: 'colored-toast',
                closeButton: 'close-toast',
              },
              showConfirmButton: false,
              showCloseButton: true,
              closeButtonHtml: '<i class="fa-solid fa-xmark"></i>',
              closeButtonAriaLabel: 'Close this toast',
            }).then((response) => {
              if (response.dismiss) {
                store.commit('genericStore/setNotification', null)
              }
            })
          }
        },
        {
          immediate: true,
        }
      )
    },
  })
