
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      icon: {
        type: String,
        required: false,
      },
    },
  })
