import { createApp } from 'vue'
import { i18n } from '@/locales'
import App from '@/App.vue'
import store from '@/store'
import '@/index.css'
import router from '@/router'
import axios from 'axios'

let protocol = process.env.VUE_APP_PROTOCOL
let domain = new URL(location.href).hostname.split('.')[0]
let api_subdomain = process.env.VUE_APP_API_SUBDOMAIN
let base_domain = process.env.VUE_APP_BASE_DOMAIN

axios.defaults.withCredentials = true
axios.defaults.baseURL = `${protocol}${domain}.${api_subdomain}.${base_domain}`

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('authenticated')
      store.commit('auth/setAuthenticated', false)
      store.commit('auth/setAuthenticatedUser', null)
      store.commit('auth/setAuthenticatedUserPermissions', null)
      return window.location.replace('/login')
    } else {
      return Promise.reject(error)
    }
  }
)

const app = createApp(App)

app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (typeof binding.value !== 'function') {
          throw new Error('@click-outside must be a function')
        }
        binding.value()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

app.use(i18n).use(router).use(store).mount('#app')
