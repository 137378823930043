import { EnComponents } from "@/locales/messages/components"

export default {
    careGroups: {
        breadcrumb: 'Care Groups',
        grid: {
            headers: {
                id: EnComponents.grid.headers.id,
                tagging: EnComponents.grid.headers.tagging,
                phone: EnComponents.grid.headers.phone,
                email: EnComponents.grid.headers.email,
                updatedOn: EnComponents.grid.headers.updatedOn,
                updatedBy: EnComponents.grid.headers.updatedBy,
                reports: EnComponents.grid.headers.reports,
                name: `Care Group ${EnComponents.grid.headers.name}`,
                owner: `Care Group ${EnComponents.grid.headers.owner}`
            }
        }
    }
}