import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "font-bold text-3xl tracking-slight mb-8" }
const _hoisted_3 = {
  key: 0,
  class: "w-full"
}
const _hoisted_4 = { class: "space-y-10" }
const _hoisted_5 = {
  key: 1,
  class: "w-full"
}
const _hoisted_6 = { class: "flex items-start space-x-20" }
const _hoisted_7 = { class: "w-1/2" }
const _hoisted_8 = { class: "space-y-10" }
const _hoisted_9 = {
  key: 0,
  class: "w-1/2"
}
const _hoisted_10 = { class: "space-y-10" }
const _hoisted_11 = {
  key: 1,
  class: "w-1/2"
}
const _hoisted_12 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_multi_select = _resolveComponent("multi-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, " Configure your " + _toDisplayString(_ctx.selectedWidget.name), 1),
      _createVNode(_component_button_group, { class: "absolute right-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_cc_button, {
            label: "cancel",
            type: "secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel-clicked')))
          }),
          _createVNode(_component_cc_button, {
            label: "finish",
            type: "success",
            icon: "fas fa-check",
            "is-disabled": 
          (_ctx.selectedWidget.type !== 'data-card' &&
            _ctx.postData.reportOnFields &&
            !_ctx.postData.option.length) ||
          (!_ctx.postData.reportOnFields && !_ctx.postData.location.length)
        ,
            onClick: _ctx.onFinish
          }, null, 8, ["is-disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    (_ctx.selectedWidget.type === 'data-card')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_text_input, {
              label: "Card Title",
              isRequired: false,
              modelValue: _ctx.postData.title,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postData.title) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_select_input, {
              label: "Form Period",
              options: _ctx.formData.availablePeriods,
              isRequired: false,
              "onUpdate:modelValue": _ctx.periodSelected
            }, null, 8, ["options", "onUpdate:modelValue"]),
            _createVNode(_component_select_input, {
              label: "Reports On",
              options: _ctx.formData.availableEntities,
              isRequired: false,
              "onUpdate:modelValue": _ctx.entitySelected
            }, null, 8, ["options", "onUpdate:modelValue"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_text_input, {
                  label: "Card Title",
                  isRequired: false,
                  modelValue: _ctx.postData.title,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.postData.title) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_select_input, {
                  label: "Form Period",
                  options: _ctx.formData.availablePeriods,
                  isRequired: false,
                  "onUpdate:modelValue": _ctx.periodSelected
                }, null, 8, ["options", "onUpdate:modelValue"]),
                _createVNode(_component_select_input, {
                  label: "Select a form",
                  options: _ctx.formData.availableForms,
                  isRequired: false,
                  modelValue: _ctx.postData.form,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postData.form) = $event)),
                    _ctx.formSelected
                  ]
                }, null, 8, ["options", "modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_radio_button, {
                  label: "Report on Fields",
                  layout: "horizontal",
                  buttons: [
              { id: 0, text: 'Yes' },
              { id: 1, text: 'No' },
            ],
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (
              _ctx.postData.reportOnFields = !_ctx.postData.reportOnFields
            ))
                })
              ])
            ]),
            (_ctx.postData.reportOnFields)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_select_input, {
                      label: "Select a field",
                      options: _ctx.formData.availableFields,
                      isRequired: false,
                      modelValue: _ctx.postData.field,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_ctx.postData.field) = $event)),
                        _ctx.fieldSelected
                      ]
                    }, null, 8, ["options", "modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_multi_select, {
                      label: "Select an option",
                      isRequired: false,
                      tagItems: _ctx.availableOptions.value,
                      onAddedItem: _cache[6] || (_cache[6] = (item) => _ctx.postData.option.push(item)),
                      onRemovedItem: _cache[7] || (_cache[7] = 
              (item) => _ctx.postData.option.splice(_ctx.postData.option.indexOf(item), 1)
            )
                    }, null, 8, ["tagItems"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_multi_select, {
                      label: "Select homes and schools",
                      isRequired: false,
                      tagItems: _ctx.formData.availableLocations,
                      onAddedItem: _cache[8] || (_cache[8] = (item) => _ctx.postData.location.push(item)),
                      onRemovedItem: _cache[9] || (_cache[9] = 
              (item) =>
                _ctx.postData.location.splice(_ctx.postData.option.indexOf(item), 1)
            )
                    }, null, 8, ["tagItems"])
                  ])
                ]))
          ])
        ]))
  ], 64))
}