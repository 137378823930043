import BespokeReportIndex from '../views/modules/reporting/Index.vue'
import BespokeReportShow from '../views/modules/reporting/Show.vue'
import BespokeReportDelete from '../views/modules/reporting/Delete.vue'
import BespokeReportRestore from '../views/modules/reporting/Restore.vue'
import BespokeReportCreate from '../views/modules/reporting/Create.vue'
import BespokeReportEdit from '../views/modules/reporting/Edit.vue'
import BespokeReportDesign from '../views/modules/reporting/Design.vue'
import BespokeReportPublish from '../views/modules/reporting/Publish.vue'
import BespokeReportPublishedIndex from '../views/modules/reporting/Published/Index.vue'
import BespokeReportPublishedShow from '../views/modules/reporting/Published/Show.vue'
import BespokeReportPublishedDelete from '../views/modules/reporting/Published/Delete.vue'
import BespokeReportPublishedRestore from '../views/modules/reporting/Published/Restore.vue'
import BespokeReportPublishedEdit from '../views/modules/reporting/Edit.vue'
import BespokeReportPublishedCommentCreate from '../views/modules/reporting/Published/Comments/Create.vue'
import BespokeReportPublishedCommentShow from '../views/modules/reporting/Published/Comments/Show.vue'
import BespokeReportPublishedCommentDelete from '../views/modules/reporting/Published/Comments/Delete.vue'

import { RouteRecordRaw } from 'vue-router'
import auth from '@/middleware/auth/auth'
import { canEdit, canView } from '@/middleware/permissions/module'

export default [
  {
    path: '/bespoke-reporting',
    name: 'BespokeReportIndex',
    component: BespokeReportIndex,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canView],
    },
  },
  {
    path: '/bespoke-reporting/:id',
    name: 'BespokeReportShow',
    props: true,
    component: BespokeReportShow,
    meta: {
      title: 'Bespoke Report',
      module: 'reporting',
      middleware: [auth, canView],
    },
  },
  {
    path: '/bespoke-reporting/delete',
    name: 'BespokeReportDelete',
    component: BespokeReportDelete,
    meta: {
      title: 'Bespoke Reporting',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/restore',
    name: 'BespokeReportRestore',
    component: BespokeReportRestore,
    meta: {
      title: 'Bespoke Reporting',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/create',
    name: 'BespokeReportCreate',
    component: BespokeReportCreate,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/edit',
    name: 'BespokeReportEdit',
    component: BespokeReportEdit,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/design',
    name: 'BespokeReportDesign',
    component: BespokeReportDesign,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/publish',
    name: 'BespokeReportPublish',
    component: BespokeReportPublish,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports',
    name: 'BespokeReportPublishedIndex',
    component: BespokeReportPublishedIndex,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canView],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/:publishedReportId',
    name: 'BespokeReportPublishedShow',
    component: BespokeReportPublishedShow,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canView],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/delete',
    name: 'BespokeReportPublishedDelete',
    component: BespokeReportPublishedDelete,
    meta: {
      title: 'Bespoke Reporting',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/restore',
    name: 'BespokeReportPublishedRestore',
    component: BespokeReportPublishedRestore,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'sysadmin',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:bespokeReportId/published-reports/:id/edit',
    name: 'BespokeReportPublishedEdit',
    component: BespokeReportPublishedEdit,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/:publishedReportId/comments/:commentId',
    name: 'BespokeReportPublishedCommentShow',
    component: BespokeReportPublishedCommentShow,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/:publishedReportId/comments/create',
    name: 'BespokeReportPublishedCommentCreate',
    component: BespokeReportPublishedCommentCreate,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
  {
    path: '/bespoke-reporting/:id/published-reports/:publishedReportId/comments/delete',
    name: 'BespokeReportPublishedCommentDelete',
    component: BespokeReportPublishedCommentDelete,
    props: true,
    meta: {
      title: 'Bespoke Reporting',
      module: 'reporting',
      middleware: [auth, canEdit],
    },
  },
] as RouteRecordRaw[]
