export default {
  getTasksForSummary: (params: any) => {
    let data: object[] = []
    switch (params.category) {
      case 'all tasks':
        data = [
          {
            id: 14245,
            name: 'Daily Summary',
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_id: 1,
            category: 'to-do tasks',
            project_name: 'Eric Broadhurst',
            project_image: 'https://randomuser.me/api/portraits/men/41.jpg', // Summary Task data should come back with project image now
          },
          {
            id: 19239,
            name: 'Daily Summary',
            project_id: 1,
            type: 'warning',
            category: 'draft tasks',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_name: 'Naomi Jane Smith',
            project_image: 'https://randomuser.me/api/portraits/women/41.jpg',
          },
          {
            id: 19238,
            name: 'Daily Summary',
            project_id: 2,
            type: 'danger',
            category: 'overdue tasks',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: '2021-05-10 13:31:04',
            end_date: '2022-05-24T22:59:59.000000Z',
            project_name: 'Sophie Dunstall',
            project_image: 'https://randomuser.me/api/portraits/women/68.jpg',
          },
          {
            id: 19237,
            name: 'Daily Summary',
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_id: 2,
            category: 'to-do tasks',
            project_name: 'George Grey',
            project_image: 'https://randomuser.me/api/portraits/men/68.jpg',
          },
        ]
        break
      case 'to-do tasks':
        data = [
          {
            id: 19240,
            name: 'Daily Summary',
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_id: 1,
            project_name: 'Eric Broadhurst',
            project_image: 'https://randomuser.me/api/portraits/men/41.jpg', // Summary Task data should come back with project image now
          },
          {
            id: 19239,
            name: 'Daily Summary',
            project_id: 1,
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_name: 'Naomi Jane Smith',
            project_image: 'https://randomuser.me/api/portraits/women/41.jpg',
          },
          {
            id: 19238,
            name: 'Daily Summary',
            project_id: 2,
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_name: 'Sophie Dunstall',
            project_image: 'https://randomuser.me/api/portraits/women/68.jpg',
          },
          {
            id: 19237,
            name: 'Daily Summary',
            type: 'primary',
            created_at: '2022-05-20T23:00:10.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-24T22:59:59.000000Z',
            project_id: 2,
            project_name: 'George Grey',
            project_image: 'https://randomuser.me/api/portraits/men/68.jpg',
          },
        ]
        break
      case 'overdue tasks':
        data = [
          {
            id: 19218,
            name: 'Cleaning YP Room',
            type: 'danger',
            created_at: '2022-05-19T23:00:16.000000Z',
            start_date: '2022-05-22T23:00:00.000000Z',
            end_date: '2022-05-23T22:59:59.000000Z',
            project_id: 1,
            project_name: 'George Grey',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19221,
            name: 'Cleaning YP Room',
            type: 'danger',
            created_at: '2022-05-19T23:00:16.000000Z',
            start_date: '2022-05-22T23:00:00.000000Z',
            end_date: '2022-05-23T22:59:59.000000Z',
            project_id: 25,
            project_name: 'Eric Broadhurst',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
        ]
        break
      case 'complete tasks':
        data = [
          {
            id: 19279,
            name: 'Cleaning YP Room',
            type: 'success',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 1,
            project_name: 'George Grey',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19282,
            name: 'Cleaning YP Room',
            type: 'success',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-27T22:59:59.000000Z',
            project_name: 'Eric Broadhurst',
            project_id: 1,
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19280,
            name: 'Cleaning YP Room',
            type: 'success',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 2,
            project_name: 'Sophie Dunstall',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19281,
            name: 'Cleaning YP Room',
            type: 'success',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: new Date().toDateString(),
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 2,
            project_name: 'Naomi Jane Smith',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
        ]
        break
      case 'draft tasks':
        data = [
          {
            id: 19279,
            name: 'Cleaning YP Room',
            type: 'warning',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: '2022-05-26T23:00:00.000000Z',
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 1,
            project_name: 'George Grey',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19282,
            name: 'Cleaning YP Room',
            type: 'warning',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: '2022-05-26T23:00:00.000000Z',
            end_date: '2022-05-27T22:59:59.000000Z',
            project_name: 'Eric Broadhurst',
            project_id: 1,
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19280,
            name: 'Cleaning YP Room',
            type: 'warning',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: '2022-05-26T23:00:00.000000Z',
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 2,
            project_name: 'Sophie Dunstall',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
          {
            id: 19281,
            name: 'Cleaning YP Room',
            type: 'warning',
            created_at: '2022-05-23T23:00:12.000000Z',
            start_date: '2022-05-26T23:00:00.000000Z',
            end_date: '2022-05-27T22:59:59.000000Z',
            project_id: 2,
            project_name: 'Naomi Jane Smith',
            project_image: 'https://bit.ly/3jDg2Q6',
          },
        ]
        break
    }

    // Pretend to filter if project id
    if (params.project_id) {
      data = data.filter((task: any) => task.project_id == params.project_id)
    }

    if (params.category !== 'all tasks') {
      data = data.map((item: any) => {
        return { ...item, ...{ category: params.category } }
      })
    }

    return {
      success: true,
      message: 'Summary tasks returned successfully',
      data: data,
    }
  },
  getApprovals: (params: any) => {
    let data = [
      {
        id: 7532,
        name: 'Daily Cleaning Record 10.08.2021',
        created_at: '2021-08-10T13:46:06.000000Z',
        task_id: 13817,
        task_start_date: '2021-08-10 14:44:26',
        task_end_date: '2021-08-10 14:44:26',
        project_name: 'Hilltop View',
        project_id: 2,
      },
      {
        id: 7528,
        name: 'Daily Cleaning Record 10.08.2021',
        created_at: '2021-08-10T12:32:00.000000Z',
        task_id: 13810,
        task_start_date: '2021-08-10 13:31:04',
        task_end_date: '2021-08-10 13:31:04',
        project_id: 1,
        project_name: 'Hilltop View',
      },
    ]

    if (params.project_id) {
      data = data.filter((task: any) => task.project_id == params.project_id)
    }

    return {
      data: data,
      success: true,
      message: 'Approvals returned successfully',
    }
  },
}
