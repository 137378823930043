export default {
  auth: {
    breadcrumb: 'Manage Profile',
    username: 'Username',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    newPassword: 'New Password',
    newPasswordConfirmation: 'Repeat Password',
    password: 'Current Password',
    errors: {
      password: {
        missing: 'You must enter your current password to save changes',
      },
      newPassword: {
        mismatch: 'The new passwords provided do not match',
      },
    },
  },
}
