import axios from 'axios'

export default {
  csrf: async () => {
    return axios
      .get('sanctum/csrf-cookie')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  login: async (payload: { username: string; password: string }) => {
    return axios
      .post('auth/login', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  logout: async () => {
    return axios
      .post('auth/logout')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getAuthenticatedUser: async () => {
    return axios
      .get('auth/user')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  acceptTermsConditions: async () => {
    return axios
      .post('auth/user/accept-terms-conditions')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateProfile: async (payload: any) => {
    return axios
      .put('auth/profile', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserAccessLogs: async (params: any) => {
    return axios
      .get('auth/audit/logins', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getUserAccessFailedLogs: async (params: any) => {
    return axios
      .get('auth/audit/failed-logins', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
