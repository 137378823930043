
  import {
    defineComponent,
    markRaw,
    ref,
    onBeforeUpdate,
    Ref,
    reactive,
    PropType,
  } from 'vue'
  import GraphCard from '@/components/Generic/Card/GraphCard.vue'
  import DoughnutChart from '@/components/Generic/Charts/DoughnutChart.vue'
  import ColumnChart from '@/components/Generic/Charts/ColumnChart.vue'
  import LineChart from '@/components/Generic/Charts/LineChart.vue'
  import DataCard from '@/components/Generic/Charts/DataCard.vue'
  import PieChart from '@/components/Generic/Charts/PieChart.vue'
  import DxChart from 'devextreme-vue/chart'
  import { useStore } from 'vuex'
  import { confirm } from 'devextreme/ui/dialog'

  export default defineComponent({
    components: {
      GraphCard,
    },
    props: {
      widgets: Array as PropType<any[]>,
    },
    setup() {
      const widgetRefs = ref([]) as Ref<any[]>
      const exportGraph = (value: any, title: string, type: string) => {
        let refs = Object.values(widgetRefs.value[value].$refs)
        if (!refs.length) {
          throw new Error('Error exporting graph')
        }

        let element = refs[0] as DxChart
        element.instance?.exportTo(title, type)
      }

      interface Movable {
        sourceIndex?: number
        targetIndex?: number
      }

      const movable: Movable = reactive({
        sourceIndex: undefined,
        targetIndex: undefined,
      })

      const chartComponents = markRaw({
        'Doughnut Chart': DoughnutChart,
        'Trends': ColumnChart,
        'Tasks Per Form': LineChart,
        'Data Card': DataCard,
        'Pie Chart': PieChart,
      })

      const getProps = (widget: any) => {
        switch (widget.graph_type) {
          case 'Tasks Per Form':
            return {
              data: widget.data,
            }

          case 'Data Card':
            return {
              data: widget.data,
            }

          default: // doughnut, column, pie
            return {
              data: Object.values(widget.data).map((entry: any) => {
                return { label: entry.name, value: entry.count }
              }),
            }
        }
      }

      onBeforeUpdate(() => {
        widgetRefs.value = []
      })
      const store = useStore()

      const deleteWidget = (widget: object) => {
        let result = confirm('Are you sure?', 'Confirm changes')
        result.then((dialogResult) => {
          if (dialogResult) {
            store.dispatch('overview/deleteWidget', { widget })
          }
        })
      }

      const moveWidget = () => {
        if (movable.sourceIndex !== undefined) {
          store.dispatch('overview/moveWidget', Object.assign({}, movable))
        }
        movable.sourceIndex = undefined
        movable.targetIndex = undefined
      }

      const getClass = (widget: any, index: number) => {
        return {
          [`col-span-${widget.size}`]: true,
          'flex': true,
          'flex-col': true,
          'outline outline-dashed outline-4 outline-gray-200':
            movable.sourceIndex !== undefined && index == movable.targetIndex,
        }
      }

      return {
        widgetRefs,
        exportGraph,
        chartComponents,
        deleteWidget,
        getProps,
        moveWidget,
        movable,
        getClass,
      }
    },
  })
