import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22a422d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-1xs font-display font-semibold text-gray-300" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  key: 0,
  class: "fas fa-check-circle text-green-500 ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_progress_bar = _resolveComponent("dx-progress-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.props.message), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_dx_progress_bar, {
        class: _normalizeClass(_ctx.props.value === _ctx.props.max ? 'complete' : 'in-progress'),
        min: _ctx.props.min,
        max: _ctx.props.max,
        value: _ctx.props.value,
        "show-status": false,
        width: "100%"
      }, null, 8, ["class", "min", "max", "value"]),
      (_ctx.props.value === _ctx.props.max)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}