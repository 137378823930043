import { RightToWork } from '@/types/modules/staffs/RightToWork'
import EmployeeRightToWorkService from '@/services/projects/employees/employeeRightToWork'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface EmployeeRightToWorkState {
  rightToWork: RightToWork | null
}

export const state: EmployeeRightToWorkState = {
  rightToWork: null,
}

export const getters: GetterTree<EmployeeRightToWorkState, any> = {
  rightToWork: (state) => state.rightToWork,
}

export const actions: ActionTree<EmployeeRightToWorkState, any> = {
  show: (context, params) => {
    const response = Promise.resolve(
      EmployeeRightToWorkService.show(params.id, params)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setRightToWork', response.data)
      }
    })

    return response
  },
  store: (context, payload) => {
    const response = Promise.resolve(
      EmployeeRightToWorkService.store(payload.id, payload.data)
    )

    response.then((response) => {
      if (response.success) {
        context.commit('setRightToWork', response.data)
      }
    })

    return response
  },
}

export const mutations: MutationTree<EmployeeRightToWorkState> = {
  setRightToWork: (state, rightToWork: RightToWork) =>
    (state.rightToWork = rightToWork),
}

export const employeeRightToWork = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
