import { ActionTree, GetterTree, MutationTree } from 'vuex'
import DefaultShifts from '@/services/rotas/DefaultShifts'
import { DefaultShift } from '@/types/modules/rotas/DefaultShift'

export type DefaultShiftGetter = GetterTree<DefaultShiftState, any>

export interface DefaultShiftState {
  plannedShifts: any[]
  defaultShifts: DefaultShift[]
  defaultShiftCount: number | null
}

export const state: DefaultShiftState = {
  plannedShifts: [],
  defaultShifts: [],
  defaultShiftCount: null,
}

export const getters: DefaultShiftGetter = {
  getPlannedShifts: (state) => state.plannedShifts,
  getDefaultShifts: (state) => state.defaultShifts,
  getDefaultShiftCount: (state) => state.defaultShiftCount,
}

export const actions: ActionTree<DefaultShiftState, any> = {
  index: (context, params) => {
    let results = Promise.resolve(DefaultShifts.index(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setDefaultShifts', response.data)
    })

    return results
  },
  count: (context, params) => {
    let results = Promise.resolve(DefaultShifts.count(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setDefaultShiftCount', response.data)
    })

    return results
  },
  getPlannedShifts: (context, params) => {
    let results = Promise.resolve(DefaultShifts.index(params))

    results.then((response) => {
      console.log(response.message)
      context.commit('setPlannedShifts', response.data)
    })

    return results
  },
}

export const mutations: MutationTree<DefaultShiftState> = {
  setPlannedShifts: (state, shifts) => (state.plannedShifts = shifts),
  setDefaultShifts: (state, shifts) => (state.defaultShifts = shifts),
  setDefaultShiftCount: (state, count) => (state.defaultShiftCount = count),
}

export const defaultShifts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
