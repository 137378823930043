import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(_ctx.avatarClasses),
    src: _ctx.computedUrl,
    alt: _ctx.props.alt,
    onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.imageLoaded = true)),
    onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setDefaultImage && _ctx.setDefaultImage(...args)))
  }, null, 42, _hoisted_1))
}