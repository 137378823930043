import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export const exportPageToPdf = (data: any, name?: string) => {
  // A4 - Inches W:8.25 H: 11.75
  const pdf = new jsPDF('p', 'in', 'a4')
  const rows = []

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const name = key
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (l) => l.toUpperCase())

      rows.push([name, data[key]])
    }
  }

  autoTable(pdf, {
    head: [['Name', 'Value']],
    body: rows,
  })

  pdf.save(name ? name : 'page.pdf')
}

export const exportPageToExcel = (data: Record<string, any>, name?: string) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Sheet 1')

  worksheet.columns = Object.keys(data).map((key) => ({
    key,
    header: key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
    width: key.length * 1.2,
  }))
  worksheet.addRow(data)

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      name ? `${name}.xlsx` : 'page.xlsx'
    )
  })
}
