import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_trigger_config = _resolveComponent("trigger-config")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_select_input, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      label: 
      _ctx.props.fieldAttributes.label ? _ctx.props.fieldAttributes.label : undefined
    ,
      isRequired: _ctx.props.fieldAttributes.required == true,
      message: 
      _ctx.props.fieldAttributes.instructions
        ? _ctx.props.fieldAttributes.instructions
        : undefined
    ,
      options: _ctx.formattedOptions,
      error: _ctx.error
    }, null, 8, ["modelValue", "label", "isRequired", "message", "options", "error"]),
    (_ctx.showTriggerConfig)
      ? (_openBlock(), _createBlock(_component_trigger_config, {
          key: 0,
          config: {
      form: {
        types: _ctx.fieldAttributes.form_types,
        always_trigger_for_same_project:
          _ctx.selectedOption?.always_trigger_for_same_project || 0,
        trigger_has_date: _ctx.selectedOption?.trigger_has_date || 0,
        restrict_projects_by_association: _ctx.selectedOption?.trigger_has_date || 1,
      },
      triggered_form: _ctx.triggeredForm,
      project: _ctx.triggeredProject,
      project_parent_id: _ctx.fieldAttributes.project_parent_id
        ? Number(_ctx.fieldAttributes.project_parent_id)
        : undefined,
    },
          showConfigByDefault: _ctx.showTriggerConfig,
          onConfigChanged: _ctx.updateTrigger
        }, null, 8, ["config", "showConfigByDefault", "onConfigChanged"]))
      : _createCommentVNode("", true)
  ], 64))
}