import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!

  return (_openBlock(), _createBlock(_component_button_group, { class: "flex-wrap gap-y-2" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentChips, (chip, index) => {
        return (_openBlock(), _createBlock(_component_cc_button, {
          onButtonClicked: ($event: any) => (_ctx.chipClicked(chip)),
          type: _ctx.getButtonType(chip),
          label: chip,
          key: index,
          title: 
        _ctx.getButtonType(chip).endsWith('-error')
          ? `There are errors on ${chip}`
          : chip
      
        }, null, 8, ["onButtonClicked", "type", "label", "title"]))
      }), 128))
    ]),
    _: 1
  }))
}