
  import {
    ExtendedFormFieldOption,
    RenderedFormFieldSchema,
  } from '@/types/components/FormBuilder'
  import FieldItemOptions from './partials/FieldItemOptions.vue'
  import {
    defineComponent,
    PropType,
    reactive,
    computed,
    ref,
    onMounted,
    Ref,
    watch,
    nextTick,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { MultiSelect as MultiSelectType } from '@/types/components/MultiSelect'
  import CcSwitch from '@/components/Generic/Forms/Inputs/Switch.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import FieldItemValue from './partials/FieldItemValue.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { object, string } from 'yup'
  import { SelectOption } from '@/types/components/SelectOption'

  export default defineComponent({
    components: {
      TextInput,
      ButtonGroup,
      CcButton,
      MultiSelect,
      TextBox,
      CcSwitch,
      SelectInput,
      FieldItemValue,
      FieldItemOptions,
      DateInput,
    },
    props: {
      data: {
        type: Object as PropType<RenderedFormFieldSchema>,
        required: true,
      },
      forms: {
        type: Array as PropType<MultiSelectType[]>,
        required: true,
      },
      keywords: {
        type: Array as PropType<MultiSelectType[]>,
        required: true,
      },
      systemListValues: {
        type: Array as PropType<SelectOption[]>,
        required: true,
      },
      inMoveMode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['delete-field', 'enter-move-mode'],
    setup(props, { emit }) {
      props = reactive(props)
      const fieldName = ref()
      const showConfigure = ref(false)
      const payload = ref({}) as Ref<RenderedFormFieldSchema>

      const showDateMinMax = ref(false)

      const isTableChild = computed(() => {
        return (
          props.data.parent_id && props.data.field.system_name !== 'table_end'
        )
      })

      const updateFieldOptions = (options: ExtendedFormFieldOption[]) => {
        let checkedOptions = options.filter(
          (option: ExtendedFormFieldOption) =>
            Boolean(option.is_default) !== false
        )

        let valueIndex = payload.value.field_attributes.findIndex(
          (att) => att.name === 'value'
        )
        let value = payload.value.field_attributes.find(
          (att) => att.name === 'value'
        )!

        if (props.data.field.system_name === 'checkbox_list') {
          value.value = checkedOptions
            ? checkedOptions.map((option) => option.name).join('||')
            : null
        } else {
          value.value =
            checkedOptions.length === 1 ? checkedOptions[0].name : null
        }

        payload.value.field_attributes.splice(valueIndex, 1, value)
        payload.value.options = options
      }

      const fieldHasOptions = computed(() => {
        return (
          ['checkbox_list', 'dropdown_select_list', 'radio_buttons'].indexOf(
            props.data.field.system_name
          ) !== -1
        )
      })

      const sizes = [
        {
          value: 'small',
          label: 'Small',
        },
        {
          value: 'medium',
          label: 'Medium',
        },
        {
          value: 'large',
          label: 'Large',
        },
      ]

      const deleteField = (field: RenderedFormFieldSchema) =>
        emit('delete-field', field)

      const toggleView = () => {
        showConfigure.value = !showConfigure.value
      }

      const enterMoveMode = () => {
        if (props.inMoveMode) {
          return
        }
        emit('enter-move-mode', props.data)
      }

      const configureField = () => {
        payload.value = props.data
        nextTick(() => (fieldLoaded.value = true))
      }

      const fieldLoaded = ref(false)

      const isDbField = (field: RenderedFormFieldSchema): boolean => {
        try {
          object({
            id: string().uuid(),
          }).validateSync({ id: field.id })
          return false
        } catch {
          return true
        }
      }

      watch(
        payload,
        () => {
          if (!fieldLoaded.value) return
          if (isDbField(payload.value)) {
            payload.value.has_changed = true
          }
        },
        { deep: true, flush: 'sync' }
      )

      onMounted(() => {
        configureField()
      })

      return {
        props,
        deleteField,
        toggleView,
        showConfigure,
        payload,
        fieldName,
        enterMoveMode,
        fieldHasOptions,
        updateFieldOptions,
        showDateMinMax,
        sizes,
        isTableChild,
      }
    },
  })
