import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filing_cabinet = _resolveComponent("filing-cabinet")!
  const _component_home_details = _resolveComponent("home-details")!
  const _component_project_associations = _resolveComponent("project-associations")!
  const _component_reg_reporting = _resolveComponent("reg-reporting")!
  const _component_medical_cabinet = _resolveComponent("medical-cabinet")!
  const _component_user_permissions = _resolveComponent("user-permissions")!
  const _component_rota_index = _resolveComponent("rota-index")!
  const _component_project_attachments = _resolveComponent("project-attachments")!
  const _component_task_attachments = _resolveComponent("task-attachments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isTab('filing-cabinet'))
      ? (_openBlock(), _createBlock(_component_filing_cabinet, {
          key: 0,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('details') && _ctx.home)
      ? (_openBlock(), _createBlock(_component_home_details, {
          key: 1,
          home: _ctx.home
        }, null, 8, ["home"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('associations') && _ctx.home?.project)
      ? (_openBlock(), _createBlock(_component_project_associations, {
          key: 2,
          "instance-id": _ctx.home.id,
          project: _ctx.home.project
        }, null, 8, ["instance-id", "project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('reg-reporting'))
      ? (_openBlock(), _createBlock(_component_reg_reporting, {
          key: 3,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('medical-cabinet') && _ctx.home)
      ? (_openBlock(), _createBlock(_component_medical_cabinet, {
          key: 4,
          project: _ctx.home.project
        }, null, 8, ["project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('user-permissions') && _ctx.home)
      ? (_openBlock(), _createBlock(_component_user_permissions, {
          key: 5,
          "project-id": _ctx.home.project_id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('rotas') && _ctx.home)
      ? (_openBlock(), _createBlock(_component_rota_index, {
          key: 6,
          project: _ctx.home.project
        }, null, 8, ["project"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('home-attachments'))
      ? (_openBlock(), _createBlock(_component_project_attachments, {
          key: 7,
          id: _ctx.id
        }, null, 8, ["id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('log-attachments'))
      ? (_openBlock(), _createBlock(_component_task_attachments, {
          key: 8,
          "project-id": _ctx.id
        }, null, 8, ["project-id"]))
      : _createCommentVNode("", true)
  ]))
}