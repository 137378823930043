
  import { computed, defineComponent } from 'vue'
  import { format } from 'date-fns'

  export default defineComponent({
    props: {
      task: {
        type: Object,
        required: true,
      },
      currentTaskId: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const backgroundColor = computed(() => {
        if (props.task.status == 'Completed') {
          return '#E9FBF3'
        }

        if (props.task.status == 'Incomplete') {
          return '#FBF2E9'
        }

        return '#fff'
      })

      const textColor = computed(() => {
        if (
          props.task.status == 'Incomplete' ||
          props.task.status == 'Completed'
        ) {
          return '#fff'
        }

        return '#333'
      })

      const lineColor = computed(() => {
        if (props.task.status == 'Completed') {
          return '#136C43'
        }

        if (props.task.status == 'Incomplete') {
          return '#CC7224'
        }

        return '#333'
      })

      const borderColor = computed(() => {
        if (props.task.status == 'Completed') {
          return '#22C379'
        }

        if (props.task.status == 'Incomplete') {
          return '#CC7224'
        }

        return '#bdbdbd'
      })

      const pillColor = computed(() => {
        if (props.task.status == 'Completed') {
          return '#22C379'
        }

        if (props.task.status == 'Incomplete') {
          return '#E8AF7D'
        }

        return '#F1F2F3'
      })

      return {
        pillColor,
        borderColor,
        backgroundColor,
        textColor,
        lineColor,
        format,
      }
    },
  })
