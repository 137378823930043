export default {
    login: {
        heading: 'Welcome Back',
        tagline: 'Log in',
        disclaimer: `Please be aware that Clearcare is best utilised under Google Chrome
          or Mozilla Firefox browsers. We do not support Internet Explorer or
          Edge as data loss can occur under some versions of these browsers.
          We apologise for any inconvienience caused.`,
        error: 'Incorrect username or password'
      },
}