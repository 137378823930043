import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "my-6 p-6 border border-gray-100 shadow-md" }
const _hoisted_2 = {
  key: 0,
  class: "my-6"
}
const _hoisted_3 = {
  key: 0,
  class: "my-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_table = _resolveComponent("cc-table")!
  const _component_multi_step = _resolveComponent("multi-step")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formattedFields.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formattedFields, (field) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `field-${field.id}`
          }, [
            (field.field)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (field.field.system_name === 'table' && field.is_visible)
                    ? (_openBlock(), _createBlock(_component_cc_table, _mergeProps({ key: 0 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field.field.system_name)), { task: _ctx.task }), null, 16, ["task"]))
                    : _createCommentVNode("", true),
                  (field.field.system_name !== 'table' && field.is_visible)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getFieldComponent(field.field.system_name)), _mergeProps({ key: 1 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field.field.system_name))), null, 16))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.state.steps.length)
      ? (_openBlock(), _createBlock(_component_multi_step, {
          key: 1,
          steps: _ctx.state.steps.map((step) => step.name)
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.state.steps, (step) => {
            return {
              name: step.name,
              fn: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.fields, (field) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: `field-${field.id}`
                  }, [
                    (field.field)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          (field.field.system_name === 'table' && field.is_visible)
                            ? (_openBlock(), _createBlock(_component_cc_table, _mergeProps({ key: 0 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field.field.system_name)), { task: _ctx.task }), null, 16, ["task"]))
                            : _createCommentVNode("", true),
                          (field.field.system_name !== 'table' && field.is_visible)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getFieldComponent(field.field.system_name)), _mergeProps({ key: 1 }, _ctx.getProps(field), _toHandlers(_ctx.registerCustomEventListeners(field.field.system_name))), null, 16))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            }
          })
        ]), 1032, ["steps"]))
      : _createCommentVNode("", true)
  ]))
}