import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-20" }
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = {
  key: 0,
  class: "space-y-10"
}
const _hoisted_4 = {
  key: 1,
  class: "space-y-10"
}
const _hoisted_5 = { class: "space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.ownershipDetails.insuranceDate'),
        "is-required": false,
        modelValue: _ctx.payload.insurance_date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.insurance_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_radio_button, {
        buttons: _ctx.buttons,
        modelValue: _ctx.payload.ownership,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.ownership) = $event))
      }, null, 8, ["buttons", "modelValue"]),
      (_ctx.payload.ownership === 'Leased')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_date_input, {
              label: _ctx.t('vehicleDetails.ownershipDetails.leaseStart'),
              "is-required": false,
              modelValue: _ctx.payload.lease_start,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.lease_start) = $event))
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_date_input, {
              label: _ctx.t('vehicleDetails.ownershipDetails.leaseEnd'),
              "is-required": false,
              modelValue: _ctx.payload.lease_end,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.lease_end) = $event))
            }, null, 8, ["label", "modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.payload.ownership === 'Purchased')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_text_input, {
              label: _ctx.t('vehicleDetails.ownershipDetails.price'),
              "is-required": false,
              modelValue: _ctx.payload.purchase_price,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.purchase_price) = $event)),
              type: "number",
              step: "0.01",
              icons: [
            {
              position: 'before',
              icon: _ctx.t('components.form.inputs.textInput.icons.currency'),
            },
          ]
            }, null, 8, ["label", "modelValue", "icons"]),
            _createVNode(_component_date_input, {
              label: _ctx.t('vehicleDetails.ownershipDetails.purchaseDate'),
              "is-required": false,
              modelValue: _ctx.payload.purchase_date,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.purchase_date) = $event))
            }, null, 8, ["label", "modelValue"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.ownershipDetails.startDate'),
        error: _ctx.props.errors.start_date,
        modelValue: _ctx.payload.start_date,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.start_date) = $event)),
        "is-required": true
      }, null, 8, ["label", "error", "modelValue"]),
      _createVNode(_component_date_input, {
        label: _ctx.t('vehicleDetails.ownershipDetails.endDate'),
        "is-required": false,
        modelValue: _ctx.payload.end_date,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.end_date) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_box, {
        label: _ctx.t('vehicleDetails.ownershipDetails.description'),
        "is-required": false,
        modelValue: _ctx.payload.description,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.description) = $event))
      }, null, 8, ["label", "modelValue"]),
      _createVNode(_component_text_input, {
        label: _ctx.t('vehicleDetails.ownershipDetails.balloon'),
        "is-required": false,
        modelValue: _ctx.payload.balloon,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.balloon) = $event))
      }, null, 8, ["label", "modelValue"])
    ])
  ]))
}