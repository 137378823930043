
  import { FormPayload } from '@/types/components/FormBuilder'
  import { defineComponent, PropType, reactive, computed } from 'vue'
  import CcPill from '@/components/Generic/Pill/Pill.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { FormKeyword } from '@/types/modules/forms/FormKeyword'
  import { useStore } from 'vuex'
  import { FormGroup } from '@/types/modules/forms/FormGroup'
  import { FormType } from '@/types/modules/forms/FormType'
  import { Region } from '@/types/modules/regions/Region'
  import { FormStatus } from '@/types/modules/forms/FormStatus'

  export default defineComponent({
    components: {
      CcPill,
      CcButton,
    },
    props: {
      form: {
        type: Object as PropType<FormPayload>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const labelClasses = computed(() => {
        return {
          'block': true,
          'font-semibold': true,
          'text-1xs': true,
          'text-gray-400': true,
          'tracking-slight': true,
          'lowercase': true,
          'mb-1': true,
        }
      })

      const keywords = computed(() => {
        if (store.getters['keywords/getKeywords'].length) {
          return store.getters['keywords/getKeywords'].filter(
            (keyword: FormKeyword) =>
              props.form.keywords.indexOf(keyword.id) !== -1
          )
        }

        return []
      })

      const region = computed(() => {
        return store.getters['regions/regions'].find(
          (region: Region) => region.id === Number(props.form.region_id)
        )
      })

      const formStatus = computed(() => {
        return store.getters['formStatuses/getFormStatuses'].find(
          (status: FormStatus) =>
            status.id === Number(props.form.form_status_id)
        )
      })

      const formTypes = computed(() => {
        if (store.getters['formTypes/formTypes'].length) {
          return store.getters['formTypes/formTypes'].filter(
            (type: FormType) => props.form.form_type_ids.indexOf(type.id) !== -1
          )
        }

        return []
      })

      const formGroup = computed(() => {
        if (store.getters['formGroups/formGroups'].length) {
          return store.getters['formGroups/formGroups'].find(
            (group: FormGroup) => group.id === props.form.form_group_id
          )
        }

        return null
      })

      return {
        props,
        labelClasses,
        keywords,
        formGroup,
        formTypes,
        formStatus,
        region,
      }
    },
  })
