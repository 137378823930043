import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { class: "ml-3" }
const _hoisted_3 = { class: "text-sm font-semibold text-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "max-w-max flex items-center font-display",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none", _ctx.enabled ? 'bg-blue-200' : 'bg-gray-200']),
      role: "switch",
      "aria-checked": "false",
      "aria-labelledby": _ctx.props.label
    }, [
      _createElementVNode("span", {
        "aria-hidden": "true",
        class: _normalizeClass(["pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200", _ctx.enabled ? 'translate-x-4' : 'translate-x-0'])
      }, null, 2)
    ], 10, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.props.label), 1)
    ])
  ]))
}