
  import { defineComponent, onMounted } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      deleteManyAction: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const columns: Column[] = [
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          dataField: 'name',
          caption: 'Name',
          allowGrouping: false,
        },
        {
          dataField: 'is_system',
          caption: 'Is System?',
          allowGrouping: true,
          calculateCellValue: (row: { is_system: number }) =>
            row.is_system === 1 ? 'Yes' : 'No',
        },
        {
          dataField: 'is_hidden',
          caption: 'Is Hidden?',
          allowGrouping: true,
          calculateCellValue: (row: { is_hidden: number }) =>
            row.is_hidden === 1 ? 'Yes' : 'No',
        },
      ]

      const deleteRecords = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let rows = store.getters['genericStore/getLookupsToBeDeleted']

        let errors = [] as string[]

        let IDs = rows
          .filter((row: { id: number; is_system: number; name: string }) => {
            if (row.is_system) {
              errors.push(row.name)
            }

            return row.is_system !== 1
          })
          .map((row: { id: number }) => row.id)

        if (errors.length) {
          store.dispatch(
            'genericStore/pushNotification',
            `Record (s): ${errors.join(
              ', '
            )} are system records and will not be deleted`
          )
        }

        store
          .dispatch(props.deleteManyAction, { ids: IDs })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
            goBack()
          })
          .catch((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(() => {
        store.commit('genericStore/setTabs', { tabs: [] })
      })

      return {
        columns,
        deleteRecords,
        goBack,
      }
    },
  })
