
  import { defineComponent, onMounted, computed, reactive, PropType } from 'vue'
  import { useStore } from 'vuex'
  import Chips from '@/components/Generic/Button/Chips.vue'
  import { useRoute } from 'vue-router'
  import {
    PayloadKey,
    Vehicle,
    VehicleOwnershipPayload,
    VehiclePayload,
    VehicleSummaryPayload,
  } from '@/types/modules/projects/Vehicle'
  import VehicleSummaryDetails from './details/VehicleSummaryDetails.vue'
  import VehicleOwnershipDetails from './details/VehicleOwnershipDetails.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import { object, string, date, number } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import { exportPageToPdf, exportPageToExcel } from '@/composables/Pages'

  export default defineComponent({
    components: {
      Chips,
      VehicleSummaryDetails,
      VehicleOwnershipDetails,
      PageToolbar,
      ValidationErrors,
    },
    props: {
      vehicle: {
        type: Object as PropType<Vehicle>,
        required: false,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        chips: ['Summary', 'Ownership Details'],
        chipsWithErrors: [] as string[],
        errors: {} as Record<string, string>,
      })
      const activeChip = computed(
        () => store.getters['genericStore/activeChips'][route.fullPath]
      )
      const allChips = computed(() => {
        return state.chips
      })
      const chipClicked = (chip: string) => {
        store.dispatch('genericStore/setActiveChip', {
          [route.fullPath]: chip,
        })
      }
      const payload = reactive({
        // summary
        id: undefined,
        name: undefined,
        make: undefined,
        model: undefined,
        colour: undefined,
        vin: undefined,
        registration_number: undefined,
        registration_date: undefined,
        mot_date: undefined,
        tax_date: undefined,
        fuel: undefined,
        company_id: undefined,
        region_id: undefined,
        administrator_id: undefined,
        contact_details: undefined,

        // ownership
        insurance_date: undefined,
        ownership: undefined,
        lease_start: undefined,
        lease_end: undefined,
        purchase_price: undefined,
        purchase_date: undefined,
        start_date: undefined,
        end_date: undefined,
        description: undefined,
        balloon: undefined,
      }) as VehiclePayload

      const summaryParams = [
        'id',
        'name',
        'make',
        'model',
        'colour',
        'vin',
        'registration_number',
        'registration_date',
        'mot_date',
        'tax_date',
        'fuel',
        'company_id',
        'region_id',
        'administrator_id',
        'contact_details',
      ]

      const ownershipParams = [
        'insurance_date',
        'ownership',
        'lease_start',
        'lease_end',
        'purchase_price',
        'purchase_date',
        'start_date',
        'end_date',
        'description',
        'balloon',
      ]

      const updatePayload = (
        updatedPayload: VehicleSummaryPayload | VehicleOwnershipPayload
      ) => {
        Object.assign(payload, updatedPayload)
        if (Object.keys(state.errors).length) {
          validate()
        }
        emit('update-payload', payload)
      }

      const validate = async () => {
        state.chipsWithErrors = []
        const errors = await validatePayload(
          validationSchema,
          payload as Record<string, any>
        )
        if (errors) {
          state.errors = errors
          const errorParams = Object.keys(errors)
          if (
            errorParams.some((param: string) => summaryParams.includes(param))
          ) {
            state.chipsWithErrors.push('Summary')
          }
          if (
            errorParams.some((param: string) => ownershipParams.includes(param))
          ) {
            state.chipsWithErrors.push('Ownership Details')
          }
        } else {
          state.errors = {}
        }
        return !errors
      }

      const submitClicked = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (await validate()) {
          store
            .dispatch('vehicles/update', payload)
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            )
            .finally(() => {
              store.dispatch('genericStore/showPageLoader', false)
            })
          return
        }
        store.dispatch('genericStore/showPageLoader', false)
      }

      const validationSchema = object({
        name: string().required('Please enter a vehicle name'),
        start_date: date()
          .required('Please enter a start date')
          .typeError('Please enter a start date'),
        administrator_id: number().required('Please select an administrator'),
        company_id: number().required('Please select a Care Group'),
      })

      const exportClicked = (option: string) => {
        let payloadToExport = {} as Record<string, any>
        if (activeChip.value === 'Summary') {
          summaryParams.forEach(
            (param: string) =>
              (payloadToExport[param] = payload[param as PayloadKey])
          )
        }
        if (activeChip.value === 'Ownership Details') {
          ownershipParams.forEach(
            (param: string) =>
              (payloadToExport[param] = payload[param as PayloadKey])
          )
        }
        if (option === 'Export To PDF') {
          return exportPageToPdf(payloadToExport, `Vehicle ${activeChip.value}`)
        }
        if (option === 'Export To Excel') {
          return exportPageToExcel(
            payloadToExport,
            `Vehicle ${activeChip.value}`
          )
        }
      }

      const mapToPayload = (vehicle: Vehicle) => {
        // summary
        payload.id = vehicle.id
        payload.name = vehicle.project?.name
        payload.make = vehicle.make
        payload.model = vehicle.model
        payload.colour = vehicle.colour
        payload.vin = vehicle.vin
        payload.registration_number = vehicle.registration_number
        payload.registration_date = vehicle.registration_date
        payload.mot_date = vehicle.mot_date
        payload.tax_date = vehicle.tax_date
        payload.fuel = vehicle.fuel
        payload.company_id = vehicle.project?.company_id
        payload.region_id = vehicle.project?.region_id
        payload.administrator_id = vehicle.project?.administrator_id
        payload.contact_details = vehicle.contact_details

        // ownership
        payload.insurance_date = vehicle.insurance_date
        payload.ownership = vehicle.ownership
        payload.lease_start = vehicle.lease_start
        payload.lease_end = vehicle.lease_end
        payload.purchase_price = vehicle.purchase_price
        payload.purchase_date = vehicle.purchase_date
        payload.start_date = vehicle.project?.start_date
        payload.end_date = vehicle.project?.end_date
        payload.description = vehicle.project?.description
        payload.balloon = vehicle.balloon
      }

      onMounted(() => {
        if (!activeChip.value) {
          chipClicked(allChips.value[0])
        }

        if (props.vehicle) {
          mapToPayload(props.vehicle)
        }
      })

      return {
        activeChip,
        state,
        props,
        allChips,
        chipClicked,
        payload,
        updatePayload,
        submitClicked,
        validate,
        exportClicked,
      }
    },
  })
