
  import { useStore } from 'vuex'
  import { defineComponent, onMounted, reactive } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { isTab } from '@/composables/Generic'
  import HomeSettings from './Settings.vue'
  import HomeTable from './partials/HomesTable.vue'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      HomeSettings,
      HomeTable,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const state = reactive({
        tabs: [
          { name: 'Homes', url: `${route.path}?tab=homes` },
          { name: 'Settings', url: `${route.path}?tab=settings` },
        ],
      })

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: t('homes.breadcrumb'), url: '/project/homes' },
      ]

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs: crumbs })

        if (!route.query.tab) {
          router.replace({ query: { tab: 'homes' } })
        }

        store.commit('genericStore/setTabs', { tabs: state.tabs })
      })

      return {
        state,
        isTab,
      }
    },
  })
