<template>
  <div class="flex items-start space-x-20">
    <div class="w-1/2">
      <div class="space-y-10">
        <text-input
          :icons="[{ icon: 'fa fa-lock', position: 'before' }]"
          :isDisabled="true"
          :label="t('employeeDetails.summary.id')"
          v-model="summary.id"
        />
        <text-input
          :label="t('employeeDetails.summary.name')"
          v-model="summary.employee_name"
        />
        <select-input
          :label="t('employeeDetails.summary.region')"
          v-model="summary.region"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <select-input
          :label="t('employeeDetails.summary.careHome')"
          v-model="summary.home_or_school"
          :options="[
            { label: 'pick me', value: 1 },
            { label: 'pick me 2', value: 2 },
            { label: 'pick me 3', value: 3 },
          ]"
        />
        <date-input
          :label="t('employeeDetails.summary.ccStartDate')"
          v-model="summary.start_date"
        />
        <date-input
          :label="t('employeeDetails.summary.ccEndDate')"
          v-model="summary.end_date"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div class="space-y-10">
        <radio-button
          :message="t('employeeDetails.summary.careGroup.message')"
          :label="t('employeeDetails.summary.careGroup.label')"
          layout="horizontal"
          v-model="summary.care_group"
          :buttons="[
            { id: 0, text: 'Low' },
            { id: 1, text: 'Normal' },
            { id: 2, text: 'Urgent' },
            { id: 3, text: 'High' },
          ]"
        />
        <date-input
          :label="t('employeeDetails.summary.joinedOn')"
          v-model="summary.joined"
        />
        <date-input
          :label="t('employeeDetails.summary.leftOn')"
          v-model="summary.left"
        />
        <radio-button
          :label="t('employeeDetails.summary.residesAtCareGroup')"
          v-model="summary.resides_at"
          layout="horizontal"
          :buttons="[
            { id: 0, text: 'Yes' },
            { id: 1, text: 'No' },
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import RadioButton from '@/components/Generic/Forms/Inputs/RadioButton.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      TextInput,
      RadioButton,
      SelectInput,
      DateInput,
    },
    setup() {
      const { t } = useI18n()
      let summary = {
        id: '1234',
        employee_name: 'Amy Schumer',
        dob: '1980-08-09',
        age: '42',
        region: 'social construct',
        home_or_school: 'Jackie',
        start_date: '2000-08-09',
        end_date: '',
        care_group: '',
        joined: '2000-08-09',
        left: '',
        resides_at: false,
      }

      return {
        summary,
        t,
      }
    },
  })
</script>
