import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lookup_grid = _resolveComponent("lookup-grid")!

  return (_openBlock(), _createBlock(_component_lookup_grid, {
    "grid-heading": "Calendar Event Types",
    "create-view": "EventTypeCreate",
    "edit-view": "EventTypeEdit",
    "delete-view": "EventTypeDelete",
    action: "eventTypes/index",
    "restore-view": "EventTypeRestore",
    "toggle-visibility-action": "eventTypes/toggleManyVisibility",
    "delete-many-action": "eventTypes/deleteMany",
    "reorder-action": "eventTypes/saveOrderings"
  }))
}