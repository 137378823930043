
  import { defineComponent, PropType, reactive } from 'vue'
  import { getApiUrl } from '@/composables/Generic'

  export default defineComponent({
    props: {
      value: {
        type: [Object, String] as PropType<any>,
      },
      fieldAttributes: {
        type: Object as PropType<any>,
      },
    },
    setup(props) {
      props = reactive(props)

      const getFileUrl = (value: any, field: any) => {
        if (value) {
          value = value.toString()

          if (
            field.toLowerCase() === 'signature image' &&
            value.indexOf('empty_signature') !== false
          ) {
            return getApiUrl() + value
          }
        } else {
          value = ''
        }

        // For inline images and image editor values parse the JSON file data and
        // return the view_file endpoint with the file ID and form module param.
        if (
          field.toLowerCase() === 'inline image' ||
          field.toLowerCase() === 'image editor'
        ) {
          let files = JSON.parse(value)
          let url = ''

          if (files) {
            for (const file of files) {
              url =
                getApiUrl() + `/view_file?file_id=${file.fileID}&module=form`
            }
          }

          return url
        }

        let url =
          value.indexOf('http') === 0 || value.indexOf('/') === 0
            ? value
            : '/' + value
        url = `${url}&${new Date().getTime()}`
        return url
      }

      return {
        getFileUrl,
      }
    },
  })
