
  import { CalendarEvent } from '@/types/modules/calendar/CalendarEvent'
  import { User } from '@/types/modules/users/User'
  import { format } from 'date-fns/esm'
  import {
    defineComponent,
    PropType,
    onMounted,
    reactive,
    computed,
    watch,
  } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { useStore } from 'vuex'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import { Project } from '@/types/modules/projects/Project'
  import { CalendarEventType } from '@/types/modules/calendar/CalendarEventType'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import { goBack } from '@/composables/Generic'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { ProcedureRecur } from '@/types/modules/procedures/ProcedureRecur'
  import { canEditModule } from '@/composables/Permissions'
  import GroupedSelectInput from '@/components/Generic/Forms/Inputs/GroupedSelectInput.vue'

  export default defineComponent({
    components: {
      CcButton,
      ButtonGroup,
      TextInput,
      SelectInput,
      DateInput,
      TextBox,
      MultiSelect,
      GroupedSelectInput,
    },
    props: {
      event: {
        type: Object as PropType<CalendarEvent>,
        required: false,
      },
    },
    emits: ['formSubmit'],
    setup(props, { emit }) {
      const store = useStore()
      const payload = reactive({
        title: undefined as string | undefined,
        start_date: format(new Date(), 'yyyy-MM-dd'),
        start_time: format(new Date(), 'HH:mm'),
        recurs_id: 1 as number | undefined,
        end_date: undefined as string | undefined,
        event_type_id: 1 as number | undefined,
        assignees: [] as number[] | undefined,
        project_type_id: undefined as number | undefined,
        project_id: undefined as number | undefined,
        form_id: undefined as number | undefined,
        description: undefined as string | undefined,
        medication_stock_id: undefined as number | undefined,
        required_dose: undefined as number | undefined,
        extension_fields: undefined as Record<string, any> | undefined,
      })

      const isMedicationEventType = computed(() => {
        let selectedType = store.getters['eventTypes/getEventTypes'].find(
          (type: CalendarEventType) => type.id == payload.event_type_id
        )

        if (!selectedType) {
          return false
        }

        return (
          selectedType.name == 'Planned Medication' ||
          selectedType.name == 'Ad-Hoc Medication'
        )
      })

      watch(
        () => isMedicationEventType.value,
        (value) => {
          if (value === true) {
            if (props.event?.project_id) {
              getStockLocations(props.event?.project_id)
              return
            }

            getStockLocations()
            return
          }
        }
      )
      const submit = () => {
        payload.start_date = format(
          new Date(
            format(new Date(payload.start_date), 'yyyy-MM-dd') +
              ` ${payload.start_time}`
          ),
          'yyyy-MM-dd HH:mm:ss'
        )

        if (payload.medication_stock_id && payload.required_dose) {
          payload.extension_fields = {
            medication_stock_id: payload.medication_stock_id,
            required_dose: payload.required_dose,
          }
        }

        console.log(payload)

        emit('formSubmit', payload)
      }

      const mapToPayload = () => {
        payload.title = props.event?.title
        payload.start_date = format(
          new Date(props.event?.start_date!),
          'yyyy-MM-dd'
        )
        payload.start_time = format(new Date(props.event?.start_date!), 'HH:mm')
        payload.recurs_id = props.event?.recurs_id
        payload.end_date = props.event?.end_date || undefined
        payload.event_type_id = props.event?.event_type_id
        payload.assignees = props.event?.assignees?.map((user: User) => user.id)
        payload.project_type_id =
          props.event?.project?.project_type_id || undefined
        payload.project_id = props.event?.project_id
        payload.form_id = props.event?.form_id || undefined
        payload.description = props.event?.description || undefined

        if (props.event?.extension_fields) {
          payload.medication_stock_id =
            props.event?.extension_fields.medication_stock_id
          payload.required_dose = props.event?.extension_fields.required_dose
        }
      }

      const isValidProjectType = computed(() => {
        if (payload.project_type_id == 2 || payload.project_type_id == 4) {
          return true
        }

        return false
      })

      const getStockLocations = (project_id: any = null) => {
        store.dispatch('genericStore/showPageLoader', true)

        if (project_id) {
          store
            .dispatch('mars/medicationLocations', {
              parent_id: project_id,
            })
            .finally(() => {
              store.dispatch('genericStore/showPageLoader', false)
            })
        } else {
          store
            .dispatch('mars/medicationLocations', {})
            .finally(() => store.dispatch('genericStore/showPageLoader', false))
        }
      }

      const stockLocations = computed(
        () => store.getters['mars/medicationLocations']
      )

      const groupedMedications = computed(() => {
        let locations = {} as Record<string, any>
        for (let location of stockLocations.value) {
          let category = `${location.name} - ${location.project?.name}`
          if (!locations[category] && location.medications.length > 0) {
            locations[category] = []
          }

          if (location.medications.length > 0) {
            for (let medication of location.medications) {
              locations[category].push({
                label: medication.name,
                value: medication.id,
              })
            }
          }
        }

        return locations
      })

      const users = computed(() => {
        if (store.getters['users/users'].length) {
          return store.getters['users/users'].map((user: User) => {
            return {
              text: user.username,
              value: user.id,
            }
          })
        }

        return []
      })

      const eventTypes = computed(() => {
        if (store.getters['eventTypes/getEventTypes'].length) {
          return store.getters['eventTypes/getEventTypes'].map(
            (type: CalendarEventType) => {
              return {
                label: type.name,
                value: type.id,
              }
            }
          )
        }

        return []
      })

      const projects = computed(() => {
        if (store.getters['projects/projects'].length) {
          return store.getters['projects/projects']
            .filter((project: Project) => {
              return project.project_type_id == payload.project_type_id
            })
            .map((project: Project) => {
              return {
                label: project.name,
                value: project.id,
              }
            })
        }

        return []
      })

      const types = computed(() => {
        if (store.getters['projects/types'].length) {
          return store.getters['projects/types'].map((project: ProjectType) => {
            return {
              label: project.name,
              value: project.id,
            }
          })
        }

        return []
      })

      const recurs = computed(() => {
        if (store.getters['procedures/getProcedureRecurs'].length) {
          return store.getters['procedures/getProcedureRecurs'].map(
            (recur: ProcedureRecur) => {
              return {
                label: recur.name,
                value: recur.id,
              }
            }
          )
        }

        return []
      })

      const forms = computed(() => {
        if (store.getters['forms/forms'].length) {
          return store.getters['forms/forms'].map((form: any) => {
            return {
              label: form.name,
              value: form.id,
            }
          })
        }

        return []
      })

      watch(
        () => payload.project_type_id,
        async () => {
          store.dispatch('genericStore/showPageLoader', true)
          await store.dispatch('forms/index', {})
          store.dispatch('genericStore/showPageLoader', false)
        }
      )

      const getRecurs = store.dispatch('procedures/getProcedureRecurs')
      const getEventTypes = store.dispatch('eventTypes/index', {})
      const getUsers = store.dispatch('users/index', {
        module: 'calendar',
        status: 'active',
        filterable: true,
      })
      const getProjects = store.dispatch('projects/index', {
        filterable: true,
        relations: ['projectType'],
      })
      const getProjectTypes = store.dispatch('projects/getProjectTypes', {})

      const loadDependencies = () => {
        return Promise.all([
          getUsers,
          getProjectTypes,
          getProjects,
          getRecurs,
          getEventTypes,
        ])
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (props.event) {
          mapToPayload()
        }

        loadDependencies().finally(() =>
          store.dispatch('genericStore/showPageLoader', false)
        )
      })

      return {
        payload,
        users,
        projects,
        eventTypes,
        types,
        goBack,
        recurs,
        submit,
        forms,
        canEditModule,
        isMedicationEventType,
        isValidProjectType,
        groupedMedications,
      }
    },
  })
