import axios from 'axios'

export default {
  show: (project_id: string) => {
    return axios
      .get(`rotas/settings/${project_id}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getCurrentWeekCycle: (project_id: any, params: any) => {
    return axios
      .get(`rotas/settings/cycle/week/${project_id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (project_id: any, params: any) => {
    return axios
      .put(`rotas/settings/${project_id}`, params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
