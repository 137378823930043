
import { reactive, computed, PropType, defineComponent } from 'vue'
import { Crumb } from '@/types/components/Crumb'

export default defineComponent({
  props: {
    crumbs: {
      type: Array as PropType<Crumb[]>,
      required: true,
    },
  },

  setup(props) {
    props = reactive(props)

    const defaultClasses = ['font-body', 'tracking-slight', 'capitalize']

    const linkClasses = [
      'text-base',
      'underline',
      'leading-5',
      'text-blue-300',
      'font-semibold',
      'mr-3 ',
    ]

    const lastCrumbClasses = [
      'font-extrabold',
      'text-black',
      'text-5xl',
      'leading-12',
    ]

    const sortedCrumbs = computed((): Crumb[] => props.crumbs.slice(0, -1))
    const lastCrumb = computed((): string => props.crumbs.slice(-1)[0].name)

    return {
      defaultClasses,
      linkClasses,
      lastCrumb,
      lastCrumbClasses,
      sortedCrumbs,
    }
  },
})
