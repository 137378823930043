
  import { User } from '@/types/modules/users/User'
  import { reactive, defineComponent, PropType, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import DropDown from '../../Dropdown/DropDown.vue'
  import DropDownItem from '../../Dropdown/DropdownItem.vue'
  import { useStore } from 'vuex'
  import { UserProjectPermission } from '@/types/modules/users/UserProjectPermission'

  export default defineComponent({
    components: {
      DropDown,
      DropDownItem,
    },
    props: {
      data: {
        type: Object as PropType<User>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        value: props.data.user_project_permissions?.length
          ? props.data.user_project_permissions[0]?.value
          : '0',
      })
      const permissions = {
        '-1': {
          name: t('userPermissions.grid.cells.readWrite'),
          icon: 'far fa-pen-line fa-fw',
        },
        '1': {
          name: t('userPermissions.grid.cells.read'),
          icon: 'far fa-pencil-slash fa-fw',
        },
        '0': {
          name: t('userPermissions.grid.cells.noAccess'),
          icon: 'far fa-lock fa-fw',
        },
      } as Record<string, Record<string, string>>

      const selectedPermissions = computed(
        () => store.getters['userProjectPermissions/selectedPermissions']
      )

      const isModified = computed(
        () =>
          !!store.getters['userProjectPermissions/selectedPermissions'].find(
            (item: UserProjectPermission) => item.user_id === props.data.id
          )
      )

      const changePermission = (permission: string) => {
        state.value = permission
        if (
          (props.data.user_project_permissions?.length &&
            Number(permission) ===
              props.data.user_project_permissions[0]?.value) ||
          (!props.data.user_project_permissions?.length && permission === '0')
        ) {
          store.getters['userProjectPermissions/selectedPermissions'].splice(
            store.getters['userProjectPermissions/selectedPermissions'].indexOf(
              {
                user_id: props.data.id,
                value: Number(permission),
              }
            ),
            1
          )
          return
        }
        store.dispatch(
          'userProjectPermissions/setSelectedPermissions',
          selectedPermissions.value
            .filter(
              (item: UserProjectPermission) => item.user_id !== props.data.id
            )
            .concat({
              user_id: props.data.id,
              value: Number(permission),
            })
        )
      }

      return {
        props,
        permissions,
        changePermission,
        state,
        isModified,
      }
    },
  })
