
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
  } from 'vue'
  import { useStore } from 'vuex'
  import SettingSection from './SettingSection.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      SettingSection,
      CcButton,
    },
    setup() {
      const store = useStore()
      const settings = computed(() => store.getters['settings/getSettings'])

      let payload = ref({}) as any

      const getSettings = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('settings/index', {
            category: 'Calendar Settings',
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const errors = ref(null) as Ref<Record<string, string[]> | null>

      const saveSettings = () => {
        store.dispatch('genericStore/showPageLoader', true)

        store
          .dispatch('settings/update', payload.value)
          .then((response) => {
            errors.value = null
            store.dispatch('genericStore/pushNotification', response.message)
            getSettings()
          })
          .catch((response) => {
            errors.value = response.errors
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const pushSettingToPayload = (data: any) => {
        let settingName = data.name

        if (typeof data.value == 'object') {
          payload.value[settingName] = data.value
          return
        }

        if (!payload.value[settingName]) {
          payload.value[settingName] = {}
        }

        payload.value[settingName].value = data.value
        return
      }

      onMounted(() => {
        getSettings()
      })

      onUnmounted(() => store.commit('settings/setSettings', []))

      return {
        settings,
        payload,
        pushSettingToPayload,
        saveSettings,
        errors,
      }
    },
  })
