import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overview_container = _resolveComponent("overview-container")!
  const _component_configure_widget = _resolveComponent("configure-widget")!
  const _component_configure_widget_form = _resolveComponent("configure-widget-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.currentView === 'overview')
      ? (_openBlock(), _createBlock(_component_overview_container, {
          key: 0,
          onAddChart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchView('widgetConfiguration-1', {})))
        }))
      : _createCommentVNode("", true),
    (_ctx.state.currentView === 'widgetConfiguration-1')
      ? (_openBlock(), _createBlock(_component_configure_widget, {
          key: 1,
          onCancelClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchView('overview', {}))),
          onNextClicked: _ctx.switchView,
          onFinishClicked: _ctx.postWidget
        }, null, 8, ["onNextClicked", "onFinishClicked"]))
      : _createCommentVNode("", true),
    (_ctx.state.currentView === 'widgetConfiguration-2')
      ? (_openBlock(), _createBlock(_component_configure_widget_form, {
          key: 2,
          selectedWidget: _ctx.state.selectedWidget,
          onCancelClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchView('overview', {}))),
          onFinishClicked: _ctx.postWidget
        }, null, 8, ["selectedWidget", "onFinishClicked"]))
      : _createCommentVNode("", true)
  ], 64))
}