import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end mb-4 font-display" }
const _hoisted_2 = { class: "border-r px-3 mr-2 flex items-center" }
const _hoisted_3 = { class: "h-max border border-gray-100 p-6 shadow-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cc_button = _resolveComponent("cc-button")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_validation_errors = _resolveComponent("validation-errors")!
  const _component_text_box = _resolveComponent("text-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_button_group, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_cc_button, {
              icon: "fas fa-arrow-left",
              "secondary-icon": "none",
              type: "tertiary",
              label: "back",
              onClick: _ctx.goBack
            }, null, 8, ["onClick"])
          ]),
          _createVNode(_component_cc_button, {
            icon: "fas fa-save",
            type: "success",
            label: "Create",
            onClick: _ctx.createClicked
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.errors && Object.values(_ctx.errors).length)
        ? (_openBlock(), _createBlock(_component_validation_errors, {
            key: 0,
            errors: _ctx.errors
          }, null, 8, ["errors"]))
        : _createCommentVNode("", true),
      _createVNode(_component_text_box, {
        modelValue: _ctx.payload.body,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.body) = $event)),
        label: "comment",
        "is-required": true
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}