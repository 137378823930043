
  import { CareGroup } from '@/types/modules/care_groups/CareGroup'
  import { computed, defineComponent, PropType } from 'vue'
  export default defineComponent({
    props: {
      data: {
        type: Object as PropType<CareGroup>,
        required: true,
      },
    },
    setup(props) {
      const careGroup = computed((): CareGroup => props.data)

      return {
        careGroup,
      }
    },
  })
