
  import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
  import { ProjectFile } from '@/types/modules/files/ProjectFile'
  import { Column } from 'devextreme/ui/data_grid'
  import { useStore } from 'vuex'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { FileCategory } from '@/types/modules/files/FileCategory'
  import { useRoute, useRouter } from 'vue-router'
  import { capitalize } from 'lodash'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
      sensitive: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const query = ref({})
      const state = reactive({
        categories: [] as string[],
        activeChip: 'all' as string,
        extraOptions: [] as string[],
      })

      const action = computed(() => {
        return props.sensitive
          ? 'files/getSensitiveProjectFiles'
          : 'files/getProjectFiles'
      })

      const heading = computed(() => {
        return route?.query?.tab
          ?.toString()
          .split('-')
          .map((word) => capitalize(word).replace('Yp', 'Young People'))
          .join(' ')
      })

      const type = computed(() => {
        return capitalize(route?.query?.tab?.toString().split('-')[0]).replace(
          'Yp',
          'Resident'
        )
      })

      const categories = computed(() =>
        store.getters['fileCategories/categories']?.map(
          (category: FileCategory) => category.name
        )
      )

      const chips = computed((): string[] => {
        const chips: string[] = ['all']
        const names: string[] = [...new Set(categories.value)] as string[]

        names.forEach((category: string) => {
          chips.push(category)
        })

        return chips
      })

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
          width: 100,
        },
        {
          caption: 'Relates to',
          dataField: 'project.name',
          allowGrouping: false,
          allowSorting: false,
          visible: props.sensitive || route.name === 'AttachmentsIndex',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'name',
          caption: 'File Name',
          cellTemplate: 'AttachmentNameCellTemplate',
        },
        {
          allowGrouping: false,
          dataType: 'string',
          dataField: 'description',
          caption: 'Description',
        },
        {
          dataType: 'string',
          dataField: 'category.name',
          caption: 'Category',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          dataType: 'string',
          dataField: 'mime_type',
          caption: 'File Type',
          calculateCellValue: (row: ProjectFile) => {
            return row.readable_type
          },
        },
        {
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          dataField: 'created_at',
          caption: 'Date',
        },
      ])

      const filters = computed(() => {
        return {
          project_id: props.id,
          project_type: type.value,
          ...query.value,
        }
      })

      const applyFilters = () => {
        query.value = {
          ...(state.activeChip &&
            state.activeChip !== 'all' && { category: state.activeChip }),
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Move Attachments') {
          router.push({
            name: 'MoveProjectAttachments',
            params: {
              id: props.id,
            },
          })
        }
      }

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        applyFilters()
      }

      const rowsSelected = (rows: ProjectFile[]) => {
        if (props.sensitive || route.name === 'AttachmentsIndex') return

        if (rows.length && state.extraOptions.includes('Move Attachments')) {
          store.commit('projects/setSelectedAttachments', rows)
          return
        }

        if (rows.length && !state.extraOptions.includes('Move Attachments')) {
          state.extraOptions.push('Move Attachments')
          store.commit('projects/setSelectedAttachments', rows)
          return
        }

        store.commit('projects/setSelectedAttachments', [])
        return (state.extraOptions = [])
      }

      onMounted(() => {
        store.dispatch('fileCategories/index', {
          ...(props.id && { project_id: props.id }),
          only: ['has_active_project_files'],
        })
      })

      return {
        type,
        chips,
        state,
        action,
        columns,
        heading,
        filters,
        categories,
        optionClicked,
        chipClicked,
        rowsSelected,
      }
    },
  })
