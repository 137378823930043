
  import { Column } from 'devextreme/ui/data_grid'
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { goBack } from '@/composables/Generic'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { useI18n } from 'vue-i18n'
  import { TaskLogComment } from '@/types/modules/tasks/TaskLogComment'
  import { renderCellLink } from '@/composables/Grids'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      DataGrid,
      CcButton,
    },
    props: {
      taskLogId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const state = reactive({
        selectedRows: [] as TaskLogComment[],
      })

      const columns: Column[] = [
        {
          caption: 'ID',
          width: 100,
          dataField: 'id',
          allowGrouping: false,
          dataType: 'number',
        },
        {
          dataField: 'created_by.username',
          caption: 'Username',
          cellTemplate: function (container, options) {
            renderCellLink(
              container,
              options.data.created_by.username,
              'UserShow',
              {
                id: options.data?.created_by.id,
              }
            )
          },
          allowGrouping: false,
        },
        {
          dataField: 'comment',
          caption: 'Comment',
          allowGrouping: false,
        },

        {
          dataField: 'commented_at',
          dataType: 'datetime',
          caption: 'Date and Time',
          allowGrouping: false,
        },
      ]

      const filters = computed(() => {
        return {
          only: ['trashed'],
          task_log_id: Number(props.taskLogId),
        }
      })

      const rowsSelected = (rows: TaskLogComment[]) =>
        (state.selectedRows = rows)

      const restore = () => {
        store.dispatch('genericStore/showPageLoader', true)
        store
          .dispatch('taskLogComments/restoreMany', {
            ids: state.selectedRows.map((row: TaskLogComment) => row.id),
          })
          .then((response) =>
            store.dispatch('genericStore/pushNotification', response.message)
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => {
            store.dispatch('genericStore/showPageLoader', false)
            goBack()
          })
      }

      onMounted(() => {
        store.getters['genericStore/breadCrumbs'].push({
          name: 'Restore Comments',
          url: route.fullPath,
        })
      })

      return {
        t,
        state,
        goBack,
        columns,
        filters,
        restore,
        rowsSelected,
      }
    },
  })
