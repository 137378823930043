import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router'
import { Store } from 'vuex'
import store from '@/store'
import routes from './routes'
import pipeline from '@/middleware/pipeline'
import { MenuLink } from '@/types/components/MenuLink'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export interface MiddlewareContext {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
  store: Store<any>
  next: NavigationGuardNext
}

router.beforeEach(async (to, from, next) => {
  /** Set Custom Page Title */
  setPageTitle(to.meta.title)

  /** Set Page Icon */
  store.getters['genericStore/menuLinks'].find((link: MenuLink) => {
    if (link.module === to.meta.module) {
      store.commit('genericStore/setIcon', link.icon)
    }
  })

  /** Build Middleware Pipelines */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware: any = to.meta.middleware
  const context: MiddlewareContext = { to, from, store, next }

  return middleware[0]({
    ...context,
    next: pipeline(context, middleware, 1),
  })
})

const setPageTitle = (title: unknown): void => {
  const companyName = window.location.host.split('.')[0]
  document.title = `${title} - ${companyName} - Clearcare`
}

export default router
