
  import { defineComponent, PropType, reactive, watch, onMounted } from 'vue'
  import DateInput from '@/components/Generic/Forms/Inputs/DateInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import { Home } from '@/types/modules/projects/Home'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      DateInput,
      TextBox,
    },
    props: {
      home: {
        type: Object as PropType<Home>,
        required: true,
      },
    },
    emits: ['update-payload'],
    setup(props, { emit }) {
      const payload = reactive({
        pat_date: undefined,
        electrical_certificate: undefined,
        gas_certificate: undefined,
        day_fire_drill_date: undefined,
        night_fire_drill_date: undefined,
        description: undefined,
      })

      const mapToPayload = () => {
        Object.assign(payload, {
          pat_date: props.home.pat_date
            ? format(new Date(props.home.pat_date), 'yyyy-MM-dd')
            : undefined,
          electrical_certificate: props.home.electrical_certificate
            ? format(new Date(props.home.electrical_certificate), 'yyyy-MM-dd')
            : undefined,
          gas_certificate: props.home.gas_certificate
            ? format(new Date(props.home.gas_certificate), 'yyyy-MM-dd')
            : undefined,
          day_fire_drill_date: props.home.day_fire_drill_date
            ? format(new Date(props.home.day_fire_drill_date), 'yyyy-MM-dd')
            : undefined,
          night_fire_drill_date: props.home.night_fire_drill_date
            ? format(new Date(props.home.night_fire_drill_date), 'yyyy-MM-dd')
            : undefined,
          description: props.home.project?.description,
        })
      }

      watch(payload, () => emit('update-payload', payload))
      onMounted(() => {
        mapToPayload()
      })
      return {
        props,
        payload,
      }
    },
  })
