
  import { defineComponent, PropType, reactive, watch } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import { WizardStep } from '@/types/components/WizardStep'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
    },
    props: {
      steps: {
        type: Array as PropType<WizardStep[]>,
        required: true,
      },
      activeStep: {
        type: Number,
        required: true,
        default: () => 1,
      },
      showButtons: {
        type: Boolean,
        required: false,
        default: true,
      },
      showNext: {
        type: Boolean,
        required: false,
        default: true,
      },
      showSave: {
        type: Boolean,
        required: false,
        default: true,
      },
      saveLabel: {
        type: String,
        required: false,
        default: 'Save',
      },
      showBack: {
        type: Boolean,
        required: false,
        default: true,
      },
      disableNext: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['submit-clicked', 'step-changed', 'go-to-step'],
    setup(props, { emit }) {
      props = reactive(props)

      const correctColor = (index: number) => {
        if (props.activeStep > index) {
          return 'bg-green-400'
        }

        if (props.activeStep === index) {
          return 'bg-orange-400'
        }

        return 'bg-gray-200'
      }

      const correctIcon = (index: number, icon: string) => {
        if (props.activeStep > index) {
          return 'fa fa-check'
        }

        return icon
      }

      const submit = () => emit('submit-clicked')

      const goToStep = (step: number) => emit('go-to-step', step)

      watch(
        () => props.activeStep,
        () => emit('step-changed', props.activeStep)
      )

      return {
        submit,
        correctColor,
        correctIcon,
        props,
        goToStep,
      }
    },
  })
