export const allowedFileExtensions = [
  '.pdf',
  '.jpg',
  '.jpeg',
  '.png',
  '.doc',
  '.docx',
  '.txt',
  '.xls',
  '.xlsx',
  '.csv',
  '.rtf',
]
