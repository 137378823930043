export default {
  schoolDetails: {
    header: 'School Details',
    summary: {
      id: 'ID',
      name: 'School Name',
      administrator: 'School Administrator',
      personInCharge: 'Person In Charge',
      minAgeGroup: 'Min Age Group',
      maxAgeGroup: 'Max Age Group',
      pupilNum: 'No. Of Pupils',
      region: 'Region',
      startDate: 'ClearCare Start Date',
      endDate: 'ClearCare End Date',
      careGroup: 'Care Group',
      description: 'Description',
    },
    contactDetails: {
      address: 'Address',
      postCode: 'Post Code',
      phone: 'Phone Number',
      fax: 'Fax',
      email: 'Email',
    },
  },
}
