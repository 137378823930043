import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-10" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-20" }
const _hoisted_3 = { class: "space-y-8" }
const _hoisted_4 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_cc_button = _resolveComponent("cc-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_text_input, {
          isDisabled: true,
          label: _ctx.t('auth.username'),
          isRequired: false,
          icons: [{ position: 'before', icon: 'fa fa-lock' }],
          modelValue: _ctx.state.payload.username,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.payload.username) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_text_input, {
          isDisabled: true,
          label: _ctx.t('auth.email'),
          isRequired: false,
          icons: [{ position: 'before', icon: 'fa fa-lock' }],
          modelValue: _ctx.state.payload.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.payload.email) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_text_input, {
          label: _ctx.t('auth.firstName'),
          isRequired: true,
          modelValue: _ctx.state.payload.first_name,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.payload.first_name) = $event)),
          error: _ctx.state.response.errors.first_name
        }, null, 8, ["label", "modelValue", "error"]),
        _createVNode(_component_text_input, {
          label: _ctx.t('auth.lastName'),
          isRequired: true,
          modelValue: _ctx.state.payload.last_name,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.payload.last_name) = $event)),
          error: _ctx.state.response.errors.last_name
        }, null, 8, ["label", "modelValue", "error"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_text_input, {
          label: _ctx.t('auth.newPassword'),
          isRequired: false,
          type: "password",
          modelValue: _ctx.state.payload.new_password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.payload.new_password) = $event)),
          error: _ctx.state.response.errors.new_password
        }, null, 8, ["label", "modelValue", "error"]),
        _createVNode(_component_text_input, {
          label: _ctx.t('auth.newPasswordConfirmation'),
          isRequired: false,
          type: "password",
          modelValue: _ctx.state.payload.new_password_confirmation,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.payload.new_password_confirmation) = $event)),
          error: _ctx.state.response.errors.new_password_confirmation
        }, null, 8, ["label", "modelValue", "error"]),
        _createVNode(_component_text_input, {
          label: _ctx.t('auth.password'),
          isRequired: _ctx.isCurrentPasswordRequired,
          type: "password",
          modelValue: _ctx.state.payload.password,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.payload.password) = $event)),
          error: _ctx.state.response.errors.password
        }, null, 8, ["label", "isRequired", "modelValue", "error"])
      ])
    ]),
    _createVNode(_component_cc_button, {
      type: "success",
      label: "Submit",
      icon: "fa fa-check",
      class: "mt-8 float-right",
      onButtonClicked: _ctx.submit,
      "is-disabled": _ctx.state.disableSubmission
    }, null, 8, ["onButtonClicked", "is-disabled"])
  ]))
}