
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      section: {
        type: Object,
        required: true,
      },
    },
    setup() {
      const getFormSectionValue = (item: any) => {
        return item.regulatory_report_values.find(
          (value: any) =>
            value.regulatory_report_type_section_item_id === item.id
        ).value
      }

      return {
        getFormSectionValue,
      }
    },
  })
