
  import { Column } from 'devextreme/ui/data_grid'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { defineComponent, computed, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { QualificationType } from '@/types/modules/projects/QualificationType'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()

      const chips = computed(() => {
        let names = store.getters[
          'qualificationTypes/getQualificationTypes'
        ].map((type: QualificationType) => type.name)
        return ['all'].concat(names)
      })

      const setChip = (chip: string) => {
        state.activeChip = chip
      }

      const columns: Column[] = reactive([
        {
          caption: 'ID',
          dataField: 'id',
          width: 100,
          allowGrouping: false,
        },
        {
          caption: 'Qualification Name',
          dataField: 'name',
          allowGrouping: true,
        },
        {
          caption: 'Type',
          dataField: 'qualification_type.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Issuing Body',
          dataField: 'qualification_issuing_body.name',
          allowGrouping: false,
          allowSorting: false,
        },
        {
          caption: 'Training Course Attended',
          dataField: 'training_course_attended',
          allowGrouping: true,
        },
        {
          caption: 'Qualification Date',
          dataField: 'qualification_date',
          dataType: 'datetime',
          format: 'dd/MM/yyyy',
          allowGrouping: true,
        },
      ])

      const filters = computed(() => {
        return {
          employee_id: props.id,
          relations: ['qualification_type', 'qualification_issuing_body'],
          ...(state.activeChip &&
            state.activeChip !== 'all' && {
              qualification_type_id: store.getters[
                'qualificationTypes/getQualificationTypes'
              ].find(
                (type: QualificationType) => type.name === state.activeChip
              ).id,
            }),
        }
      })
      const state = reactive({
        activeChip: 'all',
        chips: chips,
      })
      onMounted(() => {
        store.dispatch('qualificationTypes/index', {})
      })
      return {
        filters,
        columns,
        state,
        setChip,
      }
    },
  })
