
  import { defineComponent, onMounted, reactive } from 'vue'
  import WizardStep from '@/components/Generic/Wizard/WizardStep.vue'
  import { useStore } from 'vuex'
  import ConfigureTask from './partials/ConfigureTask.vue'
  import RunReport from './partials/RunReport.vue'
  import { Form } from '@/types/modules/forms/Form'
  import { useI18n } from 'vue-i18n'
  import { FormType } from '@/types/modules/forms/FormType'

  export default defineComponent({
    components: {
      WizardStep,
      ConfigureTask,
      RunReport,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        steps: [
          {
            icon: 'fa fa-gear',
            label: t('taskExplorer.stepWizard.configTask'),
          },
          {
            icon: 'fa fa-file-check',
            label: t('taskExplorer.stepWizard.runReport'),
          },
        ],
        activeStep: 1,
        start_date: '',
        end_date: '',
        form_id: 0,
        field_id: undefined as number | undefined,
        project_types_label: '',
        project_type_is_parent: false,
        taskAuditTrail: false,
      })

      const crumbs = [
        { name: 'Task Explorer', url: '/task-explorer' },
        {
          name: `Hello, ${store.getters['auth/authenticatedUser'].first_name}`,
          url: '#',
        },
      ]

      const stepChanged = (step: number) => {
        state.activeStep = step
        if (step === 1) {
          state.form_id = 0
          state.field_id = undefined as number | undefined
        }
      }

      const goToStep = (step: number) => {
        if (
          step > 1 &&
          (!state.start_date || !state.end_date || !state.form_id)
        ) {
          return
        }
        stepChanged(step)
      }

      const startDateSelected = (date: string) => {
        state.start_date = date
      }

      const endDateSelected = (date: string) => {
        state.end_date = date
      }

      const formSelected = (id: number, form: Form) => {
        state.project_types_label = ''
        state.form_id = id
        state.project_types_label = form.types
          .map((type: FormType) => type.name)
          .join(' or ')
        state.project_type_is_parent = form.types
          .map((type: FormType) => type.project_type?.is_parent)
          .some((parent_status: number | undefined) => !!parent_status)
      }

      const fieldSelected = (id: number) => {
        state.field_id = id
      }

      const taskAuditTrailToggled = (value: boolean) => {
        state.taskAuditTrail = value
      }

      onMounted(() => {
        store.commit('genericStore/setBreadcrumbs', { crumbs })
      })

      return {
        state,
        stepChanged,
        startDateSelected,
        endDateSelected,
        formSelected,
        fieldSelected,
        t,
        goToStep,
        taskAuditTrailToggled,
      }
    },
  })
