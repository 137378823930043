import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vehicles_table = _resolveComponent("vehicles-table")!

  return (_openBlock(), _createBlock(_component_vehicles_table, {
    "read-only": true,
    filters: {
      filters: [['project.region_id', '=', _ctx.props.regionId]],
    }
  }, null, 8, ["filters"]))
}