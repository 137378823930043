import axios from 'axios'

export default {
  getShiftSummary: (params: any) => {
    return axios
      .get('rotas/summary', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getProjects: () => {
    return axios
      .get('rotas/projects')
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
}
