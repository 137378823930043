
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    Ref,
    ref,
    watch,
  } from 'vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import FileUploader from '@/components/Generic/Forms/Inputs/FileUploader.vue'
  import UploadedFile from './partials/UploadedFile.vue'
  import DropZoneAppearanceTemplate from './partials/DropZoneAppearanceTemplate.vue'
  import { useStore } from 'vuex'
  import { Employee } from '@/types/modules/projects/Employee'
  import { format } from 'date-fns'
  import { useI18n } from 'vue-i18n'
  import { OverseasCriminalRecord } from '@/types/modules/staffs/OverseasCriminalRecord'
  import Toggle from '@/components/Generic/Forms/Inputs/Toggle.vue'
  import { OverseasCriminalRecordStatus } from '@/types/modules/staffs/OverseasCriminalRecordStatus'
  import { SelectOption } from '@/types/components/SelectOption'
  import { useRouter } from 'vue-router'
  import { validatePayload } from '@/composables/Validation'
  import { number, object } from 'yup'
  import { RecruitmentCheckFile } from '@/types/modules/files/RecruitmentFile'
  import { StaffProcessFile } from '@/types/modules/staffs/StaffProcessFile'
  import { allowedFileExtensions } from '@/composables/staffs/RecruitmentChecks'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'

  export default defineComponent({
    components: {
      PageToolbar,
      SelectInput,
      TextInput,
      Toggle,
      FileUploader,
      UploadedFile,
      DropZoneAppearanceTemplate,
    },
    props: {
      employee: {
        type: Object as PropType<Employee>,
        required: true,
      },
    },
    emits: ['update:payload', 'submit-clicked'],
    setup(props, { emit }) {
      props = reactive(props)
      const { t } = useI18n()
      const router = useRouter()
      const extraOptions = ref(['View History']) as Ref<string[]>
      const errors = ref(null) as Ref<Record<string, string | undefined> | null>
      const files = ref([]) as Ref<RecruitmentCheckFile[] | null>
      const store = useStore()
      const payload = ref({
        is_required: 0,
        status_id: 0,
        created_by: '',
        updated_by: '',
        created_at: '',
        updated_at: '',
        changed_files: [] as RecruitmentCheckFile[] | null,
        new_files: [] as RecruitmentCheckFile[] | null,
      })

      const requiredChanged = (value: boolean) => {
        return (payload.value.is_required = value ? 1 : 0)
      }

      const statuses = computed((): OverseasCriminalRecordStatus[] => {
        return store.getters['staffs/overseasCriminalRecordStatuses']
      })

      const statusOptions = computed((): SelectOption[] => {
        return store.getters['staffs/overseasCriminalRecordStatuses']?.map(
          (status: OverseasCriminalRecordStatus) => {
            return {
              value: status.id,
              label: status.name,
            }
          }
        )
      })

      const overseasCriminalRecord = computed(
        (): OverseasCriminalRecord =>
          store.getters['employeeOverseasCriminalRecord/overseasCriminalRecord']
      )

      const getStatuses = () => {
        store.dispatch('staffs/getOverseasCriminalRecordStatuses')
      }

      const getOverseasCriminalRecord = () => {
        return store.dispatch('employeeOverseasCriminalRecord/show', {
          id: props.employee.id,
          relations: ['created_by', 'updated_by'],
        })
      }

      const filteredFiles = computed(() => {
        if (files.value) {
          return files.value.filter((file: RecruitmentCheckFile) => {
            return file.is_deleted === false
          })
        }
        return []
      })

      const mapToPayload = () => {
        if (overseasCriminalRecord.value) {
          payload.value.is_required = overseasCriminalRecord.value.is_required
          payload.value.status_id = overseasCriminalRecord.value.status_id || 0
          payload.value.created_by = `${overseasCriminalRecord.value.created_by?.first_name} ${overseasCriminalRecord.value.created_by?.last_name}`
          payload.value.updated_by = `${overseasCriminalRecord.value.updated_by?.first_name} ${overseasCriminalRecord.value.updated_by?.last_name}`
          payload.value.created_at = overseasCriminalRecord.value.created_at
            ? format(
                new Date(overseasCriminalRecord.value.created_at),
                'dd/MM/yyyy hh:mm a'
              )
            : ''
          payload.value.updated_at = overseasCriminalRecord.value.updated_at
            ? format(
                new Date(overseasCriminalRecord.value.updated_at),
                'dd/MM/yyyy hh:mm a'
              )
            : ''
          files.value = overseasCriminalRecord.value.files?.map(
            (file: StaffProcessFile) => {
              return {
                file,
                id: file.id,
                is_existing: true,
                is_deleted: false,
                is_sensitive: !!file.is_sensitive,
              }
            }
          ) as RecruitmentCheckFile[]
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'View History') {
          router.push({
            name: 'RecruitmentCheckHistory',
            params: {
              employeeId: props.employee.id,
              type: 'overseas-criminal-record',
            },
          })
        }
      }

      const filesUploaded = (newFiles: File[]) => {
        let uploadedFiles = newFiles.map((file: File) => {
          return {
            file,
            id: Math.random().toString(36).substring(2, 9),
            is_existing: false,
            is_deleted: false,
            is_sensitive: false,
          }
        }) as RecruitmentCheckFile[]

        files.value = files.value
          ? [...files.value, ...uploadedFiles]
          : uploadedFiles

        payload.value.new_files = payload.value.new_files
          ? [...payload.value.new_files, ...uploadedFiles]
          : uploadedFiles
      }

      const fileSensitivityToggled = (file: RecruitmentCheckFile) => {
        if (file.is_existing) {
          if (
            payload.value.changed_files &&
            !payload.value.changed_files.find(
              (changedFile: RecruitmentCheckFile) => changedFile.id === file.id
            )
          ) {
            payload.value.changed_files.push(file)
          }
        }

        if (!file.is_existing) {
          if (
            payload.value.new_files &&
            !payload.value.new_files.find(
              (newFile: RecruitmentCheckFile) => newFile.id === file.id
            )
          ) {
            payload.value.new_files.push(file)
          }
        }
      }

      const fileDeleteClicked = (file: RecruitmentCheckFile) => {
        files.value = files.value?.filter((f: RecruitmentCheckFile) => {
          return f.id !== file.id
        }) as RecruitmentCheckFile[]
        if (file.is_existing) {
          file.is_deleted = true
          payload.value.changed_files = payload.value.changed_files
            ? [...payload.value.changed_files, file]
            : [file]
        } else {
          payload.value.new_files = payload.value.new_files?.filter(
            (f: RecruitmentCheckFile) => f.id !== file.id
          ) as RecruitmentCheckFile[]
        }
      }

      const validate = (data: Record<string, unknown>) => {
        return validatePayload(
          object().shape({
            is_required: number().oneOf([0, 1]),
            status_id: number().when('is_required', {
              is: 1,
              then: number().required('Status is required'),
            }),
          }),
          data
        )
      }

      const exportClicked = (option: string) => {
        const name = 'Recruitment Checks - Proof Of Identity'
        const data = {
          status: statuses.value.find(
            (status: OverseasCriminalRecordStatus) =>
              status.id === payload.value.status_id
          )?.name,
          created_by: payload.value.created_by,
          updated_by: payload.value.updated_by,
          created_at: payload.value.created_at,
          updated_at: payload.value.updated_at,
        }

        if (option === 'Export To Excel') {
          return exportPageToExcel(data, name)
        }
        if (option === 'Export To PDF') {
          return exportPageToPdf(data, name)
        }
      }

      const submitClicked = async () => {
        errors.value = await validate(payload.value)
        if (errors.value) return
        emit('submit-clicked', 'overseas-criminal-record')
        payload.value.changed_files = []
        payload.value.new_files = []
      }

      watch(
        () => overseasCriminalRecord.value,
        () => mapToPayload()
      )

      watch(
        () => payload.value,
        (value) => emit('update:payload', value),
        { deep: true }
      )

      const loadDependencies = () => {
        return Promise.all([getStatuses(), getOverseasCriminalRecord()])
      }

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        loadDependencies()
          .then(() => mapToPayload())
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        t,
        errors,
        payload,
        extraOptions,
        filteredFiles,
        filesUploaded,
        optionClicked,
        submitClicked,
        exportClicked,
        statusOptions,
        requiredChanged,
        fileDeleteClicked,
        allowedFileExtensions,
        fileSensitivityToggled,
      }
    },
  })
