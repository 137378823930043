
  import { defineComponent, computed, reactive, PropType, onMounted } from 'vue'
  import DataGrid from '@/components/Generic/Grid/DataGrid.vue'
  import { Column } from 'devextreme/ui/data_grid'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { ProjectType } from '@/types/modules/projects/ProjectType'
  import { useRouter } from 'vue-router'
  import { Project } from '@/types/modules/projects/Project'
  import { ProjectAssociation } from '@/types/modules/projects/ProjectAssociation'

  export default defineComponent({
    components: {
      DataGrid,
    },
    props: {
      instanceId: {
        type: Number,
        required: true,
      },
      project: {
        type: Object as PropType<Project>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()

      const state = reactive({
        chips: ['All'],
        activeChip: 'All',
        projectTypeId: 0 as number,
        selectedRows: [] as ProjectAssociation[],
        extraOptions: [] as string[],
      })

      const columns = computed((): Column[] => [
        {
          width: 100,
          dataField: 'association.id',
          caption: t('projectAssociations.grid.headers.id'),
          dataType: 'number',
          allowGrouping: false,
        },
        {
          dataField: 'association.name',
          caption: t('projectAssociations.grid.headers.name'),
          cellTemplate: props.project.project_type?.is_parent
            ? undefined
            : 'AssociationNameCellTemplate',
          allowGrouping: false,
        },
        {
          dataField: 'association.status',
          caption: t('projectAssociations.grid.headers.status'),
        },
        {
          dataField: 'association.project_type_label',
          caption: 'Type',
          visible: state.activeChip === 'All',
        },
        {
          dataField: 'admission_date',
          caption: t('projectAssociations.grid.headers.admissionDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
        {
          dataField: 'leaving_date',
          caption: t('projectAssociations.grid.headers.leavingDate'),
          dataType: 'date',
          format: 'dd/MM/yyyy',
        },
      ])

      const chipClicked = (chip: string) => {
        state.activeChip = chip
        if (chip !== 'All') {
          state.projectTypeId = projectTypes.value.find(
            (type: ProjectType) => type.name === chip
          ).id
        }
      }

      const filters = computed(() => {
        return {
          project_id: props.project.id,
          relations: [
            props.project.project_type?.is_parent ? 'project' : 'parent',
          ],
          ...(state.projectTypeId && { project_type_id: state.projectTypeId }),
        }
      })

      const allChips = computed(() =>
        state.chips.concat(
          projectTypes.value.map((type: ProjectType) => type.name)
        )
      )

      const projectTypes = computed(() => store.getters['projects/types'])

      const addClicked = () => {
        router.push({
          name: 'AssociationCreate',
          params: {
            id: props.instanceId,
            projectType: `${props.project.project_type_label.toLowerCase()}s`,
          },
        })
      }

      const rowsSelected = (rows: ProjectAssociation[]) => {
        state.selectedRows = rows
        if (!props.project.project_type?.is_parent) {
          if (rows.length && !state.extraOptions.includes('Delete')) {
            state.extraOptions.push('Delete')
          }
          if (!rows.length && state.extraOptions.includes('Delete')) {
            state.extraOptions.splice(state.extraOptions.indexOf('Delete'), 1)
          }
        }
      }

      const optionClicked = (option: string) => {
        if (option === 'Delete' && state.selectedRows.length) {
          if (state.selectedRows.some((row) => row.is_default)) {
            store.dispatch(
              'genericStore/pushNotification',
              'Please change the default association before deleting it.'
            )
            return
          }

          store.dispatch(
            'projectAssociations/setSelectedAssociations',
            state.selectedRows
          )
          router.push({
            name: 'AssociationDelete',
            params: {
              id: props.instanceId,
              projectType: `${props.project.project_type_label.toLowerCase()}s`,
            },
          })
        }

        if (option === 'View Deleted Associations') {
          router.push({
            name: 'AssociationRestore',
            params: {
              id: props.instanceId,
              projectType: `${props.project.project_type_label.toLowerCase()}s`,
            },
          })
        }
      }

      onMounted(() => {
        if (!props.project.project_type?.is_parent) {
          state.extraOptions.push('View Deleted Associations')
        }
        store.dispatch('projects/getProjectTypes', {
          only: [
            props.project.project_type?.is_parent
              ? 'child_types'
              : 'parent_types',
          ],
        })
      })

      return {
        props,
        columns,
        state,
        filters,
        projectTypes,
        allChips,
        chipClicked,
        addClicked,
        rowsSelected,
        optionClicked,
      }
    },
  })
