
  import { defineComponent, PropType, reactive, Ref, ref, watch } from 'vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import TextBox from '@/components/Generic/Forms/Inputs/TextBox.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import MultiSelect from '@/components/Generic/Forms/Inputs/MultiSelect.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import {
    Datasource,
    DatasourceParameter,
  } from '@/types/modules/reporting/Datasource'
  import { MultiSelect as MultiSelectType } from '@/types/components/MultiSelect'

  export default defineComponent({
    components: {
      TextInput,
      TextBox,
      SelectInput,
      MultiSelect,
      CcButton,
      ButtonGroup,
    },
    props: {
      datasource: {
        type: Object as PropType<Datasource>,
        required: true,
      },
    },
    emits: ['remove-clicked', 'datasource-updated'],
    setup(props, { emit }) {
      props = reactive(props)
      const showConfigure = ref(false)
      const payload = ref({
        id: 0,
        name: '',
        grouping: '',
        description: '',
      }) as Ref<Datasource>
      const operators = reactive([
        { label: 'Equals', value: 'equal' },
        { label: 'Not Equals', value: 'not_equals' },
        { label: 'Contains', value: 'contains' },
        { label: 'Does Not Contain', value: 'not_contains' },
        { label: 'Is One Of', value: 'in' },
        { label: 'Is Not One Of', value: 'not_in' },
        { label: 'Greater Than', value: 'greater_than' },
        { label: 'Less Than', value: 'less_than' },
      ])

      const getParameterOptions = (
        parameter: DatasourceParameter
      ): MultiSelectType[] => {
        return parameter.options
          .replace(/,\s*$/, '')
          .split(',')
          .map((option) => {
            return { text: option, value: option }
          })
      }

      const removeClicked = () => emit('remove-clicked', payload.value)
      const configureClicked = () =>
        (showConfigure.value = !showConfigure.value)

      watch(payload.value, () => emit('datasource-updated', payload.value))
      watch(
        () => props.datasource,
        () => {
          // @ts-ignore
          payload.value = props.datasource?.pivot?.params
            ? props.datasource.pivot
            : props.datasource
          payload.value.parameters = props.datasource.pivot?.params
            ? props.datasource.pivot.params
            : props.datasource.parameters
        },
        { deep: true, immediate: true }
      )

      return {
        payload,
        operators,
        showConfigure,
        removeClicked,
        configureClicked,
        getParameterOptions,
      }
    },
  })
