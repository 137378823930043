import { MiddlewareContext } from '@/router'

export default async function auth({ to, store, next }: MiddlewareContext) {
  if (!store.getters['auth/authenticatedUser']) {
    store.dispatch('genericStore/showPageLoader', true)

    let response = await store.dispatch('auth/attempt')

    store.dispatch('genericStore/showPageLoader', false)

    if (!response.success) {
      return next({ name: 'Login' })
    }
  }

  if (store.getters['auth/acceptedTerms'] && to.name !== 'TermsConditions') {
    return next({ name: 'TermsConditions' })
  }

  if (
    store.getters['auth/passwordExpired'] &&
    to.name !== 'ForcePasswordChange'
  ) {
    return next({ name: 'ForcePasswordChange' })
  }

  if (
    store.getters['documents/getDocumentsToAcknowledge'].length &&
    to.name !== 'DocumentsToAcknowledge' &&
    !localStorage.getItem('document_acknowledgments_dismissed')
  ) {
    return next({ name: 'DocumentsToAcknowledge' })
  }

  return next()
}
