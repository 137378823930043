
  import { defineComponent, reactive } from 'vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import QualificationIssuingBodiesTable from './partials/qualifications/QualificationIssuingBodiesTable.vue'
  import QualificationTypesTable from './partials/qualifications/QualificationTypesTable.vue'
  import JobTitlesTable from './partials/JobTitlesTable.vue'
  import ContractTypesTable from '@/views/modules/staffs/partials/ContractTypesTable.vue'
  import EvidenceTypesTable from '@/views/modules/staffs/partials/EvidenceTypesTable.vue'
  import ShiftTypesTable from '@/views/modules/rotas/employees/partials/ShiftTypesTable.vue'
  import CustomFieldGroupsTable from '../partials/CustomFieldGroupsTable.vue'
  import CustomFieldsTable from '../partials/CustomFieldsTable.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      JobTitlesTable,
      QualificationIssuingBodiesTable,
      QualificationTypesTable,
      ContractTypesTable,
      EvidenceTypesTable,
      ShiftTypesTable,
      CustomFieldsTable,
      CustomFieldGroupsTable,
    },
    setup() {
      const state = reactive({
        activeTab: 'Job Titles',
        tabs: [
          'Job Titles',
          'Qualification Types',
          'Qualification Issuing Bodies',
          'Evidence Types',
          'Contract Types',
          'Shift Types',
          'Custom Field Groups',
          'Custom Fields',
        ],
      })

      return {
        state,
      }
    },
  })
