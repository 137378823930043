
  import { defineComponent, reactive } from 'vue'
  import CcButton from './Button.vue'

  export default defineComponent({
    components: {
      CcButton,
    },
    props: {
      showButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      expanded: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      props = reactive(props)
      const state = reactive({
        expanded: props.expanded,
      })

      return {
        state,
        props,
      }
    },
  })
