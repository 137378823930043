import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_region_details = _resolveComponent("region-details")!
  const _component_region_homes = _resolveComponent("region-homes")!
  const _component_region_schools = _resolveComponent("region-schools")!
  const _component_region_young_people = _resolveComponent("region-young-people")!
  const _component_region_employees = _resolveComponent("region-employees")!
  const _component_region_vehicles = _resolveComponent("region-vehicles")!
  const _component_region_forms = _resolveComponent("region-forms")!
  const _component_region_procedures = _resolveComponent("region-procedures")!
  const _component_region_users = _resolveComponent("region-users")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTab('details') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_details, {
          key: 0,
          "region-id": _ctx.props.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('homes') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_homes, {
          key: 1,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('schools') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_schools, {
          key: 2,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('young-people') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_young_people, {
          key: 3,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('employees') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_employees, {
          key: 4,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('vehicles') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_vehicles, {
          key: 5,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('forms') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_forms, {
          key: 6,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('procedures') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_procedures, {
          key: 7,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true),
    (_ctx.isTab('users') && _ctx.region)
      ? (_openBlock(), _createBlock(_component_region_users, {
          key: 8,
          "region-id": _ctx.id
        }, null, 8, ["region-id"]))
      : _createCommentVNode("", true)
  ], 64))
}