
  import { defineComponent, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import TextInput from '@/components/Generic/Forms/Inputs/TextInput.vue'
  import { goBack } from '@/composables/Generic'
  import FormsTable from './FormsTable.vue'
  import DropDown from '@/components/Generic/Dropdown/DropDown.vue'
  import DropdownItem from '@/components/Generic/Dropdown/DropdownItem.vue'
  export default defineComponent({
    components: {
      CcButton,
      TextInput,
      FormsTable,
      DropDown,
      DropdownItem,
    },
    setup() {
      const store = useStore()

      const state = reactive({
        dropdownItems: ['delete'],
      })

      const payload = reactive({
        current_email: undefined as string | undefined,
        new_email: undefined as string | undefined,
        action: 'replace with',
      })
      const setButtonText = (text: string) => {
        state.dropdownItems.push(payload.action)
        payload.action = text
        state.dropdownItems = state.dropdownItems.filter(
          (item: string) => item !== text
        )
      }
      const submitPayload = () => {
        store.dispatch('forms/updateEmail', payload).then(() => goBack())
      }
      onMounted(() => {
        if (store.getters['forms/selectedForms'].length == 0) {
          return goBack()
        }
      })
      return {
        goBack,
        payload,
        state,
        setButtonText,
        submitPayload,
      }
    },
  })
