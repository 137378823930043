
  import { Project } from '@/types/modules/projects/Project'
  import { format } from 'date-fns/esm'
  import { defineComponent, PropType, reactive, computed, watch } from 'vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import addWeeks from 'date-fns/esm/addWeeks/index.js'
  import Alert from 'sweetalert2'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import CcButton from '@/components/Generic/Button/Button.vue'

  export default defineComponent({
    components: {
      ButtonGroup,
      CcButton,
      SelectInput,
    },
    props: {
      weeks: {
        required: true,
        type: Array,
        default: () => [],
      },
      project: {
        required: true,
        type: Object as PropType<Project>,
        default: () => {},
      },
      shifts: {
        required: true,
        type: Array,
        default: () => [],
      },
    },
    emits: ['close', 'copy'],
    setup(props, { emit }) {
      props = reactive(props)

      const state = reactive({
        weekFrom: 1 as any,
        weekTo: 2 as any,
      })

      watch(
        () => state.weekFrom,
        (value) => {
          if (value === state.weekTo) {
            state.weekTo = props.weeks.filter((week) => week !== value).shift()
          }
        }
      )

      watch(
        () => state.weekTo,
        (value) => {
          if (value === state.weekFrom) {
            state.weekFrom = props.weeks
              .filter((week) => week !== value)
              .shift()
          }
        }
      )

      const formatShiftTime = (value: any) => {
        return format(value, 'eeee h:mma')
      }

      const formattedWeeks = computed(() => {
        return props.weeks.map((week: any) => {
          return {
            label: `Week ${week}`,
            value: week,
          }
        })
      })

      const shiftsToCopy = computed((): any[] => {
        return props.shifts
          .filter((shift: any) => {
            return shift.shift_cycle_number == state.weekFrom
          })
          .map((shift: any) => {
            return {
              allDay: false,
              condition: 'added',
              employee: shift.employee,
              employee_project_id: shift.employee_project_id,
              shift_type_id: shift.shift_type_id,
              project: props.project,
              valid_job_titles: shift.valid_job_titles,
              shift_start: addWeeks(
                new Date(shift.shift_start),
                weekDifference.value
              ),
              shift_end: addWeeks(
                new Date(shift.shift_end),
                weekDifference.value
              ),
              id: '_' + Math.random().toString(36).substr(2, 9),
              shift_cycle_number: state.weekTo,
              image: shift.image,
              color: '#C1C1C1',
              is_dirty: true,
              text: shift.text,
            }
          })
      })

      const weekDifference = computed(() => {
        return state.weekTo - state.weekFrom
      })

      const submit = () => {
        if (!shiftsToCopy.value.length) {
          return Alert.fire({
            icon: 'info',
            title: 'No Shifts Available',
            text: `Week Cycle ${state.weekFrom} does not currently have any shifts available to copy. Please select an alternative week.`,
          })
        }

        let weekToShifts = props.shifts.filter(
          (s: any) => s.shift_cycle_number === state.weekTo
        )

        if (weekToShifts.length) {
          return Alert.fire({
            icon: 'warning',
            title: 'Existing Shifts',
            text: `Week Cycle ${state.weekTo} already has ${weekToShifts.length} shifts assigned to it. Are you sure you wish to continue copying ${shiftsToCopy.value.length} shifts over from Week Cycle ${state.weekFrom}?`,
            showConfirmButton: true,
            showCancelButton: true,
          }).then((result) => {
            if (result) {
              emit('copy', {
                shifts: shiftsToCopy.value,
              })
            }
          })
        }

        return Alert.fire({
          icon: 'warning',
          title: 'Please Confirm',
          text: `Are you sure you wish to copy ${shiftsToCopy.value.length} shifts over from Week Cycle ${state.weekFrom} to Week Cycle ${state.weekTo}?`,
          showConfirmButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result) {
            emit('copy', {
              shifts: shiftsToCopy.value,
            })
          }
        })
      }

      const clear = () => {
        state.weekFrom = 1
        state.weekTo = 2
      }

      const close = () => {
        clear()
        emit('close')
      }

      return {
        shiftsToCopy,
        formatShiftTime,
        formattedWeeks,
        props,
        state,
        submit,
        close,
      }
    },
  })
