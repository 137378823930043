
  import Chips from '@/components/Generic/Button/Chips.vue'
  import PageToolbar from '@/components/Generic/Toolbar/PageToolbar.vue'
  import { School } from '@/types/modules/projects/School'
  import { defineComponent, PropType, reactive, computed, onMounted } from 'vue'
  import SchoolContactDetails from './details/SchoolContactDetails.vue'
  import SchoolSummary from './details/SchoolSummary.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { exportPageToExcel, exportPageToPdf } from '@/composables/Pages'
  import { date, number, object, string } from 'yup'
  import { validatePayload } from '@/composables/Validation'
  import ValidationErrors from '@/components/Generic/Validation/ValidationErrors.vue'
  import format from 'date-fns/format'

  export default defineComponent({
    components: {
      SchoolSummary,
      SchoolContactDetails,
      Chips,
      PageToolbar,
      ValidationErrors,
    },
    props: {
      school: {
        type: Object as PropType<School>,
        required: true,
      },
    },
    setup(props) {
      props = reactive(props)
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        chips: ['Summary', 'Contact Details'],
        chipsWithErrors: [] as string[],
        errors: {},
      })

      const payload = reactive({
        // summary
        id: undefined,
        name: undefined,
        administrator_id: undefined,
        person_in_charge_id: undefined,
        minimum_age_group: undefined,
        maximum_age_group: undefined,
        number_of_pupils: undefined,
        company_id: undefined,
        region_id: undefined,
        start_date: undefined,
        end_date: undefined,
        description: undefined,

        // contact details
        address: undefined,
        postcode: undefined,
        phone: undefined,
        fax: undefined,
        email: undefined,
      })

      const summaryParams = [
        'id',
        'name',
        'administrator_id',
        'person_in_charge_id',
        'minimum_age_group',
        'maximum_age_group',
        'number_of_pupils',
        'company_id',
        'region_id',
        'start_date',
        'end_date',
        'description',
      ]

      const contactDetailsParams = [
        'address',
        'postcode',
        'phone',
        'fax',
        'email',
      ]

      const validationSchema = object({
        name: string().required('Please enter a school name'),
        administrator_id: number().required(
          'Please select a school administrator'
        ),
        person_in_charge_id: number().required(
          'Please select a person in charge'
        ),
        company_id: number().required('Please select a care group'),
        start_date: date()
          .typeError('Please select a ClearCare start date')
          .required('Please select a ClearCare start date'),
        end_date: date().typeError('ClearCare end date must be a date'),
        minimum_age_group: number().typeError(
          'Minimum age group must be a number'
        ),
        maximum_age_group: number().typeError(
          'Maximum age group must be a number'
        ),
        number_of_pupils: number().typeError(
          'Number of pupils must be a number'
        ),
        email: string().email('Email must be a valid email address'),
      })

      const validate = async () => {
        state.errors = {}
        state.chipsWithErrors = []
        const errors = await validatePayload(validationSchema, payload)
        if (errors) {
          state.errors = errors
          if (summaryParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Summary')
          }
          if (contactDetailsParams.some((param: string) => param in errors)) {
            state.chipsWithErrors.push('Contact Details')
          }
        }
        return !errors
      }

      const submitClicked = async () => {
        store.dispatch('genericStore/showPageLoader', true)
        if (await validate()) {
          await store
            .dispatch('schools/update', payload)
            .then((response) => {
              store.dispatch('genericStore/pushNotification', response.message)
            })
            .catch((error) =>
              store.dispatch('genericStore/pushNotification', error.message)
            )
        }
        store.dispatch('genericStore/showPageLoader', false)
      }

      const exportClicked = (option: string) => {
        const name = `School details - ${props.school.project?.name}`
        if (option === 'Export To Excel') {
          return exportPageToExcel(payload, name)
        }
        if (option === 'Export To PDF') {
          return exportPageToPdf(payload, name)
        }
      }

      const chipClicked = (chip: string) => {
        store.dispatch('genericStore/setActiveChip', { [route.fullPath]: chip })
      }

      const activeChip = computed(
        () => store.getters['genericStore/activeChips'][route.fullPath]
      )

      const updatePayload = (updatedPayload: Record<string, any>) => {
        Object.assign(payload, updatedPayload)
        if (Object.keys(state.errors).length) {
          validate()
        }
      }

      const mapToPayload = () => {
        updatePayload({
          // summary
          id: props.school.id,
          name: props.school.project?.name,
          administrator_id: props.school.project?.administrator_id,
          person_in_charge_id: props.school.person_in_charge_id,
          minimum_age_group: props.school.minimum_age_group,
          maximum_age_group: props.school.maximum_age_group,
          number_of_pupils: props.school.number_of_pupils,
          company_id: props.school.project?.company_id,
          region_id: props.school.project?.region_id,
          start_date: props.school.project?.start_date
            ? format(new Date(props.school.project?.start_date), 'yyyy-MM-dd')
            : undefined,
          end_date: props.school.project?.end_date
            ? format(new Date(props.school.project?.end_date), 'yyyy-MM-dd')
            : undefined,
          description: props.school.project?.description,

          // contact details
          address: props.school.address,
          postcode: props.school.postcode,
          phone: props.school.phone,
          fax: props.school.fax,
          email: props.school.email,
        })
      }

      onMounted(() => {
        if (!activeChip.value) {
          chipClicked(state.chips[0])
        }
        mapToPayload()
      })

      return {
        props,
        submitClicked,
        exportClicked,
        state,
        chipClicked,
        activeChip,
        updatePayload,
      }
    },
  })
