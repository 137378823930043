import { GetterTree, MutationTree, ActionTree } from 'vuex'
import GroupingService from '@/services/groupings/groupings'
import { Grouping } from '@/types/modules/groupings/Grouping'

export type GroupingGetter = GetterTree<GroupingState, any>

export interface GroupingState {
  groupings: Grouping[]
}

export const state: GroupingState = {
  groupings: [],
}

export const getters: GroupingGetter = {
  groupings: (state) => state.groupings,
}

export const actions: ActionTree<GroupingState, any> = {
  index: async (context, params) => {
    const response = await GroupingService.index(params)
    if (response.success && params.per_page) {
      context.commit('setGroupings', {
        data: response.data,
        totalCount: response.meta.total,
      })

      return {
        data: response.data,
        totalCount: response.meta.total,
      }
    }

    if (response.success && !params.per_page) {
      context.commit('setGroupings', response.data)

      return response
    }

    return response
  },
}

export const mutations: MutationTree<GroupingState> = {
  setGroupings: (state, groupings: Grouping[]) => {
    state.groupings = groupings
  },
}

export const groupings = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
