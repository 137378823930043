
  import RotaTimeline from './partials/RotaTimeline.vue'
  import { DxView, DxResource } from 'devextreme-vue/scheduler'
  import { DxDateBox } from 'devextreme-vue/date-box'
  import { DxButton } from 'devextreme-vue/button'
  import RotaDateRange from './partials/RotaDateRange.vue'
  import { defineComponent, reactive, watch, computed, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { loadDatesBetweenDates } from '@/composables/rotas/Rotas'
  import Alert from 'sweetalert2'
  import startOfISOWeek from 'date-fns/startOfISOWeek'
  import endOfISOWeek from 'date-fns/endOfISOWeek'
  import format from 'date-fns/format'
  import isAfter from 'date-fns/isAfter'
  import isBefore from 'date-fns/isBefore'
  import { addHours } from 'date-fns/esm'
  import getDay from 'date-fns/getDay'
  import set from 'date-fns/set/index'
  import CcButton from '@/components/Generic/Button/Button.vue'
  import ButtonGroup from '@/components/Generic/Button/ButtonGroup.vue'
  import SelectInput from '@/components/Generic/Forms/Inputs/SelectInput.vue'
  import { getShiftTypes, shiftTypes } from '@/composables/rotas/ShiftTypes'
  import { getApiUrl } from '@/composables/Generic'
  import { canEditModule } from '@/composables/Permissions'

  export default defineComponent({
    components: {
      SelectInput,
      RotaDateRange,
      RotaTimeline,
      DxView,
      DxResource,
      DxDateBox,
      DxButton,
      CcButton,
      ButtonGroup,
    },
    setup() {
      const store = useStore()
      const state = reactive({
        allow_edit_past_rotas: {},
        group_types: [
          { label: 'None', value: 'none' },
          { label: 'Shift Type', value: 'shift_type' },
        ],
        selected_grouping: 'none',
        levels: {
          home_overview: 1,
          employee_overview: 2,
          shift_overview: 3,
        },
        timeline: {
          level: 1 as number | null,
          view: 'timelineWeek',
          view_name: 'Care Group Overview',
          template_type: 'overview' as string | undefined,
          picker_format: 'E ddd',
          height: '850',
          hide_times: true,
          datasource: [] as any[],
          planned_datasource: [] as any[],
          project_id: null as any,
          employee: null as any,
          employee_project_id: null as any,
          shift_type_id: null as any,
          shift_type_name: null as any,
          shift_type_icon: null as any,
          hidden: false,
          current_date: new Date() as any,
          start_date: startOfISOWeek(new Date()) as any,
          end_date: endOfISOWeek(new Date()) as any,
          duration: 1440,
          editable: {
            allowAdding: false,
            allowDeleting: false,
            allowUpdating: false,
            allowResizing: false,
            allowDragging: false,
          },
        },
        groupings: {
          group_by: ['project_id'],
          projects: [] as any[],
        },
        shifts: [] as any[],
        skeletonShift: {} as Record<string, any>,
        filteredEmployees: [] as any[],
      })

      const shortDate = (value: any) => {
        return format(new Date(value), 'E d')
      }

      watch(
        () => state.timeline.current_date,
        (value: any) => {
          if (new Date(value)) {
            loadJobTitles()
          }
        }
      )

      watch(
        () => state.timeline.project_id,
        (value: any) => {
          if (value) {
            loadEmployees()
          }
        }
      )

      watch(
        () => state.selected_grouping,
        () => {
          loadSummary(
            state.timeline.level,
            format(new Date(state.timeline.start_date), 'yyyy-MM-dd'),
            format(new Date(state.timeline.end_date), 'yyyy-MM-dd')
          )
        }
      )

      const liveShifts = computed(
        () => store.getters['shiftInstances/getShiftInstances']
      )
      const jobTitles = computed(
        () => store.getters['employeeJobTitles/getJobTitles']
      )
      const employees = computed(() => store.getters['projects/projects'])

      const projectName = computed(() => {
        return state.groupings.projects
          .filter((p) => p.id === state.timeline.project_id)
          .map((p) => p.name)[0]
      })

      const exportReport = () => {
        if (
          state.timeline.level &&
          state.timeline.level <= state.levels.employee_overview
        ) {
          //@ts-ignore
          let params = new URLSearchParams({
            include: state.groupings.projects.map((p) => p.id).join(','),
            start_date: format(
              new Date(state.timeline.start_date),
              'yyyy-MM-dd'
            ),
            end_date: format(new Date(state.timeline.end_date), 'yyyy-MM-dd'),
            level: state.timeline.level,
          }).toString()

          window.open(`${getApiUrl()}/rotas/summary/export?${params}`, '_blank')
          return
        }

        // @ts-ignore
        let params = new URLSearchParams({
          include: state.timeline.project_id,
          start_date: format(state.timeline.current_date, 'yyyy-MM-dd'),
          end_date: format(state.timeline.current_date, 'yyyy-MM-dd'),
          level: state.timeline.level,
        }).toString()

        window.open(`${getApiUrl()}/rotas/summary/export?${params}`, '_blank')
        return
      }

      const isFutureDate = computed(() => {
        return isAfter(state.timeline.current_date, new Date())
      })

      const getRotasPastEditSetting = (id: any) =>
        store
          .dispatch('settings/show', id)
          .then((response) => (state.allow_edit_past_rotas = response.data))
      const getProjects = () => store.dispatch('rotas/getProjects')
      const getJobTitles = (params: any) =>
        store.dispatch('employeeJobTitles/index', params)
      const getEmployeeProjects = (params: any) =>
        store.dispatch('projects/index', params)
      const getHomeSummary = (params: any) =>
        store.dispatch('rotas/getSummary', params)
      const getEmployeeSummary = (params: any) =>
        store.dispatch('rotas/getSummary', params)
      const getShiftInstances = (params: any) =>
        store.dispatch('shiftInstances/index', params)
      const getPlannedShifts = (params: any) =>
        store.dispatch('defaultShifts/getPlannedShifts', params)
      const saveLiveShifts = (payload: any) =>
        store.dispatch('shiftInstances/update', payload)

      const drillup = (level: any) => {
        state.shifts = []
        state.timeline.level = level
        state.timeline.view = 'timelineWeek'
        state.timeline.template_type = 'overview'
        state.timeline.height = (
          350 * state.groupings.projects.length
        ).toString()
        state.timeline.hide_times = true
        state.timeline.project_id = null
        state.timeline.employee = null
        state.timeline.employee_project_id = null
        state.timeline.shift_type_id = null
        state.timeline.shift_type_name = null
        state.timeline.shift_type_icon = null
        state.timeline.datasource = []
        state.timeline.planned_datasource = []
        state.timeline.duration = 1440
        state.timeline.current_date = new Date(state.timeline.start_date)
        state.timeline.start_date = startOfISOWeek(
          new Date(state.timeline.current_date)
        )
        state.timeline.end_date = endOfISOWeek(
          new Date(state.timeline.current_date)
        )
        state.timeline.editable = {
          allowAdding: false,
          allowDeleting: false,
          allowUpdating: false,
          allowResizing: false,
          allowDragging: false,
        }
        loadSummary(
          state.timeline.level,
          format(state.timeline.start_date, 'yyyy-MM-dd'),
          format(state.timeline.end_date, 'yyyy-MM-dd')
        )
      }

      const drilldown = (e: any) => {
        state.shifts = []
        let shift = e.appointmentData

        if (state.timeline.level === state.levels.home_overview) {
          e.cancel = true
          state.timeline.height = (
            350 * state.groupings.projects.length
          ).toString()
          loadSummary(
            state.levels.employee_overview,
            format(state.timeline.start_date, 'yyyy-MM-dd'),
            format(state.timeline.end_date, 'yyyy-MM-dd')
          )
        }

        if (state.timeline.level === state.levels.employee_overview) {
          e.cancel = true
          state.timeline.template_type = undefined
          state.timeline.project_id = shift.project_id
          state.timeline.employee = shift.employee
          state.timeline.employee_project_id = shift.employee_project_id
          state.timeline.shift_type_id = shift.shift_type_id
          state.timeline.shift_type_name = shift.shift_type_name
          state.timeline.shift_type_icon = shift.shift_type_icon
          state.timeline.datasource = []
          state.timeline.planned_datasource = []
          state.timeline.current_date = new Date(shift.shift_start)
          state.timeline.start_date = new Date(shift.shift_start)
          state.timeline.end_date = new Date(shift.shift_end)
          state.timeline.level = state.levels.shift_overview
          state.timeline.duration = 120
          state.timeline.view = 'timelineDay'
          state.timeline.height = '350'
          state.timeline.hide_times = false
          state.timeline.editable = {
            allowAdding: canEditModule('rotas'),
            allowDeleting: canEditModule('rotas'),
            allowUpdating: canEditModule('rotas'),
            allowResizing: canEditModule('rotas'),
            allowDragging: false,
          }

          store.dispatch('genericStore/showPageLoader', true)

          loadShifts(
            state.timeline.project_id,
            format(state.timeline.current_date, 'yyyy-MM-dd'),
            format(state.timeline.current_date, 'yyyy-MM-dd')
          )
          loadLiveShiftsForOtherProjects(
            state.timeline.project_id,
            format(state.timeline.current_date, 'yyyy-MM-dd'),
            format(state.timeline.current_date, 'yyyy-MM-dd')
          )
          loadPlannedShifts(
            state.timeline.project_id,
            format(state.timeline.current_date, 'yyyy-MM-dd')
          )
          getShiftTypes().finally(() =>
            store.dispatch('genericStore/showPageLoader', false)
          )
        }
      }

      const disabledDates = (date_to_check: any) => {
        let current_date = format(new Date(date_to_check), 'yyyy-MM-dd')
        let start_date = format(state.timeline.start_date, 'yyyy-MM-dd')
        let end_date = format(state.timeline.end_date, 'yyyy-MM-dd')

        return (
          isBefore(new Date(current_date), new Date(start_date)) ||
          isAfter(new Date(current_date), new Date(end_date))
        )
      }

      const setDefaultEndDate = (start_date: any) => {
        return addHours(new Date(start_date), 8)
      }

      const loadProjects = () => {
        store.dispatch('genericStore/showPageLoader', true)
        getProjects()
          .then((response) => {
            response.data.forEach((project: any) => {
              state.groupings.projects.push({
                id: project.id,
                name: project.name,
                color:
                  project.colour.indexOf('#') !== -1
                    ? project.colour
                    : `#${project.colour}`,
                image: project.image.thumbnail,
              })
            })
          })
          .then(() =>
            loadSummary(
              1,
              format(state.timeline.start_date, 'yyyy-MM-dd'),
              format(state.timeline.end_date, 'yyyy-MM-dd')
            )
          )
          .then(() => loadJobTitles())
          .then(
            () =>
              (state.timeline.height = (
                350 * state.groupings.projects.length
              ).toString())
          )
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const loadSummary = (level: any, start_date: any, end_date: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        let range = { start_date: start_date, end_date: end_date }
        let group = state.selected_grouping ? state.selected_grouping : 'none'
        let project_ids = state.groupings.projects.map((p) => p.id)
        let request =
          level === state.levels.home_overview
            ? getHomeSummary({
                include: project_ids,
                ...range,
                group: group,
                level: level,
              })
            : getEmployeeSummary({
                include: project_ids,
                ...range,
                group: group,
                level: level,
              })

        request
          .then((response) => {
            state.timeline.level = level
            state.timeline.datasource = response.data
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const loadShifts = (project_id: any, start: any, end: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        let params = {
          start_date: start,
          end_date: end,
          include: [project_id],
        }

        getShiftInstances(params)
          .then((response) => {
            response.data.forEach((shift: any) => {
              state.timeline.datasource.push({
                allDay: false,
                id: shift.id ? shift.id : null,
                shift_instance_id: shift.id ? shift.id : null,
                text: setShiftText(shift.employee, shift),
                default_shift_id: shift.default_shift_id
                  ? shift.default_shift_id
                  : null,
                employee: shift.employee ? shift.employee : null,
                employee_project_id: shift.employee
                  ? shift.employee.project_id
                  : null,
                shift_type_id: shift.shift_type ? shift.shift_type.id : null,
                shift_type_name: shift.shift_type
                  ? shift.shift_type.name
                  : null,
                shift_type_icon: shift.shift_type
                  ? shift.shift_type.icon
                  : null,
                valid_job_titles: shift.valid_job_titles
                  ? shift.valid_job_titles
                  : [],
                shift_start: shift.shift_start,
                shift_end: shift.shift_end,
                image:
                  shift.employee && shift.employee.image
                    ? shift.employee.image
                    : null,
                snapshot: shift.snapshot ? shift.snapshot : null,
                color: getDefaultShiftColour(shift),
                project: shift.project ? shift.project : null,
                hidden: false,
              })
            })
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const loadLiveShiftsForOtherProjects = (
        project_id: any,
        start_date: any,
        end_date: any
      ) => {
        getShiftInstances({
          start_date: start_date,
          end_date: end_date,
          exclude: [project_id],
        }).catch((error) =>
          store.dispatch('genericStore/pushNotification', error.message)
        )
      }

      const loadPlannedShifts = (project_id: any, date: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        let params = { date: date, project_id: project_id }

        getPlannedShifts(params)
          .then((response) => {
            response.data.forEach((shift: any) => {
              state.timeline.planned_datasource.push({
                allDay: false,
                id: shift.id ? shift.id : null,
                shift_instance_id: shift.id ? shift.id : null,
                text: setShiftText(shift.employee, shift),
                employee: shift.employee ? shift.employee : null,
                employee_project_id: shift.employee
                  ? shift.employee.project_id
                  : null,
                shift_type_id: shift.shift_type ? shift.shift_type.id : null,
                shift_type_name: shift.shift_type
                  ? shift.shift_type.name
                  : null,
                shift_type_icon: shift.shift_type
                  ? shift.shift_type.icon
                  : null,
                valid_job_titles: shift.valid_job_titles
                  ? shift.valid_job_titles
                  : [],
                image:
                  shift.employee && shift.employee.image
                    ? shift.employee.image
                    : null,
                shift_start: new Date(shift.shift_start),
                shift_end: new Date(shift.shift_end),
                color: getDefaultShiftColour(shift),
                project: shift.project ? shift.project : null,
                hidden: false,
              })
            })
          })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const changeDate = (event: any) => {
        state.shifts = []
        state.timeline.datasource = []
        state.timeline.planned_datasource = []

        state.timeline.current_date = new Date(event.selected_date)

        if (
          state.timeline.level &&
          state.timeline.level <= state.levels.employee_overview
        ) {
          state.timeline.start_date = startOfISOWeek(event.selected_date)
          state.timeline.end_date = endOfISOWeek(event.selected_date)
        } else {
          state.timeline.start_date = new Date(event.selected_date)
          state.timeline.end_date = new Date(event.selected_date)
        }

        loadDatesBetweenDates(
          state.timeline.start_date,
          state.timeline.end_date
        )

        if (
          state.timeline.level &&
          state.timeline.level <= state.levels.employee_overview
        ) {
          return loadSummary(
            state.timeline.level,
            format(state.timeline.start_date, 'yyyy-MM-dd'),
            format(state.timeline.end_date, 'yyyy-MM-dd')
          )
        }

        loadShifts(
          state.timeline.project_id,
          format(state.timeline.start_date, 'yyyy-MM-dd'),
          format(state.timeline.end_date, 'yyyy-MM-dd')
        )
        loadLiveShiftsForOtherProjects(
          state.timeline.project_id,
          format(state.timeline.start_date, 'yyyy-MM-dd'),
          format(state.timeline.end_date, 'yyyy-MM-dd')
        )
        loadPlannedShifts(
          state.timeline.project_id,
          format(state.timeline.start_date, 'yyyy-MM-dd')
        )
      }

      const getDefaultShiftColour = (shift: any) => {
        if (shift && shift.employee && shift.employee.colour) {
          return `#${shift.employee.colour.replace('#', '')}`
        }

        return '#C1C1C1'
      }

      const buildForm = (e: any) => {
        let form = e.form
        let shift = e.appointmentData
        setShiftEmployees(
          shift.valid_job_titles,
          shift.shift_start,
          shift.shift_end
        )
        form.updateData(
          'shift_end',
          setDefaultEndDate(form.getEditor('shift_start').option('value'))
        )

        form.option('items', [
          {
            dataField: 'id',
            editorType: 'dxTextBox',
            editorOptions: {
              value: '_' + Math.random().toString(36).substr(2, 9),
            },
            visible: false,
          },
          {
            label: {
              text: 'Valid Job Titles',
            },
            dataField: 'valid_job_titles',
            editorType: 'dxTagBox',
            editorOptions: {
              items: jobTitles.value,
              displayExpr: 'job_title',
              onValueChanged(args: any) {
                const editor = form.getEditor('employee')

                if (!args.value.length) {
                  state.filteredEmployees = []
                  editor.option('value', null)
                  editor.option('dataSource', [])
                }

                if (args.value.length) {
                  setShiftEmployees(
                    args.value,
                    shift.shift_start,
                    shift.shift_end
                  )
                  editor.option('dataSource', state.filteredEmployees)
                }
              },
            },
            validationRules: [
              {
                type: 'required',
                message:
                  'Please select at least one job title associated with this shift.',
              },
            ],
          },
          {
            label: {
              text: 'Employee',
            },
            name: 'employee',
            dataField: 'employee_project_id',
            editorType: 'dxSelectBox',
            editorOptions: {
              items: state.filteredEmployees,
              displayExpr: 'name',
              valueExpr: 'id',
              showClearButton: true,
            },
          },
          {
            label: {
              text: 'Shift Start',
            },
            dataField: 'shift_start',
            editorType: 'dxDateBox',
            editorOptions: {
              type: 'datetime',
              acceptCustomValue: false,
              disabledDates: (args: any) => {
                return disabledDates(args.date)
              },
            },
            validationRules: [
              {
                type: 'required',
                message: 'You must provide a valid start date for the shift.',
              },
              {
                type: 'custom',
                reevaluate: true,
                message: 'The start date cannot be after the end date',
                validationCallback: (e: any) => {
                  let updated = new Date(e.value)
                  let end_shift = new Date(
                    form.getEditor('shift_end').option('value')
                  )

                  return end_shift.getTime() >= updated.getTime()
                },
              },
            ],
          },
          {
            label: {
              text: 'Shift End',
            },
            dataField: 'shift_end',
            acceptCustomValue: false,
            editorType: 'dxDateBox',
            editorOptions: {
              type: 'datetime',
              disabledDates: (args: any) => {
                return disabledDates(args.date)
              },
            },
            validationRules: [
              {
                type: 'required',
                message: 'You must provide a valid end date for the shift.',
              },
              {
                type: 'custom',
                reevaluate: true,
                message: 'The end time cannot be before the start time',
                validationCallback: (e: any) => {
                  let updated = new Date(e.value)
                  let start_shift = new Date(
                    form.getEditor('shift_start').option('value')
                  )

                  return start_shift.getTime() <= updated.getTime()
                },
              },
              {
                type: 'custom',
                reevaluate: true,
                message:
                  'The end date should be within the current active week',
                validationCallback: (e: any) => {
                  let updated = new Date(e.value)
                  return !disabledDates(updated)
                },
              },
            ],
          },
          {
            label: {
              text: 'Shift Type',
            },
            name: 'shiftType',
            dataField: 'shift_type_id',
            editorType: 'dxSelectBox',
            editorOptions: {
              items: shiftTypes.value,
              displayExpr: 'name',
              valueExpr: 'id',
              onValueChanged(args: any) {
                if (!args.value) return

                let selectedType = shiftTypes.value.find(
                  (item: any) => item.id === args.value
                )
                form.updateData('shift_type_name', selectedType.name)
                form.updateData('shift_type_icon', selectedType.tag_icon.icon)
              },
            },
            validationRules: [
              {
                type: 'required',
                message:
                  'Please select a shift type associated with this shift.',
              },
            ],
          },
        ])
      }

      const setupAddModal = () => {
        state.skeletonShift = {
          id: '_' + Math.random().toString(36).substr(2, 9),
          shift_instance_id: null,
          allDay: false,
          shift_start: set(new Date(state.timeline.current_date), {
            hours: 9,
            minutes: 0,
          }),
          shift_end: set(new Date(state.timeline.current_date), {
            hours: 17,
            minutes: 0,
          }),
          text: null,
          valid_job_titles: [],
          employee: null,
          employee_project_id: null,
          shift_type_id: null,
          shift_type_name: null,
          shift_type_icon: null,
          image: null,
          snapshot: null,
          color: getDefaultShiftColour(null),
          is_default: false,
        }
      }

      const adding = (e: any) => {
        let shift = e.appointmentData
        shift.condition = 'added'
        shift.employee = employees.value.find(
          (e: any) => e.id === shift.employee_project_id
        )
        shift.project = state.groupings.projects.filter(
          (p: any) => p.id === state.timeline.project_id
        )[0]
        shift.text = setShiftText(shift.employee, shift)
        shift.shift_start = format(
          new Date(shift.shift_start),
          'yyyy-MM-dd HH:mm:ss'
        )
        shift.shift_end = format(
          new Date(shift.shift_end),
          'yyyy-MM-dd HH:mm:ss'
        )
        shift.is_dirty = true
        shift.color = '#C1C1C1'
        shift.image = '/images/employee_default.png'
        shift.shift_type_id = shift.shift_type_id
        shift.shift_type_name = shift.shift_type_name
        shift.shift_type_icon = shift.shift_type_icon
        shift.hidden = false

        return state.shifts.push(shift)
      }

      const updating = (e: any) => {
        let shift = e.newData
        shift.condition =
          !Number.isInteger(shift.id) || shift.is_default ? 'added' : 'updated'
        shift.employee = employees.value.find(
          (e: any) => e.id === shift.employee_project_id
        )
        shift.project = state.groupings.projects.filter(
          (p) => p.id === state.timeline.project_id
        )[0]
        shift.text = setShiftText(shift.employee, shift)
        shift.is_dirty = true
        shift.shift_type_id = shift.shift_type_id
        shift.shift_type_name = shift.shift_type_name
        shift.shift_type_icon = shift.shift_type_icon
        shift.hidden = false

        let index = state.shifts.findIndex((s) => s.id === shift.id)
        if (index !== -1) {
          state.shifts.splice(index, 1)
          state.shifts.push(shift)
        } else {
          state.shifts.push(shift)
        }
      }

      const deleting = (e: any) => {
        let shift = e.appointmentData
        if (shift.is_default) {
          e.cancel = true
          return Alert.fire({
            icon: 'warning',
            title: 'Hold on!',
            text:
              'This is an unassigned default shift and cannot be deleted as no live shift has been generated. ' +
              'To delete default shifts, please go to the rotas tab for this home.',
            showConfirmButton: true,
          })
        }

        shift.condition = 'deleted'

        let index = state.shifts.findIndex((s) => s.id === shift.id)
        if (index !== -1) {
          state.shifts.splice(index, 1)
          if (Number.isInteger(shift.id) && !shift.is_default) {
            state.shifts.push(shift)
          }
        } else {
          if (Number.isInteger(shift.id) && !shift.is_default) {
            state.shifts.push(shift)
          }
        }

        return
      }

      const setShiftText = (employee: any, shift: any) => {
        if (employee) {
          return employee.name
        }

        if (!employee && shift.valid_job_titles.length) {
          return shift.valid_job_titles
            .map((title: any) => title.job_title)
            .join(', ')
        }

        return 'Unassigned'
      }

      const setShiftEmployees = (
        job_titles = [],
        start_date_filter: any,
        end_date_filter: any
      ) => {
        if (!job_titles.length) {
          return (state.filteredEmployees = [])
        }

        return (state.filteredEmployees = employees.value.filter((e: any) => {
          let start_date = getDay(new Date(e.start_date))
          let start_date_filter_day = getDay(new Date(start_date_filter))
          let end_date = getDay(new Date(e.start_date))
          let end_date_filter_day = getDay(new Date(end_date_filter))
          let isSameOrBefore = start_date <= start_date_filter_day
          let isSameOrAfter = e.end_date && end_date <= end_date_filter_day

          return (
            job_titles
              .map((t: any) => t.id)
              .includes(e.employee.job_title_id) &&
            isSameOrBefore &&
            ((e.end_date && isSameOrAfter) || !e.end_date)
          )
        }))
      }

      const saveChanges = () => {
        Alert.fire({
          title: 'Save Live Shifts',
          text: 'Are you sure you want to save the current changes to live shifts?',
          showCancelButton: true,
          showConfirmButton: true,
          icon: 'warning',
        }).then((result) => {
          if (result) {
            store.dispatch('genericStore/showPageLoader', true)
            saveLiveShifts({
              project_id: state.timeline.project_id,
              shifts: state.shifts,
            })
              .then((response) => {
                state.shifts = []
                state.timeline.datasource = []
                state.timeline.planned_datasource = []
                loadShifts(
                  state.timeline.project_id,
                  format(state.timeline.current_date, 'yyyy-MM-dd'),
                  format(state.timeline.current_date, 'yyyy-MM-dd')
                )
                loadPlannedShifts(
                  state.timeline.project_id,
                  format(state.timeline.current_date, 'yyyy-MM-dd')
                )

                store.dispatch(
                  'genericStore/pushNotification',
                  response.message
                )
              })
              .catch((error) => {
                store.dispatch('genericStore/pushNotification', error.message)
              })
              .finally(() => {
                store.dispatch('genericStore/showPageLoader', false)
                loadLiveShiftsForOtherProjects(
                  null,
                  format(state.timeline.start_date, 'yyyy-MM-dd'),
                  format(state.timeline.end_date, 'yyyy-MM-dd')
                )
              })
          }
        })
      }

      const loadJobTitles = () => {
        store.dispatch('genericStore/showPageLoader', true)
        let start_date = state.timeline.start_date
          ? state.timeline.start_date
          : startOfISOWeek(new Date())
        let end_date = state.timeline.end_date
          ? state.timeline.end_date
          : endOfISOWeek(new Date())
        let parent_ids =
          state.timeline.level &&
          state.timeline.level <= state.levels.employee_overview
            ? state.groupings.projects.map((p) => p.id)
            : [state.timeline.project_id]

        getJobTitles({
          relations: ['tags'],
          parents: parent_ids,
          start_date: format(start_date, 'yyyy-MM-dd'),
          end_date: format(end_date, 'yyyy-MM-dd'),
        })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const loadEmployees = () => {
        store.dispatch('genericStore/showPageLoader', true)
        getEmployeeProjects({
          type: ['employee'],
          relations: [
            'employee',
            'employee.qualifications.qualificationType.tags',
            'projectType',
          ],
          in_parent: state.timeline.project_id,
          // status: 'current,planned',
        })
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      const init = () => {
        loadProjects()
      }

      const breadcrumbClasses = [
        'max-w-max',
        'w-max',
        'border-none',
        'bg-none',
        'text-blue-300',
      ]

      const crumbs = [
        { name: 'Dashboard', url: '/' },
        { name: 'rotas', url: '/rotas' },
      ]

      onMounted(() => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: crumbs,
        })
        getRotasPastEditSetting('allow_rotas_past_edits')
          .then(() => init())
          .catch((error) =>
            store.dispatch('genericStore/pushNotification', error.message)
          )
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      })

      return {
        buildForm,
        saveChanges,
        adding,
        updating,
        deleting,
        isFutureDate,
        liveShifts,
        shortDate,
        state,
        exportReport,
        projectName,
        drillup,
        drilldown,
        changeDate,
        canEditModule,
        setupAddModal,
        breadcrumbClasses,
      }
    },
  })
