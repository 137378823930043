
  import { defineComponent, reactive } from 'vue'
  import { WizardStep } from '@/types/components/WizardStep'
  import WizardStepVue from '@/components/Generic/Wizard/WizardStep.vue'

  export default defineComponent({
    components: {
      WizardStepVue,
    },
    emits: ['save-form', 'step-changed'],
    props: {
      activeStep: {
        type: Number,
        required: true,
        default: () => 1,
      },
    },
    setup(props, { emit }) {
      props = reactive(props)
      const steps = [
        {
          icon: 'far fa-memo-circle-info',
          label: 'Details',
        },
        {
          icon: 'far fa-shield-keyhole',
          label: 'Access',
        },
        {
          icon: 'far fa-input-text',
          label: 'Build',
        },
        {
          icon: 'far fa-file-check',
          label: 'Preview',
        },
      ] as WizardStep[]

      const stepChanged = (step: number) => emit('step-changed', step)
      const saveForm = () => emit('save-form')

      return {
        steps,
        saveForm,
        stepChanged,
        props,
      }
    },
  })
