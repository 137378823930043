import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "font-display" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_dx_file_uploader = _resolveComponent("dx-file-uploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_label, {
      label: _ctx.label,
      error: '',
      "is-required": _ctx.isRequired,
      class: _normalizeClass(false)
    }, null, 8, ["label", "is-required"]),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: _normalizeClass(["mt-1", _ctx.labelClasses])
        }, _toDisplayString(_ctx.message), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", { id: _ctx.id }, [
      _renderSlot(_ctx.$slots, "drop-zone-appearance")
    ], 8, _hoisted_2),
    _createVNode(_component_dx_file_uploader, _mergeProps({
      "select-button-text": _ctx.uploadText,
      accept: "*",
      "allowed-file-extensions": _ctx.allowedExtensions,
      "allow-canceling": true,
      "upload-mode": "useForm",
      onValueChanged: _ctx.uploadFiles,
      multiple: "multiple"
    }, _ctx.$attrs, {
      "dialog-trigger": _ctx.slots['drop-zone-appearance'] ? `#${_ctx.id}` : undefined,
      "drop-zone": _ctx.slots['drop-zone-appearance'] ? `#${_ctx.id}` : undefined,
      visible: !_ctx.slots['drop-zone-appearance']
    }), null, 16, ["select-button-text", "allowed-file-extensions", "onValueChanged", "dialog-trigger", "drop-zone", "visible"])
  ]))
}