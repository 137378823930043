import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-bold text-2xl my-4" }
const _hoisted_3 = { class: "border px-4" }
const _hoisted_4 = { class: "border px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reg_report_section = _resolveComponent("reg-report-section")!

  return (_ctx.report && Object.keys(_ctx.report).length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.report.regulatory_report_type.report_title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.report.regulatory_report_type
        .regulatory_report_type_section, (section) => {
          return (_openBlock(), _createElementBlock("div", {
            key: section,
            class: "py-8"
          }, [
            _createVNode(_component_reg_report_section, { section: section }, null, 8, ["section"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.regulatory_report_type_section_item, (item) => {
              return (_openBlock(), _createElementBlock("div", { key: item }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.regulatory_report_values[0].json_values
            .table_responses, (row) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: row,
                    class: _normalizeClass(`grid grid-cols-${Object.keys(row).length}`)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(row), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-span-1"
                      }, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(item[0]), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(item[1]), 1)
                      ]))
                    }), 128))
                  ], 2))
                }), 128))
              ]))
            }), 128))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}