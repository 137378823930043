
  import { computed, defineComponent, onMounted, reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import EventForm from './partials/EventForm.vue'

  export default defineComponent({
    components: {
      EventForm,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter()
      const store = useStore()
      props = reactive(props)

      const event = computed(() => store.getters['calendar/getEvent'])

      const updateEvent = async (payload: any) => {
        store.dispatch('genericStore/showPageLoader', true)
        await store
          .dispatch('calendar/updateEvent', {
            id: event.value.id,
            payload: payload,
          })
          .then((response) => {
            store.dispatch('genericStore/pushNotification', response.message)

            if (response.success) {
              router.push({ name: 'Calendar' })
            }
          })
          .catch((response) => {
            store.dispatch('genericStore/pushNotification', response.message)
          })
          .finally(() => store.dispatch('genericStore/showPageLoader', false))
      }

      onMounted(async () => {
        store.dispatch('genericStore/showPageLoader', true)
        store.commit('genericStore/setBreadcrumbs', {
          crumbs: [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Calendar', url: '/calendar' },
            { name: 'Edit Event', url: `/calendar/event/${props.id}/edit` },
          ],
        })
        store.commit('genericStore/setTabs', { tabs: [] })
        await store.dispatch('calendar/getEvent', props.id)
        store.dispatch('genericStore/showPageLoader', false)
      })

      return {
        event,
        updateEvent,
      }
    },
  })
