import axios from 'axios'

export default {
  toggleManyVisibility: async (params: any) => {
    return axios
      .post('forms/toggle_visibility', params)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  updateEmail: (payload: any) => {
    payload = payload

    return {
      success: true,
      message: 'Emails updated successfully',
      data: null,
    }
  },
  index: async (params: any) => {
    return axios
      .get('forms', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  store: (payload: any) => {
    return axios
      .post('forms', payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  update: (id: any, payload: any) => {
    return axios
      .post(`forms/${id}`, payload)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSystemLists: (params: any) => {
    return axios
      .get('forms/system-lists', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getSystemListValues: (id: any, params: any) => {
    return axios
      .get(`forms/system-lists/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getFormTemplates: (params: any) => {
    return axios
      .get('forms/templates', { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  show: (id: any, params: any) => {
    return axios
      .get(`forms/${id}`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  },
  getFormFields: async (form_id: number, params: any) => {
    return axios
      .get(`forms/${form_id}/fields/with_previous`, { params })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response.data))
  }
}
